import { UserService } from './../../../../../services/user/user.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';

@Component({
    selector: 'app-updatepassword',
    templateUrl: './updatepassword.component.html',
    styleUrls: ['./updatepassword.component.scss']
})
export class UpdatepasswordComponent implements OnInit {

    PasswordForm;
    constructor(
        private formBuilder: FormBuilder,
        private userservice: UserService
    ) { }

    ngOnInit(): void {
        this.PasswordForm = this.formBuilder.group({
            old_password: "",
            new_password: ""
        });
    }

    onSubmit(userData){
        var send = 0
        this.userservice.user_obs.subscribe((user) => {
            if(user != null && send == 0 ){
                user.old_password = userData['old_password']
                user.password = userData['new_password']
                user.passwordUpdated = true
                send = 1
                this.userservice.updateUser(user)
            }

        })
    }

}

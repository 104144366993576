import { ImgReportPopupComponent } from '../../../contractstats/modals/imgReportPopup/imgReportPopup.component';
import { AngularFireStorage } from '@angular/fire/storage';
import { ReportingstatsService } from '../../../../services/reportingstats/reportingstats.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../../../../services/alert/alert.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Component, OnInit, Input, HostListener, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { Message, Report } from 'src/app/models/report/report';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import * as firebase from 'firebase';
import { Alert } from './../../../../models/alert/alert';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { ContratService } from 'src/app/services/contrat/contrat.service';
import { Contrat } from 'src/app/models/contrat/contrat';
import { hexToCSSFilter } from 'hex-to-css-filter';
import { UserBo } from 'src/app/models/user/user';

@Component({
    selector: 'app-reportaccess',
    templateUrl: './reportaccess.component.html',
    styleUrls: ['./reportaccess.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ReportaccessComponent implements OnInit {

    constructor(
        private lodingBarService: LoadingBarService,
        private alertservices: AlertService,
        public activeModal: NgbActiveModal,
        private route: ActivatedRoute,
        private reportingservice : ReportingstatsService,
        private afStorage : AngularFireStorage,
        private modalService: NgbModal,
        private router: Router,
        private datepipe: DatePipe,
        private contratservice: ContratService,
    ) { }

    @Input() report: Report
    @ViewChild("msg") msgRef : ElementRef
    reportImageUrl : string
    mail : string = ""
    hiddenSpin : boolean = false
    mailerror : boolean = false
    error : boolean = false 
    public innerWidth: any
    statusBefore: string
    commentBefore?:string
    userImageUrl = './../../../../../assets/img/user_image.jpg';
    userName = "Administrateur"
    showHistory: boolean = true
    message?: string
    activateSendButton = false
    isMessagesActive = false
    primaryColor?: string
    cssFilter?: string = undefined

    ngOnInit(): void {
        this.innerWidth = window.innerWidth;
        this.route.params.subscribe( params => {
            if(Object.keys(params).length>0){
                const gid = params['gid'];
                firebase
                .firestore()
                .collection("formulaires")
                .doc(gid).get()
                .then(async (docRef) => {
                    this.report = docRef.data() as Report
                    this.report.gid = docRef.id
                    this.mapReporting(this.report)
                    this.commentBefore = this.report.commentaire
                    this.statusBefore = this.report.status
                    this.isMessagesActive = (this.report.messages?.length ?? 0) > 0
                    if(this.report.status === 'traiter'){
                        this.alertResolu()
                    }
                    const ref = this.afStorage.ref(this.report.idContrat +'/formulaires/'+this.getValue(this.report.formulaires, 'image'));
                    ref.getDownloadURL().subscribe((href)=> {
                        this.hiddenSpin = true
                        this.reportImageUrl = href
                    })
                    this.contratservice.getContract(this.report.idContrat).then(docSnapshot => {
                        if (docSnapshot.exists) {
                            let contract = docSnapshot.data() as Contrat
                            this.primaryColor = contract.design.couleurPrincipale
                            this.cssFilter = hexToCSSFilter(this.primaryColor).filter.replace(";","")
                        }
                    })
                })
            }
            if(Object.keys(params).length>1){
                const convert = (from, to) => str => Buffer.from(str, from).toString(to)
                const hexToUtf8 = convert('hex', 'utf8')
                this.mail = hexToUtf8(params['mail'])
            }
        });
    }

    alertResolu(){
        this.error = true
        var alert: Alert = {
            type: "success",
            message: " ❗ Attention le ticket est déjà résolu.",
        };
        this.alertservices.setAlert(alert)
    }

    statusColor(status: string) {
        switch(status) {
            case "A traiter" :
                return "action-red"
            case "En cours" :
                return "action-yellow"
            case "Résolu" :
                return "action-green"
            case "Archiver" :
                return "action-grey"
            default :
                return ""
        }
    }

    genHtmlAction(action:string) {
        let actionSuffix = action.replace(/^(a passé le statut du ticket de )/,'')
        let status = actionSuffix.split(" à ")

        if (status.length == 2) {
            return `a passé le statut du ticket de 
            <div class="ml-1 mr-1 action ${this.statusColor(status[0])}" >
            ${status[0]}
            </div> 
            à 
            <div class="ml-1 mr-1 action ${this.statusColor(status[1])}" >
            ${status[1]}
            </div>`
        } else {
            return action
        }
    }

    deleteAllCookies() {
        var cookies = document.cookie.split(";");
    
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var eqPos = cookie.indexOf("=");
            var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
    }
    
    @HostListener('window:unload', [ '$event' ])
    unloadHandler(event) {
        let user = firebase.auth().currentUser
        if(user.isAnonymous === true){
            user.delete()
            console.log('deleted')
            this.deleteAllCookies()
        }
    }

    @HostListener('window:beforeunload', [ '$event' ])
    beforeUnloadHandler(event) {
        let user = firebase.auth().currentUser
        if(user.isAnonymous === true){
            user.delete()
            console.log('deleted')
            this.deleteAllCookies()
        }
    }

    removeAnonUser(){
        let user = firebase.auth().currentUser
        if(user.isAnonymous === true && user.email === null){
            user.delete()
        }
        this.router.navigate(['/user/success'])
    }

    validateEmail(email: string){
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    mapReporting(report){
        if(report.coordonnees === undefined) {
            report['coordonnees'] = 
                {
                    'Nom' : report.nom,
                    'Prénom' : report.prenom,
                    'Email' : report.email,           
                    'Téléphone' : report.telephone
                }    
            
            report['formulaires'] = 
                {
                    'Message' : report.message,
                    'Motif' : this.checkUndefinedMotif(report.motif),
                    'Image' :report.image,
                    'Adresse' : report.adresse
                }
        } else if(report.image) {
            Object.assign(report.formulaires, {'image': report.image});
        } else if(!report.id) {
            report.id = this.parseId(report.type, report.date.seconds)
        }
    }

    parseId(type: string, secs: number) {
        let code = ''
        if(type === 'Commerces et Professionnels') {
            code = 'PRO'
        } else if(type === 'Dépôt sauvage') {
            code = 'DS'
        } else if(type === 'Emcombrants') {
            code = 'ENC'
        } else if(type === 'Anomalie de collecte') {
            code = 'AC'
        } else if(type === 'Demande de bac') {
            code = 'BAC'
        } else if(type === 'Borne cassée') {
            code = 'BC'
        } else if(type === 'Autres') {
            code = 'AUT'
        } else if(type === 'Point de collecte') {
            code = 'PC'
        }
        let date = new Date(1970, 0, 1)
        date.setSeconds(secs)
        let newdate = this.datepipe.transform(date, "ddMMyyyyHHmmss")
        const dateParsed = `${code}${newdate}`
        return dateParsed
    }

    show() {
        let options: NgbModalOptions = {};
        options.centered = true
        options.size='lg'
        const modalRef = this.modalService.open(ImgReportPopupComponent,options)
        modalRef.componentInstance.reportImageUrl = this.reportImageUrl;
    }

    checkEmpty(str){
        if (str == undefined || str.length === 0) {
            return true
        } else {
            return false
        }
    }

    inputChange(event: any){
        this.mail = event.target.value
    }

    checkUndefinedMotif(element: string) {
        let data = "Sans motif"
        if (element === undefined || element === '') {
            return data
        } else {
            return element
        }
    }

    alertMail(){
        this.mailerror = true
        this.error = true
        var alert: Alert = {
            type: "danger",
            message: " ❗ Merci de saisir un email valide",
        };
        this.alertservices.setAlert(alert)
    }

    filterCheck(key){
        key = key.toLowerCase()
        return !key.includes("image") && !key.includes("motif") && !key.includes("photo")
    }

    getValue(items, valeur){
        valeur = valeur.toLowerCase()
        var want = '';
            for (let [key, value] of Object.entries(items)) {
                key = key.toLowerCase()
                if(key.includes(valeur)) {
                    want = String(value)
                    return want
                }
                if (valeur.includes("image")) {
                    if(key.includes("photo")) {
                        want = String(value)
                        return want
                    } 
                }
            }
    }

    toDateTime(secs) {
        var date = new Date(1970, 0, 1); // Epoch
        date.setSeconds(secs);
        return moment(date).format('DD/MM/YYYY')
    }

    updateStatus() {
        if (this.updateHistory()) {
            if (this.report.status === 'traiter') {
                this.report.soldeur = this.mail
                this.report.dateFin = firebase.firestore.Timestamp.now();
            } else if (this.report.status === 'en_cours') {
                this.report.dateEnCours = firebase.firestore.Timestamp.now();
            }
            this.report.majBO = false
            this.report.maj = true
            this.reportingservice.updateReporting(this.report).then((status) => {
                if (status === 'success') {
                    this.statusBefore = this.report.status
                    this.commentBefore = this.report.commentaire
                }
            })
            this.removeAnonUser()
        }
    }

    updateHistory(){
        let result = false
        if (!this.report.historique) {
            this.report.historique = []
        }
        if (this.report.commentaire != this.commentBefore) {
            let action = {
                date: moment().format('DD/MM/YYYY HH:mm'),
                email: this.mail,
                nom: this.userName,
                image: this.userImageUrl,
                action: "a ajouté un commentaire :",
                commentaire: this.report.commentaire,
            }
            this.report.historique.splice(0, 0, action)
            result = true
        }

        if (this.report.status != this.statusBefore) {
            let action = {
                date: moment().format('DD/MM/YYYY HH:mm'),
                email: this.mail,
                nom: this.userName,
                image: this.userImageUrl,
                action: `a passé le statut du ticket de ${this.humanizeStatus(this.statusBefore)} à ${this.humanizeStatus(this.report.status)}`,
                commentaire: this.report.commentaire,
            }
            this.report.historique.splice(0, 0, action)
            result = true
        }
        return result
    }

    humanizeStatus(status:string){
        let result = ''
        if (status === 'a_traiter') {
            result = 'A traiter'
        }
        else if (status === 'en_cours') {
            result = 'En cours'
        }
        else if (status === 'traiter') {
            result = 'Résolu'
        }
        else if (status === 'archiver') {
            result = 'Archiver'
        }
        return result
    }

    changeStatus(status: string){
        this.report.status = status
    }

    timestampToDate(timestamp){
        try {
            return moment(timestamp.toDate()).format('DD/MM/YYYY à HH:mm')
        } catch (error) {
            return ""
        }
    }

    changeHistoryVisibility(status: boolean) {
        this.showHistory = status
    }

    async sendMessage() {
        if (this.message) {
            let message = new Message()
            message.message = this.message
            message.expediteur = 1
            message.email = this.mail
            this.report.messages.push({...message})
            this.lodingBarService.start()
            this.reportingservice.updateReportMessages(this.report).then(() => {
                this.msgRef.nativeElement.innerText = ""
                this.message = undefined
                this.lodingBarService.stop()
            }).catch((error) => {
                console.log(error)
                this.report.messages.pop()
                this.lodingBarService.stop()
            })
        }
    }
}

import { Alert } from './../../models/alert/alert';
import { Router } from '@angular/router';
import { LodingBarService } from './../lodingbar/loding-bar-service.service';
import { AlertService } from './../alert/alert.service';
import { BehaviorSubject } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Report } from 'src/app/models/report/report';
import {ToastrService} from 'ngx-toastr'


@Injectable({
    providedIn: 'root'
})
export class ReportingstatsService {
    reporting: BehaviorSubject<Report[]> = new BehaviorSubject<Report[]>(null);
    reporting_obs = this.reporting.asObservable()
    item: BehaviorSubject<Report[]> = new BehaviorSubject<Report[]>(null);
    
    constructor(
        private afs: AngularFirestore,
        private alertservices: AlertService,
        private lodingBarService: LodingBarService,
        private router: Router,
        private datepipe: DatePipe,
        private toastr: ToastrService
    ) { }

    getReporting(idContrat: string){
        this.reportFromDatabase(idContrat).subscribe((data) => {
            var report_data = data.map((e) => {
                let gid = e.payload.doc.id;
                var report : Report =  e.payload.doc.data() as Report;
                report.gid = gid
                report.id = this.parseId(report.type, report.date.seconds)
                return report
            });
            this.reporting.next(report_data);

        });
    }

    getReportingList(idContrat: string){
        this.reportFromDatabaseList(idContrat).subscribe((data) => {
            var report_data = data.map((e) => {
                let gid = e.payload.doc.id;
                var report : Report =  e.payload.doc.data() as Report;
                report.gid = gid
                report.id = this.parseId(report.type, report.date.seconds)
                return report
            });
            this.reporting.next(report_data);

        });
    }

    parseId(type, secs){
        let code =''
        if(type === 'Commerces et Professionnels'){
            code = 'PRO'
        }else if(type === 'Dépôt sauvage'){
            code = 'DS'
        }else if(type === 'Emcombrants'){
            code = 'ENC'
        }else if(type === 'Anomalie de collecte'){
            code = 'AC'
        }else if(type === 'Demande de bac'){
            code = 'BAC'
        }else if(type === 'Borne cassée'){
            code = 'BC'
        }else if(type === 'Autres'){
            code = 'AUT'
        }else if(type === 'Point de collecte'){
            code = 'PC'
        }
        let date = new Date(1970, 0, 1); // Epoch
        date.setSeconds(secs);
        let newdate = this.datepipe.transform(date, "ddMMyyyyHHmmss")
        const dateParsed = `${code}${newdate}`
        return dateParsed;
    }

    reportFromDatabase(idContrat: string) {
        return this.afs
            .collection("formulaires", (ref) =>
                ref.where("idContrat", "==", idContrat).
                where("status", "!=", "archiver")
            )
            .snapshotChanges();
    }

    reportFromDatabaseList(idContrat: string) {
        return this.afs
            .collection("formulaires", (ref) =>
                ref.where("idContrat", "==", idContrat)
            )
            .snapshotChanges();
    }

    getItem(idContrat: string){
        
    }

    async changeReadStatus(id: string) {
        return this.afs.collection('formulaires').doc(id).update({"majBO": false})
    }

    async updateReporting(report: Report) {
        var id = report.gid
        var self = this;
        return self.afs.collection('formulaires').doc(id).set({...report})
            .then(function () {
                return 'success'
            })
            .catch(function (error) {
                return 'error'
            });
    }

    async updateReportMessages(report: Report) {
        var id = report.gid
        var self = this;
        return self.afs.collection('formulaires').doc(id).update({"messages": report.messages, "maj": true})
    }

    updateCommentaire(report: Report) {
        var id = report.gid
        this.lodingBarService.setStatus(true);
        var self = this;
        self.afs.collection('formulaires').doc(id).update({"commentaire":report.commentaire, "soldeur": report.soldeur})
            .then(function () {
                self.toastr.success('Données enregistrées avec succès', "",{
                    closeButton: true,
                    positionClass: 'toast-bottom-left',
                });
                self.lodingBarService.setStatus(false);
            })
            .catch(function (error) {
                self.toastr.warning(`Chargement échoué. Veuillez réessayer.`, "",{
                    closeButton: true,
                    positionClass: 'toast-bottom-left',
                });
                self.lodingBarService.setStatus(false);
            });
    }
}

export class Cartes {
    flux?: Flux[];
    constructor({flux = []}){
        this.flux = flux
    }
}
export class Flux {
    defaut?:boolean;
    description :string;
    media : string;
    couleur: string;
    image: string;
    active : boolean;
	url_marker? :string;
	url_menu?: string;
    nom:   string;
    error? :  boolean;
    displayName : string;
    colorErrorMsg? : string
    pavs:  Pav[];
    ordre: number;
}

export class Pav {
    id:                  string
    adresseVoie:         string;
    aPublier:            string;
    cp:                  string;
    etat?:              string;
    dimanche?:           string;
    jeudi?:              string;
    lundi?:              string;
    mail:                string;
    telephone:           string;
    mardi?:              string;
    marcredi?:           string;
    nom:                 string;
    site:                string;
    samedi?:             string;
    url:                 string;
    informations:        string;
    ouvert24h:           string;
    vendredi?:           string;
    ville:               string;
    lat:                 number;
    lng:                 number;
    adresseComplement?:  string;
	TEL?:                string;
	distance?:			 number
}

export class FilteredItem  {
    pav: Pav
    flux: string
    constructor(pav, flux) {
        this.pav = pav
        this.flux = flux
    }
}
import { CommonModule } from '@angular/common';
import { MainModule } from './modules/main/main.module';
import { UserModule } from './modules/user-module/user-module.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule, SETTINGS as FIRESTORE_SETTINGS } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from '../environments/environment';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule, ToastContainerModule } from 'ngx-toastr';
import { ToastAlertsComponent } from './modules/main/components/toast-alerts/toast-alerts.component';
import { SuccessToast } from './modules/actussettings/modals/custom-toast/success';
import { TransferToast } from './modules/actussettings/modals/custom-toast/transfer';
import { FailedToast } from './modules/actussettings/modals/custom-toast/failed';
import { ErrorToast } from './modules/actussettings/modals/custom-toast/error';
import { UploadocumentsComponent } from './modules/uploadocuments/uploadocuments.component';
import { DocumentComponent } from './modules/uploadocuments/modals/document/document.component';
import { DeleteDocumentComponent } from './modules/uploadocuments/modals/delete-document/delete-document.component';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    AppComponent,
    SuccessToast,
    TransferToast,
    FailedToast,
    ErrorToast,
    UploadocumentsComponent,
    DocumentComponent,
    DeleteDocumentComponent
  ],
  imports: [
    UserModule,
    MainModule,
    FormsModule,
    BrowserModule,
    CommonModule,
    NgbModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-left',
      
    }),
    ToastContainerModule,
    LoadingBarModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production, registrationStrategy: 'registerImmediately' }),
  ],
  entryComponents: [SuccessToast, TransferToast, FailedToast, ErrorToast],
  providers: [
        NgbActiveModal,
        ToastAlertsComponent,
        {
            provide: FIRESTORE_SETTINGS,
            useValue: { ignoreUndefinedProperties: true }
        }
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }

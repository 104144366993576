import { SendmailComponent } from './../../modals/sendmail/sendmail.component';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from './../../../../services/alert/alert.service';
import { LodingBarService } from './../../../../services/lodingbar/loding-bar-service.service';
import { AuthentificationService } from './../../../../services/authentification/authentification.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';


@Component({
    selector: 'app-sign-in',
    templateUrl: './sign-in.component.html',
    styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {
    SignInForm;
    private  is_invit  :  boolean ;
    private id_invit : string = "";
    constructor(
        private authservice: AuthentificationService,
        private formBuilder: FormBuilder,
        private router: Router,
        private lodingBarService: LodingBarService,
        private alertservice: AlertService,
        private route: ActivatedRoute,
        private modalService: NgbModal,
        

    ) { }

    ngOnInit(): void {
        window.scrollTo(0, 0);

            this.SignInForm = this.formBuilder.group({
                email_signin: "",
                password_signin: "",
                name_signin: ""
            });

    }

    pass(url: string) {
        this.router.navigateByUrl(url)
    }

    onSubmit(userData) {
		this.lodingBarService.setStatus(true);
		this.authservice.signIn(userData["email_signin"], userData["password_signin"]);
	}

    showSendMailModal(){
        window.scrollTo(0, 0);
		let options: NgbModalOptions = {};
        options.centered = true
		this.modalService.dismissAll()
		const modalRef = this.modalService.open(SendmailComponent,options)
    }
}

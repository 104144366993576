import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class MenuService {
    subsection : BehaviorSubject<string> = new BehaviorSubject<string>('territoire');
    subsection_obs = this.subsection.asObservable()
    section : BehaviorSubject<string> = new BehaviorSubject<string>('statistiques');
    section_obs = this.section.asObservable()

    constructor() {

    }

    setSection(section: string){
        this.section.next(section)
    }

    setSubsection(subsection : string){
        this.subsection.next(subsection)
    }
}

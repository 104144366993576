import { ReportingComponent } from './../../modals/reporting/reporting.component';
import { ReportingstatsService } from './../../../../services/reportingstats/reportingstats.service';
import { ContratService } from './../../../../services/contrat/contrat.service';
import { DatePipe } from '@angular/common';
import { AngularFireStorage } from '@angular/fire/storage';
import { Contrat } from 'src/app/models/contrat/contrat';
import { UserBo } from './../../../../models/user/user';
import { Report } from './../../../../models/report/report';
import { Component, OnInit, Injectable, ViewEncapsulation, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbDate, NgbCalendar, NgbDateParserFormatter, NgbInputDatepicker} from '@ng-bootstrap/ng-bootstrap';
import { Location } from '@angular/common';
import * as moment from 'moment';
import * as XLSX from 'xlsx';
import {cloneDeep, uniqWith, isEqual, update} from 'lodash';
import { UserService } from './../../../../services/user/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { firestore } from 'firebase';
import * as firebase from 'firebase';


@Injectable({
    providedIn: 'root',
})
export class NgbDateMomentParserFormatter extends NgbDateParserFormatter {
    constructor() {
        super();
    };
    format(date: NgbDate): string {
        if (date === null) {
            return 'DD/MM/YYYY';
        }
        let d = moment({ year: date.year,
                         month: date.month - 1,
                         date: date.day });
        return d.isValid() ? d.format('DD/MM/YYYY') : '';
    }

    parse(value: string): NgbDate {
        if (!value) {
            return null;
        }
        let d = moment(value, 'DD/MM/YYYY');
        return d.isValid() ? new NgbDate(d.year(),d.month()+1,d.date()) : null}

    formatTwoDate(datefrom: NgbDate,dateto: NgbDate): string {
        if (datefrom === null || dateto === null) {
            return '';
        }
        let dfrom = moment({ year: datefrom.year,
                         month: datefrom.month - 1,
                         date: datefrom.day });
         let dto = moment({ year: dateto.year,
                         month: dateto.month - 1,
                         date: dateto.day });
        return dfrom.isValid() && dto.isValid() ? "Du "+dfrom.format('DD/MM/YYYY') +" au " + dto.format('DD/MM/YYYY'): '';
    }

}

@Component({
    selector: 'app-resportinglist',
    templateUrl: './resportinglist.component.html',
    styleUrls: ['./resportinglist.component.scss'],
    template: `
    <app-reporting (messageEvent)="receiveMessage($event)"></app-reporting>
`,
    encapsulation: ViewEncapsulation.None,
    styles: [`
        .popStatut .popover-body {
            margin-left: 16px;
        }
        .popStatut {
            width: 150px;
        }
        .popSujet .popover-body {
            margin-left: 19px;
        }
        .popSujet {
            width: 184px;
        }
        .popMotif .popover-body {
            margin-left: 17px;
        }
        .popMotif {
            width: 182px;
        }
  `]
})
export class ResportinglistComponent implements OnInit {
    contrat: Contrat;
    user: UserBo;
    page = 1;
    pageSize = 25;
    collectionSize : number;
    reporting_list : Report[];
    reporting : Report[];
    filteredReporting : Report[];
    idContrat : string = ""
    byType = 'status'
    checks = {}
    checksMotifs = {}
    checksMotifsOrder = []
    checksStatus = {}
    befDate : Date
    aftDate : Date
    dateFilter = "DAY"
    date: string = '0000-00-00'
    current_date: Date = new Date()
    classDemandeur = 'fa fa-caret-left'
    classDate = 'fa fa-caret-left'
    classID = 'fa fa-caret-left'
    hoveredDate: NgbDate | null = null;
    fromDate: NgbDate;
    toDate: NgbDate | null = null;
    tempReporting = []
    dateFilteredReporting = []
    items = []
    RTDSChartDataTest = [ ];
    CDSTest = {}
    trashStatus = []
    trashMotif = []
    trashSujet = []
    currentUserMail = ""
    currentContrat = ""
    inputSearch = ""
    stateFilter:boolean = false
    days = ['DIM', 'LUN', 'MAR', 'MER', 'JEU', 'VEN', 'SAM'];
    months = ['JAN', 'FEV', 'MAR', 'AVR', 'MAI', 'JUN', 'JUL', 'AOU', 'SEP', 'OCT', 'NOV', 'DEC']
    alldates = [
        '01', '02', '03', '04', '05', '06', '07', '08', '09', '10',
        '11', '12', '13', '14', '15', '16', '17', '18', '19', '20',
        '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'
    ];
    @ViewChild('datepicker') datePicker: NgbInputDatepicker;
    
    constructor(
        public datepipe: DatePipe,
        private contratservice: ContratService,
        private router: Router,
        private reportingservice: ReportingstatsService,
        private  afStorage : AngularFireStorage,
        private modalService: NgbModal,
        public location: Location,
        private calendar: NgbCalendar,
        public formatter: NgbDateMomentParserFormatter,
        private userService: UserService,
        private route: ActivatedRoute,
    ) {
        let date = new Date()
        this.fromDate = new NgbDate(date.getFullYear(), date.getMonth()+1, date.getDate())
    }

    ngOnInit(): void {
        window.scrollTo(0, 0);
        this.contratservice.contrat_obs.subscribe(contrat => {
            this.contrat =  contrat
            if (contrat.contrat.idContrat != 'BOTEST') {
                const service = contrat.services.find(service => service.nom == 'Demandes et Signalements')
                const subservices = service?.sousServices?.map(function(subservices) {return subservices})
                subservices.forEach(element => {
                    let nom = element.nom
                    if(nom === 'Problème de bac'){
                        nom = 'Borne cassée'
                    }else if (nom === 'Autre demande'){
                        nom = 'Autres'
                    }
                    let data = [nom, 0]
                    this.RTDSChartDataTest.push(data)
                    let obj = {}
                    obj[nom] = {
                        show: true,
                        active : false ,
                        points: [],
                      }
                    
                    Object.assign(this.CDSTest, obj)
                });
            }
        })
        
        this.contratservice.contrat_obs.subscribe((contrat)=>{
            if(contrat.contrat.idContrat != 'BOTEST'){
                    this.reportingservice.getReportingList(contrat.contrat.idContrat)
                    this.reportingservice.reporting_obs.subscribe((reporting) => {
                        if(this.currentContrat != this.contrat.contrat.idContrat){
                            if (reporting != null) {
                                this.userService.user_obs.subscribe((user) => {
                                    this.currentUserMail = user.email
                                    if (user.role.indexOf("Agent") > -1) {
                                        reporting = reporting.filter(elem => {
                                            return elem.destinataire == user.email
                                        })
                                        
                                    }
                                    this.mapReporting(reporting)
                                    this.reporting_list = reporting
                                    this.collectionSize = this.reporting_list.length 
                                    this.init()
                                    this.currentContrat = this.contrat.contrat.idContrat
                                    this.route.params.subscribe(params => {
                                        switch (params["filter"]) {
                                            case "MONTH": {
                                                this.MonthStats()
                                                break
                                            }
                                            case "YEAR": {
                                                this.YearStats()
                                                break
                                            }
                                            default: {
                                                this.DayStats()
                                            }
                                        }
                                    })
                                })
                            }
                        } else {
                            this.tempReporting = this.tempReporting
                        }
                    })
            }

            if (contrat.contrat.idContrat != 'BOTEST') {
                var service = contrat.services.find(service => service.nom == 'Demandes et Signalements')
                this.RTDSChartDataTest.forEach(element => {
                    let name = element[0]
                    let key = 'active'
                    this.CDSTest[name][key] = service?.sousServices?.find(sousService => sousService.nom === element[0])?.active ?? false
                })
            }
        })

        this.items.push(this.CDSTest)
    }

    onChange(event){
        if(event.target.value){
            this.checkExist(event.target.value)
        }else{
            //this.refreshReporting()
            this.stateFilter = false
            this.filter()
            this.updateReportingList()
        }
        this.inputSearch = event.target.value
    }

    removeSearch(){
        this.inputSearch = ""
        this.refreshReporting()
        this.stateFilter = false
    }

    disableFilter(){
        this.stateFilter = !this.stateFilter
    }

    checkExist(input){
        this.stateFilter = true
        this.filter()
        this.updateReportingList()
        this.refreshReporting()
        
        let elementList = []
        let filteredIds = []
        this.reporting_list.forEach(element => {
            let elem = []
            elem.push(element.id)
            for (const [_, value] of Object.entries(element)) {
                if (typeof value === 'string' && value != ''){
                    elem.push(value)
                } else if (typeof value === 'object'){
                    for (const [_, val] of Object.entries(value)) {
                        if (typeof val === 'string' && val != ''){
                            elem.push(val)
                        }
                    }
                }
            }
            elementList.push(elem)
        });
        elementList.forEach(element => {
            for (const elem of element) {
                let lowercasedElemWithoutAccent = elem.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase() // whithout accent
                let lowercasedInputWithoutAccent = input.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()
                if(lowercasedElemWithoutAccent.includes(lowercasedInputWithoutAccent)){
                    filteredIds.push(element[0])
                    break
                }
            }
        });
        this.filteredReporting = this.filteredReporting.filter(element => 
            filteredIds.indexOf(element.id) > -1
        )
        this.page = 1
        this.updateReportingList()
    }

    onClickButtonRange() {
        this.inputSearch = ""
        this.dateFilter = "RANGE"
        this.hoveredDate = null
        let date = new Date()
        this.fromDate = new NgbDate(date.getFullYear(), date.getMonth()+1, date.getDate())
        this.toDate = null
    }

    navigateBack() {
        this.router.navigate(['/montri/reportingstats', { filter: this.dateFilter }]);
    }

    filterList(type:string, opt:string, motif?:string){
        this.byType = type
        if(this.byType === "status") {
            this.checksStatus[opt] = !this.checksStatus[opt]
            this.filter()
            this.updateReportingList()
        } else if(this.byType === 'sujet') {        
            this.checks[opt] = !this.checks[opt]
            this.filter()
            this.updateReportingList()
        } else if(this.byType === 'motif'){
            this.checksMotifs[opt][motif] = !this.checksMotifs[opt][motif]
            this.filter()
            this.updateReportingList()
        } else if(this.byType === 'demandeur'){
            if (this.classDemandeur === 'fa fa-caret-down') {
                this.classDemandeur = 'fa fa-caret-up'
                this.sortByDemandeur('up')
            } else {
                this.classDemandeur = 'fa fa-caret-down'
                this.sortByDemandeur('down')
            }
            this.classDate = 'fa fa-caret-left'
            this.classID = 'fa fa-caret-left'
        } else if(this.byType === 'date'){
            if(this.classDate === 'fa fa-caret-down') {
                this.classDate = 'fa fa-caret-up'
                this.sortByDate('up')
            } else {
                this.classDate = 'fa fa-caret-down'
                this.sortByDate('down')
            }
            this.classDemandeur = 'fa fa-caret-left'
            this.classID = 'fa fa-caret-left'
        } else if (this.byType === 'id') {
            if(this.classID === 'fa fa-caret-down') {
                this.classID = 'fa fa-caret-up'
                this.sortById('up')
            } else {
                this.classID = 'fa fa-caret-down'
                this.sortById('down')
            }
            this.classDemandeur = 'fa fa-caret-left'
            this.classDate = 'fa fa-caret-left'
        }
    }

    getColor(string, motif?){
        let color = 'black'
        if(string === 'status') {
            for (let key in this.checksStatus) {
                if (!this.checksStatus[key]) {
                    return color = '#F47000'
                }
            }
        } else if(string === 'sujet'){
            for (let key in this.checks) {
                if (!this.checks[key]) {
                    return color = '#F47000'
                }
            }
        } else if(string === 'motif'){
            for (let key in this.checksMotifs[motif]) {
                if (!this.checksMotifs[motif][key]) {
                    return color = '#F47000'
                }
            }
        }
        return color
    }
    
    sortById(str: string) {
        if (str === 'up') {
            this.filteredReporting.sort((a, b) => (a.id > b.id ? -1 : 1))
        } else if (str === 'down'){
            this.filteredReporting.sort((a, b) => (a.id < b.id ? -1 : 1))
        }
        this.page = 1
        this.updateReportingList()
    }

    sortByDate(str: string){
        if (str === 'up') {
            this.filteredReporting.sort((a, b) => a.date - b.date)
        } else if (str === 'down') {
            this.filteredReporting.sort((a, b) => b.date - a.date)
        }
        this.page = 1
        this.updateReportingList()
    }

    sortByDemandeur(str: string){
        let self = this
        if (str === 'up') {
            this.filteredReporting.sort(function(a, b) {
                var textA = self.getValue(a.coordonnees, 'nom').toUpperCase()[0];
                var textB = self.getValue(b.coordonnees, 'nom').toUpperCase()[0];
                return (textA > textB) ? -1 : (textA < textB) ? 1 : 0;
            });
        } else if(str === 'down') {
            this.filteredReporting.sort(function(a, b) {
                var textA = self.getValue(a.coordonnees, 'nom').toUpperCase()[0];
                var textB = self.getValue(b.coordonnees, 'nom').toUpperCase()[0];
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });
        }
        this.page = 1
        this.updateReportingList()
    }

    updateReportingList() {
        this.collectionSize = this.filteredReporting.length
        let start = (this.page - 1) * this.pageSize
        let end = start + this.pageSize
        this.reporting = this.filteredReporting.map((report, i) => ({ id: i + 1, ...report })).slice(start, end)
    }

    formatDateTime(item: Report) {
        var date = new Date(1970, 0, 1)
        date.setSeconds(item.date.seconds)
        return moment(date).format('DD/MM/YYYY')
    }

    DayStats() {
        this.inputSearch = ''
        this.dateFilter = "DAY"
        let date = new Date()
        this.befDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0)
        this.aftDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59)
        this.filter()
        this.updateReportingList()
    }

    MonthStats() {
        this.inputSearch = ''
        this.dateFilter = "MONTH"
        let date = new Date()
        let aftDay = new Date(date.getFullYear(), date.getMonth()+1, 0).getDate()
        this.befDate = new Date(date.getFullYear(), date.getMonth(), 1)
        this.aftDate = new Date(date.getFullYear(), date.getMonth(), aftDay, 23, 59, 59)
        this.filter()
        this.updateReportingList()
    }

    YearStats() {
        this.inputSearch = ''
        this.dateFilter = "YEAR"
        let date = new Date()
        this.befDate = new Date(date.getFullYear(), 0, 1)
        this.aftDate = new Date(date.getFullYear(), 11, 31, 23, 59, 59)
        this.filter()
        this.updateReportingList()
    }

    lenCheck(str){
        if (str != undefined) {
            if(str.length > 20){
                str = str.substring(0, 16);
                str = str + "..."
                return str
            } else {
                return str
            }
        } else{
            return ""
        }  
    }

    checkTrash(trash,element){
        let o = trash.find(o => o.id === element.id)
        if(o){
            return false
        }else if(!o){
            return true
        }
    }

    filter() {
        this.filteredReporting = this.filterByMotif()
    }

    filterByMotif() {
        let result = this.filterBySujet()
        for (let motif in this.checksMotifs) {
            result = result.filter(element => {
                let key = this.getMotif(element, motif) == "" ? "Vide" : this.getMotif(element, motif)
                if (key in this.checksMotifs[motif]) {
                    return this.checksMotifs[motif][key]
                }
            })
        }
        return result
    }

    filterBySujet() {
        return this.filterByStatus().filter(element => {
            return this.checks[element.type]
        })
    }

    getStatusName(status) {
        let name = "À traiter"
        switch (status) {
            case "en_cours": {
                name = "En cours"
                break
            }
            case "traiter": {
                name = "Résolu"
                break
            }
            case "archiver": {
                name = "Archiver"
                break
            }
            default: {
                break
            }
        }
        return name
    }

    filterByStatus() {
        return this.filterByDateRange().filter(element => {
            return this.checksStatus[this.getStatusName(element.status)]
        })
    }

    toDateTime(secs) {
        var date = new Date(1970, 0, 1); // Epoch
        date.setSeconds(secs);
        return date;
    }

    filterByDateRange() {
        return this.reporting_list.filter(report => {
            let dateParsed = this.toDateTime(report.date.seconds)
            return dateParsed >= this.befDate && dateParsed <= this.aftDate
        })
    }

    refreshReporting() {
        for (let key in this.checksStatus) {
            this.checksStatus[key] = true
        }
        for (let key in this.checks) {
            this.checks[key] = true
        }
        for (let key1 in this.checksMotifs) {
            for (let key2 in this.checksMotifs[key1]) {
                this.checksMotifs[key1][key2] = true
            }
        }
        this.filter()
        this.updateReportingList()
    }

    init() {
        this.reporting_list = this.reporting_list.sort((a, b) => b.date - a.date)

        let service = this.contrat.services.find(service => service.nom == 'Demandes et Signalements')
        for (let sousService of service.sousServices) {
            if (sousService.active) {
                sousService.formulaires.champs.filter(champs => {
                    if (champs.format == "liste") {
                        if (!(champs.nom in this.checksMotifs)) {
                            this.checksMotifs[champs.nom] = {}
                            this.checksMotifsOrder.push(champs.nom)
                        }
                    }
                })
            }
        }

        for (let element of this.reporting_list) {
            for (const [key, value] of Object.entries(element.formulaires)) {
                if (key in this.checksMotifs) {
                    this.checksMotifs[key][value] = true
                }
            }
        }

        for (let key in this.checksMotifs) {
            this.checksMotifs[key]["Vide"] = true
        }

        for (let element of this.reporting_list) {
            this.checksStatus[this.getStatusName(element.status)] = true
            this.checks[element.type] = true
        }
    }

    capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    updateReport(rep : Report){
		let options: NgbModalOptions = {};
        options.centered = true
        this.modalService.dismissAll()
        options.size='lg'
        const modalRef = this.modalService.open(ReportingComponent,options)
        modalRef.componentInstance.report = rep;
        modalRef.componentInstance.currentUserMail = this.currentUserMail

        modalRef.result.then((data) => {
            // on close
            for (let index in this.reporting_list) {
                if (this.reporting_list[index].id == data.id) {
                    this.reporting_list[index] = data
                    break
                }
            }
            for (let index in this.filteredReporting) {
                if (this.filteredReporting[index].id == data.id) {
                    this.filteredReporting[index] = data
                    break
                }
            }
            this.filter()
            this.updateReportingList()
          }, (reason) => {
            // on dismiss
          });
    }

    onDateSelection(date: NgbDate) {
        if (!this.fromDate && !this.toDate) {
          this.fromDate = date
        } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
          this.toDate = date 
        } else {
          this.toDate = null
          this.fromDate = date
        }

        if(this.fromDate != null && this.toDate != null) {
            this.datePicker.close()
            this.befDate = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day)
            this.aftDate = new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day, 23, 59, 59)
            this.filter()
            this.updateReportingList()
        }
    }

    isHovered(date: NgbDate) {
        return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
    }

    isInside(date: NgbDate) {
        return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
    }

    isRange(date: NgbDate) {
        return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
    }

    validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
        const parsed = this.formatter.parse(input);
        return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
    }

    secToDateTime(secs) {
        var date = new Date(1970, 0, 1); // Epoch
        date.setSeconds(secs);
        return moment(date).format('DD/MM/YYYY')
    }

    timeConverter(timestamp){
        if(timestamp != undefined){
            var date = new Date(1970, 0, 1) // Epoch
            date.setSeconds(timestamp);
            return date;
        } else {
            return 'Ticket non traité'
        }
      }

    mapReporting(reports: Report[]) {
        for (let element of reports) {
            if(element.coordonnees === undefined){
                element['coordonnees'] = {
                    'Nom' : element.nom,
                    'Prénom' : element.prenom ? element.prenom : "",
                    'Email' : element.email,           
                    'Téléphone' : element.telephone
                }
                element['formulaires'] = {
                    'Message' : element.message,
                    'Image' :element.image,
                    'Adresse' : element.adresse
                } 
                if (element.motif) {
                    element['formulaires']['Motif de votre demande'] = element.motif
                }
            }
        }
    }

    getValue(items, valeur){
        for (const [key, value] of Object.entries(items)) {
            let lowercaseValue = valeur.toLowerCase()
            let lowercaseKey = key.toLocaleLowerCase()
            if (lowercaseKey == lowercaseValue) {
                if (lowercaseValue == "prénom") {
                    if (String(value).length > 0) {
                        return `, ${String(value)}`
                    } else {
                        return ""
                    }
                }
                return String(value)
            }
            if (lowercaseValue == "image") {
                if(lowercaseKey.includes("photo")) {
                    return String(value)
                } 
            }
            if(lowercaseKey.includes(lowercaseValue)) {
                let count = (lowercaseKey.match(/nom/g) || []).length
                if (count == 2 && lowercaseValue == "prénom") {
                    return ""
                } else {
                    return String(value)
                }
            }
        }
        return ""
    }

    getMotif(item: Report, motif) {
        if (motif in item.formulaires) {
            return item.formulaires[motif]
        }
        return ""
    }

    async getUrlImage(image){
        let downloadURL = ''
        try {
            let img = firebase.storage().ref(this.currentContrat+"/formulaires/" + image)
            downloadURL = await img.getDownloadURL()
        } catch (error) {
            downloadURL = "Pas d'image"
        }
        return downloadURL
    }

    getCommentaire(element) {
        if (element) {
            if(element.length === 0 || element === undefined) {
                return ""
            } else {
                return element
            }
        } else {
            return ""
        }
        
    }

/*    exportListCsv(){
        const csvArray = [
            [
                "Statut",
                "Type",
                "Message",
                "Adresse incident",
                "Photo",
                "Nom demandeur",
                "Email demandeur",
                "Telephone demandeur",
                "Date",
            ],
            ...this.reporting.map( item => [
                item.status,
                item.type,
                this.getValue(item.formulaires, 'Message'),
                this.getValue(item.coordonnees, 'Adresse'),
                this.getUrlImage(item.image),
                this.getValue(item.coordonnees, 'Nom'),
                this.getValue(item.coordonnees, 'Email'),
                this.getValue(item.coordonnees, 'Téléphone'),
                this.SectoDateTime(item.date.seconds),
            ])
          ]
        let content = "";
        csvArray.forEach(function(row) {
            content += row.join(",") + "\n";
        });
        const blob = new Blob([content], {type: 'text/csv;charset=utf-8;'});
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute("download", "signalements.csv");
        link.click(); 
    } */

    extractInfo(adresse:string){
        let city = ""
        let postal = ""
        let country = ""
        let address = ""
        if(adresse){
            let adresseArray = adresse.split(",")
            if(adresseArray.length === 1){
                country = adresseArray[0]
            }else if(adresseArray.length === 2){
                city = adresseArray[0]
                country = adresseArray[1]
            }
            else if(adresseArray.length === 3){
                address = adresseArray[0]
                let splitted = adresseArray[1].trim().split(" ")
                postal = splitted[0]
                for (let i = 1; i<splitted.length; ++i) {
                    city = `${city}${splitted[i]} `
                }
                if (city.length > 0) {
                    city = city.slice(0, -1)
                }
                country = adresseArray[2]
            }
        }
        return [city, postal];
    }

    async exportListXls(){
        'use strict';
        let arrayItem = []
        for  (let item of this.filteredReporting){
            let tempItem = {}
            let image = this.getValue(item.formulaires, 'image')
            tempItem["Numéro"] = item.id;
            tempItem["Date"] = this.secToDateTime(item.date.seconds);
            tempItem["Usager"] = this.getValue(item.coordonnees, 'Nom');
            tempItem["Adresse"] = this.getValue(item.formulaires, 'adresse');
            let [city, postal] = this.extractInfo(tempItem["Adresse"])
            tempItem["Ville"] = city ?? ""
            tempItem["Code postal"] = postal ?? ""
            tempItem["Motif"] = item.type;
            for (let key in this.checksMotifs) {
                tempItem[key] = this.getMotif(item, key)
            }
            tempItem["Commentaire du demandeur"] =  this.getValue(item.formulaires, 'Message');
            tempItem["Etat"] = this.getStatusName(item.status);
            tempItem["Photo"] = await this.getUrlImage(image);
            tempItem["Email demandeur"] =  this.getValue(item.coordonnees, 'Email');
            tempItem["Telephone demandeur"] =  this.getValue(item.coordonnees, 'Téléphone');
            tempItem["Date cloture"] = this.timeConverter(item.dateFin);
            tempItem["Nom du soldeur"] = item.soldeur;
            tempItem["Commentaire du soldeur"] = this.getCommentaire(item.commentaire);
            arrayItem.push(tempItem)
        }
        var workSheet = XLSX.utils.json_to_sheet(arrayItem);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, workSheet, 'signalements')
        XLSX.writeFile(wb, 'signalements.xlsx')
    }

}

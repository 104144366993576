var arrayBufferToBuffer = require('arraybuffer-to-buffer');
var path = require("path")
var shapefile = require("shapefile");
var moment = require('moment')
var polyline = require("google-polyline");
var JSZip = require("jszip");

let modifiedDataOM = [];
let modifiedDataCS = [];
let modifiedDataDV = [];
let modifiedDataEV = [];
let modifiedDataDA = [];
let modifiedDataOE = [];
var strImageOM = ""
var strImageCS = ""
var strImageDA = ""
var strImageDV = ""
var strImageEV = ""
var strImageOE = ""
let bigJson;
let entriesBuff;
let strCodeModified;

//Function who encode [lat, long] with google polyline
function encodeCoordinates(polygone) {
  var encoded = polyline.encode(polygone);
  return encoded;
}

function createPolygon(listLongLat) {
    var listLatLong = [];
    for (let i = 0; i < listLongLat.length; ++i) {
        let long = listLongLat[i][0]
        let lat = listLongLat[i][1]
        listLatLong.push([lat, long])
    }
    return encodeCoordinates(listLatLong)
}

// Function who reverse coodinates from one object data and call encodeCoordinated
function reverseCoordinates(obj) {
  var polygoneEncoded = [];
  //console.log(obj)
  if (obj.geometry != null) {
    for (let x = 0; x < obj.geometry.coordinates.length; x++) {
      //loop who check polygones
      for (let y = 0; y < obj.geometry.coordinates[x].length; y++) {
        //loop who check line by line polygone, like [long, lat] and reverse them
        if (typeof obj.geometry.coordinates[x][y][0] === "number") {
          polygoneEncoded.push(createPolygon(obj.geometry.coordinates[x]));
          break;
        }
        polygoneEncoded.push(createPolygon(obj.geometry.coordinates[x][y]));
      }
    }
    return polygoneEncoded;
  } else {
    return null;
  }
}

//Function who format json and call reverseCoordinates
function formatJson(obj, strCode, i) {

    const formDaysPerMonth =  {
        "1er" : "1er",
        "2e" : "2ème",
        "3e": "3ème",
        "4e": "4ème"
    }

    var strJours = obj.properties.JOUR ? obj.properties.JOUR.toLowerCase() : ""
    var comment = obj.properties.CONSIGNE ? obj.properties.CONSIGNE : ""
    var daypermonth = ""
    var freq = "Hebdomadaire"
    var period = ""
    var periodType = "Toute l'année"
    var lundi = false
    var mardi = false
    var mercredi = false
    var jeudi = false
    var vendredi = false
    var samedi = false
    var dimanche = false

    if (strCode === "om") {
        strCodeModified = "Ordures ménagères"
    }
    if (strCode.includes("cs")) {
        strCodeModified = "Emballages recyclables"
    }
    if (strCode.includes("dv")) {
        strCodeModified = "Déchets végétaux"
    }
    if (strCode.includes("ev")) {
        strCodeModified = "Emballages en verre"
    }
    if (strCode.includes("da")) {
        strCodeModified = "Déchets organiques"
    }
    if (strCode.includes("oe")) {
        strCodeModified = "Encombrants"
    }

    if (obj.properties.FREQUENCE == "AV") {
        return {
            infos: {
                a_publier: true,
                btn_pav : false,
                commentaire: obj.properties.JOUR ? obj.properties.JOUR : "",
                daysPerMonth : daypermonth,
                description: "",
                fluxCarte: "",
                fluxCollecte: strCodeModified,
                frequence: freq,
                horaires: "",
                jours: {
                    dimanche: dimanche,
                    jeudi: jeudi,
                    lundi: lundi,
                    mardi: mardi,
                    mercredi: mercredi,
                    samedi: samedi,
                    vendredi: vendredi
                },
                libelleBouton : "",
                nomPolygone: `${strCode.toUpperCase()}${i}`, // A adapter en fonction du type du produit
                periode : period,
                periodeType: periodType
            },
            polygones: reverseCoordinates(obj)
        }
    }

    if (strJours.includes("lundi")) {
        lundi = true;
    }
    if (strJours.includes("mardi")) {
        mardi = true;
    }
    if (strJours.includes("mercredi")) {
        mercredi = true;
    }
    if (strJours.includes("jeudi")) {
        jeudi = true;
    }
    if (strJours.includes("vendredi")) {
        vendredi = true;
    }
    if (strJours.includes("samedi")) {
        samedi = true;
    }
    if (strJours.includes("dimanche")) {
        dimanche = true;
    }

    if(/(\b.*\ble\b.*\bdu\b.*\bau\b.*)/.test(strJours) === true){
        periodType = "Personnalisée"
        let str = [...strJours.matchAll(/(\d{1,2})\s+(jan(?:vier)?|fev(?:rier)?|mar(?:s)?|avr(?:il)?|mai|juin|jui(?:llet)?|aout|sep(?:tembre)?|oct(?:obre)?|nov(?:embre)?|dec(?:embre)?)/g)]
        let stringDateStart = str[0][0]
        let stringDateEnd = str[1][0]
        var dateStart = new Date(stringDateStart + " 2021")
        let dateEnd = new Date(stringDateEnd + " 2021")
        if (dateStart > dateEnd) {
            dateEnd = new Date(stringDateEnd + " 2022")
        }
        let date = `Du ${moment(dateStart).format('DD/MM/YYYY')} au ${moment(dateEnd).format('DD/MM/YYYY')}`
        period = date
    }

    if(/(\b.*\bles\b.*\b\de.\b.*\b\de\b.*\bde\b.*)/.test(strJours) === true){
        freq = "Bi-mensuelle"
        let str = [...strJours.matchAll(/(\der|\de)/g)]
        var num1 = str[0][0]
        var num2 = str[1][0]
        num1 = formDaysPerMonth[num1]
        num2 = formDaysPerMonth[num2]
        daypermonth = `Tous les ${num1} et ${num2} du mois`
    }

    return {
        infos: {
            a_publier: true,
            btn_pav : false,
            commentaire: comment,
            daysPerMonth : daypermonth,
            description: "",
            fluxCarte: "",
            fluxCollecte: strCodeModified,
            frequence: freq,
            horaires: "",
            jours: {
            dimanche: dimanche,
            jeudi: jeudi,
            lundi: lundi,
            mardi: mardi,
            mercredi: mercredi,
            samedi: samedi,
            vendredi: vendredi,
            },
            libelleBouton : "",
            nomPolygone: `${strCode.toUpperCase()}${i}`, // A adapter en fonction du type du produit
            periode : period,
            periodeType: periodType
        },
        polygones: reverseCoordinates(obj),
        }
}

// Function who transform shape data to json data, and transform json with good format. Call formatJson
async function shape2json(zip, file) {
    var strCode = file.name.split(".")[0].slice(-2).toLowerCase(); //Get type of data (OM, CS, OE...)
    if (["om", "cs", "dv", "ev", "da", "oe"].findIndex(x => x == strCode) == -1) { // Only known types continue execution
      return
    }
  
    var shpData = await zip.file(file.name).async("uint8array")
    var dbfData = await zip.file(file.name.replace(".shp",".dbf")).async("uint8array")
  
    let i = 1
    let source = await shapefile.open(shpData, dbfData, {encoding: "utf-8"})
    var result = await source.read()
    while (!result.done) {
        const data = result.value;
        let test = formatJson(data, strCode, i)
        if(test.polygones != null){
            if (strCode.includes("om")) {
                strCodeModified = "Ordures ménagères";
                strImageOM = "041-flux-omr.png"
                modifiedDataOM.push(test)
              }
              if (strCode.includes("cs")) {
                strCodeModified = "Emballages recyclables";
                strImageCS = "042-flux-recyclables.png"
                modifiedDataCS.push(test)
              }
              if (strCode.includes("dv")) {
                strCodeModified = "Déchets végétaux";
                strImageDV = "054-flux-vegetaux.png"
                modifiedDataDV.push(test)
              }
              if (strCode.includes("ev")) {
                strCodeModified = "Emballages en verre";
                strImageEV = "043-flux-verre.png"
                modifiedDataEV.push(test)
              }
              if (strCode.includes("da")) {
                strCodeModified = "Déchets organiques";
                strImageDA = "046-flux-organique.png"
                modifiedDataDA.push(test)
              }
              if (strCode.includes("oe")) {
                strCodeModified = "Encombrants";
                strImageOE = "053-flux-encombrants.png"
                modifiedDataOE.push(test)
              }
        }
        i += 1
        result = await source.read()
    }
  
}

exports.zip2json = async function(filezip) {
  let arrayBuffer = await filezip.arrayBuffer()
  let buffer = arrayBufferToBuffer(arrayBuffer)
  var zip = new JSZip();
  zip = await zip.loadAsync(buffer)
  let i = 0
  for (const [key, value] of Object.entries(zip.files)) {
    if (value.name.endsWith(".shp")) {
      i += 1
      await shape2json(zip, value)
    }
  }

  var headOM = {
      url: "https://www.est-ensemble.fr/sites/default/files/memo_tri.pdf",
      texte: "Vous pouvez déposer vos emballages dans les points de proximité adaptés.",
      flux: "Ordures ménagères",
      image: strImageOM,
      nom: strCodeModified,
      zones: modifiedDataOM,
      libelleBouton: "Trouver un point de proximité",
    };
  var headCS = {
      libelleBouton: "Trouver un point de proximité",
      texte: "Vous pouvez déposer vos emballages dans un point de proximité adapté",
      url: "https://www.est-ensemble.fr/sites/default/files/memo_tri.pdf",
      flux: "Emballages recyclables",
      image: strImageCS,
      nom: strCodeModified,
      zones: modifiedDataCS,
    };
  var headDV = {
      texte: "Si vous ne bénéficiez pas d'une collecte en porte à porte ou si elle est interrompue vous pouvez compostez vos déchets végétaux. Plus de renseignements sur https://www.est-ensemble.fr/composter ou au +33 805 055 055.  Vous pouvez également les apporter en déchèterie.",
      image: strImageDV,
      nom: strCodeModified,
      zones: modifiedDataDV,
      flux: "Déchetteries",
      libelleBouton: "Trouver une déchèterie"
    };
  var headEV = {
      url: "https://www.est-ensemble.fr/sites/default/files/memo_tri.pdf",
      image: strImageEV,
      nom: strCodeModified,
      texte: "Vous pouvez déposer vos emballages dans un point de proximité adapté.",
      libelleBouton: "Trouver un point de proximité",
      flux: "Emballages en verre",
      zones: modifiedDataEV,
    };
  var headDA = {
      image: strImageDA,
      texte: "Pas de collecte à cette adresse",
      url: "https://www.est-ensemble.fr/sites/default/files/memo_tri.pdf",
      libelleBouton: "Trouver un point de proximité",
      nom: strCodeModified,
      flux: "Sites de compostage",
      zones: modifiedDataDA,
    };
  var headOE = {
      libelleBouton: "Trouver une déchèterie",
      texte: "Merci de vous rendre en déchèterie.",
      image: strImageOE,
      nom: strCodeModified,
      flux: "Déchetteries",
      zones: modifiedDataOE,
    };
    let data;
    if(i > 0){
      data = {flux:[headOM, headCS, headDV, headEV, headDA, headOE]};
    }else{
      data = {error: true}
    }
    
    return data;
}
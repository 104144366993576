<div class="container mt-5 d-flex flex-column justify-content-center align-items-center">
    <img src="assets/img/montri_logo.png" class="montri_logo">
    <h5 class="h1 mt-3" style="text-align: center;">Bienvenue,<br /> Veuillez vous inscrire</h5>
    <div class="row d-flex flex-column justify-content-center align-items-center" style="width: 100%;">
        <form class="mt-3"  [formGroup]="SignUpForm" (ngSubmit)="onSubmit(SignUpForm.value)" style="width: 300px;">
            <div class="form-group has-feedback" style="width: 100%;">
                <input autocomplete="off" style="width: 100%;" type="email" class="form-control input_form p2" (focus)="mailError = false;error=false" style="font-family:Arial, FontAwesome" ngClass="{{mailError ? 'colorerrorinput' : 'colornoterrorinput'}}" autocomplete="off"   placeholder="{{mailError ?'&#xf071; Champs obligatoire':'Adresse email de l\'invitation'}}"
                    id="email_signup" aria-describedby="emailHelp" formControlName="email_signup">
            </div>

            <div class="form-group has-feedback" style="width: 100%;">
                <input autocomplete="off" type="text" style="width: 100%;" class="form-control input_form p2" (focus)="codeError = false;error=false" style="font-family:Arial, FontAwesome" ngClass="{{codeError ? 'colorerrorinput' : 'colornoterrorinput'}}" autocomplete="off"   placeholder="{{codeError ?'&#xf071; Champs obligatoire':'Code d\'invitation'}}"
                    id="code_singup" placeholder="Code d'invitation" formControlName="code_signup">
            </div>
            <div class="form-check">
                <input class="form-check-input" type="checkbox" id="flexCheckDefault" [(ngModel)]="isChecked" (click)="checkCheckBox()" (focus)="cguError = false;error=false" style="font-family:Arial, FontAwesome" ngClass="{{cguError ? 'cgucolorerrorinput' : 'cgucolornoterrorinput'}}" autocomplete="off"
                 formControlName="cgu_signup">
                <label class="form-check-label" for="flexCheckDefault">
                  J'ai pris connaissance du <a target="_blank" rel="noopener noreferrer" href="https://www.uzer.eu/code-de-bonne-conduite-montri">Code de bonne conduite</a>
                </label>
              </div>

            <p class="pb-0 mb-0 h3 mt-3 mb-3" *ngIf="userExist == false" style="text-align: center;">Pour continuer, <br />veuillez créer votre mot de passe</p>

            <p class="pb-0 mb-0 h3 mt-3 mb-3" *ngIf="userExist == true" style="text-align: center;">Vous avez déjà un compte via l’application mobile, <br />Connectez-vous à votre compte en renseignant votre mot de passe.</p>

            <div class="form-group has-feedback" style="width: 100%;">
                <input autocomplete="off" type="password" style="width: 100%;" *ngIf="userExist != null" class="form-control input_form p2" (focus)="passwordError = false;error=false" style="font-family:Arial, FontAwesome" ngClass="{{passwordError ? 'colorerrorinput' : 'colornoterrorinput'}}" autocomplete="off"   placeholder="{{passwordError ?'&#xf071; Champs obligatoire':'Mot de passe'}}"
                    id="password_singup" placeholder="Mot de passe" formControlName="password_signup">
            </div>

            <div class="form-group has-feedback" style="width: 100%;">
                <input autocomplete="off" type="password" style="width: 100%;" *ngIf="userExist == false"  class="form-control input_form p2"  (focus)="confirmpasswordError = false;error=false" style="font-family:Arial, FontAwesome" ngClass="{{confirmpasswordError ? 'colorerrorinput' : 'colornoterrorinput'}}" autocomplete="off"   placeholder="{{confirmpasswordError ?'&#xf071; Champs obligatoire':'Confirmez votre mot de passe'}}"
                    id="confirm_password_singup" placeholder="Mot de passe" formControlName="confirm_password_signup">
            </div>

            <div class="col-lg-12 mt-5 mb-3  d-flex flex-row justify-content-center align-items-center error_small pb-0" *ngIf="mailError||passwordError||confirmpasswordError">
                <i class="fa fa-exclamation-triangle fa-1x" aria-hidden="true" style="width: 30px;height: 30px;"></i>
                <p class="h-100 d-flex flex-row  mb-0 pb-0 justify-content-center align-items-end" style="text-align: center;">Certains champs obligatoires ne sont pas remplis</p>
            </div>

            <div class="col-lg-12 mt-3 mb-3  d-flex flex-row justify-content-center align-items-center error_small pb-0"  *ngIf="userExist != null">
                <p class="h-100 d-flex flex-row  mb-0 pb-0 justify-content-center align-items-center" style="text-align: center; color: #000000">Votre mot de passe doit contenir au minimum 8 caractères, dont une majuscule, une minuscule, un chiffre et un caractère spécial</p>
            </div>

            <div class="col-lg-12 mt-3 mb-3  d-flex flex-row justify-content-center align-items-center error_small_red pb-0" *ngIf="error">
                <i class="fa fa-times-circle fa-1x" aria-hidden="true" style="width: 30px;height: 30px;"></i>
                <p class="h-100 d-flex flex-row  mb-0 pb-0 justify-content-center align-items-end" style="text-align: center;">Email ou mot de passe incorrect. Veuillez verifier que votre mot de passe contient au minimum 8 caractères, dont une majuscule, une minuscule, un chiffre et un caractère spécial puis réessayez</p>
            </div>



            <button type="submit"  style="width: 100%;" class="btn button_form button1 w-100" id="Login_btn">M'inscrire</button>
        </form>

    </div>
</div>

import { FirebaseAnalytics, FirebaseStorage } from "angularfire2"
import { firestore } from "firebase"

export class Report {
    email : string
    message : string
    nom : string
    numero :  string
    rue : string
    telephone : string
    type : string
    ville : string
    idContrat : string
    date : any
    status : string
    gid : string
    id : string
    image? : string
    coordonne? : Coordonne
    coordonnees? : any
    formulaires? : any
    adresse ? : string
    prenom ? : string
    motif ? : string
    commentaire ? : string
    soldeur ? : string
    dateFin ? : any
    destinataire?: string
    historique?: any[]
    messages?: Message[]
    majBO?: boolean
    maj?: boolean
    dateEnCours? : firestore.Timestamp
}

export class Message {
    expediteur: number = 0
    message: string = ""
    date: firestore.Timestamp = firestore.Timestamp.now()
    email?: string
}

// export class Historique{
// 	date:string
//     email:string
//     nom:string
//     image:string
//     action:string
//     commmentaire: string
// }

export class Coordonne{
	latitude: number
	longitude: number
}


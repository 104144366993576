import { SliderService } from './../../../../services/slider/slider.service';
import { Service } from './../../../../models/contrat/contrat';
import { AlertService } from './../../../../services/alert/alert.service';
import { LodingBarService } from './../../../../services/lodingbar/loding-bar-service.service';
import { ContratService } from './../../../../services/contrat/contrat.service';
import { Contrat } from 'src/app/models/contrat/contrat';
import { Component, OnInit, Renderer2, AfterContentChecked, Input } from '@angular/core';

@Component({
    selector: 'app-mobileslider',
    templateUrl: './mobileslider.component.html',
    styleUrls: ['./mobileslider.component.scss']
})
export class MobilesliderComponent implements OnInit{

    contrat : Contrat;
    counter : number = 1;
    current_slide : Service;
    slide_services : Service [];
    @Input() splashscreen : string = "";
    @Input() services: Service [];

    constructor(
        private contratservice : ContratService,
        private lodingbarservice: LodingBarService,
        private alertservice: AlertService,
        private render : Renderer2,
        private sliderService : SliderService,
    ) { }

    ngOnInit(): void {
        this.contratservice.contrat_obs.subscribe((contrat)=>{
            this.contrat =  contrat
            this.slide_services = this.services.filter(service=>service.slider == true && service.active==true && service.titre!=""&& service.description1!=""&&service.description2!="")
            this.current_slide = this.slide_services[0]
            this.svg_textMultiline(this.current_slide.description1)
            this.svg_textMultiline2(this.current_slide.description1)
            this.svg_textMultiline3(this.current_slide.titre)

        })

    }


    slideRight(){
        if  (this.counter+1<this.slide_services.length-1){
            this.counter = this.counter+ 1
            this.current_slide =   this.slide_services[this.counter]
            this.svg_textMultiline(this.current_slide.description1)
            this.svg_textMultiline2(this.current_slide.description1)
            this.svg_textMultiline3(this.current_slide.titre)
        }

    }

    slideLeft(){

        if  (this.counter>0){
            this.counter = this.counter- 1
            this.current_slide =   this.slide_services[this.counter]
            this.svg_textMultiline(this.current_slide.description1)
            this.svg_textMultiline2(this.current_slide.description1)
            this.svg_textMultiline3(this.current_slide.titre)
        }

    }

    svg_textMultiline(text : string) {

        var x = 0;
        var y = 20;
        var width = 360;
        var lineHeight = 10;



        /* get the text */
        var element = document.getElementById('description1');


        /* split the words into array */
        var words = text.split(' ');
        var line = '';

        /* Make a tspan for testing */
        element.innerHTML = '<tspan id="PROCESSING">busy</tspan >';

        for (var n = 0; n < words.length; n++) {
          var testLine = line + words[n] + ' ';
          var testElem = document.getElementById('PROCESSING');
          /*  Add line in testElement */
          testElem.innerHTML = testLine;
          /* Messure textElement */
          var metrics = testElem.getBoundingClientRect();
          var testWidth = metrics.width;

          if (testLine.length > 45 && n > 0) {
            element.innerHTML += '<tspan x="40" dy="' + y + '">' + line + '</tspan>';
            line = words[n] + ' ';
          } else {
            line = testLine;
          }
        }

        element.innerHTML += '<tspan x="40" dy="' + y + '">' + line + '</tspan>';
        document.getElementById("PROCESSING").remove();

      }

      svg_textMultiline2(text : string) {

        var x = 0;
        var y = 20;
        var width = 360;
        var lineHeight = 10;



        /* get the text */
        var element = document.getElementById('description2');


        /* split the words into array */
        var words = text.split(' ');
        var line = '';

        /* Make a tspan for testing */
        element.innerHTML = '<tspan id="PROCESSING">busy</tspan >';

        for (var n = 0; n < words.length; n++) {
          var testLine = line + words[n] + ' ';
          var testElem = document.getElementById('PROCESSING');
          /*  Add line in testElement */
          testElem.innerHTML = testLine;
          /* Messure textElement */
          var metrics = testElem.getBoundingClientRect();
          var testWidth = metrics.width;

          if (testLine.length > 45 && n > 0) {
            element.innerHTML += '<tspan x="40" dy="' + y + '">' + line + '</tspan>';
            line = words[n] + ' ';
          } else {
            line = testLine;
          }
        }

        element.innerHTML += '<tspan x="40" dy="' + y + '">' + line + '</tspan>';
        document.getElementById("PROCESSING").remove();

      }

      svg_textMultiline3(text : string) {

        var x = 0;
        var y = 20;
        var width = 360;
        var lineHeight = 10;



        /* get the text */
        var element = document.getElementById('titre');


        /* split the words into array */
        var words = text.split(' ');
        var line = '';

        /* Make a tspan for testing */
        element.innerHTML = '<tspan id="PROCESSING">busy</tspan >';

        for (var n = 0; n < words.length; n++) {
          var testLine = line + words[n] + ' ';
          var testElem = document.getElementById('PROCESSING');
          /*  Add line in testElement */
          testElem.innerHTML = testLine;
          /* Messure textElement */
          var metrics = testElem.getBoundingClientRect();
          var testWidth = metrics.width;

          if (testLine.length > 26 && n > 0) {
            element.innerHTML += '<tspan x="40" dy="' + y + '">' + line + '</tspan>';
            line = words[n] + ' ';
          } else {
            line = testLine;
          }
        }

        element.innerHTML += '<tspan x="40" dy="' + y + '">' + line + '</tspan>';
        document.getElementById("PROCESSING").remove();

      }

}

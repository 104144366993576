import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AlertssettingsRoutingModule } from './alertssettings-routing.module';
import { AlertssettingsComponent } from './components/alertssettings/alertssettings.component';
import { DeletealertComponent } from './modals/deletealert/deletealert.component';
import { AlertinfosComponent } from './modals/alertinfos/alertinfos.component';


@NgModule({
  declarations: [AlertssettingsComponent, DeletealertComponent, AlertinfosComponent],
  imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        NgbModule,
        AngularSvgIconModule.forRoot(),
        AlertssettingsRoutingModule
    ]
})
export class AlertssettingsModule { }

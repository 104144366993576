import { Invit } from './../../models/invit/invit';
import { UserService } from './../user/user.service';
import { UserBo } from './../../models/user/user';
import { Alert } from './../../models/alert/alert';
import { AlertService } from './../alert/alert.service';
import { LodingBarService } from './../lodingbar/loding-bar-service.service';
import { Injectable } from '@angular/core';
import { AngularFirestore, validateEventsArray } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { User } from 'firebase';
import * as firebase from 'firebase';
import { map, first } from 'rxjs/operators';
import { error } from 'protractor';
import { ToastAlertsComponent } from 'src/app/modules/main/components/toast-alerts/toast-alerts.component';

@Injectable({
    providedIn: 'root',
})
export class AuthentificationService {
    userData: User;

    constructor(
        public afs: AngularFirestore, // Inject Firestore service
        public afAuth: AngularFireAuth, // Inject Firebase auth service
        public router: Router,
        private lodingBarService: LodingBarService,
        private alertservices: AlertService,
        private userService: UserService,
        private toastr: ToastAlertsComponent
    ) {
        /* Saving user data in localstorage when
        logged in and setting up null when logged out */
        this.afAuth.authState.subscribe((user) => {
            if (user) {
                this.userData = user;
                localStorage.setItem('user', JSON.stringify(this.userData));
                JSON.parse(localStorage.getItem('user'));
            } else {
                localStorage.setItem('user', null);
                JSON.parse(localStorage.getItem('user'));
            }
        });
    }

    signUp(email, password,id_invit) {
        var self = this;
        this.afAuth.signOut().then((result) => {

            this.userService.removeCurrentUser();
            self.afAuth
                .createUserWithEmailAndPassword(email, password)
                .then((result) => {
                    var userBo = new UserBo();
                    userBo.email = email;
                    userBo.configStatus = false;
                    userBo.invits = [];
                    userBo.idContrat = ""
                    userBo.listContrats =[]
                    self.afAuth.authState.subscribe((user_auth) => {
                        var reciveruid = user_auth.uid;
                        self.afs.collection('usersBo').doc(user_auth.uid).set(JSON.parse(JSON.stringify(userBo)))
                            .then(function () {
                                let doc = self.afs.collection('invitsBo').doc(id_invit).ref.get().then(function (doc) {
                                        if (doc.exists) {
                                            var data_invit = doc.data() as Invit;
                                            userBo.listContrats.push({
                                                idContrat : data_invit.id_contrat,
                                                nomContrat : data_invit.nom_contrat
                                            })
                                            self.afs.collection('usersBo').doc(reciveruid as string).update({role: data_invit.reciver_role,listContrats : userBo.listContrats}).then(function () {
                                                    self.afs.collection('invitsBo').doc(id_invit as string).update({is_accepted: true,}).then(function () {
                                                                self.afs.collection('usersBo').doc(user_auth.uid as string).update({idContrat: data_invit.id_contrat}).then(function () {
                                                                    self.lodingBarService.setStatus(false);
                                                                    if(data_invit.reciver_role.indexOf('Agent') > -1){
                                                                        self.router.navigateByUrl('/montri/reportingstats')
                                                                    }else{
                                                                        self.router.navigateByUrl('/montri/welcome');
                                                                    }
                                                                })
                                                                .catch((error) => {
                                                                            self.toastr.openFailedToast('Création du compte échouée, veuillez réessayer', "")
                                                                            self.lodingBarService.setStatus(
                                                                                false
                                                                            );
                                                                            self.logout();
                                                                        }
                                                                    );

                                                                ;
                                                            }
                                                        )
                                                        .catch(
                                                            (
                                                                error
                                                            ) => {
                                                                self.toastr.openFailedToast('Création du compte échouée, veuillez réessayer', "")
                                                                self.lodingBarService.setStatus(
                                                                    false
                                                                );
                                                                self.logout();
                                                            }
                                                        );
                                                })
                                                .catch((error) => {
                                                    self.toastr.openFailedToast('Création du compte échouée, veuillez réessayer', "")
                                                    self.lodingBarService.setStatus(
                                                        false
                                                    );
                                                    self.logout();
                                                });
                                        } else {
                                            self.toastr.openFailedToast('Création du compte échouée, veuillez réessayer', "")
                                            self.lodingBarService.setStatus(
                                                false
                                            );
                                            self.logout();
                                        }
                                    });
                            });
                    });
                });
        })
    }



    signInWithInvit(email, password ,id_invit) {
        var self = this;
        var userBo = new UserBo();
        userBo.email = email;
        userBo.invits = [];
        this.userService.user.next(userBo)
        this.afAuth.signInWithEmailAndPassword(email, password).then((result) => {
            self.afs.collection('invitsBo').doc(id_invit).ref.get().then(function (doc) {
                if (doc.exists) {
                    var data_invit = doc.data() as Invit;

                    userBo.idContrat = data_invit.id_contrat;
                    userBo.role = data_invit.reciver_role;
                    userBo.configStatus = false;
                    userBo.listContrats = []
                    userBo.listContrats.push({
                        "idContrat" : data_invit.id_contrat,
                        "nomContrat" : data_invit.nom_contrat
                    })
                    self.afAuth.authState.subscribe((user_auth) => {
                        var reciveruid = user_auth.uid;

                        self.afs.collection('usersBo').doc(user_auth.uid).set(JSON.parse(JSON.stringify(userBo)))
                            .then(function () {

                                self.afs.collection('invitsBo').doc(id_invit as string).update({is_accepted: true,}).then(function () {

                                    self.lodingBarService.setStatus(false);
                                    if(data_invit.reciver_role.indexOf('Agent') > -1){
                                        self.router.navigateByUrl('/montri/reportingstats')
                                    }else{
                                        self.router.navigateByUrl('/montri/welcome');
                                    }
                                })
                                .catch((error) => {
                                        self.toastr.openFailedToast('Création du compte échouée, veuillez réessayer', "")
                                        self.lodingBarService.setStatus(
                                            false
                                        );
                                        self.logout();
                                    }
                                );
                            })
                            .catch((error) => {
                                self.toastr.openFailedToast('Création du compte échouée, veuillez réessayer', "")
                                self.lodingBarService.setStatus(false);
                                self.logout();
                            });
                    })
                }
                else {
                    self.toastr.openFailedToast('Création du compte échouée, veuillez réessayer', "")
                    self.lodingBarService.setStatus(false);
                    self.logout();
                }
            })
            .catch((error) => {
                    self.toastr.openFailedToast('Création du compte échouée, veuillez réessayer', "")
                    self.lodingBarService.setStatus(false);
                    self.logout();
            });
        })
        .catch((error) => {
                self.toastr.openFailedToast('Création du compte échouée, veuillez réessayer', "")
                self.lodingBarService.setStatus(false);
                self.logout();
        });
    }


    signIn(email, password) {
        var self = this;
        this.afAuth
            .signInWithEmailAndPassword(email, password)
            .then((result) => {
                self.afAuth.authState.subscribe((user_auth) => {
                    self.afs.collection('usersBo').doc(user_auth.uid).ref.get().then(function (doc) {
                        var user = doc.data() as UserBo;
                        self.lodingBarService.setStatus(false)
                        if(user.role.indexOf('Agent') > -1){
                            self.router.navigateByUrl('/montri/reportingstats')
                        }else{
                            self.router.navigateByUrl('/montri/welcome');
                        }
                    })
                })                 
            })
            .catch((error) => {
                this.toastr.openFailedToast('Connexion echouée, veuillez réessayer', "")
                this.lodingBarService.setStatus(false);
            });
    }

    logout() {
        const user = this.afAuth.currentUser;
        this.afAuth.signOut().then((result) => {
            const user = this.afAuth.currentUser;
            this.userService.removeCurrentUser();
            this.router.navigateByUrl('/user/signin');
        });
    }

    isLoggedIn() {
        return this.afAuth.authState.pipe(first())
    }

    getAuth() {
        return this.afAuth;
    }

    /**
     * Initiate the password reset process for this user
     * @param email email of the user
     */
    resetPasswordInit(email: string) {
        return this.afAuth.sendPasswordResetEmail(
            email);
    }
}

import { Observable } from "rxjs";
import { BehaviorSubject } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable({
	providedIn: "root",
})
export class LodingBarService {
	private status: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
	constructor() { }

	getStatus(): Observable<any> {
		return this.status.asObservable();
	}

	setStatus(input: boolean) {
		this.status.next(input);
	}
}


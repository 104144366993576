<div class="container">
    <div style="display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 70vh;">
        <img src="../../../../../assets/img/001-navigation-checkdone-icon.svg" style="width: 120px;margin-bottom: 20px;">
        <div>
            <h5>
                La demande a bien été <br style="margin-bottom: 10px;size: 100px;">
                mise à jour
            </h5>
        </div>
    </div>
</div>

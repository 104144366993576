<div class="container mt-5 d-flex flex-column justify-content-center align-items-center">
    <img src="assets/img/montri_logo.png" class="montri_logo">
    <h5 class="h3 mt-3" style="text-align: center;">Changez<br /> votre mot
        de passe
        </h5>
    <div class="row d-flex flex-column justify-content-center align-items-center" style="width: 100%;">
        <form class="mt-3" style="width: 300px;"  [formGroup]="PasswordForm" (ngSubmit)="onSubmit(PasswordForm.value)" >
            <div class="form-group has-feedback" style="width: 100%;">
                <input autocomplete="off" style="width: 100%;" type="password" class="form-control input_form p2"
                    id="email_signin" aria-describedby="emailHelp" formControlName="old_password" placeholder="Ancien mot de passe">
            </div>

            <div class="form-group has-feedback" style="width: 100%;">
                <input autocomplete="off" type="password" style="width: 100%;" class="form-control input_form p2"
                    id="new_password" placeholder="Nouveau mot de passe" formControlName="new_password">
            </div>

            <button type="submit"  style="width: 100%;" class="btn button_form button1 mt-3 w-100"
                id="Login_btn">Valider</button>
        </form>
    </div>

</div>

<div class="row">
    <div class="col-12 d-flex flex-column justify-content-center align-items-lg-start align-items-center">
        <p class="pb-0 mb-0  h3">Activez les flux que vous souhaitez afficher sur la carte :</p>
        <p class="pb-0 mb-0 mt-0 p2">Activez ou déasctivez à tout moment les flux à afficher. Renseignez la couleur de chaque flux, et sélectionnez le flux par défaut à afficher à l'ouverture de la carte.</p>
    </div>
</div>

<div *ngIf="flux!=undefined &&user!=undefined" class="mt-5">

    <div class="row mt-5 mt-lg-2 "  *ngFor="let item of flux  ; index as i">
        <div class="col-lg-1 d-flex flex-row justify-content-lg-start justify-content-center align-items-center" style="max-width: 14.667% !important;">
            <div class="btn-group" role="group" id={{item.nom}} >
                <input type="radio"  name="{{item.nom}}" [value]="true" [(ngModel)]="item.active" (ngModelChange)="onChange($event,i)" id="{{'radiobtoui'+ i}}"  [disabled]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')"/>
                <label class="btn btn-secondary radius_left" id="{{'radiooui'+ i}}" for="{{'radiobtoui'+ i}}" [ngStyle]="{'cursor' :  ((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification') ? 'not-allowed' : 'pointer' }" >
                    Oui
                </label>
                <input type="radio"   [value]="false" [(ngModel)]="item.active" (click)="removeDefaut(i)" (ngModelChange)="onChange($event,i)"   name="{{item.nom}}"  class="radio_input radio_non"   id="{{'radiobtnon'+ i}}" [disabled]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')"/>
                <label  class="btn btn-secondary radius_right" id="{{'radionon'+ i}}"   for="{{'radiobtnon'+ i}}" [ngStyle]="{'cursor' :  ((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification') ? 'not-allowed' : 'pointer' }">
                    Non
                </label>
            </div>
        </div>

        <div class="col-lg-2 ml-lg-2 p1  d-flex flex-row justify-content-lg-start justify-content-center align-items-center" style="max-width: 14.667% !important;">
                {{item.nom}}
        </div>

        <div class="col-lg-2 d-flex flex-row justify-content-lg-start justify-content-center align-items-center" style="max-width: 14.667% !important;">
                <input type="text" pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$" maxlength="7" [ngStyle]="{'cursor' :  ((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification') ? 'not-allowed' : null }" (focus)="item.error = false;addfirst(i);error_global = false" style="font-family:Arial, FontAwesome" ngClass="{{ item.error == true ? 'colorerrorinput' : 'colornoterrorinput'}}" [hidden]="item.active ? false : true"  placeholder="{{ item.error ?'&#xf071; '+item.colorErrorMsg :'Couleur'}}"   autocomplete="off"  [disabled]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')"  [(ngModel)]="item.couleur" (ngModelChange)="onChange($event,i)" class="form-control input1" required>
        </div>

        <div class="col-lg-1 mt-2 mt-lg-0 d-flex flex-row justify-content-lg-start justify-content-center align-items-center" style="max-width: 14.667% !important;">
            <div [ngStyle]="{'background-color':item.couleur}" [hidden]="((item.couleur.length == 7)&&(item.active))  ? false : true " id="{{'color'+i }}" style="height:30px;width:40px"></div>
        </div>

        <div class="col-lg-2 mt-2 mt-lg-0 d-flex flex-row justify-content-lg-start justify-content-center align-items-center" style="max-width: 14.667% !important;">
            <button class="btn  btn-primary btn-lg" [ngStyle]="{'cursor' :  ((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification') ? 'not-allowed' : null }" ngClass="{{ item.defaut == true ? 'button4' : 'button6'}}" [hidden]="item.active ? false : true" (click)="item.defaut = !item.defaut;select(i);error_global = false">Par défaut</button>
        </div>

        <ng-container *ngIf='item.active'>
            <div class="col-lg-2 d-flex flex-row justify-content-lg-start justify-content-center align-items-center" style="font-size: 14px;color: #707070;font-family: avenir_heavy;max-width: 11.6% !important;">
                Nom à afficher :
            </div>
        </ng-container>
       
        <div class="col-lg-2 d-flex flex-row justify-content-lg-start justify-content-center align-items-center" style="max-width: 14.667% !important;">
            <input type="text"  style="font-family:Arial, FontAwesome" ngClass="" [hidden]="item.active ? false : true" placeholder="{{ item.nom }}"   autocomplete="off" [ngStyle]="{'cursor' :  ((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification') ? 'not-allowed' : null }" [disabled]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')"  value="{{ item.displayName ? item.displayName : item.nom}}" (keyup)="onChangeDisplayName($event, item.nom)" class="form-control input1">
        </div>

    </div>
</div>


<div class="row mt-5 mb-4" *ngIf="flux!=undefined">
    <div class="col-lg-3 d-flex flex-row justify-content-center">
        <button type="button"  class="btn  btn-primary w-100 button2" routerLink="/montri/cartsettings/coordinate">Précédent</button>
    </div>
    <div class="col-lg-3 mt-3 mt-lg-0 d-flex flex-row justify-content-center">
        <button type="button" class="btn  btn-primary w-100  h3_heavy button4"  (click)="error_global ? null :saveData()" ngClass="{{ error_global ? 'opacity05':''}}">Suivant</button>
    </div>
</div>






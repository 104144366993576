import { scanAppEvolutionResponse } from './../../models/responses/response';
import { FuctionsqueryService } from './../functionsquery/fuctionsquery.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AppstatsService {

    constructor(
        private fqService :  FuctionsqueryService
    ) { }

    // users stats
    getUserNbr(contractName : string ){
        const params = new HttpParams().append('contractName',contractName)
        return this.fqService.onQueryGET('usersAppNumber',params)
    }

    getScanAppEvolution(contractName : string,date : string,type:string){
        const params = new HttpParams().set('contractName',contractName)
        .set('date',date)
        .set('type',type)
        return this.fqService.onQueryGET('scanAppEvolution',params)
    }

    getInstructionAppRecognition(contractName : string,date : string,type:string){
        const params = new HttpParams().set('contractName',contractName)
        .set('date',date)
        .set('type',type)
        return this.fqService.onQueryGET('instructionAppRecognition',params)
    }

    getProductAppRecognition(contractName : string,date : string,type:string){
        const params = new HttpParams().set('contractName',contractName)
        .set('date',date)
        .set('type',type)
        return this.fqService.onQueryGET('productAppRecognition',params)
    }

    getNewUsersAppEvolution(contractName : string,date : string,type:string){
        const params = new HttpParams().set('contractName',contractName)
        .set('date',date)
        .set('type',type)
        return this.fqService.onQueryGET('newUsersAppEvolution',params)
    }

    getAccountAppDistribution(contractName : string,date : string,type:string){
        const params = new HttpParams().set('contractName',contractName)
        .set('date',date)
        .set('type',type)
        return this.fqService.onQueryGET('accountAppDistribution',params)
    }

    getRetentionRate(contractName : string,date : string,type:string){
        const params = new HttpParams().set('contractName',contractName)
        .set('date',date)
        .set('type',type)
        return this.fqService.onQueryGET('retentionRate',params)
    }

    getUserAppActive(contractName : string,date : string,type:string){
        const params = new HttpParams().set('contractName',contractName)
        .set('date',date)
        .set('type',type)
        return this.fqService.onQueryGET('usersAppActive',params)
    }

    getScanAppNbr(contractName : string,date : string,type:string){
        const params = new HttpParams().set('contractName',contractName)
        .set('date',date)
        .set('type',type)
        return this.fqService.onQueryGET('scanAppNbr',params)
    }

    // flux  stats
    getRSTE(contractName : string,date : string,type:string){
        const params = new HttpParams().set('contractName',contractName)
        .set('date',date)
        .set('type',type)
        return this.fqService.onQueryGET('fluxStatsRSTE',params)
    }

    getRSTM(contractName : string,date : string,type:string){
        const params = new HttpParams().set('contractName',contractName)
        .set('date',date)
        .set('type',type)
        return this.fqService.onQueryGET('fluxStatsRTM',params)
    }

    getTTES(contractName : string,date : string,type:string){
        const params = new HttpParams().set('contractName',contractName)
        .set('date',date)
        .set('type',type)
        return this.fqService.onQueryGET('fluxStatsTTES',params)
    }

    getETT(contractName : string,date : string,type:string){
        const params = new HttpParams().set('contractName',contractName)
        .set('date',date)
        .set('type',type)
        return this.fqService.onQueryGET('fluxStatsETT',params)
    }


}

<div class="container-fluid mt-5 pb-5 mr-lg-2 ml-0">
    <div class="row ml-4  text_black text_heavy">
        <p class="h2">Conﬁgurez votre compte Utilisateur </p>
    </div>
    <div class="row ml-4 text_light mt-3 d-flex flex-row justify-content-start align-items-center"
        style="text-align: center;">
        <div class="img_user_photo" style="background-size: cover;"
            [ngStyle]="{'background-image': 'url('+ imageUrl+')'}">
            <input type="file" name="file" #fileInput id="file" class="inputfile"
                (change)="onChange(fileInput.files[0])" />
        </div>
        <label for="file"
            class="demi_cercle cercle_content d-flex flex-column justify-content-center align-items-center mb-0">
            <h6 class="h4 ml-3">Ajouter une photo</h6>
        </label>
    </div>
    <form class="mt-5 ml-4 " [formGroup]="UserForm" (ngSubmit)="onSubmit(UserForm.value)">
        <div class="form-row">
            <div class="col-lg-3">
                <label for="validationDefault01" class="p2">Prénom :</label>
                <input type="text" (focus)="prenomError = false" style="font-family:Arial, FontAwesome"
                    ngClass="{{prenomError ? 'colorerrorinput' : 'colornoterrorinput'}}" autocomplete="off"
                    placeholder="{{prenomError ?'&#xf071; Champs obligatoire':'Tapez ici'}}" autocomplete="off"
                    placeholder="Tapez ici" class="form-control input1" id="firstname_user"
                    formControlName="firstname_user" required>
            </div>

            <div class="col-lg-1"></div>

            <div class="col-lg-3">
                <label for="validationDefault01" class="p2">Nom :</label>
                <input type="text" (focus)="nomError = false" style="font-family:Arial, FontAwesome"
                    ngClass="{{nomError ? 'colorerrorinput' : 'colornoterrorinput'}}" autocomplete="off"
                    placeholder="{{nomError ?'&#xf071; Champs obligatoire':'Tapez ici'}}" autocomplete="off"
                    class="form-control input1" placeholder="Tapez ici" id="lastname_user"
                    formControlName="lastname_user" required>
            </div>
        </div>

        <div class="form-row mt-4">
            <div class="col-lg-3">
                <label for="validationDefault01" class="p2">Email :</label>
                <input type="text" (focus)="mailError = false" style="font-family:Arial, FontAwesome"
                    ngClass="{{mailError ? 'colorerrorinput' : 'colornoterrorinput'}}" autocomplete="off"
                    placeholder="{{mailError ?'&#xf071; Champs obligatoire':'Tapez ici'}}" autocomplete="off"
                    class="form-control input1" placeholder="Tapez ici" id="email_user" formControlName="email_user"
                    required>
            </div>

            <div class="col-lg-1"></div>

            <div class="col-lg-3">
                <label for="validationDefault01" class="p2">Téléphone :</label>
                <input type="text" (focus)="tlphError = false" style="font-family:Arial, FontAwesome"
                    ngClass="{{tlphError ? 'colorerrorinput' : 'colornoterrorinput'}}" autocomplete="off"
                    placeholder="{{tlphError ?'&#xf071; Champs obligatoire':'Tapez ici'}}" autocomplete="off"
                    class="form-control input1" placeholder="Tapez ici" id="phone_user" formControlName="phone_user"
                    required>
            </div>
        </div>





        <div class="row mt-5">
            <div class="col-lg-3 pr-2">
                <button type="button" type="submit" class="btn  btn-primary btn-lg btn-block  button4">Valider
                </button>
            </div>
            <div class="col-lg-6 mt-3 mt-lg-0 d-flex flex-row justify-content-center align-items-center error pb-0"
                *ngIf="
            prenomError||nomError||tlphError||mailError">
                <i class="fa fa-exclamation-triangle fa-2x" aria-hidden="true" style="width: 30px;height: 30px;"></i>
                <p class="ml-3 h-100 d-flex flex-row  mb-0 pb-0 justify-content-center align-items-end"
                    style="text-align: center;">Certains champs obligatoires ne sont pas remplis</p>
            </div>
        </div>
    </form>
    <div *ngIf="invits != null">
        <div *ngIf="userBo.role =='super_user'&&invits.length>0">
            <div class="row ml-4  mt-5 h4" *ngIf="invits.length>0">
                <img class="mr-2" src="./../../../../../assets/img/028-addelementleft.svg"
                    style="width: 20px;height: 20px;">
                Invitations envoyées :
            </div>
        </div>

        <div *ngIf="userBo.role =='super_user' && invits.length>0">
            <div class="row ml-4 mt-3 d-flex flex-row align-items-center" *ngFor="let invit of invits"
                style="border-left: 2px solid #f47000;">
                <div class="col-xl-3 text_light p1 d-flex flex-row justify-content-center justify-content-xl-start">
                    {{invit.reciver_email}}</div>
                <button type="button" class="btn btn-outline-secondary col-xl-2 mt-2 mt-xl-0  ml-2"
                    [disabled]="true">{{invit.reciver_role}}</button>
                <button type="button" [disabled]="true"
                    [ngStyle]="{'color': 'white','background-color': invit.is_accepted ?'#5EC394':'#DCDCDC','border-color':invit.is_accepted ?'#5EC394':'#DCDCDC'}"
                    class="btn btn-primary col-xl-1 mt-2 mt-xl-0  ml-2 ">acceptée</button>
                <button type="button" [disabled]="true"
                    [ngStyle]="{'color': 'white','background-color': !invit.is_accepted ?'#5EC394':'#DCDCDC','border-color': !invit.is_accepted ?'#5EC394':'#DCDCDC'}"
                    class="btn btn-primary col-xl-2 mt-2 mt-xl-0  ml-2">en attente</button>
                <button (click)="renvoyerInvit(invit)" type="button"
                    [ngStyle]="{'color': 'white','background-color': invit.is_accepted ?'#DCDCDC':'#5EC394','border-color':invit.is_accepted ?'#DCDCDC':'#5EC394'}"
                    [disabled]="invit.is_accepted ? true:false"
                    class="btn btn-info col-xl-1 mt-2 mt-xl-0  ml-2">renvoyer</button>
                <button (click)="retractInvit(invit)" type="button"
                    [ngStyle]="{'color': 'white','background-color': invit.is_accepted ?'#DCDCDC':'red','border-color':invit.is_accepted ?'#DCDCDC':'red'}"
                    [disabled]="invit.is_accepted ? true:false"
                    class="btn btn-danger col-xl-1 mt-2 mt-xl-0  ml-2">rétracter</button>
                <button (click)="deleteUser(invit)" type="button"
                    [ngStyle]="{'color': 'white','background-color': invit.is_accepted ?'red':'#DCDCDC','border-color':invit.is_accepted ?'red':'#DCDCDC'}"
                    [disabled]="invit.is_accepted ? false:true"
                    class="btn btn-danger col-xl-1 mt-2 mt-xl-0  ml-2">supprimer</button>
            </div>
        </div>
    </div>

    <div class="row ml-4 mt-5 h4" *ngIf="userBo.role=='super_user'">
        <img class="mr-2" src="./../../../../../assets/img/028-addelementleft.svg" style="width: 20px;height: 20px;">
        Inviter un collaborateur
    </div>

    <form class="mt-3 ml-4" *ngIf="userBo.role=='super_user'" [formGroup]="InvitForm"
        (ngSubmit)="OnSubmitInvit(InvitForm.value)">
        <div class="form-row">
            <div class="col-12">
                <div class="row">
                    <div class="col-xl-3 mt-2 mt-xl-0">
                        <input type="text" class="form-control input11" style="height: 37px; color: black;" placeholder="Tapez une adresse email" id="email_invit" formControlName="email_invit"
                            required>
                    </div>
                    <div class="col-xl-3 mt-2 mt-xl-0  input">
                        <select style="width: 100%;box-shadow: none;border: 1px solid #F47000; color: black;" class="custom-select p2" id="role_invit" formControlName="role_invit">
                            <option *ngIf="userBo.email == 'palarcon@uzer.eu'" class="p2">super_user</option>
                            <option *ngIf="userBo.email == 'palarcon@uzer.eu'" disabled style="font-style:italic" class="p2">&nbsp;&nbsp;&nbsp;(Peut visualiser,créer et modifier.)</option>
                            <option class="p2">Normal</option>
                            <option class="p2" disabled style="font-style:italic">&nbsp;&nbsp;&nbsp;(Peut visualiser seulement)
                            </option>
                            <option class="p2">Agent</option>
                            <option class="p2" disabled style="font-style:italic">&nbsp;&nbsp;&nbsp;(Gestion des signalements)</option>
                            <option class="p2">Agent administrateur</option>
                            <option class="p2" disabled style="font-style:italic">&nbsp;&nbsp;&nbsp;(Gestion des signalements avec envoi de messages)</option>
                            <option class="p2">Administrateur</option>
                            <option class="p2" disabled style="font-style:italic">&nbsp;&nbsp;&nbsp;(Peut visualiser,créer et
                                modifier.)</option>
                        </select>
                    </div>
                    <button type="submit" class="btn btn-primary button1 col-xl-2 mt-2 mt-xl-0">Envoyer</button>
                </div>
            </div>
        </div>
    </form>

    <div class="row ml-4 mt-5 h4" *ngIf="userBo.role=='super_user'">
        <img class="mr-2" src="./../../../../../assets/img/028-addelementleft.svg" style="width: 20px;height: 20px;">
        Ajouter un collaborateur à un contrat
    </div>

    <div class="mt-3 ml-4" *ngIf="userBo.role=='super_user'" style="margin-bottom: 150px;">
        <div class="form-row">
            <div class="col-12">
                <div class="row">
                    <div class="col-xl-3 mt-2 mt-xl-0">
                        <select class="custom-select p2" style="width: 100%;box-shadow: none;border: 1px solid #F47000; color: black;" [(ngModel)]="userToAdd" (ngModelChange)="addUser()">
                            <option class="p2" [ngValue]="'choisissez un email'" disabled  selected>Choisissez une adresse email</option>
                            <option class="p2" *ngFor="let invit  of mailsList; let i=index" [ngValue]="invit.reciver_email">{{invit.reciver_email}}</option>
                        </select>
                    </div>
                    <div class="col-xl-3 mt-2 mt-xl-0  input">
                        <select class="custom-select p2"  style="width: 100%;box-shadow: none;border: 1px solid #F47000; color: black;"   [(ngModel)]="contratToAdd" (ngModelChange)="addCollaborateur()">
                            <option class="p2" [ngValue]="'Choisissez un contrat'"disabled  selected>Choisissez un contrat</option>
                            <option class="p2" *ngFor="let item  of contratsList; let i=index" [ngValue]="item.idContrat">{{item.nomContrat}}</option>
                        </select>
                    </div>
                    <button type="submit" class="btn btn-primary button1 col-xl-2 mt-2 mt-xl-0" (click)="updateInvitedUser()">Ajouter</button>
                </div>
            </div>
        </div>
    </div>
</div>

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CalendarsettingsRoutingModule } from './calendarsettings-routing.module';
import { CalendarsettingsComponent } from './components/calendarsettings/calendarsettings/calendarsettings.component';


@NgModule({
  declarations: [CalendarsettingsComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    AngularSvgIconModule.forRoot(),
    CalendarsettingsRoutingModule
  ]
})
export class CalendarsettingsModule { }

import { element } from 'protractor';
import { BehaviorSubject } from 'rxjs';
import { Service } from './../../models/contrat/contrat';
import { ContratService } from './../contrat/contrat.service';
import { Injectable, OnInit } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SliderService  {

    temp_services : BehaviorSubject<Service[]> = new BehaviorSubject<Service[]>([]);
    temp_services_obs = this.temp_services.asObservable()

    constructor(

    ) {

    }

    setTempServices(services : Service[]){
        this.temp_services .next([])
        for(let element of  services ){
            this.temp_services.value.push({... element})
        }
    }


}

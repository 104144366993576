import { Component, OnInit } from '@angular/core';




@Component({
    selector: 'app-reportsuccess',
    templateUrl: './success.component.html',
    styleUrls: ['./success.component.scss']
})
export class ReportsuccessComponent implements OnInit {

    constructor(
       
    ) { }
     

    ngOnInit(): void {
        
    }
}
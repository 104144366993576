<div class="container mt-5 mb-5 d-flex flex-column justify-content-center align-items-center">
    <img src="assets/img/montri_logo.png" class="montri_logo">
    <h5 class="h1 mt-3" style="text-align: center;">Veuillez renseignez votre adresse e-mail
        </h5>
    <div class="row d-flex flex-column justify-content-center align-items-center" style="width: 100%;">
        <form class="mt-3" style="width: 300px;"  [formGroup]="SendMailForm" (ngSubmit)="onSubmit(SendMailForm.value)" >

            <div class="form-group has-feedback" style="width: 100%;">
                <input autocomplete="off" type="text" style="width: 100%;" class="form-control input_form p2"  (focus)="sendMailError = false;error=false" style="font-family:Arial, FontAwesome" ngClass="{{sendMailError ? 'colorerrorinput' : 'colornoterrorinput'}}"  placeholder="{{sendMailError ?'&#xf071; Champs obligatoire':'Adresse e-mail'}}"
                    id="adress_mail" placeholder="Adresse e-mail" formControlName="adress_mail">
            </div>

            <button type="submit"  style="width: 100%;" class="btn button_form button1 mt-3 w-100"
                id="Login_btn">Valider</button>
        </form>
    </div>

    <div class="col-lg-12 mt-5 mb-3  d-flex flex-row justify-content-center align-items-center error pb-0" *ngIf="error||sendMailError">
            <i class="fa fa-exclamation-triangle fa-2x" aria-hidden="true" style="width: 30px;height: 30px;"></i>
            <p class="ml-3 h-100 d-flex flex-row  mb-0 pb-0 justify-content-center align-items-end" style="text-align: center;">Certains champs obligatoires ne sont pas remplis</p>
    </div>

</div>

import { ResportinglistComponent } from './../contractstats/components/resportinglist/resportinglist.component';
import { ReportingstatsComponent } from './../contractstats/components/reportingstats/reportingstats.component';
import { FluxstatsComponent } from './../contractstats/components/fluxstats/fluxstats.component';
import { AppstatsComponent } from './../contractstats/components/appstats/appstats.component';
import { FullstatsComponent } from './../contractstats/components/fullstats/fullstats.component';
import { AlertssettingsModule } from './../alertssettings/alertssettings.module';
import { ActussettingsComponent } from './../actussettings/components/actussettings/actussettings.component';
import { FluxComponent } from './../cartsettings/components/flux/flux.component';
import { CoordinateComponent } from './../cartsettings/components/coordinate/coordinate.component';
import { CartesettingsComponent } from './../cartsettings/components/cartesettings/cartesettings.component';
import { SliderComponent } from './../contractsettings/components/slider/slider.component';
import { ServicesComponent } from './../contractsettings/components/services/services.component';
import { DesignComponent } from './../contractsettings/components/design/design.component';
import { RecyclingInstructionComponent } from './../contractsettings/components/recycling-instruction/recycling-instruction.component';
import { InformationsComponent } from './../contractsettings/components/informations/informations.component';
import { AuthGuardService as AuthGuard } from './../../services/guards/auth-guard.service';
import { AccountComponent } from './components/account/account.component';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { MapComponent } from './components/map/map.component';
import { MainComponent } from './components/main/main.component';
import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule, CanActivate } from '@angular/router';
import { ContractsettingsComponent } from '../contractsettings/components/contractsettings/contractsettings.component';
import { CalendarsettingsComponent } from '../calendarsettings/components/calendarsettings/calendarsettings/calendarsettings.component';
import { AlertssettingsComponent } from '../alertssettings/components/alertssettings/alertssettings.component';
import { FiltreComponent } from '../cartsettings/components/filtre/filtre.component';
import { UploadocumentsComponent } from '../uploadocuments/uploadocuments.component';


const routes: Routes = [
    {
        // tslint:disable-next-line: indent
        path: 'montri',
        component: MainComponent,
        children: [
            {
                path: 'welcome',
                component: WelcomeComponent,
                canActivate: [AuthGuard]

            },
            {
                path: 'account',
                component: AccountComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'map',
                component: MapComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'contractsettings',
                component: ContractsettingsComponent,
                children: [
                    {
                        path: 'informations',
                        component: InformationsComponent,
                        canActivate: [AuthGuard]

                    },
                    {
                        path: 'recyclinginstructions',
                        component: RecyclingInstructionComponent,
                        canActivate: [AuthGuard]

                    },
                    {
                        path: 'design',
                        component: DesignComponent,
                        canActivate: [AuthGuard]

                    },
                    {
                        path: 'services',
                        component: ServicesComponent,
                        canActivate: [AuthGuard]

                    },
                    {
                        path: 'slider',
                        component: SliderComponent,
                        canActivate: [AuthGuard]
                    },

                ],

            },
            {
                path: 'cartsettings',
                component: CartesettingsComponent,
                children: [
                    {
                        path: 'coordinate',
                        component: CoordinateComponent,
                        canActivate: [AuthGuard]
                    },
                    {
                        path: 'flux',
                        component: FluxComponent,
                        canActivate: [AuthGuard]
                    },
                    {
                        path: 'filtre',
                        component: FiltreComponent,
                        canActivate: [AuthGuard]
                    }
                ],

            },
            {
                path: 'calendarsettings',
                component: CalendarsettingsComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'actussettings',
                component: ActussettingsComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'alertssettings',
                component: AlertssettingsComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'documents',
                component: UploadocumentsComponent,
                canActivate: [AuthGuard]
            },
            {

                path: 'fullstats',
                component: FullstatsComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'appstats',
                component: AppstatsComponent,
                canActivate: [AuthGuard]
            },
            {

                path: 'fluxstats',
                component: FluxstatsComponent,
                canActivate: [AuthGuard]

            },
            {

                path: 'reportingstats',
                component: ReportingstatsComponent,
                canActivate: [AuthGuard]

            },
            {

                path: 'reportinglist',
                component: ResportinglistComponent,
                canActivate: [AuthGuard]

            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class MainRoutingModule {}

import { MenuService } from './../../../../services/menu/menu.service';
import { SliderService } from './../../../../services/slider/slider.service';
import { ContratService } from './../../../../services/contrat/contrat.service';
import { Router } from '@angular/router';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { TabmessageService } from 'src/app/services/tabmessage/tabmessage.service';

@Component({
  selector: 'app-cartesettings',
  templateUrl: './cartesettings.component.html',
  styleUrls: ['./cartesettings.component.scss']
})
export class CartesettingsComponent implements OnInit {

    public sectionname : string;

    constructor(
        private sectionservice: TabmessageService,
        private router : Router,
        private changeDetector: ChangeDetectorRef,
        private contratservice : ContratService,
        private sliderService : SliderService,
        private menuservice : MenuService

    ) {
       this.sectionservice.ngOnInit()
     }

    ngOnInit(): void {
        window.scroll(0,0)
        var  self = this ;
        this.menuservice.setSection("paramétrage");
        this.menuservice.setSubsection("carte");
        this.sectionservice.secttion_obs.subscribe((section)=>{
                self.sectionname =  section;
                self.changeDetector.detectChanges();

        })
        this.contratservice.contrat_obs.subscribe((contrat)=>{
            this.sliderService.setTempServices(contrat.services)
        })
    }

    gotourl(s : string){
        this.router.navigateByUrl(s)
    }
}

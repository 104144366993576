import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InvitID } from './../../../../models/invit/invit';
import { UserService } from './../../../../services/user/user.service';
import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-retractinvit',
    templateUrl: './retractinvit.component.html',
    styleUrls: ['./retractinvit.component.scss']
})
export class RetractinvitComponent implements OnInit {

    @Input() invit : InvitID
    constructor(
        private userService: UserService,
        public activeModal: NgbActiveModal
    ) { }

    ngOnInit(): void {

    }

    retractinvit() {
        this.userService.retractInvit(this.invit)
        this.activeModal.close()
    }

    closeModal() {
        this.activeModal.close()
    }
}

<div class="container-fluid  mt-5 mb-5" *ngIf="contrat != undefined && contrat.contrat.idContrat !='BOTEST'" >

    <div *ngIf="state == 'config_compte'">
        <div class="row ml-4">
            <p class="h1">Bienvenue sur votre espace Client Montri.</p>
        </div>

        <div class="row ml-4  mt-3">
            <p class="p2">Avant de commencer le paramétrage de vos solutions, veuillez configurer votre compte Utilisateur. </p>
        </div>

        <div class="row ml-4  mt-2">
            <p class="p2">Pour toute question ou demande d’aide, veuillez contacter notre équipe support : <span class="h4"><a href="mailto:support@montri.fr">support@montri.fr</a></span></p>
        </div>
    </div>


    <div *ngIf="state == 'premier_modification'">
        <div class="row ml-4">
            <p class="h1">Bienvenue sur votre espace Client Montri.</p>
        </div>

        <div class="row ml-4  mt-3">
            <p class="p2">Maintenant que votre compte est configuré, vous allez pouvoir gérer le contenu et design de votre solution Montri. </p>
        </div>

        <div class="row ml-4  mt-2">
            <p class="p2">Pour toute question ou demande d’aide, veuillez contacter notre équipe support : <span class="h4"><a href="mailto:support@montri.fr">support@montri.fr</a></span></p>
        </div>
    </div>

    <div *ngIf="state == 'modification'">
        <div class="row ml-4">
            <p class="h1">Bienvenue sur votre espace Client Montri.</p>
        </div>

        <div class="row ml-4  mt-3">
            <p class="p2">Reprenez là où vous vous êtes arrêté(e). Vous êtes sur la bonne voie !</p>
        </div>

        <div class="row ml-4  mt-2">
            <p class="p2">Pour toute question ou demande d’aide, veuillez contacter notre équipe support : <span class="h4"><a href="mailto:support@montri.fr">support@montri.fr</a></span></p>
        </div>
    </div>



    <div *ngIf="state == 'complet'">
        <div class="row ml-4">
            <p class="h1">Paramétrage terminé !</p>
        </div>

        <div class="row ml-4  mt-3">
            <p class="p2">Votre paramétrage est maintenant terminé. Après validation par nos équipes, vous recevrez un email de confirmation qui vous permettra de mettre en ligne vos solutions.</p>
        </div>

        <div class="row ml-4  mt-2">
            <p class="p2">Pour toute question ou demande d’aide, veuillez contacter notre équipe support : <span class="h4"><a href="mailto:support@montri.fr">support@montri.fr</a></span></p>
        </div>
    </div>

    <div *ngIf="state == 'attente_validation_first_actus'">
        <div class="row ml-4">
            <p class="h1">Paramétrage validé !</p>
        </div>

        <div class="row ml-4  mt-3">
            <p class="p2">Nourrissez votre application avec une première actualité. Vos usagers seront heureux de connaitre les initiatives locales et responsables.</p>
        </div>

        <div class="row ml-4  mt-2">
            <p class="p2">Pour toute question ou demande d’aide, veuillez contacter notre équipe support : <span class="h4"><a href="mailto:support@montri.fr">support@montri.fr</a></span></p>
        </div>
    </div>


    <div *ngIf="state == 'attente_validation'">
        <div class="row ml-4">
            <p class="h1">Actualité publiée !</p>
        </div>

        <div class="row ml-4  mt-3">
            <p class="p2">Votre actualité sera visible de1s la mise en ligne de vos solutions.</p>
        </div>

        <div class="row ml-4  mt-2">
            <p class="p2">Pour toute question ou demande d’aide, veuillez contacter notre équipe support : <span class="h4"><a href="mailto:support@montri.fr">support@montri.fr</a></span></p>
        </div>
    </div>

    <div *ngIf="state == 'valide'">
        <div class="row ml-4">
            <p class="h1">Paramétrage validé !</p>
        </div>

        <div class="row ml-4  mt-3">
            <p class="p2">Votre paramétrage est maintenant validé. Après confirmation par vos soins, vos solutions seront disponibles pour vos usagers.</p>
        </div>

        <div class="row ml-4  mt-2">
            <p class="p2">Pour toute question ou demande d’aide, veuillez contacter notre équipe support : <span class="h4"><a href="mailto:support@montri.fr">support@montri.fr</a></span></p>
        </div>
    </div>


    <div *ngIf="state == 'en_ligne'">
        <div class="row ml-4">
            <p class="h1">Votre application est en ligne !</p>
        </div>

        <div class="row ml-4  mt-3">
            <p class="p2">Vous pouvez modifier vos informations à tout moment, puis validez vos modifications à nouveau pour les publier.</p>
        </div>

        <div class="row ml-4  mt-2">
            <p class="p2">Pour toute question ou demande d’aide, veuillez contacter notre équipe support : <span class="h4"><a href="mailto:support@montri.fr">support@montri.fr</a></span></p>
        </div>
    </div>



    <div class="row ml-4  mt-3">
        <div class="col-4 pr-2 pl-0">
            <button type="button"   [ngStyle]="{'cursor' : (((user.role=='super_user') &&  step == 'Mettre en ligne') ||( ((user.role=='super_user') || (user.role=='Administrateur')) && step != 'Mettre en ligne') || backToModify) ?  null : 'not-allowed'}"   ngbTooltip="{{((user.role!='super_user') &&  step == 'Mettre en ligne') ? 'Vous ne disposez pas des droits nécessaires. Seul l\'administrateur principal peut mettre en ligne l\'application.' : ''}}" tooltipClass="legend"  [disabled]="contrat.contratstatus == 'en_attente' ? true : false"  (click)="((((user.role=='super_user') &&  step == 'Mettre en ligne') ||( ((user.role=='super_user') || (user.role=='Administrateur')) && step != 'Mettre en ligne'))  &&  !backToModify) ? goToStep(step) : null" class="btn button1 btn-primary btn-lg btn-block">{{step}}</button>
        </div>
        <div class="col-1"></div>
        <div class="col-4 pr-2 pl-0">
            <button type="button" [hidden]="contrat.contratstatus == 'en_attente' || backToModify ? false : true"  (click)="updateContrat()" class="btn button1 btn-primary btn-lg btn-block">Modifier votre paramétrage</button>
        </div>
    </div>

    <div class="row ml-4  mt-5 text_black text_heavy">
        <p class="h2">Votre Check list :</p>
    </div>
    <ng-container *ngFor="let item of contrat.configstatus" >
    <div  *ngIf="item.active" class="row ml-4  p2 mt-4 d-flex flex-row justify-content-start align-items-center" style="text-align: center;">
        <div class="col-lg-1 ml-0 mr-0 pl-0 pr-0 d-flex flex-row justify-content-lg-start justify-content-center align-items-center">
            <img class="mr-2" src ="./../../../../../assets/img/025-checkon.svg" *ngIf="item.status" style="width: 15px;height: 15px;">
            <img class="mr-2" src ="./../../../../../assets/img/024-checkoff.svg" *ngIf="!item.status" style="width: 15px;height: 15px;">
        </div>
        <div  class="col-lg-7  ml-0 pl-0 d-flex flex-row justify-content-lg-start justify-content-center align-items-center">
                    {{item.ConfigName}}
        </div>
        <div  class="col-lg-1 ml-lg-2  heavy_gray d-flex flex-row justify-content-lg-start justify-content-center align-items-center">

            <svg-icon src="../../../../../assets/img/033-pencil.svg" [stretch]="true"  *ngIf="item.status == true" (click)="goToStep(item.ConfigName)"
                        [svgStyle]="{'width.px':20, 'height.px':20 , 'fill': '#f47000' ,'stroke': '#f47000'}">
            </svg-icon>

            <svg-icon src="../../../../../assets/img/033-pencil.svg" [stretch]="true" *ngIf="item.status  == false" (click)="goToStep(item.ConfigName)"
                        [svgStyle]="{'width.px':20, 'height.px':20 , 'fill': '#707070' ,'stroke': '#707070'}">
            </svg-icon>
        </div>
    
    </div>
</ng-container>
</div>


import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
const QUERY_URL: string = `${environment.statsFunctionsUrl}`;

@Injectable({
    providedIn: 'root'
})
export class FuctionsqueryService {

    constructor(private http: HttpClient) { }

    onQueryGET(functionName: string, params: HttpParams) {
        return this.http.get(QUERY_URL + functionName, { params });
    }

}


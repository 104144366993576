<div class="row  ml-5 mr-5 mb-5 d-flex flex-column justify-content-start align-items-start bg-white pt-3" style="margin-top: 100px;border-top-left-radius: 20px;border-top-right-radius: 20px;">
    <div class="col-3 ml-2 mb-3">
        <p class="pb-0 mb-0 h3">Historique des alertes</p>
    </div>
    <table class="table table-responsive-xl">
        <thead>
          <tr>
            <th scope="col"> <div class="d-flex justify-content-center h3">ID</div></th>
            <th scope="col"><div class="d-flex justify-content-center h3">Type d'alerte</div></th>
            <th scope="col"><div class="d-flex justify-content-center h3">Alerte</div></th>
            <th scope="col"><div class="d-flex justify-content-center h3">Date de publication</div></th>
            <th scope="col"> <div class="d-flex justify-content-center h3">Action</div></th>

          </tr>
        </thead>
        <tbody>
            <tr>
                <td style="max-width: 120px;">
                    <div class="mt-4 mb-4 d-flex flex-row justify-content-center">
                        <button type="button" [ngStyle]="{'cursor' :  ((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification') ? 'not-allowed' : null }" (click)="(((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')) ? null : showModal(null)" class="btn btn-primary w-100 btn_add_actus">Envoyer une alerte</button>
                    </div>
                </td>

            </tr>

            <tr *ngFor="let item of actus ; index as i">
                <th scope="row" class="d-flex justify-content-center">
                    <!-- <div id="content">
                        <div id="outer-circle"></div>
                    </div> -->
                    <div class="h3">{{i+1+((page-1)*4)}}</div>
               </th>

               <td style="max-width: 200px;" >
                    <div class="d-flex flex-row justify-content-center">
                        <div>{{capitalize(item.type.toLocaleLowerCase())}}</div>
                    </div>
                </td>
                <td style="max-width: 200px;">
                    <div class="d-flex flex-column justify-content-center">
                        <p class="d-inline-block text-truncate pb-0 mb-0  h3">{{capitalize(item.titre.toLocaleLowerCase())}}</p>
                        <p class="d-inline-block text-truncate pb-0 mb-0 mt-0 p2">{{capitalize(item.description.toLocaleLowerCase())}}</p>
                    </div>
                </td>


                <td style="max-width: 200px;" >
                    <div class="d-flex flex-row justify-content-center">
                        <div>{{item.date.toDate() | date : 'yyyy-MM-dd'}}</div>
                    </div>
                </td>

                <td style="max-width: 200px;" >
                    <div class="d-flex flex-row justify-content-center">
                        <div class="d-flex flex-column justify-content-center h-100 ml-2"  [ngStyle]="{'cursor' : ((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification') ? 'not-allowed' : null }"  (click)="(((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')) ? null :deletealert(item)"><i class="fa fa-trash fa-2x" style="color: #EF627A;" aria-hidden="true"></i></div>

                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <div class="w-100 d-flex justify-content-center" *ngIf="actus.length>0">
        <div class="d-flex justify-content-center p-2">
            <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize" (pageChange)="refreshActus()">
            </ngb-pagination>

            <select class="custom-select ml-3" style="width: auto;box-shadow: none;border: 1px solid #F47000; color: black;" [(ngModel)]="pageSize" (ngModelChange)="refreshActus()">
            <option [ngValue]="2">2 Actualités par page</option>
            <option [ngValue]="4">4 Actualités par page</option>
            <option [ngValue]="6">6 Actualités par page</option>
            </select>
        </div>
    </div>
</div>

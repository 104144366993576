import { NgbModal, NgbActiveModal, NgbTimeStruct, NgbTimeAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { Info, DesignFlux, Carte } from './../../../../../models/contrat/contrat';
import { Flux } from './../../../../../models/flux/flux';
import { element, ElementFinder, logging } from 'protractor';
import { Router } from '@angular/router';
import { LodingBarService } from './../../../../../services/lodingbar/loding-bar-service.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { MenuService } from './../../../../../services/menu/menu.service';
import { UserBo } from './../../../../../models/user/user';
import { UserService } from './../../../../../services/user/user.service';
import { Calendrier, Jours } from './../../../../../models/calendrier/calendrier';
import { ContratService } from './../../../../../services/contrat/contrat.service';
import { Component, OnInit, ViewChild, Injectable } from '@angular/core';
import { Contrat } from 'src/app/models/contrat/contrat';
import { AngularFireStorage } from '@angular/fire/storage';
import * as XLSX from 'xlsx';
import * as Papa from 'papaparse';
import * as $ from 'jquery';
import { fromPromise } from 'rxjs/internal-compatibility';
import {NgbDate, NgbCalendar, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import {zip2json} from 'shapeimporter';
import {ToastAlertsComponent} from '../../../../main/components/toast-alerts/toast-alerts.component'
//import {zip2json} from '../../../../../../../node_modules/zip2json/index.js';


declare var ContextMenu: any;
declare var HelloWorld: any;


const pad = (i: number): string => i < 10 ? `0${i}` : `${i}`;

/**
 * Example of a String Time adapter
 */
@Injectable()
export class NgbTimeStringAdapter extends NgbTimeAdapter<string> {

  fromModel(value: string| null): NgbTimeStruct | null {
    if (!value) {
      return null;
    }
    const split = value.split(':');
    return {
      hour: parseInt(split[0], 10),
      minute: parseInt(split[1], 10),
      second: parseInt(split[2], 10)
    };
  }

  toModel(time: NgbTimeStruct | null): string | null {
    return time != null ? `${pad(time.hour)}:${pad(time.minute)}:${pad(time.second)}` : null;
  }
}

@Injectable({
    providedIn: 'root',
})
export class NgbDateMomentParserFormatter extends NgbDateParserFormatter {
    constructor() {
        super();
    };
    format(date: NgbDate): string {
        if (date === null) {
            return 'DD/MM/YYYY';
        }
        let d = moment({ year: date.year,
                         month: date.month - 1,
                         date: date.day });
        return d.isValid() ? d.format('DD/MM/YYYY') : '';
    }

    parse(value: string): NgbDate {
        if (!value) {
            return null;
        }
        let d = moment(value, 'DD/MM/YYYY');
        return d.isValid() ? new NgbDate(d.year(),d.month()+1,d.date()) : null}

    formatTwoDate(datefrom: NgbDate,dateto: NgbDate): string {
        if (datefrom === null || dateto === null) {
            return '';
        }
        let dfrom = moment({ year: datefrom.year,
                         month: datefrom.month - 1,
                         date: datefrom.day });
         let dto = moment({ year: dateto.year,
                         month: dateto.month - 1,
                         date: dateto.day });
        return dfrom.isValid() && dto.isValid() ? "Du "+dfrom.format('DD/MM/YYYY') +" au " + dto.format('DD/MM/YYYY'): '';
    }

}


@Component({
    selector: 'app-calendarsettings',
    templateUrl: './calendarsettings.component.html',
    styleUrls: ['./calendarsettings.component.scss'],
    providers: [{provide: NgbTimeAdapter, useClass: NgbTimeStringAdapter}]
})

export class CalendarsettingsComponent implements OnInit {

    contrat: Contrat;
    calendrier : Calendrier = {};
    carte : Carte
    calendrierTemplate : string =''
    user: UserBo;
    shape = null;
    shapes = []
    drawingManager;
    selectedShape;
    colors = ['#1E90FF', '#FF1493', '#32CD32', '#FF8C00', '#4B0082'];
    selectedColor;
    colorButtons = {};
    currntPolygon : google.maps.Polygon = null
    newPolygon  :  google.maps.Polygon = null
    flux : DesignFlux[]
    map :  google.maps.Map
    polygon_list  : BehaviorSubject<google.maps.Polygon[]> = new  BehaviorSubject<google.maps.Polygon[]>([])
    polygon_list_obs = this.polygon_list.asObservable()
    fluxCount : Map<string,number> = new Map()
    clickedPolygonIndex  : number = null
    clickedInfos : any
    event : google.maps.MouseEvent
    @ViewChild('polygonActions', { static: false }) private polygonActions;
    modal_ref : any
    mapContext : any
    pageSize = 1
    hoveredDate: NgbDate | null = null;
    fromDate: NgbDate;
    toDate: NgbDate | null = null;
    polygonError : boolean =  false



    constructor(
        private contartservice : ContratService,
        private userservice : UserService,
        private menuservice : MenuService,
        private afStorage : AngularFireStorage,
        private loadingBarService : LodingBarService,
        private routeur : Router,
        public modalService: NgbModal,
        public activeModal: NgbActiveModal,
        private calendar: NgbCalendar,
        public formatter: NgbDateMomentParserFormatter,
        private toastr: ToastAlertsComponent
    ) {

        this.fromDate = calendar.getToday();
        this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);
    }

    ngOnInit(): void {
        this.menuservice.setSection("paramétrage");
        this.menuservice.setSubsection("calendrier");


        const ref2 = this.afStorage.ref('TemplatesBO/Calendrier-type.xlsx');
        ref2.getDownloadURL().subscribe((href)=>{
            this.calendrierTemplate = href
        })

        var check = 0

        this.userservice.user_obs.subscribe((user)=>{
            this.user =  user
            this.contartservice.contrat_obs.subscribe((contart)=>{
                this.contrat =  contart
                if(this.contrat.contrat.idContrat != 'BOTEST'){
                    this.contartservice.calendrier_obs.subscribe((calendrier)=>{
                            this.calendrier = {... calendrier}
                               if(check == 0){
                                    this.flux = this.contrat.design.flux
                                    for(let [i,service] of this.flux.entries()){
                                        this.flux[i].error = false
                                        this.flux[i].showInCalendar = true
                                        this.flux[i].colorErrorMsg = ''
                                        this.fluxCount[service.nom] = 0
                                    }
                                }
                                var  self = this;
                                setTimeout(function(){
                                    if(check == 0   && self.calendrier != null &&  self.calendrier != undefined && Object.keys(self.calendrier).length != 0){
                                        self.initialize()
                                        check = 1
                                    }
                                    self.mapContext = new ContextMenu({
                                            map: self.map,                // objet google.maps.Map
                                            idMenu: "cm-map",         // id élément DOM du menu
                                            callback: self.mapAction.bind(self)      // réf. fonction appelée
                                    });
                                },200);

                    })
                    this.contartservice.carte_obs.subscribe((carte)=>{
                        this.carte = carte as Carte
                    })
                }
            })
        })
    }

    async ImportPolygons(file: File) {
  
            let error = false
            this.calendrier = {... await zip2json(file)}
            
            if(this.calendrier['error']=== true){
                this.toastr.openFailedToast('Importation échoué. Veuillez verifier votre fichier et réessayer.','')
            }
            var self  = this
            var polygon_list_temp = []

            for (let  [index,itemFlux] of (this.calendrier?.flux ??[]).entries()){
                for (let element of itemFlux.zones){
                    var paths_temp = []
                    for (let item of element.polygones){
                        paths_temp.push(google.maps.geometry.encoding.decodePath(item))
                    }

                    var temp_plygon = new google.maps.Polygon({
                        paths: paths_temp,
                        strokeColor: "#808080",
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: "#808080",
                        fillOpacity: 0.35,
                    });

                    temp_plygon.set("infos",element.infos)
                    temp_plygon.setMap(this.map)
                    temp_plygon.set('fluxType', itemFlux.nom)
                    temp_plygon.set('index',polygon_list_temp.length)
                    temp_plygon.setOptions({
                        strokeColor: self.checkAllInfosExists(temp_plygon),
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: self.checkAllInfosExists(temp_plygon),
                        fillOpacity: 0.35,
                    })
                    polygon_list_temp.push(temp_plygon)

                    google.maps.event.addListener(temp_plygon,'click',function () {
                                self.currntPolygon.setOptions({
                                    strokeColor: self.checkAllInfosExists(self.currntPolygon),
                                    strokeOpacity: 0.8,
                                    strokeWeight: 2,
                                    fillColor: self.checkAllInfosExists(self.currntPolygon),
                                    fillOpacity: 0.35,
                                })
                                self.currntPolygon = this
                                self.currntPolygon.setOptions({
                                    strokeColor: "#f47000",
                                    strokeOpacity: 0.8,
                                    strokeWeight: 2,
                                    fillColor: "#f47000",
                                    fillOpacity: 0.35,
                                })
                                self.setSelection(self.currntPolygon);
                        })

                    google.maps.event.addListener(temp_plygon,'rightclick',function (e) {
                        if(this.getEditable()){
                            self.clickedPolygonIndex = this.get('index')
                            self.clickedInfos = {...this.get('infos')}
                            self.event = e
                            self.mapContext.show(this, e.latLng);
                        }

                    })
                }
            }

            for (const polygon of this.polygon_list.value) {
                polygon.setMap(null)
            }

            this.polygon_list.next(polygon_list_temp)
           
        
    }

    changePolygonType(){
        if(this.contrat.contrat.collectePolygon){
            for(let [i,service] of this.flux.entries()){
                this.flux[i].error = false
                this.flux[i].showInCalendar = true
                this.flux[i].colorErrorMsg = ''
                this.fluxCount[service.nom] = 0
            }
            var self = this ;
            setTimeout(function(){
                self.initialize()
                self.mapContext = new ContextMenu({
                    map: self.map,                // objet google.maps.Map
                    idMenu: "cm-map",         // id élément DOM du menu
                    callback: self.mapAction.bind(self)      // réf. fonction appelée
                });

            },500);
        }
    }


    deleteCalendrier(){

    }

    findPeriode(text: string) {
        return text.indexOf("Du ")
    }

    getVeille(day) {
        switch(day) {
            case "lundi": {
                return "dimanche"
            }
            case "mardi": {
                return "lundi"
            }
            case "mercredi": {
                return "mardi"
            }
            case "jeudi": {
                return "mercredi"
            }
            case "vendredi": {
                return "jeudi"
            }
            case "samedi": {
                return "vendredi"
            }
            case "dimanche": {
                return "samedi"
            }
        }
    }

    computeInfos(info) {
        let consigne = info[5] ? info[5] : ""
        let flux = info[0]
        let jour = info[4]
        let texte = info[6]

        var frequence = "Hebdomadaire"
        var daysPerMonth = null
        if ((/Tous les (\d.{2,3}) et (\d.{2,3})/gi).test(jour)) {
            frequence = "Bi-mensuelle"
            daysPerMonth = jour.match(/Tous les (\d.{2,3}) et (\d.{2,3})/gi)
            daysPerMonth = daysPerMonth && daysPerMonth.length > 0 ? `${daysPerMonth[0]} du mois` : null
        } else if ((/Tous les (\d.{2,3}) .{5,} du mois/gi).test(jour)) {
            frequence = "mensuelle"
            daysPerMonth = jour.match(/Tous les (\d.{2,3})/gi)
            daysPerMonth = daysPerMonth && daysPerMonth.length > 0 ? `${daysPerMonth[0]} du mois` : null
        } else if (jour.indexOf("semaine paire") > -1) {
            frequence = "Semaine paire"
        } else if (jour.indexOf("semaine impaire") > -1) {
            frequence = "Semaine impaire"
        }

        var periodeType = "Toute l'année"
        let result = {}
        var match = jour.replace("de mi-mars à début décembre", "Du 15/03/2021 au 30/11/2021").match(/Du (\d{1,2}([-/])\d{1,2}([-/])\d{4}) au (\d{1,2}([-/])\d{1,2}([-/])\d{4})/gi)
        if (match && match.length > 0) {
            periodeType = "Personnalisée"
            result["periode"] = match[0]
        }
        result["fluxCollecte"] = flux

        result["commentaire"] = jour

        if (consigne.length > 0) {
            result["commentaire"] = `${result["commentaire"]} - ${consigne}`
        }
        if (texte) {
            result["texte"] = texte
        }
        if (frequence != "Hebdomadaire") {
            result["frequence"] = frequence
        }
        if (daysPerMonth) {
            result["daysPerMonth"] = daysPerMonth
        }
        if (periodeType != "Toute l'année") {
            result["periodeType"] = periodeType
        }
        var jours = null
        match = jour.match(/lundi|mardi|mercredi|jeudi|vendredi|samedi|dimanche/gi)
        if (match) {
            jours = {}
            match = match.map((x: string) => x.toLowerCase());
            let isVeille = consigne.indexOf("veille") > -1
            if ((/tous les jours/gi).test(jour)) {
                for (let j of ["lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi", "dimanche"]) {
                    var day = j
                    if (isVeille) {
                        day = this.getVeille(j)
                    }
                    if (match.indexOf(j) > -1) {
                        continue
                    } else {
                        jours[day] = true
                    }
                }
            } else {
                for (let j of match) {
                    if (isVeille) {
                        jours[this.getVeille(j)] = true
                    } else {
                        jours[j] = true
                    }
                }
            }
        }
        if (jours) {
            result["jours"] = jours
        }
        return result
    }

    sendCalendrier( file : File){
        this.loadingBarService.setStatus(true)
        this.contartservice.contrat.value.localfiles.localCalendar = file.name
        const reader: FileReader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = (e: any) => {
        /* create workbook */
        const binarystr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });

        /* selected the first sheet */
        const wsname: string = wb.SheetNames[1];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        var range = XLSX.utils.decode_range(ws['!ref']) //<-- start "select"
        range.s.c = 0 // <-- start column
        range.e.c = 9 // <-- end column
        range.s.r = 15 // <-- start row
        ws['!ref'] = XLSX.utils.encode_range(range) //<-- end "select"

        /* save data */
        var xlData = XLSX.utils.sheet_to_csv(ws,{FS:";"})


        Papa.parse(xlData, {
            header: false,
            skipEmptyLines: false,
            complete: (result,file) => {
                const parsedCsv = result.data
                var data = { villes : [] }

                function insertCollect(city, street, number, collect, calendarUrl) {
                  var found = false
                  for (var i in data.villes) {
                    if (data.villes[i].nom == city) {
                      if (street == "") {
                        data.villes[i].infos.push(collect)
                        if (calendarUrl != "") {
                            data.villes[i].calendarUrl = calendarUrl
                        }
                        found = true
                        break
                      } else {
                        for (var j in data.villes[i].rues) {
                          if (data.villes[i].rues[j].nom == street) {
                            if (number == "") {
                              data.villes[i].rues[j].infos.push(collect)
                              if (calendarUrl != "") {
                                data.villes[i].rues[j].calendarUrl = calendarUrl
                              }
                              found = true
                              break
                            } else {
                              for (var k in data.villes[i].rues[j].numeros) {
                                if (data.villes[i].rues[j].numeros[k].nom == number) {
                                  data.villes[i].rues[j].numeros[k].infos.push(collect)
                                  if (calendarUrl != "") {
                                    data.villes[i].rues[j].numeros[k].calendarUrl = calendarUrl
                                  }
                                  found = true
                                  break
                                }
                              }
                              if (!found) {
                                if (calendarUrl != "") {
                                    data.villes[i].rues[j].numeros.push({ nom : number, infos: [collect], calendarUrl: calendarUrl})
                                } else {
                                    data.villes[i].rues[j].numeros.push({ nom : number, infos: [collect]})
                                }
                                found = true
                                break
                              }
                            }
                          }
                        }
                        if (!found) {
                          data.villes[i].rues.push({ nom : street, numeros: [], infos: [] })
                          insertCollect(city, street, number, collect, calendarUrl)
                          found = true
                          break
                        }
                      }
                    }
                  }
                  if (!found) {
                    data.villes.push({ nom : city, rues: [], infos: [] })
                    insertCollect(city, street, number, collect, calendarUrl)
                  }
                }

                for (var i in parsedCsv) {
                  if (parsedCsv[i][0] == "") {
                    break
                  }

                  //var collect = { /*consigne : parsedCsv[i][5],*/ infos: this.computeInfos(parsedCsv[i]) }/*, flux : parsedCsv[i][0], jour : parsedCsv[i][4], texte : parsedCsv[i][6], carte : carte, libelleBouton : libelleBouton , infos: this.computeInfos(parsedCsv[i]) }*/

                  var collect = this.computeInfos(parsedCsv[i])

                  if (parsedCsv[i][7] != "") {
                      collect["fluxCarte"] = parsedCsv[i][7]
                      //collect["libelleBouton"] = "Trouver un point de proximité"
                  }

                  insertCollect(parsedCsv[i][1], parsedCsv[i][2], parsedCsv[i][3], collect, parsedCsv[i][8])
                }

                for (var i in data.villes) {
                    if (data.villes[i].rues.length == 0) {
                      delete data.villes[i].rues
                    } else {
                        for (var j in data.villes[i].rues) {
                            if (data.villes[i].rues[j].numeros.length == 0) {
                                delete data.villes[i].rues[j].numeros
                            }
                        }
                    }
                }

                this.calendrier = data as Calendrier
                this.loadingBarService.setStatus(false)
            }

          });
        };

    }

    selectType(type : string){
        if(type == "excel"){
            this.contrat.contrat.collectePolygon = false
        }
        else{
            this.contrat.contrat.collectePolygon = true
        }
    }

    async saveData(){
        if( Object.keys(this.calendrier).length == 0){
            this.toastr.openFailedToast(`Chargement échoué. Merci de renseigner le fichier.`, "");
        }
        else{
            let ref = await this.toastr.openTransferToast(`Transfert de données en cours.`, "");
            this.contartservice.setCalendrier(this.calendrier)
            let status = await this.contartservice.saveCalendrier('')
            if(status === "success"){
                this.contrat.configstatus[7].status = true
                this.contartservice.setContrat(this.contrat)
                this.contartservice.saveContrat('')
                this.toastr.clear(ref.toastId)
                this.toastr.openSuccessToast('Données enregistrées avec succès', "");
            }else{
                this.toastr.clear(ref.toastId)
                this.toastr.openErrorToast(`Chargement échoué. Veuillez verifier votre fichier et réessayer.`, "");
            }
        }
    }

    next(){
        return  Object.keys(this.calendrier).length == 0
    }


    clearSelection() {
        if (this.selectedShape) {
            this.selectedShape.setEditable(false);
            this.selectedShape = null;
        }
    }

    setSelection(shape) {
        this.clearSelection();
        this.selectedShape = shape;
        shape.setEditable(true);

    }

    deleteSelectedShape() {
        if (this.selectedShape) {
            this.selectedShape.setMap(null);
            this.shapes = this.shapes.filter(element => element !=  this.selectedShape.getPath())
        }
    }

    selectColor(color) {
        this.selectedColor = color;

        // Retrieves the current options from the drawing manager and replaces the
        // stroke or fill color as appropriate.
        var polylineOptions = this.drawingManager.get('polylineOptions');
        polylineOptions.strokeColor = color;
        this.drawingManager.set('polylineOptions', polylineOptions);

        var rectangleOptions = this.drawingManager.get('rectangleOptions');
        rectangleOptions.fillColor = color;
        this.drawingManager.set('rectangleOptions', rectangleOptions);

        var circleOptions = this.drawingManager.get('circleOptions');
        circleOptions.fillColor = color;
        this.drawingManager.set('circleOptions', circleOptions);

        var polygonOptions = this.drawingManager.get('polygonOptions');
        polygonOptions.fillColor = color;
        this.drawingManager.set('polygonOptions', polygonOptions);
    }

    setSelectedShapeColor(color) {
        if (this.selectedShape) {
            if (
                this.selectedShape.type ==
                google.maps.drawing.OverlayType.POLYLINE
            ) {
                this.selectedShape.set('strokeColor', color);
            } else {
                this.selectedShape.set('fillColor', color);
            }
        }
    }

    makeColorButton(color) {
        var button = document.createElement('span');
        button.className = 'color-button';
        button.style.backgroundColor = color;
        google.maps.event.addDomListener(
            button,
            'click',
            function () {
                this.selectColor(color);
                this.setSelectedShapeColor(color);
            }.bind(this)
        );
        return button;
    }

    buildColorPalette() {
        var colorPalette = document.getElementById('color-palette');
        for (var i = 0; i < this.colors.length; ++i) {
            var currColor = this.colors[i];
            var colorButton = this.makeColorButton(currColor);
            colorPalette.appendChild(colorButton);
            this.colorButtons[currColor] = colorButton;
        }
        this.selectColor(this.colors[0]);
    }

    initialize() {
        var self  = this
        this.polygon_list.next([])

        this.map = new google.maps.Map(document.getElementById('map'), {
            zoom: 13,
            center: new google.maps.LatLng(self.contrat.contrat.coordonne.latitude,self.contrat.contrat.coordonne.longitude),
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            disableDefaultUI: true,
            zoomControl: true,
            fullscreenControl: true
        });




        for (let  [index,itemFlux] of (this.calendrier?.flux ??[]) .entries()){
            for (let element of itemFlux.zones){
                var paths_temp = []
                for (let item of element.polygones){
                    paths_temp.push(google.maps.geometry.encoding.decodePath(item))
                }

                var temp_plygon = new google.maps.Polygon({
                    paths: paths_temp,
                    strokeColor: "#808080",
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: "#808080",
                    fillOpacity: 0.35,
                });

                /*var infos = {
                    nomPolygone : "",
                    a_publier : true,
                    fluxCollecte : itemFlux.nom,
                    periodeType : "Personnalisée",
                    periode : "" ,
                    frequence : "Hebdomadaire",
                    fluxCarte : "",
                    jours : {
                        lundi : false,
                        mardi : false,
                        mercredi : false,
                        jeudi : false,
                        vendredi : false,
                        samedi : false,
                        dimanche : false
                    },

                    daysPerMonth : "",
                    horaires : '',
                    commentaire :"",
                    btn_pav : true,
                    libelleBouton : "",
                    description : "",
                    defaultLibelleBouton : "",
                    defaultDescription : "",
                    defaultFlux : "",
                    urlConsignes : ""
                } */

                temp_plygon.set("infos",element.infos)
                temp_plygon.setMap(this.map)
                temp_plygon.set('fluxType', itemFlux.nom)
                temp_plygon.set('index',this.polygon_list.value.length)
                temp_plygon.setOptions({
                    strokeColor: self.checkAllInfosExists(temp_plygon),
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: self.checkAllInfosExists(temp_plygon),
                    fillOpacity: 0.35,
                })
                this.polygon_list.value.push(temp_plygon)

                google.maps.event.addListener(temp_plygon,'click',function () {
                            self.currntPolygon.setOptions({
                                strokeColor: self.checkAllInfosExists(self.currntPolygon),
                                strokeOpacity: 0.8,
                                strokeWeight: 2,
                                fillColor: self.checkAllInfosExists(self.currntPolygon),
                                fillOpacity: 0.35,
                            })
                            self.currntPolygon = this
                            self.currntPolygon.setOptions({
                                strokeColor: "#f47000",
                                strokeOpacity: 0.8,
                                strokeWeight: 2,
                                fillColor: "#f47000",
                                fillOpacity: 0.35,
                            })
                            self.setSelection(self.currntPolygon);
                    })

                google.maps.event.addListener(temp_plygon,'rightclick',function (e) {
                    if(this.getEditable()){
                        self.clickedPolygonIndex = this.get('index')
                        self.clickedInfos = {...this.get('infos')}
                        self.event = e
                        self.mapContext.show(this, e.latLng);
                    }

                })
            }
        }






        this.newPolygon = new google.maps.Polygon({
            paths: [],
            strokeColor: "#f47000",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#f47000",
            fillOpacity: 0.35,
        });

        this.newPolygon.setMap(this.map);
        this.newPolygon.set('fluxType',null)
        this.newPolygon.set("infos", {
            nomPolygone : "",

            a_publier : true,
            fluxCollecte : "",
            periodeType : "Personnalisée",
            periode : "" ,
            frequence : "Hebdomadaire",
            fluxCarte : "",
            jours : {
                lundi : false,
                mardi : false,
                mercredi : false,
                jeudi : false,
                vendredi : false,
                samedi : false,
                dimanche : false
            },

            daysPerMonth : "",
            horaires : '',
            commentaire :"",
            btn_pav : true,
            libelleBouton : "",
            description : "",
            defaultLibelleBouton : "",
            defaultDescription : "",
            defaultFlux : "",
            urlConsignes : ""
        })
        this.newPolygon.set("index",this.polygon_list.value.length)
        this.polygon_list.value.push(this.newPolygon)

        google.maps.event.addListener(this.polygon_list.value[this.polygon_list.value.length -1],'click',function () {
            self.currntPolygon.setOptions({
                strokeColor: self.checkAllInfosExists(self.currntPolygon),
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: self.checkAllInfosExists(self.currntPolygon),
                fillOpacity: 0.35,
            })
            self.currntPolygon = this
            self.currntPolygon.setOptions({
                strokeColor: "#f47000",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "#f47000",
                fillOpacity: 0.35,
            })
            self.setSelection(self.currntPolygon);
        })


        google.maps.event.addListener(this.polygon_list.value[this.polygon_list.value.length -1],'rightclick',function (e) {
            if(this.getEditable()){
                self.clickedPolygonIndex = this.get('index')
                self.clickedInfos = {...this.get('infos')}
                self.event = e
                self.mapContext.show(this, e.latLng);
            }
        })


        var polyOptions = {
            strokeColor: "#f47000",
            strokeWeight: 0,
            fillOpacity: 0.45,
            editable: true,
            fillColor: "#f47000",
        };

        this.currntPolygon  = this.polygon_list.value[this.polygon_list.value.length -1]

        // Creates a drawing manager attached to the map that allows the user to draw
        // markers, lines, and shapes.
        this.drawingManager = new google.maps.drawing.DrawingManager({
            drawingMode: null,
            drawingControl: true,
            drawingControlOptions: {
                drawingModes: [
                google.maps.drawing.OverlayType.POLYGON
                ]
            },
            markerOptions: {
                draggable: true,
            },
            polylineOptions: {
                editable: true,
            },
            rectangleOptions: polyOptions,
            circleOptions: polyOptions,
            polygonOptions: polyOptions,
            map: this.map,
        });





        google.maps.event.addListener(
            this.drawingManager,
            'overlaycomplete',
            function (e) {
                if (e.type != google.maps.drawing.OverlayType.MARKER) {
                    // Switch back to non-drawing mode after drawing a shape.
                    self.drawingManager.setDrawingMode(null);

                    // Add an event listener that selects the newly-drawn shape when the user
                    // mouses down on it.

                    var newShape = e.overlay;
                    self.shapes.push(e.overlay.getPath())
                    var pathsList = self.currntPolygon.getPaths()
                    pathsList.push(e.overlay.getPath())
                    self.currntPolygon.setPaths(pathsList)
                    self.setSelection(newShape);
                    self.setSelection(self.currntPolygon)
                    var temp_poly = self.currntPolygon
                    newShape.setMap(null)
                    google.maps.event.addListener(temp_poly,'click',function (event ) {

                        self.currntPolygon.setOptions({
                            strokeColor: self.checkAllInfosExists(self.currntPolygon),
                            strokeOpacity: 0.8,
                            strokeWeight: 2,
                            fillColor: self.checkAllInfosExists(self.currntPolygon),
                            fillOpacity: 0.35,
                        })
                        self.currntPolygon = this
                        self.currntPolygon.setOptions({
                            strokeColor: "#f47000",
                            strokeOpacity: 0.8,
                            strokeWeight: 2,
                            fillColor: "#f47000",
                            fillOpacity: 0.35,
                        })
                        self.setSelection(self.currntPolygon);
                    })

                    google.maps.event.addListener(temp_poly,'rightclick',function (e) {
                        if(this.getEditable()){
                            self.clickedPolygonIndex = this.get('index')
                            self.clickedInfos = {...this.get('infos')}
                            self.event = e
                            self.mapContext.show(this, e.latLng);
                        }

                })
                }
            }
        );

        this.polygon_list_obs.subscribe((data)=>{
            for(let item  of this.flux){
                this.fluxCount[item.nom] = data.filter(element => element.get('infos').fluxCollecte == item.nom).length
            }
        })

    }


    createNewPolygon(){
        var self =  this ;
        if(this.polygon_list.value[this.polygon_list.value.length -1].getPaths().getLength() == 0){
            for(let [index,element] of this.polygon_list.value.entries()){
                this.polygon_list.value[index].setEditable(false)
                this.polygon_list.value[index].setOptions({
                    strokeColor: self.checkAllInfosExists(this.polygon_list.value[index]),
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: self.checkAllInfosExists(this.polygon_list.value[index]),
                    fillOpacity: 0.35,
                })
            }
            this.currntPolygon = this.polygon_list.value[this.polygon_list.value.length -1]
        }
        else{
            for(let [index,element] of this.polygon_list.value.entries()){
                    this.polygon_list.value[index].setEditable(false)
                    this.polygon_list.value[index].setOptions({
                        strokeColor: self.checkAllInfosExists(this.polygon_list.value[index]),
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: self.checkAllInfosExists(this.polygon_list.value[index]),
                        fillOpacity: 0.35,
                    })
            }
            this.newPolygon = new google.maps.Polygon({
                paths: [],
                strokeColor: "#f47000",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "#f47000",
                fillOpacity: 0.35,
            });
            this.newPolygon.set('index',this.polygon_list.value.length)
            this.newPolygon.setMap(this.map);
            this.newPolygon.set("infos",{
                nomPolygone : "",

                a_publier : true,
                fluxCollecte : "",
                periodeType : "Personnalisée",
                periode : "" ,
                frequence : "Hebdomadaire",
                fluxCarte : "",
                jours : {
                    lundi : false,
                    mardi : false,
                    mercredi : false,
                    jeudi : false,
                    vendredi : false,
                    samedi : false,
                    dimanche : false
                },

                daysPerMonth : "",
                horaires : '',
                commentaire :"",
                btn_pav : true,
                libelleBouton : "",
                description : "",
                defaultLibelleBouton : "",
                defaultDescription : "",
                defaultFlux : "",
                urlConsignes : ""
            })
            this.newPolygon.set('fluxType',null)

            google.maps.event.addListener(this.newPolygon,'click',function () {
                self.currntPolygon.setOptions({
                    strokeColor: self.checkAllInfosExists(self.currntPolygon),
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: self.checkAllInfosExists(self.currntPolygon),
                    fillOpacity: 0.35,
                })
                self.currntPolygon = this
                self.currntPolygon.setOptions({
                    strokeColor: "#f47000",
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: "#f47000",
                    fillOpacity: 0.35,
                })
                self.setSelection(self.currntPolygon);
            })


            google.maps.event.addListener(this.newPolygon,'rightclick',function (e) {
                if(this.getEditable()){
                    self.clickedPolygonIndex = this.get('index')
                    self.clickedInfos = {...this.get('infos')}
                    self.event = e
                    self.mapContext.show(this, e.latLng);
                }
            })
            this.polygon_list.value.push(this.newPolygon)
            this.currntPolygon =  this.polygon_list.value[this.polygon_list.value.length -1]
        }

        this.currntPolygon.setOptions({
            strokeColor: "#f47000",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#f47000",
            fillOpacity: 0.35,
        })
    }

    onChange(nom : string, show : boolean){
        for(let [index , item ] of this.polygon_list.value.entries()){
            if(item.get('infos').fluxCollecte != undefined && item.get('infos').fluxCollecte == nom){
                this.polygon_list.value[index].setVisible(show)
            }
        }

    }

    deleteOnePolygon(){
        var self  = this ;
        var allPaths = this.polygon_list.value[self.clickedPolygonIndex].getPaths()
        var pathsNotDelated = []
        allPaths.forEach(element => {
            if(!google.maps.geometry.poly.containsLocation(self.event.latLng,new google.maps.Polygon({ paths : element}))){
                pathsNotDelated.push(element)
            }
        });
        this.currntPolygon = this.polygon_list.value[this.polygon_list.value.length -1]
        self.currntPolygon.setOptions({
            strokeColor: "#f47000",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#f47000",
            fillOpacity: 0.35,
        })
        this.polygon_list.value[self.clickedPolygonIndex].setEditable(false)
        this.polygon_list.value[self.clickedPolygonIndex].setPaths(pathsNotDelated)

    }

    deletePolygonGroup(){
        this.currntPolygon = this.polygon_list.value[this.polygon_list.value.length -1]
        this.currntPolygon.setOptions({
            strokeColor: "#f47000",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#f47000",
            fillOpacity: 0.35,
        })
        this.polygon_list.value[this.clickedPolygonIndex].setEditable(false)
        this.polygon_list.value[this.clickedPolygonIndex].setPaths([])
    }

    copyOnePolygon(){
        var  self = this;

        var allPaths = this.polygon_list.value[self.clickedPolygonIndex].getPaths()
        var pathsNotDelated = []
        allPaths.forEach(element => {
            if(google.maps.geometry.poly.containsLocation(self.event.latLng,new google.maps.Polygon({ paths : element}))){
                    pathsNotDelated.push(element)
                }
        });

        for(let [index,element] of this.polygon_list.value.entries()){
            this.polygon_list.value[index].setEditable(false)
        }

        this.newPolygon = new google.maps.Polygon({
            paths: pathsNotDelated,
            strokeColor: "#f47000",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#f47000",
            fillOpacity: 0.35,
        });

        this.newPolygon.set('index',this.polygon_list.value.length)
        this.newPolygon.setMap(this.map);
        this.newPolygon.set("infos",{
            nomPolygone : "",

            a_publier : true,
            fluxCollecte : "",
            periodeType : "Personnalisée",
            periode : "" ,
            frequence : "Hebdomadaire",
            fluxCarte : "",
            jours : {
                lundi : false,
                mardi : false,
                mercredi : false,
                jeudi : false,
                vendredi : false,
                samedi : false,
                dimanche : false
            },

            daysPerMonth : "",
            horaires : '',
            commentaire :"",
            btn_pav : true,
            libelleBouton : "",
            description : "",
            defaultLibelleBouton : "",
            defaultDescription : "",
            defaultFlux : "",
            urlConsignes : ""
        })

        this.polygon_list.value.push(this.newPolygon)

        google.maps.event.addListener(self.polygon_list.value[self.polygon_list.value.length -1],'click',function () {
            self.currntPolygon.setOptions({
                strokeColor: self.checkAllInfosExists(self.currntPolygon),
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: self.checkAllInfosExists(self.currntPolygon),
                fillOpacity: 0.35,
            })
            self.currntPolygon = this
            self.currntPolygon.setOptions({
                strokeColor: "#f47000",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "#f47000",
                fillOpacity: 0.35,
            })
            self.setSelection(self.currntPolygon);
        })

        google.maps.event.addListener(self.polygon_list.value[self.polygon_list.value.length -1],'rightclick',function (e) {
            if(this.getEditable()){
                self.clickedPolygonIndex = this.get('index')
                self.clickedInfos = {...this.get('infos')}
                self.event = e
                self.mapContext.show(this, e.latLng);
            }
        })


        this.currntPolygon =  this.polygon_list.value[this.polygon_list.value.length -1]
        self.setSelection(self.currntPolygon);


    }

    copyPolygoneGroup(){
        var  self = this;
        var allPaths = this.polygon_list.value[self.clickedPolygonIndex].getPaths()

        for(let [index,element] of this.polygon_list.value.entries()){
                this.polygon_list.value[index].setEditable(false)
        }

        this.newPolygon = new google.maps.Polygon({
            paths: allPaths,
            strokeColor: "#f47000",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#f47000",
            fillOpacity: 0.35,
        });

        this.newPolygon.set('index',this.polygon_list.value.length)
        this.newPolygon.setMap(this.map);
        this.newPolygon.set("infos",{
            nomPolygone : "",

            a_publier : true,
            fluxCollecte : "",
            periodeType : "Personnalisée",
            periode : "" ,
            frequence : "Hebdomadaire",
            fluxCarte : "",
            jours : {
                lundi : false,
                mardi : false,
                mercredi : false,
                jeudi : false,
                vendredi : false,
                samedi : false,
                dimanche : false
            },

            daysPerMonth : "",
            horaires : '',
            commentaire :"",
            btn_pav : true,
            libelleBouton : "",
            description : "",
            defaultLibelleBouton : "",
            defaultDescription : "",
            defaultFlux : "",
            urlConsignes : ""
        })
        this.polygon_list.value.push(this.newPolygon)

        google.maps.event.addListener(self.polygon_list.value[self.polygon_list.value.length -1],'click',function () {
            self.currntPolygon.setOptions({
                strokeColor: self.checkAllInfosExists(self.currntPolygon),
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: self.checkAllInfosExists(self.currntPolygon),
                fillOpacity: 0.35,
            })
            self.currntPolygon = this
            self.currntPolygon.setOptions({
                strokeColor: "#f47000",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "#f47000",
                fillOpacity: 0.35,
            })
            self.setSelection(self.currntPolygon);
        })

        google.maps.event.addListener(self.polygon_list.value[self.polygon_list.value.length -1],'rightclick',function (e) {
            if(this.getEditable()){
                self.clickedPolygonIndex = this.get('index')
                self.clickedInfos = {...this.get('infos')}
                self.event = e
                self.mapContext.show(this, e.latLng);
            }
        })


        this.currntPolygon =  this.polygon_list.value[this.polygon_list.value.length -1]
        self.setSelection(self.currntPolygon);
    }

    openModalInfos(){
        this.polygonError = false
        if(this.clickedInfos.periode == "" ){
            this.toDate = null
            this.fromDate = null
        }
        else{
            this.toDate = this.formatter.parse(this.clickedInfos.periode.split(" ")[3]) as NgbDate
            this.fromDate = this.formatter.parse(this.clickedInfos.periode.split(" ")[1]) as NgbDate
        }

        // Set default text and bouton if the flow is not found
        for(let index = 0; index < (this.contartservice.calendrier.value?.flux ?? []).length; ++index) {
            if (this.contartservice.calendrier.value.flux[index].nom == this.clickedInfos.fluxCollecte) {
                this.clickedInfos.urlConsignes = this.contartservice.calendrier.value.flux[index].url
                this.clickedInfos.defaultDescription = this.contartservice.calendrier.value.flux[index].texte
                this.clickedInfos.defaultLibelleBouton = this.contartservice.calendrier.value.flux[index].libelleBouton
                this.clickedInfos.defaultFlux = this.contartservice.calendrier.value.flux[index].flux
                break
            }
        }

        this.modal_ref =this.modalService.open(this.polygonActions, {backdrop: 'static', keyboard: false, centered: true});
    }

    savePolygonInfos(){
        this.clickedInfos.periode = this.formatter.formatTwoDate(this.fromDate,this.toDate)
        this.polygon_list.value[this.clickedPolygonIndex].set('infos',this.clickedInfos)
        this.modal_ref.close()
        for(let item  of this.flux){
            this.fluxCount[item.nom] = this.polygon_list.value.filter(element => element.get('infos').fluxCollecte == item.nom).length
        }
        // Set default text and bouton if the flow is not found
        for(let index = 0; index < (this.contartservice.calendrier.value?.flux ?? []).length; ++index) {
            if (this.contartservice.calendrier.value.flux[index].nom == this.clickedInfos.fluxCollecte) {
                this.contartservice.calendrier.value.flux[index].url = this.clickedInfos.urlConsignes
                this.contartservice.calendrier.value.flux[index].texte = this.clickedInfos.defaultDescription
                this.contartservice.calendrier.value.flux[index].libelleBouton = this.clickedInfos.defaultLibelleBouton
                this.contartservice.calendrier.value.flux[index].flux = this.clickedInfos.defaultFlux
                break
            }
        }
    }

    updateFlow() {
        // Set default text and bouton if the flow is not found
        for(let index = 0; index < (this.contartservice.calendrier.value?.flux ?? []).length; ++index) {
            if (this.contartservice.calendrier.value.flux[index].nom == this.clickedInfos.fluxCollecte) {
                this.clickedInfos.urlConsignes = this.contartservice.calendrier.value.flux[index].url ?? ""
                this.clickedInfos.defaultDescription = this.contartservice.calendrier.value.flux[index].texte ?? ""
                this.clickedInfos.defaultLibelleBouton = this.contartservice.calendrier.value.flux[index].libelleBouton ?? ""
                this.clickedInfos.defaultFlux = this.contartservice.calendrier.value.flux[index].flux ?? ""
                break
            }
        }
    }

    cancelModalInfos(){

         this.modal_ref.close()
    }

    mapAction(param) {
        switch (param.action) {
            case "infosPolygon":
                this.openModalInfos()
                break;
            case "deleteOnePlygon":
                this.deleteOnePolygon()
                break;
            case "deletePolygonGroup":
                this.deletePolygonGroup()
                break;
            case "copyOnePolygon":
                this.copyOnePolygon()
                break;
            case "copyPolygonGroup":
              this.copyPolygoneGroup()
              break;
            case "quite":
                this.quite()
                break;
            case "cancel":
              break;

          }
    }


    change(){

    }

    onDateSelection(date: NgbDate) {

        if (!this.fromDate && !this.toDate) {
          this.fromDate = date;
        } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
          this.toDate = date;
        } else {
          this.toDate = null;
          this.fromDate = date;
        }
      }

      isHovered(date: NgbDate) {
        return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
      }

      isInside(date: NgbDate) {
        return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
      }

      isRange(date: NgbDate) {
        return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
      }

      validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
        const parsed = this.formatter.parse(input);
        return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
      }

    //check if all infos exite  in  polygon
    checkAllInfosExists(polygonToVerifiy : google.maps.Polygon){

        var infosPolygonToVerifiy =  polygonToVerifiy.get('infos');

        if(infosPolygonToVerifiy.btn_pav){
            if(infosPolygonToVerifiy.fluxCarte == ''){
                return '#FF0000';
            }
        }

        return  (infosPolygonToVerifiy.nomPolygone != ""  &&  infosPolygonToVerifiy.fluxCollecte != "" && ((infosPolygonToVerifiy. periode !="" &&infosPolygonToVerifiy.periodeType == "Personnalisée" ) || (infosPolygonToVerifiy. periode =="" &&infosPolygonToVerifiy.periodeType == "Toute l\'année") ) && infosPolygonToVerifiy.frequence != "" /*&&

                (infosPolygonToVerifiy.jours.lundi|| infosPolygonToVerifiy.jours.mardi||infosPolygonToVerifiy.jours.mercredi ||infosPolygonToVerifiy.jours.jeudi|| infosPolygonToVerifiy.jours.vendredi||
                infosPolygonToVerifiy.jours.samedi || infosPolygonToVerifiy.jours.dimanche)*/ )?  "#808080" : "#FF0000"
    }

    async savePolygons(){
        var check = 0
        for (let polygon of this.polygon_list.value ){
            if(polygon.getPaths().getLength()>0 && this.checkAllInfosExists(polygon) == "#FF0000"){
                this.toastr.openFailedToast(`Chargement échoué. Veuillez Remplir les informations de tout les polygones.`, "");
                return null
            }
        }
        var listToSave = []
        for (let polygon of this.polygon_list.value){
            if(polygon.getPaths().getLength()>0){
                check = 1
                var polygonToSave  = {
                    infos : polygon.get('infos'),
                    polygones : []
                }
            

                polygon.getPaths().forEach((path) =>{
                    polygonToSave.polygones.push(google.maps.geometry.encoding.encodePath(path))
                })

                listToSave.push(polygonToSave)
            }
        }

        for (let  [index,itemFlux] of this.contartservice.calendrier.value.flux.entries()){
            this.contartservice.calendrier.value.flux[index].zones = listToSave.filter(element => element.infos.fluxCollecte == itemFlux.nom)
        }

        this.contartservice.saveCalendrier('')
        if(check == 1){
            let ref = await this.toastr.openTransferToast(`Transfert de données en cours.`, "");
            this.contrat.configstatus[7].status = true
            this.contartservice.setContrat(this.contrat)
            let status = await this.contartservice.saveContrat('')
            if(status === "success"){
                this.toastr.clear(ref.toastId)
                this.toastr.openSuccessToast('Données enregistrées avec succès', "");
            }else{
                this.toastr.clear(ref.toastId)
                this.toastr.openErrorToast(`Chargement échoué. Veuillez verifier votre fichier et réessayer.`, "");
            }
        }
    }

    quite() {
        var self =  this ;
        if(self.polygon_list.value[this.polygon_list.value.length -1].getPaths().getLength() == 0){
            for(let [index,element] of this.polygon_list.value.entries()){
                this.polygon_list.value[index].setEditable(false)
                this.polygon_list.value[index].setOptions({
                    strokeColor: self.checkAllInfosExists(this.polygon_list.value[index]),
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: self.checkAllInfosExists(this.polygon_list.value[index]),
                    fillOpacity: 0.35,
                })
            }
            this.currntPolygon = this.polygon_list.value[this.polygon_list.value.length -1]
        }
        else{
            for(let [index,element] of this.polygon_list.value.entries()){
                    this.polygon_list.value[index].setEditable(false)
                    this.polygon_list.value[index].setOptions({
                        strokeColor: self.checkAllInfosExists(this.polygon_list.value[index]),
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: self.checkAllInfosExists(this.polygon_list.value[index]),
                        fillOpacity: 0.35,
                    })
            }
            this.newPolygon = new google.maps.Polygon({
                paths: [],
                strokeColor: "#f47000",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "#f47000",
                fillOpacity: 0.35,
            });
            this.newPolygon.set('index',this.polygon_list.value.length)
            this.newPolygon.setMap(this.map);
            this.newPolygon.set("infos",{
                nomPolygone : "",

                a_publier : true,
                fluxCollecte : "",
                periodeType : "Personnalisée",
                periode : "" ,
                frequence : "Hebdomadaire",
                fluxCarte : "",
                jours : {
                    lundi : false,
                    mardi : false,
                    mercredi : false,
                    jeudi : false,
                    vendredi : false,
                    samedi : false,
                    dimanche : false
                },

                daysPerMonth : "",
                horaires : '',
                commentaire :"",
                btn_pav : true,
                libelleBouton : "",
                description : "",
                defaultLibelleBouton : "",
                defaultDescription : "",
                defaultFlux : "",
                urlConsignes : ""
            })
            this.newPolygon.set('fluxType',null)

            google.maps.event.addListener(this.newPolygon,'click',function () {
                self.currntPolygon.setOptions({
                    strokeColor: self.checkAllInfosExists(self.currntPolygon),
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: self.checkAllInfosExists(self.currntPolygon),
                    fillOpacity: 0.35,
                })
                self.currntPolygon = this
                self.currntPolygon.setOptions({
                    strokeColor: "#f47000",
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: "#f47000",
                    fillOpacity: 0.35,
                })
                self.setSelection(self.currntPolygon);
            })


            google.maps.event.addListener(this.newPolygon,'rightclick',function (e) {
                if(this.getEditable()){
                    self.clickedPolygonIndex = this.get('index')
                    self.clickedInfos = {...this.get('infos')}
                    self.event = e
                    self.mapContext.show(this, e.latLng);
                }
            })
            this.polygon_list.value.push(this.newPolygon)
            this.currntPolygon =  this.polygon_list.value[this.polygon_list.value.length -1]
        }
    }
}




import { Service } from './../../../../../models/contrat/contrat';
import { element } from 'protractor';
import { MsgserviceService } from './../../../../../services/msgservice/msgservice.service';
import { ContratService } from './../../../../../services/contrat/contrat.service';
import { SliderService } from './../../../../../services/slider/slider.service';
import { AlertService } from './../../../../../services/alert/alert.service';
import { LodingBarService } from './../../../../../services/lodingbar/loding-bar-service.service';
import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-serviceinfos',
    templateUrl: './serviceinfos.component.html',
    styleUrls: ['./serviceinfos.component.scss']
})
export class ServiceinfosComponent implements OnInit {

    titre : string ='' ;
    description1 : string = '';
    description2 : string ='';
    current_service : string ;
    @Input() services : Service[]

    constructor(
        private lodingbarservice: LodingBarService,
        private alertservice: AlertService,
        private sliderService: SliderService,
        private contratservice: ContratService,
        private msgservice : MsgserviceService,
        public activeModal: NgbActiveModal
    ) { }

    ngOnInit(): void {
        this.msgservice.service_obs.subscribe((msg)=>{
                this.titre  = this.services.filter(item=>item.nom == msg)[0].titre
                this.description1  = this.services.filter(item=>item.nom == msg)[0].description1
                this.description2  = this.services.filter(item=>item.nom == msg)[0].description2
                this.current_service = msg

        })
    }

    changeinput(){

    }

    saveservice(){
        for(let [index,element] of this.services.entries()){
            if(element.nom == this.current_service){
                this.services[index].titre = this.titre
                this.services[index].description1 = this.description1
                this.services[index].description2 = this.description2
                this.activeModal.close(this.services)
            }
        }
    }

    cancel(){
        this.activeModal.close()
    }
}

<div class="row">
    <div class="col-12 d-flex flex-column justify-content-center align-items-lg-start align-items-center">
        <p class="pb-0 mb-0  h3">Désactivez vos PAV en travaux :</p>
        <p class="pb-0 mb-0 mt-0 p2">Recherchez un point de collecte en renseignant son ID interne, et desactivez le momentanément avec un motif.</p>
    </div>
</div>

<div>
    <div class="ng-autocomplete" style="right: 26%;position: relative;margin-bottom: 3%;margin-top: 2%;">
        <ng-autocomplete
          [data]="temp"
          [searchKeyword]="keyword"
          placeholder="ID interne du PAV"
          (selected)='selectEvent($event)'
          (inputChanged)='onChangeSearch($event)'
          historyIdentifier="temp"
          historyHeading = "Récemment séléctionné"
          [itemTemplate]="itemTemplate"
          historyListMaxNumber = 3
          [notFoundTemplate]="notFoundTemplate">
        </ng-autocomplete>
      
        <ng-template #itemTemplate let-item>
          <a [innerHTML]="item.id"></a>
        </ng-template>
      
        <ng-template #notFoundTemplate let-notFound>
            <div>
                <p style="display: inline;">ID introuvable</p>
            </div>
        </ng-template>
      </div>

   
      <ng-container *ngIf='selectedItem'>
        <div class="col-xl-4 col-sm-12 col-12 mt-xl-0 mt-3">
            <div class="card card_radius" style="height: 300px;width: 110%;left: -5%;">
                <div class="card-content">
                    <div class="card-body flex-column pb-0">

                        <div class="d-flex flex-row justify-content-between">
                            <div class="media-body text-left">
                                <p class="h2" style="text-align: left;">ID interne : {{selectedItem.id}}</p>
                            </div>
                        </div>

                        <div class="d-flex flex-row justify-content-around">
                            <div class="d-flex flex-column justify-content-center align-items-center">
                                <div class="d-flex flex-column justify-content-center align-items-center"
                                    id="JSFiddle3">
                                </div>
                                <div class="" style="margin-top: 40%;width: 100%;position: absolute;margin-left: 10%;">
                                    <p style="font-size: 15px;">Nom: {{selectedItem.nom}}</p>
                                    <p *ngIf='selectedItem.flux' style="font-size: 15px;">Flux: {{selectedItem.flux}}</p>
                                    <p style="font-size: 15px;">{{selectedItem.adresseVoie}}, {{selectedItem.ville}}</p>
                                </div>
                                
                                <div class="text-center">
                                    <div class="spinner-border text-primary"
                                        style="width: 3rem; height: 3rem;margin-bottom: 160px;margin-top: 160px;"
                                        [hidden]="true">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <p class="h3" style="margin-top: 48%;">Statut de la borne :</p>
                        <div style="margin-top: 4%;">
                            <button type="button" (click)='changeState("opérationnel")' [ngClass]="(pavState === 'opérationnel' ? 'selectedButtonOp' : 'notSelectedButton')" style="margin-right: 10px;" id="stateButton" class="btn btn-primary">✅ Opérationnel</button>
                            <button type="button" (click)='changeState("travaux")' [ngClass]="(pavState === 'travaux' ? 'selectedButtonTrv' : 'notSelectedButton')" style="margin-right: 10px;"  id="stateButton" class="btn btn-primary">⛔️ En travaux</button>
                            <button type="button" (click)='changeState("maintenance")' [ngClass]="(pavState === 'maintenance' ? 'selectedButtonMtn' : 'notSelectedButton')"  id="stateButton" class="btn btn-primary">🛠 Maintenance</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <ng-container *ngIf='stateChanged'>
                <button type="button" style="right:828px;
                position: fixed;
                width: 18%;
                border-radius: 10px;
                margin-top: 20px;" (click)='registerPav()' class="btn btn-success">Enregistrer</button>
            </ng-container>
            <ng-container *ngIf='!stateChanged'>
                <button type="button" disabled style="right:828px;
                position: fixed;
                width: 18%;
                border-radius: 10px;
                margin-top: 20px;" class="btn btn-success">Enregistrer</button>
            </ng-container>
        </div>
      </ng-container>
        
</div>
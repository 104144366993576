
<div class="modal-content  border_modal" >
    <div class="modal-header d-flex flex-column" style='border: none;'>
        <P class="avenir_light form-check-label">Vous n’avez pas activer ce service. Pour le proposer à vous usagers et le paramétrer, veuillez l’activer</P>
    </div>
    <div class="modal-footer" style='border: none;'>
        <button type="button" class="btn btn-secondary button3" (click)="closeModal()">Annuler</button>
        <button type="submit" class="btn btn-primary button1" (click)="gotourl()">Activer</button>
    </div>
</div>

import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ContratService } from './../../../../services/contrat/contrat.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { Report } from 'src/app/models/report/report';

@Component({
  selector: 'app-imgReportPopup',
  templateUrl: './imgReportPopup.component.html',
  styleUrls: ['./imgReportPopup.component.scss']
})
export class ImgReportPopupComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal,
    private contratservice: ContratService,
    private  afStorage : AngularFireStorage
  ) { }

    @Input() reportImageUrl: string;

    ngOnInit(): void {
    }

  cancel(){
    this.activeModal.close()
  }

}

import { InformationsComponent } from './components/informations/informations.component';
import { RecyclingInstructionComponent } from './components/recycling-instruction/recycling-instruction.component';
import { DesignComponent } from './components/design/design.component';
import { SliderComponent } from './components/slider/slider.component';
import { ServicesComponent } from './components/services/services.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContractsettingsComponent } from './components/contractsettings/contractsettings.component';


const routes: Routes = [


];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ContractsettingsRoutingModule { }

import { MenuService } from './../../../../services/menu/menu.service';
import { SliderService } from './../../../../services/slider/slider.service';
import { Contrat } from 'src/app/models/contrat/contrat';
import { element } from 'protractor';
import { ContratService } from './../../../../services/contrat/contrat.service';
import { Router } from '@angular/router';
import { TabmessageService } from './../../../../services/tabmessage/tabmessage.service';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';

@Component({
    selector: 'app-contractsettings',
    templateUrl: './contractsettings.component.html',
    styleUrls: ['./contractsettings.component.scss']
})
export class ContractsettingsComponent implements OnInit {

    public sectionname : string;
    contrat : Contrat

    constructor(
        private sectionservice: TabmessageService,
        private router : Router,
        private changeDetector: ChangeDetectorRef,
        private contratservice : ContratService,
        private sliderService : SliderService,
        private menuservice : MenuService

    ) {
       this.sectionservice.ngOnInit()
     }

    ngOnInit(): void {
        window.scroll(0,0)
        var  self = this ;
        this.menuservice.setSection("paramétrage");
        this.menuservice.setSubsection("territoire");
        this.sectionservice.secttion_obs.subscribe((section)=>{
                self.sectionname =  section;
                self.changeDetector.detectChanges();

        })
        this.contratservice.contrat_obs.subscribe((contrat)=>{
            this.contrat = contrat
            this.sliderService.setTempServices(contrat.services)
        })
    }

    gotourl(s : string){
        this.router.navigateByUrl(s)
    }
}

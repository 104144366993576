import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserModule } from '@angular/platform-browser';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoogleChartsModule } from 'angular-google-charts';
import { GoogleMapsModule } from '@angular/google-maps'
import { ContractstatsRoutingModule } from './contractstats-routing.module';
import { ChartsModule } from 'ng2-charts';
import { DatePipe } from '@angular/common';
import { FullstatsComponent } from './components/fullstats/fullstats.component';
import { AppstatsComponent } from './components/appstats/appstats.component';
import { FluxstatsComponent } from './components/fluxstats/fluxstats.component';
import { ReportingstatsComponent } from './components/reportingstats/reportingstats.component';
import { ReportaccessComponent } from './../user-module/components/reportaccess/reportaccess.component';
import { ResportinglistComponent } from './components/resportinglist/resportinglist.component';
import { ReportingComponent } from './modals/reporting/reporting.component';
import { FilterPointsPipe } from './pipes/filter-points.pipe';


@NgModule({
  declarations: [FullstatsComponent, AppstatsComponent, ReportaccessComponent,FluxstatsComponent, ReportingstatsComponent, ResportinglistComponent, ReportingComponent, FilterPointsPipe],
  providers: [DatePipe],
  imports: [

    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserModule,
    GoogleMapsModule,
    ChartsModule,
    NgbModule,
    GoogleChartsModule.forRoot(),
    AngularSvgIconModule.forRoot(),
    ContractstatsRoutingModule
  ]
})
export class ContractstatsModule { }

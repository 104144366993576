import { AuthGuardService  as AuthGuard} from './services/guards/auth-guard.service';
import { WelcomeComponent } from './modules/main/components/welcome/welcome.component';
import { AppComponent } from './app.component';
import { SignInComponent } from './modules/user-module/components/sign-in/sign-in.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule,CanActivate  } from '@angular/router';



const routes: Routes = [
    {path: '', redirectTo:'/montri/welcome', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

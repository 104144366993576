import { InvitID } from './../../../../models/invit/invit';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from './../../../../services/user/user.service';
import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-sendinvit',
    templateUrl: './sendinvit.component.html',
    styleUrls: ['./sendinvit.component.scss']
})
export class SendinvitComponent implements OnInit {

    @Input() invit : InvitID

    constructor(
        private userService: UserService,
        public activeModal: NgbActiveModal
    ) { }

    ngOnInit(): void {
    }

    sendinvit() {
        this.userService.resendInvit(this.invit)
        this.activeModal.close()
    }

    closeModal() {
        this.activeModal.close()
    }

}

import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import * as firebase from 'firebase';
import { Contrat } from 'src/app/models/contrat/contrat';
import { Document } from 'src/app/models/document/document';
import { UserBo } from 'src/app/models/user/user';
import { ContratService } from 'src/app/services/contrat/contrat.service';
import { MenuService } from 'src/app/services/menu/menu.service';
import { UserService } from 'src/app/services/user/user.service';
import { DeleteDocumentComponent } from './modals/delete-document/delete-document.component';
import { DocumentComponent } from './modals/document/document.component';

@Component({
  selector: 'app-uploadocuments',
  templateUrl: './uploadocuments.component.html',
  styleUrls: ['./uploadocuments.component.scss']
})
export class UploadocumentsComponent implements OnInit {
  contrat: Contrat;
  user :  UserBo
  doc_list : Document[] = [];
  doc : Document


  constructor(
    private contartservice : ContratService,
    private userservice : UserService,
    private modalService: NgbModal,
    private menuservice : MenuService,
  ) { }

  ngOnInit(): void {
    this.menuservice.setSection("documents");
    this.userservice.user_obs.subscribe((user)=>{
      this.user = user
      this.contartservice.contrat_obs.subscribe((data)=>{
        this.contrat = data
        let documentService = this.contrat.services.find(service => service.nom == 'Documents')
        if(documentService){
          this.doc_list = documentService.documents
        }
      })
    })
   
  }
  
  capitalize(s) {
    if (typeof s !== 'string') return ''
    return s.toUpperCase()
  }

  getIcon(index: string){
    const type = this.doc_list[index].type
    switch (type) {
      case "Informations":
        return "../../../assets/img/document-pdf.svg"

      case "Règlement":
        return "../../../assets/img/document-contract.svg"

      case "Formulaire":
        return "../../../assets/img/document-form.svg"

      case "Calendrier":
        return "../../../assets/img/document-agenda.svg"

      default:
        return "../../../assets/img/document-type.svg"

    }
  }


  addDocument(){
    window.scrollTo(0, 0);
		let options: NgbModalOptions = {};
    options.centered = true
    this.modalService.dismissAll()
    const modalRef =this.modalService.open(DocumentComponent,options)
    modalRef.componentInstance.contrat = this.contrat
  }

  updateDocument(id : number){
    this.doc = this.doc_list[id]
    window.scrollTo(0, 0);
    let options: NgbModalOptions = {};
    options.centered = true
    this.modalService.dismissAll()
    const modalRef = this.modalService.open(DocumentComponent,options)
    modalRef.componentInstance.document = this.doc
    modalRef.componentInstance.contrat = this.contrat
    modalRef.componentInstance.update = true
  }

  deleteDocument(id : number){
    this.doc = this.doc_list[id]
    var self  = this ;
    window.scrollTo(0, 0);
		let options: NgbModalOptions = {};
    options.centered = true
		this.modalService.dismissAll()
        const modalRef = this.modalService.open(DeleteDocumentComponent,options)
        modalRef.result.then(async (result) => {
            if (result) {
                this.contartservice.deleteDocument(`${this.doc.chemin}`)
                this.doc_list.splice(id, 1)
                this.contartservice.saveContrat('')
            }
        })
  }

}

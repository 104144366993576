import { Actu } from './../../../../models/actus/actus';
import { ActusService } from './../../../../services/actus/actus.service';
import { UserBo } from './../../../../models/user/user';
import { UserService } from './../../../../services/user/user.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { DeleteactusComponent } from './../../modals/deleteactus/deleteactus.component';
import { MenuService } from './../../../../services/menu/menu.service';
import { Contrat } from 'src/app/models/contrat/contrat';
import { ContratService } from './../../../../services/contrat/contrat.service';
import { ActusinfosComponent } from './../../modals/actusinfos/actusinfos.component';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit } from '@angular/core';
import { ToastAlertsComponent } from 'src/app/modules/main/components/toast-alerts/toast-alerts.component';

@Component({
    selector: 'app-actussettings',
    templateUrl: './actussettings.component.html',
    styleUrls: ['./actussettings.component.scss']
})
export class ActussettingsComponent implements OnInit {

    page = 1;
    pageSize = 4;
    collectionSize : number;
    contrat : Contrat;
    actus_list : Actu[] = [];
    actus : Actu[] = [];
    user :  UserBo

    constructor(
        private modalService: NgbModal,
        private contratservice : ContratService,
        private menuservice : MenuService,
        private userservice : UserService,
        private actusservice : ActusService,
        private afstorage : AngularFireStorage,
        private toastr: ToastAlertsComponent
    ) {}

    ngOnInit(): void {
        var self = this ;
        this.menuservice.setSection("paramétrage");
        this.menuservice.setSubsection("actus");
        this.userservice.user_obs.subscribe((user)=>{
            this.user = user
            this.contratservice.contrat_obs.subscribe((contrat)=>{
                this.contrat = contrat
                this.actusservice.loadActus(this.contrat.contrat.idContrat)
                this.actusservice.actus_obs.subscribe((data) =>{
                    if(data != null){
                        this.actus_list = data.actualites.sort((a, b) => new Date(b.date.seconds).getTime() - new Date(a.date.seconds).getTime());
                        this.collectionSize = this.actus_list.length
                        self.refreshActus();
                    }
                })

            })
        })
    }

    refreshActus() {
        this.actus = this.actus_list
            .map((actu, i) => ({ id: i + 1, ...actu }))
            .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
    }

    capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    onchange(evt,i){
        this.actus_list[i] = this.actus[i]
        this.actusservice.actus.value.actualites = [...this.actus_list]
        this.actusservice.saveActus(this.contrat.contrat.idContrat)
    }

    showModal(actusid : number){
        window.scrollTo(0, 0);
		let options: NgbModalOptions = {};
        options.centered = true
		this.modalService.dismissAll()
        const modalRef = this.modalService.open(ActusinfosComponent,options)
        modalRef.componentInstance.actus = this.actus_list;
        modalRef.componentInstance.actusid = null;
        modalRef.componentInstance.idContrat = this.contrat.contrat.idContrat
    }

    updateActus(id : number){
        window.scrollTo(0, 0);
		let options: NgbModalOptions = {};
        options.centered = true
		this.modalService.dismissAll()
        const modalRef = this.modalService.open(ActusinfosComponent,options)
        modalRef.componentInstance.actus = this.actus_list;
        modalRef.componentInstance.actusid = id;
        modalRef.componentInstance.idContrat = this.contrat.contrat.idContrat
    }

    deleteactus(k){
        var self  = this ;
        window.scrollTo(0, 0);
		let options: NgbModalOptions = {};
        options.centered = true
		this.modalService.dismissAll()
        const modalRef = this.modalService.open(DeleteactusComponent,options)
        modalRef.result.then(async (result) => {
            if (result) {
                if((this.actus.length == 1) && this.page>1){
                    this.page = this.page-1
                }
                var ref = self.afstorage.ref(self.contrat.contrat.idContrat + "/actualites/"+this.actus_list[k].image);
                ref.delete()
                this.actus_list = this.actus_list.filter(obj => obj!=this.actus_list[k])
                this.actusservice.actus.value.actualites = [...this.actus_list]
                let status = await this.actusservice.saveActus(this.contrat.contrat.idContrat)
                if(status === 'success'){
                    this.toastr.openSuccessToast("Actualité supprimée avec succès","")
                }else{
                    this.toastr.openErrorToast("Suppression echouée, veuillez réessayer","")
                }
            }
        })
    }
}

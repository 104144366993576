import { Component } from '@angular/core';

import { Toast, ToastrService, ToastPackage } from 'ngx-toastr';

let transfer = '../../../../../assets/img/alerts/transfer.svg'

@Component({
  selector: '[bootstrap-toast-component]',
  template: `
  
  <div class="parent" class="toast-body" style='
  padding: .75rem;
  word-wrap: break-word;
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 14px;
  grid-row-gap: 0px;'>
      <div class="div1" style='grid-area: 1 / 1 / 2 / 2;'> 
        <img src='${transfer}' style="width: 75%;display: table-cell;
        vertical-align: middle;
        text-align: center;" >
      </div>
      <div class="div2" style='grid-area: 1 / 2 / 2 / 3;display: inline;
      margin-top: 4%;font-family: avenir_heavy;
      text-align: center;'>
        <p style='display: inline;
        margin-top: auto;'>{{ message}}</p>
      </div>
      <div class="div3" style=' grid-area: 1 / 3 / 2 / 4;text-align: center;'>
      <img *ngIf="options.closeButton" src='../../../../../assets/img/alerts/close.svg' style="width: 20%;margin-left: auto;
      margin-right: 5%;
      display: block;
      margin-top: 28%;"
        (click)='remove()'>
      </div>
  </div> 
  `,
  preserveWhitespaces: false,
})
export class TransferToast extends Toast {
  // used for demo purposes
  undoString = 'undo';

  // constructor is only necessary when not using AoT
  constructor(protected toastrService: ToastrService, public toastPackage: ToastPackage) {
    super(toastrService, toastPackage);
  }

  // Demo click handler
  handleClick(event: Event) {
    event.stopPropagation();
    this.undoString = 'undid';
    this.toastPackage.triggerAction();
    return false;
  }
}

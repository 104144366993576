<div class="row" *ngIf="contrat!=undefined && contrat.contrat.webApp">
    <div class="col-lg-5">
        <div class="row">
            <div class="col-12 d-flex flex-column justify-content-center align-items-lg-start align-items-center">
                <p class="pb-0 mb-0  h3" style="text-align: center;">Logo de votre site web Montri : </p>
                <p class="pb-0 mb-0 mt-0 p2" style="text-align: center;">C’est le logo qui s’affichera sur le site
                    www.[votrecollectivité].montri.fr </p>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-lg-12  d-flex flex-row justify-content-center justify-content-lg-start">
                <div class="row w-100">

                    <div class="col-lg-6  d-flex flex-row justify-content-center justify-content-lg-start " *ngIf="user!=undefined && contrat != undefined">
                        <img src="../../../../../assets/img/Boutons/002-Delete-icon.svg"  [ngStyle]="{'cursor' :  ((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification') ? 'not-allowed' : null }"
                        style="width: 35px;height: 35px;"   (click)="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')?false : fileInput.value=null; ((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification') ? false :deletelogowebapp()" [hidden]="contrat.localfiles == undefined || contrat.localfiles.localLogoWeb == '' ">
                        <input type="file" name="file" #fileInput id="file" class="inputfile"  [disabled]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')"
                            (change)="sendWebappLogo(fileInput.files[0])" />
                        <label for="file"
                            class="d-flex flex-row w-100  d-flex flex-row justify-content-center justify-content-lg-start ">
                            <img src="../../../../../assets/img/028-addelementleft.svg"  [ngStyle]="{'cursor' :  ((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification') ? 'not-allowed' : null }"
                                style="width: 35px;height: 35px;"  [hidden]="contrat.localfiles != undefined && contrat.localfiles.localLogoWeb != ''" >

                            <input type="text"  name="file" class="form-control classname w-100 h4"   [disabled]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')"
                                style="height: 35px;border-radius: 0 10px 10px 0 !important;color: ;"
                                value="{{ contrat.localfiles != undefined && contrat.localfiles.localLogoWeb != '' ? contrat.localfiles.localLogoWeb  : 'Charger une image'}}" readonly />
                        </label>
                    </div>
                    <div class="col-lg-6  d-flex flex-column  justify-content-center p3 align-items-lg-start align-items-center" style="height: 35px;">
                        Format .png (300px * 100px)
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-5">
            <div class="col-12 d-flex flex-column justify-content-center align-items-lg-start align-items-center">
                <p class="pb-0 mb-0  h3" style="text-align: center;">Photo de couverture de votre site web Montri
                    : </p>
                <p class="pb-0 mb-0 mt-0 p2" style="text-align: center;">C’est l’image de couverture qui s’affiche
                    en page d’accueil de votre site. </p>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-lg-12  d-flex flex-row justify-content-center justify-content-lg-start">
                <div class="row w-100">
                    <div class="col-lg-6  d-flex flex-row justify-content-center justify-content-lg-start" *ngIf="user!=undefined && contrat !=undefined">
                        <img src="../../../../../assets/img/Boutons/002-Delete-icon.svg"  [ngStyle]="{'cursor' :  ((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification') ? 'not-allowed' : null }"
                        style="width: 35px;height: 35px;" (click)="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')?false : fileInput1.value=null; ((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification') ? false :deleteCoverImage()" [attr.disabled]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')"  [hidden]="contrat.localfiles == undefined || contrat.localfiles.localCoverWeb == '' ">
                        <input type="file" name="file1" #fileInput1 id="file1" class="inputfile"  [disabled]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')"
                            (change)="sendCoverImage(fileInput1.files[0])" />
                        <label for="file1" [ngStyle]="{'cursor' :  ((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification') ? 'not-allowed' : 'pointer' }"
                            class="d-flex flex-row w-100  d-flex flex-row justify-content-center justify-content-lg-start ">
                            <img src="../../../../../assets/img/028-addelementleft.svg"  [ngStyle]="{'cursor' :  ((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification') ? 'not-allowed' : null }"
                            style="width: 35px;height: 35px;"  [hidden]="contrat.localfiles != undefined && contrat.localfiles.localCoverWeb != '' " >

                        <input type="text" name="file1" class="form-control classname w-100 h4"  [disabled]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')"
                            style="height: 35px;border-radius: 0 10px 10px 0 !important;color: ;"
                            value="{{ contrat.localfiles != undefined && contrat.localfiles.localCoverWeb != ''  ? contrat.localfiles.localCoverWeb : 'Charger une image'}}" readonly />
                        </label>
                    </div>
                    <div class="col-lg-6 d-flex flex-column justify-content-center p3 align-items-lg-start align-items-center"
                        style="height: 35px;">
                        Format .png (1500px * 500px)
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div class="col-lg-5">
        <app-webhome *ngIf="web!=undefined" [logo_site]="logowebapp" [cover_image]="coverimage" [titrecover]="web.titreCover" [soustitrecover]="web.soustitreCover" [couleur]="Designe.couleurPrincipale"></app-webhome>
    </div>
</div>

<div class="row mt-3" *ngIf="contrat!=undefined  && contrat.contrat.webApp">
    <div class="col-lg-12 d-flex flex-column justify-content-center align-items-lg-start align-items-center">
        <p class="pb-0 mb-0  h3" style="text-align: center;">Titres et sous-titre : </p>
        <p class="pb-0 mb-0 mt-0 p2" style="text-align: center;">Il s’agit du titre et du sous-titre qui
            s’affichent en page d’accueil de votre site.</p>
    </div>
</div>

<div class="row mt-3" *ngIf="contrat!=undefined  && contrat.contrat.webApp">
    <div class="col-lg-4">
        <input type="text" *ngIf="user!=undefined && web!=undefined" [ngStyle]="{'cursor' :  ((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification') ? 'not-allowed' : null }" [disabled]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')"  (focus)="titrecovererror = false" style="font-family:Arial, FontAwesome" ngClass="{{titrecovererror ? 'colorerrorinput' : 'colornoterrorinput'}}"  [(ngModel)]="web.titreCover" (ngModelChange)="onChange()" placeholder="{{titrecovererror ? '&#xf071; Champs obligatoire':'Titre (40 caractères maximum)'}}" autocomplete="off"  class="form-control input1"  required>
    </div>
    <div class="col-lg-6">
        <input type="text" *ngIf="user!=undefined && web!=undefined" [ngStyle]="{'cursor' :  ((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification') ? 'not-allowed' : null }" [disabled]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')"  (focus)="titrecovererror = false" style="font-family:Arial, FontAwesome" ngClass="{{soustitrecovererror ? 'colorerrorinput' : 'colornoterrorinput'}}"  [(ngModel)]="web.soustitreCover" (ngModelChange)="onChange()" placeholder="{{soustitrecovererror ? '&#xf071; Champs obligatoire':'Sous-titre (180 caractères maximum)'}}"  autocomplete="off"  class="form-control input1" id="insta_contract" required>
    </div>
</div>

<div class="row mt-4" *ngIf="contrat!=undefined  && contrat.contrat.webApp">
    <div class="col-lg-12 d-flex flex-column justify-content-center align-items-lg-start align-items-center">
        <p class="pb-0 mb-0  h3" style="text-align: center;">Couleur principale : </p>
        <p class="pb-0 mb-0 mt-0 p2" style="text-align: center;">Il s’agit de la couleur principale de
            l’application (bouton, icones, titres etc.)</p>
    </div>
</div>

<div class="row mt-3" *ngIf="contrat!=undefined  && contrat.contrat.webApp">
    <div class="col-lg-4">
        <input type="text"  maxlength="7" *ngIf="user!=undefined && Designe!=undefined"  [ngStyle]="{'cursor' :  ((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification') ? 'not-allowed' : null }" [disabled]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')"  (focus)="colorerror = false;completeColor()" style="font-family:Arial, FontAwesome" ngClass="{{colorerror ? 'colorerrorinput' : 'colornoterrorinput'}}"  [(ngModel)]="Designe.couleurPrincipale" (ngModelChange)="onChange()" placeholder="{{colorerror ? '&#xf071; '+colorErrorMsg : '#...'}}" autocomplete="off"  class="form-control input1"  required>
    </div>
</div>


<div class="row mt-5" *ngIf="contrat!=undefined && contrat.contrat.mobileApp">
    <div class="col-lg-5">
        <div class="row">
            <div class="col-12 d-flex flex-column justify-content-center align-items-lg-start align-items-center">
                <p class="pb-0 mb-0  h3" style="text-align: center;">Logo de votre application Montri : </p>
                <p class="pb-0 mb-0 mt-0 p2" style="text-align: center;">C’est le logo qui s’affichera sur votre
                    application</p>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-lg-12 d-flex flex-row justify-content-center justify-content-lg-start">
                <div class="row w-100">
                    <div class="col-lg-6  d-flex flex-row justify-content-center justify-content-lg-start " *ngIf="user!=undefined&& contrat!=undefined">
                        <img src="../../../../../assets/img/Boutons/002-Delete-icon.svg"  [ngStyle]="{'cursor' :  ((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification') ? 'not-allowed' : null }"
                        style="width: 35px;height: 35px;" (click)="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')?false : fileInput3.value=null; ((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification') ? false :deleteMobileappLogo()" [hidden]="contrat.localfiles == undefined || contrat.localfiles.localLogoMobile == ''">
                        <input type="file" name="file3" #fileInput3 id="file3" class="inputfile" [disabled]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')"
                            (change)="sendMobileappLogo(fileInput3.files[0])" />
                        <label for="file3"
                            class="d-flex flex-row w-100  d-flex flex-row justify-content-center justify-content-lg-start ">
                            <img src="../../../../../assets/img/028-addelementleft.svg"  [ngStyle]="{'cursor' :  ((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification') ? 'not-allowed' : null }"
                            style="width: 35px;height: 35px;"  [hidden]="contrat.localfiles != undefined && contrat.localfiles.localLogoMobile != ''" >

                        <input type="text" name="file3" class="form-control classname w-100 h4"  [disabled]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')"
                            style="height: 35px;border-radius: 0 10px 10px 0 !important;color: ;"
                            value="{{ contrat.localfiles != undefined && contrat.localfiles.localLogoMobile != '' ? contrat.localfiles.localLogoMobile  : 'Charger l\'image'}}" readonly />
                        </label>
                    </div>
                    <div class="col-lg-6  d-flex flex-column  justify-content-center p3 align-items-lg-start align-items-center">
                        Format .png (200px * 200px)
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-5">
            <div class="col-lg-12 d-flex flex-column justify-content-center align-items-lg-start align-items-center">
                <p class="pb-0 mb-0  h3" style="text-align: center;">Couleur principale : </p>
                <p class="pb-0 mb-0 mt-0 p2" style="text-align: center;">Il s’agit de la couleur principale de
                    l’application (bouton, icones, titres etc.)</p>
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-lg-10">
                <input type="text"[ngStyle]="{'cursor' :  ((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification') ? 'not-allowed' : null }"  *ngIf="user!=undefined && Designe!=undefined"   maxlength="7"  [disabled]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')" (focus)="colorerror = false;completeColor()" style="font-family:Arial, FontAwesome" ngClass="{{colorerror ? 'colorerrorinput' : 'colornoterrorinput'}}"  [(ngModel)]="Designe.couleurPrincipale" (ngModelChange)="onChange()" placeholder="{{colorerror ? '&#xf071; '+ colorErrorMsg:'#...'}}" class="form-control input1"  required>
            </div>
        </div>

    </div>

    <div class="col-lg-5">
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px" y="0px" width="300px" height="300px" viewBox="0 0 300 605" enable-background="new 0 0 300 605"
            xml:space="preserve">
            <path fill="#B4B4B4"
                d="M299.896,207.294c0.062-0.188,0.104-0.385,0.104-0.594V141.66c0-0.209-0.042-0.405-0.104-0.593
 c-0.011-0.029-0.017-0.057-0.025-0.086c-0.07-0.179-0.164-0.343-0.277-0.493c-0.016-0.021-0.03-0.038-0.046-0.057
 c-0.262-0.316-0.617-0.547-1.026-0.648c-0.146-0.034-0.298-0.059-0.456-0.059h-0.587v68.914h0.587c0.599,0,1.128-0.278,1.482-0.705
 c0.016-0.02,0.03-0.038,0.046-0.057c0.113-0.149,0.207-0.313,0.277-0.494C299.88,207.351,299.886,207.322,299.896,207.294" />
            <path fill="#222325" d="M186.079,23.776c0,2.538-2.055,4.597-4.591,4.597c-2.533,0-4.589-2.059-4.589-4.597
 c0-2.537,2.056-4.595,4.589-4.595C184.024,19.181,186.079,21.239,186.079,23.776" />
            <path fill="#222325" d="M167.427,23.776c0,1.355-1.099,2.454-2.448,2.454h-32.102c-1.354,0-2.452-1.099-2.452-2.454
 c0-1.354,1.098-2.452,2.452-2.452h32.102C166.328,21.324,167.427,22.422,167.427,23.776" />
            <path fill="#FFFFFF" d="M43.523,36.045c0.102,0.343,0.532,0.706,1.249,0.706c1.363,0,1.871-1.322,1.827-2.943h-0.069
 c-0.231,0.605-0.852,1.141-1.884,1.141c-1.352,0-2.462-0.931-2.462-2.568c0-1.644,1.179-2.625,2.725-2.625
 c1.394,0,2.8,0.83,2.8,3.861c0,2.738-0.953,4.151-2.96,4.151c-1.475,0-2.271-0.846-2.368-1.722H43.523z M44.844,30.771
 c-0.833,0-1.521,0.6-1.521,1.61c0,1.057,0.714,1.575,1.543,1.575c0.808,0,1.543-0.514,1.543-1.551
 C46.409,31.364,45.734,30.771,44.844,30.771" />
            <path fill="#FFFFFF" d="M50.25,32.207c0.495,0,0.874,0.36,0.874,0.88c0,0.509-0.378,0.863-0.874,0.863
 c-0.464,0-0.846-0.354-0.846-0.863C49.403,32.562,49.779,32.207,50.25,32.207 M50.25,35.989c0.495,0,0.874,0.356,0.874,0.88
 c0,0.508-0.378,0.859-0.874,0.859c-0.464,0-0.846-0.351-0.846-0.859C49.403,36.345,49.779,35.989,50.25,35.989" />
            <path fill="#FFFFFF"
                d="M57.467,29.918v5.148h0.989v1.045h-0.989v1.514h-1.098v-1.514h-3.74V35.05
 c0.948-1.715,2.068-3.42,3.139-5.131H57.467z M56.369,35.067v-4.143h-0.063c-0.862,1.292-1.783,2.727-2.56,4.092v0.05H56.369z" />
            <polygon fill="#FFFFFF" points="61.624,37.625 61.624,31.184 61.567,31.184 59.793,32.455 59.793,31.257 61.624,29.918
 62.771,29.918 62.771,37.625 " />
            <path fill="#FFFFFF" d="M235.031,37.279c0,0.192-0.155,0.347-0.347,0.347h-1.043c-0.194,0-0.349-0.155-0.349-0.347V30.27
 c0-0.191,0.154-0.347,0.349-0.347h1.043c0.191,0,0.347,0.155,0.347,0.347V37.279z" />
            <path fill="#FFFFFF" d="M231.854,37.279c0,0.192-0.157,0.347-0.349,0.347h-1.042c-0.193,0-0.348-0.155-0.348-0.347v-5.129
 c0-0.193,0.154-0.347,0.348-0.347h1.042c0.191,0,0.349,0.154,0.349,0.347V37.279z" />
            <path fill="#FFFFFF" d="M228.678,37.279c0,0.192-0.156,0.347-0.349,0.347h-1.043c-0.191,0-0.347-0.155-0.347-0.347v-3.562
 c0-0.193,0.155-0.349,0.347-0.349h1.043c0.192,0,0.349,0.156,0.349,0.349V37.279z" />
            <path fill="#FFFFFF" d="M225.501,37.279c0,0.192-0.157,0.347-0.349,0.347h-1.044c-0.192,0-0.347-0.155-0.347-0.347v-2.311
 c0-0.19,0.154-0.346,0.347-0.346h1.044c0.191,0,0.349,0.156,0.349,0.346V37.279z" />
            <path fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M240.28,33.436c-0.471-0.461-0.928-0.906-1.383-1.353
 c2.838-2.896,7.814-3.006,10.799,0.004c-0.45,0.453-0.9,0.907-1.364,1.376c-1.13-1.062-2.469-1.632-4.028-1.638
 C242.743,31.823,241.401,32.383,240.28,33.436" />
            <path fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF"
                d="M242.26,35.45c-0.461-0.451-0.916-0.897-1.372-1.345
 c1.771-1.827,4.963-1.879,6.788-0.019c-0.446,0.458-0.893,0.913-1.343,1.372C244.972,34.396,243.614,34.394,242.26,35.45" />
            <path fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M245.627,36.14c-0.454,0.459-0.896,0.91-1.334,1.354
 c-0.45-0.449-0.896-0.895-1.343-1.341C243.531,35.51,244.858,35.397,245.627,36.14" />
            <g opacity="0.4">
                <g>
                    <defs>
                        <rect id="SVGID_1_" x="253.403" y="29.728" width="15.321" height="8.093" />
                    </defs>
                    <clipPath id="SVGID_2_">
                        <use xlink:href="#SVGID_1_" overflow="visible" />
                    </clipPath>
                    <path clip-path="url(#SVGID_2_)" fill="#FFFFFF" d="M266.996,29.729h-11.863c-0.953,0-1.728,0.775-1.728,1.73v4.631
   c0,0.955,0.774,1.729,1.728,1.729h11.863c0.953,0,1.729-0.775,1.729-1.729v-4.631C268.725,30.504,267.949,29.729,266.996,29.729
    M268.034,36.091c0,0.574-0.467,1.039-1.038,1.039h-11.863c-0.574,0-1.038-0.465-1.038-1.039v-4.631
   c0-0.575,0.464-1.04,1.038-1.04h11.863c0.571,0,1.038,0.465,1.038,1.04V36.091z" />
                </g>
            </g>
            <path fill="#FFFFFF" d="M267.344,36.091c0,0.192-0.155,0.349-0.348,0.349h-11.863c-0.192,0-0.35-0.156-0.35-0.349v-4.631
 c0-0.194,0.157-0.35,0.35-0.35h11.863c0.192,0,0.348,0.156,0.348,0.35V36.091z" />
            <path fill="#FFFFFF"
                d="M270.377,33.774c0,0.514-0.418,0.933-0.93,0.933v-1.865C269.959,32.842,270.377,33.259,270.377,33.774" />
            <path fill="#B4B4B4" d="M298.111,56.203c0-19.638-2.011-31.848-14.428-44.033C271.692,0.4,254.395,0,248.534,0H49.573
 c-5.859,0-23.158,0.4-35.148,12.17C2.01,24.355,0,36.565,0,56.203V547.31c0,19.637,2.01,31.847,14.424,44.032
 c11.99,11.769,29.29,12.171,35.148,12.168h198.961c5.86,0.003,23.158-0.399,35.149-12.168
 c12.417-12.186,14.428-24.396,14.428-44.032V56.203z" />
            <path d="M49.573,601.064c-17.983,0-28.106-6.236-33.437-11.47C4.491,578.166,2.441,566.852,2.441,547.31V56.204
 c0-19.545,2.049-30.857,13.696-42.287C21.466,8.682,31.594,2.448,49.561,2.448h198.973c17.981,0,28.108,6.234,33.438,11.469
 c11.646,11.43,13.695,22.742,13.695,42.287V547.31c0,19.542-2.049,30.856-13.695,42.285c-5.329,5.233-15.452,11.47-33.393,11.47
 H49.573z" />
            <text transform="matrix(1 0 0 1 145.7358 66.3037)" fill="#3E3D40" font-family="'DINBoldAlternate'"
                font-size="11.202"> </text>
            <g>
                <defs>
                    <path id="SVGID_3_" d="M270.693,23.842c-3.263-3.204-9.828-7.021-22.567-7.021h-22.737c-1.158,0.149-2.837,0.846-3.001,3.625
   c-0.229,3.816-0.699,8.851-4.477,12.72c-2.912,2.987-6.682,4.405-10.602,4.405H90.195c-3.92,0-7.689-1.418-10.604-4.405
   c-3.774-3.869-4.246-8.904-4.473-12.72c-0.166-2.779-1.846-3.476-3.002-3.625H48.78c-12.735,0-19.295,3.818-22.558,7.021
   c-7.668,7.529-9.035,14.719-9.035,31.199v492.036c0,16.477,1.367,23.669,9.035,31.196c3.263,3.204,9.828,7.021,22.566,7.019
   h199.345c12.736,0,19.3-3.814,22.56-7.019c7.671-7.527,9.034-14.72,9.034-31.196V55.041
   C279.728,38.561,278.364,31.371,270.693,23.842" />
                </defs>
                <use xlink:href="#SVGID_3_" overflow="visible" fill="#FFFFFF" />
                <clipPath id="SVGID_4_">
                    <use xlink:href="#SVGID_3_" overflow="visible" />
                </clipPath>
                <defs>
                    <pattern id="img1" height="100%" width="100%"
                    patternContentUnits="objectBoundingBox">
               <image height="1" width="1" preserveAspectRatio="none"
                      attr.xlink:href="{{logomobileapp}}" />
           </pattern>
                  </defs>
                <g clip-path="url(#SVGID_4_)">
                    <g>
                        <rect x="17.187" y="16.983" fill="#FFFFFF" width="262.382" height="568.145" />
                    </g>
                    <g>

                        <path fill="url(#img1)" d="M112.811,139.464c0,4.488-3.639,8.127-8.127,8.127H48.637c-4.489,0-8.127-3.639-8.127-8.127V83.417
    c0-4.488,3.639-8.127,8.127-8.127h56.046c4.488,0,8.127,3.639,8.127,8.127V139.464z" />
                    </g>
                    <g opacity="1">
                        <path fill="#EAEAEA" d="M263.895,469.389c0,4.48-3.633,8.113-8.113,8.113H41.292c-4.481,0-8.113-3.633-8.113-8.113V191.487
    c0-4.48,3.632-8.113,8.113-8.113h214.489c4.48,0,8.113,3.633,8.113,8.113V469.389z" />
                    </g>
                    <g>
                        <path d="M56.948,415.248h-0.027c-0.126,0.216-0.284,0.403-0.473,0.561c-0.189,0.158-0.395,0.289-0.616,0.393
    s-0.453,0.18-0.697,0.23c-0.243,0.049-0.482,0.074-0.717,0.074c-0.478,0-0.92-0.082-1.326-0.244s-0.753-0.39-1.042-0.684
    c-0.289-0.293-0.514-0.64-0.676-1.041c-0.162-0.4-0.244-0.84-0.244-1.318s0.082-0.918,0.244-1.319s0.388-0.749,0.676-1.042
    s0.636-0.521,1.042-0.683s0.848-0.243,1.326-0.243c0.234,0,0.474,0.024,0.717,0.074c0.244,0.049,0.476,0.127,0.697,0.23
    s0.426,0.234,0.616,0.392s0.347,0.345,0.473,0.562h0.027v-5.072h0.812v10.227h-0.812V415.248z M57.029,413.219
    c0-0.352-0.068-0.684-0.203-0.994c-0.135-0.312-0.32-0.582-0.554-0.812c-0.235-0.229-0.51-0.412-0.825-0.547
    c-0.316-0.136-0.658-0.203-1.028-0.203c-0.378,0-0.722,0.067-1.028,0.203c-0.307,0.135-0.568,0.317-0.785,0.547
    c-0.216,0.23-0.381,0.501-0.494,0.812c-0.113,0.311-0.169,0.643-0.169,0.994s0.057,0.683,0.169,0.994
    c0.113,0.311,0.277,0.582,0.494,0.812s0.478,0.413,0.785,0.548c0.306,0.135,0.649,0.203,1.028,0.203
    c0.37,0,0.712-0.068,1.028-0.203c0.315-0.135,0.59-0.318,0.825-0.548c0.234-0.229,0.419-0.501,0.554-0.812
    C56.961,413.901,57.029,413.57,57.029,413.219z" />
                        <path d="M65.268,415.166c-0.316,0.479-0.706,0.82-1.17,1.028c-0.465,0.208-0.976,0.312-1.535,0.312
    c-0.478,0-0.911-0.082-1.299-0.244s-0.722-0.39-1.001-0.684c-0.279-0.293-0.494-0.64-0.643-1.041
    c-0.149-0.4-0.223-0.846-0.223-1.332c0-0.469,0.077-0.902,0.23-1.299s0.37-0.742,0.649-1.035
    c0.279-0.293,0.609-0.522,0.987-0.689c0.379-0.167,0.798-0.25,1.258-0.25c0.478,0,0.899,0.083,1.265,0.25s0.671,0.388,0.92,0.662
    c0.248,0.275,0.435,0.589,0.562,0.941c0.126,0.352,0.189,0.707,0.189,1.068v0.568h-5.249c0,0.171,0.034,0.39,0.102,0.656
    c0.068,0.266,0.191,0.525,0.372,0.777c0.18,0.252,0.421,0.469,0.724,0.648c0.302,0.182,0.692,0.271,1.17,0.271
    c0.415,0,0.816-0.1,1.204-0.298s0.685-0.469,0.893-0.812L65.268,415.166z M64.645,412.691c0-0.28-0.056-0.542-0.169-0.785
    c-0.113-0.244-0.266-0.457-0.46-0.643c-0.194-0.185-0.424-0.332-0.69-0.439c-0.266-0.108-0.548-0.162-0.845-0.162
    c-0.442,0-0.81,0.09-1.103,0.27c-0.293,0.182-0.526,0.389-0.697,0.623c-0.171,0.234-0.293,0.462-0.365,0.684
    c-0.072,0.221-0.108,0.371-0.108,0.453H64.645z" />
                        <path
                            d="M69.907,410.094h0.919l2.083,5.37l2.029-5.37h0.825l-2.462,6.25h-0.838L69.907,410.094z" />
                        <path d="M76.698,413.219c0-0.479,0.081-0.918,0.244-1.319c0.162-0.401,0.387-0.749,0.676-1.042
    c0.289-0.293,0.636-0.521,1.042-0.683s0.848-0.243,1.326-0.243s0.917,0.081,1.319,0.243s0.749,0.39,1.042,0.683
    c0.292,0.293,0.521,0.641,0.683,1.042c0.162,0.401,0.243,0.841,0.243,1.319s-0.081,0.918-0.243,1.318
    c-0.163,0.401-0.391,0.748-0.683,1.041c-0.293,0.294-0.641,0.521-1.042,0.684s-0.841,0.244-1.319,0.244s-0.92-0.082-1.326-0.244
    s-0.753-0.39-1.042-0.684c-0.289-0.293-0.514-0.64-0.676-1.041C76.779,414.137,76.698,413.697,76.698,413.219z M77.509,413.219
    c0,0.352,0.057,0.683,0.169,0.994c0.113,0.311,0.278,0.582,0.494,0.812s0.478,0.413,0.785,0.548
    c0.306,0.135,0.649,0.203,1.028,0.203c0.379,0,0.722-0.068,1.028-0.203c0.307-0.135,0.568-0.318,0.785-0.548
    c0.217-0.229,0.381-0.501,0.494-0.812c0.112-0.312,0.169-0.643,0.169-0.994s-0.057-0.684-0.169-0.994
    c-0.113-0.312-0.277-0.582-0.494-0.812c-0.216-0.229-0.478-0.412-0.785-0.547c-0.306-0.136-0.649-0.203-1.028-0.203
    c-0.378,0-0.722,0.067-1.028,0.203c-0.307,0.135-0.568,0.317-0.785,0.547c-0.216,0.23-0.381,0.501-0.494,0.812
    C77.566,412.535,77.509,412.867,77.509,413.219z" />
                        <path d="M85.017,407.618c0-0.171,0.059-0.315,0.176-0.433s0.261-0.176,0.433-0.176c0.171,0,0.315,0.059,0.433,0.176
    s0.176,0.262,0.176,0.433s-0.059,0.315-0.176,0.433s-0.262,0.176-0.433,0.176c-0.172,0-0.316-0.059-0.433-0.176
    S85.017,407.789,85.017,407.618z M85.22,410.094h0.812v6.25H85.22V410.094z" />
                        <path d="M91.388,410.824h-1.786v4.071c0,0.28,0.077,0.496,0.23,0.649c0.153,0.154,0.365,0.23,0.636,0.23
    c0.153,0,0.309-0.021,0.467-0.062c0.158-0.04,0.309-0.092,0.453-0.155l0.067,0.73c-0.153,0.055-0.329,0.104-0.527,0.148
    c-0.199,0.045-0.375,0.068-0.527,0.068c-0.289,0-0.537-0.043-0.744-0.129c-0.208-0.086-0.374-0.201-0.5-0.345
    c-0.126-0.145-0.219-0.313-0.277-0.507c-0.059-0.194-0.088-0.404-0.088-0.63v-4.071h-1.353v-0.73h1.353v-1.799h0.812v1.799h1.786
    V410.824z" />
                        <path d="M98.125,414.584c0,0.117,0.005,0.26,0.014,0.427s0.016,0.336,0.02,0.507c0.005,0.172,0.012,0.332,0.021,0.48
    s0.014,0.264,0.014,0.346h-0.812c-0.009-0.234-0.016-0.461-0.021-0.678c-0.004-0.216-0.016-0.355-0.034-0.418h-0.041
    c-0.163,0.342-0.433,0.638-0.812,0.885c-0.378,0.248-0.82,0.373-1.325,0.373c-0.487,0-0.886-0.077-1.197-0.23
    s-0.555-0.354-0.73-0.602c-0.176-0.248-0.296-0.535-0.359-0.859s-0.094-0.658-0.094-1.001v-3.72h0.812v3.68
    c0,0.252,0.022,0.498,0.067,0.736c0.045,0.24,0.124,0.453,0.237,0.643s0.271,0.341,0.474,0.453
    c0.203,0.113,0.466,0.17,0.792,0.17c0.297,0,0.579-0.053,0.845-0.156s0.496-0.262,0.69-0.473c0.194-0.213,0.347-0.479,0.46-0.799
    c0.113-0.32,0.169-0.696,0.169-1.129v-3.125h0.812V414.584z" />
                        <path d="M100.357,411.852c0-0.117-0.005-0.259-0.014-0.426c-0.009-0.166-0.016-0.336-0.021-0.507
    c-0.004-0.171-0.011-0.331-0.02-0.48c-0.009-0.149-0.014-0.264-0.014-0.345h0.812c0.009,0.234,0.016,0.459,0.021,0.676
    c0.004,0.217,0.016,0.357,0.034,0.42c0.208-0.369,0.473-0.672,0.798-0.906s0.722-0.352,1.19-0.352c0.082,0,0.16,0.006,0.237,0.02
    s0.156,0.029,0.237,0.048l-0.095,0.798c-0.108-0.035-0.212-0.054-0.311-0.054c-0.352,0-0.656,0.056-0.913,0.169
    s-0.469,0.269-0.636,0.467c-0.167,0.198-0.291,0.43-0.372,0.696s-0.122,0.553-0.122,0.858v3.41h-0.812V411.852z" />
                        <path
                            d="M110.137,415.166c-0.315,0.479-0.706,0.82-1.17,1.028s-0.977,0.312-1.535,0.312c-0.478,0-0.911-0.082-1.299-0.244
    s-0.722-0.39-1.001-0.684c-0.28-0.293-0.494-0.64-0.643-1.041c-0.149-0.4-0.223-0.846-0.223-1.332
    c0-0.469,0.077-0.902,0.23-1.299c0.153-0.396,0.37-0.742,0.649-1.035c0.28-0.293,0.609-0.522,0.988-0.689s0.798-0.25,1.258-0.25
    c0.478,0,0.899,0.083,1.265,0.25s0.672,0.388,0.92,0.662c0.248,0.275,0.435,0.589,0.561,0.941
    c0.126,0.352,0.189,0.707,0.189,1.068v0.568h-5.249c0,0.171,0.034,0.39,0.102,0.656c0.067,0.266,0.191,0.525,0.372,0.777
    c0.18,0.252,0.421,0.469,0.724,0.648c0.302,0.182,0.692,0.271,1.17,0.271c0.415,0,0.816-0.1,1.204-0.298s0.686-0.469,0.893-0.812
    L110.137,415.166z M109.515,412.691c0-0.28-0.057-0.542-0.169-0.785c-0.113-0.244-0.266-0.457-0.46-0.643
    c-0.194-0.185-0.424-0.332-0.69-0.439c-0.266-0.108-0.548-0.162-0.846-0.162c-0.442,0-0.81,0.09-1.103,0.27
    c-0.293,0.182-0.525,0.389-0.696,0.623c-0.171,0.234-0.293,0.462-0.365,0.684c-0.072,0.221-0.108,0.371-0.108,0.453H109.515z" />
                        <path d="M121.175,415.166c-0.316,0.479-0.706,0.82-1.17,1.028s-0.976,0.312-1.535,0.312c-0.478,0-0.911-0.082-1.299-0.244
    s-0.721-0.39-1.001-0.684c-0.28-0.293-0.494-0.64-0.643-1.041c-0.149-0.4-0.223-0.846-0.223-1.332
    c0-0.469,0.077-0.902,0.23-1.299s0.37-0.742,0.649-1.035c0.279-0.293,0.609-0.522,0.987-0.689c0.379-0.167,0.798-0.25,1.258-0.25
    c0.478,0,0.899,0.083,1.265,0.25s0.672,0.388,0.92,0.662c0.248,0.275,0.435,0.589,0.562,0.941
    c0.126,0.352,0.189,0.707,0.189,1.068v0.568h-5.249c0,0.171,0.034,0.39,0.102,0.656c0.068,0.266,0.191,0.525,0.372,0.777
    c0.18,0.252,0.421,0.469,0.724,0.648c0.302,0.182,0.692,0.271,1.17,0.271c0.415,0,0.816-0.1,1.204-0.298s0.686-0.469,0.893-0.812
    L121.175,415.166z M120.553,412.691c0-0.28-0.056-0.542-0.169-0.785c-0.113-0.244-0.266-0.457-0.46-0.643
    c-0.194-0.185-0.424-0.332-0.69-0.439c-0.266-0.108-0.548-0.162-0.845-0.162c-0.442,0-0.81,0.09-1.103,0.27
    c-0.293,0.182-0.525,0.389-0.696,0.623s-0.293,0.462-0.365,0.684c-0.072,0.221-0.108,0.371-0.108,0.453H120.553z
     M118.957,406.766h1.082l-1.812,1.948h-0.676L118.957,406.766z" />
                        <path d="M128.412,415.424c-0.307,0.369-0.654,0.643-1.042,0.818c-0.388,0.176-0.807,0.264-1.258,0.264
    c-0.479,0-0.92-0.082-1.326-0.244c-0.405-0.162-0.753-0.39-1.042-0.684c-0.289-0.293-0.514-0.64-0.676-1.041
    c-0.162-0.4-0.244-0.84-0.244-1.318s0.082-0.918,0.244-1.319s0.388-0.749,0.676-1.042s0.636-0.521,1.042-0.683
    c0.406-0.162,0.848-0.243,1.326-0.243c0.459,0,0.886,0.094,1.278,0.283c0.393,0.189,0.742,0.456,1.048,0.799l-0.649,0.486
    c-0.234-0.262-0.491-0.467-0.771-0.615c-0.28-0.148-0.582-0.223-0.906-0.223c-0.379,0-0.722,0.067-1.028,0.203
    c-0.307,0.135-0.568,0.317-0.785,0.547c-0.216,0.23-0.381,0.501-0.494,0.812c-0.113,0.311-0.169,0.643-0.169,0.994
    s0.056,0.683,0.169,0.994c0.113,0.311,0.277,0.582,0.494,0.812s0.478,0.413,0.785,0.548s0.649,0.203,1.028,0.203
    c0.342,0,0.656-0.079,0.94-0.237c0.284-0.157,0.53-0.367,0.737-0.629L128.412,415.424z" />
                        <path
                            d="M129.332,413.219c0-0.479,0.081-0.918,0.244-1.319s0.388-0.749,0.676-1.042c0.289-0.293,0.636-0.521,1.042-0.683
    c0.406-0.162,0.847-0.243,1.325-0.243c0.479,0,0.918,0.081,1.319,0.243c0.401,0.162,0.748,0.39,1.042,0.683
    c0.293,0.293,0.521,0.641,0.683,1.042s0.244,0.841,0.244,1.319s-0.082,0.918-0.244,1.318c-0.162,0.401-0.39,0.748-0.683,1.041
    c-0.293,0.294-0.641,0.521-1.042,0.684c-0.401,0.162-0.841,0.244-1.319,0.244c-0.478,0-0.919-0.082-1.325-0.244
    c-0.406-0.162-0.753-0.39-1.042-0.684c-0.289-0.293-0.514-0.64-0.676-1.041C129.413,414.137,129.332,413.697,129.332,413.219z
     M130.144,413.219c0,0.352,0.056,0.683,0.169,0.994c0.113,0.311,0.277,0.582,0.494,0.812c0.217,0.229,0.478,0.413,0.785,0.548
    s0.649,0.203,1.028,0.203c0.379,0,0.722-0.068,1.028-0.203s0.568-0.318,0.785-0.548s0.381-0.501,0.494-0.812
    c0.113-0.312,0.169-0.643,0.169-0.994s-0.056-0.684-0.169-0.994c-0.113-0.312-0.277-0.582-0.494-0.812
    c-0.216-0.229-0.478-0.412-0.785-0.547c-0.307-0.136-0.649-0.203-1.028-0.203c-0.378,0-0.721,0.067-1.028,0.203
    c-0.307,0.135-0.568,0.317-0.785,0.547c-0.216,0.23-0.381,0.501-0.494,0.812C130.2,412.535,130.144,412.867,130.144,413.219z" />
                        <path
                            d="M137.719,411.852c0-0.117-0.005-0.259-0.014-0.426c-0.009-0.166-0.016-0.336-0.021-0.507
    c-0.004-0.171-0.011-0.331-0.02-0.48c-0.009-0.149-0.014-0.264-0.014-0.345h0.812c0.009,0.234,0.016,0.459,0.021,0.676
    c0.004,0.217,0.016,0.357,0.034,0.42h0.041c0.163-0.343,0.433-0.639,0.812-0.887c0.378-0.248,0.82-0.371,1.326-0.371
    c0.487,0,0.883,0.076,1.19,0.229c0.306,0.153,0.548,0.354,0.724,0.603s0.297,0.533,0.365,0.858
    c0.067,0.325,0.102,0.659,0.102,1.001v3.721h-0.812v-3.68c0-0.253-0.022-0.498-0.067-0.737c-0.046-0.239-0.125-0.454-0.237-0.644
    c-0.113-0.188-0.271-0.34-0.474-0.452c-0.203-0.112-0.467-0.169-0.791-0.169c-0.298,0-0.58,0.052-0.846,0.155
    c-0.266,0.104-0.496,0.262-0.69,0.474s-0.347,0.479-0.46,0.798c-0.113,0.32-0.169,0.696-0.169,1.13v3.125h-0.812V411.852z" />
                        <path d="M144.888,413.219c0-0.479,0.081-0.918,0.244-1.319c0.162-0.401,0.387-0.749,0.676-1.042
    c0.288-0.293,0.636-0.521,1.041-0.683c0.406-0.162,0.848-0.243,1.326-0.243c0.478,0,0.918,0.081,1.318,0.243
    c0.401,0.162,0.749,0.39,1.042,0.683s0.521,0.641,0.683,1.042c0.163,0.401,0.244,0.841,0.244,1.319s-0.081,0.918-0.244,1.318
    c-0.162,0.401-0.39,0.748-0.683,1.041c-0.293,0.294-0.641,0.521-1.042,0.684c-0.4,0.162-0.841,0.244-1.318,0.244
    c-0.479,0-0.92-0.082-1.326-0.244c-0.405-0.162-0.753-0.39-1.041-0.684c-0.289-0.293-0.514-0.64-0.676-1.041
    C144.969,414.137,144.888,413.697,144.888,413.219z M145.7,413.219c0,0.352,0.056,0.683,0.169,0.994
    c0.113,0.311,0.277,0.582,0.494,0.812s0.478,0.413,0.784,0.548s0.649,0.203,1.028,0.203s0.721-0.068,1.028-0.203
    c0.306-0.135,0.567-0.318,0.784-0.548s0.381-0.501,0.494-0.812c0.112-0.312,0.169-0.643,0.169-0.994s-0.057-0.684-0.169-0.994
    c-0.113-0.312-0.277-0.582-0.494-0.812c-0.217-0.229-0.479-0.412-0.784-0.547c-0.308-0.136-0.649-0.203-1.028-0.203
    s-0.722,0.067-1.028,0.203c-0.307,0.135-0.567,0.317-0.784,0.547c-0.217,0.23-0.381,0.501-0.494,0.812
    C145.755,412.535,145.7,412.867,145.7,413.219z" />
                        <path d="M153.276,411.852c0-0.117-0.005-0.259-0.014-0.426c-0.009-0.166-0.016-0.336-0.02-0.507
    c-0.006-0.171-0.012-0.331-0.021-0.48c-0.008-0.149-0.014-0.264-0.014-0.345h0.812c0.009,0.234,0.016,0.459,0.02,0.676
    c0.005,0.217,0.017,0.357,0.035,0.42h0.04c0.162-0.343,0.433-0.639,0.812-0.887c0.38-0.248,0.821-0.371,1.327-0.371
    c0.414,0,0.794,0.105,1.137,0.317s0.591,0.558,0.744,1.034c0.198-0.469,0.493-0.811,0.887-1.027
    c0.393-0.217,0.805-0.324,1.238-0.324c0.486,0,0.884,0.076,1.19,0.229s0.549,0.354,0.725,0.603s0.297,0.533,0.365,0.858
    c0.067,0.325,0.102,0.659,0.102,1.001v3.721h-0.812v-3.68c0-0.253-0.021-0.498-0.067-0.737c-0.045-0.239-0.124-0.454-0.236-0.644
    c-0.113-0.188-0.271-0.34-0.474-0.452c-0.204-0.112-0.468-0.169-0.792-0.169c-0.379,0-0.69,0.07-0.934,0.209
    c-0.244,0.141-0.437,0.318-0.576,0.535c-0.14,0.216-0.239,0.451-0.298,0.703s-0.088,0.491-0.088,0.717v3.518h-0.812v-3.762
    c0-0.576-0.106-1.041-0.317-1.393c-0.213-0.352-0.54-0.527-0.981-0.527c-0.299,0-0.58,0.052-0.846,0.155
    c-0.267,0.104-0.497,0.262-0.69,0.474c-0.195,0.212-0.348,0.479-0.461,0.798c-0.112,0.32-0.169,0.696-0.169,1.13v3.125h-0.812
    V411.852z" />
                        <path d="M164.737,407.618c0-0.171,0.059-0.315,0.176-0.433s0.262-0.176,0.434-0.176c0.171,0,0.315,0.059,0.433,0.176
    s0.176,0.262,0.176,0.433s-0.059,0.315-0.176,0.433s-0.262,0.176-0.433,0.176c-0.172,0-0.316-0.059-0.434-0.176
    S164.737,407.789,164.737,407.618z M164.94,410.094h0.812v6.25h-0.812V410.094z" />
                        <path d="M168.065,414.747c0.171,0.333,0.424,0.589,0.758,0.765c0.333,0.176,0.685,0.264,1.055,0.264
    c0.172,0,0.343-0.027,0.514-0.082c0.172-0.054,0.325-0.13,0.461-0.229c0.135-0.099,0.245-0.218,0.331-0.358
    c0.085-0.14,0.128-0.3,0.128-0.48c0-0.289-0.092-0.505-0.277-0.649c-0.185-0.144-0.412-0.257-0.683-0.338
    s-0.568-0.151-0.894-0.21c-0.324-0.059-0.621-0.148-0.893-0.271c-0.27-0.122-0.498-0.29-0.683-0.507s-0.277-0.523-0.277-0.92
    c0-0.307,0.063-0.572,0.189-0.799c0.126-0.225,0.291-0.412,0.493-0.561c0.203-0.148,0.432-0.26,0.684-0.332
    s0.51-0.107,0.771-0.107c0.514,0,0.957,0.094,1.332,0.283c0.374,0.189,0.665,0.488,0.873,0.893l-0.73,0.434
    c-0.154-0.289-0.346-0.508-0.576-0.656c-0.229-0.148-0.529-0.223-0.898-0.223c-0.145,0-0.296,0.022-0.453,0.067
    c-0.158,0.045-0.301,0.108-0.427,0.189s-0.231,0.185-0.317,0.312c-0.086,0.126-0.129,0.27-0.129,0.432
    c0,0.28,0.092,0.487,0.277,0.623c0.185,0.135,0.412,0.241,0.684,0.318c0.27,0.076,0.567,0.142,0.893,0.195
    c0.324,0.055,0.622,0.143,0.893,0.264c0.271,0.122,0.498,0.298,0.684,0.527c0.184,0.23,0.277,0.558,0.277,0.981
    c0,0.324-0.062,0.606-0.184,0.845c-0.121,0.24-0.288,0.439-0.5,0.603c-0.212,0.162-0.453,0.284-0.724,0.365
    s-0.555,0.122-0.853,0.122c-0.541,0-1.035-0.104-1.48-0.312c-0.447-0.208-0.792-0.528-1.035-0.96L168.065,414.747z" />
                        <path d="M179.333,415.166c-0.315,0.479-0.705,0.82-1.17,1.028s-0.977,0.312-1.535,0.312c-0.479,0-0.91-0.082-1.299-0.244
    c-0.388-0.162-0.721-0.39-1-0.684c-0.28-0.293-0.494-0.64-0.643-1.041c-0.149-0.4-0.224-0.846-0.224-1.332
    c0-0.469,0.076-0.902,0.229-1.299s0.369-0.742,0.649-1.035c0.279-0.293,0.608-0.522,0.987-0.689s0.799-0.25,1.258-0.25
    c0.479,0,0.9,0.083,1.266,0.25s0.672,0.388,0.92,0.662c0.248,0.275,0.435,0.589,0.561,0.941c0.127,0.352,0.189,0.707,0.189,1.068
    v0.568h-5.248c0,0.171,0.033,0.39,0.102,0.656c0.067,0.266,0.191,0.525,0.371,0.777c0.181,0.252,0.422,0.469,0.725,0.648
    c0.302,0.182,0.691,0.271,1.17,0.271c0.414,0,0.816-0.1,1.204-0.298s0.685-0.469,0.893-0.812L179.333,415.166z M178.712,412.691
    c0-0.28-0.057-0.542-0.17-0.785c-0.113-0.244-0.266-0.457-0.46-0.643c-0.194-0.185-0.424-0.332-0.69-0.439
    c-0.266-0.108-0.547-0.162-0.845-0.162c-0.442,0-0.81,0.09-1.103,0.27c-0.293,0.182-0.525,0.389-0.697,0.623
    c-0.171,0.234-0.293,0.462-0.365,0.684c-0.072,0.221-0.107,0.371-0.107,0.453H178.712z M177.114,406.766h1.083l-1.813,1.948
    h-0.676L177.114,406.766z" />
                        <path d="M181.349,414.747c0.172,0.333,0.424,0.589,0.758,0.765s0.686,0.264,1.055,0.264c0.172,0,0.344-0.027,0.515-0.082
    c0.171-0.054,0.325-0.13,0.46-0.229c0.135-0.099,0.246-0.218,0.331-0.358c0.086-0.14,0.129-0.3,0.129-0.48
    c0-0.289-0.093-0.505-0.277-0.649c-0.185-0.144-0.413-0.257-0.683-0.338c-0.271-0.081-0.568-0.151-0.894-0.21
    s-0.622-0.148-0.893-0.271s-0.499-0.29-0.684-0.507s-0.276-0.523-0.276-0.92c0-0.307,0.062-0.572,0.188-0.799
    c0.126-0.225,0.291-0.412,0.494-0.561c0.202-0.148,0.431-0.26,0.683-0.332c0.253-0.072,0.51-0.107,0.771-0.107
    c0.514,0,0.958,0.094,1.332,0.283c0.375,0.189,0.665,0.488,0.873,0.893l-0.73,0.434c-0.153-0.289-0.346-0.508-0.575-0.656
    s-0.53-0.223-0.899-0.223c-0.145,0-0.295,0.022-0.453,0.067s-0.3,0.108-0.426,0.189c-0.127,0.081-0.232,0.185-0.318,0.312
    c-0.086,0.126-0.129,0.27-0.129,0.432c0,0.28,0.093,0.487,0.277,0.623c0.186,0.135,0.413,0.241,0.684,0.318
    c0.271,0.076,0.568,0.142,0.893,0.195c0.324,0.055,0.623,0.143,0.893,0.264c0.271,0.122,0.498,0.298,0.684,0.527
    c0.185,0.23,0.277,0.558,0.277,0.981c0,0.324-0.061,0.606-0.183,0.845c-0.122,0.24-0.289,0.439-0.501,0.603
    c-0.211,0.162-0.453,0.284-0.723,0.365c-0.271,0.081-0.555,0.122-0.854,0.122c-0.541,0-1.034-0.104-1.48-0.312
    s-0.791-0.528-1.035-0.96L181.349,414.747z" />
                        <path d="M196.892,415.938c0,0.775-0.113,1.402-0.338,1.881c-0.226,0.477-0.508,0.847-0.846,1.108s-0.701,0.438-1.089,0.528
    c-0.388,0.09-0.739,0.135-1.056,0.135c-0.315,0-0.624-0.029-0.926-0.088c-0.303-0.059-0.589-0.145-0.859-0.258
    c-0.271-0.112-0.516-0.25-0.737-0.412s-0.412-0.348-0.575-0.555l0.637-0.595c0.288,0.405,0.648,0.703,1.082,0.893
    c0.433,0.189,0.893,0.284,1.379,0.284c0.415,0,0.765-0.055,1.049-0.162c0.284-0.108,0.519-0.25,0.703-0.427
    c0.186-0.176,0.33-0.37,0.434-0.581c0.104-0.213,0.18-0.424,0.229-0.637c0.05-0.211,0.079-0.412,0.088-0.602
    s0.014-0.342,0.014-0.459v-0.866h-0.026c-0.235,0.378-0.553,0.677-0.954,0.893s-0.872,0.325-1.414,0.325
    c-0.46,0-0.893-0.084-1.298-0.251c-0.406-0.167-0.761-0.396-1.062-0.69c-0.303-0.293-0.541-0.633-0.717-1.021
    s-0.264-0.803-0.264-1.245c0-0.441,0.088-0.855,0.264-1.244c0.176-0.388,0.414-0.726,0.717-1.014
    c0.302-0.289,0.656-0.52,1.062-0.69c0.405-0.171,0.838-0.257,1.298-0.257c0.542,0,1.013,0.107,1.414,0.324
    s0.719,0.514,0.954,0.893h0.026v-1.055h0.812V415.938z M196.106,413.137c0-0.342-0.064-0.664-0.195-0.967
    s-0.307-0.563-0.527-0.785c-0.222-0.221-0.482-0.396-0.785-0.527s-0.625-0.195-0.967-0.195c-0.344,0-0.666,0.064-0.967,0.195
    c-0.303,0.131-0.564,0.307-0.785,0.527c-0.221,0.222-0.396,0.482-0.527,0.785s-0.196,0.625-0.196,0.967
    c0,0.344,0.065,0.666,0.196,0.968s0.307,0.563,0.527,0.784s0.482,0.396,0.785,0.527c0.301,0.131,0.623,0.197,0.967,0.197
    c0.342,0,0.664-0.066,0.967-0.197s0.563-0.307,0.785-0.527c0.221-0.221,0.396-0.482,0.527-0.784S196.106,413.48,196.106,413.137z
    " />
                        <path d="M198.88,411.852c0-0.117-0.004-0.259-0.014-0.426c-0.009-0.166-0.016-0.336-0.02-0.507
    c-0.005-0.171-0.012-0.331-0.021-0.48s-0.014-0.264-0.014-0.345h0.812c0.009,0.234,0.016,0.459,0.021,0.676
    s0.016,0.357,0.034,0.42c0.207-0.369,0.473-0.672,0.798-0.906c0.324-0.234,0.722-0.352,1.19-0.352c0.081,0,0.16,0.006,0.236,0.02
    s0.156,0.029,0.236,0.048l-0.094,0.798c-0.108-0.035-0.213-0.054-0.312-0.054c-0.352,0-0.656,0.056-0.913,0.169
    s-0.469,0.269-0.636,0.467s-0.291,0.43-0.372,0.696s-0.121,0.553-0.121,0.858v3.41h-0.812V411.852z" />
                        <path d="M208.241,413.719c0,0.307,0.002,0.582,0.006,0.825c0.005,0.243,0.012,0.47,0.021,0.677
    c0.008,0.207,0.02,0.401,0.033,0.582c0.014,0.18,0.033,0.359,0.061,0.541h-0.758c-0.054-0.307-0.08-0.645-0.08-1.016h-0.027
    c-0.234,0.406-0.525,0.704-0.873,0.893c-0.347,0.189-0.787,0.285-1.318,0.285c-0.271,0-0.537-0.034-0.799-0.102
    c-0.262-0.068-0.496-0.176-0.703-0.324c-0.207-0.149-0.377-0.341-0.508-0.576c-0.13-0.234-0.195-0.518-0.195-0.852
    c0-0.479,0.121-0.857,0.365-1.137s0.543-0.488,0.899-0.629c0.356-0.14,0.735-0.229,1.136-0.27
    c0.402-0.041,0.756-0.062,1.062-0.062h0.947v-0.379c0-0.541-0.168-0.929-0.501-1.163s-0.753-0.352-1.258-0.352
    c-0.722,0-1.357,0.234-1.907,0.703l-0.474-0.555c0.298-0.289,0.667-0.508,1.109-0.656c0.441-0.148,0.866-0.223,1.271-0.223
    c0.757,0,1.361,0.178,1.812,0.533c0.451,0.357,0.677,0.932,0.677,1.726V413.719z M205.347,406.766h0.946l1.461,1.948h-0.879
    l-1.097-1.461l-1.096,1.461h-0.797L205.347,406.766z M206.618,413.205c-0.344,0-0.675,0.022-0.994,0.067
    c-0.32,0.046-0.609,0.122-0.866,0.229c-0.257,0.109-0.462,0.253-0.616,0.434c-0.152,0.18-0.229,0.406-0.229,0.676
    c0,0.189,0.043,0.359,0.128,0.508c0.086,0.148,0.197,0.271,0.332,0.365c0.135,0.094,0.286,0.167,0.453,0.217
    c0.167,0.049,0.336,0.074,0.508,0.074c0.433,0,0.789-0.063,1.068-0.189c0.279-0.127,0.502-0.291,0.67-0.494
    c0.166-0.203,0.281-0.433,0.344-0.689c0.063-0.258,0.096-0.517,0.096-0.778v-0.419H206.618z" />
                        <path d="M215.64,415.424c-0.307,0.369-0.653,0.643-1.041,0.818s-0.807,0.264-1.258,0.264c-0.479,0-0.92-0.082-1.326-0.244
    c-0.405-0.162-0.753-0.39-1.041-0.684c-0.289-0.293-0.515-0.64-0.677-1.041c-0.162-0.4-0.243-0.84-0.243-1.318
    s0.081-0.918,0.243-1.319s0.388-0.749,0.677-1.042c0.288-0.293,0.636-0.521,1.041-0.683c0.406-0.162,0.848-0.243,1.326-0.243
    c0.46,0,0.886,0.094,1.278,0.283c0.392,0.189,0.741,0.456,1.048,0.799l-0.648,0.486c-0.235-0.262-0.492-0.467-0.771-0.615
    s-0.582-0.223-0.906-0.223c-0.379,0-0.722,0.067-1.028,0.203c-0.307,0.135-0.568,0.317-0.784,0.547
    c-0.217,0.23-0.381,0.501-0.494,0.812c-0.113,0.311-0.169,0.643-0.169,0.994s0.056,0.683,0.169,0.994
    c0.113,0.311,0.277,0.582,0.494,0.812c0.216,0.229,0.478,0.413,0.784,0.548s0.649,0.203,1.028,0.203
    c0.342,0,0.656-0.079,0.939-0.237c0.285-0.157,0.53-0.367,0.738-0.629L215.64,415.424z" />
                        <path d="M222.431,415.166c-0.315,0.479-0.705,0.82-1.17,1.028s-0.977,0.312-1.535,0.312c-0.479,0-0.911-0.082-1.299-0.244
    s-0.722-0.39-1.001-0.684c-0.28-0.293-0.493-0.64-0.643-1.041c-0.149-0.4-0.224-0.846-0.224-1.332
    c0-0.469,0.076-0.902,0.23-1.299c0.153-0.396,0.369-0.742,0.649-1.035c0.279-0.293,0.608-0.522,0.987-0.689
    s0.799-0.25,1.258-0.25c0.479,0,0.899,0.083,1.266,0.25c0.365,0.167,0.671,0.388,0.92,0.662c0.247,0.275,0.435,0.589,0.561,0.941
    c0.127,0.352,0.189,0.707,0.189,1.068v0.568h-5.248c0,0.171,0.033,0.39,0.102,0.656c0.066,0.266,0.191,0.525,0.371,0.777
    c0.181,0.252,0.422,0.469,0.725,0.648c0.301,0.182,0.691,0.271,1.17,0.271c0.414,0,0.816-0.1,1.203-0.298
    c0.389-0.198,0.686-0.469,0.893-0.812L222.431,415.166z M221.809,412.691c0-0.28-0.057-0.542-0.169-0.785
    c-0.113-0.244-0.266-0.457-0.46-0.643c-0.194-0.185-0.425-0.332-0.69-0.439c-0.266-0.108-0.547-0.162-0.846-0.162
    c-0.441,0-0.809,0.09-1.102,0.27c-0.293,0.182-0.525,0.389-0.697,0.623c-0.171,0.234-0.293,0.462-0.365,0.684
    c-0.072,0.221-0.107,0.371-0.107,0.453H221.809z" />
                        <path d="M232.806,413.719c0,0.307,0.002,0.582,0.008,0.825c0.004,0.243,0.011,0.47,0.02,0.677
    c0.01,0.207,0.021,0.401,0.034,0.582c0.014,0.18,0.034,0.359,0.062,0.541h-0.758c-0.055-0.307-0.082-0.645-0.082-1.016h-0.027
    c-0.234,0.406-0.525,0.704-0.871,0.893c-0.348,0.189-0.787,0.285-1.32,0.285c-0.27,0-0.536-0.034-0.797-0.102
    c-0.262-0.068-0.496-0.176-0.704-0.324c-0.208-0.149-0.376-0.341-0.507-0.576c-0.131-0.234-0.196-0.518-0.196-0.852
    c0-0.479,0.122-0.857,0.365-1.137s0.543-0.488,0.899-0.629c0.355-0.14,0.734-0.229,1.137-0.27c0.4-0.041,0.755-0.062,1.062-0.062
    h0.946v-0.379c0-0.541-0.166-0.929-0.5-1.163s-0.753-0.352-1.258-0.352c-0.722,0-1.357,0.234-1.908,0.703l-0.473-0.555
    c0.297-0.289,0.667-0.508,1.109-0.656c0.441-0.148,0.865-0.223,1.271-0.223c0.758,0,1.361,0.178,1.812,0.533
    c0.451,0.357,0.676,0.932,0.676,1.726V413.719z M231.183,413.205c-0.342,0-0.674,0.022-0.994,0.067
    c-0.32,0.046-0.608,0.122-0.865,0.229c-0.258,0.109-0.463,0.253-0.615,0.434c-0.154,0.18-0.23,0.406-0.23,0.676
    c0,0.189,0.043,0.359,0.129,0.508c0.085,0.148,0.195,0.271,0.331,0.365c0.136,0.094,0.286,0.167,0.453,0.217
    c0.167,0.049,0.336,0.074,0.507,0.074c0.434,0,0.789-0.063,1.068-0.189c0.279-0.127,0.503-0.291,0.67-0.494
    s0.282-0.433,0.346-0.689c0.062-0.258,0.094-0.517,0.094-0.778v-0.419H231.183z" />
                        <path d="M240.26,414.584c0,0.117,0.005,0.26,0.013,0.427c0.01,0.167,0.017,0.336,0.021,0.507c0.004,0.172,0.011,0.332,0.02,0.48
    c0.01,0.148,0.014,0.264,0.014,0.346h-0.812c-0.009-0.234-0.016-0.461-0.021-0.678c-0.004-0.216-0.016-0.355-0.033-0.418h-0.041
    c-0.162,0.342-0.433,0.638-0.812,0.885c-0.379,0.248-0.821,0.373-1.325,0.373c-0.488,0-0.887-0.077-1.197-0.23
    c-0.312-0.153-0.555-0.354-0.73-0.602s-0.296-0.535-0.359-0.859c-0.062-0.324-0.094-0.658-0.094-1.001v-3.72h0.811v3.68
    c0,0.252,0.023,0.498,0.068,0.736c0.045,0.24,0.124,0.453,0.236,0.643c0.113,0.189,0.271,0.341,0.474,0.453
    c0.203,0.113,0.467,0.17,0.792,0.17c0.297,0,0.578-0.053,0.846-0.156c0.266-0.104,0.495-0.262,0.689-0.473
    c0.193-0.213,0.347-0.479,0.459-0.799c0.113-0.32,0.17-0.696,0.17-1.129v-3.125h0.812V414.584z" />
                        <path d="M244.088,412.961l-2.231-2.867h1.041l1.731,2.367l1.718-2.367h1.015l-2.204,2.867l2.678,3.383h-1.055l-2.179-2.828
    l-2.137,2.828h-0.974L244.088,412.961z" />
                        <path
                            d="M99.164,427.057h-1.786v4.072c0,0.279,0.076,0.496,0.229,0.648c0.153,0.154,0.366,0.23,0.636,0.23
    c0.153,0,0.309-0.021,0.466-0.061c0.158-0.041,0.309-0.093,0.454-0.156l0.067,0.73c-0.153,0.055-0.329,0.104-0.527,0.149
    c-0.199,0.045-0.375,0.067-0.528,0.067c-0.289,0-0.537-0.043-0.744-0.129c-0.208-0.085-0.374-0.2-0.5-0.344
    c-0.126-0.145-0.219-0.314-0.277-0.508s-0.088-0.404-0.088-0.629v-4.072h-1.353v-0.73h1.353v-1.799h0.812v1.799h1.786V427.057z" />
                        <path d="M100.611,428.085c0-0.117-0.005-0.259-0.014-0.427c-0.009-0.166-0.016-0.336-0.021-0.506
    c-0.004-0.172-0.011-0.332-0.021-0.48c-0.009-0.149-0.013-0.264-0.013-0.346h0.812c0.009,0.234,0.016,0.46,0.021,0.676
    c0.004,0.217,0.016,0.357,0.034,0.42c0.207-0.369,0.474-0.672,0.798-0.906c0.325-0.234,0.722-0.352,1.191-0.352
    c0.081,0,0.16,0.007,0.236,0.02c0.077,0.014,0.156,0.03,0.237,0.049l-0.095,0.797c-0.108-0.035-0.212-0.054-0.311-0.054
    c-0.352,0-0.656,0.057-0.913,0.169c-0.257,0.113-0.469,0.27-0.636,0.467c-0.167,0.199-0.291,0.431-0.372,0.697
    c-0.081,0.266-0.122,0.553-0.122,0.858v3.409h-0.812V428.085z" />
                        <path d="M105.048,423.852c0-0.172,0.059-0.316,0.176-0.434s0.262-0.176,0.433-0.176c0.171,0,0.315,0.059,0.433,0.176
    c0.118,0.117,0.176,0.262,0.176,0.434c0,0.171-0.058,0.315-0.176,0.432c-0.117,0.118-0.262,0.176-0.433,0.176
    c-0.171,0-0.316-0.058-0.433-0.176C105.106,424.167,105.048,424.022,105.048,423.852z M105.251,426.326h0.812v6.25h-0.812
    V426.326z" />
                        <path d="M117.344,431.48h-0.027c-0.126,0.217-0.284,0.404-0.473,0.561c-0.189,0.158-0.395,0.289-0.616,0.393
    c-0.221,0.104-0.453,0.181-0.697,0.23c-0.243,0.049-0.482,0.074-0.717,0.074c-0.478,0-0.92-0.081-1.326-0.243
    c-0.406-0.163-0.753-0.39-1.042-0.684c-0.289-0.293-0.514-0.64-0.677-1.042c-0.162-0.4-0.243-0.84-0.243-1.318
    s0.081-0.918,0.243-1.318c0.163-0.402,0.388-0.749,0.677-1.042c0.288-0.293,0.636-0.521,1.042-0.683s0.848-0.244,1.326-0.244
    c0.234,0,0.474,0.025,0.717,0.074c0.244,0.05,0.476,0.127,0.697,0.23c0.221,0.104,0.426,0.234,0.616,0.393
    c0.189,0.157,0.347,0.344,0.473,0.561h0.027v-5.072h0.812v10.227h-0.812V431.48z M117.425,429.451
    c0-0.352-0.068-0.684-0.203-0.994s-0.32-0.582-0.555-0.812s-0.509-0.413-0.825-0.548c-0.315-0.136-0.658-0.203-1.028-0.203
    c-0.379,0-0.722,0.067-1.028,0.203c-0.307,0.135-0.568,0.318-0.784,0.548s-0.381,0.501-0.494,0.812s-0.169,0.643-0.169,0.994
    s0.056,0.684,0.169,0.994c0.113,0.312,0.278,0.582,0.494,0.812c0.216,0.23,0.478,0.413,0.784,0.548
    c0.307,0.136,0.649,0.203,1.028,0.203c0.37,0,0.712-0.067,1.028-0.203c0.316-0.135,0.591-0.317,0.825-0.548
    c0.234-0.229,0.419-0.5,0.555-0.812C117.357,430.135,117.425,429.803,117.425,429.451z" />
                        <path d="M125.663,431.398c-0.315,0.479-0.706,0.821-1.17,1.029c-0.465,0.207-0.977,0.311-1.535,0.311
    c-0.479,0-0.911-0.081-1.299-0.243c-0.388-0.163-0.722-0.39-1.001-0.684c-0.28-0.293-0.494-0.64-0.643-1.042
    c-0.149-0.4-0.223-0.845-0.223-1.332c0-0.469,0.077-0.901,0.23-1.299c0.153-0.396,0.37-0.741,0.649-1.035
    c0.279-0.292,0.608-0.521,0.987-0.689c0.379-0.166,0.798-0.25,1.258-0.25c0.478,0,0.899,0.084,1.265,0.25
    c0.365,0.168,0.671,0.389,0.92,0.663c0.248,0.274,0.435,0.589,0.561,0.94c0.126,0.352,0.189,0.708,0.189,1.068v0.568h-5.249
    c0,0.172,0.034,0.391,0.102,0.656c0.067,0.266,0.191,0.525,0.372,0.777c0.181,0.253,0.422,0.469,0.724,0.649
    c0.302,0.181,0.692,0.271,1.17,0.271c0.415,0,0.816-0.1,1.204-0.297c0.388-0.199,0.685-0.469,0.893-0.812L125.663,431.398z
     M125.041,428.924c0-0.279-0.057-0.541-0.169-0.785c-0.113-0.243-0.266-0.457-0.46-0.643c-0.194-0.185-0.424-0.331-0.689-0.439
    c-0.266-0.107-0.548-0.162-0.845-0.162c-0.442,0-0.81,0.09-1.103,0.271s-0.525,0.388-0.697,0.622
    c-0.171,0.234-0.293,0.463-0.365,0.684c-0.072,0.221-0.108,0.371-0.108,0.453H125.041z" />
                        <path
                            d="M127.679,430.98c0.171,0.333,0.424,0.588,0.758,0.764c0.333,0.176,0.685,0.264,1.055,0.264
    c0.171,0,0.343-0.027,0.514-0.081s0.325-0.13,0.46-0.229s0.246-0.219,0.332-0.359c0.086-0.139,0.128-0.299,0.128-0.48
    c0-0.288-0.093-0.504-0.277-0.648c-0.185-0.145-0.413-0.258-0.683-0.338c-0.271-0.082-0.568-0.151-0.893-0.211
    c-0.325-0.058-0.623-0.148-0.893-0.27c-0.271-0.122-0.499-0.291-0.683-0.508c-0.185-0.217-0.277-0.522-0.277-0.92
    c0-0.307,0.063-0.572,0.189-0.798c0.126-0.226,0.291-0.413,0.494-0.562c0.203-0.148,0.431-0.259,0.683-0.331
    s0.51-0.108,0.771-0.108c0.514,0,0.958,0.095,1.333,0.284s0.665,0.487,0.873,0.893l-0.73,0.433
    c-0.153-0.288-0.345-0.507-0.575-0.656c-0.23-0.148-0.53-0.223-0.9-0.223c-0.144,0-0.295,0.023-0.453,0.067
    c-0.158,0.046-0.3,0.108-0.426,0.19c-0.126,0.08-0.232,0.184-0.318,0.311c-0.086,0.127-0.129,0.271-0.129,0.433
    c0,0.28,0.093,0.487,0.278,0.622c0.184,0.135,0.412,0.242,0.683,0.318c0.271,0.076,0.568,0.143,0.893,0.195
    c0.325,0.055,0.622,0.143,0.893,0.264c0.271,0.123,0.499,0.299,0.684,0.528c0.185,0.229,0.277,0.558,0.277,0.981
    c0,0.324-0.061,0.605-0.182,0.845c-0.122,0.239-0.289,0.439-0.501,0.603c-0.212,0.162-0.453,0.283-0.724,0.365
    c-0.271,0.08-0.555,0.121-0.852,0.121c-0.542,0-1.035-0.104-1.481-0.311c-0.447-0.208-0.792-0.528-1.035-0.961L127.679,430.98z" />
                        <path d="M136.945,422.35h0.812v5.072h0.027c0.162-0.342,0.433-0.638,0.812-0.886s0.82-0.372,1.326-0.372
    c0.487,0,0.884,0.076,1.19,0.23c0.307,0.152,0.548,0.354,0.724,0.602s0.298,0.534,0.365,0.859c0.068,0.324,0.102,0.658,0.102,1
    v3.721h-0.812v-3.68c0-0.252-0.022-0.498-0.067-0.736c-0.045-0.24-0.124-0.454-0.237-0.644c-0.113-0.188-0.271-0.341-0.474-0.452
    c-0.203-0.113-0.466-0.17-0.791-0.17c-0.298,0-0.58,0.053-0.846,0.156c-0.266,0.104-0.496,0.261-0.689,0.473
    c-0.194,0.213-0.348,0.479-0.46,0.799c-0.113,0.32-0.169,0.696-0.169,1.129v3.125h-0.812V422.35z" />
                        <path d="M149.322,429.951c0,0.307,0.002,0.582,0.007,0.826c0.004,0.243,0.011,0.469,0.02,0.676
    c0.009,0.207,0.021,0.401,0.034,0.582c0.013,0.18,0.034,0.36,0.061,0.541h-0.757c-0.054-0.307-0.082-0.645-0.082-1.015h-0.027
    c-0.234,0.405-0.525,0.704-0.873,0.893c-0.347,0.189-0.787,0.284-1.319,0.284c-0.271,0-0.537-0.033-0.798-0.102
    c-0.262-0.067-0.496-0.176-0.704-0.324s-0.376-0.34-0.507-0.575c-0.131-0.234-0.196-0.519-0.196-0.853
    c0-0.478,0.122-0.856,0.365-1.136c0.244-0.28,0.543-0.489,0.9-0.63c0.356-0.139,0.735-0.229,1.136-0.27s0.755-0.061,1.062-0.061
    h0.947v-0.379c0-0.541-0.167-0.93-0.5-1.164c-0.334-0.234-0.753-0.352-1.258-0.352c-0.721,0-1.357,0.234-1.907,0.703
    l-0.474-0.555c0.298-0.288,0.667-0.507,1.109-0.656c0.442-0.148,0.866-0.223,1.271-0.223c0.758,0,1.362,0.178,1.813,0.534
    c0.451,0.356,0.676,0.932,0.676,1.726V429.951z M147.699,429.438c-0.343,0-0.674,0.023-0.994,0.068
    c-0.32,0.045-0.609,0.121-0.866,0.229s-0.462,0.253-0.615,0.433c-0.153,0.181-0.23,0.406-0.23,0.677
    c0,0.188,0.042,0.358,0.128,0.507c0.085,0.148,0.196,0.271,0.332,0.365c0.135,0.095,0.286,0.168,0.453,0.217
    c0.167,0.049,0.335,0.074,0.507,0.074c0.433,0,0.789-0.062,1.068-0.189c0.28-0.126,0.503-0.291,0.67-0.494
    c0.167-0.203,0.282-0.432,0.345-0.689c0.063-0.257,0.095-0.516,0.095-0.777v-0.42H147.699z" />
                        <path d="M151.311,422.35h0.812v5.072h0.027c0.126-0.217,0.284-0.403,0.474-0.561c0.189-0.158,0.395-0.289,0.615-0.393
    s0.453-0.181,0.697-0.23c0.243-0.049,0.481-0.074,0.717-0.074c0.478,0,0.917,0.082,1.318,0.244s0.748,0.39,1.041,0.683
    c0.294,0.293,0.521,0.64,0.684,1.042c0.162,0.4,0.244,0.84,0.244,1.318s-0.082,0.918-0.244,1.318
    c-0.162,0.402-0.39,0.749-0.684,1.042c-0.293,0.294-0.64,0.521-1.041,0.684c-0.401,0.162-0.841,0.243-1.318,0.243
    c-0.235,0-0.474-0.025-0.717-0.074c-0.244-0.05-0.477-0.127-0.697-0.23s-0.426-0.234-0.615-0.393
    c-0.189-0.156-0.348-0.344-0.474-0.561h-0.027v1.096h-0.812V422.35z M152.041,429.451c0,0.352,0.068,0.684,0.203,0.994
    c0.135,0.312,0.32,0.582,0.555,0.812c0.234,0.23,0.51,0.413,0.825,0.548c0.315,0.136,0.658,0.203,1.028,0.203
    c0.379,0,0.721-0.067,1.027-0.203c0.307-0.135,0.568-0.317,0.785-0.548c0.217-0.229,0.381-0.5,0.494-0.812
    c0.112-0.311,0.168-0.643,0.168-0.994s-0.056-0.684-0.168-0.994c-0.113-0.311-0.277-0.582-0.494-0.812s-0.479-0.413-0.785-0.548
    c-0.307-0.136-0.648-0.203-1.027-0.203c-0.37,0-0.713,0.067-1.028,0.203c-0.315,0.135-0.591,0.318-0.825,0.548
    s-0.42,0.501-0.555,0.812S152.041,429.1,152.041,429.451z" />
                        <path d="M159.684,423.852c0-0.172,0.059-0.316,0.176-0.434s0.262-0.176,0.434-0.176c0.171,0,0.315,0.059,0.433,0.176
    s0.176,0.262,0.176,0.434c0,0.171-0.059,0.315-0.176,0.432c-0.117,0.118-0.262,0.176-0.433,0.176
    c-0.172,0-0.316-0.058-0.434-0.176C159.742,424.167,159.684,424.022,159.684,423.852z M159.887,426.326h0.812v6.25h-0.812
    V426.326z" />
                        <path
                            d="M166.055,427.057h-1.785v4.072c0,0.279,0.076,0.496,0.23,0.648c0.152,0.154,0.365,0.23,0.635,0.23
    c0.154,0,0.31-0.021,0.467-0.061c0.158-0.041,0.309-0.093,0.453-0.156l0.068,0.73c-0.154,0.055-0.33,0.104-0.527,0.149
    c-0.199,0.045-0.375,0.067-0.528,0.067c-0.288,0-0.536-0.043-0.743-0.129c-0.208-0.085-0.375-0.2-0.501-0.344
    c-0.126-0.145-0.219-0.314-0.277-0.508s-0.088-0.404-0.088-0.629v-4.072h-1.353v-0.73h1.353v-1.799h0.812v1.799h1.785V427.057z" />
                        <path d="M172.359,429.951c0,0.307,0.002,0.582,0.006,0.826c0.005,0.243,0.012,0.469,0.021,0.676s0.021,0.401,0.034,0.582
    c0.014,0.18,0.033,0.36,0.061,0.541h-0.758c-0.054-0.307-0.081-0.645-0.081-1.015h-0.026c-0.234,0.405-0.525,0.704-0.873,0.893
    c-0.348,0.189-0.787,0.284-1.318,0.284c-0.271,0-0.537-0.033-0.799-0.102c-0.262-0.067-0.496-0.176-0.703-0.324
    s-0.377-0.34-0.508-0.575c-0.131-0.234-0.195-0.519-0.195-0.853c0-0.478,0.121-0.856,0.365-1.136
    c0.243-0.28,0.543-0.489,0.899-0.63c0.356-0.139,0.735-0.229,1.136-0.27c0.401-0.041,0.756-0.061,1.062-0.061h0.947v-0.379
    c0-0.541-0.168-0.93-0.501-1.164c-0.334-0.234-0.753-0.352-1.259-0.352c-0.721,0-1.356,0.234-1.906,0.703l-0.475-0.555
    c0.299-0.288,0.668-0.507,1.109-0.656c0.442-0.148,0.866-0.223,1.271-0.223c0.758,0,1.362,0.178,1.813,0.534
    c0.45,0.356,0.677,0.932,0.677,1.726V429.951z M170.735,429.438c-0.343,0-0.674,0.023-0.994,0.068s-0.608,0.121-0.866,0.229
    c-0.257,0.108-0.462,0.253-0.615,0.433c-0.153,0.181-0.229,0.406-0.229,0.677c0,0.188,0.042,0.358,0.128,0.507
    s0.196,0.271,0.332,0.365c0.135,0.095,0.286,0.168,0.453,0.217c0.166,0.049,0.336,0.074,0.507,0.074
    c0.433,0,0.789-0.062,1.069-0.189c0.279-0.126,0.502-0.291,0.669-0.494s0.282-0.432,0.345-0.689
    c0.063-0.257,0.096-0.516,0.096-0.777v-0.42H170.735z" />
                        <path d="M174.523,428.085c0-0.117-0.005-0.259-0.014-0.427c-0.01-0.166-0.016-0.336-0.021-0.506
    c-0.005-0.172-0.011-0.332-0.021-0.48c-0.009-0.149-0.014-0.264-0.014-0.346h0.812c0.008,0.234,0.016,0.46,0.02,0.676
    c0.005,0.217,0.016,0.357,0.034,0.42h0.04c0.163-0.342,0.434-0.638,0.812-0.886s0.82-0.372,1.325-0.372
    c0.487,0,0.884,0.076,1.19,0.23c0.307,0.152,0.548,0.354,0.724,0.602s0.298,0.534,0.365,0.859c0.067,0.324,0.102,0.658,0.102,1
    v3.721h-0.812v-3.68c0-0.252-0.022-0.498-0.068-0.736c-0.045-0.24-0.123-0.454-0.236-0.644c-0.112-0.188-0.271-0.341-0.473-0.452
    c-0.203-0.113-0.467-0.17-0.792-0.17c-0.298,0-0.579,0.053-0.845,0.156c-0.267,0.104-0.496,0.261-0.69,0.473
    c-0.194,0.213-0.348,0.479-0.46,0.799c-0.113,0.32-0.169,0.696-0.169,1.129v3.125h-0.812V428.085z" />
                        <path
                            d="M185.102,427.057h-1.785v4.072c0,0.279,0.076,0.496,0.229,0.648c0.153,0.154,0.365,0.23,0.636,0.23
    c0.153,0,0.309-0.021,0.467-0.061c0.158-0.041,0.309-0.093,0.453-0.156l0.067,0.73c-0.153,0.055-0.329,0.104-0.527,0.149
    c-0.198,0.045-0.374,0.067-0.527,0.067c-0.289,0-0.537-0.043-0.744-0.129c-0.208-0.085-0.374-0.2-0.501-0.344
    c-0.126-0.145-0.219-0.314-0.277-0.508s-0.088-0.404-0.088-0.629v-4.072h-1.353v-0.73h1.353v-1.799h0.812v1.799h1.785V427.057z" />
                        <path d="M186.562,430.98c0.171,0.333,0.424,0.588,0.758,0.764c0.333,0.176,0.685,0.264,1.055,0.264
    c0.171,0,0.343-0.027,0.514-0.081c0.172-0.054,0.325-0.13,0.461-0.229c0.135-0.1,0.244-0.219,0.33-0.359
    c0.086-0.139,0.129-0.299,0.129-0.48c0-0.288-0.092-0.504-0.277-0.648c-0.185-0.145-0.412-0.258-0.684-0.338
    c-0.27-0.082-0.567-0.151-0.893-0.211c-0.324-0.058-0.621-0.148-0.893-0.27c-0.27-0.122-0.498-0.291-0.684-0.508
    c-0.184-0.217-0.276-0.522-0.276-0.92c0-0.307,0.062-0.572,0.188-0.798c0.127-0.226,0.291-0.413,0.494-0.562
    s0.431-0.259,0.684-0.331c0.252-0.072,0.51-0.108,0.771-0.108c0.514,0,0.957,0.095,1.332,0.284
    c0.374,0.189,0.664,0.487,0.873,0.893l-0.731,0.433c-0.153-0.288-0.345-0.507-0.575-0.656c-0.229-0.148-0.529-0.223-0.898-0.223
    c-0.145,0-0.296,0.023-0.453,0.067c-0.158,0.046-0.301,0.108-0.427,0.19c-0.126,0.08-0.232,0.184-0.317,0.311
    c-0.086,0.127-0.129,0.271-0.129,0.433c0,0.28,0.092,0.487,0.277,0.622c0.185,0.135,0.412,0.242,0.684,0.318
    c0.27,0.076,0.567,0.143,0.893,0.195c0.324,0.055,0.622,0.143,0.893,0.264c0.271,0.123,0.498,0.299,0.684,0.528
    c0.184,0.229,0.277,0.558,0.277,0.981c0,0.324-0.062,0.605-0.184,0.845c-0.121,0.239-0.289,0.439-0.5,0.603
    c-0.212,0.162-0.453,0.283-0.724,0.365c-0.271,0.08-0.555,0.121-0.853,0.121c-0.541,0-1.035-0.104-1.48-0.311
    c-0.447-0.208-0.792-0.528-1.035-0.961L186.562,430.98z" />
                        <path
                            d="M196.6,432.008c0-0.18,0.062-0.334,0.189-0.46c0.126-0.126,0.279-0.189,0.459-0.189c0.182,0,0.334,0.063,0.461,0.189
    c0.125,0.126,0.189,0.28,0.189,0.46c0,0.181-0.064,0.334-0.189,0.46c-0.127,0.126-0.279,0.189-0.461,0.189
    c-0.18,0-0.333-0.063-0.459-0.189C196.662,432.342,196.6,432.188,196.6,432.008z M197.654,430.061h-0.812v-7.062h0.812V430.061z" />
                    </g>
                    <g>
                        <path d="M102.218,217.971h3.863c0.373,0,0.741,0.044,1.105,0.133c0.364,0.088,0.688,0.233,0.973,0.434s0.516,0.459,0.693,0.777
    s0.266,0.7,0.266,1.147c0,0.56-0.159,1.021-0.476,1.385c-0.317,0.364-0.732,0.625-1.246,0.784v0.028
    c0.308,0.038,0.595,0.124,0.861,0.259s0.492,0.308,0.679,0.518c0.187,0.21,0.333,0.453,0.441,0.728
    c0.107,0.275,0.161,0.572,0.161,0.889c0,0.541-0.107,0.991-0.322,1.351c-0.215,0.359-0.497,0.648-0.847,0.867
    c-0.35,0.219-0.751,0.376-1.204,0.469s-0.912,0.14-1.378,0.14h-3.568V217.971z M103.981,222.001h1.609
    c0.588,0,1.029-0.114,1.323-0.343c0.293-0.229,0.44-0.529,0.44-0.902c0-0.429-0.152-0.749-0.455-0.959
    c-0.303-0.209-0.791-0.314-1.462-0.314h-1.455V222.001z M103.981,226.368h1.623c0.224,0,0.464-0.014,0.721-0.042
    c0.256-0.028,0.492-0.093,0.707-0.196s0.392-0.25,0.532-0.441c0.14-0.191,0.21-0.45,0.21-0.777c0-0.522-0.17-0.886-0.511-1.091
    s-0.87-0.308-1.588-0.308h-1.693V226.368z" />
                        <path d="M111.132,221.162h1.679v1.063h0.028c0.187-0.392,0.452-0.695,0.797-0.91c0.345-0.214,0.742-0.322,1.189-0.322
    c0.103,0,0.203,0.009,0.301,0.028c0.098,0.019,0.198,0.042,0.301,0.07v1.623c-0.14-0.037-0.277-0.067-0.413-0.091
    c-0.135-0.023-0.268-0.035-0.399-0.035c-0.392,0-0.707,0.072-0.944,0.217c-0.238,0.145-0.42,0.31-0.546,0.497
    c-0.126,0.187-0.21,0.373-0.252,0.56s-0.063,0.327-0.063,0.42v3.596h-1.679V221.162z" />
                        <path d="M120.773,226.956h-0.042c-0.243,0.382-0.551,0.66-0.924,0.833c-0.373,0.172-0.783,0.259-1.231,0.259
    c-0.308,0-0.609-0.042-0.903-0.126s-0.555-0.21-0.784-0.378c-0.229-0.168-0.413-0.377-0.552-0.629
    c-0.14-0.252-0.21-0.546-0.21-0.882c0-0.364,0.065-0.674,0.196-0.931c0.131-0.256,0.306-0.471,0.525-0.644
    s0.474-0.31,0.763-0.413c0.289-0.103,0.59-0.179,0.903-0.23c0.312-0.051,0.627-0.084,0.944-0.098s0.616-0.021,0.896-0.021h0.42
    v-0.182c0-0.42-0.145-0.735-0.434-0.945c-0.29-0.21-0.658-0.315-1.105-0.315c-0.355,0-0.686,0.063-0.994,0.189
    c-0.308,0.126-0.574,0.296-0.798,0.511l-0.881-0.882c0.373-0.382,0.809-0.657,1.308-0.826c0.499-0.167,1.015-0.251,1.546-0.251
    c0.476,0,0.877,0.054,1.204,0.161c0.326,0.107,0.597,0.245,0.812,0.413s0.38,0.361,0.497,0.581
    c0.116,0.219,0.2,0.441,0.252,0.665c0.051,0.224,0.082,0.44,0.091,0.65c0.009,0.21,0.014,0.395,0.014,0.553v3.862h-1.512V226.956
    z M120.676,224.87h-0.35c-0.233,0-0.495,0.01-0.784,0.028s-0.562,0.063-0.818,0.133c-0.257,0.07-0.474,0.172-0.651,0.308
    c-0.177,0.135-0.266,0.324-0.266,0.566c0,0.159,0.035,0.292,0.105,0.399c0.07,0.107,0.161,0.198,0.272,0.273
    c0.112,0.075,0.238,0.128,0.378,0.161c0.14,0.033,0.28,0.049,0.42,0.049c0.578,0,1.005-0.138,1.28-0.413
    c0.275-0.275,0.413-0.651,0.413-1.126V224.87z" />
                        <path
                            d="M123.18,221.162h1.833l1.791,4.702h0.028l1.805-4.702h1.722l-2.646,6.717h-1.791L123.18,221.162z" />
                        <path d="M131.017,224.521c0-0.532,0.096-1.015,0.287-1.449c0.191-0.434,0.45-0.804,0.776-1.112
    c0.327-0.308,0.714-0.545,1.162-0.714c0.448-0.167,0.923-0.251,1.427-0.251s0.979,0.084,1.428,0.251
    c0.448,0.168,0.834,0.406,1.161,0.714c0.327,0.308,0.585,0.679,0.777,1.112c0.191,0.434,0.287,0.917,0.287,1.449
    s-0.096,1.015-0.287,1.448c-0.191,0.434-0.45,0.805-0.777,1.113c-0.327,0.308-0.713,0.545-1.161,0.713
    c-0.448,0.168-0.924,0.252-1.428,0.252s-0.979-0.084-1.427-0.252s-0.835-0.406-1.162-0.713c-0.327-0.308-0.585-0.679-0.776-1.113
    C131.112,225.535,131.017,225.052,131.017,224.521z M132.696,224.521c0,0.261,0.044,0.513,0.133,0.755
    c0.088,0.243,0.219,0.458,0.392,0.644c0.172,0.187,0.38,0.336,0.623,0.448s0.518,0.168,0.826,0.168
    c0.308,0,0.583-0.056,0.826-0.168s0.45-0.261,0.623-0.448c0.173-0.187,0.303-0.401,0.392-0.644
    c0.088-0.242,0.133-0.494,0.133-0.755s-0.044-0.513-0.133-0.756c-0.089-0.242-0.219-0.457-0.392-0.644
    c-0.172-0.187-0.38-0.336-0.623-0.448s-0.518-0.168-0.826-0.168c-0.308,0-0.583,0.056-0.826,0.168s-0.45,0.261-0.623,0.448
    c-0.173,0.187-0.303,0.401-0.392,0.644C132.74,224.007,132.696,224.259,132.696,224.521z" />
                        <path d="M145.374,225.108c0.056,0.503,0.252,0.896,0.588,1.175c0.335,0.28,0.741,0.42,1.217,0.42c0.42,0,0.772-0.086,1.057-0.259
    c0.284-0.172,0.534-0.389,0.749-0.65l1.203,0.91c-0.392,0.485-0.83,0.83-1.315,1.036c-0.485,0.205-0.994,0.308-1.525,0.308
    c-0.504,0-0.98-0.084-1.428-0.252s-0.835-0.406-1.161-0.713c-0.327-0.308-0.585-0.679-0.777-1.113
    c-0.191-0.434-0.287-0.917-0.287-1.448s0.095-1.015,0.287-1.449c0.191-0.434,0.45-0.804,0.777-1.112
    c0.326-0.308,0.713-0.545,1.161-0.714c0.448-0.167,0.924-0.251,1.428-0.251c0.466,0,0.893,0.082,1.28,0.245
    c0.387,0.164,0.718,0.399,0.994,0.707c0.275,0.308,0.49,0.688,0.644,1.141c0.154,0.453,0.231,0.973,0.231,1.561v0.462H145.374z
     M148.816,223.849c-0.009-0.494-0.164-0.884-0.462-1.168c-0.299-0.284-0.714-0.427-1.245-0.427c-0.504,0-0.903,0.145-1.197,0.434
    s-0.474,0.676-0.539,1.161H148.816z" />
                        <path
                            d="M151.279,222.589v-1.427h1.385v-1.945h1.68v1.945h1.848v1.427h-1.848v2.938c0,0.336,0.062,0.602,0.189,0.798
    c0.125,0.196,0.375,0.294,0.748,0.294c0.149,0,0.312-0.017,0.49-0.049c0.177-0.032,0.316-0.086,0.42-0.161v1.413
    c-0.178,0.084-0.395,0.142-0.651,0.175c-0.257,0.032-0.482,0.049-0.679,0.049c-0.447,0-0.814-0.049-1.098-0.147
    c-0.285-0.098-0.51-0.247-0.672-0.448c-0.164-0.201-0.275-0.45-0.336-0.749s-0.092-0.644-0.092-1.036v-3.079H151.279z" />
                        <path d="M161.844,217.971h2.688l2.645,6.941l2.673-6.941h2.659v9.908h-1.68v-8.229h-0.027l-3.01,8.229h-1.23l-3.01-8.229h-0.027
    v8.229h-1.68V217.971z" />
                        <path d="M175.949,225.108c0.057,0.503,0.252,0.896,0.588,1.175s0.742,0.42,1.218,0.42c0.42,0,0.772-0.086,1.057-0.259
    c0.284-0.172,0.534-0.389,0.749-0.65l1.203,0.91c-0.393,0.485-0.83,0.83-1.315,1.036c-0.485,0.205-0.993,0.308-1.525,0.308
    c-0.504,0-0.979-0.084-1.427-0.252c-0.448-0.168-0.836-0.406-1.162-0.713c-0.326-0.308-0.586-0.679-0.776-1.113
    c-0.191-0.434-0.287-0.917-0.287-1.448s0.096-1.015,0.287-1.449c0.19-0.434,0.45-0.804,0.776-1.112
    c0.326-0.308,0.714-0.545,1.162-0.714c0.447-0.167,0.923-0.251,1.427-0.251c0.466,0,0.894,0.082,1.28,0.245
    c0.387,0.164,0.719,0.399,0.994,0.707c0.274,0.308,0.489,0.688,0.644,1.141c0.153,0.453,0.23,0.973,0.23,1.561v0.462H175.949z
     M179.393,223.849c-0.01-0.494-0.164-0.884-0.463-1.168c-0.298-0.284-0.713-0.427-1.244-0.427c-0.504,0-0.903,0.145-1.197,0.434
    c-0.293,0.289-0.473,0.676-0.539,1.161H179.393z" />
                        <path d="M182.639,221.162h1.68v1.063h0.027c0.187-0.392,0.453-0.695,0.798-0.91c0.345-0.214,0.741-0.322,1.189-0.322
    c0.103,0,0.202,0.009,0.301,0.028c0.098,0.019,0.198,0.042,0.301,0.07v1.623c-0.14-0.037-0.277-0.067-0.413-0.091
    c-0.135-0.023-0.268-0.035-0.398-0.035c-0.393,0-0.707,0.072-0.945,0.217c-0.237,0.145-0.419,0.31-0.545,0.497
    c-0.126,0.187-0.21,0.373-0.252,0.56s-0.062,0.327-0.062,0.42v3.596h-1.68V221.162z" />
                        <path
                            d="M192.42,223.177c-0.195-0.205-0.4-0.369-0.615-0.49c-0.215-0.122-0.434-0.182-0.658-0.182
    c-0.308,0-0.582,0.056-0.825,0.168s-0.45,0.261-0.622,0.448c-0.174,0.187-0.305,0.401-0.393,0.644
    c-0.089,0.243-0.133,0.495-0.133,0.756s0.044,0.513,0.133,0.755c0.088,0.243,0.219,0.458,0.393,0.644
    c0.172,0.187,0.379,0.336,0.622,0.448s0.518,0.168,0.825,0.168c0.262,0,0.504-0.054,0.729-0.161
    c0.224-0.107,0.414-0.264,0.573-0.469l1.12,1.204c-0.168,0.168-0.362,0.312-0.582,0.434c-0.219,0.122-0.439,0.219-0.664,0.294
    c-0.224,0.075-0.438,0.128-0.644,0.161c-0.206,0.032-0.383,0.049-0.532,0.049c-0.504,0-0.979-0.084-1.427-0.252
    c-0.448-0.168-0.835-0.406-1.161-0.713c-0.327-0.308-0.586-0.679-0.777-1.113c-0.191-0.434-0.287-0.917-0.287-1.448
    s0.096-1.015,0.287-1.449c0.191-0.434,0.45-0.804,0.777-1.112c0.326-0.308,0.713-0.545,1.161-0.714
    c0.447-0.167,0.923-0.251,1.427-0.251c0.43,0,0.861,0.082,1.295,0.245c0.434,0.164,0.813,0.422,1.141,0.777L192.42,223.177z" />
                        <path
                            d="M194.365,218.881c0-0.27,0.101-0.506,0.301-0.707c0.201-0.201,0.455-0.301,0.763-0.301s0.567,0.096,0.776,0.287
    c0.211,0.191,0.315,0.432,0.315,0.721c0,0.29-0.104,0.53-0.315,0.721c-0.209,0.191-0.469,0.287-0.776,0.287
    s-0.562-0.1-0.763-0.301C194.466,219.387,194.365,219.152,194.365,218.881z M194.604,221.162h1.679v6.717h-1.679V221.162z" />
                        <path d="M202.355,226.899c0-0.298,0.109-0.555,0.329-0.77c0.219-0.215,0.482-0.322,0.79-0.322c0.299,0,0.561,0.103,0.784,0.308
    s0.335,0.457,0.335,0.755c0,0.299-0.109,0.556-0.328,0.77c-0.219,0.215-0.482,0.322-0.791,0.322
    c-0.148,0-0.291-0.028-0.426-0.084c-0.136-0.056-0.255-0.13-0.357-0.224c-0.104-0.093-0.185-0.205-0.245-0.336
    C202.386,227.188,202.355,227.049,202.355,226.899z M204.314,224.772h-1.68v-6.801h1.68V224.772z" />
                    </g>
                    <g id="Btn_Back_2_">
                        <g>
                            <path fill-rule="evenodd" *ngIf="contrat!=undefined" clip-rule="evenodd" attr.fill="{{contrat.design.couleurPrincipale}}" d="M246.35,313.207l-5.088-5.015
     c-0.108-0.106-0.284-0.104-0.393,0.002l-0.736,0.725c-0.108,0.108-0.111,0.28-0.002,0.388l4.209,4.146l-4.209,4.148
     c-0.107,0.106-0.105,0.28,0.002,0.386l0.736,0.726c0.109,0.107,0.283,0.111,0.393,0.003l5.088-5.014
     c0.067-0.066,0.092-0.16,0.074-0.248C246.443,313.368,246.418,313.273,246.35,313.207z" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <path fill="#707070" d="M122.754,564.309h-1.395c-0.225,0-0.407-0.182-0.407-0.406s0.182-0.406,0.407-0.406h1.395
     c0.544,0,0.987-0.443,0.987-0.988v-1.395c0-0.225,0.182-0.406,0.407-0.406s0.407,0.182,0.407,0.406v1.395
     C124.555,563.5,123.747,564.309,122.754,564.309z" />
                        </g>
                        <g>
                            <path fill="#707070" d="M124.148,547.574c-0.225,0-0.407-0.182-0.407-0.406v-1.395c0-0.545-0.443-0.987-0.987-0.987h-1.395
     c-0.225,0-0.407-0.183-0.407-0.407s0.182-0.406,0.407-0.406h1.395c0.993,0,1.801,0.809,1.801,1.801v1.395
     C124.555,547.393,124.373,547.574,124.148,547.574z" />
                        </g>
                        <g>
                            <path fill="#707070" d="M107.415,564.309h-1.395c-0.993,0-1.801-0.809-1.801-1.801v-1.395c0-0.225,0.182-0.406,0.407-0.406
     s0.407,0.182,0.407,0.406v1.395c0,0.545,0.443,0.988,0.987,0.988h1.395c0.224,0,0.407,0.182,0.407,0.406
     S107.639,564.309,107.415,564.309z" />
                        </g>
                        <g>
                            <path fill="#707070" d="M104.625,547.574c-0.225,0-0.407-0.182-0.407-0.406v-1.395c0-0.992,0.808-1.801,1.801-1.801h1.395
     c0.224,0,0.407,0.182,0.407,0.406s-0.183,0.407-0.407,0.407h-1.395c-0.544,0-0.987,0.442-0.987,0.987v1.395
     C105.032,547.393,104.85,547.574,104.625,547.574z" />
                        </g>
                        <g>
                            <path fill="#707070" d="M110.77,560.822c-0.225,0-0.407-0.183-0.407-0.407v-12.55c0-0.225,0.182-0.406,0.407-0.406
     s0.407,0.182,0.407,0.406v12.55C111.177,560.64,110.994,560.822,110.77,560.822z" />
                        </g>
                        <g>
                            <path fill="#707070" d="M116.115,560.822c-0.225,0-0.407-0.183-0.407-0.407v-12.55c0-0.225,0.182-0.406,0.407-0.406
     s0.407,0.182,0.407,0.406v12.55C116.522,560.64,116.34,560.822,116.115,560.822z" />
                        </g>
                        <g>
                            <path fill="#707070" d="M118.09,560.822c-0.225,0-0.407-0.183-0.407-0.407v-12.55c0-0.225,0.182-0.406,0.407-0.406
     s0.407,0.182,0.407,0.406v12.55C118.497,560.64,118.315,560.822,118.09,560.822z" />
                        </g>
                        <g>
                            <path fill="#707070" d="M107.487,560.09v-11.898c0-0.217,0.108-0.326,0.326-0.326h0.568c0.217,0,0.326,0.109,0.326,0.326v11.898
     c0,0.217-0.109,0.325-0.326,0.325h-0.568C107.596,560.415,107.487,560.307,107.487,560.09z" />
                        </g>
                        <g>
                            <path fill="#707070" d="M108.381,560.822h-0.568c-0.445,0-0.733-0.288-0.733-0.732v-11.898c0-0.445,0.288-0.732,0.733-0.732
     h0.568c0.445,0,0.733,0.287,0.733,0.732v11.898C109.114,560.534,108.827,560.822,108.381,560.822z M107.895,560.008h0.405
     l0.001-11.734l-0.405-0.002L107.895,560.008z" />
                        </g>
                        <g>
                            <path fill="#707070" d="M112.833,560.09v-11.898c0-0.217,0.108-0.326,0.326-0.326h0.568c0.217,0,0.327,0.109,0.327,0.326v11.898
     c0,0.217-0.109,0.325-0.327,0.325h-0.568C112.941,560.415,112.833,560.307,112.833,560.09z" />
                        </g>
                        <g>
                            <path fill="#707070" d="M113.727,560.822h-0.568c-0.445,0-0.733-0.288-0.733-0.732v-11.898c0-0.445,0.288-0.732,0.733-0.732
     h0.568c0.445,0,0.733,0.287,0.733,0.732v11.898C114.46,560.527,114.165,560.822,113.727,560.822z M113.24,560.008h0.406
     l0.001-11.734l-0.406-0.002L113.24,560.008z" />
                        </g>
                        <g>
                            <path fill="#707070" d="M120.065,560.09v-11.898c0-0.217,0.109-0.326,0.327-0.326h0.568c0.218,0,0.326,0.109,0.326,0.326v11.898
     c0,0.217-0.108,0.325-0.326,0.325h-0.568C120.174,560.415,120.065,560.307,120.065,560.09z" />
                        </g>
                        <g>
                            <path fill="#707070" d="M120.96,560.822h-0.568c-0.445,0-0.733-0.288-0.733-0.732v-11.898c0-0.445,0.288-0.732,0.733-0.732
     h0.568c0.445,0,0.733,0.287,0.733,0.732v11.898C121.693,560.534,121.405,560.822,120.96,560.822z M120.473,560.008h0.405
     l0.001-11.734l-0.406-0.002L120.473,560.008z" />
                        </g>
                    </g>
                    <g>
                        <g>
                            <g>
                                <path fill="#707070" d="M180.071,563.535L180.071,563.535c-0.353,0-0.688-0.15-0.923-0.413
      c-0.713-0.794-6.961-7.906-6.961-13.028c0-4.348,3.537-7.885,7.884-7.885s7.884,3.537,7.884,7.885
      c0,5.116-6.248,12.234-6.961,13.029C180.76,563.385,180.423,563.535,180.071,563.535z M180.071,543.376
      c-3.704,0-6.717,3.013-6.717,6.718c0,4.769,6.39,11.947,6.662,12.25c0.015,0.017,0.033,0.025,0.055,0.025l0,0
      c0.022,0,0.041-0.01,0.056-0.025c0.271-0.303,6.662-7.486,6.662-12.25C186.789,546.389,183.775,543.376,180.071,543.376z
       M180.071,554.35c-2.347,0-4.255-1.91-4.255-4.256c0-2.348,1.908-4.256,4.255-4.256s4.256,1.908,4.256,4.256
      C184.327,552.439,182.418,554.35,180.071,554.35z M180.071,547.004c-1.703,0-3.089,1.386-3.089,3.09
      c0,1.703,1.386,3.09,3.089,3.09c1.704,0,3.09-1.387,3.09-3.09C183.161,548.39,181.775,547.004,180.071,547.004z" />
                            </g>
                        </g>
                    </g>
                    <g>
                        <path *ngIf="contrat!=undefined" attr.fill="{{contrat.design.couleurPrincipale}}" d="M57.548,553.146c0-0.001-0.001-0.001-0.001-0.001l-7.611-7.611c-0.325-0.324-0.756-0.503-1.215-0.503
    s-0.89,0.179-1.215,0.503l-7.607,7.607c-0.002,0.002-0.005,0.005-0.008,0.008c-0.666,0.67-0.665,1.757,0.003,2.426
    c0.305,0.305,0.708,0.481,1.14,0.5c0.018,0.002,0.035,0.003,0.053,0.003h0.303v5.601c0,1.109,0.902,2.011,2.01,2.011h2.978
    c0.302,0,0.547-0.245,0.547-0.546v-4.393c0-0.506,0.412-0.916,0.917-0.916h1.756c0.506,0,0.917,0.41,0.917,0.916v4.393
    c0,0.301,0.245,0.546,0.546,0.546h2.978c1.108,0,2.01-0.901,2.01-2.011v-5.601h0.281c0.459,0,0.89-0.179,1.215-0.503
    C58.216,554.904,58.216,553.815,57.548,553.146z M56.773,554.801c-0.118,0.118-0.274,0.184-0.441,0.184h-0.828
    c-0.302,0-0.546,0.244-0.546,0.547v6.146c0,0.506-0.412,0.918-0.917,0.918h-2.431v-3.846c0-1.107-0.902-2.01-2.01-2.01h-1.756
    c-1.109,0-2.011,0.902-2.011,2.01v3.846h-2.431c-0.506,0-0.917-0.412-0.917-0.918v-6.146c0-0.303-0.245-0.547-0.546-0.547h-0.814
    c-0.008-0.001-0.017-0.002-0.026-0.002c-0.163-0.002-0.315-0.067-0.43-0.182c-0.244-0.244-0.244-0.64,0-0.883c0,0,0,0,0-0.001
    l0-0.001l7.611-7.609c0.118-0.118,0.274-0.184,0.441-0.184c0.167,0,0.323,0.065,0.441,0.184l7.609,7.609
    c0.001,0,0.002,0.002,0.003,0.002C57.017,554.162,57.017,554.559,56.773,554.801z" />
                    </g>
                    <g>
                        <g>
                            <g>
                                <g>
                                    <path fill="#707070" d="M252.176,556.474c0.43,0,0.777-0.349,0.777-0.778v-8.238c0-1.715-1.394-3.109-3.109-3.109h-1.01
       v-0.777c0-0.43-0.349-0.777-0.777-0.777c-0.43,0-0.777,0.348-0.777,0.777v0.777h-3.537v-0.777c0-0.43-0.348-0.777-0.777-0.777
       s-0.777,0.348-0.777,0.777v0.777h-3.498v-0.777c0-0.43-0.348-0.777-0.777-0.777c-0.429,0-0.777,0.348-0.777,0.777v0.777
       h-0.971c-1.715,0-3.109,1.395-3.109,3.109v12.125c0,1.715,1.395,3.109,3.109,3.109h13.68c1.716,0,3.109-1.395,3.109-3.109
       c0-0.429-0.348-0.777-0.777-0.777c-0.429,0-0.777,0.349-0.777,0.777c0,0.858-0.697,1.555-1.555,1.555h-13.68
       c-0.857,0-1.555-0.696-1.555-1.555v-12.125c0-0.857,0.697-1.555,1.555-1.555h0.971v0.777c0,0.429,0.349,0.777,0.777,0.777
       c0.43,0,0.777-0.349,0.777-0.777v-0.777h3.498v0.777c0,0.429,0.348,0.777,0.777,0.777s0.777-0.349,0.777-0.777v-0.777h3.537
       v0.777c0,0.429,0.348,0.777,0.777,0.777c0.429,0,0.777-0.349,0.777-0.777v-0.777h1.01c0.857,0,1.555,0.697,1.555,1.555v8.238
       C251.398,556.125,251.747,556.474,252.176,556.474z" />
                                    <path fill="#FFFFFF" d="M249.844,562.926h-13.68c-1.844,0-3.344-1.5-3.344-3.344v-12.125c0-1.844,1.5-3.344,3.344-3.344h0.738
       v-0.543c0-0.558,0.453-1.011,1.01-1.011c0.558,0,1.012,0.453,1.012,1.011v0.543h3.031v-0.543c0-0.558,0.453-1.011,1.01-1.011
       c0.558,0,1.012,0.453,1.012,1.011v0.543h3.069v-0.543c0-0.558,0.454-1.011,1.011-1.011s1.011,0.453,1.011,1.011v0.543h0.776
       c1.844,0,3.344,1.5,3.344,3.344v8.238c0,0.559-0.454,1.012-1.012,1.012c-0.557,0-1.01-0.453-1.01-1.012v-8.238
       c0-0.729-0.593-1.322-1.322-1.322h-0.776v0.545c0,0.557-0.454,1.01-1.011,1.01s-1.011-0.453-1.011-1.01v-0.545h-3.069v0.545
       c0,0.557-0.454,1.01-1.012,1.01c-0.557,0-1.01-0.453-1.01-1.01v-0.545h-3.031v0.545c0,0.557-0.454,1.01-1.012,1.01
       c-0.557,0-1.01-0.453-1.01-1.01v-0.545h-0.738c-0.729,0-1.322,0.594-1.322,1.322v12.125c0,0.729,0.594,1.322,1.322,1.322
       h13.68c0.729,0,1.322-0.594,1.322-1.322c0-0.557,0.453-1.01,1.01-1.01c0.558,0,1.012,0.453,1.012,1.01
       C253.188,561.426,251.688,562.926,249.844,562.926z M236.164,544.58c-1.586,0-2.877,1.291-2.877,2.877v12.125
       c0,1.586,1.291,2.877,2.877,2.877h13.68c1.586,0,2.877-1.291,2.877-2.877c0-0.299-0.244-0.543-0.545-0.543
       c-0.3,0-0.543,0.244-0.543,0.543c0,0.986-0.803,1.789-1.789,1.789h-13.68c-0.986,0-1.789-0.803-1.789-1.789v-12.125
       c0-0.986,0.803-1.789,1.789-1.789h1.205v1.012c0,0.3,0.243,0.543,0.543,0.543c0.301,0,0.545-0.243,0.545-0.543v-1.012h3.965
       v1.012c0,0.3,0.243,0.543,0.543,0.543c0.301,0,0.545-0.243,0.545-0.543v-1.012h4.003v1.012c0,0.3,0.243,0.543,0.544,0.543
       c0.3,0,0.544-0.243,0.544-0.543v-1.012h1.243c0.986,0,1.789,0.803,1.789,1.789v8.238c0,0.301,0.243,0.545,0.543,0.545
       c0.301,0,0.545-0.244,0.545-0.545v-8.238c0-1.586-1.291-2.877-2.877-2.877h-1.243v-1.01c0-0.301-0.244-0.545-0.544-0.545
       c-0.301,0-0.544,0.244-0.544,0.545v1.01h-4.003v-1.01c0-0.301-0.244-0.545-0.545-0.545c-0.3,0-0.543,0.244-0.543,0.545v1.01
       h-3.965v-1.01c0-0.301-0.244-0.545-0.545-0.545c-0.3,0-0.543,0.244-0.543,0.545v1.01H236.164z" />
                                </g>
                                <g>
                                    <path fill="#707070" d="M246.453,550.93l-3.832,5.352c-0.104,0.121-0.235,0.146-0.306,0.152
       c-0.073,0.005-0.213-0.005-0.333-0.118l-2.48-2.382c-0.311-0.299-0.802-0.288-1.1,0.021c-0.297,0.31-0.287,0.803,0.023,1.1
       l2.482,2.383c0.372,0.355,0.867,0.552,1.379,0.552c0.044,0,0.088-0.001,0.132-0.005c0.556-0.037,1.075-0.308,1.425-0.741
       c0.01-0.013,0.018-0.023,0.026-0.035l3.847-5.374c0.249-0.348,0.17-0.834-0.18-1.084
       C247.188,550.5,246.703,550.58,246.453,550.93z" />
                                    <path fill="#FFFFFF" d="M242.287,558.223c-0.576,0-1.123-0.219-1.54-0.615l-2.483-2.385c-0.402-0.386-0.415-1.027-0.029-1.43
       c0.192-0.199,0.451-0.311,0.729-0.311c0.263,0,0.512,0.101,0.701,0.283l2.479,2.381c0.047,0.045,0.103,0.055,0.14,0.055
       c0.058-0.004,0.115-0.018,0.161-0.072l3.819-5.336c0.189-0.264,0.496-0.422,0.822-0.422c0.211,0,0.414,0.065,0.587,0.189
       c0.452,0.324,0.558,0.956,0.233,1.41l-3.877,5.414c-0.395,0.489-0.975,0.791-1.595,0.832
       C242.386,558.221,242.337,558.223,242.287,558.223z M238.963,553.949c-0.148,0-0.289,0.06-0.393,0.168
       c-0.207,0.216-0.2,0.561,0.017,0.769l2.482,2.384c0.356,0.34,0.841,0.518,1.333,0.482c0.492-0.033,0.951-0.271,1.26-0.655
       l0.02-0.026l3.846-5.371c0.174-0.244,0.117-0.585-0.126-0.76c-0.236-0.168-0.591-0.109-0.759,0.125l-3.832,5.353
       c-0.172,0.202-0.374,0.242-0.48,0.249c-0.012,0-0.028,0.002-0.047,0.002c-0.172,0-0.336-0.065-0.461-0.183l-2.482-2.384
       C239.238,554.004,239.104,553.949,238.963,553.949z" />
                                </g>
                            </g>
                        </g>
                    </g>
                    <g>
                        <path *ngIf="contrat != undefined" attr.fill="{{contrat.design.couleurPrincipale}}" d="M61.906,382.758l12.828-11.521c0.653-0.59,1.306-1.26,1.959-2.006c0.653-0.746,0.98-1.616,0.98-2.611
    c0-1.12-0.405-1.998-1.213-2.637c-0.809-0.637-1.757-0.955-2.846-0.955c-1.306,0-2.325,0.404-3.055,1.213
    c-0.731,0.809-1.143,1.803-1.236,2.984l-6.997-0.513c0.093-1.71,0.451-3.195,1.073-4.454c0.622-1.26,1.446-2.31,2.472-3.149
    c1.026-0.839,2.231-1.47,3.615-1.89c1.383-0.419,2.884-0.629,4.501-0.629c1.493,0,2.892,0.21,4.198,0.629
    c1.306,0.42,2.44,1.043,3.405,1.867c0.963,0.824,1.718,1.857,2.262,3.102c0.544,1.244,0.816,2.689,0.816,4.338
    c0,1.058-0.109,2.014-0.326,2.869c-0.218,0.855-0.521,1.633-0.91,2.332c-0.389,0.699-0.848,1.346-1.376,1.936
    c-0.529,0.592-1.104,1.166-1.726,1.726l-10.029,8.63h14.647v6.157H61.906V382.758z" />
                        <path *ngIf="contrat != undefined" attr.fill="{{contrat.design.couleurPrincipale}}" d="M115.268,378.373c-0.374,0.094-0.747,0.172-1.12,0.233c-0.373,0.063-0.762,0.093-1.166,0.093
    c-1.555,0-2.97-0.279-4.245-0.839c-1.275-0.56-2.364-1.321-3.265-2.286c-0.902-0.963-1.603-2.105-2.099-3.428
    c-0.498-1.322-0.747-2.729-0.747-4.223c0-1.709,0.318-3.256,0.956-4.641c0.637-1.383,1.508-2.572,2.612-3.568
    c1.104-0.994,2.402-1.765,3.895-2.309s3.109-0.816,4.851-0.816c1.71,0,3.319,0.272,4.828,0.816
    c1.508,0.544,2.814,1.314,3.918,2.309c1.104,0.996,1.975,2.186,2.612,3.568c0.637,1.385,0.956,2.932,0.956,4.641
    c0,1.184-0.109,2.264-0.326,3.242c-0.218,0.98-0.506,1.906-0.863,2.775c-0.358,0.871-0.778,1.703-1.259,2.496
    c-0.482,0.793-0.988,1.609-1.516,2.449l-7.23,11.288h-8.536L115.268,378.373z M109.624,368.205c0,1.492,0.49,2.729,1.469,3.708
    s2.262,1.47,3.848,1.47s2.869-0.49,3.848-1.47c0.98-0.979,1.469-2.216,1.469-3.708c0-1.493-0.489-2.729-1.469-3.709
    c-0.979-0.979-2.262-1.469-3.848-1.469s-2.869,0.489-3.848,1.469S109.624,366.712,109.624,368.205z" />
                        <path *ngIf="contrat != undefined" attr.fill="{{contrat.design.couleurPrincipale}}" d="M130.94,382.758l12.828-11.521c0.653-0.59,1.306-1.26,1.959-2.006c0.653-0.746,0.979-1.616,0.979-2.611
    c0-1.12-0.404-1.998-1.213-2.637c-0.809-0.637-1.757-0.955-2.845-0.955c-1.306,0-2.325,0.404-3.055,1.213
    c-0.731,0.809-1.143,1.803-1.236,2.984l-6.997-0.513c0.093-1.71,0.451-3.195,1.073-4.454c0.622-1.26,1.446-2.31,2.472-3.149
    c1.026-0.839,2.231-1.47,3.615-1.89c1.383-0.419,2.884-0.629,4.501-0.629c1.493,0,2.892,0.21,4.198,0.629
    c1.306,0.42,2.441,1.043,3.405,1.867s1.718,1.857,2.263,3.102c0.544,1.244,0.816,2.689,0.816,4.338
    c0,1.058-0.109,2.014-0.327,2.869s-0.521,1.633-0.909,2.332c-0.39,0.699-0.848,1.346-1.376,1.936
    c-0.529,0.592-1.104,1.166-1.726,1.726l-10.029,8.63h14.647v6.157H130.94V382.758z" />
                        <path *ngIf="contrat != undefined" attr.fill="{{contrat.design.couleurPrincipale}}" d="M166.345,370.164h2.286c0.652,0,1.289-0.047,1.912-0.141c0.621-0.093,1.189-0.271,1.702-0.536
    c0.513-0.264,0.925-0.638,1.236-1.12c0.311-0.48,0.466-1.111,0.466-1.889c0-0.994-0.396-1.819-1.189-2.473
    c-0.793-0.652-1.765-0.979-2.914-0.979c-1.09,0-1.991,0.295-2.706,0.886c-0.716,0.591-1.183,1.322-1.399,2.192l-7.697-1.586
    c0.374-1.43,0.934-2.65,1.68-3.662c0.746-1.01,1.633-1.826,2.659-2.449c1.026-0.621,2.169-1.08,3.429-1.375
    c1.259-0.295,2.588-0.443,3.988-0.443c1.461,0,2.868,0.18,4.221,0.536c1.354,0.358,2.543,0.918,3.568,1.679
    c1.026,0.764,1.843,1.719,2.449,2.869s0.91,2.52,0.91,4.105c0,1.835-0.482,3.404-1.447,4.711
    c-0.963,1.307-2.363,2.146-4.197,2.52v0.139c0.994,0.125,1.873,0.42,2.635,0.887s1.407,1.043,1.937,1.727
    c0.528,0.684,0.933,1.461,1.212,2.332c0.28,0.871,0.42,1.789,0.42,2.752c0,1.648-0.318,3.094-0.955,4.338
    c-0.639,1.244-1.493,2.278-2.566,3.102c-1.072,0.824-2.332,1.439-3.778,1.843s-2.978,0.606-4.595,0.606
    c-2.954,0-5.496-0.684-7.626-2.053c-2.131-1.367-3.538-3.529-4.222-6.483l7.37-1.726c0.249,1.182,0.754,2.115,1.517,2.799
    c0.762,0.684,1.889,1.025,3.381,1.025c1.523,0,2.65-0.396,3.383-1.189c0.729-0.793,1.096-1.811,1.096-3.055
    c0-0.934-0.195-1.656-0.583-2.17c-0.39-0.513-0.895-0.893-1.517-1.143c-0.622-0.248-1.322-0.396-2.099-0.443
    c-0.778-0.046-1.556-0.069-2.332-0.069h-1.633V370.164z" />
                        <path *ngIf="contrat != undefined" attr.fill="{{contrat.design.couleurPrincipale}}" d="M200.554,369.016h4.198v12.931l4.646-5.374h5.149l-5.401,6.13l5.541,7.473h-5.289l-4.591-6.885h-0.056
    v6.885h-4.198V369.016z" />
                        <path  *ngIf="contrat != undefined" attr.fill="{{contrat.design.couleurPrincipale}}" d="M216.115,376.572h4.029v1.848h0.057c0.131-0.262,0.316-0.521,0.56-0.783
    c0.242-0.262,0.532-0.494,0.868-0.7c0.336-0.205,0.718-0.373,1.146-0.503c0.43-0.131,0.896-0.197,1.4-0.197
    c0.951,0,1.795,0.197,2.532,0.588c0.737,0.393,1.292,1.008,1.665,1.848c0.485-0.877,1.073-1.502,1.764-1.875
    c0.689-0.373,1.539-0.561,2.547-0.561c0.914,0,1.684,0.154,2.309,0.463c0.625,0.307,1.119,0.727,1.483,1.259
    s0.625,1.157,0.784,1.876c0.158,0.718,0.237,1.488,0.237,2.309v8.032h-4.198v-7.921c0-0.634-0.135-1.18-0.406-1.637
    c-0.27-0.457-0.75-0.686-1.44-0.686c-0.485,0-0.892,0.079-1.218,0.237c-0.326,0.159-0.588,0.378-0.783,0.657
    c-0.196,0.281-0.336,0.607-0.42,0.98s-0.126,0.766-0.126,1.176v7.192h-4.198v-7.192c0-0.242-0.01-0.541-0.028-0.896
    c-0.019-0.354-0.083-0.689-0.196-1.008c-0.111-0.316-0.293-0.587-0.545-0.811c-0.252-0.225-0.621-0.336-1.105-0.336
    c-0.541,0-0.98,0.098-1.315,0.293c-0.336,0.196-0.593,0.458-0.77,0.784c-0.178,0.327-0.294,0.695-0.351,1.105
    c-0.056,0.411-0.084,0.84-0.084,1.288v6.772h-4.197V376.572z" />
                    </g>
                    <g>
                        <path fill="#FFD500" d="M90.259,297.402c0,2.431-1.972,4.401-4.403,4.401c-2.43,0-4.401-1.971-4.401-4.401
    c0-2.432,1.971-4.401,4.401-4.401C88.287,293.001,90.259,294.971,90.259,297.402z" />
                        <path fill="#B9ACAC" d="M210.043,287.93v-1.186c0-2.188-1.772-3.961-3.96-3.961c-1.245,0-2.355,0.574-3.082,1.473
    c-0.283,0.349-0.852,0.135-0.852-0.316l0.002-0.504c0-3.604-2.921-6.523-6.523-6.523c-3.604,0-6.526,2.922-6.524,6.526
    l0.003,3.386c0.002,3.292-2.667,5.962-5.959,5.962h-8.334v2.986l3.955-0.03c3.013-0.022,5.467,2.413,5.467,5.426v0.389
    c0,2.158,1.766,4.044,3.923,4.062c1.147,0.012,2.18-0.465,2.907-1.234c0.299-0.315,0.827-0.105,0.827,0.328v0.001
    c0,3.609,2.927,6.536,6.536,6.536c3.603,0,6.524-2.914,6.536-6.515l0.008-1.517c0.004-0.832,0.682-1.505,1.513-1.505h3.559
    c3.651,0,6.612-2.961,6.612-6.613c0-3.468-2.67-6.313-6.067-6.59C210.282,288.485,210.043,288.236,210.043,287.93z" />
                        <path fill="#9E9797" d="M211.368,288.628c0.763,1.078,1.217,2.39,1.217,3.811c0,3.653-2.961,6.613-6.613,6.613h-3.558
    c-0.833,0-1.509,0.673-1.514,1.505l-0.008,1.517c-0.012,3.602-2.934,6.515-6.536,6.515c-0.439,0-0.868-0.044-1.282-0.126
    c1.182,1.686,3.139,2.789,5.354,2.789c3.602,0,6.523-2.914,6.536-6.514l0.008-1.518c0.003-0.832,0.681-1.505,1.513-1.505h3.559
    c3.651,0,6.612-2.961,6.612-6.612C216.656,291.904,214.385,289.242,211.368,288.628z" />
                        <path fill="#D5F3F7" d="M171.337,282.56l-2.693-17.445c-0.521-3.372-3.422-5.861-6.834-5.861h-38.893
    c-0.682,0-1.333,0.284-1.8,0.782l-16.093,22.524H171.337z" />
                        <path fill="#A7E8F1" d="M167.739,259.253h-9.148l3.526,23.306h9.22L167.739,259.253z" />
                        <path fill="#F47000" d="M173.353,282.248l-2.693-17.444c-0.679-4.398-4.399-7.589-8.85-7.589h-38.893
    c-1.243,0-2.441,0.521-3.291,1.428c-0.061,0.064-0.117,0.134-0.169,0.207l-16.094,22.524c-0.443,0.622-0.503,1.439-0.154,2.12
    c0.35,0.679,1.05,1.106,1.815,1.106h66.312c0.596,0,1.16-0.261,1.548-0.712C173.273,283.435,173.443,282.837,173.353,282.248z
     M122.667,261.375c0.071-0.052,0.159-0.082,0.25-0.082h10.237v19.227h-24.165L122.667,261.375z M137.233,280.521v-19.227h24.576
    c2.423,0,4.448,1.737,4.818,4.132l2.33,15.095H137.233L137.233,280.521z" />
                        <path fill="#F48607" d="M177.984,279.983H105.88l-15.729,3.782c-3.26,0.552-5.645,3.375-5.645,6.681v15.09
    c0,1.562,1.267,2.829,2.83,2.829h90.653c1.562,0,2.829-1.267,2.829-2.829v-22.72C180.818,281.252,179.55,279.983,177.984,279.983
    z" />
                        <path fill="#F47000" d="M177.984,279.983h-22.629v15.948c0,1.565-1.269,2.833-2.833,2.833H84.506v6.772
    c0,1.562,1.268,2.829,2.831,2.829h90.653c1.562,0,2.829-1.267,2.829-2.829v-22.72
    C180.818,281.252,179.55,279.983,177.984,279.983z" />
                        <path fill="#5B5555" d="M116.609,308.366c0,5.982-4.85,10.83-10.832,10.83s-10.831-4.848-10.831-10.83
    c0-5.981,4.849-10.831,10.831-10.831C111.759,297.535,116.609,302.385,116.609,308.366z" />
                        <path fill="#444444" d="M109.885,298.344c1.037,1.661,1.637,3.62,1.637,5.724c0,5.98-4.849,10.83-10.831,10.83
    c-1.455,0-2.841-0.288-4.108-0.809c1.913,3.066,5.314,5.107,9.194,5.107c5.982,0,10.831-4.848,10.831-10.831
    C116.608,303.839,113.83,299.962,109.885,298.344z" />
                        <path fill="#EFE2DD" d="M108.811,308.366c0,1.675-1.358,3.032-3.033,3.032c-1.674,0-3.031-1.357-3.031-3.032
    s1.357-3.033,3.031-3.033C107.453,305.333,108.811,306.691,108.811,308.366z" />
                        <path fill="#5B5555" d="M174.813,308.366c0,5.982-4.85,10.83-10.831,10.83s-10.831-4.848-10.831-10.83
    c0-5.981,4.85-10.831,10.831-10.831S174.813,302.385,174.813,308.366z" />
                        <path fill="#444444" d="M168.09,298.344c1.035,1.661,1.637,3.62,1.637,5.724c0,5.98-4.85,10.83-10.831,10.83
    c-1.454,0-2.841-0.288-4.108-0.809c1.913,3.066,5.314,5.107,9.194,5.107c5.981,0,10.83-4.848,10.83-10.831
    C174.813,303.839,172.033,299.962,168.09,298.344z" />
                        <path fill="#EFE2DD" d="M167.016,308.366c0,1.675-1.359,3.032-3.033,3.032c-1.675,0-3.033-1.357-3.033-3.032
    s1.358-3.033,3.033-3.033C165.656,305.333,167.016,306.691,167.016,308.366z" />
                        <path fill="#F47000" d="M129.384,291.37h-4.756c-1.126,0-2.041-0.913-2.041-2.04s0.913-2.04,2.041-2.04h4.756
    c1.126,0,2.04,0.913,2.04,2.04S130.511,291.37,129.384,291.37z" />
                        <path fill="#B9ACAC" d="M210.663,272.917c0,1.601-1.299,2.899-2.899,2.899c-1.602,0-2.898-1.298-2.898-2.899
    s1.297-2.899,2.898-2.899C209.364,270.019,210.663,271.316,210.663,272.917z" />
                    </g>
                    <rect x="17.187" y="114.622" *ngIf="contrat != undefined" attr.fill="{{contrat.design.couleurPrincipale}}" width="262.382" height="0.633" />
                    <rect x="16.985" y="525.871" fill="#707070" width="262.382" height="0.634" />
                </g>
            </g>
            <g>
            </g>
        </svg>

    </div>
</div>

<div class="row mt-5 mb-4">
    <div class="col-lg-3 d-flex flex-row justify-content-center">
        <button type="button"  class="btn  btn-primary w-100 button2" routerLink="/montri/contractsettings/recyclinginstructions">Précédent</button>
    </div>
    <div class="col-lg-3 mt-3 mt-lg-0 d-flex flex-row justify-content-center">
        <button type="button" *ngIf="web!=undefined" class="btn  btn-primary w-100 button4"  (click)="saveData()" ngClass="{{ (web.titreCover!='' && web.soustitreCover!='' && Designe.couleurPrincipale!='') ? '' : 'opacity05'}}">Suivant</button>
    </div>
</div>



<div class="mt-4 mb-4 d-flex flex-row justify-content-center">
    <button type="button" style="max-width: 15%;margin-top: 3%;position: absolute;margin-left: 60%;" 
    [ngStyle]="{'cursor' :  ((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification') ? 'not-allowed' : null }"
     (click)="(((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')) ? null : addDocument()" 
     class="btn btn-primary w-100 btn_add_doc" >Ajouter un document</button>
</div>
<div class="container-fluid mt-5 pl-5" *ngIf="contrat != undefined && contrat.contrat.idContrat!='BOTEST'">
    <div class="row">
    </div>
   <div class="row mt-4">
        <div class="col-12">
            <div class="row">
                <div class="col-12 d-flex flex-column justify-content-center align-items-lg-start align-items-center">
                    <p class="pb-0 mb-0  h2">Mes documents :</p>
                    <p class="pb-0 mb-0 mt-0 p2">Chargez ici les documents que vous souhaitez afficher sur votre page d'acceuil </p>
                </div>
            </div>


            <div class="card mt-5 shadow-sm p-3 mb-5 bg-white" *ngFor="let doc of doc_list; index as i" style="width: 90%;border-radius: 10px;">
                <div class="card-body" >
                  <div class="row">
                      
                    <div class="inner-addon left-addon mr-4">
                        <i class="fa fa-font"></i>
                        <input disabled style="border-radius: 10px; border: 0px; background-color: #f4f3f3;color: black;height: 2.5rem;font-family:Arial, FontAwesome;" type="text" class="form-control" placeholder="{{doc.nom}}" />
                    </div>
    
                    <div class="inner-addon left-addon mr-4">
                   
                        <img class="special" [src]="getIcon(i)" alt="">
                        <input disabled style="border-radius: 10px; border: 0px; background-color: #f4f3f3;color: black;height: 2.5rem;font-family:Arial, FontAwesome;" type="text" class="form-control" placeholder="{{doc.type}}" />
                    </div> 
                    <div class="inner-addon-special right-addon mr-4">
                        <i class="fa fa-times" (click)="deleteDocument(i)" style="cursor: pointer;"></i>
                        <input disabled style="border-radius: 10px; border: 0px; background-color: grey;color: black;height: 2.5rem;font-family:Arial, FontAwesome;" type="text" class="form-control fileName" placeholder="{{doc.nom+'.'+doc.extension}}" />
                    </div>   
                    <div class="mr-5 ml-4">
                        <p style="margin-top: .5rem;background-color: #43df6044 ;padding: 1px 25px 1px 25px;border-radius: 10px;color: #43DF5F;">Publié</p>
                    </div>  
                    
                    
                    <div class="dropdown mt-2">
                      <a  type="text" id="dropdownMenuButton" style="opacity: .5;" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Paramètres
                        <i class="fa fa-cog"></i>
                      </a>
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item" (click)="updateDocument(i)">Modifier</a>
                        <a class="dropdown-item"  (click)="deleteDocument(i)">Supprimer</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div class="card mt-5 shadow-sm p-3 mb-5 bg-white " style="width: 88%;border-radius: 10px;">
                <div class="card-body" >
                  <div class="row">
                    <div class="form-group has-feedback my-auto">
                        <input type="text" class="form-control" placeholder="Reglement" />
                        <i class="glyphicon glyphicon-user form-control-feedback"></i>
                    </div>                    
                  </div>
                </div>
              </div> -->
        </div>
    </div>
</div>










import { AuthentificationService } from './../../../../services/authentification/authentification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Alert } from './../../../../models/alert/alert';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { AlertService } from './../../../../services/alert/alert.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LodingBarService } from './../../../../services/lodingbar/loding-bar-service.service';
import { UserService } from './../../../../services/user/user.service';
import { FormBuilder } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import * as firebase from "firebase";
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.scss']
})

export class ResetpasswordComponent implements OnInit {

    PasswordForm;
    oldpasswordError : boolean = false
    newpasswordError : boolean = false
    error : boolean = false

    // The user management actoin to be completed
    mode: string;

    // Just a code Firebase uses to prove that
    // this is a real password reset.
    actionCode: string;
    private  user_email;
    actionCodeChecked: boolean;

    ngUnsubscribe: Subject<any> = new Subject<any>();

    constructor(
        private formBuilder: FormBuilder,
        private userservice: UserService,
        private lodingBarService: LodingBarService,
        private alertservices: AlertService,
        public afAuth: AngularFireAuth,
        private afs : AngularFirestore,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private authservice : AuthentificationService
    ) { }

    ngOnInit(): void {
        this.PasswordForm = this.formBuilder.group({
            old_password: "",
            new_password: ""
        });

        this.activatedRoute.queryParams
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(params => {
        // if we didn't receive any parameters,
        // we can't do anything
        if (!params) this.router.navigate(['/home']);

        this.mode = params['mode'];
        this.actionCode = params['oobCode'];

        switch (params['mode']) {
            case 'resetPassword': {
            // Verify the password reset code is valid.
            this.authservice.getAuth().verifyPasswordResetCode(this.actionCode).then(email => {
                this.user_email = email
                this.actionCodeChecked = true;
            }).catch(e => {
                // Invalid or expired action code. Ask user to try to reset the password
                // again.
                var alert: Alert = {
                    type: "danger",
                    message: "❗ Le lien utilisé est invalide ou déjà utilisé",
                };
                this.alertservices.setAlert(alert);
                this.router.navigate(['/user/signin']);
            });
            } break
            case 'recoverEmail': {

            } break
            case 'verifyEmail': {

            } break
            default: {
            console.log('query parameters are missing');
            this.router.navigate(['/user/signin']);
            }
        }
    })

    }

    onSubmit(userData){
        var  self = this;
        self.lodingBarService.setStatus(true);
        this.afAuth.confirmPasswordReset(
            this.actionCode,
            userData['new_password']
          ).then(resp => {
            var alert: Alert = {
                type: "success",
                message: " ✔️ Votre compte a été mis à jour avec succès",
            };
            self.alertservices.setAlert(alert);
            self.lodingBarService.setStatus(false);
            this.authservice.signIn(this.user_email, userData["new_password"]);
        }).catch(e => {
              // Error occurred during confirmation. The code might have
              // expired or the password is too weak. alert(e);
            });

    }

}

import { AlertService } from './../../../../services/alert/alert.service';
import { Alert } from './../../../../models/alert/alert';
import { Router } from '@angular/router';
import { Route } from '@angular/compiler/src/core';
import { LodingBarService } from './../../../../services/lodingbar/loding-bar-service.service';
import { LoadingBarModule, LoadingBarService } from '@ngx-loading-bar/core';
import { Contrat } from './../../../../models/contrat/contrat';
import { FluxService } from './../../../../services/flux/flux.service';
import { Flux, WasteFlux } from './../../../../models/flux/flux';
import { AngularFireStorage } from '@angular/fire/storage';
import { UserBo } from './../../../../models/user/user';
import { UserService } from './../../../../services/user/user.service';
import { MenuService } from './../../../../services/menu/menu.service';
import { ContratService } from './../../../../services/contrat/contrat.service';
import { Component, OnInit } from '@angular/core';
import { TabmessageService } from 'src/app/services/tabmessage/tabmessage.service';
import * as XLSX from 'xlsx';
import * as Papa from 'papaparse';
import { AngularFirestore } from '@angular/fire/firestore';
import * as ngl from 'node-geometry-library'
import { firestore } from 'firebase';
import * as firebase from 'firebase';
import { ToastAlertsComponent } from 'src/app/modules/main/components/toast-alerts/toast-alerts.component';

@Component({
    selector: 'app-coordinate',
    templateUrl: './coordinate.component.html',
    styleUrls: ['./coordinate.component.scss']
})

export class CoordinateComponent implements OnInit {

    user: UserBo
    FluxTemplate
    contrat: Contrat
    localfile : string = ''
    userFlux: Flux[] = []
    dechetterie = []
    data;
    constructor(
        private sectionservice: TabmessageService,
        private contartservice: ContratService,
        private menuservice: MenuService,
        private userservice: UserService,
        private afStorage: AngularFireStorage,
        private afs: AngularFirestore,
        private fluxService: FluxService,
        private alertservices: AlertService,
        private loadingBarService: LodingBarService,
        private routeur: Router,
        private toastr: ToastAlertsComponent
    ) { }

    ngOnInit(): void {

        window.scroll(0, 0)
        this.sectionservice.setSection('cord');
        const ref2 = this.afStorage.ref('TemplatesBO/Carte-point-de-collecte-type.xlsx');
        ref2.getDownloadURL().subscribe((href) => {
            this.FluxTemplate = href
        })
        this.contartservice.contrat_obs.subscribe((contrat) => {
            this.contrat = contrat
            this.userservice.user_obs.subscribe((user) => {
                this.user = user
            })
            this.contartservice.carte_obs.subscribe((data)=>{
                if(data.flux.length > 0){
                    this.data = data.flux
                }else{
                    this.data = this.contrat.carte
                }
            })
        })
    }

    deleteFlux() {}

    sendFlux(file: File) {
        this.loadingBarService.setStatus(true)
        this.contrat.localfiles.localCarte = file.name
        const reader: FileReader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = (e: any) => {
            /* create workbook */
            const binarystr: string = e.target.result;
            const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });

            /* selected the first sheet */
            const wsname: string = wb.SheetNames[1];
            const ws: XLSX.WorkSheet = wb.Sheets[wsname];

            var range = XLSX.utils.decode_range(ws['!ref']) //<-- start "select"
            range.s.c = 0 // <-- start column
            range.e.c = 23 // <-- end column
            range.s.r = 10 // <-- start row
            ws['!ref'] = XLSX.utils.encode_range(range) //<-- end "select"

            /* save data */
            var xlData = XLSX.utils.sheet_to_csv(ws, { FS: ";" })
            var data = { guideDutri: [] }
            var index = 0
            Papa.parse(xlData, {
                header: false,
                skipEmptyLines: false,
                complete: (result, file) => {
                    try{
                    const parsedCsv = result.data
                    var data: any = {
                        flux: [
                            {
                                nom: 'Textile',
                                image: '045-flux-textile.png',
                                couleur: '',
                                defaut: false,
                                active: false,
                                description: '',
                                media: 'Image Map Vetements.png',
                                pavs: []
                            },
                            {
                                nom: 'Déchetteries',
                                image: '047-flux-dechetterie.png',
                                couleur: '',
                                defaut: false,
                                active: false,
                                description: '',
                                media: 'Image Map Dechetteries.png',
                                pavs: []
                            },
                            {
                                nom: 'Déchetteries mobiles',
                                image: '048-flux-dechetterie-mobile.png',
                                couleur: '',
                                defaut: false,
                                active: false,
                                description: '',
                                media: 'Image Map Dechetteriesmobiles.png',
                                pavs: []
                            },
                            {
                                nom: 'Parcs à sapins',
                                image: '074-flux-sapins.png',
                                couleur: '',
                                defaut: false,
                                active: false,
                                description: '',
                                media: 'Image Map Sapins.png',
                                pavs: []
                            },
                            {
                                nom: 'Sites de compostage',
                                image: '050-flux-sites-compostage.png',
                                couleur: '',
                                defaut: false,
                                active: false,
                                description: '',
                                media: 'Image Map Compost.png',
                                pavs: []
                            },
                            {
                                nom: 'Sites de réemploi',
                                image: '049-flux-reemploi.png',
                                couleur: '',
                                defaut: false,
                                active: false,
                                description: 'Image Map Ressourcerie.png',
                                media: '',
                                pavs: []
                            },
                            {
                                nom: 'Emballages en verre',
                                image: '043-flux-verre.png',
                                couleur: '',
                                defaut: false,
                                active: false,
                                description: 'Image Map Verre.png',
                                media: '',
                                pavs: []
                            },
                            {
                                nom: 'Emballages recyclables',
                                image: '042-flux-recyclables.png',
                                couleur: '',
                                defaut: false,
                                active: false,
                                description: '',
                                media: 'Image Map Recyclables.png',
                                pavs: []
                            },
                            {
                                nom: 'Ordures ménagères',
                                image: '041-flux-omr.png',
                                couleur: '',
                                defaut: false,
                                active: false,
                                description: '',
                                media: 'Image Map Omr.png',
                                pavs: []
                            },
                            {
                                nom: 'Papiers',
                                image: '055-flux-papier.png',
                                couleur: '',
                                defaut: false,
                                active: false,
                                description: '',
                                media: 'Image Map Papier.png',
                                pavs: []
                            },
                            {
                                nom: 'Bornes Montri',
                                image: '044-flux-montri.png',
                                couleur: '',
                                defaut: false,
                                active: false,
                                description: 'A nice, on trie solidaire ! En scannant vos emballages sur les bornes Montri, vous les transformez en repas pour les Restos du Coeur !',
                                media: 'montri-comment-ca-marche.png',
                                pavs: []
                            },
                            {
                                nom: 'Piles',
                                image: '052-flux-piles.png',
                                couleur: '',
                                defaut: false,
                                active: false,
                                description: '',
                                media: 'Image Map Piles.png',
                                pavs: []
                            },
                            {
                                nom: 'Ampoules',
                                image: '051-flux-ampoules.png',
                                couleur: '',
                                defaut: false,
                                active: false,
                                description: '',
                                media: 'Image Map Ampoules.png',
                                pavs: []
                            },
                            {
                                nom: 'Restaurants 0 déchet',
                                image: '076-flux-restaurants.png',
                                pavs: [],
                                couleur: '',
                                defaut: false,
                                active: false,
                                description: '',
                                media: 'Image Map Restaurants.png'
                            },
                            {
                                nom: 'Ressourceries',
                                image: '075-flux-ressourceries.png',
                                pavs: [],
                                couleur: '',
                                defaut: false,
                                active: false,
                                description: '',
                                media: 'Image Map Ressourcerie.png'
                            },
                            {
                                nom: 'Epiceries vrac & bio',
                                image: '077-flux-epiceries.png',
                                pavs: [],
                                couleur: '',
                                defaut: false,
                                active: false,
                                description: '',
                                media: 'Image Map Epiceries.png'
                            },
                            {
                                nom: 'Boutiques solidaires',
                                image: '078-flux-boutiques.png',
                                pavs: [],
                                couleur: '',
                                defaut: false,
                                active: false,
                                description: '',
                                media: 'Image Map Boutiques.png'
                            },
                            {
                                nom: 'Dons vestimentaires',
                                image: '080-flux-vetements.png',
                                pavs: [],
                                couleur: '',
                                defaut: false,
                                active: false,
                                description: '',
                                media: 'Image Map Vetements.png'
                            },
                            {
                                nom: 'Fontaines à eau',
                                image: '079-flux-fontaines.png',
                                pavs: [],
                                couleur: '',
                                defaut: false,
                                active: false,
                                description: '',
                                media: 'Image Map Fontaines.png'
                            },
                            {
                                nom: 'Commerces partenaires',
                                image: '081-flux-partenaires.png',
                                pavs: [],
                                couleur: '',
                                defaut: false,
                                active: false,
                                description: '',
                                media: 'Image Map Partenaires.png'
                            },
                            {
                                nom: 'Biodéchets',
                                image: '082-flux-alimentaire.png',
                                pavs: [],
                                couleur: '',
                                defaut: false,
                                active: false,
                                description: '',
                                media: 'Image Map Biodechets.png'
                            },
                            {
                                nom: 'Déchets verts',
                                image: '083-flux-vegetaux.png',
                                pavs: [],
                                couleur: '',
                                defaut: false,
                                active: false,
                                description: '',
                                media: 'Image Map Vegetaux.png'
                            }
                        ]
                    }

                    for (var i in parsedCsv) {
                        if (parsedCsv[i][0] == "") {
                            break
                        }
                        if (parsedCsv[i][16] == "Oui") {
                            parsedCsv[i][16] = true
                        }

                        if (parsedCsv[i][0] == "Oui") {
                            parsedCsv[i][0] = true
                        } else {
                            //parsedCsv[i][0] = ""
                            continue
                        }

                        let pav = {
                            aPublier: parsedCsv[i][0],
                            id: parsedCsv[i][1],
                            nom: parsedCsv[i][2],
                            lat: parseFloat(parsedCsv[i][5]),
                            lng: parseFloat(parsedCsv[i][6]),
                            adresseVoie: parsedCsv[i][7],
                            adresseComplement: parsedCsv[i][8],
                            cp: parsedCsv[i][9],
                            ville: parsedCsv[i][10],
                            mail: parsedCsv[i][11],
                            telephone: parsedCsv[i][12],
                            site: parsedCsv[i][13],
                            url: parsedCsv[i][14],
                            informations: parsedCsv[i][15],
                            ouvert24h: parsedCsv[i][16],
                            lundi: parsedCsv[i][17],
                            mardi: parsedCsv[i][18],
                            mercredi: parsedCsv[i][19],
                            jeudi: parsedCsv[i][20],
                            vendredi: parsedCsv[i][21],
                            samedi: parsedCsv[i][22],
                            dimanche: parsedCsv[i][23]
                        }

                        if(parsedCsv[i][0]=="" || isNaN(pav.lat) || isNaN(pav.lng)){
                            throw new Error('Something bad happened');
                        }

                        for (const [key, value] of Object.entries(pav)) {
                            if (value == "") {
                                delete pav[key]
                            }
                        }

                        // Remove aPublier entry when they are true
                        if (pav["aPublier"]) {
                            delete pav["aPublier"]
                        }

                        // Remove ouvert24h entry when they are false
                        if (!pav["ouvert24h"]) {
                            delete pav["ouvert24h"]
                        }

                        switch (parsedCsv[i][4]) {
                            case 'BT'://
                                data.flux[0].pavs.push(pav)
                                break
                            case 'DF'://
                                data.flux[1].pavs.push(pav)
                                break
                            case 'DM'://
                                data.flux[2].pavs.push(pav)
                                break
                            case 'PS'://
                                data.flux[3].pavs.push(pav)
                                break
                            case 'SC'://
                                data.flux[4].pavs.push(pav)
                                break
                            case 'SR'://
                                data.flux[5].pavs.push(pav)
                                break
                            case 'BV'://
                                data.flux[6].pavs.push(pav)
                                break
                            case 'BCS'://
                                data.flux[7].pavs.push(pav)
                                break
                            case 'BOM'://
                                data.flux[8].pavs.push(pav)
                                break
                            case 'BPP'://
                                data.flux[9].pavs.push(pav)
                                break
                            case 'BMT'://
                                data.flux[10].pavs.push(pav)
                                break
                            case 'BP'://
                                data.flux[11].pavs.push(pav)
                                break
                            case 'BA'://
                                data.flux[12].pavs.push(pav)
                                break
                            case 'RES'://
                                data.flux[13].pavs.push(pav)
                                break
                            case 'REC'://
                                data.flux[14].pavs.push(pav)
                                break
                            case 'EPI'://
                                data.flux[15].pavs.push(pav)
                                break
                            case 'ESS'://
                                data.flux[16].pavs.push(pav)
                                break
                            case 'VET'://
                                data.flux[17].pavs.push(pav)
                                break
                            case 'FAE'://
                                data.flux[18].pavs.push(pav)
                                break
                            case 'CP'://
                                data.flux[19].pavs.push(pav)
                                break
                            case 'BDA'://
                                data.flux[20].pavs.push(pav)
                            break
                            case 'BDV'://
                                data.flux[21].pavs.push(pav)
                                break
                            default:
                                break
                        }
                    }
                    this.dechetterie = data.flux[2].pavs
                    var output = {
                        flux: []
                    }

                    for (var i in data.flux) {
                        if (data.flux[i].pavs.length > 0) {
                            output.flux.push(data.flux[i])
                        }
                    }

                    this.userFlux = output.flux as Flux[]
                    this.toastr.openSuccessToast('Vos informations ont bien été enregistrées','')
                    this.loadingBarService.setStatus(false)
                    }
                    catch(e){
                        this.toastr.openFailedToast("Votre fichier semble contenir des erreurs ou des données sont manquantes. Merci de vérifier votre fichier et de réessayer.","")
                        this.loadingBarService.setStatus(false);
                    }
                }
            }).then(
                this.addDechetterie()
            );
        };
    }


    addDechetterie(){
        this.afs.collection('ciblage').doc(this.contrat.contrat.idContrat).get().subscribe((querySnap) => {
            var users = querySnap.data()
            let data = users['infos']
            for (let [id, val] of Object.entries(data)) {
                for(let [key, value] of Object.entries(val)){
                    if(key === 'coordonne'){
                        if(value != ""){
                            let userLocation = ngl.PolyUtil.decode(value)
                            this.dechetterie.forEach(obj => {
                                const latlng1 = new google.maps.LatLng(obj.lat, obj.lng)
                                const latlng2 = new google.maps.LatLng(userLocation[0].lat, userLocation[0].lng)
                                let meter = google.maps.geometry.spherical.computeDistanceBetween(latlng1 ,latlng2)
                                if(meter < 1000){
                                    if(!val['infosDechetterie']){
                                        val['infosDechetterie'] = {}
                                    }
                                    if(!val['infosDechetterie'].date){
                                        val['infosDechetterie'].date = ""
                                    }
                                    val['infosDechetterie'].date = new firebase.firestore.Timestamp(firebase.firestore.Timestamp.now().seconds, firebase.firestore.Timestamp.now().nanoseconds)
                                }     
                            }) 
                        }
                    }
                }
            }
            this.afs.collection('ciblage').doc(this.contrat.contrat.idContrat).set(users)
        })
    }

    async saveData() {
        if (this.userFlux.length == 0) {
            this.toastr.openFailedToast(`Chargement échoué. Merci de renseigner le fichier.`, "");
        }
        else {
            let carte = this.userFlux as Flux[]
            carte = this.getState(this.data, carte)
            let obj = {flux: carte}
            let ref = await this.toastr.openTransferToast(`Transfert de données en cours.`, "");
            this.contartservice.setCarte(obj)
            let status =  await this.contartservice.saveCarte('/montri/cartsettings/flux')
            if(status === "success"){
                this.contartservice.setContrat(this.contrat)
                this.contartservice.saveContrat('')
                this.toastr.clear(ref.toastId)
                this.toastr.openSuccessToast('Données enregistrées avec succès', "");
            }else{
                this.toastr.clear(ref.toastId)
                this.toastr.openErrorToast(`Chargement échoué. Veuillez verifier votre fichier et réessayer.`, "");
            }
            
        }
    }

    getState(oldData, newData){
        let idState = []
        let colorFlux = []
        oldData?.forEach(flux => {
            let pavs = flux.pavs
            if(flux.couleur != ''){
                colorFlux.push([flux.nom, flux.couleur, flux.active, flux.defaut])
            }
            pavs.forEach(pav => {
                if(pav.etat && pav.etat != 'opérationnel'){
                    idState.push([pav.id, pav.etat])
                }   
            });
        });

        newData.forEach(flux => {
            let pavs = flux.pavs
            if(colorFlux.length > 0){
                colorFlux.forEach(element => {
                    if(flux.nom === element[0]){
                        flux.couleur = element[1]
                        flux.active = element[2]
                        flux.defaut = element[3]
                    }
                });
            }
            pavs.forEach(pav => {
                idState.forEach(element => {
                    if(pav.id === element[0]){
                        pav['etat'] = element[1]
                    }
                });
            });
        });
        return newData
    }

}

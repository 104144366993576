import { element } from 'protractor';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { UserService } from './../../../../services/user/user.service';
import { UserBo } from './../../../../models/user/user';
import { MenuService } from './../../../../services/menu/menu.service';
import { ReseauxSociaux, Contact, ContratClass, Contrat } from './../../../../models/contrat/contrat';
import { ContratService } from './../../../../services/contrat/contrat.service';
import { TabmessageService } from './../../../../services/tabmessage/tabmessage.service';
import { Component, OnInit } from '@angular/core';
import { ToastAlertsComponent } from 'src/app/modules/main/components/toast-alerts/toast-alerts.component';


@Component({
    selector: 'app-informations',
    templateUrl: './informations.component.html',
    styleUrls: ['./informations.component.scss']
})
export class InformationsComponent implements OnInit {

    next_status: boolean = true;
    socialMedia: ReseauxSociaux;
    contact: Contact;
    contratInfos : ContratClass
    siteContratError : boolean = false
    cpError : boolean = false
    villeError : boolean = false
    nomVoieError : boolean = false
    nomContactError : boolean = false
    horairesSupportError : boolean = false
    mailContactError : boolean = false
    telephoneContactError : boolean = false
    prixAppelError: boolean = false
    user : UserBo
    contrat : Contrat

    selectedZipCode: any;

    zipCodes: any[];

    filteredZipCodes: any[];

    selectedZipcodes: any[] = [];

    selectedZipCodeAdvanced: any[];

    filteredBrands: any[];

    showZipCodeError = false


    constructor(
        private sectionservice: TabmessageService,
        private contartservice: ContratService,
        private menuservice: MenuService,
        private userservice : UserService,
        private  afs : AngularFirestore,
        private toastr: ToastAlertsComponent
    ) { }

    ngOnInit(): void {
        window.scroll(0,0)
        this.userservice.user_obs.subscribe((user)=>{
            this.user =  user
            if(user != null){
                window.scroll(0,0)
                this.menuservice.setSection('paramétrage')
                this.menuservice.setSubsection('territoire')
                this.sectionservice.setSection('informations');
                this.contartservice.contrat.subscribe((contrat) => {
                    this.contrat = contrat
                    this.socialMedia = { ...contrat.reseauxSociaux }
                    this.contact = { ...contrat.contact }
                    this.contratInfos = { ... contrat.contrat}
                    var  temZipCodes = []
                    for(let element of this.contrat.villesListe){
                        temZipCodes.push({"codePostal": element})
                    }
                    this.selectedZipcodes = temZipCodes
                    if ((this.contratInfos.siteContrat != "") &&
                        (this.contact.cp != "")&&
                        (this.contact.ville != "")&&
                        (this.contact.nomVoie != "")&&
                        (this.contact.nomContact != "")&&
                        (this.contact.horairesSupport!="")&&
                        (this.contact.mailContact!="")&&
                        (this.contact.telephoneContact!="")&&
                        (this.contact.prixAppel!="")){
                            this.next_status = false
                    }
                    else{
                        this.next_status = true
                    }
                })
            }

        })


    }

    toastError(){
        this.toastr.openFailedToast(`Certains champs obligatoires ne sont pas remplis.`, "");
    }

    async saveData() {

        this.contartservice.setInformations(this.contact,this.socialMedia)
        if(this.contratInfos.siteContrat == ""){
            this.siteContratError = true
            this.toastError()
        }

        else if(this.contact.cp == ""){
            this.cpError = true
            this.toastError()
        }

        else if(this.contact.ville == ""){
            this.villeError = true
            this.toastError()
        }

        else if(this.contact.nomVoie == ""){
            this.nomVoieError = true
            this.toastError()
        }

        else if(this.contact.nomContact == ""){
            this.nomContactError = true
            this.toastError()
        }

        else if(this.contact.mailContact == ""){
            this.mailContactError = true
            this.toastError()
        }

        else if(this.contact.horairesSupport == ""){
            this.horairesSupportError = true
            this.toastError()
        }

        else if(this.contact.telephoneContact == ""){
            this.telephoneContactError = true
            this.toastError()
        }

        else if(this.contact.prixAppel == ""){
            this.prixAppelError = true
            this.toastError()
        }
        else{
            let ref = await this.toastr.openTransferToast(`Transfert de données en cours.`, "");
            this.contartservice.contrat.value.villesListe = []
            for(let element of this.selectedZipcodes){
                this.contartservice.contrat.value.villesListe.push(element.codePostal)
            }
            this.contartservice.contrat.value.reseauxSociaux = {...this.socialMedia}
            this.contartservice.contrat.value.contact = {...this.contact}
            this.contartservice.contrat.value.contrat = {...this.contratInfos}
            this.contartservice.contrat.value.configstatus[1].status = true
            let status = await this.contartservice.saveContrat('/montri/contractsettings/recyclinginstructions')
            if(status === "success"){
                this.toastr.clear(ref.toastId)
                this.toastr.openSuccessToast('Données enregistrées avec succès', "");
            }else{
                this.toastr.clear(ref.toastId)
                this.toastr.openErrorToast(`Chargement échoué. Veuillez réessayer.`, "");
            }
        }

    }


    onChange() {

        if ((this.contratInfos.siteContrat != "") &&
            (this.contact.cp != "")&&
            (this.contact.ville != "")&&
            (this.contact.nomVoie != "")&&
            (this.contact.nomContact != "")&&
            (this.contact.horairesSupport!="")&&
            (this.contact.mailContact!="")&&
            (this.contact.telephoneContact!="")&&
            (this.contact.prixAppel!="")){
                this.next_status = false
        }
        else{
               this.next_status = true
        }
    }

    async filterCountry(event) {
            var temp_array = []
            var  villes = await  this.afs.collection("villes", (ref) => ref.where("codePostal", "==", event.query)).get().toPromise()
            villes.forEach(doc => {
                temp_array.push( doc.data())
            });
            this.filteredZipCodes = temp_array.slice(0,1)
            if(temp_array.length == 0){
                this.showZipCodeError = true
            }
            return null


    }
}

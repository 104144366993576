export class Invit {
   email_collaboratteur : string ;
   html : string ;
   sender_email : string ;
   reciver_email : string ;
   reciver_role : string ;
   is_accepted : boolean;
   id_contrat : string;
   nom_contrat : string
}


export class InvitID {
    email_collaboratteur : string ;
    html : string ;
    sender_email : string ;
    reciver_email : string ;
    reciver_role : string ;
    is_accepted : boolean;
    id_contrat : string;
    id : string ;
 }

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterPoints'
})
export class FilterPointsPipe implements PipeTransform {

  transform(value: any [], ...args: unknown[]): number {

    return  value.filter(point => point.status != 'traiter').length;
  }

}

<div class="row mt-5 ml-4 mr-4 mb-3">
    <div class="col-12">
        <div class="row ">
            <div class="col-xl-4">
            </div>
            <div class="col-xl-4 d-flex flex-row justify-content-center align-items-center">
                <div class="d-flex flex-column align-items-center justify-content-center  h-100" (click)="MoveLet()">
                    <div class="cercle_fleche">
                        <i class="fa fa-chevron-left"></i>
                    </div>
                </div>
                <div class="date mr-5 ml-5 d-flex flex-column justify-content-center  align-items-center h-100 bg-red"  *ngIf="timeType == 'DAY'">
                    {{date}}
                </div>
                <div class="date mr-5 ml-5 d-flex flex-column justify-content-center  align-items-center h-100 bg-red"  *ngIf="timeType == 'MONTH'">
                    {{months[this.current_date.getMonth()]}}
                </div>
                <div class="date mr-5 ml-5 d-flex flex-column justify-content-center  align-items-center h-100 bg-red"  *ngIf="timeType == 'YEAR'">
                    {{this.current_date.getFullYear()}}
                </div>
                <div class="d-flex flex-column align-items-center justify-content-center  h-100" (click)="MoveRight()">
                    <i class="fa fa-chevron-right"></i>
                </div>
            </div>
            <div class="col-xl-4 d-flex flex-row justify-content-xl-end justify-content-center mt-5 mt-xl-0">
                <div class="btn-group btn-group-toggle" data-toggle="buttons">
                    <label class="btn btn-outline-secondary active btn_select_time p2" (click)="DayStats($event)">
                        <input type="radio" name="options" id="option1" autocomplete="off" checked> Jour
                    </label>
                    <label class="btn btn-outline-secondary btn_select_time p2" (click)="MonthStats($event)">
                        <input type="radio" name="options" id="option2" autocomplete="off"> Mois
                    </label>
                    <label class="btn btn-outline-secondary btn_select_time p2" (click)="YearStats($event)">
                        <input type="radio" name="options" id="option3" autocomplete="off"> Année
                    </label>
                </div>
            </div>
        </div>

        <div class="row mt-5">
            <div class="col-xl-8 col-sm-12 col-12">
                <div class="card card_radius">
                    <div class="card-content">
                        <div class="card-body d-flex flex-column pb-0">
                            <div class="d-flex flex-row justify-content-between">
                                <div class="media-body text-left">
                                    <p class="h3">Répartition des scans par types d’emballages</p>
                                </div>

                            </div>

                            <google-chart id="chartNCA" #RSTEChart [dynamicResize]="true" style="width: 100%;"
                                [hidden]="hiddenRSTEChart" [type]="RSTEChartType" [data]="RSTEChartData"
                                [options]="RSTEChartOptions">
                            </google-chart>
                            <div class="text-center">
                                <div class="spinner-border text-primary"
                                    style="width: 3rem; height: 3rem;margin-bottom: 126px;margin-top: 126px;"
                                    [hidden]="hiddenRSTESpinner" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-4 col-sm-12 col-12 mt-xl-0 mt-3">
                <div class="card card_radius" style="height: 350px;">
                    <div class="card-content">
                        <div class="card-body flex-column pb-0">

                            <div class="d-flex flex-row justify-content-between">
                                <div class="media-body text-left">
                                    <p class="h3">Répartition par types de matériaux</p>
                                </div>
                            </div>

                            <div class="d-flex flex-row justify-content-around">
                                <div class="d-flex flex-column justify-content-center align-items-center">
                                    <div class="d-flex flex-column justify-content-center align-items-center"
                                        id="JSFiddle3">
                                        <google-chart style="width: 100%; margin-bottom: 0px;" [hidden]="hiddenRSTMChart"
                                            [type]="RSTMChartType" [data]="RSTMChartData" [options]="RSTMChartOptions">
                                        </google-chart>
                                        <div id="cnt" class="overlay3"  [ngStyle]="{'color': RSTMColor}" [hidden]="hiddenRSTMChart">{{RSTMvalue}}</div>
                                    </div>
                                    <div class="text-center">
                                        <div class="spinner-border text-primary"
                                            style="width: 3rem; height: 3rem;margin-bottom: 126px;margin-top: 126px;"
                                            [hidden]="hiddenRSTMSpinner" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-5">
            <div class="col-xl-4 col-sm-12 col-12 ">
                <div class="card card_radius" style="height: 350px;">
                    <div class="card-content">
                        <div class="card-body flex-column pb-0">

                            <div class="d-flex flex-row justify-content-between">
                                <div class="media-body text-left">
                                    <p class="h3">Taux de tri des emballages scannés</p>
                                </div>
                            </div>

                            <div class="d-flex flex-row justify-content-around">
                                <div class="d-flex flex-column justify-content-center align-items-center">
                                    <div class="d-flex flex-column justify-content-center align-items-center"
                                        id="JSFiddle3">
                                        <google-chart style="width: 100%; margin-bottom: 0px;" [hidden]="hiddenTTESChart"
                                            [type]="TTESChartType" [data]="TTESChartData" [options]="TTESChartOptions">
                                        </google-chart>
                                        <div id="cnt" class="overlay3" [hidden]="hiddenTTESChart">{{TTESvalue}}</div>
                                    </div>
                                    <div class="text-center">
                                        <div class="spinner-border text-primary"
                                            style="width: 3rem; height: 3rem;margin-bottom: 160px;margin-top: 160px;"
                                            [hidden]="hiddenTTESSpinner" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-8 col-sm-12 col-12 m-xl-0 mt-3">
                <div class="card card_radius">
                    <div class="card-content">
                        <div class="card-body d-flex flex-column pb-0">
                            <div class="d-flex flex-row justify-content-between">
                                <div class="media-body text-left">
                                    <p class="h3">Evolution du taux de tri</p>
                                </div>

                            </div>

                            <google-chart id="chartNCA" #RSTEChart [dynamicResize]="true" style="width: 100%;"
                                [hidden]="hiddenETTChart" [type]="ETTChartType" [data]="ETTChartData"
                                [options]="ETTChartOptions">
                            </google-chart>
                            <div class="text-center">
                                <div class="spinner-border text-primary"
                                    style="width: 3rem; height: 3rem;margin-bottom: 126px;margin-top: 126px;"
                                    [hidden]="hiddenETTSpinner" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="row mt-5">
            <div class="col-xl-6 col-sm-12 col-12">
                <div class="card card_radius">
                    <div class="card-content">
                        <div class="card-body d-flex flex-column pb-5">
                            <div class="d-flex flex-row justify-content-between">
                                <div class="media-body text-left">
                                    <p class="h3">Top produits</p>
                                </div>
                            </div>
                            <div class="row mt-5">
                                <div class="col-lg-3">
                                    <img src='../../../../../assets/img/top_produit.jpg'  style="width: 100px;height: 100px;">
                                </div>
                                <div class="col-lg-5 d-flex flex-column justify-content-around">
                                      <p class="p2">Cristaline Eau de source 1,5L</p>
                                      <p class="p3">Cristaline</p>
                                </div>
                                <div class="col-lg-4 d-flex flex-column align-items-end justify-content-center">
                                    <p class="h_black">1516<span class="p2_green">(0.83%)</span></p>
                                </div>
                            </div>
                            <div class="row mt-5">
                                <div class="col-lg-3">
                                    <img src='../../../../../assets/img/top_produit2.png'  style="width: 100px;height: 100px;">
                                </div>
                                <div class="col-lg-5 d-flex flex-column justify-content-around">
                                      <p class="p2">Cristaline Eau de source 1,5L</p>
                                      <p class="p3">Cristaline</p>
                                </div>
                                <div class="col-lg-4 d-flex flex-column align-items-end justify-content-center">
                                    <p class="h_black">1516<span class="p2_green">(0.83%)</span></p>
                                </div>
                            </div>
                            <div class="row mt-5">
                                <div class="col-lg-3">
                                    <img src='../../../../../assets/img/top_produit3.jpg'  style="width: 100px;height: 100px;">
                                </div>
                                <div class="col-lg-5 d-flex flex-column justify-content-around">
                                      <p class="p2">Cristaline Eau de source 1,5L</p>
                                      <p class="p3">Cristaline</p>
                                </div>
                                <div class="col-lg-4 d-flex flex-column align-items-end justify-content-center">
                                    <p class="h_black">1516<span class="p2_green">(0.83%)</span></p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-6 col-sm-12 col-12 mt-xl-0 mt-3">
                <div class="card card_radius" >
                    <div class="card-content">
                        <div class="card-body flex-column pb-5">
                            <div class="d-flex flex-row justify-content-between">
                                <div class="media-body text-left">
                                    <p class="h3">Top marques</p>
                                </div>
                            </div>


                            <div class="row mt-5">
                                <div class="col-lg-3">
                                    <img src='../../../../../assets/img/top_marque.svg'  style="width: 100px;height: 100px;">
                                </div>
                                <div class="col-lg-5 d-flex flex-column justify-content-around">
                                      <p class="p2">Cristaline Eau de source 1,5L</p>
                                      <p class="p3">Cristaline</p>
                                </div>
                                <div class="col-lg-4 d-flex flex-column align-items-end justify-content-center">
                                    <p class="h_black">1516<span class="p2_green">(0.83%)</span></p>
                                </div>
                            </div>
                            <div class="row mt-5">
                                <div class="col-lg-3">
                                    <img src='../../../../../assets/img/top_marque2.jpg'  style="width: 100px;height: 100px;">
                                </div>
                                <div class="col-lg-5 d-flex flex-column justify-content-around">
                                      <p class="p2">Cristaline Eau de source 1,5L</p>
                                      <p class="p3">Cristaline</p>
                                </div>
                                <div class="col-lg-4 d-flex flex-column align-items-end justify-content-center">
                                    <p class="h_black">1516<span class="p2_green">(0.83%)</span></p>
                                </div>
                            </div>
                            <div class="row mt-5">
                                <div class="col-lg-3">
                                    <img src='../../../../../assets/img/top_marque3.png'  style="width: 100px;height: 100px;">
                                </div>
                                <div class="col-lg-5 d-flex flex-column justify-content-around">
                                      <p class="p2">Cristaline Eau de source 1,5L</p>
                                      <p class="p3">Cristaline</p>
                                </div>
                                <div class="col-lg-4 d-flex flex-column align-items-end justify-content-center">
                                    <p class="h_black">1516<span class="p2_green">(0.83%)</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->

    </div>
</div>

export class UserBo {
    prenom:string = "";
    nom:string = "";
    telephone : string = "";
    email : string = "";
    passwordUpdated :boolean= false ;
    password : string = "";
    new_password : string = "";
    old_password : string = "";
    invits :  userInvited[] = [];
    role : string = "";
    idContrat : string ;
    configStatus?: boolean ;
    listContrats? : contratInfos[];
    permissions? : []
}



export class userInvited{
   email : string ;
   role : string ;
   is_accepte : boolean;
   html : string;
}


export class contratInfos{
    idContrat : string
    nomContrat : string
}

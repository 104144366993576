import { ResetpasswordComponent } from './components/resetpassword/resetpassword.component';
import { SignupComponent } from './components/signup/signup.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { ReportsuccessComponent } from './components/reportsuccess/success.component';
import { ReportaccessComponent } from './components/reportaccess/reportaccess.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, RoutesRecognized } from '@angular/router';
import { UserComponent } from './components/user/user.component';
import { AuthGuardService as AuthGuard } from './../../services/guards/auth-guard.service';
import { AuthAnonService as AuthAnon } from './../../services/anonymous/auth-anon.service';



const routes: Routes = [{
    path: 'user',
    component: UserComponent,
    children:[
        {
            path:'signin',
            component : SignInComponent
        },
        {
            path:'signin/:id_invit',
            component : SignInComponent
        },
        {
            path:'signup',
            component : SignupComponent
        },
        {
            path:'signup/:id_invit',
            component : SignupComponent
        },
        {
            path:'resetpassword',
            component : ResetpasswordComponent
        },
        {
            path:'demande/:gid/:mail',
            canActivate : [AuthAnon],
            component : ReportaccessComponent,
            pathMatch: 'full',
        },
        {
            path:'success',
            component : ReportsuccessComponent,
        },
    ]
}];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserModuleRoutingModule { }

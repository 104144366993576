import { Calendrier } from './../../models/calendrier/calendrier';
import { Cartes } from './../../models/carte/carte';
import { map } from 'rxjs/operators';
import { Notification } from './../../models/notification/notification';
import { Router } from '@angular/router';
import { LodingBarService } from './../lodingbar/loding-bar-service.service';
import { AlertService } from './../alert/alert.service';
import { Alert } from './../../models/alert/alert';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject } from 'rxjs';
import { Contrat, Service, Contact, ReseauxSociaux, ContractStats, Carte } from './../../models/contrat/contrat';
import { Injectable } from '@angular/core';
import { CONTRACTCONST } from "./../../const/contrat/contrat";
import { Route } from '@angular/compiler/src/core';
import { Flux } from 'src/app/models/flux/flux';
import { ToastAlertsComponent } from 'src/app/modules/main/components/toast-alerts/toast-alerts.component';
import * as firebase from 'firebase';

@Injectable({
    providedIn: 'root'
})
export class ContratService {

    contrat: BehaviorSubject<Contrat> = new BehaviorSubject<Contrat>(CONTRACTCONST);
    contrat_obs = this.contrat.asObservable()
    calendrier : BehaviorSubject<Calendrier> = new BehaviorSubject<Calendrier>(null);
    calendrier_obs = this.calendrier.asObservable()
    carte : BehaviorSubject<Cartes> = new BehaviorSubject<Cartes>(null);
    carte_obs = this.carte.asObservable()
    temp_services: BehaviorSubject<Service[]> = new BehaviorSubject<Service[]>(this.contrat.value.services);
    temp_services_obs = this.temp_services.asObservable()
    notifications : BehaviorSubject<Notification[]> = new BehaviorSubject<Notification[]>([]);
    notifications_obs = this.notifications.asObservable()
    contractStats : BehaviorSubject<ContractStats> = new BehaviorSubject<ContractStats>(null);
    contractStats_obs = this.contractStats.asObservable()

    constructor(
        private afs: AngularFirestore,
        private alertservices: AlertService,
        private lodingBarService: LodingBarService,
        private router: Router,
        private toastr: ToastAlertsComponent

    ) {

    }

    setInformations(contact: Contact, socialMedia: ReseauxSociaux) {
        this.contrat.value.contact = contact;
        this.contrat.value.reseauxSociaux = socialMedia;
    }

    getContract(idContrat: string) {
        return this.afs.firestore.doc(`preContrats/${idContrat}`).get()
    }

    loadContract(idContrat: string) {
        this.contracctFromDatabase(idContrat).subscribe((data) => {
            if(data.length > 0){
                this.contrat.next(data[0].payload.doc.data() as Contrat);
                this.loadCarte()
                this.loadCalendrier()
            }else{
                console.log('Contrat not found')
            }
        });
    }

    async saveContrat(next_url: string) {
     
        var self = this;
        return self.afs.collection('preContrats').doc(this.contrat.value.contrat.idContrat).set(JSON.parse(JSON.stringify(this.contrat.value)))
                .then(function () {
                    if (next_url != '') {
                        self.router.navigateByUrl(next_url)
                    }
                    return 'success'
                })
                .catch(function (error) {
        
                    return 'error'
                });
    }

    

    deleteAlertePolygon(id:any){
        var self = this;
        this.afs.firestore.doc('preContrats/' + this.contrat.value.contrat.idContrat + '/data/polygonsAlertes').get()
        .then(docSnapshot => {
            if (docSnapshot.exists) {
                let data = docSnapshot.data()
                delete data[id]
                self.afs.collection('preContrats').doc(this.contrat.value.contrat.idContrat).collection('data').doc('polygonsAlertes').set({...data})
                .then(function () {
                    console.log('Polygon deleted')
                })
                .catch(function (error) {
                    console.log('Error :'+ error)
                });
            }
        })
    }

    saveAlertesPolygon(data: any){
        var self = this;
        this.afs.firestore.doc('preContrats/' + this.contrat.value.contrat.idContrat + '/data/polygonsAlertes').get()
        .then(docSnapshot => {
        if (docSnapshot.exists) {
                self.afs.collection('preContrats').doc(this.contrat.value.contrat.idContrat).collection('data').doc('polygonsAlertes').update({...data})
                .then(function () {
                    console.log('New polygon register')
                })
                .catch(function (error) {
                    console.log('Error :'+ error)
                });
            }else if(!docSnapshot.exists){
                self.afs.collection('preContrats').doc(this.contrat.value.contrat.idContrat).collection('data').doc('polygonsAlertes').set({...data})
                .then(function () {
                    console.log('New polygon register')
                })
                .catch(function (error) {
                    console.log('Error :'+ error)
                });
            }
        });  
    }

    saveDocument(file:any, fileName:string){
        var self = this; 
        let storage = firebase.storage().ref(this.contrat.value.contrat.idContrat + "/documents/"+fileName)
        storage.put(file)
        .then(function () {
            console.log('New document register')
        })
        .catch(function (error) {
            console.log('Error :'+ error)
        });
    }

    getDocument(){
        var self = this; 
        let storage = firebase.storage().ref(this.contrat.value.contrat.idContrat + "/documents/")
        storage.listAll()
        .then((res) => {
            return res.items
        }).catch((error) => {
            console.log('Error :'+ error)
        });
    }

    deleteDocument(chemin:string){
        var self = this; 
        let storage = firebase.storage().ref(this.contrat.value.contrat.idContrat + chemin)
        storage.delete()
        .then(function () {
            console.log('Document delete')
        })
        .catch(function (error) {
            console.log('Error :'+ error)
        });
    }

    contracctFromDatabase(idContart: string) {
        return this.afs
            .collection("preContrats", (ref) =>
                ref.where("contrat.idContrat", "==", idContart)
            )
            .snapshotChanges();
    }

    setContrat(contrat: Contrat) {
        this.contrat.next(contrat)
    }

    loadNotifications(idContrat: string) {
        this.notificationsFromDatabase(idContrat).subscribe((data) => {
            var notifications_data = data.map((e) => {
                var notif : Notification =  e.payload.doc.data() as Notification;
                notif.id = e.payload.doc.id
                return notif
            });
            this.notifications.next(notifications_data);
        });
    }

    notificationsFromDatabase(idContrat: string) {
        return this.afs
            .collection("notifications", (ref) =>
                ref.where("idContrat", "==", idContrat)
            )
            .snapshotChanges();
    }

    deleteNotification( notif : Notification){
        var self = this;
        this.afs.doc(`notifications/${notif.id}`).delete()
                .catch(error => {
                    self.toastr.openErrorToast(`Votre alerte n'a pas pu être supprimée, merci de réessayer.`, "");
                    self.lodingBarService.setStatus(false)
                })
                .then(() =>{
                    self.toastr.openSuccessToast('Votre alerte a bien été supprimée', "");
                    self.lodingBarService.setStatus(false)
                });
    }

    updateNotification(notif : Notification){
        var self = this;
        this.afs.doc(`notifications/${notif.id}`).set(notif)
                .catch(error => {
                    self.toastr.openErrorToast(`Votre alerte n'a pas pu être modifiée, merci de réessayer.`, "");
                    self.lodingBarService.setStatus(false)
                })
                .then(() =>{
                    self.toastr.openSuccessToast('Votre alerte a bien été modifiée', "");
                    self.lodingBarService.setStatus(false)
                });
    }

    async addNotification(notif : Notification){
        var self = this ;
        return this.afs.collection('notifications').add(notif)
                .then(function(docRef) {
                    self.lodingBarService.setStatus(false)
                    return "success"
                })
                .catch(function(error) {
                    self.lodingBarService.setStatus(false);
                    return "error"
                });
    }

    loadStats(idContrat: string) {
        var self = this;
       this.afs.collection('stats')
       .doc(idContrat)
       .ref.get()
       .then(
        function (doc){
            var  stats  = doc.data() == undefined  ?  new ContractStats({}) : new ContractStats(doc.data())
            self.contractStats.next(stats)
        }
       )
    }

    setCalendrier(calendrier: Calendrier) {
        this.calendrier.next(calendrier)
    }

    async saveCalendrier(next_url : string){
        this.lodingBarService.setStatus(true);
        var self = this;
        return self.afs.collection('preContrats').doc(this.contrat.value.contrat.idContrat).collection('data').doc('calendrier').set({...this.calendrier.value})
            .then(function () {
                self.lodingBarService.setStatus(false);
                if (next_url != '') {
                    self.router.navigateByUrl(next_url)
                }
                return 'success'
            })
            .catch(function (error) {
                self.lodingBarService.setStatus(false);
                return 'error'
            });
    }

    loadCalendrier() {
        this.calendrierFromDatabase().subscribe((data) => {
            this.calendrier.next(new Calendrier(data.payload.data()??{}));
        });
    }

    calendrierFromDatabase() {
        return this.afs.doc('preContrats/' + this.contrat.value.contrat.idContrat + '/data/calendrier').snapshotChanges();
    }
    
    alertesFromDatabase() {
        return this.afs.doc('preContrats/' + this.contrat.value.contrat.idContrat + '/data/polygonsAlertes').snapshotChanges();
    }

    setCarte(carte: Cartes) {
        this.carte.next(carte)
    }

    async saveCarte(next_url : string){
        this.lodingBarService.setStatus(true);
        var self = this;
        return self.afs.collection('preContrats').doc(this.contrat.value.contrat.idContrat).collection('data').doc('carte').set({...this.carte.value})
            .then(function () {
                self.lodingBarService.setStatus(false);
                if (next_url != '') {
                    self.router.navigateByUrl(next_url)
                }
                return 'success'
            })
            .catch(function (error) {
                self.lodingBarService.setStatus(false);
                return 'error'
            });
    }

    loadCarte() {
        this.carteFromDatabase().subscribe((data) => {
            this.carte.next(new Cartes(data.payload.data()??{}));
        });
    }

    carteFromDatabase() {
        return this.afs.doc('preContrats/' + this.contrat.value.contrat.idContrat + '/data/carte').snapshotChanges();
    }
}

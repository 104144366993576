import { FluxComponent } from './components/flux/flux.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule,ReactiveFormsModule} from '@angular/forms'
import { CoordinateComponent } from './components/coordinate/coordinate.component';
import { CartsettingsRoutingModule } from './cartsettings-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { CartesettingsComponent } from './components/cartesettings/cartesettings.component';
import { FiltreComponent } from './components/filtre/filtre.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';


@NgModule({
  declarations: [CartesettingsComponent,CoordinateComponent,FluxComponent,FiltreComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AngularSvgIconModule.forRoot(),
    CartsettingsRoutingModule,
    AutocompleteLibModule
  ]
})
export class CartsettingsModule { }

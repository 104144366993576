<ng-template #registerPolygon >
    <ng-container *ngIf='exist[0]'>
    <form>
        <div class="form-group">
            <div class="col text-center">
          <label for="titlePolygon" style="margin-top:10px;">Nom du dessin : </label>
            <input type="text" #titlePolygon required class="form-control" id="titlePolygon" value="{{exist[1]}}" placeholder="Un nom">
          <small id="passwordHelpInline" class="text-muted">
            Ce champs est obligatoire.
          </small>
            <div class="dropdown mt-2">
                <button class="btn btn-secondary dropdown-toggle" type="button" 
                id="sampleDropdownMenu" style="background-color: white;padding: 3px 6px 3px 6px;color: black;border-color: #F47000;font-size: 14px;font-family: avenir_light;" data-toggle="dropdown">
                Couleur du polygone
                </button>
                <div class="dropdown-menu text-center" style="width: 50%;">
                    <ng-container  *ngFor="let element of colorsPolygons" >
                        <button type="button" class="btn btn-primary" (click)="onChangeColor(element)" [style.background-color]="element" style="margin-left: 8px;" data-toggle="button" aria-pressed="false" autocomplete="off"> 
                        </button>
                    </ng-container>
                </div>
            </div>
            <button type="button" class="btn btn-primary" disabled [style.background-color]="selectedcolor" style="opacity: 1 !important;" ></button>
        </div>
        </div>
        <div class="col text-center">
        <button type="button"  style="margin-bottom: 11px;background-color: #F47000;padding: 2px 7px;margin-right: 20px;" (click)='closeRegister()' class="btn btn-primary">Fermer</button>
        <button type="submit"  style="margin-bottom: 11px;background-color: #F47000;padding: 2px 7px;" (click)='modifyOnePolygon(titlePolygon.value)' class="btn btn-primary">Enregistrer</button>
        </div>
    </form>
    </ng-container>
    <ng-container *ngIf='!exist[0]'>
        <form>
            <div class="form-group">
                <div class="col text-center">
                <label for="titlePolygon" style="margin-top:10px;">Nom du dessin : </label>
                    <input type="text" #titlePolygon required class="form-control" id="titlePolygon" placeholder="Un nom">
                <small id="passwordHelpInline" class="text-muted">
                    Ce champs est obligatoire.
                </small>
                <div class="dropdown mt-2">
                    <button class="btn btn-secondary dropdown-toggle" type="button" 
                    id="sampleDropdownMenu" style="background-color: white;padding: 3px 6px 3px 6px;color: black;border-color: #F47000;font-size: 14px;font-family: avenir_light;" data-toggle="dropdown">
                    Couleur du polygone
                    </button>
                    <div class="dropdown-menu text-center" style="width: 50%;">
                        <ng-container  *ngFor="let element of colorsPolygons" >
                            <button type="button" class="btn btn-primary" (click)="onChangeColor(element)" [style.background-color]="element" style="margin-left: 8px;" data-toggle="button" aria-pressed="false" autocomplete="off"> 
                            </button>
                        </ng-container>
                    </div>
                </div>
                <button type="button" class="btn btn-primary" disabled [style.background-color]="selectedcolor" style="opacity: 1 !important;" ></button>
             </div>
            </div>
            <div class="col text-center">
            <button type="button"  style="margin-bottom: 11px;background-color: #F47000;padding: 2px 7px;margin-right: 20px;" (click)='closeRegister()' class="btn btn-primary">Fermer</button>
            <button type="submit"  style="margin-bottom: 11px;background-color: #F47000;padding: 2px 7px;" (click)='registerPolygonGroup(titlePolygon.value)' class="btn btn-primary">Enregistrer</button>
            </div>
        </form>
        </ng-container>
</ng-template>

<ng-template #modalDelete >
            <div class="modal-body" style="padding: 15px 10px;">
              <p style="margin-bottom: auto;text-align: center;">Etes vous sur de vouloir supprimer {{onDelete}} ?</p>
            </div>
            <div class="col text-center" style="padding: 6px 15px;">
              <button type="button" class="btn btn-secondary" style="margin-bottom: 11px;background-color: #F47000;padding: 2px 7px;margin-right: 20px;" (click)='closeConfirmDelete()'>Fermer</button>
              <button type="button" class="btn btn-primary" style="margin-bottom: 11px;background-color: #F47000;padding: 2px 7px;" (click)='deletePolygon(onDelete)'>Valider</button>
            </div>
</ng-template>

<div class="row p-3" >
    <div class="col-lg-12">
        <div class="row">
            <div class="col-12 d-flex flex-column justify-content-center align-items-lg-start align-items-center">
                <p class="pb-0 mb-0  h3" style="text-align: center;">Envoyer une alerte
                </p>
                <p class="pb-0 mb-0 mt-0 p2" style="text-align: center;">Votre notification sera envoyé dès sa validation</p>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-lg-12  d-flex flex-row justify-content-center justify-content-lg-start">
                <div class="row w-100">
                    <div class="col-lg-10  d-flex flex-row justify-content-center justify-content-lg-start">
                        <input type="file" name="file" #fileInput id="file" class="inputfile" (change)="sendactusimage(fileInput.files[0])" />
                        <label for="file" style="cursor: pointer;" class="d-flex flex-row w-100  d-flex flex-row justify-content-center justify-content-lg-start ">
                            <img src="../../../../../assets/img/028-addelementleft.svg" style="width: 35px;height: 35px;">
                            <input type="text" name="file" class="form-control classname w-100 h4"
                                style="height: 35px;border-radius: 0 10px 10px 0 !important;"
                                value="{{fileInput.files[0] != null ?fileInput.files[0].name :'Ajouter une image'}}"
                                readonly />
                        </label>
                    </div>

                </div>
            </div>
        </div>

            <div class="row">
                <div class="col-12 d-flex flex-column justify-content-center align-items-lg-start align-items-center">
                    <p class="pb-0 mb-0  h3" style="text-align: center;">Type de votre alerte :
                    </p>
                    <p class="pb-0 mb-0 mt-0 p2" style="text-align: center;">Il s’agit du type de votre  notification</p>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-lg-12 d-flex flex-column justify-content-center align-items-lg-start align-items-center">
                    <select class="custom-select p2" [(ngModel)]="notification.type" style="width: auto;box-shadow: none;border: 1px solid #F47000; color: black;">
                        <option class="p2" *ngFor="let item of alerttypes" [ngValue]="item">{{capitalize(item)}}</option>

                    </select>
                </div>
            </div>

            <div class="row mt-2 mb-4"  *ngIf="typectuserror==true">
                <div class="col-lg-12  d-flex flex-row justify-content-center justify-content-lg-start">
                    <div class="row w-100">
                        <div class="col-lg-12 mt-3 mt-lg-0 d-flex flex-row justify-content-center justify-content-lg-start align-items-center error pb-0">
                            <i class="fa fa-exclamation-triangle fa-2x" aria-hidden="true" style="width: 30px;height: 30px;"></i>
                            <p class="ml-3 h-100 d-flex flex-row  mb-0 pb-0 justify-content-center align-items-end" style="text-align: center;">Le type de l'alerte est obligatoire</p>
                        </div>
                    </div>
                </div>
            </div>




        <div class="row mt-3">
            <div class="col-12 d-flex flex-column justify-content-center align-items-lg-start align-items-center">
                <p class="pb-0 mb-0  h3" style="text-align: center;">Titres et sous-titre :
                </p>
                <p class="pb-0 mb-0 mt-0 p2" style="text-align: center;">Il s’agit du titre et du sous-titre qui s’affichent dans le fil d’actualité.</p>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-lg-12 d-flex flex-column justify-content-center align-items-lg-start align-items-center">
                <input type="text" *ngIf="notification != undefined" ngClass="{{titleactuserror ? 'colorerrorinput' : 'colornoterrorinput'}}" style="font-family:Arial, FontAwesome" autocomplete="off" (focus)="titleactuserror = false"  placeholder="{{titleactuserror ? '&#xf071; Champs obligatoire':'Titre'}}"  [(ngModel)]="notification.titre" (ngModelChange)="onChange()"   placeholder="Titre" class="form-control input1"  required>
                <p class="p3">{{notification.titre.length}}/180</p>
            </div>
        </div>

        <div class="row mt-2">
            <div class="col-lg-12 d-flex flex-column justify-content-center align-items-lg-start align-items-center">
                <textarea type="text" *ngIf="notification != undefined"  ngClass="{{descriptionactuserror ? 'colorerrorinput' : 'colornoterrorinput'}}"  style="font-family:Arial, FontAwesome" autocomplete="off" (focus)="descriptionactuserror = false"  placeholder="{{descriptionactuserror ? '&#xf071; Champs obligatoire':'Description'}}"  [(ngModel)]="notification.description" rows="6" (ngModelChange)="onChange()"   placeholder="Description" class="form-control input1"  required></textarea>
                <p class="p3">{{notification.description.length}}/180</p>
            </div>
        </div>



        <div class="row mt-2">
            <div class="col-lg-12 d-flex flex-column justify-content-center align-items-lg-start align-items-center">
                <input type="text" *ngIf="notification != undefined" style="font-family:Arial, FontAwesome" ngClass="{{urlactuserror ? 'colorerrorinput' : 'colornoterrorinput'}}" autocomplete="off" (focus)="urlactuserror = false"  placeholder="{{urlactuserror ? '&#xf071; Champs obligatoire':'URL'}}"  [(ngModel)]="notification.url" (ngModelChange)="onChange()"   placeholder="URL" class="form-control input1"  required>
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-lg-12 d-flex flex-row align-items-center">
                <p class="pb-0 mb-0  h3 ">Epinglé :</p>
                <div class="btn-group ml-2 mt-2" role="group" id="{{'itemp'}}">
                    <input type="radio" name="{{'itemp'}}"  [value]="true"  [(ngModel)]="pinned" (ngModelChange)="onchange($event)" id="{{'radioptoui'}}"/>
                    <label class="btn btn-secondary radius_left_actus" id="{{'radioouip'}}" for="{{'radioptoui'}}">
                        Oui
                    </label>
                    <input type="radio" name="{{'itemp'}}" [value]="false" [(ngModel)]="pinned"  (ngModelChange)="onchange($event)"    class="radio_input radio_non"   id="{{'radioptnon'}}"/>
                    <label  class="btn btn-secondary radius_right_actus" id="{{'radiononp'}}"   for="{{'radioptnon'}}">
                        Non
                    </label>
                </div>
            </div>
        </div>

        <div class="row mt-2">
            <div class="col-lg-12 d-flex flex-row align-items-center">
                <p class="pb-0 mb-0  h3 ">Ciblage :</p>
                <div class="btn-group ml-2 mt-2" role="group" id="{{'item'}}">
                    <input type="radio" name="{{'item'}}"  [value]="true"  [(ngModel)]="notification.polygon" (ngModelChange)="onchange($event)" id="{{'radiobtoui'}}"/>
                    <label class="btn btn-secondary radius_left_actus" id="{{'radiooui'}}" for="{{'radiobtoui'}}">
                        Oui
                    </label>
                    <input type="radio" name="{{'item'}}" [value]="false" [(ngModel)]="notification.polygon"  (ngModelChange)="onchange($event)"    class="radio_input radio_non"   id="{{'radiobtnon'}}"/>
                    <label  class="btn btn-secondary radius_right_actus" id="{{'radionon'}}"   for="{{'radiobtnon'}}">
                        Non
                    </label>
                </div>
            </div>
        </div>

        <ng-container *ngIf='groupShapes.length > 0'>
            <div class="row" [hidden]="!notification.polygon">
                <div class="col-12 d-flex flex-column justify-content-center align-items-lg-start align-items-center">
                    <p class="pb-0 mb-0  h3" style="text-align: center;">Historiques des dessins:
                    </p>
                    <p class="pb-0 mb-0 mt-0 p2" style="text-align: center;">Il s’agit de vos dessins enregistrés</p>
                </div>
            </div>
            <div class="row mt-2" [hidden]="!notification.polygon">
                <div class="col-lg-12 d-flex flex-column justify-content-center align-items-lg-start align-items-center">
                    <div class="dropdown">
                        <button class="btn btn-secondary dropdown-toggle" type="button" 
                        id="sampleDropdownMenu" style="background-color: white;padding: 3px 6px 3px 6px;color: black;border-color: #F47000;font-size: 14px;font-family: avenir_light;" data-toggle="dropdown">
                        Vos dessins
                        </button>
                        <div class="dropdown-menu" style="width: 380%;">
                            <ng-container  *ngFor="let element of groupShapes" >
                            <button class="dropdown-item" type="button" style="margin-top: -5px;">
                                <input type="checkbox" (click)='filterList(element, $event)'>    {{element[0]}}
                                <i class="fa fa-trash fa-1x" style="color: #EF627A;position: fixed;right: 18px;;font-size: 20px;" (click)='showConfirmDelete(element[0])' ></i>
                            </button>
                            </ng-container>
                            <!-- <div class="dropdown-divider"></div>
                            <a class="dropdown-item" >Gerer vos dessins</a> -->
                        </div>
                    </div>
                </div>
            </div>
           
        </ng-container>
        <!-- <ng-container *ngIf='groupShapes.length === 0'>
            <p class="pb-0 mb-0 mt-0 p2" style="text-align: center;">Vous pouvez enregistrer le polygon en faisant clique droit dessus.</p>
        </ng-container> -->
        

        <div class="row mt-2"  [hidden]="!notification.polygon">
           <p class="pb-4 pt-4 pl-3 mb-0  h3">Dessinez sur la carte les zones géographique que vous souhaitez cibler :</p>
           <ng-container *ngIf='groupShapes.length === 0'>
            <p class="ml-3" style="margin-top: -10px; font-size: 13px;">Vous pouvez enregistrer le polygone en faisant clique droit dessus.</p>
             </ng-container>
             <ng-container *ngIf='errorNoPolygon'>
                <p class="ml-3" style="margin-top: -10px; font-size: 13px;color: red;">Merci de selectionner au moins un polygone.</p>
                 </ng-container>
            <div class="col-lg-12 d-flex flex-row align-items-center" style="height: 500px;" id="map">

            </div>
        </div>

        <div class="row mb-4 mt-4">
            <div class="col-lg-4">
                <button type="button" class="btn button4 btn-primary w-100" (click)="savenotif()">Envoyer</button>
            </div>
            <div class="col-lg-4 mt-3 mt-lg-0 ">
                <button type="button" class="btn button7 btn-primary w-100" (click)="cancel()">Annuler</button>
            </div>
            <div id="cm-map" class="context-menu">
                <ul>
                  <li data-action="registerOnePolygon">Enregistrer/Modifier ce polygone</li>
                  <li data-action="deleteOnePolygon">Supprimer ce polygone</li>
                  <li data-action="deleteAllPolygon">Supprimer l’ensemble</li>
                </ul>
            </div>
        </div>

    </div>
</div>

<link href="//netdna.bootstrapcdn.com/bootstrap/3.0.3/css/bootstrap.min.css" rel="stylesheet" id="bootstrap-css">
<script src="//netdna.bootstrapcdn.com/bootstrap/3.0.3/js/bootstrap.min.js"></script>
<script src="//code.jquery.com/jquery-1.11.1.min.js"></script>

<div class="container-fluid mt-5">

    <div class="row text_light ml-2 mt-5">
        <div class="col-3 pr-2">
            <button type="button" (click)="setMenu(0)" type="submit" style="background-color: #5EC394;border: #5EC394;" class="btn  btn-primary btn-lg btn-block">Sélection des flux
            </button>
        </div>
        <div class="col-3 pr-2">
            <button type="button" (click)="setMenu(1)" type="submit" style="background-color: #5EC394;border: #5EC394;" class="btn  btn-primary btn-lg btn-block">Coordonnées
            </button>
        </div>
    </div>

    <div *ngIf="menu == 0">
        <div class="row ml-4 mt-5 text_black text_heavy">
            <h3>Activez vos flux</h3>
        </div>
        <div class="row ml-4 mb-4 text_black text_heavy">
            <span>Activez les flux que vous voulez afficher sur la carte.</span>
        </div>

        <div class="container" *ngFor="let flow of flows; index as i">
            <div class="row">
                <div class="col-md-3 mt-3 mb-3">
                    <div class="btn-group" id="status" data-toggle="buttons">
                      <label class="btn btn-default btn-on btn-xs">
                      <input type="radio" value="1" (click)="setOption(i, true)" name="multifeatured_module[module_id][status]">Oui</label>
                      <label class="btn btn-default btn-off btn-xs active">
                      <input type="radio" value="0" (click)="setOption(i, false)" name="multifeatured_module[module_id][status]" >Non</label>
                    </div>
                    <span class="ml-3" >{{ flow }}</span>
                </div>
                <div class="col-md-2 mt-1" *ngIf="options[i] == true">
                    <input type="text" autocomplete="off" class="form-control delete_border" id="flow_color" formControlName="flow_color" required>
                </div>
            </div>
        </div>
    </div> 

    <div *ngIf="menu == 1">
        <div class="row ml-4 mt-5 text_black text_heavy">
            <h5>Renseignez les coordonnees GPS de vos points d’apport volontaire :</h5>
        </div>
        <div class="row ml-4 mb-4 text_black">
            <span>Activez les flux que vous voulez afficher sur la carte.</span>
        </div>
        <div class="row ml-4 form-group">
            <input type="file" id="file" (change)="handleFileInput($event.target.files)">
        </div>
    </div> 

    <div class="row text_light ml-2 mt-5 mb-5">
        <div class="col-3 pr-2 ">
            <button type="button" (click)="uploadFile()" type="submit" style="background-color: #5EC394;border: #5EC394;" class="btn  btn-primary btn-lg btn-block">Enregistrer
            </button>
        </div>
    </div>

    <div class="form-group">
        <label for="file">Choose File</label>
        <input type="file"
           id="file"
           >
    </div>

</div>

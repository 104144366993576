export class Calendrier {
    villes?: Ville[];
    flux?:   FluxCalendrier[];
    collecteKo?: string
    constructor({villes = [] , flux = [], collecteKo = "" }){
        this.villes = villes
        this.flux = flux
        this.collecteKo = collecteKo
    }

}

export class FluxCalendrier {
    nom:   string;
    image: string;
    texte?: string;
    libelleBouton?: string;
    flux?: string;
    zones : Zone[];
    url?: string;
}

export class Zone {
    consigne : string;
    jour : string;
    polygones : string[]
    infos :  infosPolygons
}

export class Ville {
    infos: infosPolygons[];
    nom:   string;
}

export class Info {
    jour:           string;
    flux:           string;
    carte?:         string[];
    texte?:         string;
    libelleBouton?: string;
    consigne?:      string;
    infos?:         infosPolygons
}

export class infosPolygons{
    nomPolygone? : string;
    a_publier? : boolean;
    fluxCollecte? : string;
    periodeType? : string;
    fluxCarte? : string;
    periode? : string;
    frequence? : string;
    jours? : Jours ;
    daysPerMonth? : string;
    horaires? :  string;
    commentaire? : string;
    btn_pav? : boolean;
    libelleBouton? : string;
    description? : string;
}

export class Jours{
    lundi : boolean;
    mardi : boolean;
    mercredi : boolean;
    jeudi : boolean;
    vendredi : boolean;
    samedi : boolean;
    dimanche : boolean
}

import { Zone } from './../../../../models/calendrier/calendrier';
import { UserBo } from './../../../../models/user/user';
import { UserService } from './../../../../services/user/user.service';
import { error, element } from 'protractor';
import { Alert } from './../../../../models/alert/alert';
import { LodingBarService } from './../../../../services/lodingbar/loding-bar-service.service';
import { AlertService } from './../../../../services/alert/alert.service';
import { Contrat, FluxClass, Stats, DesignFlux } from './../../../../models/contrat/contrat';
import { ContratService } from './../../../../services/contrat/contrat.service';
import { TabmessageService } from './../../../../services/tabmessage/tabmessage.service';
import { Component, OnInit } from '@angular/core';
import { ToastAlertsComponent } from 'src/app/modules/main/components/toast-alerts/toast-alerts.component';


@Component({
    selector: 'app-recycling-instruction',
    templateUrl: './recycling-instruction.component.html',
    styleUrls: ['./recycling-instruction.component.scss']
})
export class RecyclingInstructionComponent implements OnInit {

    next_status : boolean = false ;
    error_status : boolean = false ;
    flux : DesignFlux[] = [];
    colorItem : string ;
    edit_status : boolean = true;
    user : UserBo
    contart : Contrat
    couleurErrorMsg : string = ''

    flows = ["Ordures ménagères", "Emballages recyclables", "Verre", "Bornes Montri", "Déchetteries", "Déchetteries mobiles", "Sites de compostage", "Sites de réemploi", "Piles", "Ampoules", "Parcs à sapins"];


    constructor(
        private sectionservice: TabmessageService,
        private contratservice : ContratService,
        private lodingbarservice: LodingBarService,
        private alertservice: AlertService,
        private userservice : UserService,
        private toastr: ToastAlertsComponent
    ) {

    }

    ngOnInit(): void {
        window.scroll(0,0)
        this.sectionservice.setSection('recyclinginstructions');
        this.userservice.user_obs.subscribe((user)=>{
            this.user =  user
            if(user != null){

                this.contratservice.contrat_obs.subscribe((contrat)=>{
                    this.contart = contrat
                    this.flux = [...this.contart.design.flux]
                    for(let [i,service] of contrat.services.entries()){
                        this.flux[i].error = false
                        this.flux[i].colorErrorMsg = ''
                    }

                })
            }

        })

    }

    async saveData(){
        let error = 0 ;
        for (let [i,element] of this.flux.entries()){
            if  (element.active && element.couleur == ''){
                this.flux[i].error = true
                this.flux[i].colorErrorMsg = 'Champs obligatoire'
                error = 1
            }
            else if  (element.active && !element.couleur.match('^#([A-Fa-f0-9]{6})$')){
                this.flux[i].couleur = ''
                this.flux[i].error = true
                this.flux[i].colorErrorMsg = 'Code couleur incorrect'
                error = 1
            }
        }

        if(error === 1){
            this.error_status = true
            this.toastr.openFailedToast(`Certains champs obligatoires ne sont pas remplis.`, "")
        }
        else{
            let ref = await this.toastr.openTransferToast(`Transfert de données en cours.`, "")
            this.error_status = false
            for (let element of  this.flux){
                if(element.active && (this.contratservice.calendrier.value.flux.filter(item => item.nom == element.nom).length == 0)){
                    this.contratservice.calendrier.value.flux.push(
                        {
                            image : element.image,
                            nom : element.nom,
                            zones : []
                        }
                    )
                }
            }

            this.contratservice.contrat.value.design.flux =[...this.flux]
            this.contratservice.contrat.value.configstatus[2].status = true
            this.contratservice.saveCalendrier('')
            let status = await this.contratservice.saveContrat('/montri/contractsettings/design')
            if(status === "success"){
                this.toastr.clear(ref.toastId)
                this.toastr.openSuccessToast('Données enregistrées avec succès', "");
            }else{
                this.toastr.clear(ref.toastId)
                this.toastr.openErrorToast(`Chargement échoué. Veuillez réessayer.`, "");
            }
        }
    }

    onfocus(evt,i){

    }

    onChangeDisplayName(event, itemName){
        let displayName = event.target.value
        for(let item of this.flux){
            if(item.nom === itemName){
                item.displayName = displayName
            }
        }
    }

    onChange(evt,i){
        if(evt.value==7){
            var color_div = document.getElementById('color'+i)
            color_div.style.backgroundColor = evt.value
            color_div.hidden = false
        }
        else{
            color_div.hidden = true
        }
        var status_correct = 0 ;
        var  status_exist = 0;
        for (let [i,element] of this.flux.entries()){
            if  (element.active && !element.couleur.match('^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$')){
                this.flux[i].error = true
                status_correct = 1
            }
        }

        if(status_correct == 1){
            this.next_status = true
        }
        else{
            this.next_status = false
        }
    }

    checkerror(flux : FluxClass){
        return flux.error ==  true
    }

    checknext(flux : FluxClass){
        return flux.active == true && flux.couleur == ""
    }

    error(){
        return this.flux.some(this.checkerror)
    }

    next(){
        return  (this.flux.some(this.checknext))
    }

    addfirst(i : number){
        if(this.flux[i].couleur==''){
            this.flux[i].couleur="#"
        }
    }

    updateType(){

    }
}

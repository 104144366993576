import { AngularFireAuth } from '@angular/fire/auth';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import * as firebase from "firebase/app";


import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/take';


@Injectable({
	providedIn: "root",
})
export class AuthGuardService implements CanActivate {

  constructor(private afAuth: AngularFireAuth, private router: Router) {}

  canActivate(): Observable<boolean> {
      return this.afAuth.authState
       .take(1)
       .map(user => {
          if ((user == null) || ((user != null) && user.isAnonymous)) {
            return false
          } else {
            return true
          }
       })
       .do(loggedIn => {
         if (!loggedIn) {
           console.log("access denied")
           this.router.navigateByUrl('/user/signin');
         }
     })
   }
}




// import { map, tap } from 'rxjs/operators';
// import { UserService } from './../user/user.service';
// import { AuthentificationService } from './../authentification/authentification.service';
// import { Injectable } from '@angular/core';
// import { Router, CanActivate } from '@angular/router';
// import { Observable } from 'rxjs';
// import { of } from 'rxjs';



// @Injectable({
//     providedIn: 'root'
// })
// export class AuthGuardService implements CanActivate {
//     constructor(
//         private authservice: AuthentificationService,
//         private userservice: UserService,
//         public router: Router) {}

//     canActivate(): Observable<boolean> | Promise<boolean> | boolean   {

//         return new Promise((resolve, reject) => {
//             this.authservice.isLoggedIn().pipe(
//                 tap(user => {
//                 if (user) {
//                     resolve(true)
//                 } else {
//                     this.router.navigateByUrl('/user/signin');
//                     resolve(false)
//                 }
//                 })
//             )
//         })


//         // if (!this.authservice.isLoggedIn()) {
//         //     this.router.navigateByUrl('/user/signin');
//         //     return of(false);
//         // }
//         // else{
//         //     return of(true)
//         //     return this.userservice.user_obs.pipe(map((user)=>{
//         //         if(user!= null){
//         //             if(user.passwordupdated == false){
//         //                 this.router.navigateByUrl('/user/updatepassword');
//         //                 return false
//         //             }
//         //             return true
//         //         }
//         //         return false
//         //     }))
//         // }

//     }
// }

import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as firebase from 'firebase';
import { Contrat } from 'src/app/models/contrat/contrat';
import { Document } from 'src/app/models/document/document';
import { ContratService } from 'src/app/services/contrat/contrat.service';
import * as short from 'short-uuid';

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss']
})
export class DocumentComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal,
    private contratservice : ContratService,
  ) { }
  @Input() document : Document;
  @Input() contrat: Contrat;
  @Input() update : boolean = false
  doc_list : Document[] = [];
  documentFile
  nameError : boolean = false
  typeError : boolean = false
  docError : boolean = false
  fileType
  fileExtension
  @ViewChild('titleInput') titleInput:ElementRef;
  labelAjoutDocument: string = "Ajouter un document"
  items: any[] = [
    { id: 1, name: 'Informations' },
    { id: 2, name: 'Règlement' },
    { id: 3, name: 'Formulaire' },
    { id: 4, name: 'Calendrier' },
  ];


  ngOnInit(): void {
    let documentService = this.contrat.services.find(service => service.nom == 'Documents')
        if(documentService){
          this.doc_list = documentService.documents
        }
    if(this.document){
      this.labelAjoutDocument = `${this.document.nom}.${this.document.extension}`
      this.fileType = this.document.type

    }else{
      this.document = new Document()
    }

    if(this.update)(
      this.documentFile = "document"
    )
  }

  getFile(file: File){
    this.documentFile = file;
    this.labelAjoutDocument = file.name
    this.fileExtension = file.name.split('.')[1]
  }

  selectOption(name: string){
    this.fileType = name
    console.log('ok')
  }

  cutString(str: string, maxLength: number){
    if(str.length > maxLength){
      return str.substring(0, maxLength) + "..."
    }
    return str
  }
  

  savedoc(){
    if(this.titleInput.nativeElement.value){
      this.document.nom = this.titleInput.nativeElement.value
      this.nameError = false
      if(this.fileType && this.fileType != "Selectionner"){
        this.document.type = this.fileType
        this.typeError = false
        if(this.documentFile){
          if(!this.update){
            let id = short.generate()
            this.document.extension = this.fileExtension
            this.document.chemin = `/documents/${id}.${this.fileExtension}`
            this.document.date = new firebase.firestore.Timestamp(firebase.firestore.Timestamp.now().seconds,firebase.firestore.Timestamp.now().nanoseconds);
            this.contratservice.saveDocument(this.documentFile, `${id}.${this.document.extension}`)
            this.doc_list.push(this.document)
          }
          this.docError = false
          this.contratservice.saveContrat('')
          this.activeModal.close(this.document)
        }else{
          this.docError = true
        }
      }else{
        this.typeError = true
      }
    }else{
      this.nameError = true
    }
  }

  close(){
    this.activeModal.close()
  }

  

}

import { Component, OnInit } from '@angular/core';
import { FilteredItem } from 'src/app/models/carte/carte';
import { Contrat } from 'src/app/models/contrat/contrat';
import { Flux } from 'src/app/models/flux/flux';
import { ContratService } from 'src/app/services/contrat/contrat.service';
import { TabmessageService } from 'src/app/services/tabmessage/tabmessage.service';
import { ToastAlertsComponent } from 'src/app/modules/main/components/toast-alerts/toast-alerts.component';



@Component({
  selector: 'app-filtre',
  templateUrl: './filtre.component.html',
  styleUrls: ['./filtre.component.scss']
})

export class FiltreComponent implements OnInit {

    contrat :  Contrat
    filteredItems = []
    data: Flux[]
    inputSearch = ""
    hideresult: boolean = false
    selectedItem = null
    pavState = 'opérationnel'
    currentState = ''
    stateChanged : boolean = false
    keyword = 'id';
    temp = [];

    constructor(
        private contratservice  : ContratService,
        private sectionservice: TabmessageService,
        private toastr: ToastAlertsComponent
    ){

    }

    ngOnInit(): void {
        window.scroll(0,0)

        this.sectionservice.setSection('filtre');
        this.contratservice.contrat.subscribe((contrat)=>{
            this.contrat = contrat
            this.contratservice.carte_obs.subscribe((data)=>{
                if (data.flux.length > 0) {
                    this.data = data.flux
                } else {
                    this.data = this.contrat.carte
                }
                this.data.forEach(element => {
                    element.pavs.forEach(pav => {
                        if(!pav.flux){
                            pav.flux = element.nom
                        }
                        this.temp.push(pav)
                    });
                });
            })
        })
    }

    selectEvent(item) {
        this.selectResult(item)
      }
    
      onChangeSearch(search: string) {
        // fetch remote data from here
        // And reassign the 'data' which is binded to 'data' property.
        //this.hideresult = false
        this.selectedItem = null
      }
    

    changeState(state) {
        if (this.currentState != state) {
            this.pavState = state
            this.stateChanged = true
        } else {
            this.pavState = this.currentState
            this.stateChanged = false
        }
    }

    // onChange(event) {
    //     if (event.target.value) {
    //         this.checkExist(event.target.value) 
    //     } else {
    //         this.filteredItems = []
    //     }
    //     this.inputSearch = event.target.value
    //     this.selectedItem = null
    //     this.hideresult = false
    // }

   

    selectResult(item) {
        this.inputSearch = item.id
        console.log(item)
        this.hideresult = true
        this.selectedItem = item
        if (!item.etat) {
            this.currentState = 'opérationnel'
            this.pavState = 'opérationnel'
        } else if (item.pav.etat) {
            this.currentState = item.etat
            this.pavState = item.etat
        }
    }

    // checkExist(pattern) {
    //     this.filteredItems = []
    //     let patternLen = pattern.length
    //     let unaccentLowercasedPattern = pattern.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()
    //     this.data.forEach(element => {
    //         element.pavs.forEach( pav => {
    //             let unaccentLowercasedPavName = pav.id.substring(0, patternLen)
    //             unaccentLowercasedPavName = unaccentLowercasedPavName.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()
    //             if (unaccentLowercasedPattern === unaccentLowercasedPavName) {
    //                 this.filteredItems.push(new FilteredItem(pav,element.nom))
    //             }
    //         })
    //     })
    // }

    async registerPav(){
        this.selectedItem.pav.etat = this.pavState
        let obj = {flux: this.data}
        this.contratservice.setCarte(obj)
        let status = await this.contratservice.saveCarte('')
        if(status === 'success'){
            this.toastr.openSuccessToast('Données enregistrées avec succès', "");
        }else{
            this.toastr.openErrorToast(`Chargement échoué. Veuillez réessayer.`, "");
        }
    }
}

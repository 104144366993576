import { NgModule } from '@angular/core';
import {FormsModule,ReactiveFormsModule} from '@angular/forms'
import { ContractsettingsRoutingModule } from './contractsettings-routing.module';
import { ContractsettingsComponent } from './components/contractsettings/contractsettings.component';
import { InformationsComponent } from './components/informations/informations.component';
import { RecyclingInstructionComponent } from './components/recycling-instruction/recycling-instruction.component';
import { DesignComponent } from './components/design/design.component';
import { ServicesComponent } from './components/services/services.component';
import { SliderComponent } from './components/slider/slider.component';
import { WebhomeComponent } from './previews/webhome/webhome.component';
import { HttpClientModule } from '@angular/common/http';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { MobilesliderComponent } from './previews/mobileslider/mobileslider.component';
import { ServiceinfosComponent } from './modals/serviceinfos/serviceinfos/serviceinfos.component';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChipsModule } from 'primeng/chips';
import { ButtonModule } from 'primeng/button';
import { BrowserModule } from '@angular/platform-browser';
import { AutoCompleteModule } from 'primeng/autocomplete';


@NgModule({
  declarations: [ContractsettingsComponent, InformationsComponent, RecyclingInstructionComponent, DesignComponent, ServicesComponent, SliderComponent, WebhomeComponent, MobilesliderComponent, ServiceinfosComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    ChipsModule,
    AutoCompleteModule,
    ButtonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AngularSvgIconModule.forRoot(),
    ContractsettingsRoutingModule
  ],
  bootstrap:    [ InformationsComponent ]
})
export class ContractsettingsModule { }

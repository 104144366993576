import { UpdatepasswordComponent } from './modules/main/modals/updatepassword/updatepassword.component';
import { NgbModalOptions,NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from './services/user/user.service';
import { Router } from '@angular/router';
import { AuthentificationService } from './services/authentification/authentification.service';
import { AlertService } from './services/alert/alert.service';
import { Alert } from './models/alert/alert';
import { LodingBarService } from './services/lodingbar/loding-bar-service.service';
import { Component } from '@angular/core';
import { LoadingBarService } from '@ngx-loading-bar/core';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    title = 'MontriBO';
    alert: Alert = null;
    load_bar_color = "#f47000";



    constructor(
        private loadingBar: LoadingBarService,
        private lodingBarService : LodingBarService,
        private alertservice :AlertService,
        private authservice: AuthentificationService,
        private userservice: UserService,
        private modalService: NgbModal,
        private router : Router,
    ) {

    }

    ngOnInit(): void {
        this.userservice.user_obs.subscribe((user)=>{
                // if(user != null){
                //     if(user.passwordUpdated == false && user.role=='super_user'){
                //         window.scrollTo(0, 0);
                //         let options: NgbModalOptions = {};
                //         options.centered = true
                //         options.size='lg'
                //         options.backdrop =  'static'
                //         options.keyboard = false
                //         this.modalService.dismissAll()
                //         const modalRef = this.modalService.open(UpdatepasswordComponent,options)

                //     }
                // }
        })
        this.alertservice.alert_status_obs.subscribe((status)=>{
			if (status == true){
				this.alert = null;
                this.alert = this.alertservice.alert.getValue();
                setTimeout(() => {
					this.alert = null;
				}, 3000);
			}
			if (status == false){
				this.alert = null;
			}
        });

        this.lodingBarService.getStatus().subscribe((status)=>{
			if(status == true){
				this.loadingBar.start();
			}

			if(status == false){
				this.loadingBar.complete();
			}
        });
    }

    close() {
        this.alert = null ;
    }
}

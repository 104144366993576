import { ContratService } from './../contrat/contrat.service';
import { AuthentificationService } from './../authentification/authentification.service';
import { Invit, InvitID } from './../../models/invit/invit';
import { Alert } from './../../models/alert/alert';
import { UserBo, userInvited } from './../../models/user/user';
import { BehaviorSubject } from 'rxjs';
import { AlertService } from './../alert/alert.service';
import { LodingBarService } from './../lodingbar/loding-bar-service.service';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import * as firebase from 'firebase';
import { AngularFireStorage } from '@angular/fire/storage';
import { map } from 'rxjs/operators';
import { environment } from './../../../environments/environment';
import { ToastAlertsComponent } from 'src/app/modules/main/components/toast-alerts/toast-alerts.component';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    public user: BehaviorSubject<UserBo> = new BehaviorSubject<UserBo>(null);
    private invits: BehaviorSubject<InvitID[]> = new BehaviorSubject<InvitID[]>(
        null
    );
    public user_obs = this.user.asObservable();
    public invits_obs = this.invits.asObservable();
    private profile_image: BehaviorSubject<any> = new BehaviorSubject<any>(
        null
    );
    public profile_image_obs = this.profile_image.asObservable();
    private uid: string;

    constructor(
        public afs: AngularFirestore, // Inject Firestore service
        public afAuth: AngularFireAuth, // Inject Firebase auth service
        public router: Router,
        private afStorage: AngularFireStorage,
        private lodingBarService: LodingBarService,
        private alertservices: AlertService,
        private contratservice: ContratService,
        private routeur: Router,
        private toastr: ToastAlertsComponent
    ) {
        this.afAuth.authState.subscribe((user_auth) => {
            if (user_auth) {
                this.uid = user_auth.uid;
                if (user_auth.isAnonymous) {
                    return
                }
                const ref = this.afStorage.ref(
                    'Profiles-photos/' + user_auth.uid
                );
                ref.getDownloadURL().subscribe((href) => {
                    this.profile_image.next(href);
                });
                this.userFromDatabase(user_auth.uid).subscribe((data) => {
                    if (data.payload.exists == false && this.user.value == null) {
                        this.afAuth.signOut().then((result) => {
                            this.removeCurrentUser();
                            this.router.navigateByUrl('/user/signin');
                        });
                    } else if (data.payload.exists == false && this.user.value != null){

                    } else {
                        this.user.next(data.payload.data() as UserBo);
                        this.contratservice.loadContract(
                            (data.payload.data() as UserBo).idContrat
                        )
                        this.afs
                            .collection('invitsBo', (ref) =>
                                ref.where(
                                    'sender_email',
                                    '==',
                                    this.user.value.email
                                )
                            )
                            .snapshotChanges()
                            .pipe(
                                map((actions) => {
                                    return actions.map((a) => {
                                        const data = a.payload.doc.data() as Invit;
                                        const id = a.payload.doc.id;
                                        return { id, ...data };
                                    });
                                })
                            )
                            .subscribe((data) => {
                                this.invits.next(data);
                            });
                    }
                });
            } else {
                this.user.next(null);
            }
        });
    }

    userFromDatabase(uid: string) {
        return this.afs.doc('usersBo/' + uid).snapshotChanges();
    }

    setProfileImage(url: string) {
        this.profile_image.next(url);
    }

    updateUser(user: UserBo) {
        var self = this;
        if (user.new_password != '') {
            let credential = firebase.auth.EmailAuthProvider.credential(
                user.email,
                user.old_password
            );

            this.afAuth.authState.subscribe((user_auth) => {
                user_auth
                    .reauthenticateWithCredential(credential)
                    .then(function () {
                        user_auth
                            .updatePassword(user.new_password)
                            .then(function () {
                                user.password = '';
                                user.old_password = '';
                                user.new_password = '';

                                self.afs
                                    .collection('usersBo')
                                    .doc(user_auth.uid as string)
                                    .set(JSON.parse(JSON.stringify(user)))
                                    .then(function () {
                                        self.toastr.openSuccessToast("Votre compte a été mis à jour avec succès","")
                                        self.user.next(user);
                                        self.lodingBarService.setStatus(false);
                                        self.router.navigateByUrl(
                                            '/montri/welcome'
                                        );
                                    })
                                    .catch(function (error) {
                                        self.toastr.openErrorToast("Mise à jour des informations non réussie, réessayer à nouveau","")
                                        self.lodingBarService.setStatus(false);
                                    });
                            });
                    })
                    .catch(function (error) {
                        self.toastr.openFailedToast("Merci de bien renseigner votre ancien mot de passe","")
                        self.lodingBarService.setStatus(false);
                    });
            });
        } else {
            user.password = '';
            user.old_password = '';
            user.new_password = '';

            self.afs
                .collection('usersBo')
                .doc(this.uid as string)
                .set(JSON.parse(JSON.stringify(user)))
                .then(function () {
                    self.toastr.openSuccessToast("Votre compte a été mis à jour avec succès","")
                    self.user.next(user);
                    self.lodingBarService.setStatus(false);
                    self.router.navigateByUrl('/montri/welcome');
                })
                .catch(function (error) {
                    self.toastr.openErrorToast("Mise à jour des informations non réussie, réessayer à nouveau","")
                    self.lodingBarService.setStatus(false);
                });
        }
    }

    removeCurrentUser() {
        this.user.next(null);
        this.profile_image.next(null);
    }

    sendInvit(invit: Invit) {
        this.lodingBarService.setStatus(true);
        var self = this;
        self.afs
            .collection('invitsBo')
            .add(JSON.parse(JSON.stringify(invit)))
            .then(function (docRef) {
                if(invit.sender_email == 'palarcon@uzer.eu' && invit.reciver_role == 'super_user'){
                    self.afs
                    .collection('mail')
                    .add({
                        to: invit.reciver_email,
                        message: {
                            subject: 'Montri - Commencez votre paramétrage',
                            html: `<code>

                            <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
                            <html xmlns="http://www.w3.org/1999/xhtml">
                                <head>
                                    <!-- NAME: 1 COLUMN -->
                                    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
                                    <meta name="viewport" content="width=device-width, initial-scale=1.0">
                                    <title>*|MC:SUBJECT|*</title>

                                <style type="text/css">
                                    body,#bodyTable,#bodyCell{
                                        height:100% !important;
                                        margin:0;
                                        padding:0;
                                        width:100% !important;
                                    }
                                    table{
                                        border-collapse:collapse;
                                    }
                                    img,a img{
                                        border:0;
                                        outline:none;
                                        text-decoration:none;
                                    }
                                    h1,h2,h3,h4,h5,h6{
                                        margin:0;
                                        padding:0;
                                    }
                                    p{
                                        margin:1em 0;
                                        padding:0;
                                    }
                                    a{
                                        word-wrap:break-word;
                                    }
                                    .mcnPreviewText{
                                        display:none !important;
                                    }
                                    .ReadMsgBody{
                                        width:100%;
                                    }
                                    .ExternalClass{
                                        width:100%;
                                    }
                                    .ExternalClass,.ExternalClass p,.ExternalClass span,.ExternalClass font,.ExternalClass td,.ExternalClass div{
                                        line-height:100%;
                                    }
                                    table,td{
                                        mso-table-lspace:0pt;
                                        mso-table-rspace:0pt;
                                    }
                                    #outlook a{
                                        padding:0;
                                    }
                                    img{
                                        -ms-interpolation-mode:bicubic;
                                    }
                                    body,table,td,p,a,li,blockquote{
                                        -ms-text-size-adjust:100%;
                                        -webkit-text-size-adjust:100%;
                                    }
                                    #templatePreheader,#templateHeader,#templateBody,#templateFooter{
                                        min-width:100%;
                                    }
                                    #bodyCell{
                                        padding:20px;
                                    }
                                    .mcnImage,.mcnRetinaImage{
                                        vertical-align:bottom;
                                    }
                                    .mcnTextContent img{
                                        height:auto !important;
                                    }
                                /*
                                @tab Page
                                @section background style
                                @tip Set the background color and top border for your email. You may want to choose colors that match your company's branding.
                                */
                                    body,#bodyTable{
                                        /*@editable*/background-color:#F2F2F2;
                                    }
                                /*
                                @tab Page
                                @section background style
                                @tip Set the background color and top border for your email. You may want to choose colors that match your company's branding.
                                */
                                    #bodyCell{
                                        /*@editable*/border-top:0;
                                    }
                                /*
                                @tab Page
                                @section email border
                                @tip Set the border for your email.
                                */
                                    #templateContainer{
                                        /*@editable*/border:0;
                                    }
                                /*
                                @tab Page
                                @section heading 1
                                @tip Set the styling for all first-level headings in your emails. These should be the largest of your headings.
                                @style heading 1
                                */
                                    h1{
                                        /*@editable*/color:#606060 !important;
                                        display:block;
                                        /*@editable*/font-family:Helvetica;
                                        /*@editable*/font-size:40px;
                                        /*@editable*/font-style:normal;
                                        /*@editable*/font-weight:bold;
                                        /*@editable*/line-height:125%;
                                        /*@editable*/letter-spacing:-1px;
                                        margin:0;
                                        /*@editable*/text-align:left;
                                    }
                                /*
                                @tab Page
                                @section heading 2
                                @tip Set the styling for all second-level headings in your emails.
                                @style heading 2
                                */
                                    h2{
                                        /*@editable*/color:#404040 !important;
                                        display:block;
                                        /*@editable*/font-family:Helvetica;
                                        /*@editable*/font-size:26px;
                                        /*@editable*/font-style:normal;
                                        /*@editable*/font-weight:bold;
                                        /*@editable*/line-height:125%;
                                        /*@editable*/letter-spacing:-.75px;
                                        margin:0;
                                        /*@editable*/text-align:left;
                                    }
                                /*
                                @tab Page
                                @section heading 3
                                @tip Set the styling for all third-level headings in your emails.
                                @style heading 3
                                */
                                    h3{
                                        /*@editable*/color:#606060 !important;
                                        display:block;
                                        /*@editable*/font-family:Helvetica;
                                        /*@editable*/font-size:18px;
                                        /*@editable*/font-style:normal;
                                        /*@editable*/font-weight:bold;
                                        /*@editable*/line-height:125%;
                                        /*@editable*/letter-spacing:-.5px;
                                        margin:0;
                                        /*@editable*/text-align:left;
                                    }
                                /*
                                @tab Page
                                @section heading 4
                                @tip Set the styling for all fourth-level headings in your emails. These should be the smallest of your headings.
                                @style heading 4
                                */
                                    h4{
                                        /*@editable*/color:#808080 !important;
                                        display:block;
                                        /*@editable*/font-family:Helvetica;
                                        /*@editable*/font-size:16px;
                                        /*@editable*/font-style:normal;
                                        /*@editable*/font-weight:bold;
                                        /*@editable*/line-height:125%;
                                        /*@editable*/letter-spacing:normal;
                                        margin:0;
                                        /*@editable*/text-align:left;
                                    }
                                /*
                                @tab Preheader
                                @section preheader style
                                @tip Set the background color and borders for your email's preheader area.
                                */
                                    #templatePreheader{
                                        /*@editable*/background-color:#FFFFFF;
                                        /*@editable*/border-top:0;
                                        /*@editable*/border-bottom:0;
                                    }
                                /*
                                @tab Preheader
                                @section preheader text
                                @tip Set the styling for your email's preheader text. Choose a size and color that is easy to read.
                                */
                                    .preheaderContainer .mcnTextContent,.preheaderContainer .mcnTextContent p{
                                        /*@editable*/color:#606060;
                                        /*@editable*/font-family:Helvetica;
                                        /*@editable*/font-size:11px;
                                        /*@editable*/line-height:125%;
                                        /*@editable*/text-align:left;
                                    }
                                /*
                                @tab Preheader
                                @section preheader link
                                @tip Set the styling for your email's header links. Choose a color that helps them stand out from your text.
                                */
                                    .preheaderContainer .mcnTextContent a{
                                        /*@editable*/color:#606060;
                                        /*@editable*/font-weight:normal;
                                        /*@editable*/text-decoration:underline;
                                    }
                                /*
                                @tab Header
                                @section header style
                                @tip Set the background color and borders for your email's header area.
                                */
                                    #templateHeader{
                                        /*@editable*/background-color:#FFFFFF;
                                        /*@editable*/border-top:0;
                                        /*@editable*/border-bottom:0;
                                    }
                                /*
                                @tab Header
                                @section header text
                                @tip Set the styling for your email's header text. Choose a size and color that is easy to read.
                                */
                                    .headerContainer .mcnTextContent,.headerContainer .mcnTextContent p{
                                        /*@editable*/color:#606060;
                                        /*@editable*/font-family:Helvetica;
                                        /*@editable*/font-size:15px;
                                        /*@editable*/line-height:150%;
                                        /*@editable*/text-align:left;
                                    }
                                /*
                                @tab Header
                                @section header link
                                @tip Set the styling for your email's header links. Choose a color that helps them stand out from your text.
                                */
                                    .headerContainer .mcnTextContent a{
                                        /*@editable*/color:#6DC6DD;
                                        /*@editable*/font-weight:normal;
                                        /*@editable*/text-decoration:underline;
                                    }
                                /*
                                @tab Body
                                @section body style
                                @tip Set the background color and borders for your email's body area.
                                */
                                    #templateBody{
                                        /*@editable*/background-color:#FFFFFF;
                                        /*@editable*/border-top:0;
                                        /*@editable*/border-bottom:0;
                                    }
                                /*
                                @tab Body
                                @section body text
                                @tip Set the styling for your email's body text. Choose a size and color that is easy to read.
                                */
                                    .bodyContainer .mcnTextContent,.bodyContainer .mcnTextContent p{
                                        /*@editable*/color:#606060;
                                        /*@editable*/font-family:Helvetica;
                                        /*@editable*/font-size:15px;
                                        /*@editable*/line-height:150%;
                                        /*@editable*/text-align:left;
                                    }
                                /*
                                @tab Body
                                @section body link
                                @tip Set the styling for your email's body links. Choose a color that helps them stand out from your text.
                                */
                                    .bodyContainer .mcnTextContent a{
                                        /*@editable*/color:#6DC6DD;
                                        /*@editable*/font-weight:normal;
                                        /*@editable*/text-decoration:underline;
                                    }
                                /*
                                @tab Footer
                                @section footer style
                                @tip Set the background color and borders for your email's footer area.
                                */
                                    #templateFooter{
                                        /*@editable*/background-color:#FFFFFF;
                                        /*@editable*/border-top:0;
                                        /*@editable*/border-bottom:0;
                                    }
                                /*
                                @tab Footer
                                @section footer text
                                @tip Set the styling for your email's footer text. Choose a size and color that is easy to read.
                                */
                                    .footerContainer .mcnTextContent,.footerContainer .mcnTextContent p{
                                        /*@editable*/color:#606060;
                                        /*@editable*/font-family:Helvetica;
                                        /*@editable*/font-size:11px;
                                        /*@editable*/line-height:125%;
                                        /*@editable*/text-align:left;
                                    }
                                /*
                                @tab Footer
                                @section footer link
                                @tip Set the styling for your email's footer links. Choose a color that helps them stand out from your text.
                                */
                                    .footerContainer .mcnTextContent a{
                                        /*@editable*/color:#606060;
                                        /*@editable*/font-weight:normal;
                                        /*@editable*/text-decoration:underline;
                                    }
                                @media only screen and (max-width: 480px){
                                    body,table,td,p,a,li,blockquote{
                                        -webkit-text-size-adjust:none !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    body{
                                        width:100% !important;
                                        min-width:100% !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    .mcnRetinaImage{
                                        max-width:100% !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    td[id=bodyCell]{
                                        padding:10px !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    table[class=mcnTextContentContainer]{
                                        width:100% !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    .mcnBoxedTextContentContainer{
                                        max-width:100% !important;
                                        min-width:100% !important;
                                        width:100% !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    table[class=mcpreview-image-uploader]{
                                        width:100% !important;
                                        display:none !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    img[class=mcnImage]{
                                        width:100% !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    table[class=mcnImageGroupContentContainer]{
                                        width:100% !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    td[class=mcnImageGroupContent]{
                                        padding:9px !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    td[class=mcnImageGroupBlockInner]{
                                        padding-bottom:0 !important;
                                        padding-top:0 !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    tbody[class=mcnImageGroupBlockOuter]{
                                        padding-bottom:9px !important;
                                        padding-top:9px !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    table[class=mcnCaptionTopContent],table[class=mcnCaptionBottomContent]{
                                        width:100% !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    table[class=mcnCaptionLeftTextContentContainer],table[class=mcnCaptionRightTextContentContainer],table[class=mcnCaptionLeftImageContentContainer],table[class=mcnCaptionRightImageContentContainer],table[class=mcnImageCardLeftTextContentContainer],table[class=mcnImageCardRightTextContentContainer],.mcnImageCardLeftImageContentContainer,.mcnImageCardRightImageContentContainer{
                                        width:100% !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    td[class=mcnImageCardLeftImageContent],td[class=mcnImageCardRightImageContent]{
                                        padding-right:18px !important;
                                        padding-left:18px !important;
                                        padding-bottom:0 !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    td[class=mcnImageCardBottomImageContent]{
                                        padding-bottom:9px !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    td[class=mcnImageCardTopImageContent]{
                                        padding-top:18px !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    td[class=mcnImageCardLeftImageContent],td[class=mcnImageCardRightImageContent]{
                                        padding-right:18px !important;
                                        padding-left:18px !important;
                                        padding-bottom:0 !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    td[class=mcnImageCardBottomImageContent]{
                                        padding-bottom:9px !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    td[class=mcnImageCardTopImageContent]{
                                        padding-top:18px !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    table[class=mcnCaptionLeftContentOuter] td[class=mcnTextContent],table[class=mcnCaptionRightContentOuter] td[class=mcnTextContent]{
                                        padding-top:9px !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    td[class=mcnCaptionBlockInner] table[class=mcnCaptionTopContent]:last-child td[class=mcnTextContent],.mcnImageCardTopImageContent,.mcnCaptionBottomContent:last-child .mcnCaptionBottomImageContent{
                                        padding-top:18px !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    td[class=mcnBoxedTextContentColumn]{
                                        padding-left:18px !important;
                                        padding-right:18px !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    td[class=mcnTextContent]{
                                        padding-right:18px !important;
                                        padding-left:18px !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                /*
                                @tab Mobile Styles
                                @section template width
                                @tip Make the template fluid for portrait or landscape view adaptability. If a fluid layout doesn't work for you, set the width to 300px instead.
                                */
                                    table[id=templateContainer],table[id=templatePreheader],table[id=templateHeader],table[id=templateBody],table[id=templateFooter]{
                                        /*@tab Mobile Styles
                            @section template width
                            @tip Make the template fluid for portrait or landscape view adaptability. If a fluid layout doesn't work for you, set the width to 300px instead.*/max-width:600px !important;
                                        /*@editable*/width:100% !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                /*
                                @tab Mobile Styles
                                @section heading 1
                                @tip Make the first-level headings larger in size for better readability on small screens.
                                */
                                    h1{
                                        /*@editable*/font-size:24px !important;
                                        /*@editable*/line-height:125% !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                /*
                                @tab Mobile Styles
                                @section heading 2
                                @tip Make the second-level headings larger in size for better readability on small screens.
                                */
                                    h2{
                                        /*@editable*/font-size:20px !important;
                                        /*@editable*/line-height:125% !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                /*
                                @tab Mobile Styles
                                @section heading 3
                                @tip Make the third-level headings larger in size for better readability on small screens.
                                */
                                    h3{
                                        /*@editable*/font-size:18px !important;
                                        /*@editable*/line-height:125% !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                /*
                                @tab Mobile Styles
                                @section heading 4
                                @tip Make the fourth-level headings larger in size for better readability on small screens.
                                */
                                    h4{
                                        /*@editable*/font-size:16px !important;
                                        /*@editable*/line-height:125% !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                /*
                                @tab Mobile Styles
                                @section Boxed Text
                                @tip Make the boxed text larger in size for better readability on small screens. We recommend a font size of at least 16px.
                                */
                                    table[class=mcnBoxedTextContentContainer] td[class=mcnTextContent],td[class=mcnBoxedTextContentContainer] td[class=mcnTextContent] p{
                                        /*@editable*/font-size:18px !important;
                                        /*@editable*/line-height:125% !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                /*
                                @tab Mobile Styles
                                @section Preheader Visibility
                                @tip Set the visibility of the email's preheader on small screens. You can hide it to save space.
                                */
                                    table[id=templatePreheader]{
                                        /*@editable*/display:block !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                /*
                                @tab Mobile Styles
                                @section Preheader Text
                                @tip Make the preheader text larger in size for better readability on small screens.
                                */
                                    td[class=preheaderContainer] td[class=mcnTextContent],td[class=preheaderContainer] td[class=mcnTextContent] p{
                                        /*@editable*/font-size:14px !important;
                                        /*@editable*/line-height:115% !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                /*
                                @tab Mobile Styles
                                @section Header Text
                                @tip Make the header text larger in size for better readability on small screens.
                                */
                                    td[class=headerContainer] td[class=mcnTextContent],td[class=headerContainer] td[class=mcnTextContent] p{
                                        /*@editable*/font-size:18px !important;
                                        /*@editable*/line-height:125% !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                /*
                                @tab Mobile Styles
                                @section Body Text
                                @tip Make the body text larger in size for better readability on small screens. We recommend a font size of at least 16px.
                                */
                                    td[class=bodyContainer] td[class=mcnTextContent],td[class=bodyContainer] td[class=mcnTextContent] p{
                                        /*@editable*/font-size:18px !important;
                                        /*@editable*/line-height:125% !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                /*
                                @tab Mobile Styles
                                @section footer text
                                @tip Make the body content text larger in size for better readability on small screens.
                                */
                                    td[class=footerContainer] td[class=mcnTextContent],td[class=footerContainer] td[class=mcnTextContent] p{
                                        /*@editable*/font-size:14px !important;
                                        /*@editable*/line-height:115% !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    td[class=footerContainer] a[class=utilityLink]{
                                        display:block !important;
                                    }

                            }</style></head>
                                <body leftmargin="0" marginwidth="0" topmargin="0" marginheight="0" offset="0">

                                    <center>
                                        <table align="center" border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable">
                                            <tr>
                                                <td align="center" valign="top" id="bodyCell">
                                                    <!-- BEGIN TEMPLATE // -->
                                                    <table border="0" cellpadding="0" cellspacing="0" width="600" id="templateContainer">
                                                        <tr>
                                                            <td align="center" valign="top">
                                                                <!-- BEGIN PREHEADER // -->
                                                                <table border="0" cellpadding="0" cellspacing="0" width="600" id="templatePreheader">
                                                                    <tr>
                                                                        <td valign="top" class="preheaderContainer" style="padding-top:9px;"><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width:100%;">
                                <tbody class="mcnTextBlockOuter">
                                    <tr>
                                        <td valign="top" class="mcnTextBlockInner" style="padding-top:9px;">
                                              <!--[if mso]>
                                            <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                                            <tr>
                                            <![endif]-->

                                            <!--[if mso]>
                                            <td valign="top" width="390" style="width:390px;">
                                            <![endif]-->
                                            <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width:390px;" width="100%" class="mcnTextContentContainer">
                                                <tbody><tr>

                                                    <td valign="top" class="mcnTextContent" style="padding-top:0; padding-left:18px; padding-bottom:9px; padding-right:18px;">


                                                    </td>
                                                </tr>
                                            </tbody></table>
                                            <!--[if mso]>
                                            </td>
                                            <![endif]-->

                                            <!--[if mso]>
                                            <td valign="top" width="210" style="width:210px;">
                                            <![endif]-->

                                            <!--[if mso]>
                                            </td>
                                            <![endif]-->

                                            <!--[if mso]>
                                            </tr>
                                            </table>
                                            <![endif]-->
                                        </td>
                                    </tr>
                                </tbody>
                            </table></td>
                                                                    </tr>
                                                                </table>
                                                                <!-- // END PREHEADER -->
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td align="center" valign="top">
                                                                <!-- BEGIN HEADER // -->
                                                                <table border="0" cellpadding="0" cellspacing="0" width="600" id="templateHeader">
                                                                    <tr>
                                                                        <td valign="top" class="headerContainer"><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageBlock" style="min-width:100%;">
                                <tbody class="mcnImageBlockOuter">
                                        <tr>
                                            <td valign="top" style="padding:9px" class="mcnImageBlockInner">
                                                <table align="left" width="100%" border="0" cellpadding="0" cellspacing="0" class="mcnImageContentContainer" style="min-width:100%;">
                                                    <tbody><tr>
                                                        <td class="mcnImageContent" valign="top" style="padding-right: 9px; padding-left: 9px; padding-top: 0; padding-bottom: 0;">

                                                                <a href="${environment.base_url}" title="" class="" target="_blank">
                                                                    <img align="left" alt="" src="https://mcusercontent.com/a24798abd1508f12878dc615c/images/4ae1a36e-18f0-4829-812c-323e39c1d993.png" width="564" style="max-width:900px; padding-bottom: 0; display: inline !important; vertical-align: bottom;" class="mcnImage">
                                                                </a>

                                                        </td>
                                                    </tr>
                                                </tbody></table>
                                            </td>
                                        </tr>
                                </tbody>
                            </table></td>
                                                                    </tr>
                                                                </table>
                                                                <!-- // END HEADER -->
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td align="center" valign="top">
                                                                <!-- BEGIN BODY // -->
                                                                <table border="0" cellpadding="0" cellspacing="0" width="600" id="templateBody">
                                                                    <tr>
                                                                        <td valign="top" class="bodyContainer"><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width:100%;">
                                <tbody class="mcnTextBlockOuter">
                                    <tr>
                                        <td valign="top" class="mcnTextBlockInner" style="padding-top:9px;">
                                              <!--[if mso]>
                                            <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                                            <tr>
                                            <![endif]-->

                                            <!--[if mso]>
                                            <td valign="top" width="600" style="width:600px;">
                                            <![endif]-->
                                            <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width:100%; min-width:100%;" width="100%" class="mcnTextContentContainer">
                                                <tbody><tr>

                                                    <td valign="top" class="mcnTextContent" style="padding-top:0; padding-right:18px; padding-bottom:9px; padding-left:18px;">

                                                        <div style="text-align: center;">
                            <div style="text-align: left;"><span style="font-size:30px"><span style="font-family:tahoma,verdana,segoe,sans-serif"><span style="color:#2f2e31"><strong>Bonjour,</strong></span></span></span><br>
                            &nbsp;
                            <p><span style="font-size:15px"><span style="color:#000000"><span style="font-family:tahoma,verdana,segoe,sans-serif">Je suis Paul, Chargé de la Satisfaction Client chez Uzer, et je suis ravi de faire votre connaissance.</span></span></span></p>

                            <p><span style="font-size:15px"><span style="color:#000000"><span style="font-family:tahoma,verdana,segoe,sans-serif">Vous pouvez dès maintenant commencer le paramétrage de votre application Montri.<br>
                            <br>
                            Accédez à votre espace Client, copiez le code ci-dessous et collez-le&nbsp;dans le champs&nbsp;<strong>"Code de l'invitation"</strong>&nbsp;pour créer votre compte et commencer votre&nbsp;paramétrage.</span></span></span></p>
                            </div>
                            </div>

                                                    </td>
                                                </tr>
                                            </tbody></table>
                                            <!--[if mso]>
                                            </td>
                                            <![endif]-->

                                            <!--[if mso]>
                                            </tr>
                                            </table>
                                            <![endif]-->
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnBoxedTextBlock" style="min-width:100%;">
                                <!--[if gte mso 9]>
                                <table align="center" border="0" cellspacing="0" cellpadding="0" width="100%">
                                <![endif]-->
                                <tbody class="mcnBoxedTextBlockOuter">
                                    <tr>
                                        <td valign="top" class="mcnBoxedTextBlockInner">

                                            <!--[if gte mso 9]>
                                            <td align="center" valign="top" ">
                                            <![endif]-->
                                            <table align="left" border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width:100%;" class="mcnBoxedTextContentContainer">
                                                <tbody><tr>

                                                    <td style="padding-top:9px; padding-left:18px; padding-bottom:9px; padding-right:18px;">

                                                        <table border="0" cellspacing="0" class="mcnTextContentContainer" width="100%" style="min-width: 100% !important;background-color: #FFEECB;">
                                                            <tbody><tr>
                                                                <td valign="top" class="mcnTextContent" style="padding: 18px;color: #000000;font-family: Helvetica;font-size: 22px;font-weight: normal;text-align: center;">
                                                                    <span style="font-size:22px"><span style="font-family:tahoma,verdana,segoe,sans-serif">${docRef.id}</span></span>
                                                                </td>
                                                            </tr>
                                                        </tbody></table>
                                                    </td>
                                                </tr>
                                            </tbody></table>
                                            <!--[if gte mso 9]>
                                            </td>
                                            <![endif]-->

                                            <!--[if gte mso 9]>
                                            </tr>
                                            </table>
                                            <![endif]-->
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnButtonBlock" style="min-width:100%;">
                                <tbody class="mcnButtonBlockOuter">
                                    <tr>
                                        <td style="padding-top:0; padding-right:18px; padding-bottom:18px; padding-left:18px;" valign="top" align="center" class="mcnButtonBlockInner">
                                            <table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnButtonContentContainer" style="border-collapse: separate !important;border-radius: 6px;background-color: #FFC953;">
                                                <tbody>
                                                    <tr>
                                                        <td align="center" valign="middle" class="mcnButtonContent" style="font-family: Tahoma, Verdana, Segoe, sans-serif; font-size: 24px; padding: 20px;">
                                                            <a class="mcnButton " title="Mon espace Client" href="${environment.base_url}/user/signup" target="_blank" style="font-weight: bold;letter-spacing: normal;line-height: 100%;text-align: center;text-decoration: none;color: #FFFFFF;">Mon espace Client</a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageBlock" style="min-width:100%;">
                                <tbody class="mcnImageBlockOuter">
                                        <tr>
                                            <td valign="top" style="padding:9px" class="mcnImageBlockInner">
                                                <table align="left" width="100%" border="0" cellpadding="0" cellspacing="0" class="mcnImageContentContainer" style="min-width:100%;">
                                                    <tbody><tr>
                                                        <td class="mcnImageContent" valign="top" style="padding-right: 9px; padding-left: 9px; padding-top: 0; padding-bottom: 0; text-align:center;">


                                                                    <img align="center" alt="" src="https://mcusercontent.com/a24798abd1508f12878dc615c/images/d059d44b-3d91-432a-9773-a50604c25529.png" width="564" style="max-width:900px; padding-bottom: 0; display: inline !important; vertical-align: bottom;" class="mcnImage">


                                                        </td>
                                                    </tr>
                                                </tbody></table>
                                            </td>
                                        </tr>
                                </tbody>
                            </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width:100%;">
                                <tbody class="mcnTextBlockOuter">
                                    <tr>
                                        <td valign="top" class="mcnTextBlockInner" style="padding-top:9px;">
                                            <!--[if mso]>
                                            <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                                            <tr>
                                            <![endif]-->

                                            <!--[if mso]>
                                            <td valign="top" width="600" style="width:600px;">
                                            <![endif]-->
                                            <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width:100%; min-width:100%;" width="100%" class="mcnTextContentContainer">
                                                <tbody><tr>

                                                    <td valign="top" class="mcnTextContent" style="padding-top:0; padding-right:18px; padding-bottom:9px; padding-left:18px;">

                                                        <div style="text-align: center;">
                            <div style="text-align: left;">
                            <p><span style="font-size:20px"><span style="font-family:tahoma,verdana,segoe,sans-serif"><font color="#2f2e31"><strong>Par où commencer ?</strong></font></span></span></p>
                            </div>
                            </div>

                                                    </td>
                                                </tr>
                                            </tbody></table>
                                            <!--[if mso]>
                                            </td>
                                            <![endif]-->

                                            <!--[if mso]>
                                            </tr>
                                            </table>
                                            <![endif]-->
                                        </td>
                                    </tr>
                                </tbody>
                            </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnCaptionBlock">
                                <tbody class="mcnCaptionBlockOuter">
                                    <tr>
                                        <td class="mcnCaptionBlockInner" valign="top" style="padding:9px;">


                            <table align="left" border="0" cellpadding="0" cellspacing="0" class="mcnCaptionBottomContent">
                                <tbody><tr>
                                    <td class="mcnCaptionBottomImageContent" align="left" valign="top" style="padding:0 9px 9px 9px;">



                                        <img alt="" src="https://mcusercontent.com/a24798abd1508f12878dc615c/images/afd926fe-d712-40c2-b61e-bfb1de7cd710.png" width="50" style="max-width:50px;" class="mcnImage">


                                    </td>
                                </tr>
                                <tr>
                                    <td class="mcnTextContent" valign="top" style="padding:0 9px 0 9px;" width="564">
                                        <p style="text-align: left;"><span style="font-size:15px"><span style="font-family:tahoma,verdana,segoe,sans-serif"><a href="https://www.dropbox.com/s/y78tck45g3rv1hn/Documentation%20Back-Office%20Montri%20V1.5.pdf?dl=0" target="_blank">Téléchargez notre guide</a></span><span style="color:#000000"><span style="font-family:tahoma,verdana,segoe,sans-serif"> et accédez à votre espace et configurez votre compte</span></span></span></p>

                                    </td>
                                </tr>
                            </tbody></table>





                                        </td>
                                    </tr>
                                </tbody>
                            </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnCaptionBlock">
                                <tbody class="mcnCaptionBlockOuter">
                                    <tr>
                                        <td class="mcnCaptionBlockInner" valign="top" style="padding:9px;">


                            <table align="left" border="0" cellpadding="0" cellspacing="0" class="mcnCaptionBottomContent">
                                <tbody><tr>
                                    <td class="mcnCaptionBottomImageContent" align="left" valign="top" style="padding:0 9px 9px 9px;">



                                        <img alt="" src="https://mcusercontent.com/a24798abd1508f12878dc615c/images/c9793e37-5c1a-4528-b447-5447625139a0.png" width="50" style="max-width:50px;" class="mcnImage">


                                    </td>
                                </tr>
                                <tr>
                                    <td class="mcnTextContent" valign="top" style="padding:0 9px 0 9px;" width="564">
                                        <p style="text-align: left;"><span style="font-size:15px"><span style="font-family:tahoma,verdana,segoe,sans-serif"><span style="color:#000000">Invitez vos collaborateurs à rejoindre votre espace pour les laisser contribuer au paramétrage</span></span></span></p>

                                    </td>
                                </tr>
                            </tbody></table>





                                        </td>
                                    </tr>
                                </tbody>
                            </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnCaptionBlock">
                                <tbody class="mcnCaptionBlockOuter">
                                    <tr>
                                        <td class="mcnCaptionBlockInner" valign="top" style="padding:9px;">


                            <table align="left" border="0" cellpadding="0" cellspacing="0" class="mcnCaptionBottomContent">
                                <tbody><tr>
                                    <td class="mcnCaptionBottomImageContent" align="left" valign="top" style="padding:0 9px 9px 9px;">



                                        <img alt="" src="https://mcusercontent.com/a24798abd1508f12878dc615c/images/05019f46-9ce1-4766-8166-7ad543d4b362.png" width="50" style="max-width:50px;" class="mcnImage">


                                    </td>
                                </tr>
                                <tr>
                                    <td class="mcnTextContent" valign="top" style="padding:0 9px 0 9px;" width="564">
                                        <p style="text-align: left;"><span style="font-size:15px"><span style="color:#000000"><span style="font-family:tahoma,verdana,segoe,sans-serif">Paramétrez le design et les services de votre application et visualisez vos modifications dans l'appli</span></span></span></p>

                                    </td>
                                </tr>
                            </tbody></table>





                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageBlock" style="min-width:100%;">
                                <tbody class="mcnImageBlockOuter">
                                        <tr>
                                            <td valign="top" style="padding:9px" class="mcnImageBlockInner">
                                                <table align="left" width="100%" border="0" cellpadding="0" cellspacing="0" class="mcnImageContentContainer" style="min-width:100%;">
                                                    <tbody><tr>
                                                        <td class="mcnImageContent" valign="top" style="padding-right: 9px; padding-left: 9px; padding-top: 0; padding-bottom: 0; text-align:center;">


                                                                    <img align="center" alt="" src="https://mcusercontent.com/a24798abd1508f12878dc615c/images/d059d44b-3d91-432a-9773-a50604c25529.png" width="564" style="max-width:900px; padding-bottom: 0; display: inline !important; vertical-align: bottom;" class="mcnImage">


                                                        </td>
                                                    </tr>
                                                </tbody></table>
                                            </td>
                                        </tr>
                                </tbody>
                            </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width:100%;">
                                <tbody class="mcnTextBlockOuter">
                                    <tr>
                                        <td valign="top" class="mcnTextBlockInner" style="padding-top:9px;">
                                              <!--[if mso]>
                                            <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                                            <tr>
                                            <![endif]-->

                                            <!--[if mso]>
                                            <td valign="top" width="600" style="width:600px;">
                                            <![endif]-->
                                            <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width:100%; min-width:100%;" width="100%" class="mcnTextContentContainer">
                                                <tbody><tr>

                                                    <td valign="top" class="mcnTextContent" style="padding-top:0; padding-right:18px; padding-bottom:9px; padding-left:18px;">

                                                        <div style="text-align: center;">
                            <div style="text-align: left;">
                            <p><span style="font-size:20px"><span style="font-family:tahoma,verdana,segoe,sans-serif"><font color="#2f2e31"><strong>Comment visualiser vos modifications ?</strong></font></span></span><br>
                            <br>
                            <span style="font-size:15px"><span style="font-family:tahoma,verdana,segoe,sans-serif"><span style="color:#000000">Visualisez&nbsp;le rendu de votre application à tout moment sur votre téléphone en téléchargeant la version démo de l'application :</span></span></span></p>

                            <ul>
                                <li><span style="font-size:15px"><span style="font-family:tahoma,verdana,segoe,sans-serif"><span style="color:#000000">iOS (iPhone) : </span><a href="https://testflight.apple.com/join/vTivHyVn" target="_blank"><span style="color:#0000FF">https://testflight.apple.com/join/vTivHyVn</span></a></span></span></li>
                                <li><span style="font-size:15px"><span style="font-family:tahoma,verdana,segoe,sans-serif"><span style="color:#000000">Android : </span><a href="https://appdistribution.firebase.dev/i/1fa299dc06f41ee7" target="_blank"><span style="color:#0000FF">https://appdistribution.firebase.dev/i/1fa299dc06f41ee7</span></a></span></span></li>
                                <li><span style="font-size:15px"><span style="font-family:tahoma,verdana,segoe,sans-serif"><span style="color:#000000">Application web :</span> <a href="https://preprod.montri.fr/" target="_blank"><span style="color:#0000FF">https://preprod.montri.fr/</span></a></span></span></li>
                            </ul>
                            </div>
                            </div>

                                                    </td>
                                                </tr>
                                            </tbody></table>
                                            <!--[if mso]>
                                            </td>
                                            <![endif]-->

                                            <!--[if mso]>
                                            </tr>
                                            </table>
                                            <![endif]-->
                                        </td>
                                    </tr>
                                </tbody>
                            </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnDividerBlock" style="min-width:100%;">
                                <tbody class="mcnDividerBlockOuter">
                                    <tr>
                                        <td class="mcnDividerBlockInner" style="min-width: 100%; padding: 20px 18px;">
                                            <table class="mcnDividerContent" border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width: 100%;border-top: 3px solid #FFC953;">
                                                <tbody><tr>
                                                    <td>
                                                        <span></span>
                                                    </td>
                                                </tr>
                                            </tbody></table>
                            <!--
                                            <td class="mcnDividerBlockInner" style="padding: 18px;">
                                            <hr class="mcnDividerContent" style="border-bottom-color:none; border-left-color:none; border-right-color:none; border-bottom-width:0; border-left-width:0; border-right-width:0; margin-top:0; margin-right:0; margin-bottom:0; margin-left:0;" />
                            -->
                                        </td>
                                    </tr>
                                </tbody>
                            </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width:100%;">
                                <tbody class="mcnTextBlockOuter">
                                    <tr>
                                        <td valign="top" class="mcnTextBlockInner" style="padding-top:9px;">
                                              <!--[if mso]>
                                            <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                                            <tr>
                                            <![endif]-->

                                            <!--[if mso]>
                                            <td valign="top" width="600" style="width:600px;">
                                            <![endif]-->
                                            <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width:100%; min-width:100%;" width="100%" class="mcnTextContentContainer">
                                                <tbody><tr>

                                                    <td valign="top" class="mcnTextContent" style="padding-top:0; padding-right:18px; padding-bottom:9px; padding-left:18px;">

                                                        <div style="text-align: left;"><span style="color:#000000"><span style="font-size:15px"><span style="font-family:verdana,geneva,sans-serif">N'hésitez pas à me contacter pour toutes questions concernant nos outils et l'utilisation de nos services.<br>
                            <br>
                            Bien cordialement,<br>
                            Paul</span></span></span></div>

                                                    </td>
                                                </tr>
                                            </tbody></table>
                                            <!--[if mso]>
                                            </td>
                                            <![endif]-->

                                            <!--[if mso]>
                                            </tr>
                                            </table>
                                            <![endif]-->
                                        </td>
                                    </tr>
                                </tbody>
                            </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageBlock" style="min-width:100%;">
                                <tbody class="mcnImageBlockOuter">
                                        <tr>
                                            <td valign="top" style="padding:9px" class="mcnImageBlockInner">
                                                <table align="left" width="100%" border="0" cellpadding="0" cellspacing="0" class="mcnImageContentContainer" style="min-width:100%;">
                                                    <tbody><tr>
                                                        <td class="mcnImageContent" valign="top" style="padding-right: 9px; padding-left: 9px; padding-top: 0; padding-bottom: 0;">


                                                                    <img align="left" alt="" src="https://mcusercontent.com/a24798abd1508f12878dc615c/images/0f821d07-fc33-45af-99be-982e21640a89.png" width="100" style="max-width:100px; padding-bottom: 0; display: inline !important; vertical-align: bottom;" class="mcnImage">


                                                        </td>
                                                    </tr>
                                                </tbody></table>
                                            </td>
                                        </tr>
                                </tbody>
                            </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width:100%;">
                                <tbody class="mcnTextBlockOuter">
                                    <tr>
                                        <td valign="top" class="mcnTextBlockInner" style="padding-top:9px;">
                                              <!--[if mso]>
                                            <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                                            <tr>
                                            <![endif]-->

                                            <!--[if mso]>
                                            <td valign="top" width="600" style="width:600px;">
                                            <![endif]-->
                                            <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width:100%; min-width:100%;" width="100%" class="mcnTextContentContainer">
                                                <tbody><tr>

                                                    <td valign="top" class="mcnTextContent" style="padding-top:0; padding-right:18px; padding-bottom:9px; padding-left:18px;">

                                                        <div style="text-align: left;"><span style="font-size:12px"><span style="color:#000000"><span style="font-family:verdana,geneva,sans-serif">Mob.: </span></span><span style="color:#0000FF"><span style="font-family:verdana,geneva,sans-serif">06 58 16 55 08</span></span><br>
                            <span style="color:#000000"><span style="font-family:verdana,geneva,sans-serif">Email : </span></span><a href="mailto:palarcon@uzer.eu?subject=J'ai%20besoin%20d'aide" target="_blank"><span style="color:#0000FF"><span style="font-family:verdana,geneva,sans-serif">palarcon@uzer.eu</span></span></a></span></div>

                                                    </td>
                                                </tr>
                                            </tbody></table>
                                            <!--[if mso]>
                                            </td>
                                            <![endif]-->

                                            <!--[if mso]>
                                            </tr>
                                            </table>
                                            <![endif]-->
                                        </td>
                                    </tr>
                                </tbody>
                            </table></td>
                                                                    </tr>
                                                                </table>
                                                                <!-- // END BODY -->
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td align="center" valign="top">
                                                                <!-- BEGIN FOOTER // -->
                                                                <table border="0" cellpadding="0" cellspacing="0" width="600" id="templateFooter">
                                                                    <tr>
                                                                        <td valign="top" class="footerContainer" style="padding-bottom:9px;"></td>
                                                                    </tr>
                                                                </table>
                                                                <!-- // END FOOTER -->
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    <!-- // END TEMPLATE -->
                                                </td>
                                            </tr>
                                        </table>
                                    </center>
                                </body>
                            </html>


                                </code>`,
                        },
                    })
                    .then(function (docRef) {
                        self.toastr.openSuccessToast("Votre invitation a été bien envoyée","")
                        self.lodingBarService.setStatus(false);
                    })
                    .catch(function (error) {
                        self.toastr.openErrorToast("Envoi d'invitation non réussi réssayer de nouveau","")
                        self.lodingBarService.setStatus(false);
                    });
                }
                else{
                self.afs
                    .collection('mail')
                    .add({
                        to: invit.reciver_email,
                        message: {
                            subject: 'Montri - Commencez votre paramétrage',
                            html: `<code>
                            <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
                            <html xmlns="http://www.w3.org/1999/xhtml">
                                <head>
                                    <!-- NAME: 1 COLUMN -->
                                    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
                                    <meta name="viewport" content="width=device-width, initial-scale=1.0">
                                    <title>*|MC:SUBJECT|*</title>

                                <style type="text/css">
                                    body,#bodyTable,#bodyCell{
                                        height:100% !important;
                                        margin:0;
                                        padding:0;
                                        width:100% !important;
                                    }
                                    table{
                                        border-collapse:collapse;
                                    }
                                    img,a img{
                                        border:0;
                                        outline:none;
                                        text-decoration:none;
                                    }
                                    h1,h2,h3,h4,h5,h6{
                                        margin:0;
                                        padding:0;
                                    }
                                    p{
                                        margin:1em 0;
                                        padding:0;
                                    }
                                    a{
                                        word-wrap:break-word;
                                    }
                                    .mcnPreviewText{
                                        display:none !important;
                                    }
                                    .ReadMsgBody{
                                        width:100%;
                                    }
                                    .ExternalClass{
                                        width:100%;
                                    }
                                    .ExternalClass,.ExternalClass p,.ExternalClass span,.ExternalClass font,.ExternalClass td,.ExternalClass div{
                                        line-height:100%;
                                    }
                                    table,td{
                                        mso-table-lspace:0pt;
                                        mso-table-rspace:0pt;
                                    }
                                    #outlook a{
                                        padding:0;
                                    }
                                    img{
                                        -ms-interpolation-mode:bicubic;
                                    }
                                    body,table,td,p,a,li,blockquote{
                                        -ms-text-size-adjust:100%;
                                        -webkit-text-size-adjust:100%;
                                    }
                                    #templatePreheader,#templateHeader,#templateBody,#templateFooter{
                                        min-width:100%;
                                    }
                                    #bodyCell{
                                        padding:20px;
                                    }
                                    .mcnImage,.mcnRetinaImage{
                                        vertical-align:bottom;
                                    }
                                    .mcnTextContent img{
                                        height:auto !important;
                                    }
                                /*
                                @tab Page
                                @section background style
                                @tip Set the background color and top border for your email. You may want to choose colors that match your company's branding.
                                */
                                    body,#bodyTable{
                                        /*@editable*/background-color:#F2F2F2;
                                    }
                                /*
                                @tab Page
                                @section background style
                                @tip Set the background color and top border for your email. You may want to choose colors that match your company's branding.
                                */
                                    #bodyCell{
                                        /*@editable*/border-top:0;
                                    }
                                /*
                                @tab Page
                                @section email border
                                @tip Set the border for your email.
                                */
                                    #templateContainer{
                                        /*@editable*/border:0;
                                    }
                                /*
                                @tab Page
                                @section heading 1
                                @tip Set the styling for all first-level headings in your emails. These should be the largest of your headings.
                                @style heading 1
                                */
                                    h1{
                                        /*@editable*/color:#606060 !important;
                                        display:block;
                                        /*@editable*/font-family:Helvetica;
                                        /*@editable*/font-size:40px;
                                        /*@editable*/font-style:normal;
                                        /*@editable*/font-weight:bold;
                                        /*@editable*/line-height:125%;
                                        /*@editable*/letter-spacing:-1px;
                                        margin:0;
                                        /*@editable*/text-align:left;
                                    }
                                /*
                                @tab Page
                                @section heading 2
                                @tip Set the styling for all second-level headings in your emails.
                                @style heading 2
                                */
                                    h2{
                                        /*@editable*/color:#404040 !important;
                                        display:block;
                                        /*@editable*/font-family:Helvetica;
                                        /*@editable*/font-size:26px;
                                        /*@editable*/font-style:normal;
                                        /*@editable*/font-weight:bold;
                                        /*@editable*/line-height:125%;
                                        /*@editable*/letter-spacing:-.75px;
                                        margin:0;
                                        /*@editable*/text-align:left;
                                    }
                                /*
                                @tab Page
                                @section heading 3
                                @tip Set the styling for all third-level headings in your emails.
                                @style heading 3
                                */
                                    h3{
                                        /*@editable*/color:#606060 !important;
                                        display:block;
                                        /*@editable*/font-family:Helvetica;
                                        /*@editable*/font-size:18px;
                                        /*@editable*/font-style:normal;
                                        /*@editable*/font-weight:bold;
                                        /*@editable*/line-height:125%;
                                        /*@editable*/letter-spacing:-.5px;
                                        margin:0;
                                        /*@editable*/text-align:left;
                                    }
                                /*
                                @tab Page
                                @section heading 4
                                @tip Set the styling for all fourth-level headings in your emails. These should be the smallest of your headings.
                                @style heading 4
                                */
                                    h4{
                                        /*@editable*/color:#808080 !important;
                                        display:block;
                                        /*@editable*/font-family:Helvetica;
                                        /*@editable*/font-size:16px;
                                        /*@editable*/font-style:normal;
                                        /*@editable*/font-weight:bold;
                                        /*@editable*/line-height:125%;
                                        /*@editable*/letter-spacing:normal;
                                        margin:0;
                                        /*@editable*/text-align:left;
                                    }
                                /*
                                @tab Preheader
                                @section preheader style
                                @tip Set the background color and borders for your email's preheader area.
                                */
                                    #templatePreheader{
                                        /*@editable*/background-color:#FFFFFF;
                                        /*@editable*/border-top:0;
                                        /*@editable*/border-bottom:0;
                                    }
                                /*
                                @tab Preheader
                                @section preheader text
                                @tip Set the styling for your email's preheader text. Choose a size and color that is easy to read.
                                */
                                    .preheaderContainer .mcnTextContent,.preheaderContainer .mcnTextContent p{
                                        /*@editable*/color:#606060;
                                        /*@editable*/font-family:Helvetica;
                                        /*@editable*/font-size:11px;
                                        /*@editable*/line-height:125%;
                                        /*@editable*/text-align:left;
                                    }
                                /*
                                @tab Preheader
                                @section preheader link
                                @tip Set the styling for your email's header links. Choose a color that helps them stand out from your text.
                                */
                                    .preheaderContainer .mcnTextContent a{
                                        /*@editable*/color:#606060;
                                        /*@editable*/font-weight:normal;
                                        /*@editable*/text-decoration:underline;
                                    }
                                /*
                                @tab Header
                                @section header style
                                @tip Set the background color and borders for your email's header area.
                                */
                                    #templateHeader{
                                        /*@editable*/background-color:#FFFFFF;
                                        /*@editable*/border-top:0;
                                        /*@editable*/border-bottom:0;
                                    }
                                /*
                                @tab Header
                                @section header text
                                @tip Set the styling for your email's header text. Choose a size and color that is easy to read.
                                */
                                    .headerContainer .mcnTextContent,.headerContainer .mcnTextContent p{
                                        /*@editable*/color:#606060;
                                        /*@editable*/font-family:Helvetica;
                                        /*@editable*/font-size:15px;
                                        /*@editable*/line-height:150%;
                                        /*@editable*/text-align:left;
                                    }
                                /*
                                @tab Header
                                @section header link
                                @tip Set the styling for your email's header links. Choose a color that helps them stand out from your text.
                                */
                                    .headerContainer .mcnTextContent a{
                                        /*@editable*/color:#6DC6DD;
                                        /*@editable*/font-weight:normal;
                                        /*@editable*/text-decoration:underline;
                                    }
                                /*
                                @tab Body
                                @section body style
                                @tip Set the background color and borders for your email's body area.
                                */
                                    #templateBody{
                                        /*@editable*/background-color:#FFFFFF;
                                        /*@editable*/border-top:0;
                                        /*@editable*/border-bottom:0;
                                    }
                                /*
                                @tab Body
                                @section body text
                                @tip Set the styling for your email's body text. Choose a size and color that is easy to read.
                                */
                                    .bodyContainer .mcnTextContent,.bodyContainer .mcnTextContent p{
                                        /*@editable*/color:#606060;
                                        /*@editable*/font-family:Helvetica;
                                        /*@editable*/font-size:15px;
                                        /*@editable*/line-height:150%;
                                        /*@editable*/text-align:left;
                                    }
                                /*
                                @tab Body
                                @section body link
                                @tip Set the styling for your email's body links. Choose a color that helps them stand out from your text.
                                */
                                    .bodyContainer .mcnTextContent a{
                                        /*@editable*/color:#6DC6DD;
                                        /*@editable*/font-weight:normal;
                                        /*@editable*/text-decoration:underline;
                                    }
                                /*
                                @tab Footer
                                @section footer style
                                @tip Set the background color and borders for your email's footer area.
                                */
                                    #templateFooter{
                                        /*@editable*/background-color:#FFFFFF;
                                        /*@editable*/border-top:0;
                                        /*@editable*/border-bottom:0;
                                    }
                                /*
                                @tab Footer
                                @section footer text
                                @tip Set the styling for your email's footer text. Choose a size and color that is easy to read.
                                */
                                    .footerContainer .mcnTextContent,.footerContainer .mcnTextContent p{
                                        /*@editable*/color:#606060;
                                        /*@editable*/font-family:Helvetica;
                                        /*@editable*/font-size:11px;
                                        /*@editable*/line-height:125%;
                                        /*@editable*/text-align:left;
                                    }
                                /*
                                @tab Footer
                                @section footer link
                                @tip Set the styling for your email's footer links. Choose a color that helps them stand out from your text.
                                */
                                    .footerContainer .mcnTextContent a{
                                        /*@editable*/color:#606060;
                                        /*@editable*/font-weight:normal;
                                        /*@editable*/text-decoration:underline;
                                    }
                                @media only screen and (max-width: 480px){
                                    body,table,td,p,a,li,blockquote{
                                        -webkit-text-size-adjust:none !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    body{
                                        width:100% !important;
                                        min-width:100% !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    .mcnRetinaImage{
                                        max-width:100% !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    td[id=bodyCell]{
                                        padding:10px !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    table[class=mcnTextContentContainer]{
                                        width:100% !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    .mcnBoxedTextContentContainer{
                                        max-width:100% !important;
                                        min-width:100% !important;
                                        width:100% !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    table[class=mcpreview-image-uploader]{
                                        width:100% !important;
                                        display:none !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    img[class=mcnImage]{
                                        width:100% !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    table[class=mcnImageGroupContentContainer]{
                                        width:100% !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    td[class=mcnImageGroupContent]{
                                        padding:9px !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    td[class=mcnImageGroupBlockInner]{
                                        padding-bottom:0 !important;
                                        padding-top:0 !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    tbody[class=mcnImageGroupBlockOuter]{
                                        padding-bottom:9px !important;
                                        padding-top:9px !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    table[class=mcnCaptionTopContent],table[class=mcnCaptionBottomContent]{
                                        width:100% !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    table[class=mcnCaptionLeftTextContentContainer],table[class=mcnCaptionRightTextContentContainer],table[class=mcnCaptionLeftImageContentContainer],table[class=mcnCaptionRightImageContentContainer],table[class=mcnImageCardLeftTextContentContainer],table[class=mcnImageCardRightTextContentContainer],.mcnImageCardLeftImageContentContainer,.mcnImageCardRightImageContentContainer{
                                        width:100% !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    td[class=mcnImageCardLeftImageContent],td[class=mcnImageCardRightImageContent]{
                                        padding-right:18px !important;
                                        padding-left:18px !important;
                                        padding-bottom:0 !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    td[class=mcnImageCardBottomImageContent]{
                                        padding-bottom:9px !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    td[class=mcnImageCardTopImageContent]{
                                        padding-top:18px !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    td[class=mcnImageCardLeftImageContent],td[class=mcnImageCardRightImageContent]{
                                        padding-right:18px !important;
                                        padding-left:18px !important;
                                        padding-bottom:0 !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    td[class=mcnImageCardBottomImageContent]{
                                        padding-bottom:9px !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    td[class=mcnImageCardTopImageContent]{
                                        padding-top:18px !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    table[class=mcnCaptionLeftContentOuter] td[class=mcnTextContent],table[class=mcnCaptionRightContentOuter] td[class=mcnTextContent]{
                                        padding-top:9px !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    td[class=mcnCaptionBlockInner] table[class=mcnCaptionTopContent]:last-child td[class=mcnTextContent],.mcnImageCardTopImageContent,.mcnCaptionBottomContent:last-child .mcnCaptionBottomImageContent{
                                        padding-top:18px !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    td[class=mcnBoxedTextContentColumn]{
                                        padding-left:18px !important;
                                        padding-right:18px !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    td[class=mcnTextContent]{
                                        padding-right:18px !important;
                                        padding-left:18px !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                /*
                                @tab Mobile Styles
                                @section template width
                                @tip Make the template fluid for portrait or landscape view adaptability. If a fluid layout doesn't work for you, set the width to 300px instead.
                                */
                                    table[id=templateContainer],table[id=templatePreheader],table[id=templateHeader],table[id=templateBody],table[id=templateFooter]{
                                        /*@tab Mobile Styles
                            @section template width
                            @tip Make the template fluid for portrait or landscape view adaptability. If a fluid layout doesn't work for you, set the width to 300px instead.*/max-width:600px !important;
                                        /*@editable*/width:100% !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                /*
                                @tab Mobile Styles
                                @section heading 1
                                @tip Make the first-level headings larger in size for better readability on small screens.
                                */
                                    h1{
                                        /*@editable*/font-size:24px !important;
                                        /*@editable*/line-height:125% !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                /*
                                @tab Mobile Styles
                                @section heading 2
                                @tip Make the second-level headings larger in size for better readability on small screens.
                                */
                                    h2{
                                        /*@editable*/font-size:20px !important;
                                        /*@editable*/line-height:125% !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                /*
                                @tab Mobile Styles
                                @section heading 3
                                @tip Make the third-level headings larger in size for better readability on small screens.
                                */
                                    h3{
                                        /*@editable*/font-size:18px !important;
                                        /*@editable*/line-height:125% !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                /*
                                @tab Mobile Styles
                                @section heading 4
                                @tip Make the fourth-level headings larger in size for better readability on small screens.
                                */
                                    h4{
                                        /*@editable*/font-size:16px !important;
                                        /*@editable*/line-height:125% !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                /*
                                @tab Mobile Styles
                                @section Boxed Text
                                @tip Make the boxed text larger in size for better readability on small screens. We recommend a font size of at least 16px.
                                */
                                    table[class=mcnBoxedTextContentContainer] td[class=mcnTextContent],td[class=mcnBoxedTextContentContainer] td[class=mcnTextContent] p{
                                        /*@editable*/font-size:18px !important;
                                        /*@editable*/line-height:125% !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                /*
                                @tab Mobile Styles
                                @section Preheader Visibility
                                @tip Set the visibility of the email's preheader on small screens. You can hide it to save space.
                                */
                                    table[id=templatePreheader]{
                                        /*@editable*/display:block !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                /*
                                @tab Mobile Styles
                                @section Preheader Text
                                @tip Make the preheader text larger in size for better readability on small screens.
                                */
                                    td[class=preheaderContainer] td[class=mcnTextContent],td[class=preheaderContainer] td[class=mcnTextContent] p{
                                        /*@editable*/font-size:14px !important;
                                        /*@editable*/line-height:115% !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                /*
                                @tab Mobile Styles
                                @section Header Text
                                @tip Make the header text larger in size for better readability on small screens.
                                */
                                    td[class=headerContainer] td[class=mcnTextContent],td[class=headerContainer] td[class=mcnTextContent] p{
                                        /*@editable*/font-size:18px !important;
                                        /*@editable*/line-height:125% !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                /*
                                @tab Mobile Styles
                                @section Body Text
                                @tip Make the body text larger in size for better readability on small screens. We recommend a font size of at least 16px.
                                */
                                    td[class=bodyContainer] td[class=mcnTextContent],td[class=bodyContainer] td[class=mcnTextContent] p{
                                        /*@editable*/font-size:18px !important;
                                        /*@editable*/line-height:125% !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                /*
                                @tab Mobile Styles
                                @section footer text
                                @tip Make the body content text larger in size for better readability on small screens.
                                */
                                    td[class=footerContainer] td[class=mcnTextContent],td[class=footerContainer] td[class=mcnTextContent] p{
                                        /*@editable*/font-size:14px !important;
                                        /*@editable*/line-height:115% !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    td[class=footerContainer] a[class=utilityLink]{
                                        display:block !important;
                                    }

                            }</style></head>
                                <body leftmargin="0" marginwidth="0" topmargin="0" marginheight="0" offset="0">

                                    <center>
                                        <table align="center" border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable">
                                            <tr>
                                                <td align="center" valign="top" id="bodyCell">
                                                    <!-- BEGIN TEMPLATE // -->
                                                    <table border="0" cellpadding="0" cellspacing="0" width="600" id="templateContainer">
                                                        <tr>
                                                            <td align="center" valign="top">
                                                                <!-- BEGIN PREHEADER // -->
                                                                <table border="0" cellpadding="0" cellspacing="0" width="600" id="templatePreheader">
                                                                    <tr>
                                                                        <td valign="top" class="preheaderContainer" style="padding-top:9px;"><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width:100%;">
                                <tbody class="mcnTextBlockOuter">
                                    <tr>
                                        <td valign="top" class="mcnTextBlockInner" style="padding-top:9px;">
                                              <!--[if mso]>
                                            <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                                            <tr>
                                            <![endif]-->

                                            <!--[if mso]>
                                            <td valign="top" width="390" style="width:390px;">
                                            <![endif]-->
                                            <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width:390px;" width="100%" class="mcnTextContentContainer">
                                                <tbody><tr>

                                                    <td valign="top" class="mcnTextContent" style="padding-top:0; padding-left:18px; padding-bottom:9px; padding-right:18px;">


                                                    </td>
                                                </tr>
                                            </tbody></table>
                                            <!--[if mso]>
                                            </td>
                                            <![endif]-->

                                            <!--[if mso]>
                                            <td valign="top" width="210" style="width:210px;">
                                            <![endif]-->

                                            <!--[if mso]>
                                            </td>
                                            <![endif]-->

                                            <!--[if mso]>
                                            </tr>
                                            </table>
                                            <![endif]-->
                                        </td>
                                    </tr>
                                </tbody>
                            </table></td>
                                                                    </tr>
                                                                </table>
                                                                <!-- // END PREHEADER -->
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td align="center" valign="top">
                                                                <!-- BEGIN HEADER // -->
                                                                <table border="0" cellpadding="0" cellspacing="0" width="600" id="templateHeader">
                                                                    <tr>
                                                                        <td valign="top" class="headerContainer"><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageBlock" style="min-width:100%;">
                                <tbody class="mcnImageBlockOuter">
                                        <tr>
                                            <td valign="top" style="padding:9px" class="mcnImageBlockInner">
                                                <table align="left" width="100%" border="0" cellpadding="0" cellspacing="0" class="mcnImageContentContainer" style="min-width:100%;">
                                                    <tbody><tr>
                                                        <td class="mcnImageContent" valign="top" style="padding-right: 9px; padding-left: 9px; padding-top: 0; padding-bottom: 0;">

                                                                <a href="${environment.base_url}" title="" class="" target="_blank">
                                                                    <img align="left" alt="" src="https://mcusercontent.com/a24798abd1508f12878dc615c/images/4ae1a36e-18f0-4829-812c-323e39c1d993.png" width="564" style="max-width:900px; padding-bottom: 0; display: inline !important; vertical-align: bottom;" class="mcnImage">
                                                                </a>

                                                        </td>
                                                    </tr>
                                                </tbody></table>
                                            </td>
                                        </tr>
                                </tbody>
                            </table></td>
                                                                    </tr>
                                                                </table>
                                                                <!-- // END HEADER -->
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td align="center" valign="top">
                                                                <!-- BEGIN BODY // -->
                                                                <table border="0" cellpadding="0" cellspacing="0" width="600" id="templateBody">
                                                                    <tr>
                                                                        <td valign="top" class="bodyContainer"><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width:100%;">
                                <tbody class="mcnTextBlockOuter">
                                    <tr>
                                        <td valign="top" class="mcnTextBlockInner" style="padding-top:9px;">
                                              <!--[if mso]>
                                            <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                                            <tr>
                                            <![endif]-->

                                            <!--[if mso]>
                                            <td valign="top" width="600" style="width:600px;">
                                            <![endif]-->
                                            <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width:100%; min-width:100%;" width="100%" class="mcnTextContentContainer">
                                                <tbody><tr>

                                                    <td valign="top" class="mcnTextContent" style="padding-top:0; padding-right:18px; padding-bottom:9px; padding-left:18px;">

                                                        <div style="text-align: center;">
                            <div style="text-align: left;"><span style="font-size:30px"><span style="font-family:tahoma,verdana,segoe,sans-serif"><span style="color:#2f2e31"><strong>Bonjour,</strong></span></span></span><br>
                            &nbsp;
                            <p><span style="font-size:15px"><span style="color:#000000"><span style="font-family:tahoma,verdana,segoe,sans-serif">${self.user.value.email} vous invite à collaborer sur son espace Client Montri.<br>
                            <br>
                            Acceptez son invitation et créez votre compte en cliquant sur le bouton ci-dessous&nbsp;</span></span></span>:</p>
                            </div>
                            </div>

                                                    </td>
                                                </tr>
                                            </tbody></table>
                                            <!--[if mso]>
                                            </td>
                                            <![endif]-->

                                            <!--[if mso]>
                                            </tr>
                                            </table>
                                            <![endif]-->
                                        </td>
                                    </tr>
                                </tbody>
                            </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnButtonBlock" style="min-width:100%;">
                                <tbody class="mcnButtonBlockOuter">
                                    <tr>
                                        <td style="padding-top:0; padding-right:18px; padding-bottom:18px; padding-left:18px;" valign="top" align="center" class="mcnButtonBlockInner">
                                            <table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnButtonContentContainer" style="border-collapse: separate !important;border-radius: 6px;background-color: #FFC953;">
                                                <tbody>
                                                    <tr>
                                                        <td align="center" valign="middle" class="mcnButtonContent" style="font-family: Tahoma, Verdana, Segoe, sans-serif; font-size: 24px; padding: 20px;">
                                                            <a class="mcnButton " title="Accepter l'invitation" href="${environment.base_url}/user/signup" target="_blank" style="font-weight: bold;letter-spacing: normal;line-height: 100%;text-align: center;text-decoration: none;color: #FFFFFF;">Accepter l'invitation</a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width:100%;">
                                <tbody class="mcnTextBlockOuter">
                                    <tr>
                                        <td valign="top" class="mcnTextBlockInner" style="padding-top:9px;">
                                              <!--[if mso]>
                                            <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                                            <tr>
                                            <![endif]-->

                                            <!--[if mso]>
                                            <td valign="top" width="600" style="width:600px;">
                                            <![endif]-->
                                            <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width:100%; min-width:100%;" width="100%" class="mcnTextContentContainer">
                                                <tbody><tr>

                                                    <td valign="top" class="mcnTextContent" style="padding-top:0; padding-right:18px; padding-bottom:9px; padding-left:18px;">

                                                        <div style="text-align: center;">
                            <div style="text-align: left;">
                            <p><span style="font-size:15px"><span style="color:#000000"><span style="font-family:tahoma,verdana,segoe,sans-serif">Copiez le code ci-dessous et collez-le dans le champs <strong>"Code de l'invitation"</strong> pour accéder à votre espace.</span></span></span></p>
                            </div>
                            </div>

                                                    </td>
                                                </tr>
                                            </tbody></table>
                                            <!--[if mso]>
                                            </td>
                                            <![endif]-->

                                            <!--[if mso]>
                                            </tr>
                                            </table>
                                            <![endif]-->
                                        </td>
                                    </tr>
                                </tbody>
                            </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnBoxedTextBlock" style="min-width:100%;">
                                <!--[if gte mso 9]>
                                <table align="center" border="0" cellspacing="0" cellpadding="0" width="100%">
                                <![endif]-->
                                <tbody class="mcnBoxedTextBlockOuter">
                                    <tr>
                                        <td valign="top" class="mcnBoxedTextBlockInner">

                                            <!--[if gte mso 9]>
                                            <td align="center" valign="top" ">
                                            <![endif]-->
                                            <table align="left" border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width:100%;" class="mcnBoxedTextContentContainer">
                                                <tbody><tr>

                                                    <td style="padding-top:9px; padding-left:18px; padding-bottom:9px; padding-right:18px;">

                                                        <table border="0" cellspacing="0" class="mcnTextContentContainer" width="100%" style="min-width: 100% !important;background-color: #FFEECB;">
                                                            <tbody><tr>
                                                                <td valign="top" class="mcnTextContent" style="padding: 18px;color: #000000;font-family: Tahoma, Verdana, Segoe, sans-serif;font-size: 22px;font-weight: normal;text-align: center;">
                                                                    ${docRef.id}
                                                                </td>
                                                            </tr>
                                                        </tbody></table>
                                                    </td>
                                                </tr>
                                            </tbody></table>
                                            <!--[if gte mso 9]>
                                            </td>
                                            <![endif]-->

                                            <!--[if gte mso 9]>
                                            </tr>
                                            </table>
                                            <![endif]-->
                                        </td>
                                    </tr>
                                </tbody>
                            </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width:100%;">
                                <tbody class="mcnTextBlockOuter">
                                    <tr>
                                        <td valign="top" class="mcnTextBlockInner" style="padding-top:9px;">
                                              <!--[if mso]>
                                            <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                                            <tr>
                                            <![endif]-->

                                            <!--[if mso]>
                                            <td valign="top" width="600" style="width:600px;">
                                            <![endif]-->
                                            <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width:100%; min-width:100%;" width="100%" class="mcnTextContentContainer">
                                                <tbody><tr>

                                                    <td valign="top" class="mcnTextContent" style="padding-top:0; padding-right:18px; padding-bottom:9px; padding-left:18px;">

                                                        <div style="text-align: center;">
                            <div style="text-align: left;">
                            <p><span style="font-size:15px"><span style="color:#000000"><span style="font-family:tahoma,verdana,segoe,sans-serif">Veillez à bien utiliser l'adresse email sur laquelle vous avez reçu cet email pour créer votre compte.</span></span></span></p>
                            </div>
                            </div>

                                                    </td>
                                                </tr>
                                            </tbody></table>
                                            <!--[if mso]>
                                            </td>
                                            <![endif]-->

                                            <!--[if mso]>
                                            </tr>
                                            </table>
                                            <![endif]-->
                                        </td>
                                    </tr>
                                </tbody>
                            </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageBlock" style="min-width:100%;">
                                <tbody class="mcnImageBlockOuter">
                                        <tr>
                                            <td valign="top" style="padding:9px" class="mcnImageBlockInner">
                                                <table align="left" width="100%" border="0" cellpadding="0" cellspacing="0" class="mcnImageContentContainer" style="min-width:100%;">
                                                    <tbody><tr>
                                                        <td class="mcnImageContent" valign="top" style="padding-right: 9px; padding-left: 9px; padding-top: 0; padding-bottom: 0; text-align:center;">


                                                                    <img align="center" alt="" src="https://mcusercontent.com/a24798abd1508f12878dc615c/images/d059d44b-3d91-432a-9773-a50604c25529.png" width="564" style="max-width:900px; padding-bottom: 0; display: inline !important; vertical-align: bottom;" class="mcnImage">


                                                        </td>
                                                    </tr>
                                                </tbody></table>
                                            </td>
                                        </tr>
                                </tbody>
                            </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width:100%;">
                                <tbody class="mcnTextBlockOuter">
                                    <tr>
                                        <td valign="top" class="mcnTextBlockInner" style="padding-top:9px;">
                                              <!--[if mso]>
                                            <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                                            <tr>
                                            <![endif]-->

                                            <!--[if mso]>
                                            <td valign="top" width="600" style="width:600px;">
                                            <![endif]-->
                                            <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width:100%; min-width:100%;" width="100%" class="mcnTextContentContainer">
                                                <tbody><tr>

                                                    <td valign="top" class="mcnTextContent" style="padding-top:0; padding-right:18px; padding-bottom:9px; padding-left:18px;">

                                                        <div style="text-align: center;">
                            <div style="text-align: left;">
                            <p><span style="font-size:20px"><span style="font-family:tahoma,verdana,segoe,sans-serif"><font color="#2f2e31"><strong>Comment visualiser vos modifications ?</strong></font></span></span><br>
                            <br>
                            <span style="font-size:15px"><span style="font-family:tahoma,verdana,segoe,sans-serif"><span style="color:#000000">Visualisez&nbsp;le rendu de votre application à tout moment sur votre téléphone en téléchargeant la version démo de l'application :</span></span></span></p>

                            <ul>
                                <li><span style="font-size:15px"><span style="font-family:tahoma,verdana,segoe,sans-serif"><span style="color:#000000">iOS (iPhone) : </span><a href="https://testflight.apple.com/join/vTivHyVn" target="_blank"><span style="color:#0000FF">https://testflight.apple.com/join/vTivHyVn</span></a></span></span></li>
                                <li><span style="font-size:15px"><span style="font-family:tahoma,verdana,segoe,sans-serif"><span style="color:#000000">Android : </span><a href="https://appdistribution.firebase.dev/i/1fa299dc06f41ee7" target="_blank"><span style="color:#0000FF">https://appdistribution.firebase.dev/i/1fa299dc06f41ee7</span></a></span></span></li>
                                <li><span style="font-size:15px"><span style="font-family:tahoma,verdana,segoe,sans-serif"><span style="color:#000000">Application web :</span> <a href="https://preprod.montri.fr/" target="_blank"><span style="color:#0000FF">https://preprod.montri.fr/</span></a></span></span></li>
                            </ul>
                            </div>
                            </div>

                                                    </td>
                                                </tr>
                                            </tbody></table>
                                            <!--[if mso]>
                                            </td>
                                            <![endif]-->

                                            <!--[if mso]>
                                            </tr>
                                            </table>
                                            <![endif]-->
                                        </td>
                                    </tr>
                                </tbody>
                            </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnDividerBlock" style="min-width:100%;">
                                <tbody class="mcnDividerBlockOuter">
                                    <tr>
                                        <td class="mcnDividerBlockInner" style="min-width: 100%; padding: 20px 18px;">
                                            <table class="mcnDividerContent" border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width: 100%;border-top: 3px solid #FFC953;">
                                                <tbody><tr>
                                                    <td>
                                                        <span></span>
                                                    </td>
                                                </tr>
                                            </tbody></table>
                            <!--
                                            <td class="mcnDividerBlockInner" style="padding: 18px;">
                                            <hr class="mcnDividerContent" style="border-bottom-color:none; border-left-color:none; border-right-color:none; border-bottom-width:0; border-left-width:0; border-right-width:0; margin-top:0; margin-right:0; margin-bottom:0; margin-left:0;" />
                            -->
                                        </td>
                                    </tr>
                                </tbody>
                            </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width:100%;">
                                <tbody class="mcnTextBlockOuter">
                                    <tr>
                                        <td valign="top" class="mcnTextBlockInner" style="padding-top:9px;">
                                              <!--[if mso]>
                                            <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                                            <tr>
                                            <![endif]-->

                                            <!--[if mso]>
                                            <td valign="top" width="600" style="width:600px;">
                                            <![endif]-->
                                            <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width:100%; min-width:100%;" width="100%" class="mcnTextContentContainer">
                                                <tbody><tr>

                                                    <td valign="top" class="mcnTextContent" style="padding-top:0; padding-right:18px; padding-bottom:9px; padding-left:18px;">

                                                        <div style="text-align: left;"><span style="color:#000000"><span style="font-size:15px"><span style="font-family:verdana,geneva,sans-serif">N'hésitez pas à me contacter pour toutes questions concernant nos outils et l'utilisation de nos services.<br>
                            <br>
                            Bien cordialement,<br>
                            Paul</span></span></span></div>

                                                    </td>
                                                </tr>
                                            </tbody></table>
                                            <!--[if mso]>
                                            </td>
                                            <![endif]-->

                                            <!--[if mso]>
                                            </tr>
                                            </table>
                                            <![endif]-->
                                        </td>
                                    </tr>
                                </tbody>
                            </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageBlock" style="min-width:100%;">
                                <tbody class="mcnImageBlockOuter">
                                        <tr>
                                            <td valign="top" style="padding:9px" class="mcnImageBlockInner">
                                                <table align="left" width="100%" border="0" cellpadding="0" cellspacing="0" class="mcnImageContentContainer" style="min-width:100%;">
                                                    <tbody><tr>
                                                        <td class="mcnImageContent" valign="top" style="padding-right: 9px; padding-left: 9px; padding-top: 0; padding-bottom: 0;">


                                                                    <img align="left" alt="" src="https://mcusercontent.com/a24798abd1508f12878dc615c/images/0f821d07-fc33-45af-99be-982e21640a89.png" width="100" style="max-width:100px; padding-bottom: 0; display: inline !important; vertical-align: bottom;" class="mcnImage">


                                                        </td>
                                                    </tr>
                                                </tbody></table>
                                            </td>
                                        </tr>
                                </tbody>
                            </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width:100%;">
                                <tbody class="mcnTextBlockOuter">
                                    <tr>
                                        <td valign="top" class="mcnTextBlockInner" style="padding-top:9px;">
                                              <!--[if mso]>
                                            <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                                            <tr>
                                            <![endif]-->

                                            <!--[if mso]>
                                            <td valign="top" width="600" style="width:600px;">
                                            <![endif]-->
                                            <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width:100%; min-width:100%;" width="100%" class="mcnTextContentContainer">
                                                <tbody><tr>

                                                    <td valign="top" class="mcnTextContent" style="padding-top:0; padding-right:18px; padding-bottom:9px; padding-left:18px;">

                                                        <div style="text-align: left;"><span style="font-size:12px"><span style="color:#000000"><span style="font-family:verdana,geneva,sans-serif">Mob.: </span></span><span style="color:#0000FF"><span style="font-family:verdana,geneva,sans-serif">06 58 16 55 08</span></span><br>
                            <span style="color:#000000"><span style="font-family:verdana,geneva,sans-serif">Email : </span></span><a href="mailto:palarcon@uzer.eu?subject=J'ai%20besoin%20d'aide" target="_blank"><span style="color:#0000FF"><span style="font-family:verdana,geneva,sans-serif">palarcon@uzer.eu</span></span></a></span></div>

                                                    </td>
                                                </tr>
                                            </tbody></table>
                                            <!--[if mso]>
                                            </td>
                                            <![endif]-->

                                            <!--[if mso]>
                                            </tr>
                                            </table>
                                            <![endif]-->
                                        </td>
                                    </tr>
                                </tbody>
                            </table></td>
                                                                    </tr>
                                                                </table>
                                                                <!-- // END BODY -->
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td align="center" valign="top">
                                                                <!-- BEGIN FOOTER // -->
                                                                <table border="0" cellpadding="0" cellspacing="0" width="600" id="templateFooter">
                                                                    <tr>
                                                                        <td valign="top" class="footerContainer" style="padding-bottom:9px;"></td>
                                                                    </tr>
                                                                </table>
                                                                <!-- // END FOOTER -->
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    <!-- // END TEMPLATE -->
                                                </td>
                                            </tr>
                                        </table>
                                    </center>
                                </body>
                            </html>

                                </code>`,
                        },
                    })
                    .then(function (docRef) {
                        self.toastr.openSuccessToast("Votre invitation a été bien envoyée","")
                        self.lodingBarService.setStatus(false);
                    })
                    .catch(function (error) {
                        self.toastr.openErrorToast("Envoi d'invitation non réussi réssayer de nouveau","")
                        self.lodingBarService.setStatus(false);
                    });

                }
            })
            .catch(function (error) {
                self.toastr.openErrorToast("Envoi d'invitation non réussi réssayer de nouveau","")
                self.lodingBarService.setStatus(false);
            });
    }

    resendInvit(invit: InvitID) {
        this.lodingBarService.setStatus(true);
        var self = this;
        if(invit.sender_email=='palarcon@uzer.eu' && invit.reciver_role == 'super_user'){
            self.afs
            .collection('mail')
            .add({
                to: invit.reciver_email,
                message: {
                    subject: 'Montri - Commencez votre paramétrage',
                    html: `<code>
                    <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
                    <html xmlns="http://www.w3.org/1999/xhtml">
                        <head>
                            <!-- NAME: 1 COLUMN -->
                            <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
                            <meta name="viewport" content="width=device-width, initial-scale=1.0">
                            <title>*|MC:SUBJECT|*</title>

                        <style type="text/css">
                            body,#bodyTable,#bodyCell{
                                height:100% !important;
                                margin:0;
                                padding:0;
                                width:100% !important;
                            }
                            table{
                                border-collapse:collapse;
                            }
                            img,a img{
                                border:0;
                                outline:none;
                                text-decoration:none;
                            }
                            h1,h2,h3,h4,h5,h6{
                                margin:0;
                                padding:0;
                            }
                            p{
                                margin:1em 0;
                                padding:0;
                            }
                            a{
                                word-wrap:break-word;
                            }
                            .mcnPreviewText{
                                display:none !important;
                            }
                            .ReadMsgBody{
                                width:100%;
                            }
                            .ExternalClass{
                                width:100%;
                            }
                            .ExternalClass,.ExternalClass p,.ExternalClass span,.ExternalClass font,.ExternalClass td,.ExternalClass div{
                                line-height:100%;
                            }
                            table,td{
                                mso-table-lspace:0pt;
                                mso-table-rspace:0pt;
                            }
                            #outlook a{
                                padding:0;
                            }
                            img{
                                -ms-interpolation-mode:bicubic;
                            }
                            body,table,td,p,a,li,blockquote{
                                -ms-text-size-adjust:100%;
                                -webkit-text-size-adjust:100%;
                            }
                            #templatePreheader,#templateHeader,#templateBody,#templateFooter{
                                min-width:100%;
                            }
                            #bodyCell{
                                padding:20px;
                            }
                            .mcnImage,.mcnRetinaImage{
                                vertical-align:bottom;
                            }
                            .mcnTextContent img{
                                height:auto !important;
                            }
                        /*
                        @tab Page
                        @section background style
                        @tip Set the background color and top border for your email. You may want to choose colors that match your company's branding.
                        */
                            body,#bodyTable{
                                /*@editable*/background-color:#F2F2F2;
                            }
                        /*
                        @tab Page
                        @section background style
                        @tip Set the background color and top border for your email. You may want to choose colors that match your company's branding.
                        */
                            #bodyCell{
                                /*@editable*/border-top:0;
                            }
                        /*
                        @tab Page
                        @section email border
                        @tip Set the border for your email.
                        */
                            #templateContainer{
                                /*@editable*/border:0;
                            }
                        /*
                        @tab Page
                        @section heading 1
                        @tip Set the styling for all first-level headings in your emails. These should be the largest of your headings.
                        @style heading 1
                        */
                            h1{
                                /*@editable*/color:#606060 !important;
                                display:block;
                                /*@editable*/font-family:Helvetica;
                                /*@editable*/font-size:40px;
                                /*@editable*/font-style:normal;
                                /*@editable*/font-weight:bold;
                                /*@editable*/line-height:125%;
                                /*@editable*/letter-spacing:-1px;
                                margin:0;
                                /*@editable*/text-align:left;
                            }
                        /*
                        @tab Page
                        @section heading 2
                        @tip Set the styling for all second-level headings in your emails.
                        @style heading 2
                        */
                            h2{
                                /*@editable*/color:#404040 !important;
                                display:block;
                                /*@editable*/font-family:Helvetica;
                                /*@editable*/font-size:26px;
                                /*@editable*/font-style:normal;
                                /*@editable*/font-weight:bold;
                                /*@editable*/line-height:125%;
                                /*@editable*/letter-spacing:-.75px;
                                margin:0;
                                /*@editable*/text-align:left;
                            }
                        /*
                        @tab Page
                        @section heading 3
                        @tip Set the styling for all third-level headings in your emails.
                        @style heading 3
                        */
                            h3{
                                /*@editable*/color:#606060 !important;
                                display:block;
                                /*@editable*/font-family:Helvetica;
                                /*@editable*/font-size:18px;
                                /*@editable*/font-style:normal;
                                /*@editable*/font-weight:bold;
                                /*@editable*/line-height:125%;
                                /*@editable*/letter-spacing:-.5px;
                                margin:0;
                                /*@editable*/text-align:left;
                            }
                        /*
                        @tab Page
                        @section heading 4
                        @tip Set the styling for all fourth-level headings in your emails. These should be the smallest of your headings.
                        @style heading 4
                        */
                            h4{
                                /*@editable*/color:#808080 !important;
                                display:block;
                                /*@editable*/font-family:Helvetica;
                                /*@editable*/font-size:16px;
                                /*@editable*/font-style:normal;
                                /*@editable*/font-weight:bold;
                                /*@editable*/line-height:125%;
                                /*@editable*/letter-spacing:normal;
                                margin:0;
                                /*@editable*/text-align:left;
                            }
                        /*
                        @tab Preheader
                        @section preheader style
                        @tip Set the background color and borders for your email's preheader area.
                        */
                            #templatePreheader{
                                /*@editable*/background-color:#FFFFFF;
                                /*@editable*/border-top:0;
                                /*@editable*/border-bottom:0;
                            }
                        /*
                        @tab Preheader
                        @section preheader text
                        @tip Set the styling for your email's preheader text. Choose a size and color that is easy to read.
                        */
                            .preheaderContainer .mcnTextContent,.preheaderContainer .mcnTextContent p{
                                /*@editable*/color:#606060;
                                /*@editable*/font-family:Helvetica;
                                /*@editable*/font-size:11px;
                                /*@editable*/line-height:125%;
                                /*@editable*/text-align:left;
                            }
                        /*
                        @tab Preheader
                        @section preheader link
                        @tip Set the styling for your email's header links. Choose a color that helps them stand out from your text.
                        */
                            .preheaderContainer .mcnTextContent a{
                                /*@editable*/color:#606060;
                                /*@editable*/font-weight:normal;
                                /*@editable*/text-decoration:underline;
                            }
                        /*
                        @tab Header
                        @section header style
                        @tip Set the background color and borders for your email's header area.
                        */
                            #templateHeader{
                                /*@editable*/background-color:#FFFFFF;
                                /*@editable*/border-top:0;
                                /*@editable*/border-bottom:0;
                            }
                        /*
                        @tab Header
                        @section header text
                        @tip Set the styling for your email's header text. Choose a size and color that is easy to read.
                        */
                            .headerContainer .mcnTextContent,.headerContainer .mcnTextContent p{
                                /*@editable*/color:#606060;
                                /*@editable*/font-family:Helvetica;
                                /*@editable*/font-size:15px;
                                /*@editable*/line-height:150%;
                                /*@editable*/text-align:left;
                            }
                        /*
                        @tab Header
                        @section header link
                        @tip Set the styling for your email's header links. Choose a color that helps them stand out from your text.
                        */
                            .headerContainer .mcnTextContent a{
                                /*@editable*/color:#6DC6DD;
                                /*@editable*/font-weight:normal;
                                /*@editable*/text-decoration:underline;
                            }
                        /*
                        @tab Body
                        @section body style
                        @tip Set the background color and borders for your email's body area.
                        */
                            #templateBody{
                                /*@editable*/background-color:#FFFFFF;
                                /*@editable*/border-top:0;
                                /*@editable*/border-bottom:0;
                            }
                        /*
                        @tab Body
                        @section body text
                        @tip Set the styling for your email's body text. Choose a size and color that is easy to read.
                        */
                            .bodyContainer .mcnTextContent,.bodyContainer .mcnTextContent p{
                                /*@editable*/color:#606060;
                                /*@editable*/font-family:Helvetica;
                                /*@editable*/font-size:15px;
                                /*@editable*/line-height:150%;
                                /*@editable*/text-align:left;
                            }
                        /*
                        @tab Body
                        @section body link
                        @tip Set the styling for your email's body links. Choose a color that helps them stand out from your text.
                        */
                            .bodyContainer .mcnTextContent a{
                                /*@editable*/color:#6DC6DD;
                                /*@editable*/font-weight:normal;
                                /*@editable*/text-decoration:underline;
                            }
                        /*
                        @tab Footer
                        @section footer style
                        @tip Set the background color and borders for your email's footer area.
                        */
                            #templateFooter{
                                /*@editable*/background-color:#FFFFFF;
                                /*@editable*/border-top:0;
                                /*@editable*/border-bottom:0;
                            }
                        /*
                        @tab Footer
                        @section footer text
                        @tip Set the styling for your email's footer text. Choose a size and color that is easy to read.
                        */
                            .footerContainer .mcnTextContent,.footerContainer .mcnTextContent p{
                                /*@editable*/color:#606060;
                                /*@editable*/font-family:Helvetica;
                                /*@editable*/font-size:11px;
                                /*@editable*/line-height:125%;
                                /*@editable*/text-align:left;
                            }
                        /*
                        @tab Footer
                        @section footer link
                        @tip Set the styling for your email's footer links. Choose a color that helps them stand out from your text.
                        */
                            .footerContainer .mcnTextContent a{
                                /*@editable*/color:#606060;
                                /*@editable*/font-weight:normal;
                                /*@editable*/text-decoration:underline;
                            }
                        @media only screen and (max-width: 480px){
                            body,table,td,p,a,li,blockquote{
                                -webkit-text-size-adjust:none !important;
                            }

                    }	@media only screen and (max-width: 480px){
                            body{
                                width:100% !important;
                                min-width:100% !important;
                            }

                    }	@media only screen and (max-width: 480px){
                            .mcnRetinaImage{
                                max-width:100% !important;
                            }

                    }	@media only screen and (max-width: 480px){
                            td[id=bodyCell]{
                                padding:10px !important;
                            }

                    }	@media only screen and (max-width: 480px){
                            table[class=mcnTextContentContainer]{
                                width:100% !important;
                            }

                    }	@media only screen and (max-width: 480px){
                            .mcnBoxedTextContentContainer{
                                max-width:100% !important;
                                min-width:100% !important;
                                width:100% !important;
                            }

                    }	@media only screen and (max-width: 480px){
                            table[class=mcpreview-image-uploader]{
                                width:100% !important;
                                display:none !important;
                            }

                    }	@media only screen and (max-width: 480px){
                            img[class=mcnImage]{
                                width:100% !important;
                            }

                    }	@media only screen and (max-width: 480px){
                            table[class=mcnImageGroupContentContainer]{
                                width:100% !important;
                            }

                    }	@media only screen and (max-width: 480px){
                            td[class=mcnImageGroupContent]{
                                padding:9px !important;
                            }

                    }	@media only screen and (max-width: 480px){
                            td[class=mcnImageGroupBlockInner]{
                                padding-bottom:0 !important;
                                padding-top:0 !important;
                            }

                    }	@media only screen and (max-width: 480px){
                            tbody[class=mcnImageGroupBlockOuter]{
                                padding-bottom:9px !important;
                                padding-top:9px !important;
                            }

                    }	@media only screen and (max-width: 480px){
                            table[class=mcnCaptionTopContent],table[class=mcnCaptionBottomContent]{
                                width:100% !important;
                            }

                    }	@media only screen and (max-width: 480px){
                            table[class=mcnCaptionLeftTextContentContainer],table[class=mcnCaptionRightTextContentContainer],table[class=mcnCaptionLeftImageContentContainer],table[class=mcnCaptionRightImageContentContainer],table[class=mcnImageCardLeftTextContentContainer],table[class=mcnImageCardRightTextContentContainer],.mcnImageCardLeftImageContentContainer,.mcnImageCardRightImageContentContainer{
                                width:100% !important;
                            }

                    }	@media only screen and (max-width: 480px){
                            td[class=mcnImageCardLeftImageContent],td[class=mcnImageCardRightImageContent]{
                                padding-right:18px !important;
                                padding-left:18px !important;
                                padding-bottom:0 !important;
                            }

                    }	@media only screen and (max-width: 480px){
                            td[class=mcnImageCardBottomImageContent]{
                                padding-bottom:9px !important;
                            }

                    }	@media only screen and (max-width: 480px){
                            td[class=mcnImageCardTopImageContent]{
                                padding-top:18px !important;
                            }

                    }	@media only screen and (max-width: 480px){
                            td[class=mcnImageCardLeftImageContent],td[class=mcnImageCardRightImageContent]{
                                padding-right:18px !important;
                                padding-left:18px !important;
                                padding-bottom:0 !important;
                            }

                    }	@media only screen and (max-width: 480px){
                            td[class=mcnImageCardBottomImageContent]{
                                padding-bottom:9px !important;
                            }

                    }	@media only screen and (max-width: 480px){
                            td[class=mcnImageCardTopImageContent]{
                                padding-top:18px !important;
                            }

                    }	@media only screen and (max-width: 480px){
                            table[class=mcnCaptionLeftContentOuter] td[class=mcnTextContent],table[class=mcnCaptionRightContentOuter] td[class=mcnTextContent]{
                                padding-top:9px !important;
                            }

                    }	@media only screen and (max-width: 480px){
                            td[class=mcnCaptionBlockInner] table[class=mcnCaptionTopContent]:last-child td[class=mcnTextContent],.mcnImageCardTopImageContent,.mcnCaptionBottomContent:last-child .mcnCaptionBottomImageContent{
                                padding-top:18px !important;
                            }

                    }	@media only screen and (max-width: 480px){
                            td[class=mcnBoxedTextContentColumn]{
                                padding-left:18px !important;
                                padding-right:18px !important;
                            }

                    }	@media only screen and (max-width: 480px){
                            td[class=mcnTextContent]{
                                padding-right:18px !important;
                                padding-left:18px !important;
                            }

                    }	@media only screen and (max-width: 480px){
                        /*
                        @tab Mobile Styles
                        @section template width
                        @tip Make the template fluid for portrait or landscape view adaptability. If a fluid layout doesn't work for you, set the width to 300px instead.
                        */
                            table[id=templateContainer],table[id=templatePreheader],table[id=templateHeader],table[id=templateBody],table[id=templateFooter]{
                                /*@tab Mobile Styles
                    @section template width
                    @tip Make the template fluid for portrait or landscape view adaptability. If a fluid layout doesn't work for you, set the width to 300px instead.*/max-width:600px !important;
                                /*@editable*/width:100% !important;
                            }

                    }	@media only screen and (max-width: 480px){
                        /*
                        @tab Mobile Styles
                        @section heading 1
                        @tip Make the first-level headings larger in size for better readability on small screens.
                        */
                            h1{
                                /*@editable*/font-size:24px !important;
                                /*@editable*/line-height:125% !important;
                            }

                    }	@media only screen and (max-width: 480px){
                        /*
                        @tab Mobile Styles
                        @section heading 2
                        @tip Make the second-level headings larger in size for better readability on small screens.
                        */
                            h2{
                                /*@editable*/font-size:20px !important;
                                /*@editable*/line-height:125% !important;
                            }

                    }	@media only screen and (max-width: 480px){
                        /*
                        @tab Mobile Styles
                        @section heading 3
                        @tip Make the third-level headings larger in size for better readability on small screens.
                        */
                            h3{
                                /*@editable*/font-size:18px !important;
                                /*@editable*/line-height:125% !important;
                            }

                    }	@media only screen and (max-width: 480px){
                        /*
                        @tab Mobile Styles
                        @section heading 4
                        @tip Make the fourth-level headings larger in size for better readability on small screens.
                        */
                            h4{
                                /*@editable*/font-size:16px !important;
                                /*@editable*/line-height:125% !important;
                            }

                    }	@media only screen and (max-width: 480px){
                        /*
                        @tab Mobile Styles
                        @section Boxed Text
                        @tip Make the boxed text larger in size for better readability on small screens. We recommend a font size of at least 16px.
                        */
                            table[class=mcnBoxedTextContentContainer] td[class=mcnTextContent],td[class=mcnBoxedTextContentContainer] td[class=mcnTextContent] p{
                                /*@editable*/font-size:18px !important;
                                /*@editable*/line-height:125% !important;
                            }

                    }	@media only screen and (max-width: 480px){
                        /*
                        @tab Mobile Styles
                        @section Preheader Visibility
                        @tip Set the visibility of the email's preheader on small screens. You can hide it to save space.
                        */
                            table[id=templatePreheader]{
                                /*@editable*/display:block !important;
                            }

                    }	@media only screen and (max-width: 480px){
                        /*
                        @tab Mobile Styles
                        @section Preheader Text
                        @tip Make the preheader text larger in size for better readability on small screens.
                        */
                            td[class=preheaderContainer] td[class=mcnTextContent],td[class=preheaderContainer] td[class=mcnTextContent] p{
                                /*@editable*/font-size:14px !important;
                                /*@editable*/line-height:115% !important;
                            }

                    }	@media only screen and (max-width: 480px){
                        /*
                        @tab Mobile Styles
                        @section Header Text
                        @tip Make the header text larger in size for better readability on small screens.
                        */
                            td[class=headerContainer] td[class=mcnTextContent],td[class=headerContainer] td[class=mcnTextContent] p{
                                /*@editable*/font-size:18px !important;
                                /*@editable*/line-height:125% !important;
                            }

                    }	@media only screen and (max-width: 480px){
                        /*
                        @tab Mobile Styles
                        @section Body Text
                        @tip Make the body text larger in size for better readability on small screens. We recommend a font size of at least 16px.
                        */
                            td[class=bodyContainer] td[class=mcnTextContent],td[class=bodyContainer] td[class=mcnTextContent] p{
                                /*@editable*/font-size:18px !important;
                                /*@editable*/line-height:125% !important;
                            }

                    }	@media only screen and (max-width: 480px){
                        /*
                        @tab Mobile Styles
                        @section footer text
                        @tip Make the body content text larger in size for better readability on small screens.
                        */
                            td[class=footerContainer] td[class=mcnTextContent],td[class=footerContainer] td[class=mcnTextContent] p{
                                /*@editable*/font-size:14px !important;
                                /*@editable*/line-height:115% !important;
                            }

                    }	@media only screen and (max-width: 480px){
                            td[class=footerContainer] a[class=utilityLink]{
                                display:block !important;
                            }

                    }</style></head>
                        <body leftmargin="0" marginwidth="0" topmargin="0" marginheight="0" offset="0">

                            <center>
                                <table align="center" border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable">
                                    <tr>
                                        <td align="center" valign="top" id="bodyCell">
                                            <!-- BEGIN TEMPLATE // -->
                                            <table border="0" cellpadding="0" cellspacing="0" width="600" id="templateContainer">
                                                <tr>
                                                    <td align="center" valign="top">
                                                        <!-- BEGIN PREHEADER // -->
                                                        <table border="0" cellpadding="0" cellspacing="0" width="600" id="templatePreheader">
                                                            <tr>
                                                                <td valign="top" class="preheaderContainer" style="padding-top:9px;"><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width:100%;">
                        <tbody class="mcnTextBlockOuter">
                            <tr>
                                <td valign="top" class="mcnTextBlockInner" style="padding-top:9px;">
                                      <!--[if mso]>
                                    <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                                    <tr>
                                    <![endif]-->

                                    <!--[if mso]>
                                    <td valign="top" width="390" style="width:390px;">
                                    <![endif]-->
                                    <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width:390px;" width="100%" class="mcnTextContentContainer">
                                        <tbody><tr>

                                            <td valign="top" class="mcnTextContent" style="padding-top:0; padding-left:18px; padding-bottom:9px; padding-right:18px;">


                                            </td>
                                        </tr>
                                    </tbody></table>
                                    <!--[if mso]>
                                    </td>
                                    <![endif]-->

                                    <!--[if mso]>
                                    <td valign="top" width="210" style="width:210px;">
                                    <![endif]-->

                                    <!--[if mso]>
                                    </td>
                                    <![endif]-->

                                    <!--[if mso]>
                                    </tr>
                                    </table>
                                    <![endif]-->
                                </td>
                            </tr>
                        </tbody>
                    </table></td>
                                                            </tr>
                                                        </table>
                                                        <!-- // END PREHEADER -->
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="top">
                                                        <!-- BEGIN HEADER // -->
                                                        <table border="0" cellpadding="0" cellspacing="0" width="600" id="templateHeader">
                                                            <tr>
                                                                <td valign="top" class="headerContainer"><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageBlock" style="min-width:100%;">
                        <tbody class="mcnImageBlockOuter">
                                <tr>
                                    <td valign="top" style="padding:9px" class="mcnImageBlockInner">
                                        <table align="left" width="100%" border="0" cellpadding="0" cellspacing="0" class="mcnImageContentContainer" style="min-width:100%;">
                                            <tbody><tr>
                                                <td class="mcnImageContent" valign="top" style="padding-right: 9px; padding-left: 9px; padding-top: 0; padding-bottom: 0;">

                                                        <a href="${environment.base_url}" title="" class="" target="_blank">
                                                            <img align="left" alt="" src="https://mcusercontent.com/a24798abd1508f12878dc615c/images/4ae1a36e-18f0-4829-812c-323e39c1d993.png" width="564" style="max-width:900px; padding-bottom: 0; display: inline !important; vertical-align: bottom;" class="mcnImage">
                                                        </a>

                                                </td>
                                            </tr>
                                        </tbody></table>
                                    </td>
                                </tr>
                        </tbody>
                    </table></td>
                                                            </tr>
                                                        </table>
                                                        <!-- // END HEADER -->
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="top">
                                                        <!-- BEGIN BODY // -->
                                                        <table border="0" cellpadding="0" cellspacing="0" width="600" id="templateBody">
                                                            <tr>
                                                                <td valign="top" class="bodyContainer"><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width:100%;">
                        <tbody class="mcnTextBlockOuter">
                            <tr>
                                <td valign="top" class="mcnTextBlockInner" style="padding-top:9px;">
                                      <!--[if mso]>
                                    <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                                    <tr>
                                    <![endif]-->

                                    <!--[if mso]>
                                    <td valign="top" width="600" style="width:600px;">
                                    <![endif]-->
                                    <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width:100%; min-width:100%;" width="100%" class="mcnTextContentContainer">
                                        <tbody><tr>

                                            <td valign="top" class="mcnTextContent" style="padding-top:0; padding-right:18px; padding-bottom:9px; padding-left:18px;">

                                                <div style="text-align: center;">
                    <div style="text-align: left;"><span style="font-size:30px"><span style="font-family:tahoma,verdana,segoe,sans-serif"><span style="color:#2f2e31"><strong>Bonjour,</strong></span></span></span><br>
                    &nbsp;
                    <p><span style="font-size:15px"><span style="color:#000000"><span style="font-family:tahoma,verdana,segoe,sans-serif">Je suis Paul, Chargé de la Satisfaction Client chez Uzer, et je suis ravi de faire votre connaissance.</span></span></span></p>

                    <p><span style="font-size:15px"><span style="color:#000000"><span style="font-family:tahoma,verdana,segoe,sans-serif">Vous pouvez dès maintenant commencer le paramétrage de votre application Montri.<br>
                    <br>
                    Accédez à votre espace Client, copiez le code ci-dessous et collez-le&nbsp;dans le champs&nbsp;<strong>"Code de l'invitation"</strong>&nbsp;pour créer votre compte et commencer votre&nbsp;paramétrage.</span></span></span></p>
                    </div>
                    </div>

                                            </td>
                                        </tr>
                                    </tbody></table>
                                    <!--[if mso]>
                                    </td>
                                    <![endif]-->

                                    <!--[if mso]>
                                    </tr>
                                    </table>
                                    <![endif]-->
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnBoxedTextBlock" style="min-width:100%;">
                        <!--[if gte mso 9]>
                        <table align="center" border="0" cellspacing="0" cellpadding="0" width="100%">
                        <![endif]-->
                        <tbody class="mcnBoxedTextBlockOuter">
                            <tr>
                                <td valign="top" class="mcnBoxedTextBlockInner">

                                    <!--[if gte mso 9]>
                                    <td align="center" valign="top" ">
                                    <![endif]-->
                                    <table align="left" border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width:100%;" class="mcnBoxedTextContentContainer">
                                        <tbody><tr>

                                            <td style="padding-top:9px; padding-left:18px; padding-bottom:9px; padding-right:18px;">

                                                <table border="0" cellspacing="0" class="mcnTextContentContainer" width="100%" style="min-width: 100% !important;background-color: #FFEECB;">
                                                    <tbody><tr>
                                                        <td valign="top" class="mcnTextContent" style="padding: 18px;color: #000000;font-family: Helvetica;font-size: 22px;font-weight: normal;text-align: center;">
                                                            <span style="font-size:22px"><span style="font-family:tahoma,verdana,segoe,sans-serif">${invit.id}</span></span>
                                                        </td>
                                                    </tr>
                                                </tbody></table>
                                            </td>
                                        </tr>
                                    </tbody></table>
                                    <!--[if gte mso 9]>
                                    </td>
                                    <![endif]-->

                                    <!--[if gte mso 9]>
                                    </tr>
                                    </table>
                                    <![endif]-->
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnButtonBlock" style="min-width:100%;">
                        <tbody class="mcnButtonBlockOuter">
                            <tr>
                                <td style="padding-top:0; padding-right:18px; padding-bottom:18px; padding-left:18px;" valign="top" align="center" class="mcnButtonBlockInner">
                                    <table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnButtonContentContainer" style="border-collapse: separate !important;border-radius: 6px;background-color: #FFC953;">
                                        <tbody>
                                            <tr>
                                                <td align="center" valign="middle" class="mcnButtonContent" style="font-family: Tahoma, Verdana, Segoe, sans-serif; font-size: 24px; padding: 20px;">
                                                    <a class="mcnButton " title="Mon espace Client" href="${environment.base_url}/user/signup" target="_blank" style="font-weight: bold;letter-spacing: normal;line-height: 100%;text-align: center;text-decoration: none;color: #FFFFFF;">Mon espace Client</a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageBlock" style="min-width:100%;">
                        <tbody class="mcnImageBlockOuter">
                                <tr>
                                    <td valign="top" style="padding:9px" class="mcnImageBlockInner">
                                        <table align="left" width="100%" border="0" cellpadding="0" cellspacing="0" class="mcnImageContentContainer" style="min-width:100%;">
                                            <tbody><tr>
                                                <td class="mcnImageContent" valign="top" style="padding-right: 9px; padding-left: 9px; padding-top: 0; padding-bottom: 0; text-align:center;">


                                                            <img align="center" alt="" src="https://mcusercontent.com/a24798abd1508f12878dc615c/images/d059d44b-3d91-432a-9773-a50604c25529.png" width="564" style="max-width:900px; padding-bottom: 0; display: inline !important; vertical-align: bottom;" class="mcnImage">


                                                </td>
                                            </tr>
                                        </tbody></table>
                                    </td>
                                </tr>
                        </tbody>
                    </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width:100%;">
                        <tbody class="mcnTextBlockOuter">
                            <tr>
                                <td valign="top" class="mcnTextBlockInner" style="padding-top:9px;">
                                    <!--[if mso]>
                                    <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                                    <tr>
                                    <![endif]-->

                                    <!--[if mso]>
                                    <td valign="top" width="600" style="width:600px;">
                                    <![endif]-->
                                    <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width:100%; min-width:100%;" width="100%" class="mcnTextContentContainer">
                                        <tbody><tr>

                                            <td valign="top" class="mcnTextContent" style="padding-top:0; padding-right:18px; padding-bottom:9px; padding-left:18px;">

                                                <div style="text-align: center;">
                    <div style="text-align: left;">
                    <p><span style="font-size:20px"><span style="font-family:tahoma,verdana,segoe,sans-serif"><font color="#2f2e31"><strong>Par où commencer ?</strong></font></span></span></p>
                    </div>
                    </div>

                                            </td>
                                        </tr>
                                    </tbody></table>
                                    <!--[if mso]>
                                    </td>
                                    <![endif]-->

                                    <!--[if mso]>
                                    </tr>
                                    </table>
                                    <![endif]-->
                                </td>
                            </tr>
                        </tbody>
                    </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnCaptionBlock">
                        <tbody class="mcnCaptionBlockOuter">
                            <tr>
                                <td class="mcnCaptionBlockInner" valign="top" style="padding:9px;">


                    <table align="left" border="0" cellpadding="0" cellspacing="0" class="mcnCaptionBottomContent">
                        <tbody><tr>
                            <td class="mcnCaptionBottomImageContent" align="left" valign="top" style="padding:0 9px 9px 9px;">



                                <img alt="" src="https://mcusercontent.com/a24798abd1508f12878dc615c/images/afd926fe-d712-40c2-b61e-bfb1de7cd710.png" width="50" style="max-width:50px;" class="mcnImage">


                            </td>
                        </tr>
                        <tr>
                            <td class="mcnTextContent" valign="top" style="padding:0 9px 0 9px;" width="564">
                                <p style="text-align: left;"><span style="font-size:15px"><span style="font-family:tahoma,verdana,segoe,sans-serif"><a href="https://www.dropbox.com/s/y78tck45g3rv1hn/Documentation%20Back-Office%20Montri%20V1.5.pdf?dl=0" target="_blank">Téléchargez notre guide</a></span><span style="color:#000000"><span style="font-family:tahoma,verdana,segoe,sans-serif"> et accédez à votre espace et configurez votre compte</span></span></span></p>

                            </td>
                        </tr>
                    </tbody></table>





                                </td>
                            </tr>
                        </tbody>
                    </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnCaptionBlock">
                        <tbody class="mcnCaptionBlockOuter">
                            <tr>
                                <td class="mcnCaptionBlockInner" valign="top" style="padding:9px;">


                    <table align="left" border="0" cellpadding="0" cellspacing="0" class="mcnCaptionBottomContent">
                        <tbody><tr>
                            <td class="mcnCaptionBottomImageContent" align="left" valign="top" style="padding:0 9px 9px 9px;">



                                <img alt="" src="https://mcusercontent.com/a24798abd1508f12878dc615c/images/c9793e37-5c1a-4528-b447-5447625139a0.png" width="50" style="max-width:50px;" class="mcnImage">


                            </td>
                        </tr>
                        <tr>
                            <td class="mcnTextContent" valign="top" style="padding:0 9px 0 9px;" width="564">
                                <p style="text-align: left;"><span style="font-size:15px"><span style="font-family:tahoma,verdana,segoe,sans-serif"><span style="color:#000000">Invitez vos collaborateurs à rejoindre votre espace pour les laisser contribuer au paramétrage</span></span></span></p>

                            </td>
                        </tr>
                    </tbody></table>





                                </td>
                            </tr>
                        </tbody>
                    </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnCaptionBlock">
                        <tbody class="mcnCaptionBlockOuter">
                            <tr>
                                <td class="mcnCaptionBlockInner" valign="top" style="padding:9px;">


                    <table align="left" border="0" cellpadding="0" cellspacing="0" class="mcnCaptionBottomContent">
                        <tbody><tr>
                            <td class="mcnCaptionBottomImageContent" align="left" valign="top" style="padding:0 9px 9px 9px;">



                                <img alt="" src="https://mcusercontent.com/a24798abd1508f12878dc615c/images/05019f46-9ce1-4766-8166-7ad543d4b362.png" width="50" style="max-width:50px;" class="mcnImage">


                            </td>
                        </tr>
                        <tr>
                            <td class="mcnTextContent" valign="top" style="padding:0 9px 0 9px;" width="564">
                                <p style="text-align: left;"><span style="font-size:15px"><span style="color:#000000"><span style="font-family:tahoma,verdana,segoe,sans-serif">Paramétrez le design et les services de votre application et visualisez vos modifications dans l'appli</span></span></span></p>

                            </td>
                        </tr>
                    </tbody></table>





                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageBlock" style="min-width:100%;">
                        <tbody class="mcnImageBlockOuter">
                                <tr>
                                    <td valign="top" style="padding:9px" class="mcnImageBlockInner">
                                        <table align="left" width="100%" border="0" cellpadding="0" cellspacing="0" class="mcnImageContentContainer" style="min-width:100%;">
                                            <tbody><tr>
                                                <td class="mcnImageContent" valign="top" style="padding-right: 9px; padding-left: 9px; padding-top: 0; padding-bottom: 0; text-align:center;">


                                                            <img align="center" alt="" src="https://mcusercontent.com/a24798abd1508f12878dc615c/images/d059d44b-3d91-432a-9773-a50604c25529.png" width="564" style="max-width:900px; padding-bottom: 0; display: inline !important; vertical-align: bottom;" class="mcnImage">


                                                </td>
                                            </tr>
                                        </tbody></table>
                                    </td>
                                </tr>
                        </tbody>
                    </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width:100%;">
                        <tbody class="mcnTextBlockOuter">
                            <tr>
                                <td valign="top" class="mcnTextBlockInner" style="padding-top:9px;">
                                      <!--[if mso]>
                                    <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                                    <tr>
                                    <![endif]-->

                                    <!--[if mso]>
                                    <td valign="top" width="600" style="width:600px;">
                                    <![endif]-->
                                    <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width:100%; min-width:100%;" width="100%" class="mcnTextContentContainer">
                                        <tbody><tr>

                                            <td valign="top" class="mcnTextContent" style="padding-top:0; padding-right:18px; padding-bottom:9px; padding-left:18px;">

                                                <div style="text-align: center;">
                    <div style="text-align: left;">
                    <p><span style="font-size:20px"><span style="font-family:tahoma,verdana,segoe,sans-serif"><font color="#2f2e31"><strong>Comment visualiser vos modifications ?</strong></font></span></span><br>
                    <br>
                    <span style="font-size:15px"><span style="font-family:tahoma,verdana,segoe,sans-serif"><span style="color:#000000">Visualisez&nbsp;le rendu de votre application à tout moment sur votre téléphone en téléchargeant la version démo de l'application :</span></span></span></p>

                    <ul>
                        <li><span style="font-size:15px"><span style="font-family:tahoma,verdana,segoe,sans-serif"><span style="color:#000000">iOS (iPhone) : </span><a href="https://testflight.apple.com/join/vTivHyVn" target="_blank"><span style="color:#0000FF">https://testflight.apple.com/join/vTivHyVn</span></a></span></span></li>
                        <li><span style="font-size:15px"><span style="font-family:tahoma,verdana,segoe,sans-serif"><span style="color:#000000">Android : </span><a href="https://appdistribution.firebase.dev/i/1fa299dc06f41ee7" target="_blank"><span style="color:#0000FF">https://appdistribution.firebase.dev/i/1fa299dc06f41ee7</span></a></span></span></li>
                        <li><span style="font-size:15px"><span style="font-family:tahoma,verdana,segoe,sans-serif"><span style="color:#000000">Application web :</span> <a href="https://preprod.montri.fr/" target="_blank"><span style="color:#0000FF">https://preprod.montri.fr/</span></a></span></span></li>
                    </ul>
                    </div>
                    </div>

                                            </td>
                                        </tr>
                                    </tbody></table>
                                    <!--[if mso]>
                                    </td>
                                    <![endif]-->

                                    <!--[if mso]>
                                    </tr>
                                    </table>
                                    <![endif]-->
                                </td>
                            </tr>
                        </tbody>
                    </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnDividerBlock" style="min-width:100%;">
                        <tbody class="mcnDividerBlockOuter">
                            <tr>
                                <td class="mcnDividerBlockInner" style="min-width: 100%; padding: 20px 18px;">
                                    <table class="mcnDividerContent" border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width: 100%;border-top: 3px solid #FFC953;">
                                        <tbody><tr>
                                            <td>
                                                <span></span>
                                            </td>
                                        </tr>
                                    </tbody></table>
                    <!--
                                    <td class="mcnDividerBlockInner" style="padding: 18px;">
                                    <hr class="mcnDividerContent" style="border-bottom-color:none; border-left-color:none; border-right-color:none; border-bottom-width:0; border-left-width:0; border-right-width:0; margin-top:0; margin-right:0; margin-bottom:0; margin-left:0;" />
                    -->
                                </td>
                            </tr>
                        </tbody>
                    </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width:100%;">
                        <tbody class="mcnTextBlockOuter">
                            <tr>
                                <td valign="top" class="mcnTextBlockInner" style="padding-top:9px;">
                                      <!--[if mso]>
                                    <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                                    <tr>
                                    <![endif]-->

                                    <!--[if mso]>
                                    <td valign="top" width="600" style="width:600px;">
                                    <![endif]-->
                                    <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width:100%; min-width:100%;" width="100%" class="mcnTextContentContainer">
                                        <tbody><tr>

                                            <td valign="top" class="mcnTextContent" style="padding-top:0; padding-right:18px; padding-bottom:9px; padding-left:18px;">

                                                <div style="text-align: left;"><span style="color:#000000"><span style="font-size:15px"><span style="font-family:verdana,geneva,sans-serif">N'hésitez pas à me contacter pour toutes questions concernant nos outils et l'utilisation de nos services.<br>
                    <br>
                    Bien cordialement,<br>
                    Paul</span></span></span></div>

                                            </td>
                                        </tr>
                                    </tbody></table>
                                    <!--[if mso]>
                                    </td>
                                    <![endif]-->

                                    <!--[if mso]>
                                    </tr>
                                    </table>
                                    <![endif]-->
                                </td>
                            </tr>
                        </tbody>
                    </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageBlock" style="min-width:100%;">
                        <tbody class="mcnImageBlockOuter">
                                <tr>
                                    <td valign="top" style="padding:9px" class="mcnImageBlockInner">
                                        <table align="left" width="100%" border="0" cellpadding="0" cellspacing="0" class="mcnImageContentContainer" style="min-width:100%;">
                                            <tbody><tr>
                                                <td class="mcnImageContent" valign="top" style="padding-right: 9px; padding-left: 9px; padding-top: 0; padding-bottom: 0;">


                                                            <img align="left" alt="" src="https://mcusercontent.com/a24798abd1508f12878dc615c/images/0f821d07-fc33-45af-99be-982e21640a89.png" width="100" style="max-width:100px; padding-bottom: 0; display: inline !important; vertical-align: bottom;" class="mcnImage">


                                                </td>
                                            </tr>
                                        </tbody></table>
                                    </td>
                                </tr>
                        </tbody>
                    </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width:100%;">
                        <tbody class="mcnTextBlockOuter">
                            <tr>
                                <td valign="top" class="mcnTextBlockInner" style="padding-top:9px;">
                                      <!--[if mso]>
                                    <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                                    <tr>
                                    <![endif]-->

                                    <!--[if mso]>
                                    <td valign="top" width="600" style="width:600px;">
                                    <![endif]-->
                                    <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width:100%; min-width:100%;" width="100%" class="mcnTextContentContainer">
                                        <tbody><tr>

                                            <td valign="top" class="mcnTextContent" style="padding-top:0; padding-right:18px; padding-bottom:9px; padding-left:18px;">

                                                <div style="text-align: left;"><span style="font-size:12px"><span style="color:#000000"><span style="font-family:verdana,geneva,sans-serif">Mob.: </span></span><span style="color:#0000FF"><span style="font-family:verdana,geneva,sans-serif">06 58 16 55 08</span></span><br>
                    <span style="color:#000000"><span style="font-family:verdana,geneva,sans-serif">Email : </span></span><a href="mailto:palarcon@uzer.eu?subject=J'ai%20besoin%20d'aide" target="_blank"><span style="color:#0000FF"><span style="font-family:verdana,geneva,sans-serif">palarcon@uzer.eu</span></span></a></span></div>

                                            </td>
                                        </tr>
                                    </tbody></table>
                                    <!--[if mso]>
                                    </td>
                                    <![endif]-->

                                    <!--[if mso]>
                                    </tr>
                                    </table>
                                    <![endif]-->
                                </td>
                            </tr>
                        </tbody>
                    </table></td>
                                                            </tr>
                                                        </table>
                                                        <!-- // END BODY -->
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="top">
                                                        <!-- BEGIN FOOTER // -->
                                                        <table border="0" cellpadding="0" cellspacing="0" width="600" id="templateFooter">
                                                            <tr>
                                                                <td valign="top" class="footerContainer" style="padding-bottom:9px;"></td>
                                                            </tr>
                                                        </table>
                                                        <!-- // END FOOTER -->
                                                    </td>
                                                </tr>
                                            </table>
                                            <!-- // END TEMPLATE -->
                                        </td>
                                    </tr>
                                </table>
                            </center>
                        </body>
                    </html>
                        </code>`,
                },
            })
            .then(function (docRef) {
                self.toastr.openSuccessToast("Votre invitation a été bien renvoyée","")
                self.lodingBarService.setStatus(false);
            })
            .catch(function (error) {
                self.toastr.openErrorToast("Envoi d'invitation non réussi réssayer de nouveau","")
                self.lodingBarService.setStatus(false);
            });
        }
        else{
        self.afs
            .collection('mail')
            .add({
                to: invit.reciver_email,
                message: {
                    subject: 'Montri - Commencez votre paramétrage',
                    html: `<code>
                    <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
                            <html xmlns="http://www.w3.org/1999/xhtml">
                                <head>
                                    <!-- NAME: 1 COLUMN -->
                                    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
                                    <meta name="viewport" content="width=device-width, initial-scale=1.0">
                                    <title>*|MC:SUBJECT|*</title>

                                <style type="text/css">
                                    body,#bodyTable,#bodyCell{
                                        height:100% !important;
                                        margin:0;
                                        padding:0;
                                        width:100% !important;
                                    }
                                    table{
                                        border-collapse:collapse;
                                    }
                                    img,a img{
                                        border:0;
                                        outline:none;
                                        text-decoration:none;
                                    }
                                    h1,h2,h3,h4,h5,h6{
                                        margin:0;
                                        padding:0;
                                    }
                                    p{
                                        margin:1em 0;
                                        padding:0;
                                    }
                                    a{
                                        word-wrap:break-word;
                                    }
                                    .mcnPreviewText{
                                        display:none !important;
                                    }
                                    .ReadMsgBody{
                                        width:100%;
                                    }
                                    .ExternalClass{
                                        width:100%;
                                    }
                                    .ExternalClass,.ExternalClass p,.ExternalClass span,.ExternalClass font,.ExternalClass td,.ExternalClass div{
                                        line-height:100%;
                                    }
                                    table,td{
                                        mso-table-lspace:0pt;
                                        mso-table-rspace:0pt;
                                    }
                                    #outlook a{
                                        padding:0;
                                    }
                                    img{
                                        -ms-interpolation-mode:bicubic;
                                    }
                                    body,table,td,p,a,li,blockquote{
                                        -ms-text-size-adjust:100%;
                                        -webkit-text-size-adjust:100%;
                                    }
                                    #templatePreheader,#templateHeader,#templateBody,#templateFooter{
                                        min-width:100%;
                                    }
                                    #bodyCell{
                                        padding:20px;
                                    }
                                    .mcnImage,.mcnRetinaImage{
                                        vertical-align:bottom;
                                    }
                                    .mcnTextContent img{
                                        height:auto !important;
                                    }
                                /*
                                @tab Page
                                @section background style
                                @tip Set the background color and top border for your email. You may want to choose colors that match your company's branding.
                                */
                                    body,#bodyTable{
                                        /*@editable*/background-color:#F2F2F2;
                                    }
                                /*
                                @tab Page
                                @section background style
                                @tip Set the background color and top border for your email. You may want to choose colors that match your company's branding.
                                */
                                    #bodyCell{
                                        /*@editable*/border-top:0;
                                    }
                                /*
                                @tab Page
                                @section email border
                                @tip Set the border for your email.
                                */
                                    #templateContainer{
                                        /*@editable*/border:0;
                                    }
                                /*
                                @tab Page
                                @section heading 1
                                @tip Set the styling for all first-level headings in your emails. These should be the largest of your headings.
                                @style heading 1
                                */
                                    h1{
                                        /*@editable*/color:#606060 !important;
                                        display:block;
                                        /*@editable*/font-family:Helvetica;
                                        /*@editable*/font-size:40px;
                                        /*@editable*/font-style:normal;
                                        /*@editable*/font-weight:bold;
                                        /*@editable*/line-height:125%;
                                        /*@editable*/letter-spacing:-1px;
                                        margin:0;
                                        /*@editable*/text-align:left;
                                    }
                                /*
                                @tab Page
                                @section heading 2
                                @tip Set the styling for all second-level headings in your emails.
                                @style heading 2
                                */
                                    h2{
                                        /*@editable*/color:#404040 !important;
                                        display:block;
                                        /*@editable*/font-family:Helvetica;
                                        /*@editable*/font-size:26px;
                                        /*@editable*/font-style:normal;
                                        /*@editable*/font-weight:bold;
                                        /*@editable*/line-height:125%;
                                        /*@editable*/letter-spacing:-.75px;
                                        margin:0;
                                        /*@editable*/text-align:left;
                                    }
                                /*
                                @tab Page
                                @section heading 3
                                @tip Set the styling for all third-level headings in your emails.
                                @style heading 3
                                */
                                    h3{
                                        /*@editable*/color:#606060 !important;
                                        display:block;
                                        /*@editable*/font-family:Helvetica;
                                        /*@editable*/font-size:18px;
                                        /*@editable*/font-style:normal;
                                        /*@editable*/font-weight:bold;
                                        /*@editable*/line-height:125%;
                                        /*@editable*/letter-spacing:-.5px;
                                        margin:0;
                                        /*@editable*/text-align:left;
                                    }
                                /*
                                @tab Page
                                @section heading 4
                                @tip Set the styling for all fourth-level headings in your emails. These should be the smallest of your headings.
                                @style heading 4
                                */
                                    h4{
                                        /*@editable*/color:#808080 !important;
                                        display:block;
                                        /*@editable*/font-family:Helvetica;
                                        /*@editable*/font-size:16px;
                                        /*@editable*/font-style:normal;
                                        /*@editable*/font-weight:bold;
                                        /*@editable*/line-height:125%;
                                        /*@editable*/letter-spacing:normal;
                                        margin:0;
                                        /*@editable*/text-align:left;
                                    }
                                /*
                                @tab Preheader
                                @section preheader style
                                @tip Set the background color and borders for your email's preheader area.
                                */
                                    #templatePreheader{
                                        /*@editable*/background-color:#FFFFFF;
                                        /*@editable*/border-top:0;
                                        /*@editable*/border-bottom:0;
                                    }
                                /*
                                @tab Preheader
                                @section preheader text
                                @tip Set the styling for your email's preheader text. Choose a size and color that is easy to read.
                                */
                                    .preheaderContainer .mcnTextContent,.preheaderContainer .mcnTextContent p{
                                        /*@editable*/color:#606060;
                                        /*@editable*/font-family:Helvetica;
                                        /*@editable*/font-size:11px;
                                        /*@editable*/line-height:125%;
                                        /*@editable*/text-align:left;
                                    }
                                /*
                                @tab Preheader
                                @section preheader link
                                @tip Set the styling for your email's header links. Choose a color that helps them stand out from your text.
                                */
                                    .preheaderContainer .mcnTextContent a{
                                        /*@editable*/color:#606060;
                                        /*@editable*/font-weight:normal;
                                        /*@editable*/text-decoration:underline;
                                    }
                                /*
                                @tab Header
                                @section header style
                                @tip Set the background color and borders for your email's header area.
                                */
                                    #templateHeader{
                                        /*@editable*/background-color:#FFFFFF;
                                        /*@editable*/border-top:0;
                                        /*@editable*/border-bottom:0;
                                    }
                                /*
                                @tab Header
                                @section header text
                                @tip Set the styling for your email's header text. Choose a size and color that is easy to read.
                                */
                                    .headerContainer .mcnTextContent,.headerContainer .mcnTextContent p{
                                        /*@editable*/color:#606060;
                                        /*@editable*/font-family:Helvetica;
                                        /*@editable*/font-size:15px;
                                        /*@editable*/line-height:150%;
                                        /*@editable*/text-align:left;
                                    }
                                /*
                                @tab Header
                                @section header link
                                @tip Set the styling for your email's header links. Choose a color that helps them stand out from your text.
                                */
                                    .headerContainer .mcnTextContent a{
                                        /*@editable*/color:#6DC6DD;
                                        /*@editable*/font-weight:normal;
                                        /*@editable*/text-decoration:underline;
                                    }
                                /*
                                @tab Body
                                @section body style
                                @tip Set the background color and borders for your email's body area.
                                */
                                    #templateBody{
                                        /*@editable*/background-color:#FFFFFF;
                                        /*@editable*/border-top:0;
                                        /*@editable*/border-bottom:0;
                                    }
                                /*
                                @tab Body
                                @section body text
                                @tip Set the styling for your email's body text. Choose a size and color that is easy to read.
                                */
                                    .bodyContainer .mcnTextContent,.bodyContainer .mcnTextContent p{
                                        /*@editable*/color:#606060;
                                        /*@editable*/font-family:Helvetica;
                                        /*@editable*/font-size:15px;
                                        /*@editable*/line-height:150%;
                                        /*@editable*/text-align:left;
                                    }
                                /*
                                @tab Body
                                @section body link
                                @tip Set the styling for your email's body links. Choose a color that helps them stand out from your text.
                                */
                                    .bodyContainer .mcnTextContent a{
                                        /*@editable*/color:#6DC6DD;
                                        /*@editable*/font-weight:normal;
                                        /*@editable*/text-decoration:underline;
                                    }
                                /*
                                @tab Footer
                                @section footer style
                                @tip Set the background color and borders for your email's footer area.
                                */
                                    #templateFooter{
                                        /*@editable*/background-color:#FFFFFF;
                                        /*@editable*/border-top:0;
                                        /*@editable*/border-bottom:0;
                                    }
                                /*
                                @tab Footer
                                @section footer text
                                @tip Set the styling for your email's footer text. Choose a size and color that is easy to read.
                                */
                                    .footerContainer .mcnTextContent,.footerContainer .mcnTextContent p{
                                        /*@editable*/color:#606060;
                                        /*@editable*/font-family:Helvetica;
                                        /*@editable*/font-size:11px;
                                        /*@editable*/line-height:125%;
                                        /*@editable*/text-align:left;
                                    }
                                /*
                                @tab Footer
                                @section footer link
                                @tip Set the styling for your email's footer links. Choose a color that helps them stand out from your text.
                                */
                                    .footerContainer .mcnTextContent a{
                                        /*@editable*/color:#606060;
                                        /*@editable*/font-weight:normal;
                                        /*@editable*/text-decoration:underline;
                                    }
                                @media only screen and (max-width: 480px){
                                    body,table,td,p,a,li,blockquote{
                                        -webkit-text-size-adjust:none !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    body{
                                        width:100% !important;
                                        min-width:100% !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    .mcnRetinaImage{
                                        max-width:100% !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    td[id=bodyCell]{
                                        padding:10px !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    table[class=mcnTextContentContainer]{
                                        width:100% !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    .mcnBoxedTextContentContainer{
                                        max-width:100% !important;
                                        min-width:100% !important;
                                        width:100% !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    table[class=mcpreview-image-uploader]{
                                        width:100% !important;
                                        display:none !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    img[class=mcnImage]{
                                        width:100% !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    table[class=mcnImageGroupContentContainer]{
                                        width:100% !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    td[class=mcnImageGroupContent]{
                                        padding:9px !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    td[class=mcnImageGroupBlockInner]{
                                        padding-bottom:0 !important;
                                        padding-top:0 !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    tbody[class=mcnImageGroupBlockOuter]{
                                        padding-bottom:9px !important;
                                        padding-top:9px !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    table[class=mcnCaptionTopContent],table[class=mcnCaptionBottomContent]{
                                        width:100% !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    table[class=mcnCaptionLeftTextContentContainer],table[class=mcnCaptionRightTextContentContainer],table[class=mcnCaptionLeftImageContentContainer],table[class=mcnCaptionRightImageContentContainer],table[class=mcnImageCardLeftTextContentContainer],table[class=mcnImageCardRightTextContentContainer],.mcnImageCardLeftImageContentContainer,.mcnImageCardRightImageContentContainer{
                                        width:100% !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    td[class=mcnImageCardLeftImageContent],td[class=mcnImageCardRightImageContent]{
                                        padding-right:18px !important;
                                        padding-left:18px !important;
                                        padding-bottom:0 !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    td[class=mcnImageCardBottomImageContent]{
                                        padding-bottom:9px !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    td[class=mcnImageCardTopImageContent]{
                                        padding-top:18px !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    td[class=mcnImageCardLeftImageContent],td[class=mcnImageCardRightImageContent]{
                                        padding-right:18px !important;
                                        padding-left:18px !important;
                                        padding-bottom:0 !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    td[class=mcnImageCardBottomImageContent]{
                                        padding-bottom:9px !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    td[class=mcnImageCardTopImageContent]{
                                        padding-top:18px !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    table[class=mcnCaptionLeftContentOuter] td[class=mcnTextContent],table[class=mcnCaptionRightContentOuter] td[class=mcnTextContent]{
                                        padding-top:9px !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    td[class=mcnCaptionBlockInner] table[class=mcnCaptionTopContent]:last-child td[class=mcnTextContent],.mcnImageCardTopImageContent,.mcnCaptionBottomContent:last-child .mcnCaptionBottomImageContent{
                                        padding-top:18px !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    td[class=mcnBoxedTextContentColumn]{
                                        padding-left:18px !important;
                                        padding-right:18px !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    td[class=mcnTextContent]{
                                        padding-right:18px !important;
                                        padding-left:18px !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                /*
                                @tab Mobile Styles
                                @section template width
                                @tip Make the template fluid for portrait or landscape view adaptability. If a fluid layout doesn't work for you, set the width to 300px instead.
                                */
                                    table[id=templateContainer],table[id=templatePreheader],table[id=templateHeader],table[id=templateBody],table[id=templateFooter]{
                                        /*@tab Mobile Styles
                            @section template width
                            @tip Make the template fluid for portrait or landscape view adaptability. If a fluid layout doesn't work for you, set the width to 300px instead.*/max-width:600px !important;
                                        /*@editable*/width:100% !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                /*
                                @tab Mobile Styles
                                @section heading 1
                                @tip Make the first-level headings larger in size for better readability on small screens.
                                */
                                    h1{
                                        /*@editable*/font-size:24px !important;
                                        /*@editable*/line-height:125% !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                /*
                                @tab Mobile Styles
                                @section heading 2
                                @tip Make the second-level headings larger in size for better readability on small screens.
                                */
                                    h2{
                                        /*@editable*/font-size:20px !important;
                                        /*@editable*/line-height:125% !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                /*
                                @tab Mobile Styles
                                @section heading 3
                                @tip Make the third-level headings larger in size for better readability on small screens.
                                */
                                    h3{
                                        /*@editable*/font-size:18px !important;
                                        /*@editable*/line-height:125% !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                /*
                                @tab Mobile Styles
                                @section heading 4
                                @tip Make the fourth-level headings larger in size for better readability on small screens.
                                */
                                    h4{
                                        /*@editable*/font-size:16px !important;
                                        /*@editable*/line-height:125% !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                /*
                                @tab Mobile Styles
                                @section Boxed Text
                                @tip Make the boxed text larger in size for better readability on small screens. We recommend a font size of at least 16px.
                                */
                                    table[class=mcnBoxedTextContentContainer] td[class=mcnTextContent],td[class=mcnBoxedTextContentContainer] td[class=mcnTextContent] p{
                                        /*@editable*/font-size:18px !important;
                                        /*@editable*/line-height:125% !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                /*
                                @tab Mobile Styles
                                @section Preheader Visibility
                                @tip Set the visibility of the email's preheader on small screens. You can hide it to save space.
                                */
                                    table[id=templatePreheader]{
                                        /*@editable*/display:block !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                /*
                                @tab Mobile Styles
                                @section Preheader Text
                                @tip Make the preheader text larger in size for better readability on small screens.
                                */
                                    td[class=preheaderContainer] td[class=mcnTextContent],td[class=preheaderContainer] td[class=mcnTextContent] p{
                                        /*@editable*/font-size:14px !important;
                                        /*@editable*/line-height:115% !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                /*
                                @tab Mobile Styles
                                @section Header Text
                                @tip Make the header text larger in size for better readability on small screens.
                                */
                                    td[class=headerContainer] td[class=mcnTextContent],td[class=headerContainer] td[class=mcnTextContent] p{
                                        /*@editable*/font-size:18px !important;
                                        /*@editable*/line-height:125% !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                /*
                                @tab Mobile Styles
                                @section Body Text
                                @tip Make the body text larger in size for better readability on small screens. We recommend a font size of at least 16px.
                                */
                                    td[class=bodyContainer] td[class=mcnTextContent],td[class=bodyContainer] td[class=mcnTextContent] p{
                                        /*@editable*/font-size:18px !important;
                                        /*@editable*/line-height:125% !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                /*
                                @tab Mobile Styles
                                @section footer text
                                @tip Make the body content text larger in size for better readability on small screens.
                                */
                                    td[class=footerContainer] td[class=mcnTextContent],td[class=footerContainer] td[class=mcnTextContent] p{
                                        /*@editable*/font-size:14px !important;
                                        /*@editable*/line-height:115% !important;
                                    }

                            }	@media only screen and (max-width: 480px){
                                    td[class=footerContainer] a[class=utilityLink]{
                                        display:block !important;
                                    }

                            }</style></head>
                                <body leftmargin="0" marginwidth="0" topmargin="0" marginheight="0" offset="0">

                                    <center>
                                        <table align="center" border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable">
                                            <tr>
                                                <td align="center" valign="top" id="bodyCell">
                                                    <!-- BEGIN TEMPLATE // -->
                                                    <table border="0" cellpadding="0" cellspacing="0" width="600" id="templateContainer">
                                                        <tr>
                                                            <td align="center" valign="top">
                                                                <!-- BEGIN PREHEADER // -->
                                                                <table border="0" cellpadding="0" cellspacing="0" width="600" id="templatePreheader">
                                                                    <tr>
                                                                        <td valign="top" class="preheaderContainer" style="padding-top:9px;"><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width:100%;">
                                <tbody class="mcnTextBlockOuter">
                                    <tr>
                                        <td valign="top" class="mcnTextBlockInner" style="padding-top:9px;">
                                              <!--[if mso]>
                                            <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                                            <tr>
                                            <![endif]-->

                                            <!--[if mso]>
                                            <td valign="top" width="390" style="width:390px;">
                                            <![endif]-->
                                            <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width:390px;" width="100%" class="mcnTextContentContainer">
                                                <tbody><tr>

                                                    <td valign="top" class="mcnTextContent" style="padding-top:0; padding-left:18px; padding-bottom:9px; padding-right:18px;">


                                                    </td>
                                                </tr>
                                            </tbody></table>
                                            <!--[if mso]>
                                            </td>
                                            <![endif]-->

                                            <!--[if mso]>
                                            <td valign="top" width="210" style="width:210px;">
                                            <![endif]-->

                                            <!--[if mso]>
                                            </td>
                                            <![endif]-->

                                            <!--[if mso]>
                                            </tr>
                                            </table>
                                            <![endif]-->
                                        </td>
                                    </tr>
                                </tbody>
                            </table></td>
                                                                    </tr>
                                                                </table>
                                                                <!-- // END PREHEADER -->
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td align="center" valign="top">
                                                                <!-- BEGIN HEADER // -->
                                                                <table border="0" cellpadding="0" cellspacing="0" width="600" id="templateHeader">
                                                                    <tr>
                                                                        <td valign="top" class="headerContainer"><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageBlock" style="min-width:100%;">
                                <tbody class="mcnImageBlockOuter">
                                        <tr>
                                            <td valign="top" style="padding:9px" class="mcnImageBlockInner">
                                                <table align="left" width="100%" border="0" cellpadding="0" cellspacing="0" class="mcnImageContentContainer" style="min-width:100%;">
                                                    <tbody><tr>
                                                        <td class="mcnImageContent" valign="top" style="padding-right: 9px; padding-left: 9px; padding-top: 0; padding-bottom: 0;">

                                                                <a href="${environment.base_url}" title="" class="" target="_blank">
                                                                    <img align="left" alt="" src="https://mcusercontent.com/a24798abd1508f12878dc615c/images/4ae1a36e-18f0-4829-812c-323e39c1d993.png" width="564" style="max-width:900px; padding-bottom: 0; display: inline !important; vertical-align: bottom;" class="mcnImage">
                                                                </a>

                                                        </td>
                                                    </tr>
                                                </tbody></table>
                                            </td>
                                        </tr>
                                </tbody>
                            </table></td>
                                                                    </tr>
                                                                </table>
                                                                <!-- // END HEADER -->
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td align="center" valign="top">
                                                                <!-- BEGIN BODY // -->
                                                                <table border="0" cellpadding="0" cellspacing="0" width="600" id="templateBody">
                                                                    <tr>
                                                                        <td valign="top" class="bodyContainer"><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width:100%;">
                                <tbody class="mcnTextBlockOuter">
                                    <tr>
                                        <td valign="top" class="mcnTextBlockInner" style="padding-top:9px;">
                                              <!--[if mso]>
                                            <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                                            <tr>
                                            <![endif]-->

                                            <!--[if mso]>
                                            <td valign="top" width="600" style="width:600px;">
                                            <![endif]-->
                                            <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width:100%; min-width:100%;" width="100%" class="mcnTextContentContainer">
                                                <tbody><tr>

                                                    <td valign="top" class="mcnTextContent" style="padding-top:0; padding-right:18px; padding-bottom:9px; padding-left:18px;">

                                                        <div style="text-align: center;">
                            <div style="text-align: left;"><span style="font-size:30px"><span style="font-family:tahoma,verdana,segoe,sans-serif"><span style="color:#2f2e31"><strong>Bonjour,</strong></span></span></span><br>
                            &nbsp;
                            <p><span style="font-size:15px"><span style="color:#000000"><span style="font-family:tahoma,verdana,segoe,sans-serif">${self.user.value.email} vous invite à collaborer sur son espace Client Montri.<br>
                            <br>
                            Acceptez son invitation et créez votre compte en cliquant sur le bouton ci-dessous&nbsp;</span></span></span>:</p>
                            </div>
                            </div>

                                                    </td>
                                                </tr>
                                            </tbody></table>
                                            <!--[if mso]>
                                            </td>
                                            <![endif]-->

                                            <!--[if mso]>
                                            </tr>
                                            </table>
                                            <![endif]-->
                                        </td>
                                    </tr>
                                </tbody>
                            </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnButtonBlock" style="min-width:100%;">
                                <tbody class="mcnButtonBlockOuter">
                                    <tr>
                                        <td style="padding-top:0; padding-right:18px; padding-bottom:18px; padding-left:18px;" valign="top" align="center" class="mcnButtonBlockInner">
                                            <table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnButtonContentContainer" style="border-collapse: separate !important;border-radius: 6px;background-color: #FFC953;">
                                                <tbody>
                                                    <tr>
                                                        <td align="center" valign="middle" class="mcnButtonContent" style="font-family: Tahoma, Verdana, Segoe, sans-serif; font-size: 24px; padding: 20px;">
                                                            <a class="mcnButton " title="Accepter l'invitation" href="${environment.base_url}/user/signup" target="_blank" style="font-weight: bold;letter-spacing: normal;line-height: 100%;text-align: center;text-decoration: none;color: #FFFFFF;">Accepter l'invitation</a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width:100%;">
                                <tbody class="mcnTextBlockOuter">
                                    <tr>
                                        <td valign="top" class="mcnTextBlockInner" style="padding-top:9px;">
                                              <!--[if mso]>
                                            <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                                            <tr>
                                            <![endif]-->

                                            <!--[if mso]>
                                            <td valign="top" width="600" style="width:600px;">
                                            <![endif]-->
                                            <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width:100%; min-width:100%;" width="100%" class="mcnTextContentContainer">
                                                <tbody><tr>

                                                    <td valign="top" class="mcnTextContent" style="padding-top:0; padding-right:18px; padding-bottom:9px; padding-left:18px;">

                                                        <div style="text-align: center;">
                            <div style="text-align: left;">
                            <p><span style="font-size:15px"><span style="color:#000000"><span style="font-family:tahoma,verdana,segoe,sans-serif">Copiez le code ci-dessous et collez-le dans le champs <strong>"Code de l'invitation"</strong> pour accéder à votre espace.</span></span></span></p>
                            </div>
                            </div>

                                                    </td>
                                                </tr>
                                            </tbody></table>
                                            <!--[if mso]>
                                            </td>
                                            <![endif]-->

                                            <!--[if mso]>
                                            </tr>
                                            </table>
                                            <![endif]-->
                                        </td>
                                    </tr>
                                </tbody>
                            </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnBoxedTextBlock" style="min-width:100%;">
                                <!--[if gte mso 9]>
                                <table align="center" border="0" cellspacing="0" cellpadding="0" width="100%">
                                <![endif]-->
                                <tbody class="mcnBoxedTextBlockOuter">
                                    <tr>
                                        <td valign="top" class="mcnBoxedTextBlockInner">

                                            <!--[if gte mso 9]>
                                            <td align="center" valign="top" ">
                                            <![endif]-->
                                            <table align="left" border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width:100%;" class="mcnBoxedTextContentContainer">
                                                <tbody><tr>

                                                    <td style="padding-top:9px; padding-left:18px; padding-bottom:9px; padding-right:18px;">

                                                        <table border="0" cellspacing="0" class="mcnTextContentContainer" width="100%" style="min-width: 100% !important;background-color: #FFEECB;">
                                                            <tbody><tr>
                                                                <td valign="top" class="mcnTextContent" style="padding: 18px;color: #000000;font-family: Tahoma, Verdana, Segoe, sans-serif;font-size: 22px;font-weight: normal;text-align: center;">
                                                                    ${invit.id}
                                                                </td>
                                                            </tr>
                                                        </tbody></table>
                                                    </td>
                                                </tr>
                                            </tbody></table>
                                            <!--[if gte mso 9]>
                                            </td>
                                            <![endif]-->

                                            <!--[if gte mso 9]>
                                            </tr>
                                            </table>
                                            <![endif]-->
                                        </td>
                                    </tr>
                                </tbody>
                            </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width:100%;">
                                <tbody class="mcnTextBlockOuter">
                                    <tr>
                                        <td valign="top" class="mcnTextBlockInner" style="padding-top:9px;">
                                              <!--[if mso]>
                                            <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                                            <tr>
                                            <![endif]-->

                                            <!--[if mso]>
                                            <td valign="top" width="600" style="width:600px;">
                                            <![endif]-->
                                            <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width:100%; min-width:100%;" width="100%" class="mcnTextContentContainer">
                                                <tbody><tr>

                                                    <td valign="top" class="mcnTextContent" style="padding-top:0; padding-right:18px; padding-bottom:9px; padding-left:18px;">

                                                        <div style="text-align: center;">
                            <div style="text-align: left;">
                            <p><span style="font-size:15px"><span style="color:#000000"><span style="font-family:tahoma,verdana,segoe,sans-serif">Veillez à bien utiliser l'adresse email sur laquelle vous avez reçu cet email pour créer votre compte.</span></span></span></p>
                            </div>
                            </div>

                                                    </td>
                                                </tr>
                                            </tbody></table>
                                            <!--[if mso]>
                                            </td>
                                            <![endif]-->

                                            <!--[if mso]>
                                            </tr>
                                            </table>
                                            <![endif]-->
                                        </td>
                                    </tr>
                                </tbody>
                            </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageBlock" style="min-width:100%;">
                                <tbody class="mcnImageBlockOuter">
                                        <tr>
                                            <td valign="top" style="padding:9px" class="mcnImageBlockInner">
                                                <table align="left" width="100%" border="0" cellpadding="0" cellspacing="0" class="mcnImageContentContainer" style="min-width:100%;">
                                                    <tbody><tr>
                                                        <td class="mcnImageContent" valign="top" style="padding-right: 9px; padding-left: 9px; padding-top: 0; padding-bottom: 0; text-align:center;">


                                                                    <img align="center" alt="" src="https://mcusercontent.com/a24798abd1508f12878dc615c/images/d059d44b-3d91-432a-9773-a50604c25529.png" width="564" style="max-width:900px; padding-bottom: 0; display: inline !important; vertical-align: bottom;" class="mcnImage">


                                                        </td>
                                                    </tr>
                                                </tbody></table>
                                            </td>
                                        </tr>
                                </tbody>
                            </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width:100%;">
                                <tbody class="mcnTextBlockOuter">
                                    <tr>
                                        <td valign="top" class="mcnTextBlockInner" style="padding-top:9px;">
                                              <!--[if mso]>
                                            <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                                            <tr>
                                            <![endif]-->

                                            <!--[if mso]>
                                            <td valign="top" width="600" style="width:600px;">
                                            <![endif]-->
                                            <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width:100%; min-width:100%;" width="100%" class="mcnTextContentContainer">
                                                <tbody><tr>

                                                    <td valign="top" class="mcnTextContent" style="padding-top:0; padding-right:18px; padding-bottom:9px; padding-left:18px;">

                                                        <div style="text-align: center;">
                            <div style="text-align: left;">
                            <p><span style="font-size:20px"><span style="font-family:tahoma,verdana,segoe,sans-serif"><font color="#2f2e31"><strong>Comment visualiser vos modifications ?</strong></font></span></span><br>
                            <br>
                            <span style="font-size:15px"><span style="font-family:tahoma,verdana,segoe,sans-serif"><span style="color:#000000">Visualisez&nbsp;le rendu de votre application à tout moment sur votre téléphone en téléchargeant la version démo de l'application :</span></span></span></p>

                            <ul>
                                <li><span style="font-size:15px"><span style="font-family:tahoma,verdana,segoe,sans-serif"><span style="color:#000000">iOS (iPhone) : </span><a href="https://testflight.apple.com/join/vTivHyVn" target="_blank"><span style="color:#0000FF">https://testflight.apple.com/join/vTivHyVn</span></a></span></span></li>
                                <li><span style="font-size:15px"><span style="font-family:tahoma,verdana,segoe,sans-serif"><span style="color:#000000">Android : </span><a href="https://appdistribution.firebase.dev/i/1fa299dc06f41ee7" target="_blank"><span style="color:#0000FF">https://appdistribution.firebase.dev/i/1fa299dc06f41ee7</span></a></span></span></li>
                                <li><span style="font-size:15px"><span style="font-family:tahoma,verdana,segoe,sans-serif"><span style="color:#000000">Application web :</span> <a href="https://preprod.montri.fr/" target="_blank"><span style="color:#0000FF">https://preprod.montri.fr/</span></a></span></span></li>
                            </ul>
                            </div>
                            </div>

                                                    </td>
                                                </tr>
                                            </tbody></table>
                                            <!--[if mso]>
                                            </td>
                                            <![endif]-->

                                            <!--[if mso]>
                                            </tr>
                                            </table>
                                            <![endif]-->
                                        </td>
                                    </tr>
                                </tbody>
                            </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnDividerBlock" style="min-width:100%;">
                                <tbody class="mcnDividerBlockOuter">
                                    <tr>
                                        <td class="mcnDividerBlockInner" style="min-width: 100%; padding: 20px 18px;">
                                            <table class="mcnDividerContent" border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width: 100%;border-top: 3px solid #FFC953;">
                                                <tbody><tr>
                                                    <td>
                                                        <span></span>
                                                    </td>
                                                </tr>
                                            </tbody></table>
                            <!--
                                            <td class="mcnDividerBlockInner" style="padding: 18px;">
                                            <hr class="mcnDividerContent" style="border-bottom-color:none; border-left-color:none; border-right-color:none; border-bottom-width:0; border-left-width:0; border-right-width:0; margin-top:0; margin-right:0; margin-bottom:0; margin-left:0;" />
                            -->
                                        </td>
                                    </tr>
                                </tbody>
                            </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width:100%;">
                                <tbody class="mcnTextBlockOuter">
                                    <tr>
                                        <td valign="top" class="mcnTextBlockInner" style="padding-top:9px;">
                                              <!--[if mso]>
                                            <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                                            <tr>
                                            <![endif]-->

                                            <!--[if mso]>
                                            <td valign="top" width="600" style="width:600px;">
                                            <![endif]-->
                                            <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width:100%; min-width:100%;" width="100%" class="mcnTextContentContainer">
                                                <tbody><tr>

                                                    <td valign="top" class="mcnTextContent" style="padding-top:0; padding-right:18px; padding-bottom:9px; padding-left:18px;">

                                                        <div style="text-align: left;"><span style="color:#000000"><span style="font-size:15px"><span style="font-family:verdana,geneva,sans-serif">N'hésitez pas à me contacter pour toutes questions concernant nos outils et l'utilisation de nos services.<br>
                            <br>
                            Bien cordialement,<br>
                            Paul</span></span></span></div>

                                                    </td>
                                                </tr>
                                            </tbody></table>
                                            <!--[if mso]>
                                            </td>
                                            <![endif]-->

                                            <!--[if mso]>
                                            </tr>
                                            </table>
                                            <![endif]-->
                                        </td>
                                    </tr>
                                </tbody>
                            </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageBlock" style="min-width:100%;">
                                <tbody class="mcnImageBlockOuter">
                                        <tr>
                                            <td valign="top" style="padding:9px" class="mcnImageBlockInner">
                                                <table align="left" width="100%" border="0" cellpadding="0" cellspacing="0" class="mcnImageContentContainer" style="min-width:100%;">
                                                    <tbody><tr>
                                                        <td class="mcnImageContent" valign="top" style="padding-right: 9px; padding-left: 9px; padding-top: 0; padding-bottom: 0;">


                                                                    <img align="left" alt="" src="https://mcusercontent.com/a24798abd1508f12878dc615c/images/0f821d07-fc33-45af-99be-982e21640a89.png" width="100" style="max-width:100px; padding-bottom: 0; display: inline !important; vertical-align: bottom;" class="mcnImage">


                                                        </td>
                                                    </tr>
                                                </tbody></table>
                                            </td>
                                        </tr>
                                </tbody>
                            </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width:100%;">
                                <tbody class="mcnTextBlockOuter">
                                    <tr>
                                        <td valign="top" class="mcnTextBlockInner" style="padding-top:9px;">
                                              <!--[if mso]>
                                            <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                                            <tr>
                                            <![endif]-->

                                            <!--[if mso]>
                                            <td valign="top" width="600" style="width:600px;">
                                            <![endif]-->
                                            <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width:100%; min-width:100%;" width="100%" class="mcnTextContentContainer">
                                                <tbody><tr>

                                                    <td valign="top" class="mcnTextContent" style="padding-top:0; padding-right:18px; padding-bottom:9px; padding-left:18px;">

                                                        <div style="text-align: left;"><span style="font-size:12px"><span style="color:#000000"><span style="font-family:verdana,geneva,sans-serif">Mob.: </span></span><span style="color:#0000FF"><span style="font-family:verdana,geneva,sans-serif">06 58 16 55 08</span></span><br>
                            <span style="color:#000000"><span style="font-family:verdana,geneva,sans-serif">Email : </span></span><a href="mailto:palarcon@uzer.eu?subject=J'ai%20besoin%20d'aide" target="_blank"><span style="color:#0000FF"><span style="font-family:verdana,geneva,sans-serif">palarcon@uzer.eu</span></span></a></span></div>

                                                    </td>
                                                </tr>
                                            </tbody></table>
                                            <!--[if mso]>
                                            </td>
                                            <![endif]-->

                                            <!--[if mso]>
                                            </tr>
                                            </table>
                                            <![endif]-->
                                        </td>
                                    </tr>
                                </tbody>
                            </table></td>
                                                                    </tr>
                                                                </table>
                                                                <!-- // END BODY -->
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td align="center" valign="top">
                                                                <!-- BEGIN FOOTER // -->
                                                                <table border="0" cellpadding="0" cellspacing="0" width="600" id="templateFooter">
                                                                    <tr>
                                                                        <td valign="top" class="footerContainer" style="padding-bottom:9px;"></td>
                                                                    </tr>
                                                                </table>
                                                                <!-- // END FOOTER -->
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    <!-- // END TEMPLATE -->
                                                </td>
                                            </tr>
                                        </table>
                                    </center>
                                </body>
                            </html>
                </code>`,
                },
            })
            .then(function (docRef) {
                self.toastr.openSuccessToast("Votre invitation a été bien renvoyée","")
                self.lodingBarService.setStatus(false);
            })
            .catch(function (error) {
                self.toastr.openErrorToast("Envoi d'invitation non réussi réssayer de nouveau","")
                self.lodingBarService.setStatus(false);
            });
        }
    }

    retractInvit(invit: InvitID) {
        this.lodingBarService.setStatus(true);
        var self = this;
        this.afs
            .collection('invitsBo')
            .doc(invit.id)
            .delete()
            .then(function (docRef) {
                self.toastr.openSuccessToast("Rétractation avec succès","")
                self.lodingBarService.setStatus(false);
            })
            .catch(function (error) {
                self.toastr.openErrorToast("Rétractation non réussie, réessayer à nouveau","")
                self.lodingBarService.setStatus(false);
            });
    }

    deleteUser(invit: InvitID) {
        this.lodingBarService.setStatus(true);
        var self = this;

        this.afs
            .collection('usersBo', (ref) =>
                ref.where('email', '==', invit.reciver_email)
            )
            .snapshotChanges()
            .pipe(
                map((actions) => {
                    return actions.map((a) => {
                        const data = a.payload.doc.data() as UserBo;
                        const id = a.payload.doc.id;
                        return { id, ...data };
                    });
                })
            )
            .subscribe((data) => {
                this.afs
                    .doc(`usersBo/${data[0].id}`)
                    .delete()
                    .catch((error) => {
                        self.toastr.openErrorToast("Suppression du partenaire non réussie, réessayer à nouveau","")
                        self.lodingBarService.setStatus(false);
                    })
                    .then(() => {
                        this.afs
                            .collection('invitsBo')
                            .doc(invit.id)
                            .delete()
                            .then(function (docRef) {
                                self.toastr.openErrorToast("Suppression du partenaire avec succès","")
                                self.lodingBarService.setStatus(false);
                            })
                            .catch(function (error) {
                                self.toastr.openErrorToast("Suppression du partenaire non réussie, réessayer à nouveau","")
                                self.lodingBarService.setStatus(false);
                            });
                    });
            });
    }

    isexist(email: string) {
        return this.afs
            .collection(`invitsBo`, (ref) =>
                ref.where('reciver_email', '==', email)
            )
            .snapshotChanges();
    }
}

import { MenuService } from './../../../../services/menu/menu.service';
import { error } from 'protractor';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-fullstats',
    templateUrl: './fullstats.component.html',
    styleUrls: ['./fullstats.component.scss']
})
export class FullstatsComponent implements OnInit {

    zoom = 15;

    center: google.maps.LatLngLiteral = {
        lat: 48.856613,
        lng: 2.352222
    };
    optionsMap: google.maps.MapOptions = {
        mapTypeId: "roadmap",
        maxZoom: 50,
        minZoom: 8,
    };

    button_toogle = true
    myType = 'AreaChart';
    myData = [
        ["0", 300],
        ["1", 2570],
        ["2", 2200],
        ["3", 3660],
        ["4", 2570],
        ["5", 300]
    ];

    options = {
        colors: ['#FCB625'],
        lineWidth: 5,
        legend: 'none',
        vAxis: {
            baselineColor: 'none',
            ticks: []
        },
        pointSize: 20,
        pointShape: 'circle',
        hAxis: { gridlines: { count: 10 } },
        chartArea: {
            width: '100%',
        },
        width: '100%'
    }



    scanDevicetype = 'AreaChart';
    scanDeviceData = [
        ["0", 300],
        ["1", 2570],
        ["2", 2200],
        ["3", 3660],
        ["4", 2570],
        ["5", 300]
    ];

    scanDeviceOptions = {
        colors: ['#B9CEF9'],
        lineWidth: 5,
        legend: 'none',
        vAxis: {
            baselineColor: 'none',
            ticks: []
        },
        pointSize: 20,
        pointShape: 'circle',
        hAxis: { gridlines: { count: 10 } },
        chartArea: {
            width: '100%',
        },
        width: '100%'
    }


    useRatechart = 'LineChart';
    useRateData = [
        ["LUN", 300],
        ["MAR", 2570],
        ["MER", 2200],
        ["JEU", 3660],
        ["VEN", 2570],
        ["SAM", 300],
        ["DIM", 300]
    ];
    useRateOptions = {
        curveType: 'function',
        colors: ['#B9CEF9'],
        legend: 'none',
        vAxis: {
            baselineColor: 'none',
            ticks: []
        },
        pointSize: 20,
        pointShape: 'circle',
        hAxis: {
            gridlines: { count: 10 },
            textStyle: {
                fontSize: 12,
                bold: true,

            }
        },
        chartArea: {
            top: '60',
            width: '100%',
        },
        width: '100%'
    }


    recognitionProductchart = "PieChart"

    recognitionProductData = [
        ['Produits reconnu', 3400],
        ['Produits non reconnu', 800],
    ];

    options2 = {
        width: '160',
        height: '160',
        pieHole: 0.9,
        legend: 'none',
        colors: ['#FCB625', '#E2E2E2'],
        chartArea: {
            top: '10',
            left: '10',
            right: '10',
            bottom: '10',
            width: '100%',
            height: '100%'
        }
    };

    deviceRecognisingProductsType = "PieChart"

    deviceRecognisingProductsData = [
        ['Produits reconnu', 3400],
        ['Produits non reconnu', 800],
    ];

    deviceRecognisingProductsOptions = {
        width: '160',
        height: '160',
        pieHole: 0.9,
        legend: 'none',
        colors: ['#B9CEF9', '#E2E2E2'],
        chartArea: {
            top: '10',
            left: '10',
            right: '10',
            bottom: '10',
            width: '100%',
            height: '100%'
        }
    };


    RTHTSansType = "PieChart"

    RTHScansData = [
        ['00 - 6h', 3400],
        ['06 - 09h', 800],
        ['09 - 12h', 3400],
        ['12 - 14h', 800],
        ['14 - 18h', 3400],
        ['18 - 20h', 800],
        ['20 - 22h', 3400],
    ];

    RTHScansOptions = {
        width: '300',
        height: '300',
        pieHole: 0.6,
        legend: { position: 'right', alignment: 'center' },
        colors: ['#739df3', '#8aadf5', '#a2bef7', '#b9cef9', '#d0defb', '#e8effd', '#e8effd'],
        chartArea: {
            top: '0',

            bottom: '20',
            width: '100%',
            height: '100%'
        }
    };


    constructor(
        private menuservice : MenuService
    ) {

    }



    ngOnInit(): void {
        this.menuservice.setSection("statistiques");
        this.menuservice.setSubsection("stats");
    }

    onchange($event) {


    }

}

import { Flux, WasteFlux } from './../../models/flux/flux';
import { ContratService } from './../contrat/contrat.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LodingBarService } from './../lodingbar/loding-bar-service.service';
import { AlertService } from './../alert/alert.service';
import { Alert } from './../../models/alert/alert';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject } from 'rxjs';
import { Contrat, Service, Contact, ReseauxSociaux } from './../../models/contrat/contrat';
import { CONTRACTCONST } from "./../../const/contrat/contrat";
import { Route } from '@angular/compiler/src/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class FluxService {
    flux : BehaviorSubject<WasteFlux> = new BehaviorSubject<WasteFlux>(null);
    flux_obs = this.flux.asObservable()
    contrat : Contrat

  constructor(
        private afs : AngularFirestore,
        private alertservices : AlertService,
        private lodingBarService : LodingBarService,
        private router : Router,
        private contratService : ContratService,
        private toastr: ToastrService
    ) {

    }

    loadFlux(idContrat: string){
           this.fluxFromDatabase(idContrat).subscribe((data) => {
               if(data !=  null){
                    var flux_data = data.payload.data()
                    this.flux.next(flux_data as WasteFlux);
               }
            });
    }

    setFlux(flux : WasteFlux){
        this.flux.next(flux)
    }

    saveFlux(contrat : Contrat , next_url : string,status : boolean){
        var self = this ;
        self.afs.collection('flux').doc(contrat.contrat.idContrat).set(JSON.parse(JSON.stringify(this.flux.value)))
                            .then(function() {
                                if(status){
                                    self.contratService.setContrat(contrat)
                                    self.contratService.saveContrat(next_url)
                                }
                                else{
                                    self.toastr.success('Données enregistrées avec succès', "",{
                                        closeButton: true,
                                        positionClass: 'toast-bottom-left',
                                    });
                                    self.lodingBarService.setStatus(false);
                                    self.router.navigateByUrl(next_url)
                                }

                            })
                            .catch(function(error) {
                                self.toastr.warning(`Chargement échoué. Veuillez verifier votre fichier. ${error}`, "",{
                                    closeButton: true,
                                    positionClass: 'toast-bottom-left',
                                });
                                self.lodingBarService.setStatus(false);

        });
    }

    fluxFromDatabase(idContart: string) {
		return this.afs.doc('flux/'+idContart).snapshotChanges();
	}

}

import { Alert } from './../../models/alert/alert';
import { ListActus } from './../../models/actus/actus';
import { BehaviorSubject } from 'rxjs';
import { LodingBarService } from './../lodingbar/loding-bar-service.service';
import { AlertService } from './../alert/alert.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root',
})
export class ActusService {

    actus: BehaviorSubject<ListActus> = new BehaviorSubject<ListActus>(null);
    actus_obs = this.actus.asObservable()
    constructor(
        private afs: AngularFirestore,
        private alertservices: AlertService,
        private lodingBarService: LodingBarService,
        
    ) {}

    loadActus(idContrat: string){
        this.actusFromDatabase(idContrat).subscribe((data) => {
            var list_actus = data.payload.data() == undefined  ?  new ListActus({}) : new ListActus(data.payload.data())
            this.actus.next(list_actus);
        });
    }

    actusFromDatabase(idContrat: string) {
        return this.afs.collection("actualites").doc(idContrat).snapshotChanges()
    }

    async saveActus(idContrat: string){
        this.lodingBarService.setStatus(true);
        var self = this;
        
        return self.afs.collection('actualites').doc(idContrat).set({...this.actus.value})
            .then(function () {
                self.lodingBarService.setStatus(false);
                return "success"
            })
            .catch(function (error) {
                self.lodingBarService.setStatus(false);
                return "error"
            });
    }
}

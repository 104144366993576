
<div class="row p-3">
    <div class="col-lg-12">
         <p class="h3_heavy">Paramétrez cet écran :</p>
        <input type="text" autofocus="autofocus" style="display:none" />
        <div class="form-group row">
            <label for="staticEmail" class="col-sm-2 col-form-label p2" >Titre :</label>
            <div class="col-sm-10">
                <input autocomplete="off" class="form-control p2" [(ngModel)]="titre" maxlength="60" (ngModelChange)="changeinput()" style="box-shadow: inset 0px 0px 0px 0px;"
                onfocus="this.placeholder = ''" onblur="this.placeholder = 'Tapez ici'"
                style="font-family:Arial, FontAwesome" placeholder="Tapez ici" />
                <p class="p3">{{titre.length}}/60</p>
            </div>
        </div>


        <input type="text" autofocus="autofocus" style="display:none" />
        <div class="form-group row">
            <label for="staticEmail" class="col-sm-2 col-form-label p2">Description 1 </label>
            <div class="col-sm-10">
                <textarea autocomplete="off" class="form-control p2"  maxlength="140" [(ngModel)]="description1" (ngModelChange)="changeinput()" style="box-shadow: inset 0px 0px 0px 0px;" rows ="3"
                onfocus="this.placeholder = ''" onblur="this.placeholder = 'Tapez ici'"
                style="font-family:Arial, FontAwesome" placeholder="Tapez ici" ></textarea>
                <p class="p3">{{description1.length}}/140</p>
            </div>
        </div>


        <input type="text" autofocus="autofocus" style="display:none" />
        <div class="form-group row">
            <label for="staticEmail" class="col-sm-2 col-form-label p2">Description 2 </label>
            <div class="col-sm-10">
                <textarea autocomplete="off" class="form-control p2"   maxlength="140" [(ngModel)]="description2" (ngModelChange)="changeinput()" style="box-shadow: inset 0px 0px 0px 0px;" rows ="3"
                onfocus="this.placeholder = ''" onblur="this.placeholder = 'Tapez ici'"
                style="font-family:Arial, FontAwesome" placeholder="Tapez ici" ></textarea>
                <p class="p3">{{description2.length}}/140</p>
            </div>
        </div>
    </div>
</div>

<div class="row  mb-4 ml-2">
    <div class="col-lg-3 d-flex flex-row justify-content-center">
        <button type="button" class="btn button4 btn-primary w-100" (click)="saveservice()">Enregister</button>
    </div>
    <div class="col-lg-3 mt-3 mt-lg-0 d-flex flex-row justify-content-center">
        <button type="button" class="btn button7 btn-primary w-100" (click)="cancel()">Annuler</button>
    </div>
</div>


import { element } from 'protractor';
import { SendinvitComponent } from './../../modals/sendinvit/sendinvit.component';
import { RetractinvitComponent } from './../../modals/retractinvit/retractinvit.component';
import { ContratService } from './../../../../services/contrat/contrat.service';
import { UpdatepasswordComponent } from './../../modals/updatepassword/updatepassword.component';
import { NgbModalOptions, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MenuService } from './../../../../services/menu/menu.service';
import { User } from 'firebase';
import { Invit, InvitID } from './../../../../models/invit/invit';
import { AuthentificationService } from './../../../../services/authentification/authentification.service';
import { Alert } from './../../../../models/alert/alert';
import { UserBo, userInvited, contratInfos } from './../../../../models/user/user';
import { FormBuilder } from '@angular/forms';
import { UserService } from './../../../../services/user/user.service';
import { AlertService } from './../../../../services/alert/alert.service';
import { LodingBarService } from './../../../../services/lodingbar/loding-bar-service.service';
import { Component, OnInit, ViewChild, AfterContentChecked } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { AngularFireStorage } from "@angular/fire/storage";
import * as $ from 'jquery';
import { ToastAlertsComponent } from '../toast-alerts/toast-alerts.component';
import {
    takeWhile,
    tap,
    find,
    finalize,
    last,
    switchMap,
} from "rxjs/operators";

@Component({
    selector: 'app-account',
    templateUrl: './account.component.html',
    styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit, AfterContentChecked {

    UserForm;
    InvitForm;
    userBo: UserBo;
    imageUrl: string | ArrayBuffer = './../../../../../assets/img/026-addpicture.svg';
    fieldTextType1: boolean;
    fieldTextType2: boolean;
    fieldTextType3: boolean;
    invits: InvitID[] = [];
    mailsList : InvitID[] = []
    nomError: boolean = false
    prenomError: boolean = false
    mailError: boolean = false
    tlphError: boolean = false
    contratToAdd : string = "Choisissez un contrat"
    userToAdd : string = "choisissez un email"
    contratsList  : contratInfos[]

    constructor(
        public afs: AngularFirestore,   // Inject Firestore service
        public afAuth: AngularFireAuth, // Inject Firebase auth service
        public router: Router,
        private lodingBarService: LodingBarService,
        private alertservices: AlertService,
        private formBuilder: FormBuilder,
        private afStorage: AngularFireStorage,
        private authservice: AuthentificationService,
        private userService: UserService,
        private modalService: NgbModal,
        private menuservice: MenuService,
        private toastr: ToastAlertsComponent
    ) { }

    ngOnInit(): void {
        var self = this;
        this.menuservice.setSection('paramétrage')
        this.menuservice.setSubsection('')
        this.InvitForm = this.formBuilder.group({
            email_invit: "",
            role_invit: "Normal"
        });
        this.userService.profile_image_obs.subscribe((url) => {
            if (url == undefined || url == null || url == "") {
                this.imageUrl = './../../../../../assets/img/026-addpicture.svg';
            }
            else {
                this.imageUrl = url;
            }
        });
        this.userService.user_obs.subscribe((user) => {
            if (user == null) {

                this.UserForm = this.formBuilder.group({
                    email_user: "",
                    old_password: "",
                    new_password: "",
                    firstname_user: "",
                    lastname_user: "",
                    phone_user: "",
                });
            }
            else {
                self.userBo = user;
                this.UserForm = this.formBuilder.group({
                    email_user: user.email,
                    old_password: "",
                    new_password: "",
                    firstname_user: user.prenom,
                    lastname_user: user.nom,
                    phone_user: user.telephone
                });

            }
        })

        this.userService.invits_obs.subscribe((data) => {
            if(data != null){
                this.invits = data;
                this.mailsList = this.invits.filter(inv => inv.is_accepted)
            }
        })
    }

    ngAfterContentChecked() {
    }

    toastError(str){
        this.toastr.openFailedToast(str, "");
    }

    onSubmit(userData) {

        if (userData['lastname_user'] == "") {
            this.nomError = true
            this.toastError('Certains champs obligatoires ne sont pas remplis.')
        }
        else if (userData['firstname_user'] == "") {
            this.prenomError = true
            this.toastError('Certains champs obligatoires ne sont pas remplis.')
        }
        else if (userData['phone_user'] == "") {
            this.tlphError = true
            this.toastError('Certains champs obligatoires ne sont pas remplis.')
        }
        else if (userData['mail_user']) {
            this.tlphError = true
            this.toastError('Certains champs obligatoires ne sont pas remplis.')
        }
        else {

            var decimal = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
            if (userData["new_password"].match(decimal) || userData["new_password"] == "") {


                if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(userData["email_user"])) {
                    this.lodingBarService.setStatus(true);
                    this.userBo.prenom = userData['firstname_user']
                    this.userBo.nom = userData['lastname_user']
                    this.userBo.telephone = userData['phone_user']
                    this.userBo.new_password = userData['new_password']
                    this.userBo.old_password = userData['old_password']
                    this.userBo.configStatus = true
                    this.userService.updateUser(this.userBo)
                }
                else {
                    this.toastr.openErrorToast("Merci d'entrer une adresse e-mail valide",'')
                    this.lodingBarService.setStatus(false);

                }


            }
            else {
                this.toastr.openErrorToast("Votre mot de passe doit comporter un minimum de 8 caractères, au moins une lettre minuscule, une lettre majuscule, un chiffre numérique et un caractère spécial",'')
                this.lodingBarService.setStatus(false);

            }
        }
    }

    onChange(file: File) {
        var self = this;
        this.afAuth.authState.subscribe(user => {
            if (file) {
                const randomId = Math.random().toString(36).substring(2);
                var ref = this.afStorage.ref("Profiles-photos/" + user.uid);
                var task = ref.put(file);
                task
                    .snapshotChanges()
                    .pipe(
                        last(),
                        switchMap(() => ref.getDownloadURL())
                    )

                    .subscribe((url) => this.userService.setProfileImage(url))
            }
        })
    }

    toggleFieldTextType1() {
        this.fieldTextType1 = !this.fieldTextType1;
    }

    toggleFieldTextType2() {
        this.fieldTextType2 = !this.fieldTextType2;
    }

    toggleFieldTextType3() {
        this.fieldTextType3 = !this.fieldTextType3;
    }

    OnSubmitInvit(invitData) {
        var verif = 0;
        this.userService.isexist(invitData["email_invit"]).subscribe((data) => {
            if (data != null && data != undefined && verif == 0) {
                if (data.length > 0) {
                    if ((data[0].payload.doc.data() as Invit).is_accepted == false) {
                        var alert: Alert = {
                            type: "danger",
                            message: " ❗ Vous avez déja envoyé une invitation à ce collaborateur essayé de le renvoyer de nouveau avec le button renvoyer",
                        };
                        this.alertservices.setAlert(alert);
                    }
                    else {
                        var alert: Alert = {
                            type: "danger",
                            message: " ❗ Cette adresse email est déja en collaboration sur ce contrat",
                        };
                        this.alertservices.setAlert(alert);
                    }
                }
                else {
                    var invit = new Invit()
                    var userinvited = new userInvited()
                    invit.id_contrat = this.userBo.idContrat;
                    invit.nom_contrat = this.userBo.listContrats.filter(element => element.idContrat == this.userBo.idContrat)[0].nomContrat
                    invit.sender_email = this.userBo.email
                    invit.reciver_email = invitData["email_invit"]
                    invit.reciver_role = invitData["role_invit"]
                    invit.is_accepted = false;
                    verif = 1;
                    this.userService.sendInvit(invit)
                }
            }
        })

    }

    renvoyerInvit(invit: InvitID) {
        window.scrollTo(0, 0);
        let options: NgbModalOptions = {};
        options.centered = true
        this.modalService.dismissAll()
        const modalRef = this.modalService.open(SendinvitComponent, options)
        modalRef.componentInstance.invit = invit;

    }

    retractInvit(invit: InvitID) {
        window.scrollTo(0, 0);
        let options: NgbModalOptions = {};
        options.centered = true
        this.modalService.dismissAll()
        const modalRef = this.modalService.open(RetractinvitComponent, options)
        modalRef.componentInstance.invit = invit;

    }

    deleteUser(invit: InvitID) {
        this.userService.deleteUser(invit)
    }

    addCollaborateur(){

    }

    updateInvitedUser() {
        var  self =  this ;

        var  check  = 0
        var  selectedContrat = this.userBo.listContrats.filter(item=> item.idContrat == this.contratToAdd)[0]
        if(this.userToAdd == "choisissez un email"){
            var alert: Alert = {
                type: 'danger',
                message:
                    ' ❗ Merci de bien choisir une adresse email',
            };

            self.alertservices.setAlert(alert);
            return
        }

        if(this.contratToAdd == "Choisissez un contrat"){
            var alert: Alert = {
                type: 'danger',
                message:
                    ' ❗ Merci de bien choisir un  contrat',
            };

            self.alertservices.setAlert(alert);
            return
        }
        self.lodingBarService.setStatus(true);
        this.userFromDatabase().subscribe((data) => {
            if(check == 0){
                check = 1
                self.contratsList = []
                        data.map((a) => {
                            const data = a.payload.doc.data() as UserBo;
                            const id = a.payload.doc.id;
                            data.listContrats.push(selectedContrat)
                            self.afs
                            .collection('usersBo')
                            .doc(id)
                            .set(JSON.parse(JSON.stringify(data)))
                            .then(function () {
                                var alert: Alert = {
                                    type: 'success',
                                    message:
                                        ' ✔️ Votre collaborateur a été bien ajouté au contrat choisi',
                                };
                                self.alertservices.setAlert(alert);
                                self.lodingBarService.setStatus(false);
                                self.afs.collection('mail').add({
                                    to: self.userToAdd,
                                    message: {
                                        subject: 'Montri - Collaboration sur un nouveau contrat',
                                        html: `<code>
                                        <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
                                        <html xmlns="http://www.w3.org/1999/xhtml">
                                            <head>
                                                <!-- NAME: 1 COLUMN -->
                                                <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
                                                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                                                <title>*|MC:SUBJECT|*</title>

                                            <style type="text/css">
                                                body,#bodyTable,#bodyCell{
                                                    height:100% !important;
                                                    margin:0;
                                                    padding:0;
                                                    width:100% !important;
                                                }
                                                table{
                                                    border-collapse:collapse;
                                                }
                                                img,a img{
                                                    border:0;
                                                    outline:none;
                                                    text-decoration:none;
                                                }
                                                h1,h2,h3,h4,h5,h6{
                                                    margin:0;
                                                    padding:0;
                                                }
                                                p{
                                                    margin:1em 0;
                                                    padding:0;
                                                }
                                                a{
                                                    word-wrap:break-word;
                                                }
                                                .mcnPreviewText{
                                                    display:none !important;
                                                }
                                                .ReadMsgBody{
                                                    width:100%;
                                                }
                                                .ExternalClass{
                                                    width:100%;
                                                }
                                                .ExternalClass,.ExternalClass p,.ExternalClass span,.ExternalClass font,.ExternalClass td,.ExternalClass div{
                                                    line-height:100%;
                                                }
                                                table,td{
                                                    mso-table-lspace:0pt;
                                                    mso-table-rspace:0pt;
                                                }
                                                #outlook a{
                                                    padding:0;
                                                }
                                                img{
                                                    -ms-interpolation-mode:bicubic;
                                                }
                                                body,table,td,p,a,li,blockquote{
                                                    -ms-text-size-adjust:100%;
                                                    -webkit-text-size-adjust:100%;
                                                }
                                                #templatePreheader,#templateHeader,#templateBody,#templateFooter{
                                                    min-width:100%;
                                                }
                                                #bodyCell{
                                                    padding:20px;
                                                }
                                                .mcnImage,.mcnRetinaImage{
                                                    vertical-align:bottom;
                                                }
                                                .mcnTextContent img{
                                                    height:auto !important;
                                                }
                                            /*
                                            @tab Page
                                            @section background style
                                            @tip Set the background color and top border for your email. You may want to choose colors that match your company's branding.
                                            */
                                                body,#bodyTable{
                                                    /*@editable*/background-color:#F2F2F2;
                                                }
                                            /*
                                            @tab Page
                                            @section background style
                                            @tip Set the background color and top border for your email. You may want to choose colors that match your company's branding.
                                            */
                                                #bodyCell{
                                                    /*@editable*/border-top:0;
                                                }
                                            /*
                                            @tab Page
                                            @section email border
                                            @tip Set the border for your email.
                                            */
                                                #templateContainer{
                                                    /*@editable*/border:0;
                                                }
                                            /*
                                            @tab Page
                                            @section heading 1
                                            @tip Set the styling for all first-level headings in your emails. These should be the largest of your headings.
                                            @style heading 1
                                            */
                                                h1{
                                                    /*@editable*/color:#606060 !important;
                                                    display:block;
                                                    /*@editable*/font-family:Helvetica;
                                                    /*@editable*/font-size:40px;
                                                    /*@editable*/font-style:normal;
                                                    /*@editable*/font-weight:bold;
                                                    /*@editable*/line-height:125%;
                                                    /*@editable*/letter-spacing:-1px;
                                                    margin:0;
                                                    /*@editable*/text-align:left;
                                                }
                                            /*
                                            @tab Page
                                            @section heading 2
                                            @tip Set the styling for all second-level headings in your emails.
                                            @style heading 2
                                            */
                                                h2{
                                                    /*@editable*/color:#404040 !important;
                                                    display:block;
                                                    /*@editable*/font-family:Helvetica;
                                                    /*@editable*/font-size:26px;
                                                    /*@editable*/font-style:normal;
                                                    /*@editable*/font-weight:bold;
                                                    /*@editable*/line-height:125%;
                                                    /*@editable*/letter-spacing:-.75px;
                                                    margin:0;
                                                    /*@editable*/text-align:left;
                                                }
                                            /*
                                            @tab Page
                                            @section heading 3
                                            @tip Set the styling for all third-level headings in your emails.
                                            @style heading 3
                                            */
                                                h3{
                                                    /*@editable*/color:#606060 !important;
                                                    display:block;
                                                    /*@editable*/font-family:Helvetica;
                                                    /*@editable*/font-size:18px;
                                                    /*@editable*/font-style:normal;
                                                    /*@editable*/font-weight:bold;
                                                    /*@editable*/line-height:125%;
                                                    /*@editable*/letter-spacing:-.5px;
                                                    margin:0;
                                                    /*@editable*/text-align:left;
                                                }
                                            /*
                                            @tab Page
                                            @section heading 4
                                            @tip Set the styling for all fourth-level headings in your emails. These should be the smallest of your headings.
                                            @style heading 4
                                            */
                                                h4{
                                                    /*@editable*/color:#808080 !important;
                                                    display:block;
                                                    /*@editable*/font-family:Helvetica;
                                                    /*@editable*/font-size:16px;
                                                    /*@editable*/font-style:normal;
                                                    /*@editable*/font-weight:bold;
                                                    /*@editable*/line-height:125%;
                                                    /*@editable*/letter-spacing:normal;
                                                    margin:0;
                                                    /*@editable*/text-align:left;
                                                }
                                            /*
                                            @tab Preheader
                                            @section preheader style
                                            @tip Set the background color and borders for your email's preheader area.
                                            */
                                                #templatePreheader{
                                                    /*@editable*/background-color:#FFFFFF;
                                                    /*@editable*/border-top:0;
                                                    /*@editable*/border-bottom:0;
                                                }
                                            /*
                                            @tab Preheader
                                            @section preheader text
                                            @tip Set the styling for your email's preheader text. Choose a size and color that is easy to read.
                                            */
                                                .preheaderContainer .mcnTextContent,.preheaderContainer .mcnTextContent p{
                                                    /*@editable*/color:#606060;
                                                    /*@editable*/font-family:Helvetica;
                                                    /*@editable*/font-size:11px;
                                                    /*@editable*/line-height:125%;
                                                    /*@editable*/text-align:left;
                                                }
                                            /*
                                            @tab Preheader
                                            @section preheader link
                                            @tip Set the styling for your email's header links. Choose a color that helps them stand out from your text.
                                            */
                                                .preheaderContainer .mcnTextContent a{
                                                    /*@editable*/color:#606060;
                                                    /*@editable*/font-weight:normal;
                                                    /*@editable*/text-decoration:underline;
                                                }
                                            /*
                                            @tab Header
                                            @section header style
                                            @tip Set the background color and borders for your email's header area.
                                            */
                                                #templateHeader{
                                                    /*@editable*/background-color:#FFFFFF;
                                                    /*@editable*/border-top:0;
                                                    /*@editable*/border-bottom:0;
                                                }
                                            /*
                                            @tab Header
                                            @section header text
                                            @tip Set the styling for your email's header text. Choose a size and color that is easy to read.
                                            */
                                                .headerContainer .mcnTextContent,.headerContainer .mcnTextContent p{
                                                    /*@editable*/color:#606060;
                                                    /*@editable*/font-family:Helvetica;
                                                    /*@editable*/font-size:15px;
                                                    /*@editable*/line-height:150%;
                                                    /*@editable*/text-align:left;
                                                }
                                            /*
                                            @tab Header
                                            @section header link
                                            @tip Set the styling for your email's header links. Choose a color that helps them stand out from your text.
                                            */
                                                .headerContainer .mcnTextContent a{
                                                    /*@editable*/color:#6DC6DD;
                                                    /*@editable*/font-weight:normal;
                                                    /*@editable*/text-decoration:underline;
                                                }
                                            /*
                                            @tab Body
                                            @section body style
                                            @tip Set the background color and borders for your email's body area.
                                            */
                                                #templateBody{
                                                    /*@editable*/background-color:#FFFFFF;
                                                    /*@editable*/border-top:0;
                                                    /*@editable*/border-bottom:0;
                                                }
                                            /*
                                            @tab Body
                                            @section body text
                                            @tip Set the styling for your email's body text. Choose a size and color that is easy to read.
                                            */
                                                .bodyContainer .mcnTextContent,.bodyContainer .mcnTextContent p{
                                                    /*@editable*/color:#606060;
                                                    /*@editable*/font-family:Helvetica;
                                                    /*@editable*/font-size:15px;
                                                    /*@editable*/line-height:150%;
                                                    /*@editable*/text-align:left;
                                                }
                                            /*
                                            @tab Body
                                            @section body link
                                            @tip Set the styling for your email's body links. Choose a color that helps them stand out from your text.
                                            */
                                                .bodyContainer .mcnTextContent a{
                                                    /*@editable*/color:#6DC6DD;
                                                    /*@editable*/font-weight:normal;
                                                    /*@editable*/text-decoration:underline;
                                                }
                                            /*
                                            @tab Footer
                                            @section footer style
                                            @tip Set the background color and borders for your email's footer area.
                                            */
                                                #templateFooter{
                                                    /*@editable*/background-color:#FFFFFF;
                                                    /*@editable*/border-top:0;
                                                    /*@editable*/border-bottom:0;
                                                }
                                            /*
                                            @tab Footer
                                            @section footer text
                                            @tip Set the styling for your email's footer text. Choose a size and color that is easy to read.
                                            */
                                                .footerContainer .mcnTextContent,.footerContainer .mcnTextContent p{
                                                    /*@editable*/color:#606060;
                                                    /*@editable*/font-family:Helvetica;
                                                    /*@editable*/font-size:11px;
                                                    /*@editable*/line-height:125%;
                                                    /*@editable*/text-align:left;
                                                }
                                            /*
                                            @tab Footer
                                            @section footer link
                                            @tip Set the styling for your email's footer links. Choose a color that helps them stand out from your text.
                                            */
                                                .footerContainer .mcnTextContent a{
                                                    /*@editable*/color:#606060;
                                                    /*@editable*/font-weight:normal;
                                                    /*@editable*/text-decoration:underline;
                                                }
                                            @media only screen and (max-width: 480px){
                                                body,table,td,p,a,li,blockquote{
                                                    -webkit-text-size-adjust:none !important;
                                                }

                                        }	@media only screen and (max-width: 480px){
                                                body{
                                                    width:100% !important;
                                                    min-width:100% !important;
                                                }

                                        }	@media only screen and (max-width: 480px){
                                                .mcnRetinaImage{
                                                    max-width:100% !important;
                                                }

                                        }	@media only screen and (max-width: 480px){
                                                td[id=bodyCell]{
                                                    padding:10px !important;
                                                }

                                        }	@media only screen and (max-width: 480px){
                                                table[class=mcnTextContentContainer]{
                                                    width:100% !important;
                                                }

                                        }	@media only screen and (max-width: 480px){
                                                .mcnBoxedTextContentContainer{
                                                    max-width:100% !important;
                                                    min-width:100% !important;
                                                    width:100% !important;
                                                }

                                        }	@media only screen and (max-width: 480px){
                                                table[class=mcpreview-image-uploader]{
                                                    width:100% !important;
                                                    display:none !important;
                                                }

                                        }	@media only screen and (max-width: 480px){
                                                img[class=mcnImage]{
                                                    width:100% !important;
                                                }

                                        }	@media only screen and (max-width: 480px){
                                                table[class=mcnImageGroupContentContainer]{
                                                    width:100% !important;
                                                }

                                        }	@media only screen and (max-width: 480px){
                                                td[class=mcnImageGroupContent]{
                                                    padding:9px !important;
                                                }

                                        }	@media only screen and (max-width: 480px){
                                                td[class=mcnImageGroupBlockInner]{
                                                    padding-bottom:0 !important;
                                                    padding-top:0 !important;
                                                }

                                        }	@media only screen and (max-width: 480px){
                                                tbody[class=mcnImageGroupBlockOuter]{
                                                    padding-bottom:9px !important;
                                                    padding-top:9px !important;
                                                }

                                        }	@media only screen and (max-width: 480px){
                                                table[class=mcnCaptionTopContent],table[class=mcnCaptionBottomContent]{
                                                    width:100% !important;
                                                }

                                        }	@media only screen and (max-width: 480px){
                                                table[class=mcnCaptionLeftTextContentContainer],table[class=mcnCaptionRightTextContentContainer],table[class=mcnCaptionLeftImageContentContainer],table[class=mcnCaptionRightImageContentContainer],table[class=mcnImageCardLeftTextContentContainer],table[class=mcnImageCardRightTextContentContainer],.mcnImageCardLeftImageContentContainer,.mcnImageCardRightImageContentContainer{
                                                    width:100% !important;
                                                }

                                        }	@media only screen and (max-width: 480px){
                                                td[class=mcnImageCardLeftImageContent],td[class=mcnImageCardRightImageContent]{
                                                    padding-right:18px !important;
                                                    padding-left:18px !important;
                                                    padding-bottom:0 !important;
                                                }

                                        }	@media only screen and (max-width: 480px){
                                                td[class=mcnImageCardBottomImageContent]{
                                                    padding-bottom:9px !important;
                                                }

                                        }	@media only screen and (max-width: 480px){
                                                td[class=mcnImageCardTopImageContent]{
                                                    padding-top:18px !important;
                                                }

                                        }	@media only screen and (max-width: 480px){
                                                td[class=mcnImageCardLeftImageContent],td[class=mcnImageCardRightImageContent]{
                                                    padding-right:18px !important;
                                                    padding-left:18px !important;
                                                    padding-bottom:0 !important;
                                                }

                                        }	@media only screen and (max-width: 480px){
                                                td[class=mcnImageCardBottomImageContent]{
                                                    padding-bottom:9px !important;
                                                }

                                        }	@media only screen and (max-width: 480px){
                                                td[class=mcnImageCardTopImageContent]{
                                                    padding-top:18px !important;
                                                }

                                        }	@media only screen and (max-width: 480px){
                                                table[class=mcnCaptionLeftContentOuter] td[class=mcnTextContent],table[class=mcnCaptionRightContentOuter] td[class=mcnTextContent]{
                                                    padding-top:9px !important;
                                                }

                                        }	@media only screen and (max-width: 480px){
                                                td[class=mcnCaptionBlockInner] table[class=mcnCaptionTopContent]:last-child td[class=mcnTextContent],.mcnImageCardTopImageContent,.mcnCaptionBottomContent:last-child .mcnCaptionBottomImageContent{
                                                    padding-top:18px !important;
                                                }

                                        }	@media only screen and (max-width: 480px){
                                                td[class=mcnBoxedTextContentColumn]{
                                                    padding-left:18px !important;
                                                    padding-right:18px !important;
                                                }

                                        }	@media only screen and (max-width: 480px){
                                                td[class=mcnTextContent]{
                                                    padding-right:18px !important;
                                                    padding-left:18px !important;
                                                }

                                        }	@media only screen and (max-width: 480px){
                                            /*
                                            @tab Mobile Styles
                                            @section template width
                                            @tip Make the template fluid for portrait or landscape view adaptability. If a fluid layout doesn't work for you, set the width to 300px instead.
                                            */
                                                table[id=templateContainer],table[id=templatePreheader],table[id=templateHeader],table[id=templateBody],table[id=templateFooter]{
                                                    /*@tab Mobile Styles
                                        @section template width
                                        @tip Make the template fluid for portrait or landscape view adaptability. If a fluid layout doesn't work for you, set the width to 300px instead.*/max-width:600px !important;
                                                    /*@editable*/width:100% !important;
                                                }

                                        }	@media only screen and (max-width: 480px){
                                            /*
                                            @tab Mobile Styles
                                            @section heading 1
                                            @tip Make the first-level headings larger in size for better readability on small screens.
                                            */
                                                h1{
                                                    /*@editable*/font-size:24px !important;
                                                    /*@editable*/line-height:125% !important;
                                                }

                                        }	@media only screen and (max-width: 480px){
                                            /*
                                            @tab Mobile Styles
                                            @section heading 2
                                            @tip Make the second-level headings larger in size for better readability on small screens.
                                            */
                                                h2{
                                                    /*@editable*/font-size:20px !important;
                                                    /*@editable*/line-height:125% !important;
                                                }

                                        }	@media only screen and (max-width: 480px){
                                            /*
                                            @tab Mobile Styles
                                            @section heading 3
                                            @tip Make the third-level headings larger in size for better readability on small screens.
                                            */
                                                h3{
                                                    /*@editable*/font-size:18px !important;
                                                    /*@editable*/line-height:125% !important;
                                                }

                                        }	@media only screen and (max-width: 480px){
                                            /*
                                            @tab Mobile Styles
                                            @section heading 4
                                            @tip Make the fourth-level headings larger in size for better readability on small screens.
                                            */
                                                h4{
                                                    /*@editable*/font-size:16px !important;
                                                    /*@editable*/line-height:125% !important;
                                                }

                                        }	@media only screen and (max-width: 480px){
                                            /*
                                            @tab Mobile Styles
                                            @section Boxed Text
                                            @tip Make the boxed text larger in size for better readability on small screens. We recommend a font size of at least 16px.
                                            */
                                                table[class=mcnBoxedTextContentContainer] td[class=mcnTextContent],td[class=mcnBoxedTextContentContainer] td[class=mcnTextContent] p{
                                                    /*@editable*/font-size:18px !important;
                                                    /*@editable*/line-height:125% !important;
                                                }

                                        }	@media only screen and (max-width: 480px){
                                            /*
                                            @tab Mobile Styles
                                            @section Preheader Visibility
                                            @tip Set the visibility of the email's preheader on small screens. You can hide it to save space.
                                            */
                                                table[id=templatePreheader]{
                                                    /*@editable*/display:block !important;
                                                }

                                        }	@media only screen and (max-width: 480px){
                                            /*
                                            @tab Mobile Styles
                                            @section Preheader Text
                                            @tip Make the preheader text larger in size for better readability on small screens.
                                            */
                                                td[class=preheaderContainer] td[class=mcnTextContent],td[class=preheaderContainer] td[class=mcnTextContent] p{
                                                    /*@editable*/font-size:14px !important;
                                                    /*@editable*/line-height:115% !important;
                                                }

                                        }	@media only screen and (max-width: 480px){
                                            /*
                                            @tab Mobile Styles
                                            @section Header Text
                                            @tip Make the header text larger in size for better readability on small screens.
                                            */
                                                td[class=headerContainer] td[class=mcnTextContent],td[class=headerContainer] td[class=mcnTextContent] p{
                                                    /*@editable*/font-size:18px !important;
                                                    /*@editable*/line-height:125% !important;
                                                }

                                        }	@media only screen and (max-width: 480px){
                                            /*
                                            @tab Mobile Styles
                                            @section Body Text
                                            @tip Make the body text larger in size for better readability on small screens. We recommend a font size of at least 16px.
                                            */
                                                td[class=bodyContainer] td[class=mcnTextContent],td[class=bodyContainer] td[class=mcnTextContent] p{
                                                    /*@editable*/font-size:18px !important;
                                                    /*@editable*/line-height:125% !important;
                                                }

                                        }	@media only screen and (max-width: 480px){
                                            /*
                                            @tab Mobile Styles
                                            @section footer text
                                            @tip Make the body content text larger in size for better readability on small screens.
                                            */
                                                td[class=footerContainer] td[class=mcnTextContent],td[class=footerContainer] td[class=mcnTextContent] p{
                                                    /*@editable*/font-size:14px !important;
                                                    /*@editable*/line-height:115% !important;
                                                }

                                        }	@media only screen and (max-width: 480px){
                                                td[class=footerContainer] a[class=utilityLink]{
                                                    display:block !important;
                                                }

                                        }</style></head>
                                            <body leftmargin="0" marginwidth="0" topmargin="0" marginheight="0" offset="0">

                                                <center>
                                                    <table align="center" border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable">
                                                        <tr>
                                                            <td align="center" valign="top" id="bodyCell">
                                                                <!-- BEGIN TEMPLATE // -->
                                                                <table border="0" cellpadding="0" cellspacing="0" width="600" id="templateContainer">
                                                                    <tr>
                                                                        <td align="center" valign="top">
                                                                            <!-- BEGIN PREHEADER // -->
                                                                            <table border="0" cellpadding="0" cellspacing="0" width="600" id="templatePreheader">
                                                                                <tr>
                                                                                    <td valign="top" class="preheaderContainer" style="padding-top:9px;"><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width:100%;">
                                            <tbody class="mcnTextBlockOuter">
                                                <tr>
                                                    <td valign="top" class="mcnTextBlockInner" style="padding-top:9px;">
                                                          <!--[if mso]>
                                                        <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                                                        <tr>
                                                        <![endif]-->

                                                        <!--[if mso]>
                                                        <td valign="top" width="390" style="width:390px;">
                                                        <![endif]-->
                                                        <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width:390px;" width="100%" class="mcnTextContentContainer">
                                                            <tbody><tr>

                                                                <td valign="top" class="mcnTextContent" style="padding-top:0; padding-left:18px; padding-bottom:9px; padding-right:18px;">


                                                                </td>
                                                            </tr>
                                                        </tbody></table>
                                                        <!--[if mso]>
                                                        </td>
                                                        <![endif]-->

                                                        <!--[if mso]>
                                                        <td valign="top" width="210" style="width:210px;">
                                                        <![endif]-->

                                                        <!--[if mso]>
                                                        </td>
                                                        <![endif]-->

                                                        <!--[if mso]>
                                                        </tr>
                                                        </table>
                                                        <![endif]-->
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table></td>
                                                                                </tr>
                                                                            </table>
                                                                            <!-- // END PREHEADER -->
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td align="center" valign="top">
                                                                            <!-- BEGIN HEADER // -->
                                                                            <table border="0" cellpadding="0" cellspacing="0" width="600" id="templateHeader">
                                                                                <tr>
                                                                                    <td valign="top" class="headerContainer"><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageBlock" style="min-width:100%;">
                                            <tbody class="mcnImageBlockOuter">
                                                    <tr>
                                                        <td valign="top" style="padding:9px" class="mcnImageBlockInner">
                                                            <table align="left" width="100%" border="0" cellpadding="0" cellspacing="0" class="mcnImageContentContainer" style="min-width:100%;">
                                                                <tbody><tr>
                                                                    <td class="mcnImageContent" valign="top" style="padding-right: 9px; padding-left: 9px; padding-top: 0; padding-bottom: 0;">

                                                                            <a href="https://espaceclient.montri.fr/" title="" class="" target="_blank">
                                                                                <img align="left" alt="" src="https://mcusercontent.com/a24798abd1508f12878dc615c/images/5b234c67-995d-45f3-8455-c9b21b51b093.png" width="564" style="max-width:900px; padding-bottom: 0; display: inline !important; vertical-align: bottom;" class="mcnImage">
                                                                            </a>

                                                                    </td>
                                                                </tr>
                                                            </tbody></table>
                                                        </td>
                                                    </tr>
                                            </tbody>
                                        </table></td>
                                                                                </tr>
                                                                            </table>
                                                                            <!-- // END HEADER -->
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td align="center" valign="top">
                                                                            <!-- BEGIN BODY // -->
                                                                            <table border="0" cellpadding="0" cellspacing="0" width="600" id="templateBody">
                                                                                <tr>
                                                                                    <td valign="top" class="bodyContainer"><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width:100%;">
                                            <tbody class="mcnTextBlockOuter">
                                                <tr>
                                                    <td valign="top" class="mcnTextBlockInner" style="padding-top:9px;">
                                                          <!--[if mso]>
                                                        <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                                                        <tr>
                                                        <![endif]-->

                                                        <!--[if mso]>
                                                        <td valign="top" width="600" style="width:600px;">
                                                        <![endif]-->
                                                        <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width:100%; min-width:100%;" width="100%" class="mcnTextContentContainer">
                                                            <tbody><tr>

                                                                <td valign="top" class="mcnTextContent" style="padding-top:0; padding-right:18px; padding-bottom:9px; padding-left:18px;">

                                                                    <div style="text-align: center;">
                                        <div style="text-align: left;"><span style="font-size:30px"><span style="font-family:tahoma,verdana,segoe,sans-serif"><span style="color:#2f2e31"><strong>Bonjour,</strong></span></span></span>

                                        <p><br>
                                        <span style="font-family:tahoma,verdana,segoe,sans-serif"><font color="#000000"><span style="font-size:15px">Nous venons de vous ajouter au&nbsp;contrat ${ selectedContrat.nomContrat }.<br>
                                        <br>
                                        Rendez-vous dans votre espace Client et sélectionnez votre contrat dans&nbsp;la barre de navigation.</span></font></span></p>
                                        </div>
                                        </div>

                                                                </td>
                                                            </tr>
                                                        </tbody></table>
                                                        <!--[if mso]>
                                                        </td>
                                                        <![endif]-->

                                                        <!--[if mso]>
                                                        </tr>
                                                        </table>
                                                        <![endif]-->
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnButtonBlock" style="min-width:100%;">
                                            <tbody class="mcnButtonBlockOuter">
                                                <tr>
                                                    <td style="padding-top:0; padding-right:18px; padding-bottom:18px; padding-left:18px;" valign="top" align="center" class="mcnButtonBlockInner">
                                                        <table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnButtonContentContainer" style="border-collapse: separate !important;border-radius: 6px;background-color: #FFC953;">
                                                            <tbody>
                                                                <tr>
                                                                    <td align="center" valign="middle" class="mcnButtonContent" style="font-family: Verdana, Geneva, sans-serif; font-size: 24px; padding: 20px;">
                                                                        <a class="mcnButton " title="Publier une actualité" href="https://espaceclient.montri.fr/user/signin" target="_blank" style="font-weight: bold;letter-spacing: normal;line-height: 100%;text-align: center;text-decoration: none;color: #FFFFFF;">Publier une actualité</a>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width:100%;">
                                            <tbody class="mcnTextBlockOuter">
                                                <tr>
                                                    <td valign="top" class="mcnTextBlockInner" style="padding-top:9px;">
                                                          <!--[if mso]>
                                                        <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                                                        <tr>
                                                        <![endif]-->

                                                        <!--[if mso]>
                                                        <td valign="top" width="600" style="width:600px;">
                                                        <![endif]-->
                                                        <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width:100%; min-width:100%;" width="100%" class="mcnTextContentContainer">
                                                            <tbody><tr>

                                                                <td valign="top" class="mcnTextContent" style="padding-top:0; padding-right:18px; padding-bottom:9px; padding-left:18px;">

                                                                    <div style="text-align: left;"><span style="font-family:tahoma,verdana,segoe,sans-serif"><span style="color:#000000"><span style="font-size:15px">N'hésitez pas à me contacter pour toutes questions concernant nos outils et l'utilisation de nos services.<br>
                                        <br>
                                        Bien cordialement,<br>
                                        Paul</span></span></span></div>

                                                                </td>
                                                            </tr>
                                                        </tbody></table>
                                                        <!--[if mso]>
                                                        </td>
                                                        <![endif]-->

                                                        <!--[if mso]>
                                                        </tr>
                                                        </table>
                                                        <![endif]-->
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageBlock" style="min-width:100%;">
                                            <tbody class="mcnImageBlockOuter">
                                                    <tr>
                                                        <td valign="top" style="padding:9px" class="mcnImageBlockInner">
                                                            <table align="left" width="100%" border="0" cellpadding="0" cellspacing="0" class="mcnImageContentContainer" style="min-width:100%;">
                                                                <tbody><tr>
                                                                    <td class="mcnImageContent" valign="top" style="padding-right: 9px; padding-left: 9px; padding-top: 0; padding-bottom: 0;">


                                                                                <img align="left" alt="" src="https://mcusercontent.com/a24798abd1508f12878dc615c/images/0f821d07-fc33-45af-99be-982e21640a89.png" width="100" style="max-width:100px; padding-bottom: 0; display: inline !important; vertical-align: bottom;" class="mcnImage">


                                                                    </td>
                                                                </tr>
                                                            </tbody></table>
                                                        </td>
                                                    </tr>
                                            </tbody>
                                        </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width:100%;">
                                            <tbody class="mcnTextBlockOuter">
                                                <tr>
                                                    <td valign="top" class="mcnTextBlockInner" style="padding-top:9px;">
                                                          <!--[if mso]>
                                                        <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                                                        <tr>
                                                        <![endif]-->

                                                        <!--[if mso]>
                                                        <td valign="top" width="600" style="width:600px;">
                                                        <![endif]-->
                                                        <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width:100%; min-width:100%;" width="100%" class="mcnTextContentContainer">
                                                            <tbody><tr>

                                                                <td valign="top" class="mcnTextContent" style="padding-top:0; padding-right:18px; padding-bottom:9px; padding-left:18px;">

                                                                    <div style="text-align: left;"><span style="font-size:12px"><span style="color:#000000"><span style="font-family:verdana,geneva,sans-serif">Mob.: 06 58 16 55 08</span></span><br>
                                        <span style="color:#000000"><span style="font-family:verdana,geneva,sans-serif">Email : </span></span><a href="mailto:palarcon@uzer.eu?subject=J'ai%20besoin%20d'aide" target="_blank"><span style="font-family:verdana,geneva,sans-serif">palarcon@uzer.eu</span></a></span></div>

                                                                </td>
                                                            </tr>
                                                        </tbody></table>
                                                        <!--[if mso]>
                                                        </td>
                                                        <![endif]-->

                                                        <!--[if mso]>
                                                        </tr>
                                                        </table>
                                                        <![endif]-->
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table></td>
                                                                                </tr>
                                                                            </table>
                                                                            <!-- // END BODY -->
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td align="center" valign="top">
                                                                            <!-- BEGIN FOOTER // -->
                                                                            <table border="0" cellpadding="0" cellspacing="0" width="600" id="templateFooter">
                                                                                <tr>
                                                                                    <td valign="top" class="footerContainer" style="padding-bottom:9px;"></td>
                                                                                </tr>
                                                                            </table>
                                                                            <!-- // END FOOTER -->
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                                <!-- // END TEMPLATE -->
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </center>
                                            </body>
                                        </html>

                                        </code>`
                                    }}).then(function()  {
                                        self.contratToAdd  = "Choisissez un contrat"
                                        self.userToAdd = "choisissez un email"
                                    })

                            })
                            .catch(function (error) {
                                var alert: Alert = {
                                    type: 'danger',
                                    message:
                                        ' ❗ L\'ajout du collaborateur non réussi réssayer de nouveau',
                                };
                                self.userToAdd = ""
                                self.contratToAdd = ""
                                self.alertservices.setAlert(alert);
                                self.lodingBarService.setStatus(false);
                                self.contratToAdd  = "Choisissez un contrat"
                                self.userToAdd = "choisissez un email"
                            });

                        });
                    }
        });
    }

    addUser(){
        var  check = 0
        var self = this ;
        this.userFromDatabase().subscribe((data) => {
            if(check == 0){
                check = 1
                data.map((a) => {
                    const data = a.payload.doc.data() as UserBo;
                    self.contratsList = this.userBo.listContrats.filter(x => !data.listContrats.some(elem => (elem.idContrat == x.idContrat && elem.nomContrat == elem.nomContrat)))
                    var c = 1
                })
            }
        })
    }


    userFromDatabase() {
        return this.afs
            .collection("usersBo", (ref) =>
                ref.where("email", "==", this.userToAdd)
            )
            .snapshotChanges();
    }

    containsObject(obj, list) {

        return list.some(elem => elem === obj)
    }
}

<div class="container-fluid">
    <div class="mt-4 ml-4">

        <div class="row">
            <div class="col-12 h2" style="text-align: center;">
                Demandes et Signalements
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-1">
                <img src="../../../../../assets/img/reportingstats/084-statut-todo.png"
                    style="width: 30px; height: 30px;" *ngIf="report.status=='a_traiter'">
                <img src="../../../../../assets/img/reportingstats/082-statut-ok.png" style="width: 30px; height: 30px;"
                    *ngIf="report.status=='traiter'">
                <img src="../../../../../assets/img/reportingstats/083-statut-ongoing.png"
                    style="width: 30px; height: 30px;" *ngIf="report.status=='en_cours'">
            </div>
            <div class="col-5">
                <p class="pt-0 mb-0 p1" style="color: black;font-weight: bold;">{{report.type}}</p>
                <p class="pt-0 mb-0 p1" style="color: black;font-weight: bold;">
                    {{getValue(report.formulaires, 'Motif')}}</p>
                <p class="pt-0 mb-0 p1 mt-2">ID : {{report.id}}</p>
                <p class="pt-0 mb-0 p2 mb-3">Date : {{toDateTime(report.date.seconds)}}</p>
                <ng-container *ngFor='let info of report.formulaires | keyvalue'>
                    <ng-container *ngIf='filterCheck(info.key)'>
                        <ng-container *ngIf='info.value != "" '>
                            <p class="pt-0 mb-0 p1 mt-3" style="color: black;font-weight: bold;">{{info.key}}</p>
                            <p class="pt-0 mb-0 p2 mt-2">{{info.value}}</p>
                        </ng-container>
                    </ng-container>
                </ng-container>

            </div>
            <div class="col-4 d-flex flex-row justify-content-end " style="margin-left: auto;">
                <img [src]="reportImageUrl" *ngIf="reportImageUrl != ''" id="imgReport" (click)="show()" style="height : 150px; margin: auto;">
                <div class="spinner-border" [hidden]="hiddenSpin" id="spinner" role="status" >
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>

        <div class="row">

            <div class="col-11" style="margin-left: auto;">
                
                <p class="pt-0 mb-0 p1 mt-4 mb-2" style="color: black;">Coordonnées</p>
                <div class="row">
                    <ng-container *ngFor='let data of report.coordonnees | keyvalue'>
                        <div class="col-4" id="colCoord"  *ngIf='checkEmpty(data.value) === false'>
                            <p class="pt-0 mb-0 p2" style="color: black;font-weight: bold;">{{data.key}}</p>
                            <p class="pt-0 mb-1 p2">{{data.value}}</p>
                        </div>
                    </ng-container>


                </div>
                <div>
                    <p class="pt-0 mb-0 p1 mt-4 mb-2" style="color: black;font-weight: bold">Commentaire</p>
                    <div class="input-group input-group-sm mb-3">
                        <textarea type="text" placeholder="Votre commentaire" rows="4" [(ngModel)]="report.commentaire"
                            class="form-control" aria-label="Small" style="margin-right: 80px;"
                            aria-describedby="inputGroup-sizing-sm"></textarea>
                    </div>
                </div>
                <div class="form-group row">
                <div class="col-sm-6" *ngIf="report.status === 'traiter'">
                    <p class="pt-0 mb-0 p1 mt-4 mb-2" style="color: black;font-weight: bold">Soldeur du ticket :</p>
                    <ng-container *ngIf="mail">
                        <input type="email" class="form-control form-control-danger" ngClass="{{mailerror ? 'colorerrorinput' : 'colornoterrorinput'}}"
                        required  style="font-size: 14px;" (change)="inputChange($event)"  value="{{mail}}" (focus)="mailerror = false;error=false"
                        [(ngModel)]="mail" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Votre email">
                    </ng-container>
                    <ng-container *ngIf="!mail">
                        <input type="email" class="form-control form-control-danger" ngClass="{{mailerror ? 'colorerrorinput' : 'colornoterrorinput'}}" required style="font-size: 14px;"
                        (focus)="mailerror = false;error=false"
                        (change)="inputChange($event)" 
                        [(ngModel)]="mail" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Votre email">
                    </ng-container>
                    <small id="emailHelp" class="form-text text-muted">Email requis</small>
                    <p *ngIf="statusBefore == 'traiter'" class="pt-0 mb-0 p1 mt-4 mb-2" style="color: black"><b>Date de clôture :</b>  {{timestampToDate(this.report.dateFin)}}</p>
                </div>
                </div>
                <div class="row mt-4 mb-4">
                    <div class="col-2">
                        <p
                            style="color: black;font-size: 15px;font-family: avenir_heavy;margin-top: 5px;">
                            Statut :</p>
                    </div>
                    <div class="col-2">
                        <ng-container *ngIf='report.status === "a_traiter"'>
                            <button type="button" class="btn btn-outline-danger active"
                                style="font-size: 12px;font-family: avenir_heavy;text-align: center;"
                                (click)="changeStatus('a_traiter')"
                                ngClass="{{report.status != 'a_traiter'  ? 'opacity05' : ''}}">A traiter</button>
                        </ng-container>
                        <ng-container *ngIf='report.status != "a_traiter"'>
                            <button type="button" class="btn btn-outline-danger"
                                style="font-size: 12px;font-family: avenir_heavy;text-align: center;"
                                (click)="changeStatus('a_traiter')"
                                ngClass="{{report.status != 'a_traiter'  ? 'opacity05' : ''}}">A traiter</button>
                        </ng-container>
                    </div>
                    <div class="col-2">
                        <ng-container *ngIf='report.status === "en_cours"'>
                            <button type="button" class="btn btn-outline-warning active"
                                style="font-size: 12px;font-family: avenir_heavy;text-align: center;"
                                (click)="changeStatus('en_cours')"
                                ngClass="{{report.status != 'en_cours'  ? 'opacity05' : ''}}">En cours</button>
                        </ng-container>
                        <ng-container *ngIf='report.status != "en_cours"'>
                            <button type="button" class="btn btn-outline-warning"
                                style="font-size: 12px;font-family: avenir_heavy;text-align: center;"
                                (click)="changeStatus('en_cours')"
                                ngClass="{{report.status != 'en_cours'  ? 'opacity05' : ''}}">En cours</button>
                        </ng-container>
                    </div>
                    <div class="col-2">
                        <ng-container *ngIf='report.status === "traiter"'>
                            <button type="button" class="btn btn-outline-success active"
                                style="font-size: 12px;font-family: avenir_heavy;text-align: center;"
                                (click)="changeStatus('traiter')"
                                ngClass="{{report.status != 'traiter'  ? 'opacity05' : ''}}">Résolu</button>
                        </ng-container>
                        <ng-container *ngIf='report.status != "traiter"'>
                            <button type="button" class="btn btn-outline-success"
                                style="font-size: 12px;font-family: avenir_heavy;text-align: center;"
                                (click)="changeStatus('traiter')"
                                ngClass="{{report.status != 'traiter'  ? 'opacity05' : ''}}">Résolu</button>
                        </ng-container>
                    </div>
                    <div class="col-2">
                        <ng-container *ngIf='report.status === "archiver"'>
                            <button type="button" class="btn btn-outline-dark active"
                                style="font-size: 12px;font-family: avenir_heavy;text-align: center;background-color: grey;"
                                (click)="changeStatus('archiver')"
                                ngClass="{{report.status != 'archiver'  ? 'opacity05' : ''}}">Archiver</button>
                        </ng-container>
                        <ng-container *ngIf='report.status != "archiver"'>
                            <button type="button" class="btn btn-outline-dark"
                                style="font-size: 12px;font-family: avenir_heavy;text-align: center;"
                                (click)="changeStatus('archiver')"
                                ngClass="{{report.status != 'archiver'  ? 'opacity05' : ''}}">Archiver</button>
                        </ng-container>
                    </div>
                </div>
                <div class="row mt-5">
                    <ng-container *ngIf='validateEmail(mail) === true'>
                        <button type="submit" class="mx-auto btn btn-success" style="font-size: 12px;font-family: avenir_heavy;text-align: center; width: 10rem; opacity: 1;" (click)="updateStatus()" >Valider</button>
                    </ng-container>
                    <ng-container *ngIf='validateEmail(mail) === false'>
                        <button type="submit" class="mx-auto btn btn-success" style="font-size: 12px;font-family: avenir_heavy;text-align: center; width: 10rem;opacity: 0.5;" (click)="alertMail()" >Valider</button>
                    </ng-container>
                </div>
            </div>
        </div>

    </div>

    <div class="row ml-2 mt-5">
        <div class="col-1"></div>
        <div class="col-10">
            <div class="row mb-3">
                <div class="col-12 h2 header">
                    Historique
                </div>
            </div>
            <div class="row ml-0 mt-3 mb-3">
                <div [ngClass]="showHistory ? 'col-2 mr-3 selected tag label' : 'col-2 mr-3 tag label'" (click)="changeHistoryVisibility(true)">
                    Actions
                </div>
                <div *ngIf="isMessagesActive" [ngClass]="!showHistory ? 'col-2 mr-3 selected tag label' : 'col-2 mr-3 tag label'" (click)="changeHistoryVisibility(false)">
                    Messages
                </div>
            </div>
            <ng-container *ngIf="showHistory">
                <div *ngIf="!report.historique" class="row mb-3">
                    <p class="col-12" style="color: #a6a6a6;" >
                        Pas de données
                    </p>
                </div>
                <ng-container *ngIf="report.historique">
                    <div class="mt-5 mb-5">
                        <div *ngFor="let hist of report.historique" class="message__text mb-3 mr-4 p-2">
                            <div class="d-flex" >
                                <img style="width: 25px; height:25px; padding-bottom: 2px;;border-radius: 50%" *ngIf="hist.image != ''" src="{{hist.image}}" >
                                <span class="align-self-center text pl-2" style="font-size: 16px; font-weight: 800;">{{ hist.nom }}</span>
                                <span class="align-self-center text pr-2" style="margin-left: auto;font-size: 12px;">{{ hist.date }}</span>
                            </div>
                            <div class="text d-flex mt-3 pl-1 pb-2">
                                <a class="mr-1" style="font-size: 14px;" href="mailto:{{hist.email}}">{{hist.email}}</a> 
                                <div class="d-flex" [innerHTML]="genHtmlAction(hist.action)">
                                </div>
                                <p *ngIf="hist.commentaire?.length > 0 && hist.action == 'a ajouté un commentaire :'" class="card-text ml-3" style="opacity: 0.7;">{{hist.commentaire}}</p>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="!showHistory">
                <div class="mb-5">
                    <div class="d-flex mr-4 mt-5 px-2 input align-items-center">
                        <img src="../../../../../assets/img/reportingstats/inputIcon.svg" style="height: 20px; width: 17.5px;" >
                        <span #msg class="textarea p-2" (input)="message = $event.target.innerText" contenteditable></span>
                    </div>
                    <div class="d-flex mr-4 mt-3 mb-5 justify-content-end">
                        <button type="button" [ngClass]="message?.length ? 'activated msgbtn' : 'msgbtn'" [disabled]="!(message?.length > 0)" (click)="sendMessage()">Envoyer</button>
                    </div>
                    <div *ngFor="let message of report.messages.reverse()" class="message__text mb-3 mr-4 p-2">
                        <div class="d-flex" >
                            <div [ngStyle]="{'height': '25px', 'width': '25px', 'border-radius': '50%', 'background-color': message.expediteur == 0 ? 'clear': primaryColor}">
                                <img style="width: 100%; height:100%; padding-bottom: 2px;" [ngStyle]="{'filter': message.expediteur == 0 ? 'none': cssFilter}" [src]="message.expediteur == 0 ? '../../../../../assets/img/reportingstats/userIcon.svg' : '../../../../../assets/img/reportingstats/agentIcon.svg'" >
                            </div>
                            <a *ngIf="message.expediteur == 1" class="align-self-center text pl-2" style="font-size: 16px;" href="mailto:{{message.email}}">{{message.email}}</a>
                            <span *ngIf="message.expediteur == 0" class="align-self-center text pl-2" style="font-size: 16px; color: #2F80ED;">Utilisateur</span>
                            <span class="align-self-center text pr-2" style="margin-left: auto;font-size: 12px;">{{ timestampToDate(message.date) }}</span>
                        </div>
                        <div class="text mt-3 pl-1 pb-2">
                            {{ message.message }}
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>

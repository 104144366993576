<div class="container-fluid mt-5 pl-5">
    <div class="row">
        <div class="col-md-4 ">
            <div class= "row">
            <div class="col-lg-6">
                    <button type="button" (click)="gotourl('/montri/contractsettings/informations')"   [ngStyle]="{'background-color': sectionname == 'informations' ? 'rgb(244, 112,0)':'rgba(244, 112,0,0.3)',
                        'outline': 'none !important',
                        'box-shadow': 'none',
                        'font-size': sectionname == 'informations' ? '14px' : '14px',
                        'font-family': sectionname == 'informations' ? 'avenir_heavy' : 'avenir_light',
                        'color': sectionname == 'informations' ? 'white' : 'black',
                        'border':'none'
                    }" class="btn btn-primary w-100 h3_heavy" data-toggle="button" aria-pressed="false" autocomplete="off">Informations</button>
            </div>

            <div class="col-lg-6 mt-lg-0 mt-3">
                    <button type="button" (click)="gotourl('/montri/contractsettings/recyclinginstructions')"   [ngStyle]="{
                        'background-color': sectionname == 'recyclinginstructions' ? 'rgb(244, 112,0)':'rgba(244, 112,0,0.3)',
                        'outline': 'none !i&²mportant',
                        'box-shadow': 'none',
                        'font-size': sectionname == 'recyclinginstructions' ? '14px' : '14px',
                        'font-family': sectionname == 'recyclinginstructions' ? 'avenir_heavy' : 'avenir_light',
                        'color': sectionname == 'recyclinginstructions' ? 'white' : 'black',
                        'border':'none'
                    }" class="btn btn-primary w-100 h3_heavy" data-toggle="button" aria-pressed="false" autocomplete="off">Règles de tri</button>
            </div>

            </div>
        </div>
        <div class="col-md-4 mt-md-0 mt-3">
            <div class= "row">
                <div class="col-lg-6">
                    <button type="button" (click)="gotourl('/montri/contractsettings/design')"   [ngStyle]="{
                        'background-color': sectionname == 'design' ? 'rgb(244, 112,0)':'rgba(244, 112,0,0.3)',
                        'outline': 'none !important',
                        'box-shadow': 'none',
                        'font-size': sectionname == 'design' ? '14px' : '14px',
                        'font-family': sectionname == 'design' ? 'avenir_heavy' : 'avenir_light',
                        'color': sectionname == 'design' ? 'white' : 'black',
                        'border':'none'

                    }" class="btn btn-primary w-100 h3_heavy" data-toggle="button" aria-pressed="false" autocomplete="off">Design</button>
                </div>
                <div class="col-lg-6 mt-lg-0 mt-3">
                    <button type="button" (click)="gotourl('/montri/contractsettings/services')"  [ngStyle]="{
                        'background-color': sectionname == 'services' ? 'rgb(244, 112,0)':'rgba(244, 112,0,0.3)',
                        'outline': 'none !important',
                        'box-shadow': 'none',
                        'font-size': sectionname == 'services' ? '14px' : '14px',
                        'font-family': sectionname == 'services' ? 'avenir_heavy' : 'avenir_light',
                        'color': sectionname == 'services' ? 'white' : 'black',
                        'border':'none'

                    }"  class="btn btn-primary w-100 h3_heavy" data-toggle="button" aria-pressed="false" autocomplete="off">Services</button>
                </div>

            </div>
        </div>
        <div class="col-md-4 mt-md-0 mt-3"  *ngIf="contrat!=undefined && contrat.contrat.mobileApp">
            <div class= "row">
                <div class="col-lg-6">
                    <button type="button" (click)="gotourl('/montri/contractsettings/slider')"  [ngStyle]="{
                        'background-color': sectionname == 'slider' ? 'rgb(244, 112,0)':'rgba(244, 112,0,0.3)',
                        'outline': 'none !important',
                        'box-shadow': 'none',
                        'font-size': sectionname == 'slider' ? '14px' : '14px',
                        'font-family': sectionname == 'slider' ? 'avenir_heavy' : 'avenir_light',
                        'color': sectionname == 'slider' ? 'white' : 'black',
                        'border':'none'

                    }"  class="btn btn-primary w-100 h3_heavy" data-toggle="button" aria-pressed="false" autocomplete="off">Slider</button>
                </div>
                <div class="col-lg-6">

                </div>

            </div>
        </div>
    </div>
   <div class="row mt-4">
        <div class="col-12">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>

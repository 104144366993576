import { AngularFireAuth } from '@angular/fire/auth';
import { Alert } from './../../../../models/alert/alert';
import { AuthentificationService } from './../../../../services/authentification/authentification.service';
import { AlertService } from './../../../../services/alert/alert.service';
import { LodingBarService } from './../../../../services/lodingbar/loding-bar-service.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-signup',
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

    SignUpForm;
    private  reciver_email : string = "" ;
    private  sender_email : string = "";
    private  is_invit  :  boolean ;
    private id_invit : string = "";
    mailError  : boolean =  false
    codeError : boolean = false
    cguError : boolean= false
    passwordError : boolean =  false
    confirmpasswordError : boolean = false
    error  : boolean  =  false
    userExist : boolean =  null
    isChecked : boolean = false;

    constructor(
        private authservice : AuthentificationService,
        private formBuilder: FormBuilder,
        private router: Router,
        private lodingBarService: LodingBarService,
        private alertservice: AlertService,
        private route: ActivatedRoute,
        public afAuth: AngularFireAuth,
        private alertservices: AlertService

    ) { }

    ngOnInit(): void {
        window.scrollTo(0, 0);
        this.route.params.subscribe( params => {

            if(Object.keys(params).length>0){
                this.is_invit = true;
                this.id_invit = params['id_invit'];
            }
            else{
                this.is_invit = false;
            }

            this.SignUpForm = this.formBuilder.group({
                email_signup: "",
                password_signup: "",
                confirm_password_signup :"",
                code_signup : ""
            });
        });

    }

    checkCheckBox(){
        if (this.isChecked == false){
            this.isChecked = true
        }else if (this.isChecked == true){
            this.isChecked = false
        }

    }

    onSubmit(userData) {
        var self = this ;
        if(this.userExist == null ){
            if (userData['email_signup'] ==""){
                this.mailError = true
            }
            else if (userData['code_signup'] ==""){
                this.codeError = true
            }
            else if (this.isChecked == false){
                this.cguError = true
            }
            else if ((/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(userData["email_signup"])))  {
                this.lodingBarService.setStatus(true);
                this.afAuth
                .fetchSignInMethodsForEmail(userData["email_signup"])
                .then(function (signInMethods) {
                    if (signInMethods.length > 0) {
                        self.userExist = true
                    } else {
                        self.userExist = false
                    }
                    self?.lodingBarService.setStatus(false);
                })
            }
            else {
                this.error = true
                this?.lodingBarService.setStatus(false);
            }
        }
        else if( this.userExist == false){
            if (userData['password_signup'] ==""){
                this.passwordError = true
            }
            else if (userData['confirm_password_signup'] ==""){
                this.confirmpasswordError = true
            }
            else if (userData['code_signup'] ==""){
                this.codeError = true
            }
            else if (userData['confirm_password_signup'] != userData['password_signup']){
                this.error = true
            }
            else if (this.isChecked == false){
                this.cguError = true
            }
            else{
                var decimal = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,50}$/;
                if (userData["password_signup"].match(decimal)) {
                    this.lodingBarService.setStatus(true);
                    this.authservice.signUp(userData["email_signup"], userData["password_signup"],userData['code_signup'].replace(' ',''));
                }
                else {
                    this.error = true
                    this.lodingBarService.setStatus(false);
                }
            }

        }
        else if( this.userExist == true){
            if (userData['password_signup'] ==""){
                this.passwordError = true
            }
            else if (userData['code_signup'] ==""){
                this.codeError = true
            }
            else if (this.isChecked == false){
                this.cguError = true
            }
            else{
                var decimal = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,50}$/;
                if (userData["password_signup"].match(decimal)) {
                    this.lodingBarService.setStatus(true);
                    this.authservice.signInWithInvit(userData["email_signup"], userData["password_signup"],userData['code_signup'].replace(' ',''));
                }
                else {
                    this.error = true
                    this.lodingBarService.setStatus(false);
                }
            }
        }

    }

}

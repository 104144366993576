import { UserService } from './../../../../services/user/user.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { AlertinfosComponent } from './../../modals/alertinfos/alertinfos.component';
import { DeletealertComponent } from './../../modals/deletealert/deletealert.component';
import { Notification } from './../../../../models/notification/notification';
import { MenuService } from './../../../../services/menu/menu.service';
import { ContratService } from './../../../../services/contrat/contrat.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Contrat, Service } from './../../../../models/contrat/contrat';
import { Component, OnInit } from '@angular/core';
import { UserBo } from 'src/app/models/user/user';
import * as shortUUID from 'short-uuid';
import * as moment from 'moment';
import { ToastAlertsComponent } from 'src/app/modules/main/components/toast-alerts/toast-alerts.component';


@Component({
    selector: 'app-alertssettings',
    templateUrl: './alertssettings.component.html',
    styleUrls: ['./alertssettings.component.scss']
})
export class AlertssettingsComponent implements OnInit {


    page = 1;
    pageSize = 4;
    collectionSize : number;
    contrat : Contrat;
    notifications_list : Notification[];
    actus : Notification[] =[];
    user : UserBo

    constructor(
        private modalService: NgbModal,
        private contratservice : ContratService,
        private menuservice : MenuService,
        private userservice : UserService,
        private afstorage : AngularFireStorage,
        private toastr: ToastAlertsComponent
    ) {}

    ngOnInit(): void {
        moment.locale('fr')
        this.menuservice.setSection("paramétrage");
        this.menuservice.setSubsection("alerts");
        this.userservice.user_obs.subscribe((user)=>{
            this.user = user
            this.contratservice.contrat_obs.subscribe((contrat)=>{
                this.contrat = contrat
                if(this.contrat.contrat.idContrat != 'BOTEST'){
                    this.contratservice.loadNotifications(contrat.contrat.idContrat)
                    this.contratservice.notifications_obs.subscribe((notifications)=>{
                        this.notifications_list = notifications.sort((a, b) => new Date(b.date.seconds).getTime() - new Date(a.date.seconds).getTime());;
                        this.collectionSize = this.notifications_list.length
                        this.refreshActus()
                    })
                }
            })
        })
    }

    refreshActus() {
        this.actus = this.notifications_list
            .map((actu, i) => ({ id: i + 1, ...actu }))
            .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
    }

    capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    onchange(evt,i){}

    showModal(actusid : number){
        window.scrollTo(0, 0);
		let options: NgbModalOptions = {};
        options.centered = true
		this.modalService.dismissAll()
        const modalRef = this.modalService.open(AlertinfosComponent,options)
        modalRef.componentInstance.actus = this.actus;
        modalRef.componentInstance.actusid = null;
        modalRef.result.then(async (result) => {
            if (result) {
                let status = await this.contratservice.addNotification(result[0]);
                if(status === 'success'){
                    this.toastr.openSuccessToast('Votre alerte a bien été envoyée', "")
                }else{
                    this.toastr.openErrorToast(`Votre alerte n'a pas pu être envoyée, merci de réessayer.`, "")
                }
                if(result[1] === true){ // l'alerte est epinglée
                    if(this.contrat.interface?.designMobile?.dashboardAccueil){
                        let exist = false
                        for(let element of this.contrat.interface.designMobile.dashboardAccueil){
                            if(element.type === 'carteTopAlert'){
                                exist = true
                                element.id = shortUUID.generate()
                                element.titre = result[0].titre
                                element.description = result[0].description
                                element.url = result[0].url
                                element.legende = `Le ${moment(result[0].date.toDate()).format("D MMMM yyyy")}` 
                                break
                            }
                        }
                        if(!exist){
                            const obj = {
                                id: shortUUID.generate(),
                                image: 'V3/topCards/carteTopalert.png',
                                titre: result[0].titre,
                                couleurTexte: '#000000',
                                couleur: '#FF0000',
                                opacite: 0.1,
                                nom: 'Top Alerte',
                                active: true,
                                description: result[0].description,
                                type: "carteTopAlert",
                                url: result[0].url,
                                ordre: 0,
                                legende: `Le ${moment(result[0].date.toDate()).format("D MMMM yyyy")}` 
                            }
                            this.contrat.interface.designMobile.dashboardAccueil.push(obj)
                        }
                        this.contratservice.saveContrat('');
                    }
                }
            }
        })
    }


    deletealert(notif : Notification){
        var self  = this ;
        window.scrollTo(0, 0);
		let options: NgbModalOptions = {};
        options.centered = true
		this.modalService.dismissAll()
        const modalRef = this.modalService.open(DeletealertComponent,options)
        modalRef.result.then((result) => {

            if (result) {
                if((this.actus.length == 1) && this.page>1){
                    this.page = this.page-1
                }
                var ref = self.afstorage.ref(self.contrat.contrat.idContrat + "/preResources/Notifications/"+notif.image);
                ref.delete()
                this.contratservice.deleteNotification(notif)
            }
        })
    }

}



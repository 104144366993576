import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class MsgserviceService {

    service: BehaviorSubject<string> = new BehaviorSubject<string>('');
    service_obs = this.service.asObservable()

    menuStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    menuStatus_obs = this.menuStatus.asObservable()
    constructor() { }

    setService( name: string){
        this.service.next(name)
    }

    setMenuStatus(status: boolean){
        this.menuStatus.next(status)

    }

}

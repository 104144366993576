import { AlertService } from './../../../../services/alert/alert.service';
import { LodingBarService } from './../../../../services/lodingbar/loding-bar-service.service';
import { ContratService } from './../../../../services/contrat/contrat.service';
import { Contrat, Service } from './../../../../models/contrat/contrat';
import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-webhome',
    templateUrl: './webhome.component.html',
    styleUrls: ['./webhome.component.scss']
})
export class WebhomeComponent implements OnInit {

    contrat : Contrat;
    @Input() cover_image : string ;
    services : Service[];
    @Input() logo_site : string;
    @Input() titrecover : string;
    @Input() soustitrecover : string;
    @Input() couleur : string;
    hover : boolean = false;
    hover_lik1 : boolean = false;
    hover_lik2 : boolean = false;
    hover_lik3 : boolean = false;
    current_window : string = "";
    constructor(
        private contratservice : ContratService,
        private lodingbarservice: LodingBarService,
        private alertservice: AlertService,
    ) {

    }

    ngOnInit(): void {
        this.contratservice.contrat_obs.subscribe((contart)=>{
            this.contrat = contart
            this.services = this.contrat.services.filter(item => item.active == true && item.web == true)

        })
    }

}

<nav class="navbar navbar-expand-lg navbar-light sticky-top w-100 b" >
    <div class="container-fluid">
    <div class="container  mt-0 ">
        <div class="d-flex flex-column justify-content-center align-items-center justify-content-md-start">
            <a class="navbar-brand " href="#">
                <img  src="{{logo_site}}" style="height: 30px; width: 100px;" alt="img">
            </a>
        </div>
        <div  class="collapse navbar-collapse" style="height:100%;">
            <ul class=" row navbar-nav  d-flex flex-row  flex-lg-row  justify-content-end align-items-stretch" style="height: 100%;">

                <li class="nav-item active d-flex flex-row justify-content-center align-items-center">
                    <a class="nav-link avenir_heavy nav_bar_link h3_heavy_preview"  href="#"
                        (click)="current_window = 'home'" (mouseover)="hover_lik1=true" (mouseleave)="hover_lik1=false"
                        [ngStyle]="{
                            'color': (hover_lik1 ==true)||(current_window == 'application')  ? couleur :  'black'
                        }">Application</a>
                </li>
                <li class="nav-item d-flex flex-row justify-content-center  align-items-center">
                    <a class="nav-link avenir_heavy nav_bar_link h3_heavy_preview" id="services"
                        (mouseover)="hover_lik2=true" (click)="current_window = 'services'"  href="#"
                        (mouseleave)="hover_lik2=false"
                        [ngStyle]="{
                            'color': (hover_lik2 ==true)||(current_window == 'services')  ? couleur :  'black'
                        }"
                        >Les services</a>
                </li>
                <li class="nav-item d-flex flex-row justify-content-center align-items-center mr-1">
                    <a class="nav-link avenir_heavy nav_bar_link h3_heavy_preview" id="assistance" target="_blank"
                        href="https://www.uzer.eu/assistance" (mouseover)="hover_lik3=true"
                        (mouseleave)="hover_lik3=false" (click)="current_window = 'assistance'"
                        [ngStyle]="{
                            'color': (hover_lik3 ==true)||(current_window == 'assistance')  ? couleur :  'black'
                         }"
                        >Assistance</a>
                </li>
                <li class="nav-item d-flex flex-row justify-content-center align-items-center mr-3">
                    <button type="button" class="btn connect_button btn-primary btn-sm h3_heavy_preview" style="text-align: center;vertical-align: baseline;" (mouseover)="hover=true"
                        (mouseleave)="hover=false" [ngStyle]="{  'border-color': couleur ,
								'color': hover==true  ? 'white' :  couleur ,
								'backgroundColor': hover==true  ? couleur : 'white'
							}" id="connect_button">Connexion</button>
                </li>
            </ul>

        </div>
    </div>
    </div>
</nav>
<div class="container-fluid">
    <div class="row">
        <div class="col-12 d-flex flex-column justify-content-center align-items-center" id="background" [ngStyle]="{'background-image':' url(' + cover_image+ ')'}">
            <div class="container" style="height: 100%;">
                <div class="row" style="height: 100%;">
                    <div class="col-12 d-flex flex-column align-items-center justify-content-center"style="height: 100%;">
                        <h1 class="mt-4 h_preview_1" style="color: white;">{{titrecover}}</h1>
                        <p class="mt-1  h_preview_2" style="color: white;">{{soustitrecover}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row pt-3 pb-1"  [ngStyle]="{'background-color' : couleur}">
        <div  class="col-12 d-flex flex-column justify-content-center ">

            <h3 class="h3_heavy_preview" [ngStyle]="{'color':'white', 'text-align': 'center' }">Nos services</h3>

            <div class="container d-flex flex-row justify-content-center " >
                <div *ngFor="let service of services" class="ml-2 mr-2">
                    <div class="card card_services 1 d-flex flex-column  justify-content-center" *ngIf="service.active==true && service.web==true">
					<div class="d-flex flex-column justify-content-center align-items-center">
						<div class="d-flex flex-row justify-content-center pb-1 mt-3">
							<svg-icon src="../../assets/img/{{service.nom + '.svg'}}" [stretch]="true"
								[svgStyle]="{'width.px':20, 'height.px':20 , 'fill': couleur,'stroke':  couleur}">
							</svg-icon>
						</div>
						<div class="d-flex flex-row justify-content-center mt-2">
							<p [ngStyle]="{'color':couleur, 'text-align': 'center' }" class="default h3_heavy_preview">
								{{service.nom}}</p>
						</div>
					</div>
				</div>

                </div>
            </div>
        </div>
    </div>
</div>

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from './../../../../services/user/user.service';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-deleteactus',
  templateUrl: './deleteactus.component.html',
  styleUrls: ['./deleteactus.component.scss']
})
export class DeleteactusComponent implements OnInit {

    constructor(
        public activeModal: NgbActiveModal
    ) { }

    ngOnInit(): void {

    }

    deleteactus() {
        this.activeModal.close('ok')
    }

    closeModal() {
        this.activeModal.close()
    }

}

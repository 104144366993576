import { UserBo } from 'src/app/models/user/user';
import { MENU, AGENTMENU } from './../../../../const/permissions/permissions'
import { MsgserviceService } from './../../../../services/msgservice/msgservice.service';
import { ActiveserviceComponent } from './../../modals/activeservice/activeservice/activeservice.component';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import * as $ from 'jquery';
import { Contrat } from 'src/app/models/contrat/contrat';
import { ContratService } from './../../../../services/contrat/contrat.service';
import { UserService } from './../../../../services/user/user.service';
import { Service } from './../../../../models/contrat/contrat';
import { User } from 'firebase';
import { MenuService } from './../../../../services/menu/menu.service';
import { AlertService } from './../../../../services/alert/alert.service';
import { LodingBarService } from './../../../../services/lodingbar/loding-bar-service.service';
import { AuthentificationService } from './../../../../services/authentification/authentification.service';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewChild } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';


@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

    expanded : boolean = false
    section : string
    subsection : string
    hover_botier : boolean = false
    hover_analyse : boolean =  false
    hover_signalement : boolean = false
    hover_territoire : boolean = false
    hover_carte : boolean = false
    hover_calendrier  : boolean = false
    hover_actus : boolean = false
    hover_alerts : boolean = false
    hover_stats : boolean = false
    hover_document : boolean = false
    imageUrl : string = "../../../../../assets/img/Header/002-header-camera-icon.svg"
    contrat : Contrat
    is_active_carte :  boolean = true
    is_active_calendar : boolean = true
    is_active_actus : boolean = true
    is_active_alertes : boolean =  true
    is_active_boitier : boolean = false
    is_active_reporting : boolean = false
    is_active_document : boolean = false
    user : UserBo
    arrayPerm
    hasUpdate = false
    @ViewChild('modalVersion') modalVersion : TemplateRef<any>; // 
    @ViewChild('content', { static: false }) private content;


    constructor(
        private authservice: AuthentificationService,
        private formBuilder: FormBuilder,
        private router: Router,
        private lodingBarService: LodingBarService,
        private alertservice: AlertService,
        private menuservice : MenuService,
        private  userService : UserService,
        private servicecontrat  : ContratService,
        public modalService: NgbModal,
        private messageService : MsgserviceService,
        private route:ActivatedRoute,
        private swUpdate: SwUpdate,
    ) { }

    ngOnInit(): void {
    
        if (this.swUpdate.isEnabled) {
            this.swUpdate.available.subscribe(event => {
                if(event.current != event.available){
                    this.hasUpdate = true;
                    setTimeout(() => {
                        this.openModal()
                    }, 2500); 
                }
            });
        }

        this.imageUrl = ""
        this.menuservice.section_obs.subscribe((section)=>{
            this.section = section
        })


        this.menuservice.subsection_obs.subscribe((subsection)=>{
            this.subsection =  subsection
        })

        this.userService.user_obs.subscribe((user) => {
            this.user = user
            
        })

        this.userService.profile_image_obs.subscribe((url) => {
            if (url == undefined || url == null || url == "")
            {
                this.imageUrl ="../../../../../assets/img/Header/002-header-camera-icon.svg";
            }
            else{
                this.imageUrl = url;
            }

            this.servicecontrat.contrat_obs.subscribe((contrat)=>{
                if(contrat.contrat.idContrat != 'BOTEST'){
                    this.contrat = contrat
                    this.is_active_carte = this.contrat.services.filter(item=>item.nom == "Carte des points de collecte")[0].active
                    this.is_active_calendar = this.contrat.services.filter(item=>item.nom == "Calendrier de collecte")[0].active
                    this.is_active_actus = this.contrat.services.filter(item=>item.nom == "Actualités et événements")[0].active
                    this.is_active_alertes = this.contrat.services.filter(item=>item.nom == "Alertes")[0].active
                    let service = this.contrat.services.filter(item=>item.nom == "Boitiers Montri")
                    this.is_active_boitier = service.length > 0 ? service[0].active : false
                    this.is_active_reporting = this.contrat.services.filter(item=>item.nom == "Demandes et Signalements")[0].active
                    this.is_active_document = this.contrat.services.filter(item=>item.nom == "Documents")[0].active
                }
            })

        });


    }

    reloadSite(): void {
        location.reload();
      }

    openModal(){
        let options: NgbModalOptions = {};
        //options.backdrop = false
        options.centered = true
		this.modalService.dismissAll()
        this.modalService.open(this.modalVersion, options)
    }


    logout() {
       this.authservice.logout()
    }

    checkRoles(permission:string) {
        if (this.user) {
            if(this.user.role === 'super_user' || this.user.role === 'Administrateur' || this.user.role === 'Normal') {
                return MENU.indexOf(permission) > -1
            } else if (this.user.role.indexOf("Agent") > -1) {
                return AGENTMENU.indexOf(permission) > -1
            }
        }
    }

    map(){
      this.router.navigateByUrl('montri/map')
    }

    SidebarCollapse () {
        this.expanded = !this.expanded

        $('.menu-collapsed').toggleClass('d-none');
        $('.sidebar-submenu').toggleClass('d-none');
        $('.submenu-icon').toggleClass('d-none');
        $('#sidebar-container').toggleClass('sidebar-expanded sidebar-collapsed');

        // Treating d-flex/d-none on separators with title
        var SeparatorTitle = $('.sidebar-separator-title');
        if ( SeparatorTitle.hasClass('d-flex') ) {
            SeparatorTitle.removeClass('d-flex');
        } else {
            SeparatorTitle.addClass('d-flex');
        }

        // Collapse/Expand icon
        $('#collapse-icon').toggleClass('fa-angle-double-left fa-angle-double-right');
        this.messageService.setMenuStatus(this.expanded)

    }

    gotTo(url : string){
        this.router.navigateByUrl(url)
    }

    openUrlScan(){
        window.open('https://escan.uzer.eu', '_blank');
    }

    showModal(){
        window.scrollTo(0, 0);
		let options: NgbModalOptions = {};
        options.centered = true
		this.modalService.dismissAll()
		const modalRef = this.modalService.open(ActiveserviceComponent,options)
    }


    goHome(){
        this.router.navigateByUrl("/montri/welcome")
    }

    updateContrat(){
        this.userService.updateUser(this.user)
    }



}

export const MENU = [
    'statistiques',
    'boitiers',
    'analyse',
    'signalements',
    'parametrage',
    'territoire',
    'carte',
    'calendrier',
    'actualites',
    'alertes',
    'documents' 
]

export const AGENTMENU = [
    'signalements',
]
import { AngularFireAuth, AngularFireAuthModule } from '@angular/fire/auth';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, RouterModule } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import * as firebase from "firebase/app";


import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/take';
let gid = ""
let mail = ""

@Injectable({
	providedIn: "root",
})
export class AuthAnonService implements CanActivate {

  constructor(private afAuth: AngularFireAuth,private router: Router) {}
   canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.afAuth.authState
     .take(1)
     .map(user => {
       return !!user
     })
     .do(loggedIn => {
       if (!loggedIn) {
        firebase.auth().signInAnonymously()
            .then(() => {
                // console.log(gid, mail)
                let user = firebase.auth().currentUser
                this.router.navigateByUrl(`/user/demande/${route.params.gid}/${route.params.mail}`);
                return true
                // user.delete()
            })
            .catch((error) => {
                var errorCode = error.code;
                var errorMessage = error.message;
                // ...
            });
       }
   }
   )
 }
}

    <div class="row mt-4 d-flex flex-row justify-content-start align-items-start">
        <div class="col-3">
            <p class="pb-0 mb-0 h3">Site internet et réseaux sociaux :</p>
            <p class="pb-0 p2">C’est le site internet de votre territoire.</p>
            <input type="text" *ngIf="user!=undefined && contratInfos!=undefined" [readonly]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')"  style="font-family:Arial, FontAwesome" ngClass="{{siteContratError ? 'colorerrorinput' : 'colornoterrorinput'}}" autocomplete="off"  [(ngModel)]="contratInfos.siteContrat" (ngModelChange)="onChange()"  (focus)="siteContratError = false" placeholder="{{siteContratError ?'&#xf071; Champs obligatoire':'url(www.votresite.fr)'}}"  class="form-control input1" id="site_contract"  required>
        </div>
        <div class="col-9">
            <p class="pb-0 mb-0 h3">Renseignez les codes postaux de votre territoire :</p>
            <p class="pb-0 p2">Tapez les 5 chiffres des codes postaux et seélectionnez-les dans la liste</p>

            <span >
                <p-autoComplete [(ngModel)]="selectedZipcodes" (onKeyUp)="showZipCodeError=false"  [suggestions]="filteredZipCodes" (completeMethod)="filterCountry($event)" field="codePostal" [multiple]="true"  minLength="5" maxlength="5" oninvalid="setCustomValidity('Please Select a Name.')" onchange="try{setCustomValidity('')}catch(e){}" >
                </p-autoComplete>
            </span>
            <p class="pb-0 p2 mt-1" *ngIf="showZipCodeError" style="color: red;"> Ce code postal n’est pas dans notre base de données</p>
        </div>
    </div>

    <div class="row mt-4 d-flex flex-column justify-content-start align-items-start">
        <div class="col-12">
            <p class=" pb-0 mb-0 h3">Réseaux sociaux :</p>
            <p class="pt-0 mb-0 p2">Renseignez vos identifiants réseaux sociaux si vous souhaitez les faire apparaitre dans l’application.</p>
        </div>
    </div>

    <div class="row mt-3">
        <div class="col-md-6">
            <div class="row">
                <div class="col-lg-6">
                    <input type="text" *ngIf="user!=undefined && socialMedia!= undefined"  [readonly]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')"  [ngStyle]="{'cursor' :  ((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification') ? 'not-allowed' : null }"  autocomplete="off"  [(ngModel)]="socialMedia.lienFacebook" (ngModelChange)="onChange()"   placeholder="ID Facebook" class="form-control input1"  required>
                </div>
                <div class="col-lg-6">
                    <input type="text"  *ngIf="user!=undefined && socialMedia!=undefined" [readonly]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')"   autocomplete="off"  [ngStyle]="{'cursor' :  ((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification') ? 'not-allowed' : null }" [(ngModel)]="socialMedia.lienTwitter" (ngModelChange)="onChange()"   placeholder="Twitter (@...)" class="form-control input1"   required>
                </div>
            </div>
        </div>

        <div class="col-md-6">
            <div class="row">
                <div class="col-lg-6">
                    <input type="text" *ngIf="user!=undefined && socialMedia!=undefined"  [ngStyle]="{'cursor' :  ((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification') ? 'not-allowed' : null }"  [readonly]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')"   autocomplete="off"  [(ngModel)]="socialMedia.lienYoutube" (ngModelChange)="onChange()"  placeholder="ID Youtube" class="form-control input1"   required>
                </div>
                <div class="col-lg-6">
                    <input type="text" *ngIf="user!=undefined && socialMedia!=undefined"  [ngStyle]="{'cursor' :  ((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification') ? 'not-allowed' : null }"  [readonly]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')"   autocomplete="off"  [(ngModel)]="socialMedia.lienInstagram" (ngModelChange)="onChange()"   placeholder="ID Instagram" class="form-control input1"  required>
                </div>
            </div>
        </div>
    </div>

    <div class="row mt-4 d-flex flex-column justify-content-start align-items-start">
        <div class="col-12">
            <p class=" pb-0 mb-0 h3">Adresse postale :</p>
            <p class="pt-0 mb-0 p2">C’est l’adresse postale qui sera affichée dans l’application dans la rubrique contact.</p>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-md-6">
            <div class="row">
                <div class="col-lg-6">
                    <input type="text" *ngIf="user!=undefined && contact!=undefined" [ngStyle]="{'cursor' :  ((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification') ? 'not-allowed' : null }" [readonly]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')"  (focus)="nomContactError = false" style="font-family:Arial, FontAwesome" ngClass="{{nomContactError ? 'colorerrorinput' : 'colornoterrorinput'}}" autocomplete="off" [(ngModel)]="contact.nomContact" (ngModelChange)="onChange()" placeholder="{{nomContactError ? '&#xf071; Champs obligatoire':'Nom du service'}}" class="form-control input1"  required>
                </div>
                <div class="col-lg-6">
                    <input type="text" *ngIf="user!=undefined && contact!=undefined" [ngStyle]="{'cursor' :  ((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification') ? 'not-allowed' : null }" [readonly]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')"  (focus)="nomVoieError = false"  style="font-family:Arial, FontAwesome" ngClass="{{nomVoieError ? 'colorerrorinput' : 'colornoterrorinput'}}" autocomplete="off"  [(ngModel)]="contact.nomVoie" (ngModelChange)="onChange()"    placeholder="{{nomVoieError ?'&#xf071; Champs obligatoire':'Numéro et voie'}}"  class="form-control input1"   required>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="row">
                <div class="col-lg-6">
                    <input type="text" *ngIf="user!=undefined && contact!=undefined" [ngStyle]="{'cursor' :  ((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification') ? 'not-allowed' : null }" [readonly]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')"   (focus)="cpError = false"  style="font-family:Arial, FontAwesome" ngClass="{{cpError ? 'colorerrorinput' : 'colornoterrorinput'}}" autocomplete="off"  [(ngModel)]="contact.cp" (ngModelChange)="onChange()"    placeholder="{{cpError ? '&#xf071; Champs obligatoire':'Code postal'}}" class="form-control input1"   required>
                </div>
                <div class="col-lg-6">
                    <input type="text" *ngIf="user!=undefined && contact!=undefined"  [ngStyle]="{'cursor' :  ((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification') ? 'not-allowed' : null }" [readonly]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')"   (focus)="villeError = false" style="font-family:Arial, FontAwesome" ngClass="{{villeError ? 'colorerrorinput' : 'colornoterrorinput'}}" autocomplete="off" [(ngModel)]="contact.ville" (ngModelChange)="onChange()"   placeholder="{{villeError ?'&#xf071; Champs obligatoire':'Ville'}}" class="form-control input1"   required>
                </div>
            </div>
        </div>
    </div>

    <div class="row mt-4 d-flex flex-column justify-content-start align-items-start">
        <div class="col-12">
            <p class=" pb-0 mb-0 h3">Coordonnées de contact :</p>
            <p class="pt-0 mb-0 p2">Veuillez renseigner vos coordonnées de contact</p>
        </div>
    </div>

    <div class="row mt-3">

        <div class="col-md-6">
            <div class="row">
                <div class="col-lg-6">
                    <input type="text" *ngIf="user!=undefined && contact!=undefined" [ngStyle]="{'cursor' :  ((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification') ? 'not-allowed' : null }" [readonly]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')"   (focus)="mailContactError = false" style="font-family:Arial, FontAwesome" ngClass="{{mailContactError ? 'colorerrorinput' : 'colornoterrorinput'}}" autocomplete="off" [(ngModel)]="contact.mailContact" (ngModelChange)="onChange()"  placeholder="{{mailContactError ?'&#xf071; Champs obligatoire':'Adresse email'}}" class="form-control input1"   required>
                </div>
                <div class="col-lg-6">
                    <input type="text" *ngIf="user!=undefined && contact!=undefined" [ngStyle]="{'cursor' :  ((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification') ? 'not-allowed' : null }"  [readonly]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')"   (focus)="telephoneContactError = false"  style="font-family:Arial, FontAwesome" ngClass="{{telephoneContactError ? 'colorerrorinput' : 'colornoterrorinput'}}" autocomplete="off" [(ngModel)]="contact.telephoneContact" (ngModelChange)="onChange()"   placeholder="{{telephoneContactError ?'&#xf071; Champs obligatoire':'Numéro de téléphone'}}" class="form-control input1"   required>
                </div>
            </div>
        </div>

        <div class="col-md-6">
            <div class="row">
                <div class="col-lg-6">
                    <input type="text" *ngIf="user!=undefined && contact!=undefined" [ngStyle]="{'cursor' :  ((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification') ? 'not-allowed' : null }" [readonly]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')"  (focus)="horairesSupportError = false" style="font-family:Arial, FontAwesome" ngClass="{{horairesSupportError ? 'colorerrorinput' : 'colornoterrorinput'}}" autocomplete="off" [(ngModel)]="contact.horairesSupport" (ngModelChange)="onChange()"  placeholder="{{horairesSupportError ?'&#xf071; Champs obligatoire':'Horaires d’ouverture'}}" class="form-control input1"   required>
                </div>
                <div class="col-lg-6">
                    <input type="text" *ngIf="user!=undefined && contact!=undefined" [ngStyle]="{'cursor' :  ((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification') ? 'not-allowed' : null }" [readonly]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')"  (focus)="prixAppelError = false"  style="font-family:Arial, FontAwesome" ngClass="{{prixAppelError ? 'colorerrorinput' : 'colornoterrorinput'}}" autocomplete="off" [(ngModel)]="contact.prixAppel" (ngModelChange)="onChange()"  placeholder="{{prixAppelError ?'&#xf071; Champs obligatoire':'Tarif d’un appel'}}" class="form-control input1"  required>
                </div>
            </div>
        </div>
    </div>

    <div class="row mt-5 mb-4">

        <div class="col-lg-3 mt-3 mt-lg-0 d-flex flex-row justify-content-center">
            <button type="button" class="btn  btn-primary w-100  h3_heavy button4"  (click)="saveData()" ngClass="{{next_status ? 'opacity05' : ''}}">Suivant</button>
        </div>
    </div>

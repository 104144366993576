<div class="row">
    <div class="col-12 mb-4 d-flex flex-column justify-content-center align-items-lg-start align-items-center">
        <p class="pb-0 mb-0  h3">Sélectionnez vos services :  </p>
        <p class="pb-0 mb-0 mt-0 p2">Activez ici les services que vous souhaitez intégrer dans votre application Montri.</p>
    </div>
</div>

<div *ngFor="let service of services ; index as i">
    <div  class="row  mt-5 mt-lg-2 " >
        <div class="col-lg-1 d-flex flex-row justify-content-lg-start justify-content-center align-items-center">
            <div class="btn-group" role="group" id={{service.nom}} >
                <input type="radio"  name="{{service.nom}}" [value]="true" [disabled]="service.nom =='Scan' || service.nom == 'Documents' || service.nom == 'Guide du tri' || service.nom == 'Alertes' || service.nom == 'Actualités et événements' || (((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')) ? true : false" [(ngModel)]="service.active" (ngModelChange)="onchangeService($event,service.nom)" id="{{'radiobtoui'+ i}}"/>
                <label class="btn btn-secondary radius_left" id="{{'radiooui'+ i}}" for="{{'radiobtoui'+ i}}"  [ngStyle]="{'cursor' :  ((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification') ? 'not-allowed' : null }">
                    Oui
                </label>
                <input type="radio" [value]="false" [(ngModel)]="service.active"  [disabled]="service.nom =='Scan' || service.nom == 'Documents' || service.nom == 'Guide du tri' || service.nom == 'Alertes' || service.nom == 'Actualités et événements' || (((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')) ? true : false" (ngModelChange)="onchangeService($event,service.nom)"   name="{{service.nom}}"  class="radio_input radio_non"   id="{{'radiobtnon'+ i}}"/>
                <label  class="btn btn-secondary radius_right" id="{{'radionon'+ i}}"   for="{{'radiobtnon'+ i}}" [ngStyle]="{'cursor' :  ((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification') ? 'not-allowed' : null }">
                    Non
                </label>
            </div>
        </div>

        <div class="col-lg-3 ml-lg-2 p1  d-flex flex-row justify-content-lg-start justify-content-center align-items-center">
                {{service.nom}}
        </div>

        <div class="col-lg-1 ml-lg-2 p1  d-flex flex-row justify-content-lg-start justify-content-center align-items-center">
            <button type="button" (click)="serviceIndexMondal=i; openModal(service)"  class="btn btn-primary w-100 btn_partout  p3" [ngStyle]="{'backgroundColor' : service.partout ?  '#C3E9D7' : '#F9C4CD' }" >{{service.partout ? 'Partout':'Limité'}}</button>
       </div>

    </div>

    <div *ngIf="service.nom == 'Guide du tri'">
        <div class="row mt-3 mb-2">
            <div class="col-lg-1"></div>
            <div class="col-lg-5  d-flex flex-row justify-content-center justify-content-lg-start">
                <div class="row w-100">
                    <div class="col-lg-3  d-flex flex-row justify-content-center justify-content-lg-start ">
                        <button type="button"  class="btn btn-primary w-100 btn_partout  p3" style="background-color: darkgrey;" ><a href="{{GuideDuTriTemplate}}" [ngStyle]="{'cursor' :  ((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification') ? 'not-allowed' : null }" style='text-decoration:none;color: white;' download>Télécharger</a></button>

                        <!-- <button type="button" class="btn btn-secondary button6"><a href="{{GuideDuTriTemplate}}" [ngStyle]="{'cursor' :  ((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification') ? 'not-allowed' : null }" style='text-decoration:none;color: white;' download>Télécharger</a></button> -->
                    </div>
                    <div class="col-lg-6  d-flex flex-column  justify-content-center p1 align-items-lg-start align-items-center" style="height: 35px;">

                        <p class="pt-0 mb-0 p2" style="font-style: italic;">Télécharger le fichier exemple</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-5 mt-lg-2 ">
            <div class="col-lg-1"></div>
            <div class="col-lg-4 d-flex flex-row justify-content-lg-start justify-content-center"  *ngIf="contrat!=undefined">
                    <img src="../../../../../assets/img/Boutons/002-Delete-icon.svg"  [ngStyle]="{'cursor' :  ((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification') ? 'not-allowed' : null }"
                    style="width: 35px;height: 35px;" (click)="fileInput1.value=null;deleteGuideDuTri()" [hidden]="contrat.localfiles == undefined || contrat.localfiles.localGuideDuTri == ''">
                    <input type="file" name="file1" #fileInput1 id="file1" class="inputfile"  [disabled]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')"
                        (change)="sendGuideDuTri(fileInput1.files[0])" />
                    <label for="file1" style="cursor: pointer;"
                        class="d-flex flex-row w-100  d-flex flex-row justify-content-center justify-content-lg-start ">
                        <img src="../../../../../assets/img/028-addelementleft.svg"  [ngStyle]="{'cursor' :  ((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification') ? 'not-allowed' : null }"
                        style="width: 35px;height: 35px;"  [hidden]="contrat.localfiles != undefined && contrat.localfiles.localGuideDuTri != ''" >

                    <input type="text" name="file1" class="form-control classname w-100 h4" [disabled]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')"
                        style="height: 35px;font-style: italic;border-radius: 0 10px 10px 0 !important;"
                        value="{{ contrat.localfiles != undefined && contrat.localfiles.localGuideDuTri != '' ? contrat.localfiles.localGuideDuTri  : 'Charger votre fichier'}}" readonly />
                    </label>
            </div>
        </div>
    </div>

    <div *ngIf="service.sousServices != undefined && service.active && service.nom == 'Alertes'" >
        <div class="row  mt-5 mt-lg-2 "  *ngFor="let subservice of service.sousServices ; index as k">
            <div class="col-lg-1"></div>
            <div class="col-lg-1 d-flex flex-row justify-content-lg-start justify-content-center align-items-center" >
                <div class="btn-group" role="group" id={{subservice.nom}} >
                    <input type="radio"  name="{{subservice.nom}}" [value]="true"  [(ngModel)]="subservice.active" (ngModelChange)="onchange($event,k)" id="{{'radiosubbtoui'+ k+i}}" [disabled]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')"/>
                    <label class="btn btn-secondary radius_left " id="{{'radiosuboui'+ i}}" for="{{'radiosubbtoui'+ k+i}}" [ngStyle]="{'cursor' :  ((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification') ? 'not-allowed' : null }">
                        Oui
                    </label>
                    <input type="radio" [value]="false" [(ngModel)]="subservice.active"  (ngModelChange)="onchange($event,i)"   name="{{subservice.nom}}"  class="radio_input radio_non"   id="{{'radiosubbtnon'+ k+i}}" [disabled]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')"/>
                    <label  class="btn btn-secondary radius_right" id="{{'radiosubnon'+ i}}"   for="{{'radiosubbtnon'+ k+i}}"[ngStyle]="{'cursor' :  ((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification') ? 'not-allowed' : null }">
                        Non
                    </label>
                </div>
            </div>

            <div class="col-lg-2 ml-lg-2 p1  d-flex flex-row justify-content-lg-start justify-content-center align-items-center">
                    {{subservice.nom}}
            </div>

            <div class="col-lg-3" *ngIf="service.nom == 'Demandes et Signalements' && subservice.active">
                <input type="text"  [readonly]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')"  [ngStyle]="{'cursor' :  ((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification') ? 'not-allowed' : null }"  autocomplete="off"  [(ngModel)]="subservice.email" (ngModelChange)="onChange()" style="font-family:Arial, FontAwesome"  placeholder="&#xf0e0; Email destinataire" class="form-control input1"  required>
            </div>



        </div>
    </div>

    <div *ngIf="service.sousServices != undefined && service.active && service.nom == 'Demandes et Signalements'" >
        <div class="row  mt-5 mt-lg-2 "  *ngFor="let subservice of service.sousServices ; index as k">
            <div class="col-lg-1"></div>
            <div class="col-lg-1 d-flex flex-row justify-content-lg-start justify-content-center align-items-center">
                <div class="btn-group" role="group" id={{subservice.nom}} >
                    <input type="radio"  name="{{subservice.nom}}" [value]="true"  [(ngModel)]="subservice.active" (ngModelChange)="onchange($event,k)" id="{{'radiosubbtoui'+ k+i}}" [disabled]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')"/>
                    <label class="btn btn-secondary radius_left " id="{{'radiosuboui'+ i}}" for="{{'radiosubbtoui'+ k+i}}" [ngStyle]="{'cursor' :  ((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification') ? 'not-allowed' : null }">
                        Oui
                    </label>
                    <input type="radio" [value]="false" [(ngModel)]="subservice.active"  (ngModelChange)="onchange($event,i)"   name="{{subservice.nom}}"  class="radio_input radio_non"   id="{{'radiosubbtnon'+ k+i}}" [disabled]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')"/>
                    <label  class="btn btn-secondary radius_right" id="{{'radiosubnon'+ i}}"   for="{{'radiosubbtnon'+ k+i}}"[ngStyle]="{'cursor' :  ((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification') ? 'not-allowed' : null }">
                        Non
                    </label>
                </div>
            </div>

            <div class="col-lg-2 ml-lg-2 p1  d-flex flex-row justify-content-lg-start justify-content-center align-items-center">
                    {{subservice.nom}}
            </div>
            <ng-container *ngIf='destinataire === false'>            
            <div class="col-lg-3" *ngIf="service.nom == 'Demandes et Signalements' && subservice.active">
                <input type="text"  [readonly]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')"  [ngStyle]="{'cursor' :  ((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification') ? 'not-allowed' : null }"  autocomplete="off"  [(ngModel)]="subservice.email" (ngModelChange)="onChange();subservice.url=''" style="font-family:Arial, FontAwesome"   (focus)="subservice.error = false" style="font-family:Arial, FontAwesome" ngClass="{{ subservice.error == true ? 'colorerrorinput' : 'colornoterrorinput'}}"  placeholder="{{ subservice.error ?'&#xf071; '+subservice.emailMsgError :'&#xf0e0; Email destinataire'}}"   class="form-control input1"  required>
            </div>

            <div class="col-lg-3" *ngIf="service.nom == 'Demandes et Signalements' && subservice.active">
                <input type="text"  [readonly]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')"  [ngStyle]="{'cursor' :  ((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification') ? 'not-allowed' : null }"  autocomplete="off"  [(ngModel)]="subservice.url" (ngModelChange)="onChange();subservice.email = ''" style="font-family:Arial, FontAwesome"  (focus)="subservice.error = false" style="font-family:Arial, FontAwesome" ngClass="{{ subservice.error == true ? 'colorerrorinput' : 'colornoterrorinput'}}"  placeholder="{{ subservice.error ?'&#xf071; '+subservice.urlMsgError :'Url'}}"  class="form-control input1"  required>
            </div>
         </ng-container>

        </div>
    </div>

</div>

<div class="row mt-5 mb-4">
    <div class="col-lg-3 d-flex flex-row justify-content-center">
        <button type="button"  class="btn  btn-primary w-100 button2" routerLink="/montri/contractsettings/design">Précédent</button>
    </div>
    <div class="col-lg-3 mt-3 mt-lg-0 d-flex flex-row justify-content-center">
        <button type="button" class="btn  btn-primary w-100  h3_heavy button4"  (click)="saveData()" ngClass="{{ guideDutri != [] ? '': 'opacity05'}}">Suivant</button>
    </div>
</div>

<ng-template #serviceVilles let-c="close" let-d="dismiss">
    <div class="modal-content">
        <div class="modal-header d-flex flex-row justify-content-start mt-4" style='border: none;'>
            <p class=" pb-0 mb-0 h3">Déselectionnez les codes postaux pour lesquels vous ne <br/>
                souhaitez pas proposer ce service :</p>
        </div>
        <div class="modal-body">
            <div class="parent">
                <button *ngFor="let item of selectedVilles" (click)="item.active = !item.active" class="btn_ville" [ngStyle]="{'backgroundColor' :  item.active?  '#C3E9D7' : '#F9C4CD' }" >
                    {{item.zipCode}}
                </button>
            </div>
            <div class="row mb-3 mt-5">
                <div class="col-lg-4 mt-3 mt-lg-0 ">
                    <button type="button" class="btn button7 btn-primary w-100" (click)="cancelServiceVilles()" >Annuler</button>
                </div>
                <div class="col-lg-4">
                    <button type="button" class="btn button4 btn-primary w-100" [disabled]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')" (click)="saveVilles()" >Valider</button>
                </div>

            </div>
        </div>

    </div>
</ng-template>




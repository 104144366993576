import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-deletealert',
  templateUrl: './deletealert.component.html',
  styleUrls: ['./deletealert.component.scss']
})
export class DeletealertComponent implements OnInit {

    constructor(
        public activeModal: NgbActiveModal
    ) { }

    ngOnInit(): void {

    }

    deleteactus() {
        this.activeModal.close('ok')
    }

    closeModal() {
        this.activeModal.close()
    }
}

<div class="row">
    <div class="col-12 d-flex flex-column justify-content-center align-items-lg-start align-items-center">
        <p class="pb-0 mb-0  h3">Paramétrez le slider de l'application mobile :  </p>
        <p class="pb-0 mb-0 mt-0 p2">Chargez votre écran de bienvenue qui s’affichera juste avant le slider. </p>
    </div>
</div>

<div class="row mt-4" *ngIf="user!=undefined">
    <div class="col-lg-5  d-flex flex-row justify-content-center justify-content-lg-start">
        <div class="row w-100">
            <div class="col-lg-3  d-flex flex-row justify-content-center justify-content-lg-start ">
                <button type="button" class="btn btn-secondary button6"><a href="{{splashScreentemplate}}" [ngStyle]="{'cursor' :  ((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification') ? 'not-allowed' : null }" style='text-decoration:none;color: white;' download>Télécharger</a></button>
            </div>
            <div class="col-lg-6  d-flex flex-column  justify-content-center p1 align-items-lg-start align-items-center" style="height: 35px;">
                Fichier exemple (Format .ai)
            </div>
        </div>
    </div>
</div>

<div class="row mt-4" *ngIf="user!=undefined">
    <div class="col-lg-5  d-flex flex-row justify-content-center justify-content-lg-start">
        <div class="row w-100">
            <div class="col-lg-6 d-flex flex-row justify-content-center justify-content-lg-start" *ngIf="user!=undefined && contrat != undefined">
                <img src="../../../../../assets/img/Boutons/002-Delete-icon.svg" [ngStyle]="{'cursor' :  ((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification') ? 'not-allowed' : 'pointer' }"
                style="width: 35px;height: 35px;" (click)="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')?false : fileInput.value=null; ((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification') ? false :deleteSplashScreen()" [hidden]="contrat.localfiles == undefined || contrat.localfiles.localSplashScreen == ''">
                <input type="file" name="file" #fileInput id="file" class="inputfile" [disabled]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')"
                    (change)="sendSplashScreen(fileInput.files[0])" />
                <label for="file" style="cursor: pointer;"
                    class="d-flex flex-row w-100  d-flex flex-row justify-content-center justify-content-lg-start ">
                    <img src="../../../../../assets/img/028-addelementleft.svg" [ngStyle]="{'cursor' :  ((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification') ? 'not-allowed' : 'pointer' }"
                        style="width: 35px;height: 35px;"  [hidden]="contrat.localfiles != undefined && contrat.localfiles.localSplashScreen != ''" >

                    <input type="text"  name="file" class="form-control classname w-100 h4"   [disabled]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')"
                        style="height: 35px;border-radius: 0 10px 10px 0 !important;color: ;"
                        value="{{ contrat.localfiles != undefined && contrat.localfiles.localSplashScreen != '' ?  contrat.localfiles.localSplashScreen  : 'Charger une image'}}" readonly />
                </label>
            </div>
            <div class="col-lg-6  d-flex flex-column  justify-content-center p2 align-items-lg-start align-items-center" style="height: 35px;">
                Format .png (1125px * 1780px)
            </div>
        </div>
    </div>
</div>


<div class="row mt-4">
    <div class="col-12 d-flex flex-column justify-content-center align-items-lg-start align-items-center">
        <p class="pb-0 mb-0  h3">Paramétrez le slider :  </p>
        <p class="pb-0 mb-0 mt-0 p2">Sélectionnez vos services à mettre en avant. Pour chaque service, remplissez le titre et les descriptions. 4 écrans seront affichés au maximum</p>
    </div>
</div>

<div class="row mt-3" *ngIf="user != undefined">
    <div class="col-lg-5">
        <div *ngFor="let service of services ; index as i">
            <div  class="row  mt-5 mt-lg-2 " *ngIf="service.active" >
                <div class="col-lg-3 d-flex flex-row justify-content-lg-start justify-content-center align-items-center">
                    <div class="btn-group" role="group" id={{service.nom}} >
                        <input type="radio" name="{{service.nom}}" [value]="true"  [disabled]="(((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')) ? true : false"     [(ngModel)]="service.slider" (ngModelChange)="onchange($event,i)" id="{{'radiobtoui'+ i}}"/>
                        <label class="btn btn-secondary   radius_left" id="{{'radiooui'+ i}}" for="{{'radiobtoui'+ i}}" [ngStyle]="{'cursor' :  ((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification') ? 'not-allowed' : null }">
                            Oui
                        </label>
                        <input type="radio" [value]="false" [(ngModel)]="service.slider"  [disabled]="(((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')) ? true : false" (ngModelChange)="onchange($event,i)"   name="{{service.nom}}"  class="radio_input radio_non"   id="{{'radiobtnon'+ i}}"/>
                        <label  class="btn btn-secondary  radius_right" id="{{'radionon'+ i}}"   for="{{'radiobtnon'+ i}}" [ngStyle]="{'cursor' :  ((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification') ? 'not-allowed' : null }">
                            Non
                        </label>
                    </div>
                </div>

                <div class="col-lg-7 ml-lg-2  p1 d-flex flex-row justify-content-lg-start justify-content-center align-items-center">
                    {{service.nom}}
                </div>

                <div class="col-lg-1 ml-lg-2  heavy_gray d-flex flex-row justify-content-lg-start justify-content-center align-items-center">
                    <svg-icon src="../../../../../assets/img/033-pencil.svg" [stretch]="true" *ngIf="service.nom!=''&& service.description1!=''&& service.slider==true" (click)="showModal(service.nom)"
								[svgStyle]="{'width.px':20, 'height.px':20 , 'fill': '#5EC394' ,'stroke': '#5EC394'}">
                    </svg-icon>

                    <svg-icon src="../../../../../assets/img/033-pencil.svg" [stretch]="true"  *ngIf="!(service.nom !=''&& service.description1 != '') && service.slider == true" (click)="showModal(service.nom)"
								[svgStyle]="{'width.px':20, 'height.px':20 , 'fill': '#f47000' ,'stroke': '#f47000'}">
                    </svg-icon>

                    <svg-icon src="../../../../../assets/img/033-pencil.svg" [stretch]="true" *ngIf="service.slider == false"
								[svgStyle]="{'width.px':20, 'height.px':20 , 'fill': '#707070' ,'stroke': '#707070'}">
					</svg-icon>
                </div>
            </div>
        </div>
    </div>

    <div class="col-lg-7" >
        <div class="row mt-0 pt-0">
            <div class="col-lg-6 mt-0 pt-0 d-flex flex-row justify-content-end">
                <svg version="1.1" id="Layer_1"  xmlns:x="&ns_extend;" xmlns:i="&ns_ai;" xmlns:graph="&ns_graphs;"
             xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="287px" height="454px"
             viewBox="0 0 300 605" xml:space="preserve">

        <switch>
            <g i:extraneous="self">
                <path fill="#B4B4B4" d="M299.896,207.294c0.062-0.188,0.104-0.385,0.104-0.594v-65.04c0-0.209-0.042-0.405-0.104-0.593
                    c-0.012-0.029-0.018-0.057-0.025-0.086c-0.07-0.179-0.164-0.343-0.277-0.493c-0.016-0.021-0.029-0.038-0.046-0.057
                    c-0.262-0.316-0.616-0.547-1.026-0.648c-0.146-0.034-0.297-0.059-0.455-0.059h-0.588v68.914h0.588
                    c0.599,0,1.127-0.278,1.481-0.705c0.017-0.02,0.03-0.038,0.046-0.057c0.113-0.149,0.207-0.313,0.277-0.494
                    C299.88,207.351,299.886,207.322,299.896,207.294"/>
                <path fill="#222325" d="M186.079,23.776c0,2.538-2.056,4.597-4.591,4.597c-2.533,0-4.59-2.059-4.59-4.597
                    c0-2.537,2.057-4.595,4.59-4.595C184.023,19.181,186.079,21.239,186.079,23.776"/>
                <path fill="#222325" d="M167.427,23.776c0,1.355-1.099,2.454-2.448,2.454h-32.102c-1.354,0-2.452-1.099-2.452-2.454
                    c0-1.354,1.098-2.452,2.452-2.452h32.102C166.328,21.324,167.427,22.422,167.427,23.776"/>
                <path fill="#FFFFFF" d="M43.523,36.045c0.102,0.343,0.532,0.706,1.249,0.706c1.363,0,1.871-1.322,1.827-2.943H46.53
                    c-0.231,0.605-0.852,1.141-1.884,1.141c-1.352,0-2.462-0.931-2.462-2.568c0-1.644,1.179-2.625,2.725-2.625
                    c1.394,0,2.8,0.83,2.8,3.861c0,2.738-0.953,4.151-2.96,4.151c-1.475,0-2.271-0.846-2.368-1.722L43.523,36.045L43.523,36.045z
                     M44.844,30.771c-0.833,0-1.521,0.6-1.521,1.61c0,1.057,0.714,1.575,1.543,1.575c0.808,0,1.543-0.514,1.543-1.551
                    C46.409,31.364,45.734,30.771,44.844,30.771"/>
                <path fill="#FFFFFF" d="M50.25,32.207c0.495,0,0.874,0.36,0.874,0.88c0,0.509-0.378,0.863-0.874,0.863
                    c-0.464,0-0.846-0.354-0.846-0.863C49.403,32.562,49.779,32.207,50.25,32.207 M50.25,35.989c0.495,0,0.874,0.356,0.874,0.88
                    c0,0.508-0.378,0.859-0.874,0.859c-0.464,0-0.846-0.351-0.846-0.859C49.403,36.345,49.779,35.989,50.25,35.989"/>
                <path fill="#FFFFFF" d="M57.467,29.918v5.148h0.989v1.045h-0.989v1.514h-1.098v-1.514h-3.74V35.05
                    c0.948-1.715,2.068-3.42,3.139-5.131L57.467,29.918L57.467,29.918z M56.369,35.067v-4.143h-0.063
                    c-0.862,1.292-1.783,2.727-2.56,4.092v0.05L56.369,35.067L56.369,35.067z"/>
                <polygon fill="#FFFFFF" points="61.624,37.625 61.624,31.184 61.567,31.184 59.793,32.455 59.793,31.257 61.624,29.918
                    62.771,29.918 62.771,37.625 		"/>
                <path fill="#FFFFFF" d="M235.031,37.279c0,0.192-0.155,0.347-0.348,0.347h-1.043c-0.193,0-0.349-0.155-0.349-0.347V30.27
                    c0-0.191,0.153-0.347,0.349-0.347h1.043c0.191,0,0.348,0.155,0.348,0.347V37.279z"/>
                <path fill="#FFFFFF" d="M231.854,37.279c0,0.192-0.156,0.347-0.349,0.347h-1.042c-0.193,0-0.348-0.155-0.348-0.347V32.15
                    c0-0.193,0.154-0.347,0.348-0.347h1.042c0.19,0,0.349,0.154,0.349,0.347V37.279z"/>
                <path fill="#FFFFFF" d="M228.678,37.279c0,0.192-0.156,0.347-0.349,0.347h-1.043c-0.19,0-0.347-0.155-0.347-0.347v-3.562
                    c0-0.193,0.154-0.349,0.347-0.349h1.043c0.192,0,0.349,0.156,0.349,0.349V37.279z"/>
                <path fill="#FFFFFF" d="M225.501,37.279c0,0.192-0.157,0.347-0.349,0.347h-1.045c-0.191,0-0.346-0.155-0.346-0.347v-2.311
                    c0-0.19,0.153-0.346,0.346-0.346h1.045c0.19,0,0.349,0.156,0.349,0.346V37.279z"/>
                <path fill="#FFFFFF" d="M240.279,33.436c-0.471-0.461-0.928-0.906-1.383-1.353c2.838-2.896,7.814-3.006,10.799,0.004
                    c-0.449,0.453-0.899,0.907-1.363,1.376c-1.13-1.062-2.469-1.632-4.027-1.638C242.743,31.823,241.4,32.383,240.279,33.436"/>
                <path fill="#FFFFFF" d="M242.26,35.45c-0.461-0.451-0.916-0.897-1.371-1.345c1.771-1.827,4.963-1.879,6.787-0.019
                    c-0.445,0.458-0.893,0.913-1.343,1.372C244.973,34.396,243.613,34.394,242.26,35.45"/>
                <path fill="#FFFFFF" d="M245.627,36.14c-0.454,0.459-0.896,0.91-1.334,1.354c-0.45-0.449-0.896-0.895-1.343-1.341
                    C243.531,35.51,244.857,35.397,245.627,36.14"/>

                <path fill="#FFFFFF" d="M267.344,36.091c0,0.192-0.154,0.349-0.348,0.349h-11.863c-0.191,0-0.35-0.156-0.35-0.349V31.46
                    c0-0.194,0.156-0.35,0.35-0.35h11.863c0.191,0,0.348,0.156,0.348,0.35V36.091z"/>
                <path fill="#FFFFFF" d="M270.377,33.774c0,0.514-0.418,0.933-0.93,0.933v-1.865C269.959,32.842,270.377,33.259,270.377,33.774"/>
                <path fill="#B4B4B4" d="M298.111,56.203c0-19.638-2.012-31.848-14.428-44.033C271.691,0.4,254.395,0,248.534,0H49.573
                    c-5.859,0-23.158,0.4-35.148,12.17C2.01,24.355,0,36.565,0,56.203V547.31c0,19.638,2.01,31.848,14.424,44.032
                    c11.99,11.77,29.29,12.171,35.148,12.168h198.961c5.859,0.003,23.158-0.398,35.148-12.168
                    c12.418-12.186,14.428-24.396,14.428-44.032V56.203H298.111z"/>
                <path d="M49.573,601.064c-17.983,0-28.106-6.236-33.437-11.471C4.491,578.166,2.441,566.852,2.441,547.31V56.204
                    c0-19.545,2.049-30.857,13.696-42.287C21.466,8.682,31.594,2.448,49.561,2.448h198.973c17.981,0,28.108,6.234,33.438,11.469
                    c11.646,11.43,13.694,22.742,13.694,42.287V547.31c0,19.542-2.049,30.856-13.694,42.285c-5.33,5.233-15.453,11.47-33.394,11.47
                    H49.573L49.573,601.064z"/>
                <text transform="matrix(1 0 0 1 145.7354 66.3037)" fill="#3E3D40" font-family="'DINBoldAlternate'" font-size="11.202"> </text>
                <g>
                    <g>
                        <path id="SVGID_3_" fill="#FFFFFF" d="M270.693,23.842c-3.264-3.204-9.828-7.021-22.567-7.021h-22.737
                            c-1.158,0.149-2.837,0.846-3,3.625c-0.229,3.816-0.699,8.851-4.478,12.72c-2.912,2.987-6.683,4.405-10.603,4.405H90.195
                            c-3.92,0-7.689-1.418-10.604-4.405c-3.774-3.869-4.246-8.904-4.473-12.72c-0.166-2.779-1.846-3.476-3.002-3.625H48.78
                            c-12.735,0-19.295,3.818-22.558,7.021c-7.668,7.529-9.035,14.719-9.035,31.199v492.036c0,16.477,1.367,23.669,9.035,31.196
                            c3.263,3.203,9.828,7.021,22.566,7.019h199.345c12.736,0,19.301-3.814,22.561-7.019c7.67-7.527,9.033-14.721,9.033-31.196
                            V55.041C279.729,38.561,278.364,31.371,270.693,23.842"/>
                    </g>
                    <g>
                        <defs>
                            <path id="SVGID_4_" d="M270.693,23.842c-3.264-3.204-9.828-7.021-22.567-7.021h-22.737c-1.158,0.149-2.837,0.846-3,3.625
                                c-0.229,3.816-0.699,8.851-4.478,12.72c-2.912,2.987-6.683,4.405-10.603,4.405H90.195c-3.92,0-7.689-1.418-10.604-4.405
                                c-3.774-3.869-4.246-8.904-4.473-12.72c-0.166-2.779-1.846-3.476-3.002-3.625H48.78c-12.735,0-19.295,3.818-22.558,7.021
                                c-7.668,7.529-9.035,14.719-9.035,31.199v492.036c0,16.477,1.367,23.669,9.035,31.196c3.263,3.203,9.828,7.021,22.566,7.019
                                h199.345c12.736,0,19.301-3.814,22.561-7.019c7.67-7.527,9.033-14.721,9.033-31.196V55.041
                                C279.729,38.561,278.364,31.371,270.693,23.842"/>
                        </defs>
                        <clipPath id="SVGID_5_">
                            <use xlink:href="#SVGID_4_"  overflow="visible"/>
                        </clipPath>
                        <defs>
                            <pattern id="img1" height="100%" width="100%"
                                patternContentUnits="objectBoundingBox">
                                <image height="1" width="1" preserveAspectRatio="none"
                                        attr.xlink:href="{{splashScreen}}" />
                            </pattern>
                          </defs>

                        <rect x="17.187" y="70" clip-path="url(#SVGID_5_)" fill="url(#img1)" width="262.54" height="415.396"/>
                        <rect x="42.75" *ngIf="contrat!=undefined"  y="527.463" clip-path="url(#SVGID_5_)" attr.fill="{{contrat.design.couleurPrincipale}}" width="8.156" height="8.155"/>
                        <rect x="56.968" y="527.463" clip-path="url(#SVGID_5_)" fill="#C6C6CB" width="8.156" height="8.155"/>
                        <rect x="71.269" y="527.463" clip-path="url(#SVGID_5_)" fill="#C6C6CB" width="8.156" height="8.155"/>
                        <g clip-path="url(#SVGID_5_)">
                            <path *ngIf="contrat!=undefined"  attr.fill="{{contrat.design.couleurPrincipale}}" d="M204.256,533.663c0.499,0.262,1.297,0.523,2.107,0.523c0.873,0,1.334-0.362,1.334-0.91
                                c0-0.523-0.399-0.823-1.409-1.172c-1.396-0.499-2.318-1.271-2.318-2.494c0-1.434,1.209-2.518,3.179-2.518
                                c0.96,0,1.646,0.188,2.145,0.424l-0.424,1.521c-0.324-0.161-0.936-0.398-1.746-0.398c-0.822,0-1.221,0.386-1.221,0.81
                                c0,0.537,0.461,0.773,1.558,1.185c1.483,0.549,2.169,1.321,2.169,2.506c0,1.409-1.072,2.605-3.378,2.605
                                c-0.96,0-1.907-0.262-2.382-0.523L204.256,533.663z"/>
                            <path  *ngIf="contrat!=undefined"  attr.fill="{{contrat.design.couleurPrincipale}}" d="M216.609,533.663c0,0.797,0.025,1.445,0.051,1.957h-1.646l-0.086-0.861h-0.038
                                c-0.237,0.375-0.811,0.998-1.907,0.998c-1.246,0-2.145-0.773-2.145-2.655v-3.578h1.895v3.278c0,0.886,0.287,1.422,0.961,1.422
                                c0.523,0,0.835-0.361,0.947-0.661c0.05-0.112,0.074-0.249,0.074-0.411v-3.628h1.895V533.663z"/>
                            <path   *ngIf="contrat!=undefined"  attr.fill="{{contrat.design.couleurPrincipale}}" d="M220.088,527.828c0,0.523-0.387,0.947-1.023,0.947c-0.598,0-0.984-0.424-0.984-0.947
                                c0-0.536,0.398-0.947,1.01-0.947S220.074,527.292,220.088,527.828z M218.13,535.62v-6.097h1.896v6.097H218.13z"/>
                            <path   *ngIf="contrat!=undefined"  attr.fill="{{contrat.design.couleurPrincipale}}" d="M222.954,529.523l0.823,2.83c0.149,0.523,0.249,0.997,0.349,1.483h0.038
                                c0.1-0.498,0.199-0.96,0.336-1.483l0.785-2.83h1.995l-2.269,6.097h-1.896l-2.219-6.097H222.954z"/>
                            <path  *ngIf="contrat!=undefined"  attr.fill="{{contrat.design.couleurPrincipale}}" d="M233.139,534.148c0,0.573,0.025,1.135,0.1,1.472h-1.707l-0.113-0.611h-0.037
                                c-0.398,0.486-1.021,0.748-1.745,0.748c-1.233,0-1.97-0.897-1.97-1.87c0-1.583,1.422-2.331,3.578-2.331v-0.074
                                c0-0.338-0.175-0.799-1.109-0.799c-0.623,0-1.284,0.213-1.684,0.461l-0.349-1.221c0.424-0.237,1.259-0.549,2.368-0.549
                                c2.033,0,2.668,1.197,2.668,2.643V534.148z M231.307,532.739c-0.998,0-1.771,0.237-1.771,0.961
                                c0,0.486,0.324,0.723,0.748,0.723c0.462,0,0.86-0.312,0.985-0.698c0.024-0.1,0.037-0.212,0.037-0.324V532.739z"/>
                            <path  *ngIf="contrat!=undefined"  attr.fill="{{contrat.design.couleurPrincipale}}" d="M234.646,531.468c0-0.76-0.025-1.396-0.05-1.944h1.646l0.088,0.848h0.037
                                c0.249-0.399,0.859-0.985,1.883-0.985c1.246,0,2.182,0.836,2.182,2.631v3.604h-1.896v-3.379c0-0.785-0.274-1.321-0.96-1.321
                                c-0.523,0-0.835,0.361-0.973,0.71c-0.049,0.125-0.062,0.3-0.062,0.475v3.516h-1.895V531.468z"/>
                            <path  *ngIf="contrat!=undefined"  attr.fill="{{contrat.design.couleurPrincipale}}" d="M243.996,527.878v1.646h1.359v1.396h-1.359v2.206c0,0.736,0.188,1.072,0.748,1.072
                                c0.262,0,0.387-0.012,0.561-0.05l0.013,1.434c-0.237,0.087-0.698,0.162-1.222,0.162c-0.611,0-1.122-0.212-1.434-0.523
                                c-0.35-0.361-0.523-0.947-0.523-1.808v-2.493h-0.811v-1.396h0.811v-1.134L243.996,527.878z"/>
                        </g>
                        <g id="Btn_Back_1_" clip-path="url(#SVGID_5_)">
                            <g>
                                <path fill-rule="evenodd"  *ngIf="contrat!=undefined" clip-rule="evenodd" attr.fill="{{contrat.design.couleurPrincipale}}" d="M259.175,531.294l-5.089-5.015
                                    c-0.107-0.106-0.284-0.104-0.393,0.001l-0.736,0.725c-0.108,0.108-0.11,0.281-0.002,0.388l4.21,4.147l-4.21,4.148
                                    c-0.107,0.106-0.105,0.28,0.002,0.387l0.736,0.726c0.109,0.107,0.283,0.11,0.393,0.002l5.089-5.015
                                    c0.066-0.066,0.092-0.159,0.073-0.247C259.268,531.455,259.244,531.36,259.175,531.294z"/>
                            </g>
                        </g>
                        <rect x="85.314" y="527.463" clip-path="url(#SVGID_5_)" fill="#C6C6CB" width="8.155" height="8.155"/>
                    </g>
                </g>
            </g>
        </switch>

        </svg>
            </div>
            <div class="col-lg-6 mt-0 pt-0 d-flex flex-row justify-content-center align-items-center">
                <svg-icon  *ngIf="contrat!=undefined"  src="../../../../../assets/img/013-chevron-left.svg" class="mr-4" [stretch]="true" (click)="slideLeft()"
                                        [svgStyle]="{'width.px':20, 'height.px':20 , 'fill': contrat.design.couleurPrincipale ,'stroke': contrat.design.couleurPrincipale}"></svg-icon>
                <svg version="1.1" id="Layer_1"  xmlns:x="&ns_extend;" xmlns:i="&ns_ai;" xmlns:graph="&ns_graphs;"
                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="287px" height="455px"
                viewBox="0 0 300 605" xml:space="preserve">

           <switch>
               <g i:extraneous="self">
                   <path fill="#B4B4B4" d="M299.896,207.294c0.062-0.188,0.104-0.385,0.104-0.594v-65.04c0-0.209-0.042-0.405-0.104-0.593
                       c-0.012-0.029-0.018-0.057-0.025-0.086c-0.07-0.179-0.164-0.343-0.277-0.493c-0.016-0.021-0.029-0.038-0.046-0.057
                       c-0.262-0.316-0.616-0.547-1.026-0.648c-0.146-0.034-0.297-0.059-0.455-0.059h-0.588v68.914h0.588
                       c0.599,0,1.127-0.278,1.481-0.705c0.017-0.02,0.03-0.038,0.046-0.057c0.113-0.149,0.207-0.313,0.277-0.494
                       C299.88,207.351,299.886,207.322,299.896,207.294"/>
                   <path fill="#222325" d="M186.079,23.776c0,2.538-2.056,4.597-4.591,4.597c-2.533,0-4.59-2.059-4.59-4.597
                       c0-2.537,2.057-4.595,4.59-4.595C184.023,19.181,186.079,21.239,186.079,23.776"/>
                   <path fill="#222325" d="M167.427,23.776c0,1.355-1.099,2.454-2.448,2.454h-32.102c-1.354,0-2.452-1.099-2.452-2.454
                       c0-1.354,1.098-2.452,2.452-2.452h32.102C166.328,21.324,167.427,22.422,167.427,23.776"/>
                   <path fill="#FFFFFF" d="M43.523,36.045c0.102,0.343,0.532,0.706,1.249,0.706c1.363,0,1.871-1.322,1.827-2.943H46.53
                       c-0.231,0.605-0.852,1.141-1.884,1.141c-1.352,0-2.462-0.931-2.462-2.568c0-1.644,1.179-2.625,2.725-2.625
                       c1.394,0,2.8,0.83,2.8,3.861c0,2.738-0.953,4.151-2.96,4.151c-1.475,0-2.271-0.846-2.368-1.722L43.523,36.045L43.523,36.045z
                        M44.844,30.771c-0.833,0-1.521,0.6-1.521,1.61c0,1.057,0.714,1.575,1.543,1.575c0.808,0,1.543-0.514,1.543-1.551
                       C46.409,31.364,45.734,30.771,44.844,30.771"/>
                   <path fill="#FFFFFF" d="M50.25,32.207c0.495,0,0.874,0.36,0.874,0.88c0,0.509-0.378,0.863-0.874,0.863
                       c-0.464,0-0.846-0.354-0.846-0.863C49.403,32.562,49.779,32.207,50.25,32.207 M50.25,35.989c0.495,0,0.874,0.356,0.874,0.88
                       c0,0.508-0.378,0.859-0.874,0.859c-0.464,0-0.846-0.351-0.846-0.859C49.403,36.345,49.779,35.989,50.25,35.989"/>
                   <path fill="#FFFFFF" d="M57.467,29.918v5.148h0.989v1.045h-0.989v1.514h-1.098v-1.514h-3.74V35.05
                       c0.948-1.715,2.068-3.42,3.139-5.131L57.467,29.918L57.467,29.918z M56.369,35.067v-4.143h-0.063
                       c-0.862,1.292-1.783,2.727-2.56,4.092v0.05L56.369,35.067L56.369,35.067z"/>
                   <polygon fill="#FFFFFF" points="61.624,37.625 61.624,31.184 61.567,31.184 59.793,32.455 59.793,31.257 61.624,29.918
                       62.771,29.918 62.771,37.625 		"/>
                   <path fill="#FFFFFF" d="M235.031,37.279c0,0.192-0.155,0.347-0.348,0.347h-1.043c-0.193,0-0.349-0.155-0.349-0.347V30.27
                       c0-0.191,0.153-0.347,0.349-0.347h1.043c0.191,0,0.348,0.155,0.348,0.347V37.279z"/>
                   <path fill="#FFFFFF" d="M231.854,37.279c0,0.192-0.156,0.347-0.349,0.347h-1.042c-0.193,0-0.348-0.155-0.348-0.347V32.15
                       c0-0.193,0.154-0.347,0.348-0.347h1.042c0.19,0,0.349,0.154,0.349,0.347V37.279z"/>
                   <path fill="#FFFFFF" d="M228.678,37.279c0,0.192-0.156,0.347-0.349,0.347h-1.043c-0.19,0-0.347-0.155-0.347-0.347v-3.562
                       c0-0.193,0.154-0.349,0.347-0.349h1.043c0.192,0,0.349,0.156,0.349,0.349V37.279z"/>
                   <path fill="#FFFFFF" d="M225.501,37.279c0,0.192-0.157,0.347-0.349,0.347h-1.045c-0.191,0-0.346-0.155-0.346-0.347v-2.311
                       c0-0.19,0.153-0.346,0.346-0.346h1.045c0.19,0,0.349,0.156,0.349,0.346V37.279z"/>
                   <path fill="#FFFFFF" d="M240.279,33.436c-0.471-0.461-0.928-0.906-1.383-1.353c2.838-2.896,7.814-3.006,10.799,0.004
                       c-0.449,0.453-0.899,0.907-1.363,1.376c-1.13-1.062-2.469-1.632-4.027-1.638C242.743,31.823,241.4,32.383,240.279,33.436"/>
                   <path fill="#FFFFFF" d="M242.26,35.45c-0.461-0.451-0.916-0.897-1.371-1.345c1.771-1.827,4.963-1.879,6.787-0.019
                       c-0.445,0.458-0.893,0.913-1.343,1.372C244.973,34.396,243.613,34.394,242.26,35.45"/>
                   <path fill="#FFFFFF" d="M245.627,36.14c-0.454,0.459-0.896,0.91-1.334,1.354c-0.45-0.449-0.896-0.895-1.343-1.341
                       C243.531,35.51,244.857,35.397,245.627,36.14"/>

                   <path fill="#FFFFFF" d="M267.344,36.091c0,0.192-0.154,0.349-0.348,0.349h-11.863c-0.191,0-0.35-0.156-0.35-0.349V31.46
                       c0-0.194,0.156-0.35,0.35-0.35h11.863c0.191,0,0.348,0.156,0.348,0.35V36.091z"/>
                   <path fill="#FFFFFF" d="M270.377,33.774c0,0.514-0.418,0.933-0.93,0.933v-1.865C269.959,32.842,270.377,33.259,270.377,33.774"/>
                   <path fill="#B4B4B4" d="M298.111,56.203c0-19.638-2.012-31.848-14.428-44.033C271.691,0.4,254.395,0,248.534,0H49.573
                       c-5.859,0-23.158,0.4-35.148,12.17C2.01,24.355,0,36.565,0,56.203V547.31c0,19.638,2.01,31.848,14.424,44.032
                       c11.99,11.77,29.29,12.171,35.148,12.168h198.961c5.859,0.003,23.158-0.398,35.148-12.168
                       c12.418-12.186,14.428-24.396,14.428-44.032V56.203H298.111z"/>
                   <path d="M49.573,601.064c-17.983,0-28.106-6.236-33.437-11.471C4.491,578.166,2.441,566.852,2.441,547.31V56.204
                       c0-19.545,2.049-30.857,13.696-42.287C21.466,8.682,31.594,2.448,49.561,2.448h198.973c17.981,0,28.108,6.234,33.438,11.469
                       c11.646,11.43,13.694,22.742,13.694,42.287V547.31c0,19.542-2.049,30.856-13.694,42.285c-5.33,5.233-15.453,11.47-33.394,11.47
                       H49.573L49.573,601.064z"/>
                   <text transform="matrix(1 0 0 1 145.7354 66.3037)" fill="#3E3D40" font-family="'DINBoldAlternate'" font-size="11.202"> </text>
                   <g>
                       <g>
                           <path id="SVGID_3_" fill="#FFFFFF" d="M270.693,23.842c-3.264-3.204-9.828-7.021-22.567-7.021h-22.737
                               c-1.158,0.149-2.837,0.846-3,3.625c-0.229,3.816-0.699,8.851-4.478,12.72c-2.912,2.987-6.683,4.405-10.603,4.405H90.195
                               c-3.92,0-7.689-1.418-10.604-4.405c-3.774-3.869-4.246-8.904-4.473-12.72c-0.166-2.779-1.846-3.476-3.002-3.625H48.78
                               c-12.735,0-19.295,3.818-22.558,7.021c-7.668,7.529-9.035,14.719-9.035,31.199v492.036c0,16.477,1.367,23.669,9.035,31.196
                               c3.263,3.203,9.828,7.021,22.566,7.019h199.345c12.736,0,19.301-3.814,22.561-7.019c7.67-7.527,9.033-14.721,9.033-31.196
                               V55.041C279.729,38.561,278.364,31.371,270.693,23.842"/>
                       </g>
                       <g>
                           <defs>
                               <path id="SVGID_4_" d="M270.693,23.842c-3.264-3.204-9.828-7.021-22.567-7.021h-22.737c-1.158,0.149-2.837,0.846-3,3.625
                                   c-0.229,3.816-0.699,8.851-4.478,12.72c-2.912,2.987-6.683,4.405-10.603,4.405H90.195c-3.92,0-7.689-1.418-10.604-4.405
                                   c-3.774-3.869-4.246-8.904-4.473-12.72c-0.166-2.779-1.846-3.476-3.002-3.625H48.78c-12.735,0-19.295,3.818-22.558,7.021
                                   c-7.668,7.529-9.035,14.719-9.035,31.199v492.036c0,16.477,1.367,23.669,9.035,31.196c3.263,3.203,9.828,7.021,22.566,7.019
                                   h199.345c12.736,0,19.301-3.814,22.561-7.019c7.67-7.527,9.033-14.721,9.033-31.196V55.041
                                   C279.729,38.561,278.364,31.371,270.693,23.842"/>
                           </defs>
                           <clipPath id="SVGID_5_">
                               <use xlink:href="#SVGID_4_"  overflow="visible"/>
                           </clipPath>
                           <rect x="17.187" y="16.956" clip-path="url(#SVGID_5_)" fill="#FFFFFF" width="262.54" height="568.202"/>

                           <text x="30" *ngIf="current_slide!=undefined && contrat!=undefined"  id="titre" y="80" class="h1" attr.fill="{{contrat.design.couleurPrincipale}}">
                                <tspan  *ngFor="let element of current_slide.title" x="30" attr.fill="{{contrat.design.couleurPrincipale}}"  class="h1" dy ="20">{{element}}</tspan>
                           </text>

                           <text x="30" *ngIf="current_slide!=undefined && contrat!=undefined"  id="titre" attr.y="{{80 + (current_slide.title.length+1)*20}}" class="p3">
                            <tspan  *ngFor="let element of current_slide.description1" x="30" class="p3" dy ="20">{{element}}</tspan>
                           </text>

                           <image *ngIf="current_slide != undefined" attr.xlink:href="{{current_slide.image}}" x="30" attr.y="{{80 +(4)*20 + (3+1)*20}}" height="130px" width="220px"/>

                           <text x="30" *ngIf="current_slide!=undefined && contrat!=undefined"  id="titre" attr.y="{{80 + 150 +(4+1)*20 + (3+1)*20}}" class="p3">
                            <tspan  *ngFor="let element of current_slide.description2" x="30" class="p3" dy ="20">{{element}}</tspan>
                           </text>

                           <rect x="42.75" y="527.463"  *ngIf="contrat!=undefined" clip-path="url(#SVGID_5_)" attr.fill="{{contrat.design.couleurPrincipale}}" width="8.156" height="8.155"/>
                           <rect x="56.968" y="527.463" clip-path="url(#SVGID_5_)" fill="#C6C6CB" width="8.156" height="8.155"/>
                           <rect x="71.269" y="527.463" clip-path="url(#SVGID_5_)" fill="#C6C6CB" width="8.156" height="8.155"/>
                           <g clip-path="url(#SVGID_5_)">
                               <path *ngIf="contrat!=undefined" attr.fill="{{contrat.design.couleurPrincipale}}" d="M204.256,533.663c0.499,0.262,1.297,0.523,2.107,0.523c0.873,0,1.334-0.362,1.334-0.91
                                   c0-0.523-0.399-0.823-1.409-1.172c-1.396-0.499-2.318-1.271-2.318-2.494c0-1.434,1.209-2.518,3.179-2.518
                                   c0.96,0,1.646,0.188,2.145,0.424l-0.424,1.521c-0.324-0.161-0.936-0.398-1.746-0.398c-0.822,0-1.221,0.386-1.221,0.81
                                   c0,0.537,0.461,0.773,1.558,1.185c1.483,0.549,2.169,1.321,2.169,2.506c0,1.409-1.072,2.605-3.378,2.605
                                   c-0.96,0-1.907-0.262-2.382-0.523L204.256,533.663z"/>
                               <path  *ngIf="contrat!=undefined" attr.fill="{{contrat.design.couleurPrincipale}}" d="M216.609,533.663c0,0.797,0.025,1.445,0.051,1.957h-1.646l-0.086-0.861h-0.038
                                   c-0.237,0.375-0.811,0.998-1.907,0.998c-1.246,0-2.145-0.773-2.145-2.655v-3.578h1.895v3.278c0,0.886,0.287,1.422,0.961,1.422
                                   c0.523,0,0.835-0.361,0.947-0.661c0.05-0.112,0.074-0.249,0.074-0.411v-3.628h1.895V533.663z"/>
                               <path  *ngIf="contrat!=undefined" attr.fill="{{contrat.design.couleurPrincipale}}" d="M220.088,527.828c0,0.523-0.387,0.947-1.023,0.947c-0.598,0-0.984-0.424-0.984-0.947
                                   c0-0.536,0.398-0.947,1.01-0.947S220.074,527.292,220.088,527.828z M218.13,535.62v-6.097h1.896v6.097H218.13z"/>
                               <path  *ngIf="contrat!=undefined" attr.fill="{{contrat.design.couleurPrincipale}}" d="M222.954,529.523l0.823,2.83c0.149,0.523,0.249,0.997,0.349,1.483h0.038
                                   c0.1-0.498,0.199-0.96,0.336-1.483l0.785-2.83h1.995l-2.269,6.097h-1.896l-2.219-6.097H222.954z"/>
                               <path  *ngIf="contrat!=undefined" attr.fill="{{contrat.design.couleurPrincipale}}" d="M233.139,534.148c0,0.573,0.025,1.135,0.1,1.472h-1.707l-0.113-0.611h-0.037
                                   c-0.398,0.486-1.021,0.748-1.745,0.748c-1.233,0-1.97-0.897-1.97-1.87c0-1.583,1.422-2.331,3.578-2.331v-0.074
                                   c0-0.338-0.175-0.799-1.109-0.799c-0.623,0-1.284,0.213-1.684,0.461l-0.349-1.221c0.424-0.237,1.259-0.549,2.368-0.549
                                   c2.033,0,2.668,1.197,2.668,2.643V534.148z M231.307,532.739c-0.998,0-1.771,0.237-1.771,0.961
                                   c0,0.486,0.324,0.723,0.748,0.723c0.462,0,0.86-0.312,0.985-0.698c0.024-0.1,0.037-0.212,0.037-0.324V532.739z"/>
                               <path   *ngIf="contrat!=undefined" attr.fill="{{contrat.design.couleurPrincipale}}" d="M234.646,531.468c0-0.76-0.025-1.396-0.05-1.944h1.646l0.088,0.848h0.037
                                   c0.249-0.399,0.859-0.985,1.883-0.985c1.246,0,2.182,0.836,2.182,2.631v3.604h-1.896v-3.379c0-0.785-0.274-1.321-0.96-1.321
                                   c-0.523,0-0.835,0.361-0.973,0.71c-0.049,0.125-0.062,0.3-0.062,0.475v3.516h-1.895V531.468z"/>
                               <path  *ngIf="contrat!=undefined" attr.fill="{{contrat.design.couleurPrincipale}}" d="M243.996,527.878v1.646h1.359v1.396h-1.359v2.206c0,0.736,0.188,1.072,0.748,1.072
                                   c0.262,0,0.387-0.012,0.561-0.05l0.013,1.434c-0.237,0.087-0.698,0.162-1.222,0.162c-0.611,0-1.122-0.212-1.434-0.523
                                   c-0.35-0.361-0.523-0.947-0.523-1.808v-2.493h-0.811v-1.396h0.811v-1.134L243.996,527.878z"/>
                           </g>
                           <g id="Btn_Back_1_" clip-path="url(#SVGID_5_)">
                               <g>
                                   <path fill-rule="evenodd"  *ngIf="contrat!=undefined" clip-rule="evenodd" attr.fill="{{contrat.design.couleurPrincipale}}" d="M259.175,531.294l-5.089-5.015
                                       c-0.107-0.106-0.284-0.104-0.393,0.001l-0.736,0.725c-0.108,0.108-0.11,0.281-0.002,0.388l4.21,4.147l-4.21,4.148
                                       c-0.107,0.106-0.105,0.28,0.002,0.387l0.736,0.726c0.109,0.107,0.283,0.11,0.393,0.002l5.089-5.015
                                       c0.066-0.066,0.092-0.159,0.073-0.247C259.268,531.455,259.244,531.36,259.175,531.294z"/>
                               </g>
                           </g>
                           <rect x="85.314" y="527.463" clip-path="url(#SVGID_5_)" fill="#C6C6CB" width="8.155" height="8.155"/>
                       </g>
                   </g>
               </g>
           </switch>

           </svg>
           <svg-icon  *ngIf="contrat!=undefined" src="../../../../../assets/img/014-chevron-right.svg" class="ml-4" [stretch]="true"  (click)="slideRight()"
           [svgStyle]="{'width.px':20, 'height.px':20 , 'fill': contrat.design.couleurPrincipale ,'stroke': contrat.design.couleurPrincipale}"></svg-icon>

            </div>

        </div>
        <div  class="h4 row mt-3">
            <div class="col-lg-6   d-flex flex-row justify-content-end"></div>
            <div class="col-lg-6 h3 d-flex flex-column justify-content-center align-items-center">Slidez pour afficher les différents écrans<br/><span class="p2">(Images à titre d'illustration, images non</span><span class="p2">contractuelles)</span>
            </div>
        </div>
    </div>

</div>

<div class="row mt-5 mb-4">
    <div class="col-lg-3 d-flex flex-row justify-content-center">
        <button type="button"  class="btn  btn-primary w-100 button2" routerLink="/montri/contractsettings/services">Précédent</button>
    </div>
    <div class="col-lg-3 mt-3 mt-lg-0 d-flex flex-row justify-content-center">
        <button type="button" class="btn  btn-primary w-100  h3_heavy button4"  (click)="saveData()" ngClass="{{splashscreenError  ? 'opacity05' : ''}}">Suivant</button>
    </div>
</div>

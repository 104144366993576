import { Contrat } from './../../../../models/contrat/contrat';
import { ContratService } from './../../../../services/contrat/contrat.service';
import { UserService } from './../../../../services/user/user.service';
import { UserBo } from './../../../../models/user/user';
import { Flux, WasteFlux } from './../../../../models/flux/flux';
import { FluxService } from './../../../../services/flux/flux.service';
import { Component, OnInit } from '@angular/core';
import { TabmessageService } from 'src/app/services/tabmessage/tabmessage.service';
import { ToastAlertsComponent } from 'src/app/modules/main/components/toast-alerts/toast-alerts.component';

@Component({
  selector: 'app-flux',
  templateUrl: './flux.component.html',
  styleUrls: ['./flux.component.scss']
})

export class FluxComponent implements OnInit {

    flux : Flux[]
    contrat :  Contrat
    user : UserBo
    selected : number = null
    error_status : boolean =  false
    default_selected :  string = ''
    error_global : boolean = false
    constructor(
        private fluxservice  : FluxService,
        private userservice: UserService,
        private contratservice  : ContratService,
        private sectionservice: TabmessageService,
        private toastr : ToastAlertsComponent
    )
    { }

    ngOnInit(): void {
        window.scroll(0,0)
        this.sectionservice.setSection('flux');
        this.userservice.user_obs.subscribe((user)=>{
            this.user = user
        })

        this.contratservice.contrat.subscribe((contrat)=>{
            this.contrat = contrat
            this.contratservice.carte_obs.subscribe((data)=>{
                if(data.flux.length > 0){
                    this.flux = data.flux
                }else{
                    this.flux = this.contrat.carte
                }
            })
            //this.flux = this.contrat.carte
            for(let [i,service] of this.flux.entries()){
                this.flux[i].error = false
                this.flux[i].colorErrorMsg = ''
            }


        })
    }

    onChangeDisplayName(event, itemName){
        let displayName = event.target.value
        for(let item of this.flux){
            if(item.nom === itemName){
                item.displayName = displayName
            }
        }
    }

    changeDefaultColor(i : number){
          this.flux[i].defaut = true
    }

    async saveData(){

        var status = 0 ;
        for (let [i,element] of this.flux.entries()){
            if  (element.active && element.couleur == ''){
                this.flux[i].error = true
                this.flux[i].colorErrorMsg = 'Champs obligatoire'
                status = 1
            }
            else if  (element.active && !element.couleur.match('^#([A-Fa-f0-9]{6})$')){
                this.flux[i].couleur = ''
                this.flux[i].error = true
                this.flux[i].colorErrorMsg = 'Code couleur incorrect'
                status = 1
            }
        }

        this.error_global = ((this.flux.some(this.checkerror)) ||  ! (this.flux.some(this.checknext2)))

        if(this.error_global){
            this.error_status = true
            this.toastr.openFailedToast(`Certains champs ne sont pas remplis ou un flux par défaut n'a pas été sélectionné.`, "");
        }
        else{
            this.error_status = false
            this.contrat.configstatus[6].status = true
            //this.contrat.carte = this.flux
            let obj = {flux: this.flux}
            this.contratservice.setCarte(obj)
            let status = await this.contratservice.saveCarte('montri/welcome')
            if(status === 'success'){
                this.toastr.openSuccessToast('Données enregistrées avec succès', "")
            }else{
                this.toastr.openErrorToast(`Chargement échoué. Veuillez réessayer.`, "");
            }
            // this.contratservice.setContrat(this.contrat)
            //this.contratservice.saveContrat('montri/welcome')
        }
    }

    checkerror(a: Flux){
        return a.error == true
    }

    checknext(flux : Flux){
        return flux.active == true && flux.couleur == ""
    }

    checknext2(flux : Flux){
        return flux.defaut == true
    }

    error(){
        return ((this.flux.some(this.checkerror)) ||  ! (this.flux.some(this.checknext2)))
    }

    next(){
        return  ((this.flux.some(this.checknext)) && !(this.flux.some(this.checknext2)))
    }

    onChange(evt,i){
        if(evt.value==7){
            var color_div = document.getElementById('color'+i)
            color_div.style.backgroundColor = evt.value
            color_div.hidden = false
        }
        else{
            var color_div = document.getElementById('color'+i)
            color_div.hidden = true
        }
        var status_correct = 0 ;
        var  status_exist = 0;
        for (let element of this.flux){
            if  (element.active && element.couleur == ""){
                status_correct = 1
            }
        }

    }
    removeDefaut(i:number){
        this.flux[i].defaut = false
    }

    select(i:number){
    
        if(this.selected != null){
            this.flux[this.selected].defaut =  false
            this.selected = i
        }
        else{
            this.selected = i
        }
    }
    addfirst(i : number){
        if(this.flux[i].couleur==''){
            this.flux[i].couleur="#"
        }
    }
}

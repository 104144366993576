export class Notification {
    id : string
    idContrat : string
    image : string
    titre : string
    description : string
    url : string
    date : any
    type : string
    polygon : boolean;
    polygonPath : string[]
}

import { Alert } from './../../../../models/alert/alert';
import { AuthentificationService } from './../../../../services/authentification/authentification.service';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { AlertService } from './../../../../services/alert/alert.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LodingBarService } from './../../../../services/lodingbar/loding-bar-service.service';
import { UserService } from './../../../../services/user/user.service';
import { FormBuilder } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sendmail',
  templateUrl: './sendmail.component.html',
  styleUrls: ['./sendmail.component.scss']
})
export class SendmailComponent implements OnInit {

    SendMailForm;
    sendMailError : boolean = false
    error : boolean = false

    constructor(
        private formBuilder: FormBuilder,
        private userservice: UserService,
        private lodingBarService: LodingBarService,
        public activeModal: NgbActiveModal,
        private alertservices: AlertService,
        public afAuth: AngularFireAuth,
        private afs : AngularFirestore,
        private router: Router,
        private authservice : AuthentificationService
    ) { }

    ngOnInit(): void {
        this.SendMailForm = this.formBuilder.group({
            adress_mail: ""
        });
    }

    onSubmit(userData){
        var  self = this;
        if(userData['adress_mail']==""){
            this.sendMailError = true
        }
        else{
            self.lodingBarService.setStatus(true);
            this.authservice.resetPasswordInit(userData['adress_mail'])
            .then(
              () => {
                self.activeModal.close()
                var alert: Alert = {
                    type: "success",
                    message: " ✔️ Un e-mail de réinitialisation de mon mot de passe a été envoyé à l'adresse e-mail renseigné",
                };
                self.alertservices.setAlert(alert);
                self.lodingBarService.setStatus(false);
              },
              (rejectionReason) => {
                self.activeModal.close()
                var alert: Alert = {
                    type: "danger",
                    message: " ❗ Cette  adresse e-mail est invalide, veuillez réssayer",
                };
                self.alertservices.setAlert(alert);
                self.lodingBarService.setStatus(false);
              })
            .catch(e =>{
                self.activeModal.close()
                var alert: Alert = {
                    type: "danger",
                    message: " ❗ L'envoie de mail de réinitialisation échouée, veuillez réssayer",
                };
                self.alertservices.setAlert(alert);
                self.lodingBarService.setStatus(false);
            });

        }
    }

}

<i class="fa fa-arrow-left mt-5 ml-5" id='retour' (click)="navigateBack()" aria-hidden="true" >
<label style="font-weight: bold;font-size: 16px;height: 30px;font-family: avenir_light;margin-left: 6px;" for="retour">Retour</label></i>
<div class="recherche_p">
  <form class="form-inline my-2 my-lg-0">
    <div class="recherche-barr" style="position: relative;margin-left: 3%;z-index: 10;">
      <input class="recherche-input" style="padding: 1px 1px;" [ngModel]="inputSearch" name="inputSearch" autocomplete="off"  type="search" (keyup)="onChange($event)" placeholder="Rechercher (ID, type, mots clé etc.)" aria-label="Search">
      <i class="fa fa-search" id="recherche-icone" style="color: #F47000;"></i>
      <i *ngIf="inputSearch != ''" class="fa fa-close" (click)="removeSearch()" id="recherche-cross" style="color: #F47000;"></i>
    </div>
  </form>
</div>
<div style="position: relative;margin-left: 56%;margin-top: -4%;">
  <div class="row mt-3" >
    <div class="col-lg-4 d-flex flex-column  justify-content-center">
    </div>
    <div class="row " style="margin-left: auto;justify-content: center;margin-top: -50px;margin-bottom: 40px; margin-right: 20px;">
      <div class="col-xl-2">
      </div>
      
      <div class="col-xl-4 d-flex flex-row justify-content-xl-end justify-content-center mt-xl-0">
          <div class="btn-group btn-group-toggle" data-toggle="buttons">
              <label class="" [className]="dateFilter == 'DAY' ? 'btn btn-outline-secondary btn_select_time p2 active' : 'btn btn-outline-secondary btn_select_time p2'" (click)="DayStats()">
                  <input type="radio" name="options" id="option1" autocomplete="off" checked> Jour
              </label>
              <label [className]="dateFilter == 'MONTH' ? 'btn btn-outline-secondary btn_select_time p2 active' : 'btn btn-outline-secondary btn_select_time p2'" (click)="MonthStats()">
                  <input type="radio" name="options" id="option2" autocomplete="off"> Mois
              </label>
              <label [className]="dateFilter == 'YEAR' ? 'btn btn-outline-secondary btn_select_time p2 active' : 'btn btn-outline-secondary btn_select_time p2'" (click)="YearStats()">
                  <input type="radio" name="options" id="option3" autocomplete="off"> Année
              </label>
          </div>
      </div>
      <form class="form-inline" style="width: 60%;margin-top: 18px;justify-content: center;">
        <div class="form-group hidden">
          <div class="input-group" style="width: 50%;">
            <input name="datepicker" style="width: 100%; " type="hidden"
                   class="form-control p2 input1"
                   ngbDatepicker
                   #datepicker="ngbDatepicker"
                   [autoClose]="'outside'"
                   (dateSelect)="onDateSelection($event)"
                   [displayMonths]="1"
                   [dayTemplate]="t"
                   outsideDays="hidden"
                   [startDate]="fromDate!"
                   tabindex="-1">
            <ng-template #t let-date let-focused="focused" style="width: 50%;">
              <span class="custom-day"
                    [class.focused]="focused"
                    [class.range]="isRange(date)"
                    [class.faded]="isHovered(date) || isInside(date)"
                    (mouseenter)="hoveredDate = date"
                    (mouseleave)="hoveredDate = null">
                {{ date.day }}
              </span>
            </ng-template>
          </div>
        </div>
        <div class="form-group">
          <div class="input">
            <div class="input-group-append">
              <button [ngClass]="dateFilter == 'RANGE' ? 'btn btn-outline-secondary btn_select_time p2 active' : 'btn btn-outline-secondary btn_select_time p2'" (click)="datepicker.toggle();onClickButtonRange()" type="button">
                <i class="fa fa-calendar" style="margin-right: 4px;" aria-hidden="true" id="calendar"></i>
                <label for="calendar" style="display: unset;">  Période personnalisée</label>
              </button>
            </div>
          </div>
        </div>
      </form>
  </div>
</div>
</div>
<div class="row  ml-5 mr-5 mb-5 d-flex flex-column justify-content-start align-items-start bg-white pt-3" style="margin-top: -18px;border-top-left-radius: 20px;border-top-right-radius: 20px;">
    <div class="container">
      <div class="row">
        <div class="col ml-2 mb-3">
          <p class="pb-0 mb-0 h3">Liste des demandes et signalements</p>
        </div>
        <div class="col-5 ml-2 mb-3" style="position: relative;right: -10%;">
        
          <button class="btn btn-danger mr-4" id="refresh" (click)="refreshReporting()" style="background-color:#EF627A " id="buttonList" type="button">
            <i class="fa fa-filter" style="color: white;" aria-hidden="true"></i>
            <label class="not-selectable" style="margin-left: 10px; margin-bottom: auto;color: white;">Supprimer les filtres</label>
          </button>


          <button class="btn btn-success" (click)="exportListXls()"  type="button" id="buttonList" style="background-color:#5EC394 ">
            <i class="fa fa-download" style="color: white;" aria-hidden="true"></i>
            <label class="not-selectable"  style="margin-left: 10px; margin-bottom: auto;color: white;">Exporter (.xls)</label>
          </button> 
        </div>
      </div>
    </div>
  
    <table class="table table-hover table-responsive-xl">
        <thead>
          <tr>
            <th scope="col"> 
                <div class="d-flex justify-content-center h3">
                </div>
            </th>
            <th scope="col">
                 <div class="d-flex justify-content-center h3" style="align-items: center;">
                     <label class="form-check-label" for="filtreStatut">
                      Statut
                    </label>
                     <ng-template #popStatut>
                      <ng-container *ngFor='let item of checksStatus | keyvalue'>
                       <input class="form-check-input" type="checkbox" value="" (click)='filterList("status", item.key)' id="{{item.key}}" [checked]="item.value">
                      <label class="form-check-label" for="{{item.key}}">
                        {{item.key}}
                      </label><br>
                      </ng-container>
                    </ng-template>
                      <button *ngIf="!stateFilter"
                        type="button" [disabled]="stateFilter" class="btn btn-outline-secondary" popoverTitle="Filtres" id="filtreStatut"
                        [autoClose]="'outside'" popoverClass="popStatut" [ngbPopover]="popStatut" style="font-size: 1px;
                        margin-left: 8px;padding: 4px 5px;height: 20px;" >
                        <i class="fa fa-filter" aria-hidden="true" [style.color]="getColor('status')" style="font-size: 10px;"></i>
                      </button>
                 </div>
                </th>
                <th scope="col">
                  <div class="d-flex justify-content-center h3" (click)='filterList("id", "")'>
                    ID
                    <i [ngClass]='classID' aria-hidden="true" style="color: black; font-size: 1em; margin-left: 5px;margin-top: 2px;"></i>
                  </div>
                </th>
            <th scope="col"> 
                <div class="d-flex justify-content-center  h3" style="align-items: center;">
                <label class="form-check-label" for="filtreSujet">
                  Sujet
                </label>
                <ng-template #popSujet>
                  <ng-container *ngFor='let item of checks | keyvalue'>
                      <input class="form-check-input" [disabled]="stateFilter" type="checkbox" value="" (click)='filterList("sujet", item.key)' id="{{item.key}}" [checked]="item.value" >
                      <label class="form-check-label" for="{{item.key}}">
                        {{item.key}}
                      </label><br>
                  </ng-container>
               </ng-template>
                 <button  *ngIf="!stateFilter"
                   type="button" class="btn btn-outline-secondary" [disabled]="stateFilter"  popoverTitle="Filtres" id="filtreSujet"
                   [autoClose]="'outside'" popoverClass="popSujet" [ngbPopover]="popSujet" style="font-size: 1px;
                   margin-left: 8px;padding: 4px 5px;height: 20px">
                   <i class="fa fa-filter" aria-hidden="true" [style.color]="getColor('sujet')" style="font-size: 10px;"></i>
                 </button>
                </div>
            </th>

            <th scope="col" *ngFor='let item of checksMotifsOrder'> 
              <div class="d-flex justify-content-center h3" style="align-items: center;">
                <label class="form-check-label" for="filtreMotif">
                  {{item}}
                </label>
                <ng-template #popMotif>
                  <ng-container *ngFor='let option of checksMotifs[item] | keyvalue'>
                      <input class="form-check-input" type="checkbox" [disabled]="stateFilter"  value="" (click)='filterList("motif", item, option.key)' id="{{option.key}}" [checked]="option.value" >
                      <label class="form-check-label" for="{{option.key}}">
                        {{option.key}}
                      </label><br>
                  </ng-container>
                </ng-template>
                <button  *ngIf="!stateFilter"
                   type="button" class="btn btn-outline-secondary" [disabled]="stateFilter"  popoverTitle="Filtres" id="filtreMotif"
                   [autoClose]="'outside'" popoverClass="popMotif" [ngbPopover]="popMotif" style="font-size: 1px;
                   margin-left: 8px;padding: 4px 5px;height: 20px">
                   <i class="fa fa-filter" aria-hidden="true" [style.color]="getColor('motif', item.key)" style="font-size: 10px;"></i>
                </button>
              </div>
            </th>


            <th scope="col"><div class="d-flex justify-content-center h3" (click)='filterList("demandeur", "")'>
                Demandeur
                <i [ngClass]='classDemandeur' aria-hidden="true" style="color: black; font-size: 1em; margin-left: 5px;margin-top: 2px;"></i>
            </div>
        </th>
            <th scope="col">
                <div class="d-flex justify-content-center h3" (click)='filterList("date", "")'>
                    Date
                    <i [ngClass]='classDate' aria-hidden="true" style="color: black; font-size: 1em; margin-left: 5px;margin-top: 2px;"></i>
                </div>
            </th>
          </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of reporting; index as i" (click)="updateReport(item)">
                <td style="max-width: 200px;" >
                    <div class="d-flex flex-row justify-content-center">
                        <img src="../../../../../assets/img/reportingstats/pastille-maj.svg" style="width: 15px; height: 15px;" *ngIf="item.majBO">
                    </div>
                </td>
                <td  style="max-width: 200px;">
                    <div class="d-flex flex-row justify-content-center">
                        <img src="../../../../../assets/img/reportingstats/084-statut-todo.png" style="width: 30px; height: 30px;" *ngIf="item.status=='a_traiter'">
                        <img src="../../../../../assets/img/reportingstats/082-statut-ok.png" style="width: 30px; height: 30px;" *ngIf="item.status=='traiter'">
                        <img src="../../../../../assets/img/reportingstats/083-statut-ongoing.png" style="width: 30px; height: 30px;" *ngIf="item.status=='en_cours'">
                        <img src="../../../../../assets/img/reportingstats/iconboautres.png" style="width: 30px; height: 30px;" *ngIf="item.status=='archiver'">
                    </div>
                </td>
                <td style="max-width: 200px;">
                  <div class="d-flex flex-column justify-content-center">
                      <p class="d-inline-block text-truncate pb-0 mb-0  h3">{{item.id}}</p>
                  </div>
                </td>

                <td style="max-width: 200px;">
                    <div class="d-flex flex-column justify-content-center">
                        <p class="d-inline-block text-truncate pb-0 mb-0  h3">{{capitalize(item.type.toLocaleLowerCase())}}</p>
                        <p class="d-inline-block text-truncate pb-0 mb-0 mt-0 p2">{{lenCheck(getValue(item.formulaires, 'Message'))}}</p>
                    </div>
                </td>

                <td style="max-width: 100px;" *ngFor='let motif of checksMotifsOrder' >
                  <div class="d-flex flex-column align-items-center justify-content-center h-100">
                      <p class="d-inline-block text-truncate pb-0 mb-0 mt-0 p2" style="max-width: 140px;">{{capitalize(getMotif(item, motif))}}</p>
                  </div>
                </td>

                <td style="max-width: 100px;" >
                    <div class="d-flex flex-column align-items-center justify-content-center h-100">
                        <p class="d-inline-block text-truncate pb-0 mb-0 mt-0 p2" style="max-width: 150px;"> {{capitalize(getValue(item.coordonnees, 'Nom'))}}{{capitalize(getValue(item.coordonnees, 'Prénom'))}}</p>
                    </div>
                </td>

                <td style="max-width: 200px;" >
                    <div class="d-flex flex-row justify-content-center">
                        <div><p style="font-size: 14px">{{formatDateTime(item)}}</p></div>
                    </div>
                </td>
            </tr>

        </tbody>
    </table>
    <div class="w-100 d-flex justify-content-center" *ngIf="reporting != undefined && reporting.length>0">
        <div class="d-flex justify-content-center p-2">

            <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize" (pageChange)="updateReportingList()">
            </ngb-pagination>

            <select class="custom-select ml-3" style="width: auto;box-shadow: none;border: 1px solid #F47000; color: black;" [(ngModel)]="pageSize" (ngModelChange)="updateReportingList()">
            <option [ngValue]="25">25 signalements par page</option>
            <option [ngValue]="50">50 signalements par page</option>
            <option [ngValue]="100">100 signalements par page</option>
            </select>
            
        </div>
        
    </div>
</div>

import { Contrat } from '../../models/contrat/contrat';

export const CONTRACTCONST: Contrat = {
    "contratstatus": 'en_modification',
    "firstActus": true,
    "configstatus": [

    ],
    "calendrier":{},
    "carte":[],
    "services": [

    ],
    "reseauxSociaux": {
        "lienFacebook": "",
        "lienTwitter": "",
        "lienYoutube": "",
        "lienInstagram": ""
    },
    "contrat": {
        "nomContrat": "",
        "siteContrat": "",
        "reglesTri": "2",
        "cguContrat": "",
        "idContrat": "BOTEST",
        "collectePolygon": false,
        "webApp" : true,
        "mobileApp" : true

    },
    "dashboard": {
        "points": 0,
        "titre": "",
        "description": "",
        "image": ""
    },
    "villesListe": [],
    "kmVoiture": 3.4,
    "Web": {
        "soustitreCover": "Retrouvez l'ensemble de nos services pour faciliter la gestion quotidienne de vos déchets au quotidien.",
        "imageCover": "",
        "logoSite": "",
        "Home_tabs": [
            {
                "title": "Accédez à vos services en quelques clics",
                "image": "001-Image-webapp.png",
                "text": "Montri est également disponible en verison mobile ! Accédez à tout moment et oû que vous soyez à l'ensemble de nos services."
            },
            {
                "title": "Triez sans erreur",
                "image": "002-Image-webapp.png",
                "text": "Scannez les code-barres de vos produits ou utiliser le moteur de recherche pour savoir comment bien les trier en fonction des règles de tri et des initiatives \"0 déchets\" locales."
            },
            {
                "title": "Le tri solidaire",
                "image": "003-Image-webapp.png",
                "text": "Parce que votre geste compte, nous le récompensons en le valorisant pour une cause solidaire.\n Tous les 100 emballages scannés, 1 repas est offert à la Croix Rouge !\n \n Merci pour votre tri !"
            }
        ],
        "LogoUrl": "",
        "titreCover": ""
    },
    "design": {
        "couleurPrincipale": "#005194",
        "couleurSecondaire": "#000000",
        "logoAppli": "logoapp.png",
        "logoSite": "logosite.png",
        "flux": [

          ]

    },
    "stats": {
        "points": 0,
        "kmVoiture": 0
    },
    "guideDutri": [],
    "points": 0,
    "contact": {
        "nomContact": "",
        "mailContact": "",
        "nomVoie": "",
        "horairesSupport": "",
        "messageContact": "",
        "ville": "",
        "cp": "",
        "telephoneContact": "",
        "objets": [],
        "prixAppel": ""
    }

};


export class ListActus {
    actualites :  Actu[] ;
    constructor({actualites = []}){
        this.actualites = actualites
    }
}

export class Actu {
    date : firebase.firestore.Timestamp
    dateDebut : firebase.firestore.Timestamp
    dateFin : firebase.firestore.Timestamp
    description : string ;
    epingler : boolean;
    image : string ;
    titre : string;
    url : string;
}

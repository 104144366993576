import { Component, OnInit, ViewChildren } from '@angular/core';
import {ToastrService, GlobalConfig, ToastContainerDirective, ComponentType} from 'ngx-toastr'
import { SuccessToast } from '../../../actussettings/modals/custom-toast/success';
import { TransferToast } from '../../../actussettings/modals/custom-toast/transfer';
import { ErrorToast } from 'src/app/modules/actussettings/modals/custom-toast/error';
import { FailedToast } from 'src/app/modules/actussettings/modals/custom-toast/failed';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-toast-alerts',
  templateUrl: './toast-alerts.component.html',
  styleUrls: ['./toast-alerts.component.scss']
})
export class ToastAlertsComponent implements OnInit {

  constructor(
    private toastr: ToastrService
  ) { 
    this.options = this.toastr.toastrConfig;
  }
  @ViewChildren(ToastContainerDirective)
  options: GlobalConfig;
  message = ''
  title = ''
  private lastInserted: number[] = [];

  ngOnInit(): void {
  }

async baseToast(message: string, title: string, type: ComponentType<any>, timeOut: boolean, closeButton: boolean){
  const opt = cloneDeep(this.options);
  opt.toastComponent = type;
  opt.toastClass = 'toast';
  opt.disableTimeOut = timeOut
  opt.closeButton = closeButton
  const inserted = this.toastr.show(message, title, opt);
  if (inserted && inserted.toastId) {
    this.lastInserted.push(inserted.toastId);
  }
  return inserted;
}

  openSuccessToast(message: string, title: string) {
    this.baseToast(message, title, SuccessToast, false, true)
  }


  async openTransferToast(message: string, title: string) {
    let ref = await this.baseToast(message, title, TransferToast, true, false)
    return ref
  }

  openErrorToast(message: string, title: string) {
    this.baseToast(message, title, ErrorToast, false, true)
  }

  openFailedToast(message: string, title: string) {
    this.baseToast(message, title, FailedToast, false, true)
  }

  clear(toastId: number){
    this.toastr.clear(toastId)
  }
}

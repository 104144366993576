import { Calendrier } from './../calendrier/calendrier';
import { Flux, Ville } from './../flux/flux';
import { Document } from '../document/document';
import { stat } from 'fs';
import { app } from 'firebase';
import { Optional } from '@angular/core';
import { ArrayType } from '@angular/compiler';



export class Contrat {
    services:       Service[];
    reseauxSociaux: ReseauxSociaux;
    contrat:        ContratClass;
    dashboard:      Dashboard;
    villesListe:    string[];
    //notificationPolygon: any[]
    kmVoiture:      number;
    Web:            WebContrat;
    design:         Design;
    stats:          Stats;
    guideDutri:     GuideDutri[];
    points:         number;
    contact:        Contact;
    configstatus?:  config[];
    firstActus?:    boolean;
    carte :         Flux[];
    calendrier?:    Calendrier;
    localfiles?:    LocalFiles;
    contratstatus?: string;
    badges? :       Badge[];
    listeCodes? :   string[];
    validateur?:    string
    parrainage?:    ParrainageContrat
    maxDateEnvoi?:  number
    interface? :    DesignInterface

    constructor({services = [] , reseauxSociaux = {} , contrat = {} , dashboard = {} , villesListe = [], kmVoiture = 0,Web = {},design = {} , stats = {} ,
                 guideDutri = [],points= 0 , contact = {},configstatus = [],firstActus = false , carte = [],calendrier = {} ,localfiles = {} ,contratstatus = "en_modification",badges = [], listeCodes = [], parrainage = null}){
        this.services = services.map(serv => new Service(serv))
        this.reseauxSociaux = new ReseauxSociaux(reseauxSociaux)
        this.contrat = new ContratClass(contrat)
        this.dashboard = new  Dashboard(dashboard)
        this.villesListe = villesListe
        this.kmVoiture = kmVoiture
        this.Web = new WebContrat(Web)
        this.design = new Design(design)
        this.stats = new Stats(stats)
        this.guideDutri = guideDutri
        this.points = points
        this.contact = new Contact(contact)
        this.configstatus = configstatus
        this.firstActus = firstActus
        this.carte = carte
        this.calendrier = new Calendrier(calendrier)
        this.localfiles = new LocalFiles(localfiles)
        this.contratstatus = contratstatus
        this.badges = badges
        if (listeCodes.length > 0) {
            this.listeCodes = listeCodes
        }
        if (parrainage) {
            this.parrainage = new ParrainageContrat(parrainage)
        }
    }
}


export class DesignInterface {
    designWeb? : DesignWeb
    designMobile? : DesignMobile
    
}

export class DesignWeb {
    titreCover? : string
    soustitreCover? : string
    LogoUrl?: string
    imageCover?: string
    logoSite?: string
    Home_tabs? : HomeTabs[]
}

export class DesignMobile {
    dashboardAccueil? : DashboardAccueil[]
    charte? : Charte
    slider?: Slider[]
    titreSlider?: string
}

export class DashboardAccueil {
    id?:string 
    idContrat?:string
    type?: string
    titre?: string
    sousTitre?: string
    image?: string
    ordre?: number
    active?: boolean
    opacite?: number 
    description?: string
    legende?: string
    stats?: string
    couleurTexte?: string
    pointMediane?: string
    opaciteImage?: number
    sensDegrade?: string
    url?: string
    date?: any
    polygon?: boolean
    polygonPath?: string[]
}

export class Charte {
    logoApp?: string
    couleurPrincipale? : string
    couleurtexteBoutons1? : string
    couleurTexte?: string
}

export class Slider {
    service?: string
    couleur?: string
    display?: boolean
    image?: string
    description?: string
    type?: string
    ordre?: number
    active?: boolean
    titre?: string
}

export class HomeTabs {
    text?: string
    title?: string
    image?: string
}

export class Contact {
    nomContact:       string;
    mailContact:      string;
    nomVoie:          string;
    horairesSupport:  string;
    messageContact:   string;
    ville:            string;
    cp:               string;
    telephoneContact: string;
    objets:           string[];
    prixAppel:        string;

    constructor({
        nomContact = ""       ,
        mailContact = "",
        nomVoie = "",
        horairesSupport = "",
        messageContact = "",
        ville = "",
        cp = "",
        telephoneContact = "",
        objets = [],
        prixAppel = ""
    }) {
        this.nomContact = nomContact
        this.mailContact = mailContact
        this.nomVoie = nomVoie
        this.horairesSupport = horairesSupport
        this.messageContact = messageContact
        this.ville = ville
        this.cp = cp
        this.telephoneContact = telephoneContact
        this.objets = objets
        this.prixAppel = prixAppel
    }

}

export class ContratClass {
    nomContrat:      string;
    siteContrat:     string;
    reglesTri:       string;
    cguContrat:      string;
    idContrat:       string;
    collectePolygon: boolean;
    webApp: boolean;
    mobileApp?: boolean;
    appLive ? : boolean;
    coordonne? : Coordonne
    partner ?:  string
    nutrition?: boolean

    constructor({
        nomContrat="",
        siteContrat="",
        reglesTri="",
        cguContrat="",
        idContrat="",
        collectePolygon=false,
        webApp=false,
        mobileApp=false,
        appLive = true,
        coordonne= {},
        partner="",
        nutrition = false
    }) {
        this.nomContrat = nomContrat
        this.siteContrat = siteContrat
        this.reglesTri = reglesTri
        this.cguContrat = cguContrat
        this.idContrat = idContrat
        this.collectePolygon = collectePolygon
        this.webApp = webApp
        this.appLive = appLive
        this.mobileApp = mobileApp
        this.coordonne = new Coordonne(coordonne)
        this.partner = partner
        this.nutrition = nutrition
    }
}

export class Coordonne{
	latitude: number
    longitude: number

    constructor({
        latitude = 0,
        longitude = 0
    }) {
        this.latitude = latitude
        this.longitude = longitude
    }

}

export class Design {
    flux :DesignFlux[];
    couleurPrincipale : string;
    couleurSecondaire : string;
    logoAppli : string;
    logoSite : string;

    constructor({
        flux = [],
        couleurPrincipale = "",
        couleurSecondaire = "",
        logoAppli = "",
        logoSite = ""
    }) {
        this.flux = flux
        this.couleurPrincipale = couleurPrincipale
        this.couleurSecondaire = couleurSecondaire
        this.logoAppli = logoAppli
        this.logoSite = logoSite
    }



}

export class DesignFlux{

    nom : string;
    displayName : string
    couleur : string;
    image : string;
    active : boolean;
    error? : boolean;
    colorErrorMsg? : string
    showInCalendar : boolean

    constructor({nom = '' , couleur = '' ,image = '' ,  active = true , error = false, colorErrorMsg  = '', showInCalendar = true}){
        this.nom = nom
        this.couleur = couleur
        this.image = image
        this.active = active
        this.error = error
        this.colorErrorMsg = colorErrorMsg
        this.showInCalendar = showInCalendar
    }
}


export class Service {
    villes?:           villeServie[];
    ordreSlider?:      number;
    image?:            string;
    titre?:            string;
    webColor:          string;
    description2?:     string;
    web:               boolean;
    active:            boolean;
    description1?:     string;
    nom:               string;
    code:              number;
    slider:            boolean;
    pointsSolidaires?: boolean;
    ongPartenaire?:    string;
    generales?:        boolean;
    documents:         Document[];
    encombrants?:      boolean;
    dechetteries?:     boolean;
    sousServices?:     subservice[];
    flux?:             FluxClass[];
    fluxDefaut?:       string;
    dashboard?:        Dashboard;
    valeur?:           number;
    fichier?:          string;
    servicedefaut?:    boolean;
    partout :          boolean;
    maintenance?:      boolean
    showScanappli?:    boolean
    showCrowdsourcing?:boolean
    historique?:       boolean
    confirmation?:     boolean

    constructor({
        villes = [],
        ordreSlider  =  undefined,
        image = "" ,
        titre  = "" ,
        webColor = "",
        description2 = "",
        web = false,
        active =  false,
        description1 = "",
        nom = "",
        code =  undefined,
        slider = false,
        pointsSolidaires = false,
        ongPartenaire =   "",
        generales  = false,
        documents = [],
        encombrants = false,
        dechetteries = false,
        sousServices = [],
        flux = [],
        fluxDefaut ="",
        dashboard = {},
        valeur = undefined,
        fichier = "",
        servicedefaut = false,
        partout  = true,
        maintenance = false,
        showScanappli = null,
        showCrowdsourcing = null,
        historique = null,
        confirmation = null
    }) {
        this.villes = villes
        this.ordreSlider = ordreSlider
        this.image = image
        this.titre = titre
        this.webColor = webColor
        this.description2 = description2
        this.web = web
        this.active = active
        this.description1 = description1
        this.nom = nom
        this.code = code
        this.slider = slider
        this.pointsSolidaires = pointsSolidaires
        this.ongPartenaire = ongPartenaire
        this.generales = generales
        this.documents = documents
        this.encombrants = encombrants
        this.dechetteries = dechetteries
        this.sousServices = sousServices
        this.flux = flux
        this.fluxDefaut = fluxDefaut
        this.dashboard = new Dashboard(dashboard)
        this.valeur = valeur
        this.fichier = fichier
        this.servicedefaut = servicedefaut
        this.partout = partout
        this.maintenance = maintenance
        if (showScanappli != null) {
            this.showScanappli = showScanappli
        }
        if (showCrowdsourcing != null) {
            this.showCrowdsourcing = showCrowdsourcing
        }
        this.historique = historique
        this.confirmation = confirmation
    }
}

export class ChampsFormulaire {
    nom?: string
    format?: string
    obligatoire?: string
    preview?: string
}

export class Formulaire {
    titre?: string
    sousTitre?: string
    champs? : [ChampsFormulaire]
}

export class subservice{
    nom : string;
    active : boolean
    email : string
    url : string
    titreMotif : string
    motif : Object
    error : boolean
    emailMsgError : string
    urlMsgError : string
    description : string
    titre : string
    image : string
    colorBo : string
    iconBo : string
    formulaires : Formulaire
    coordonnees : Formulaire
    imageWeb : string
    messages?: boolean

    constructor({
        nom = "",
        active = false,
        email = "",
        url = "",
        titreMotif = "",
        motif = [],
        error = false,
        emailMsgError = "",
        urlMsgError = "",
        description = "",
        titre = "",
        image = "",
        colorBo = "",
        iconBo = "",
        formulaires = {},
        coordonnees = {},
        imageWeb = "",
        messages = false
    }) {
        this.nom = nom
        this.active = active
        this.email = email
        this.url = url
        this.titreMotif = titreMotif
        this.motif = motif
        this.error =  error,
        this.emailMsgError = emailMsgError,
        this.urlMsgError = urlMsgError
        this.description = description
        this.titre = titre
        this.image = image
        this.colorBo = colorBo
        this.iconBo = iconBo
        this.coordonnees = coordonnees
        this.formulaires = formulaires
        this.imageWeb = imageWeb
        this.messages = messages
    }
}

export class Dashboard {
    image:       string;
    details?:    Details;
    points:      number;
    titre:       string;
    description: string;

    constructor({
        image="",
        details={},
        points=0,
        titre="",
        description=""
    }) {
        this.image = image
        this.details = new Details(details)
        this.points = points
        this.titre = titre
        this.description = description
    }

}

export class Details {
    description: string;
    typePoints:  string;
    texte:       string;
    valeur:      number;
    titre:       string;

    constructor ({
        description="",
        typePoints="",
        texte = "",
        valeur= 0,
        titre = ""
    }) {
        this.description = description
        this.typePoints = typePoints
        this.texte = texte
        this.valeur = valeur
        this.titre = titre
    }
}

export class FluxClass {
    couleur:               string;
    active:                boolean;
    nom:                   string;
    error? :                boolean;

    constructor({
        couleur = "",
        active = false,
        nom = "",
        error = false
    }) {
        this.couleur = couleur
        this.active = active
        this.nom = nom
        this.error = error
    }
}

export enum FluxDefautElement {
    BornesMontri = "Bornes Montri",
    Déchetteries = "Déchetteries",
    EmballagesEnVerre = "Emballages en verre",
    EmballagesRecyclables = "Emballages recyclables",
    OrduresMénagères = "Ordures ménagères",
}

export class WebContrat {
    soustitreCover: string;
    imageCover:     string;
    logoSite:       string;
    Home_tabs:      HomeTab[];
    LogoUrl:        string;
    titreCover:     string;

    constructor({
        soustitreCover =  "",
        imageCover = "",
        logoSite = "",
        Home_tabs = [],
        LogoUrl = "",
        titreCover = ""
    }) {
        this.soustitreCover = soustitreCover
        this.imageCover = imageCover
        this.logoSite = logoSite
        this.Home_tabs = Home_tabs
        this.LogoUrl = LogoUrl
        this.titreCover = titreCover
    }

}

export class HomeTab {
    title: string;
    image: string;
    text:  string;

    constructor({
        title = "",
        image = "",
        text=""
    }) {
        this.title = title
        this.image = image
        this.text = text
    }

}

export class GuideDutri {

    image:   string;
    famille: string;
    infos:   Info[];
    nom:     string;
    motsCle?: string
    top?: string

    constructor({
        image ="",
        famille="",
        infos=[],
        nom="",
        motsCle = "",
        top = ""
    }) {
        this.image = image
        this.famille = famille
        this.infos = infos
        this.nom = nom
        this.motsCle = motsCle
        this.top = top
    }
}

export class Info {
    description?:   string;
    transition?:    string;
    libelleBouton?: string;
    action?:        string;
    lien?:          string;
    flux?:          string[];

    constructor({
        description = "",
        transition = "",
        libelleBouton = "",
        action = "",
        lien = "",
        flux = []
    }) {
        this.description = description
        this.transition = transition
        this.libelleBouton = libelleBouton
        this.action = action
        this.lien = lien
        this.flux = flux
    }

}

export class ReseauxSociaux {
    lienFacebook: string;
    lienTwitter:  string;
    lienYoutube:  string;
    lienInstagram:  string;
    constructor({lienFacebook ="",lienTwitter = "",lienYoutube = "" , lienInstagram = "" } ){
        this.lienFacebook  = lienFacebook
        this.lienInstagram = lienInstagram
        this.lienTwitter = lienTwitter
        this.lienYoutube = lienYoutube
    }
}

export class Stats {
    points:    number;
    kmVoiture: number;
    constructor({points = 0 , kmVoiture = 0}) {
        this.points = points
        this.kmVoiture = kmVoiture
    }
}


export class config{
    ConfigName : string ;
    status : boolean;
    active : boolean;

    constructor({
        ConfigName = "" ,
        status = false,
        active = false,
    }) {
        this.ConfigName = ConfigName
        this.status = status
        this.active = active
    }

}


export class  ServicesPreview{
    title : String[]
    description1 : String[]
    description2 : String[]
    image : String

    constructor({
        title = [],
        description1 = [],
        description2 = [],
        image = ""
    }) {
        this.title = title
        this.description1 = description1
        this.description2 = description2
        this.image = image
    }

}

export class LocalFiles{
    localLogoWeb : string;
    localCoverWeb : string ;
    localLogoMobile : string;
    localGuideDuTri : string;
    localSplashScreen : string;
    localCarte : string;
    localCalendar : string;

    constructor({
        localLogoWeb = "",
        localCoverWeb = "",
        localLogoMobile = "",
        localGuideDuTri = "",
        localSplashScreen = "",
        localCarte = "",
        localCalendar = ""
    }) {
        this.localLogoWeb = localLogoWeb,
        this.localCoverWeb = localCoverWeb,
        this.localLogoMobile = localLogoMobile,
        this.localGuideDuTri = localGuideDuTri ,
        this.localSplashScreen = localSplashScreen,
        this.localCarte = localCarte,
        this.localCalendar = localCalendar
    }


}

export class ContractStats{
    crowdsourcing : string;
    kmVoiture : string ;
    points : string;
    scans : string;

    constructor({crowdsourcing = '0' , kmVoiture = '0' , points = '0' , scans ='0'  }){
            this.crowdsourcing = crowdsourcing
            this.kmVoiture = kmVoiture
            this.points = points
            this.scans = scans
    }
}


export class villeServie{
    zipCode: string;
    active:  boolean;

    constructor({zipCode = '' , active = true}){
        this.zipCode = zipCode;
        this.active = active;
    }
}

export class Carte{
    flux : Flux[];
}

export class Badge{
    descriptionOff: string
    descriptionOn : string
    id :            string
    imageOff :      string
    imageOn :       string
    nom :           string
    points:         number
    titreOff :      string
    titreOn:        string


}

export class ParrainageContrat {
    active?: Boolean
    descriptionApp?: string
    titrePopup?: string
    textePopup?: string
    texteWeb?: string
    texteWebsuccess?: string
    pointsParrain?: number
    pointsInvite?: number
    textePartage?: string
    imageRecompense?: string
    cartePoints?: string
    carteInfos?: string

    constructor({active, descriptionApp, titrePopup, textePopup, texteWeb, texteWebsuccess, pointsParrain, pointsInvite, textePartage, imageRecompense, cartePoints, carteInfos}) {
        this.active = active
        this.descriptionApp = descriptionApp
        this.titrePopup = titrePopup
        this.textePopup = textePopup
        this.texteWeb = texteWeb
        this.texteWebsuccess = texteWebsuccess
        this.pointsParrain = pointsParrain
        this.pointsInvite = pointsInvite
        this.textePartage = textePartage
        this.imageRecompense = imageRecompense
        this.cartePoints = cartePoints
        this.carteInfos = carteInfos
    }
}



import { Invit } from './../../../../models/invit/invit';
import { AuthentificationService } from './../../../../services/authentification/authentification.service';
import { Alert } from './../../../../models/alert/alert';
import { UserBo, userInvited } from './../../../../models/user/user';
import { FormBuilder } from '@angular/forms';
import { UserService } from './../../../../services/user/user.service';
import { AlertService } from './../../../../services/alert/alert.service';
import { LodingBarService } from './../../../../services/lodingbar/loding-bar-service.service';
import { Component, OnInit, ViewChild, AfterContentChecked } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { AngularFireStorage } from "@angular/fire/storage";
import * as $ from 'jquery';
import {
	takeWhile,
	tap,
	find,
	finalize,
	last,
	switchMap,
} from "rxjs/operators";

@Component({
    selector: 'app-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {

    fileToUpload: File = null;
    menu = 0
    options = [false, false, false, false, false, false, false, false, false, false, false];
    flows = ["Ordures ménagères", "Emballages recyclables", "Verre", "Bornes Montri", "Déchetteries", "Déchetteries mobiles", "Sites de compostage", "Sites de réemploi", "Piles", "Ampoules", "Parcs à sapins"];

    constructor(
        public afs: AngularFirestore,   // Inject Firestore service
        private alertservices: AlertService) {
    }

    ngOnInit(): void {
    }

    handleFileInput(files: FileList) {
        this.fileToUpload = files.item(0);
    }

    uploadFile() {
        if (this.fileToUpload != null) {
            var reader = new FileReader();
            reader.onload = () => {
                console.log(reader.result);
/*                this.afs.collection('flux').doc("TEST").set(JSON.parse(reader.result))
                .then(function(){
                    var alert: Alert = {
                        type: "success",
                        message: " ✔️ Votre invitation a été bien envoyée",
                    };
                    console.log("TOTO")
                    //self.alertservices.setAlert(alert);
                })
                .catch(function(error) {
                    var alert: Alert = {
                        type: "danger",
                        message: " ❗ Envoi d'invitation non réussi réssayer de nouveau ",
                    };
                    console.log(error)
                    //self.alertservices.setAlert(alert);
                });*/
            };
            reader.readAsText(this.fileToUpload);
        } else {
            var alert: Alert = {
                type: "danger",
                message: " ❗ Envoi d'invitation non réussi réssayer de nouveau ",
            };
            //self.alertservices.setAlert(alert);
        }
    }

    setOption(index, status) {
        this.options[index]=status;
    }

    setMenu(menu) {
        this.menu = menu
    }
}

<div *ngIf="user != undefined">
<div class="row">
        <div class="col-12 d-flex flex-column justify-content-center align-items-lg-start align-items-center">
            <p class="pb-0 mb-0  h3">Règles de tri de votre territoire :  </p>
            <p class="pb-0 mb-0 mt-0 p2">Merci de sélectionner vos règles de tri dans la liste ci-dessous </p>
        </div>
</div>
<div class="row mt-2">
        <div class="col-lg-4">
            <select class="custom-select" style="width:100%;box-shadow: none;border: 1px solid #F47000; color: black;"  [disabled]="((user.role!='super_user') && (user.role!='Administrateur')) || (contart.contratstatus != 'en_modification')"  [(ngModel)]="contart.contrat.reglesTri" (ngModelChange)="updateType()">
                <option  class="h3" [ngValue]="'1'">Standard</option>
                <option disabled [ngValue]="'3'" style="font-style:italic" class="p2">&nbsp;&nbsp;&nbsp;(Les plastiques souples ne se trient pas)</option>
                <option  class="h3" [ngValue]="'2'">Extension</option>
                <option disabled  [ngValue]="'4'" style="font-style:italic" class="p2">&nbsp;&nbsp;&nbsp;(Tous les emballages se trient)</option>
            </select>

        </div>

</div>


<div class="row mt-4  mb-4">
    <div class="col-12 d-flex flex-column justify-content-center align-items-lg-start align-items-center">
        <p class="pb-0 mb-0  h3">Paramétrez vos collectes en porte à porte : </p>
        <p class="pb-0 mb-0 mt-0 p2" style="text-align: center;">Veuillez activez les flux de déchets que vous collectez en porte à porte, et renseignez la couleur de leurs bacs (Code couleur Hex #......)</p>
    </div>
</div>

<div class="row  mt-5 mt-lg-2 "  *ngFor="let item of flux  ; index as i">
    <div class="col-lg-1 d-flex flex-row justify-content-lg-start justify-content-center align-items-center">
        <div class="btn-group" role="group" id={{item.nom}} >
            <input type="radio"  name="{{item.nom}}" [value]="true" [(ngModel)]="item.active" (ngModelChange)="onChange($event,i)" id="{{'radiobtoui'+ i}}"  [disabled]="((user.role!='super_user') && (user.role!='Administrateur')) || (contart.contratstatus != 'en_modification')"/>
            <label class="btn btn-secondary radius_left" id="{{'radiooui'+ i}}"  [ngStyle]="{'cursor' :  ((user.role!='super_user') && (user.role!='Administrateur')) || (contart.contratstatus != 'en_modification') ? 'not-allowed' : null }" for="{{'radiobtoui'+ i}}" >
                Oui
            </label>
            <input type="radio"   [value]="false" [(ngModel)]="item.active" (ngModelChange)="onChange($event,i)"   name="{{item.nom}}"  class="radio_input radio_non"   id="{{'radiobtnon'+ i}}"  [disabled]="((user.role!='super_user') && (user.role!='Administrateur')) || (contart.contratstatus != 'en_modification')"/>
            <label  class="btn btn-secondary radius_right" id="{{'radionon'+ i}}"  [ngStyle]="{'cursor' :  ((user.role!='super_user') && (user.role!='Administrateur')) || (contart.contratstatus != 'en_modification') ? 'not-allowed' : null }"   for="{{'radiobtnon'+ i}}">
                Non
            </label>
        </div>
    </div>

    <div class="col-lg-3 ml-lg-2 p1  d-flex flex-row justify-content-lg-start justify-content-center align-items-center">
            {{item.nom}}
    </div>

    <div class="col-lg-2 d-flex flex-row justify-content-lg-start justify-content-center align-items-center">
            <input type="text" (focus)="item.error = false;addfirst(i)" style="font-family:Arial, FontAwesome" ngClass="{{ item.error == true ? 'colorerrorinput' : 'colornoterrorinput'}}" [hidden]="item.active ? false : true" maxlength="7" placeholder="{{ item.error ?'&#xf071;'+ item.colorErrorMsg :'Couleur'}}"   autocomplete="off" [ngStyle]="{'cursor' :  ((user.role!='super_user') && (user.role!='Administrateur')) || (contart.contratstatus != 'en_modification') ? 'not-allowed' : null }" [disabled]="((user.role!='super_user') && (user.role!='Administrateur')) || (contart.contratstatus != 'en_modification')"  [(ngModel)]="item.couleur" (ngModelChange)="onChange($event,i)" class="form-control input1" required>
    </div>

    <div class="col-lg-1 mt-2 mt-lg-0 d-flex flex-row justify-content-lg-start justify-content-center align-items-center">
        <div [ngStyle]="{'background-color':item.couleur}" [hidden]="((item.couleur.length == 7)&&(item.active))  ? false : true " id="{{'color'+i }}" style="height:30px;width:40px"></div>
    </div>

    <ng-container *ngIf='item.active'>
        <div class="col-lg-2 d-flex flex-row justify-content-lg-start justify-content-center align-items-center" style="font-size: 14px;color: #707070;font-family: avenir_heavy;">
            Nom à afficher :
        </div>
    </ng-container>
   
    <div class="col-lg-2 d-flex flex-row justify-content-lg-start justify-content-center align-items-center">
        <input type="text"  style="font-family:Arial, FontAwesome" ngClass="" [hidden]="item.active ? false : true" placeholder="{{ item.nom }}"   autocomplete="off" [ngStyle]="{'cursor' :  ((user.role!='super_user') && (user.role!='Administrateur')) || (contart.contratstatus != 'en_modification') ? 'not-allowed' : null }" [disabled]="((user.role!='super_user') && (user.role!='Administrateur')) || (contart.contratstatus != 'en_modification')"  value="{{ item.displayName ? item.displayName : item.nom}}" (keyup)="onChangeDisplayName($event, item.nom)" class="form-control input1">
    </div>
</div>


<div class="row mt-5 mb-4">
    <div class="col-lg-3 d-flex flex-row justify-content-center">
        <button type="button"  class="btn  btn-primary w-100 button2" routerLink="/montri/contractsettings/informations">Précédent</button>
    </div>
    <div class="col-lg-3 mt-3 mt-lg-0 d-flex flex-row justify-content-center">
        <button type="button" class="btn  btn-primary w-100  h3_heavy button4"  (click)="saveData()" ngClass="{{ next() ? 'opacity05':''}}">Suivant</button>
    </div>
</div>
</div>



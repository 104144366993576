import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-activeservice',
    templateUrl: './activeservice.component.html',
    styleUrls: ['./activeservice.component.scss']
})
export class ActiveserviceComponent implements OnInit {

    constructor(
        public activeModal: NgbActiveModal,
        private router : Router
    ) { }

    ngOnInit(): void {

    }

    closeModal(){
        this.activeModal.close()
    }

    gotourl(){
        this.activeModal.close()
        this.router.navigateByUrl('/montri/contractsettings/services')
    }
}

<ng-template #modalVersion let-modal >
    <div class="modal-body" style="border-color: black;border-width: 1000px;">
          <div class="modal-header" style='border: none;'>
            <h5 class="modal-title avenir_heavy h2_heavy nav_bar_link text_oranger">Une nouvelle version est disponible</h5>
          </div>
          <div class="modal-body avenir_light p2_light">
              Afin d'avoir l'intergralité des dernières modifications, merci de recharger la page.
          </div>
          <div class="modal-footer" style='border: none;'>
            <!-- <button type="button" class="btn btn-secondary" [ngStyle]="{'color': 'white','background-color':'#f47000','border-color': '#f47000'}" data-dismiss="modal" (click)='modal.close()'>Ignorer</button> -->
            <button type="button" class="btn btn-primary" [ngStyle]="{'color': 'white','background-color':'#f47000','border-color': '#f47000'}" data-dismiss="modal" (click)='reloadSite()'>Mettre à jour</button>
          </div>
    </div>
  </ng-template>


<div class=" container-fluid wrapper" >
        <div class="row d-flex flex-row" style="min-height:100vh;">
                <!-- Sidebar -->
                <div id="sidebar-container" class="sidebar-expanded WhateverYourNavIs" style="position:fixed; z-index: 1;"><!-- d-* hiddens the Sidebar in smaller devices. Its itens can be kept on the Navbar 'Menu' -->
                    <!-- Bootstrap List Group -->
                    <ul class="list-group">
                        <!-- Separator with title -->
                        <li class="d-flex justify-content-center w-100 align-items-center mb-5 menu-collapsed" attr.aria-expanded="{{expanded}}">
                            <img (click)="gotTo('/montri/welcome')" src="../../../../../assets/img/montri_logo.png">
                        </li>
                        <!-- /END Separator -->
                        <!-- Menu with submenu -->
                        <a style="text-decoration:none"  data-toggle="collapse" attr.aria-expanded="{{expanded}}" class="item h3 pt-1 pb-1 pl-0 align-items-start">
                            <div class="d-flex flex-row justify-content-start w-100 h-100 align-items-center pl-2 ml-0 mr-4"   container="body" ngbTooltip="{{expanded ? 'Statistiques' : ''}}" tooltipClass="legend"
                            data-container="body"  placement="right" [ngStyle]="{'background-color': (section == 'statistiques' )&& !expanded  ? '#f47000':'white'}">
                                <img src="../../../../../assets/img/Menu/013-menu-tableaudebord.png"  [hidden]="!expanded"  class="mr-2" style="width: 30px; height: 30px;">
                                <span class="menu-collapsed">Tableau de bord</span>
                            </div>
                        </a>

                        <ng-container *ngIf="checkRoles('statistiques') != false">
                        <a  style="text-decoration:none"  data-toggle="collapse" attr.aria-expanded="{{expanded}}" data-title="Boitiers Montri"  class="item subitem pt-1 pb-1 pl-0  align-items-start">
                            <div class="d-flex flex-row  w-100 h-100 align-items-center ml-0 mr-4 "   container="body" ngbTooltip="{{expanded ? '' : 'Statistiques'}}" tooltipClass="legend"
                            data-container="body" (click)="contrat.contrat.appLive ?gotTo('/montri/appstats'):modalService.open(content,{ centered: true })"  placement="right"  (mouseover)="hover_stats=true" (mouseout)="hover_stats=false" [ngStyle]="{'background-color': (subsection == 'stats' ) || (hover_stats == true) ? 'rgba(244,112,0,0.1)':'white'}">
                                <img src="../../../../../assets/img/Menu/002-menu-stats-icon.svg" class="mr-2" style="width: 20px; height: 20px;">
                                <span class="menu-collapsed">Statistiques</span>
                            </div>
                        </a>
                        </ng-container>

                        <ng-container *ngIf="checkRoles('boitier') != false">
                        <a  style="text-decoration:none"  *ngIf="is_active_boitier"  data-toggle="collapse" attr.aria-expanded="{{expanded}}" data-title="Boitiers Montri"  class="item subitem pt-1 pb-1 pl-0  align-items-start">
                            <div class="d-flex flex-row  w-100 h-100 align-items-center ml-0 mr-4 "   container="body" ngbTooltip="{{expanded ? 'Boitiers Montri' : ''}}" tooltipClass="legend"
                            data-container="body"  placement="right"  (mouseover)="hover_botier=true" (click)='openUrlScan()' (mouseout)="hover_botier=false" [ngStyle]="{'background-color': (subsection == 'boitier' ) || (hover_botier == true) ? 'rgba(244,112,0,0.1)':'white'}">
                                <img src="../../../../../assets/img/Menu/003-menu-montribornes-icon.svg" class="mr-2" style="width: 20px; height: 20px;">
                                <span class="menu-collapsed">Boitiers Montri</span>
                            </div>
                        </a>
                        </ng-container>

                        <ng-container *ngIf="checkRoles('analyseFlux') != false">
                        <a style="text-decoration:none" data-toggle="collapse" attr.aria-expanded="{{expanded}}" class="item subitem pt-1 pb-1 pl-0  align-items-start">
                            <div class="d-flex flex-row  w-100 h-100 align-items-center ml-0 mr-4 "   container="body" ngbTooltip="{{expanded ? 'Analyse des flux' : ''}}" tooltipClass="legend"
                            data-container="body" (click)="contrat.contrat.appLive ?gotTo('/montri/fluxstats'):modalService.open(content,{ centered: true })"   placement="right" (mouseover)="hover_analyse=true" (mouseout)="hover_analyse=false" [ngStyle]="{'background-color': (subsection == 'analyse' )  || (hover_analyse == true) ? 'rgba(244,112,0,0.1)':'white'}">
                                <img src="../../../../../assets/img/Menu/004-menu-analyse-icon.svg"  class="mr-2" style="width: 20px; height: 20px;">
                                <span class="menu-collapsed">Analyse des flux</span>
                            </div>
                        </a>
                        </ng-container>

                        <ng-container *ngIf="checkRoles('signalements') != false">
                        <a  style="text-decoration:none"  *ngIf="is_active_reporting"  data-toggle="collapse" attr.aria-expanded="{{expanded}}" class="item subitem  pt-1 pb-1 pl-0  align-items-start">
                            <div class="d-flex flex-row w-100 h-100 align-items-center ml-0 mr-4"  container="body" ngbTooltip="{{expanded ? 'Signalement' : ''}}" tooltipClass="legend"
                            data-container="body"  placement="right"   (click)="contrat.contrat.appLive ? gotTo('/montri/reportingstats') :modalService.open(content,{ centered: true })" (mouseover)="hover_signalement=true" (mouseout)="hover_signalement=false" [ngStyle]="{'background-color': (subsection == 'signalements' )  || (hover_signalement == true)? 'rgba(244,112,0,0.1)':'white'}">
                                <img src="../../../../../assets/img/Menu/005-menu-demandes-icon.svg"  class="mr-2" style="width: 20px; height: 20px;">
                                <span class="menu-collapsed">Signalements</span>
                            </div>
                        </a>
                        </ng-container>

                        <ng-container *ngIf="checkRoles('parametrage') != false">
                        <a  style="text-decoration:none" data-toggle="collapse" attr.aria-expanded="{{expanded}}" class="item  pt-1 pb-1 pl-0 h3 align-items-start">
                            <div class="d-flex flex-row justify-content-start w-100 h-100 align-items-center ml-0 pl-2 mr-4 "  container="body" ngbTooltip="{{expanded ? 'Paramétrage' : ''}}" tooltipClass="legend"
                            data-container="body"  placement="right" (click)="gotTo('/montri/welcome')"  [ngStyle]="{'background-color': (section == 'paramétrage') && !expanded  ? '#f47000':'white'}">
                                <img src="../../../../../assets/img/Menu/014-menu-parametrage.png" [hidden]="!expanded" class="mr-2" style="width: 30px; height: 30px;">
                                <span class="menu-collapsed">Paramétrage</span>
                            </div>
                        </a>
                     </ng-container>

                     <ng-container *ngIf="checkRoles('territoire') != false"> 
                        <a  style="text-decoration:none" data-toggle="collapse" attr.aria-expanded="{{expanded}}" class="item  subitem pt-1 pb-1 pl-0  align-items-start">
                            <div class="d-flex flex-row  w-100 h-100 align-items-center ml-0 mr-4"  container="body" ngbTooltip="{{expanded ? 'Mon Territoire' : ''}}" tooltipClass="legend"
                            data-container="body"  placement="right" (click)="gotTo('/montri/contractsettings/informations')" (mouseover)="hover_territoire=true" (mouseout)="hover_territoire=false" [ngStyle]="{'background-color': subsection == 'territoire' || (hover_territoire == true) ? 'rgba(244,112,0,0.1)':'white'}">
                                <img src="../../../../../assets/img/Menu/006-menu-territoire-icon.svg"  class="mr-2" style="width: 20px; height: 20px;">
                                <span class="menu-collapsed">Mon Territoire</span>

                            </div>
                        </a>
                    </ng-container>

                    <ng-container *ngIf="checkRoles('carte') != false"> 
                        <a  style="text-decoration:none"  data-toggle="collapse" attr.aria-expanded="{{expanded}}" class="item  subitem pt-1 pb-1 pl-0  align-items-start">
                            <div class="d-flex flex-row w-100 h-100 align-items-center ml-0 mr-4 "   container="body" ngbTooltip="{{expanded ? 'Carte' : ''}}" tooltipClass="legend"
                            data-container="body"  placement="right" (click)="is_active_carte ? gotTo('/montri/cartsettings/coordinate'): showModal()" (mouseover)="hover_carte=true" (mouseout)="hover_carte=false" [ngStyle]="{'background-color': (subsection == 'carte' ) || (hover_carte == true) ? 'rgba(244,112,0,0.1)':'white'}">
                                <img src="../../../../../assets/img/Menu/007-menu-carte-icon.svg"  class="mr-2" style="width: 20px; height: 20px;">
                                <span class="menu-collapsed">Carte</span>
                            </div>
                        </a>
                    </ng-container>

                    <ng-container *ngIf="checkRoles('calendrier') != false"> 
                        <a style="text-decoration:none"  data-toggle="collapse"  attr.aria-expanded="{{expanded}}" class="item subitem pt-1 pb-1 pl-0  align-items-start">
                            <div class="d-flex flex-row  w-100 h-100 align-items-center ml-0 mr-4"  container="body" ngbTooltip="{{expanded ? 'Calendrier' : ''}}" tooltipClass="legend"
                            data-container="body"  placement="right"  (click)="is_active_calendar ? gotTo('/montri/calendarsettings') : showModal()"  (mouseover)="hover_calendrier=true" (mouseout)="hover_calendrier=false" [ngStyle]="{'background-color': (subsection == 'calendrier' ) || (hover_calendrier == true) ? 'rgba(244,112,0,0.1)':'white'}">
                                <img src="../../../../../assets/img/Menu/008-menu-calendrier-icon.svg"  class="mr-2" style="width: 20px; height: 20px;">
                                <span class="menu-collapsed">Calendrier</span>
                            </div>
                        </a>
                    </ng-container>

                    <ng-container *ngIf="checkRoles('actualites') != false"> 
                        <a  style="text-decoration:none" data-toggle="collapse" [attr.disabled]="checkRoles('actualites')" attr.aria-expanded="{{expanded}}" class="item subitem pt-1 pb-1 pl-0  align-items-start">
                            <div class="d-flex flex-row  w-100 h-100 align-items-center ml-0 mr-4 "  container="body" ngbTooltip="{{expanded ? 'Actualités' : ''}}" tooltipClass="legend"
                            data-container="body"  placement="right" (click)="is_active_actus ? gotTo('/montri/actussettings'): showModal()" (mouseover)="hover_actus=true" (mouseout)="hover_actus=false" [ngStyle]="{'background-color': (subsection == 'actus' ) || (hover_actus == true) ? 'rgba(244,112,0,0.1)':'white'}">
                                <img src="../../../../../assets/img/Menu/009-menu-actu-icon.svg"  class="mr-2" style="width: 20px; height: 20px;">
                                <span class="menu-collapsed">Actualités</span>
                            </div>
                        </a>
                    </ng-container>

                   

                    <ng-container *ngIf="checkRoles('alertes') != false">
                        <a  style="text-decoration:none" data-toggle="collapse" attr.aria-expanded="{{expanded}}" class="item subitem pt-1 pb-1 pl-0  align-items-start">
                            <div class="d-flex flex-row  w-100 h-100 align-items-center ml-0 mr-4"  container="body" ngbTooltip="{{expanded ? 'Alertes' : ''}}" tooltipClass="legend"
                            data-container="body"  placement="right"  (click)="is_active_alertes ? gotTo('/montri/alertssettings'): showModal()"  (mouseover)="hover_alerts=true" (mouseout)="hover_alerts=false" [ngStyle]="{'background-color': (subsection == 'alerts' ) || (hover_alerts == true) ? 'rgba(244,112,0,0.1)':'white'}">
                                <img src="../../../../../assets/img/Menu/010-menu-notifications-icon.svg"  class="mr-2" style="width: 20px; height: 20px;">
                                <span class="menu-collapsed">Alertes</span>
                            </div>
                        </a>
                    </ng-container>

                    <ng-container *ngIf="checkRoles('documents') != false">
                        <a  style="text-decoration:none" data-toggle="collapse" attr.aria-expanded="{{expanded}}" class="item subitem pt-1 pb-1 pl-0  align-items-start">
                            <div class="d-flex flex-row  w-100 h-100 align-items-center ml-0 mr-4"  container="body" ngbTooltip="{{expanded ? 'Documents' : ''}}" tooltipClass="legend"
                            data-container="body"  placement="right"  (click)="is_active_document ? gotTo('/montri/documents'): showModal()"  (mouseover)="hover_document=true" (mouseout)="hover_document=false" [ngStyle]="{'background-color': (section == 'documents' ) || (hover_document == true) ? 'rgba(244,112,0,0.1)':'white'}">
                                <img src="../../../../../assets/img/Menu/latmenu-document.svg"  class="mr-2" style="width: 20px; height: 20px;fill: #F47000;">
                                <span class="menu-collapsed">Documents</span>
                            </div>
                        </a>
                    </ng-container>

                    
                        <div class="mt-4 mb-1 line"></div>
                        <a style="text-decoration:none"  data-toggle="sidebar-colapse" (click)="SidebarCollapse()" class="d-flex align-items-center">
                            <div class="d-flex w-100 justify-content-end align-items-center">
                                <img src="../../../../../assets/img/Menu/011-menu-leftarrow-icon.png" [hidden]="expanded"  class="mr-2" style="width: 20px; height: 20px;">
                                <img src="../../../../../assets/img/Menu/012-menu-rightarrow-icon.png" [hidden]="!expanded"  class="mr-2" style="width: 20px; height: 20px;">
                            </div>
                        </a>


                    </ul><!-- List Group END-->
                </div><!-- sidebar-container END -->


            <div class="d-flex flex-column flex-grow" style="transition: 0.5s; z-index: 0;" [ngStyle]="{'margin-left': expanded ? '60px' : '230px' }">
                <!--Header -->
                <nav class="navbar navbar-light sticky-top">
                    <div class="container-fluid">
                        <span class="navbar-brand">

                        </span>
                        <div  class="d-flex flex-row  justify-content-end  align-items-center">
                            <div class="p2 mr-4 d-flex flex-column justify-content-center align-items-center h-100"> V 1.9.6</div>
                            <select class="custom-select mr-3 p2" style="width: 200px;box-shadow: none;border: 1px solid #F47000; color: black;" [(ngModel)]="user.idContrat" (ngModelChange)="updateContrat()">
                                <option class="p2" *ngFor="let item of user.listContrats" [ngValue]="item.idContrat">{{item.nomContrat}}</option>
                            </select>

                            <img src="../../../../../assets/img/Header/034-home.svg" class="mr-3" (click)="goHome()" id="homeButton" style="height: 20px;width: 20px;">
                            <div class="img_user_photo mr-3" style="background-size: cover;" [ngStyle]="{'background-image': 'url('+ imageUrl+')'}"></div>
                            <div class="p2 mr-4 d-flex flex-column justify-content-center align-items-center h-100">Bonjour !</div>
                            <img src="../../../../../assets/img/Header/003-header-Notification-icon.svg" class="mr-5" id="notifButton" style="height: 20px;width: 20px;">
                            <div class="dropdown show" >

                                <img  src ="./../../../../../assets/img/011-chevron.svg" style="height: 30px;width: 15px;" class="dropdown-toggle"  href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" attr.aria-expanded="{{expanded}}">

                                <div class="dropdown-menu  dropdown-menu-right mt-4" aria-labelledby="dropdownMenuLink">
                                  <a class="dropdown-item" routerLink="/montri/account">Mon compte</a>
                                  <a class="dropdown-item" href="#">Contacter le support</a>
                                  <a class="dropdown-item" href="#"  data-toggle="modal" data-target="#exampleModalCenter"> Déconnexion</a>
                                </div>
                              </div>
                        </div>
                    </div>
                </nav>
                <!--Header-->
                <router-outlet style="z-index: 0;"></router-outlet>
            </div>

        </div>

</div>


<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered" role="document">
	  <div class="modal-content">
		<div class="modal-header" style='border: none;'>
		  <h5 class="modal-title avenir_heavy h2_heavy nav_bar_link text_oranger" id="exampleModalLongTitle">Déconnexion</h5>

		</div>
		<div class="modal-body avenir_light nav_bar_link border-0 ml-5 p2_light">
			Voulez-vous vraiment vous déconnecter ?
		</div>
		<div class="modal-footer" style='border: none;'>
		  <button type="button" class="btn btn-secondary" [ngStyle]="{'color': 'white','background-color':'#f47000','border-color': '#f47000'}" data-dismiss="modal">NON</button>
		  <button type="button" class="btn btn-primary" [ngStyle]="{'color': 'white','background-color':'#f47000','border-color': '#f47000'}" data-dismiss="modal" (click)="logout()">OUI</button>
		</div>
	  </div>
	</div>
  </div>

  

<ng-template #content let-c="close" let-d="dismiss">
    <div class="modal-content">
		<div class="modal-header" style='border: none;'>
		  <h5 class="modal-title avenir_heavy h2_heavy nav_bar_link text_oranger" id="exampleModalLongTitle">Informations :</h5>

		</div>
		<div class="modal-body avenir_light nav_bar_link border-0 ml-2 p2_light">
			Les statistiques seront accessibles une fois votre application en ligne.
		</div>

	</div>
</ng-template>



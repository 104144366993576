import { ImgReportPopupComponent } from '../imgReportPopup/imgReportPopup.component';
import { AngularFireStorage } from '@angular/fire/storage';
import { ReportingstatsService } from './../../../../services/reportingstats/reportingstats.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ContratService } from './../../../../services/contrat/contrat.service';
import { Component, OnInit, Input, ViewEncapsulation, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Message, Report } from 'src/app/models/report/report';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from './../../../../services/user/user.service';
import * as moment from 'moment';
import * as firebase from 'firebase';
import { DatePipe } from '@angular/common';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { hexToCSSFilter } from 'hex-to-css-filter';
import { UserBo } from 'src/app/models/user/user';

@Component({
    selector: 'app-reporting',
    templateUrl: './reporting.component.html',
    styleUrls: ['./reporting.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ReportingComponent implements OnInit {

    constructor(
        private contratservice: ContratService,
        public activeModal: NgbActiveModal,
        private reportingservice : ReportingstatsService,
        private afStorage : AngularFireStorage,
        private modalService: NgbModal,
        private userService: UserService,
        private datepipe: DatePipe,
        private lodingBarService: LoadingBarService
    ) { 
    }

    @Input() report: Report
    @ViewChild("msg") msgRef : ElementRef
    reportImageUrl : string 
    userImageUrl: string
    userMail: string;
    userName: string;
    statusBefore: string
    commentBefore? :string
    showHistory: boolean = true
    message?: string
    activateSendButton = false
    isMessagesActive = false
    primaryColor?: string
    cssFilter?: string = undefined
    user?: UserBo
    messages?: Message[]

    ngOnInit(): void {

        this.userService.user_obs.subscribe((user) => {
            if (user != null) {
                this.user = user
                this.userMail = user.email
                this.userName = `${user.prenom} ${user.nom}`
            }
        })

        this.userService.profile_image_obs.subscribe((url) => {
            if (url) {
                this.userImageUrl = url;
            } else {
                this.userImageUrl = './../../../../../assets/img/user_image.jpg';
            }
        })
        
        this.contratservice.contrat_obs.subscribe((contrat)=>{
            this.primaryColor = contrat.design.couleurPrincipale
            this.cssFilter = hexToCSSFilter(this.primaryColor).filter.replace(";","")
            const ref = this.afStorage.ref(contrat.contrat.idContrat +'/formulaires/'+this.getValue(this.report.formulaires, 'image'));
            ref.getDownloadURL().subscribe((href)=>{
                this.reportImageUrl = href
            })
        })

        this.messages = this.report.messages?.slice().reverse() ?? []
        this.isMessagesActive = (this.report.messages?.length ?? 0) > 0 

        this.commentBefore = this.report.commentaire
        this.statusBefore = this.report.status
        this.mapReporting(this.report)
    }

    mapReporting(report) {
        if (report.coordonnees === undefined) {
            report['coordonnees'] = 
                {
                    'Nom' : report.nom,
                    'Prénom' : report.prenom,
                    'Email' : report.email,           
                    'Téléphone' : report.telephone
                }    
            report['formulaires'] = 
                {
                    'Message' : report.message,
                    'Motif' : this.checkUndefinedMotif(report.motif),
                    'Image' :report.image,
                    'Adresse' : report.adresse
                }
        } else if(report.image) {
            Object.assign(report.formulaires, {'image': report.image});
        } else if(!report.id) {
            report.id = this.parseId(report.type, report.date.seconds)
        }
    }

    parseId(type: string, secs: number) {
        let code = ''
        if(type === 'Commerces et Professionnels') {
            code = 'PRO'
        } else if(type === 'Dépôt sauvage') {
            code = 'DS'
        } else if(type === 'Emcombrants') {
            code = 'ENC'
        } else if(type === 'Anomalie de collecte') {
            code = 'AC'
        } else if(type === 'Demande de bac') {
            code = 'BAC'
        } else if(type === 'Borne cassée') {
            code = 'BC'
        } else if(type === 'Autres') {
            code = 'AUT'
        } else if(type === 'Point de collecte') {
            code = 'PC'
        }
        let date = new Date(1970, 0, 1)
        date.setSeconds(secs)
        let newdate = this.datepipe.transform(date, "ddMMyyyyHHmmss")
        const dateParsed = `${code}${newdate}`
        return dateParsed
    }

    checkEmpty(str){
        if (str == undefined || str.length === 0) {
            return true
        } else {
            return false
        }
    }

    toDateTime(secs) {
        var date = new Date(1970, 0, 1); // Epoch
        date.setSeconds(secs);
        return moment(date).format('DD/MM/YYYY')
    }

    checkUndefinedMotif(element: string) {
        let data = "Sans motif"
        if (element === undefined || element === '') {
            return data
        } else {
            return element
        }
    }

    filterCheck(key: string) {
        key = key.toLowerCase()
        return !key.includes("image") && !key.includes("motif") && !key.includes("photo")
    }

    getValue(items, valeur){
        valeur = valeur.toLowerCase()
        var want = '';
        for (let [key, value] of Object.entries(items)) {
            key = key.toLowerCase()
            if(key.includes(valeur)) {
                want = String(value)
                return want
            }
            if (valeur.includes("image")) {
                if(key.includes("photo")) {
                    want = String(value)
                    return want
                } 
            }
        }
    }

    close(){
        if (this.report.majBO) {
            this.report.majBO = false
            this.reportingservice.changeReadStatus(this.report.gid)
        }
        this.activeModal.close(this.report)
    }

    cancel() {
        this.report.commentaire = this.commentBefore
        this.report.status = this.statusBefore
        if (this.report.majBO) {
            this.report.majBO = false
            this.reportingservice.changeReadStatus(this.report.gid)
        }
        this.activeModal.close(this.report)
    }

    show() {
        let options: NgbModalOptions = {};
        options.centered = true
        options.size='lg'
        const modalRef = this.modalService.open(ImgReportPopupComponent,options)
        modalRef.componentInstance.reportImageUrl = this.reportImageUrl
    }

    humanizeStatus(status:string){
        let result = ''
        if (status === 'a_traiter') {
            result = 'A traiter'
        }
        else if (status === 'en_cours') {
            result = 'En cours'
        }
        else if (status === 'traiter') {
            result = 'Résolu'
        }
        else if (status === 'archiver') {
            result = 'Archiver'
        }
        return result
    }

    statusColor(status: string) {
        switch(status) {
            case "A traiter" :
                return "action-red"
            case "En cours" :
                return "action-yellow"
            case "Résolu" :
                return "action-green"
            case "Archiver" :
                return "action-grey"
            default :
                return ""
        }
    }

    genHtmlAction(action:string) {
        let actionSuffix = action.replace(/^(a passé le statut du ticket de )/,'')
        let status = actionSuffix.split(" à ")

        if (status.length == 2) {
            return `a passé le statut du ticket de 
            <div class="ml-1 mr-1 action ${this.statusColor(status[0])}" >
            ${status[0]}
            </div> 
            à 
            <div class="ml-1 mr-1 action ${this.statusColor(status[1])}" >
            ${status[1]}
            </div>`
        } else {
            return action
        }
    }

    updateHistory(){
        let result = false
        if (!this.report.historique) {
            this.report.historique = []
        }
        if (this.report.commentaire != this.commentBefore) {
            let action = {
                date: moment().format('DD/MM/YYYY HH:mm'),
                email: this.userMail,
                nom: this.userName,
                image: this.userImageUrl,
                action: "a ajouté un commentaire :",
                commentaire: this.report.commentaire,
            }
            this.report.historique.splice(0, 0, action)
            result = true
        }

        if (this.report.status != this.statusBefore) {
            let action = {
                date: moment().format('DD/MM/YYYY HH:mm'),
                email: this.userMail,
                nom: this.userName,
                image: this.userImageUrl,
                action: `a passé le statut du ticket de ${this.humanizeStatus(this.statusBefore)} à ${this.humanizeStatus(this.report.status)}`,
                commentaire: this.report.commentaire,
            }
            this.report.historique.splice(0, 0, action)
            result = true
        }
        return result
    }

    changeStatus(status: string){
        this.report.status = status
    }

    timestampToDate(timestamp){
        try {
            return moment(timestamp.toDate()).format('DD/MM/YYYY à HH:mm')
        } catch (error) {
            return ""
        }
    }

    async updateStatus(){
        if (this.updateHistory()) {
            if (this.report.status === 'traiter') {
                this.report.soldeur = this.userMail
                this.report.dateFin = firebase.firestore.Timestamp.now();
            } else if (this.report.status === 'en_cours') {
                this.report.dateEnCours = firebase.firestore.Timestamp.now();
            }
            this.report.majBO = false
            this.report.maj = true
            let state = await this.reportingservice.updateReporting(this.report)
            if (state === 'success') {
                this.statusBefore = this.report.status
                this.commentBefore = this.report.commentaire
            }
        }
    }

    changeHistoryVisibility(status: boolean) {
        this.showHistory = status
    }

   async sendMessage() {
        if (this.message) {
            let message = new Message()
            message.message = this.message
            message.expediteur = 1
            message.email = this.userMail
            this.report.messages.push({...message})
            this.messages.splice(0,0,message)
            this.lodingBarService.start()
            this.reportingservice.updateReportMessages(this.report).then(() => {
                this.msgRef.nativeElement.innerText = ""
                this.message = undefined
                this.lodingBarService.stop()
            }).catch((error) => {
                console.log(error)
                this.report.messages.pop()
                this.messages.shift()
                this.lodingBarService.stop()
            })
        }
    }
}


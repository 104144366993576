import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ActussettingsComponent } from './components/actussettings/actussettings.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule,ReactiveFormsModule} from '@angular/forms'
import { ActussettingsRoutingModule } from './actussettings-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ActusinfosComponent } from './modals/actusinfos/actusinfos.component';
import { DeleteactusComponent } from './modals/deleteactus/deleteactus.component';



@NgModule({
    declarations: [ActussettingsComponent, ActusinfosComponent, DeleteactusComponent],
    imports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      HttpClientModule,
      NgbModule,
      AngularSvgIconModule.forRoot(),
      ActussettingsRoutingModule
    ]
})
export class ActussettingsModule { }

<div class="wrapper" style="background-color: #F8F8F8;">

    <!--Header -->
	<nav class="navbar navbar-light sticky-top">
		<div class="container ">
            <span class="navbar-brand mb-0 h1"><img src="assets/img/UZER_orange.webp" class="app_logo"></span>
		</div>
    </nav>
    <!--Header-->

    <router-outlet></router-outlet>

	<!-- Footer -->
	<footer class="footer  text-white  footer_pos">
		<div class="container d-flex flex-column justify-content-center align-items-center">
            <a class="avenir_light"href="https://www.uzer.eu/code-de-bonne-conduite-montri" target="_blank" style="color:white;">Conditions Générales d’Utilisation</a>

            <a class="avenir_light" href="https://www.uzer.eu/charte-de-confidentialite-montri" target="_blank" style="color:white;">Confidentialité</a>

            <a class="avenir_light" href="#" style="color:white;">Support</a>

            <div class="hl mt-2"></div>

            <img src="../../../../../assets/img/logo_uzer_blanc.png" class="app_logo2 ">

		</div>
	</footer>
	<!-- Footer -->
</div>

<!-- <i class="fa fa-times-circle fa-2x" id="close" aria-hidden="true"  (click)=" close()"></i> -->
<div>

<div class="mt-4 ml-4">

    <div class="row mb-2">
        <div class="col-12 h2">
            Ajouter un document
        </div>
    </div>

    <p>Type de document :</p>
    <select (change)="selectOption($event.target.value)" [(ngModel)]="document.type" class="form-select mb-4" aria-label="Default select example">
        <option value="" hidden selected disabled ><p style="opacity: .5;">Selectionner</p> </option>
        <option [value]="item.name"  *ngFor="let item of items">{{item.name}}</option>
    </select>

    <div class="row mt-2 mb-4"  *ngIf="typeError==true">
        <div class="col-lg-12  d-flex flex-row justify-content-center justify-content-lg-start">
            <div class="row w-100">
                <div class="col-lg-12 mt-3 mt-lg-0 d-flex flex-row justify-content-center justify-content-lg-start align-items-center error pb-0">
                    <i class="fa fa-exclamation-triangle fa-2x" aria-hidden="true" style="width: 30px;height: 30px;"></i>
                    <p class="ml-3 h-100 d-flex flex-row  mb-0 pb-0 justify-content-center align-items-end" style="text-align: center;">La selection du type est obligatoire</p>
                </div>
            </div>
        </div>
    </div>

    <p>Titre :</p>
    <div class="inner-addon left-addon mr-4 mb-4">
        <i class="fa fa-font"></i>
        <input [value]="document.nom " style="border-radius: 10px; border: 0px; background-color: #f4f3f3;color: black;height: 2.5rem;font-family:Arial, FontAwesome;" type="text" class="form-control" #titleInput placeholder="Nom du document" />
    </div>

    <div class="row mt-2 mb-4"  *ngIf="nameError==true">
        <div class="col-lg-12  d-flex flex-row justify-content-center justify-content-lg-start">
            <div class="row w-100">
                <div class="col-lg-12 mt-3 mt-lg-0 d-flex flex-row justify-content-center justify-content-lg-start align-items-center error pb-0">
                    <i class="fa fa-exclamation-triangle fa-2x" aria-hidden="true" style="width: 30px;height: 30px;"></i>
                    <p class="ml-3 h-100 d-flex flex-row  mb-0 pb-0 justify-content-center align-items-end" style="text-align: center;">Le nom de document est obligatoire</p>
                </div>
            </div>
        </div>
    </div>

    <p>Charger votre document :</p>
    <div class="inner-addon left-addon mr-4 mb-4">
        <i class="fa fa-plus" style="color: white;"></i>
        <label  style="border-radius: 8px;" class="btn btn-primary btn_add_doc w-50 mb-4">
            {{cutString(labelAjoutDocument, 20)}}
            <input type="file"  #fileInput  id="file" class="inputfile"  (change)="getFile(fileInput.files[0])" hidden>
        </label>
    </div>

    <div class="row mt-2 mb-4"  *ngIf="docError==true">
        <div class="col-lg-12  d-flex flex-row justify-content-center justify-content-lg-start">
            <div class="row w-100">
                <div class="col-lg-12 mt-3 mt-lg-0 d-flex flex-row justify-content-center justify-content-lg-start align-items-center error pb-0">
                    <i class="fa fa-exclamation-triangle fa-2x" aria-hidden="true" style="width: 30px;height: 30px;"></i>
                    <p class="ml-3 h-100 d-flex flex-row  mb-0 pb-0 justify-content-center align-items-end" style="text-align: center;">Veuillez ajouter un document</p>
                </div>
            </div>
        </div>
    </div>
     
     <div class="row mb-4 mt-4">
        <div class="col-lg-4">
            <button type="button" class="btn button4 btn-primary w-100" (click)="savedoc()">Publier</button>
        </div>
        <div class="col-lg-4 mt-3 mt-lg-0 ">
            <button type="button" class="btn button7 btn-primary w-100" (click)="close()">Annuler</button>
        </div>
    </div>
</div>

</div>

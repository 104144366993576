<div class="row mt-5 ml-4 mr-4 mb-3">
    <div class="col-12">
        <div class="row ">
            <div class="col-lg-4">
            </div>
            <div class="col-lg-4 d-flex flex-row justify-content-center align-items-center">
                <div class="d-flex flex-column align-items-center justify-content-center  h-100" (click)="MoveLet()">
                    <div class="cercle_fleche">
                        <i class="fa fa-chevron-left"></i>
                    </div>
                </div>
                <div class="date mr-5 ml-5 d-flex flex-column justify-content-center  align-items-center h-100 bg-red"  *ngIf="timeType == 'DAY'">
                    {{date}}
                </div>
                <div class="date mr-5 ml-5 d-flex flex-column justify-content-center  align-items-center h-100 bg-red"  *ngIf="timeType == 'MONTH'">
                    {{months[this.current_date.getMonth()]}}
                </div>
                <div class="date mr-5 ml-5 d-flex flex-column justify-content-center  align-items-center h-100 bg-red"  *ngIf="timeType == 'YEAR'">
                    {{this.current_date.getFullYear()}}
                </div>
                <div class="d-flex flex-column align-items-center justify-content-center  h-100" (click)="MoveRight()">
                    <i class="fa fa-chevron-right"></i>
                </div>
            </div>
            <div class="col-lg-4 d-flex flex-row justify-content-end">
                <div class="btn-group btn-group-toggle" data-toggle="buttons">
                    <label class="btn btn-outline-secondary active btn_select_time p2" (click)="DayStats($event)">
                        <input type="radio" name="options" id="option1" autocomplete="off" checked> Jour
                    </label>
                    <label class="btn btn-outline-secondary btn_select_time p2" (click)="MonthStats($event)">
                        <input type="radio" name="options" id="option2" autocomplete="off"> Mois
                    </label>
                    <label class="btn btn-outline-secondary btn_select_time p2" (click)="YearStats($event)">
                        <input type="radio" name="options" id="option3" autocomplete="off"> Année
                    </label>
                </div>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-xl-3 col-sm-6 col-12">
                <div class="card card_radius">
                    <div class="card-content">
                        <div class="card-body d-flex flex-column pb-0">
                            <div class="d-flex flex-row justify-content-between">
                                <div class="media-body text-left">
                                    <p class="p2"> Nombre d'utilisateurs <br />(cumul)</p>
                                    <span [hidden]="hiddenUsersNbr" class="h_yello">{{usersAppNumber}}</span>
                                </div>
                                <div>
                                    <img src="../../../../../assets/img/fullstats/063-Logo-card1.svg"
                                        style="width: 60px;height: 60px;">
                                </div>
                            </div>
                            <div [hidden]="hiddenUsersNbr">
                                <p class="p2_green mb-0 pb-0 mt-2">Depuis la mise</p>
                                <p class="p2_green mt-0 pt-0 "> en ligne</p>
                            </div>
                            <div class="spinner-border text-primary"
                                style="width: 3rem; height: 3rem;margin-bottom: 50px;margin-top: 20px;"
                                [hidden]="hiddenUsersNbrSpinner" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-sm-6 col-12">
                <div class="card card_radius">
                    <div class="card-content">
                        <div class="card-body flex-column pb-0">
                            <div class="d-flex flex-row justify-content-between">
                                <div class="media-body text-left">
                                    <p class="p2">% Utilisateurs <br />actifs</p>
                                    <span [hidden]="hiddenUsersActive" class="h_green">{{userActiveAppNumber}}%</span>
                                </div>
                                <div>
                                    <img src="../../../../../assets/img/fullstats/064-Logo-card2.svg"
                                        style="width: 60px;height: 60px;">
                                </div>
                            </div>
                            <div [hidden]="hiddenUsersActive" *ngIf="userActiveAppEvolution>0">
                                <p class="p2_green mb-0 pb-0 mt-2"><span><img
                                            src="../../../../../assets/img/fullstats/070-logo-up.svg"
                                            style="width: 10px;height: 10px;"></span> {{userActiveAppEvolution}} points</p>
                                <p class="p2_green mt-0 pt-0 " *ngIf="timeType == 'DAY'">vs {{dayBefor}}</p>
                                <p class="p2_green mt-0 pt-0 " *ngIf="timeType == 'YEAR'">vs {{yearBefor}}</p>
                                <p class="p2_green mt-0 pt-0 " *ngIf="timeType == 'MONTH'">vs {{monthBefor}}</p>
                            </div>
                            <div [hidden]="hiddenUsersActive" *ngIf="userActiveAppEvolution<0">
                                <p class="p2_red mb-0 pb-0 mt-2"><span><img
                                            src="../../../../../assets/img/fullstats/069-logo-down.svg"
                                            style="width: 10px;height: 10px;"></span> {{userActiveAppEvolution}} points</p>
                                <p class="p2_red mt-0 pt-0 " *ngIf="timeType == 'DAY'">vs {{dayBefor}}</p>
                                <p class="p2_red mt-0 pt-0 " *ngIf="timeType == 'YEAR'">vs {{yearBefor}}</p>
                                <p class="p2_red mt-0 pt-0 " *ngIf="timeType == 'MONTH'">vs {{monthBefor}}</p>
                            </div>
                            <div [hidden]="hiddenUsersActive" *ngIf="userActiveAppEvolution == 0">
                                <p class="p2_gray mb-0 pb-0 mt-2"><span><img
                                            src="../../../../../assets/img/fullstats/071-logo-equal.svg"
                                            style="width: 10px;height: 10px;"></span> {{userActiveAppEvolution}} points</p>
                                <p class="p2_gray mt-0 pt-0 " *ngIf="timeType == 'DAY'">vs {{dayBefor}}</p>
                                <p class="p2_gray mt-0 pt-0 " *ngIf="timeType == 'YEAR'">vs {{yearBefor}}</p>
                                <p class="p2_gray mt-0 pt-0 " *ngIf="timeType == 'MONTH'">vs {{monthBefor}}</p>
                            </div>
                            <div class="spinner-border text-primary"
                                style="width: 3rem; height: 3rem;margin-bottom: 50px;margin-top: 20px;"
                                [hidden]="hiddenUsersActiveSpinner" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-3 col-sm-6 col-12">
                <div class="card card_radius">
                    <div class="card-content">
                        <div class="card-body d-flex flex-column pb-0">
                            <div class="d-flex flex-row justify-content-between">
                                <div class="media-body text-left">
                                    <p class="p2">Nombre de scans<br />Appli</p>
                                    <span [hidden]="hiddenScansNbr" class="h_blue">{{scanAppNumber}}</span>
                                </div>
                                <div>
                                    <img src="../../../../../assets/img/appstats/067-Logo-card5.svg"
                                        style="width: 60px;height: 60px;">
                                </div>
                            </div>
                            <div [hidden]="hiddenScansNbr" *ngIf="scanAppEvolution>0">
                                <p class="p2_green mb-0 pb-0 mt-2"><span><img
                                            src="../../../../../assets/img/fullstats/070-logo-up.svg"
                                            style="width: 10px;height: 10px;"></span>{{scanAppEvolution}}%</p>
                                <p class="p2_green mt-0 pt-0 " *ngIf="timeType == 'DAY'">vs {{dayBefor}}</p>
                                <p class="p2_green mt-0 pt-0 " *ngIf="timeType == 'YEAR'">vs {{yearBefor}}</p>
                                <p class="p2_green mt-0 pt-0 " *ngIf="timeType == 'MONTH'">vs {{monthBefor}}</p>
                            </div>
                            <div [hidden]="hiddenScansNbr" *ngIf="scanAppEvolution<0">
                                <p class="p2_red mb-0 pb-0 mt-2"><span><img
                                            src="../../../../../assets/img/fullstats/069-logo-down.svg"
                                            style="width: 10px;height: 10px;"></span>{{scanAppEvolution}}%</p>
                                <p class="p2_red mt-0 pt-0 " *ngIf="timeType == 'DAY'">vs {{dayBefor}}</p>
                                <p class="p2_red mt-0 pt-0 " *ngIf="timeType == 'YEAR'">vs {{yearBefor}}</p>
                                <p class="p2_red mt-0 pt-0 " *ngIf="timeType == 'MONTH'">vs {{monthBefor}}</p>
                            </div>
                            <div [hidden]="hiddenScansNbr" *ngIf="scanAppEvolution==0">
                                <p class="p2_gray mb-0 pb-0 mt-2"><span><img
                                            src="../../../../../assets/img/fullstats/071-logo-equal.svg"
                                            style="width: 10px;height: 10px;"></span>{{scanAppEvolution}}%</p>
                                <p class="p2_gray mt-0 pt-0 " *ngIf="timeType == 'DAY'">vs {{dayBefor}}</p>
                                <p class="p2_gray mt-0 pt-0 " *ngIf="timeType == 'YEAR'">vs {{yearBefor}}</p>
                                <p class="p2_gray mt-0 pt-0 " *ngIf="timeType == 'MONTH'">vs {{monthBefor}}</p>
                            </div>
                            <div class="spinner-border text-primary"
                                style="width: 3rem; height: 3rem;margin-bottom: 50px;margin-top: 20px;"
                                [hidden]="hiddenScansNbrSpinner" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-sm-6 col-12">
                <div class="card card_radius">
                    <div class="card-content">
                        <div class="card-body d-flex flex-column pb-0">
                            <div class="d-flex flex-row justify-content-between">
                                <div class="media-body text-left">
                                    <p class="p2">Nombre de km <br />voiture économisés</p>
                                    <span [hidden]="hiddenKlmVoiture" class="h_red"
                                        *ngIf="contractstats != undefined">{{contractstats.kmVoiture}}</span>
                                </div>
                                <div>
                                    <img src="../../../../../assets/img/appstats/068-Logo-card6.svg"
                                        style="width: 60px;height: 60px;">
                                </div>
                            </div>
                            <div [hidden]="hiddenKlmVoiture">
                                <p class="p2_green mb-0 pb-0 mt-2">Depuis la mise </p>
                                <p class="p2_green mt-0 pt-0 ">en ligne</p>
                            </div>
                            <div class="spinner-border text-primary"
                                style="width: 3rem; height: 3rem;margin-bottom: 50px;margin-top: 20px;"
                                [hidden]="hiddenKlmVoitureSpinner" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-5">
            <div class="col-xl-6 col-sm-12 col-12">
                <div class="card card_radius">
                    <div class="card-content">
                        <div class="card-body d-flex flex-column pb-0">
                            <div class="d-flex flex-row justify-content-between">
                                <div class="media-body text-left">
                                    <p class="h3">Nombre de scans Appli</p>
                                </div>

                            </div>

                            <google-chart id="chartNCA" #NSAChart (ready)="nsaChartReady(NSAChart)"
                                [dynamicResize]="true" style="width: 100%;" [hidden]="hiddenNSAChart"
                                [type]="NSAChartType" [data]="NSAChartData" [options]="NSAChartOptions">
                            </google-chart>
                            <div class="text-center">
                                <div class="spinner-border text-primary"
                                    style="width: 3rem; height: 3rem;margin-bottom: 75px;margin-top: 75px;"
                                    [hidden]="hiddenNSASpinner" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-sm-12 col-12">
                <div class="card card_radius">
                    <div class="card-content">
                        <div class="card-body flex-column pb-0">
                            <div class="d-flex flex-row justify-content-between">
                                <div class="media-body text-left">
                                    <p class="h3">Taux de reconnaissance</p>
                                </div>
                            </div>

                            <div class="d-flex flex-row justify-content-around">
                                <div class="d-flex flex-column justify-content-center align-items-center">
                                    <p class="h_gray">produits</p>
                                    <div class="d-flex flex-column justify-content-center align-items-center"
                                        id="JSFiddle2">
                                        <google-chart style="width: 100%;" [type]="TRPChartType"
                                            [hidden]="hiddenTRPChart" [data]="TRPChartData" [options]="TRPChartOptions">
                                        </google-chart>
                                        <div id="cnt" class="overlay_device_recognising" [hidden]="hiddenTRPChart">{{TRPvalue}}
                                        </div>

                                    </div>
                                    <div class="text-center">
                                        <div class="spinner-border text-primary"
                                            style="width: 3rem; height: 3rem;margin-bottom: 55px;margin-top: 55px;"
                                            [hidden]="hiddenTRPSpinner" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>

                                </div>
                                <div class="d-flex flex-column justify-content-center align-items-center">
                                    <p class="h_gray">Consigne de tri</p>
                                    <div class="d-flex flex-column justify-content-center align-items-center"
                                        id="JSFiddle2">
                                        <google-chart style="width: 100%;" [type]="TRCChartType"
                                            [hidden]="hiddenTRCChart" [data]="TRCChartData" [options]="TRCChartOptions">
                                        </google-chart>
                                        <div id="cnt" class="overlay_device_recognising" [hidden]="hiddenTRCChart">
                                            {{TRCvalue}}</div>
                                    </div>
                                    <div class="text-center">
                                        <div class="spinner-border text-primary"
                                            style="width: 3rem; height: 3rem;margin-bottom: 55px;margin-top: 55px;"
                                            [hidden]="hiddenTRCSpinner" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="row mt-5">
            <div class="col-xl-12">
                <div class="card card_radius">
                    <div class="card-content">
                        <div class="card-body d-flex flex-column pb-0">
                            <div class="d-flex flex-row justify-content-between">
                                <div class="media-body text-left">
                                    <p class="h3">Nombre de nouveaux utilisateurs</p>
                                </div>

                            </div>
                                <google-chart style="width: 92%;" id="NNUChart" #NNUChartView
                                    (ready)="nnuChartReady(NNUChartView)" [hidden]="hiddenNNUChart"
                                    [dynamicResize]="true" [type]="NNUChartType" [data]="NNUChartData"
                                    [options]="NNUChartOptions">
                                </google-chart>

                                <div class="d-flex flex-row justify-content-center w-100">
                                    <div class="spinner-border text-primary"
                                        style="width: 3rem; height: 3rem;margin-bottom: 230px;margin-top: 230px;"
                                        [hidden]="hiddenNNUSpinner" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>


                        </div>
                    </div>
                </div>
            </div>

        </div>


        <div class="row mt-5">
            <div class="col-xl-6 col-sm-12 col-12">
                <div class="card card_radius" style="height: 400px;">
                    <div class="card-content">
                        <div class="card-body flex-column pb-0">

                            <div class="d-flex flex-row justify-content-between">
                                <div class="media-body text-left">
                                    <p class="h3">Répartition compte utilisateur / anonyme</p>
                                </div>
                            </div>

                            <div class="d-flex flex-row justify-content-around">
                                <div class="d-flex flex-column justify-content-center align-items-center">
                                    <div class="d-flex flex-column justify-content-center align-items-center"
                                        id="JSFiddle3">
                                        <google-chart style="width: 100%; margin-bottom: 0px;" [hidden]="hiddenRCUChart"
                                            [type]="RCUAChartType" [data]="RCUAChartData" [options]="RCUAChartOptions">
                                        </google-chart>
                                        <div id="cnt" class="overlay3" [hidden]="hiddenRCUChart">{{RCUvalue}}</div>
                                    </div>
                                    <div class="text-center">
                                        <div class="spinner-border text-primary"
                                            style="width: 3rem; height: 3rem;margin-bottom: 160px;margin-top: 160px;"
                                            [hidden]="hiddenRCUSpinner" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-6 col-sm-12 col-12">
                <div class="card card_radius" style="height: 400px;">
                    <div class="card-content">
                        <div class="card-body flex-column pb-0">

                            <div class="d-flex flex-row justify-content-between">
                                <div class="media-body text-left">
                                    <p class="h3">Taux de rétention à 30 jours</p>
                                </div>
                            </div>

                            <div class="d-flex flex-row justify-content-around">
                                <div class="d-flex flex-column justify-content-center align-items-center">
                                    <div class="d-flex flex-column justify-content-center align-items-center"
                                        id="JSFiddle3">
                                        <google-chart style="width: 100%; margin-bottom: 0px;"
                                            [hidden]="hiddenTR30Chart" [type]="TR30ChartType" [data]="TR30ChartData"
                                            [options]="TR30ChartOptions"></google-chart>
                                        <div id="cnt" class="overlay3" [hidden]="hiddenTR30Chart">{{TR30value}}</div>
                                    </div>
                                    <div class="text-center">
                                        <div class="spinner-border text-primary"
                                            style="width: 3rem; height: 3rem;margin-bottom: 160px;margin-top: 160px;"
                                            [hidden]="hiddenTR30pinner" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>

<div class="row mt-5 ml-4 mr-4 mb-3" *ngIf="reporting_status">
    <div class="col-12">
        <div class="row ">
            <div class="col-xl-4">
            </div>
            <div class="col-xl-4 d-flex flex-row justify-content-center align-items-center">
                <div class="d-flex flex-column align-items-center justify-content-center  h-100" (click)="MoveLet()">
                    <div class="cercle_fleche">
                        <i class="fa fa-chevron-left move_date"></i>
                    </div>
                </div>
                <div class="date mr-5 ml-5 d-flex flex-column justify-content-center  align-items-center h-100 bg-red"  *ngIf="timeType == 'DAY'">
                    {{date}}
                </div>
                <div class="date mr-5 ml-5 d-flex flex-column justify-content-center  align-items-center h-100 bg-red"  *ngIf="timeType == 'MONTH'">
                    {{months[this.current_date.getMonth()]}}
                </div>
                <div class="date mr-5 ml-5 d-flex flex-column justify-content-center  align-items-center h-100 bg-red"  *ngIf="timeType == 'YEAR'">
                    {{this.current_date.getFullYear()}}
                </div>
                <div class="d-flex flex-column align-items-center justify-content-center  h-100" (click)="MoveRight()">
                    <i class="fa fa-chevron-right move_date"></i>
                </div>
            </div>
            <div class="col-xl-4 d-flex flex-row justify-content-xl-end justify-content-center mt-5 mt-xl-0">
                <div class="btn-group btn-group-toggle" data-toggle="buttons">
                    <label [className]="timeType == 'DAY' ? 'btn btn-outline-secondary active btn_select_time p2' : 'btn btn-outline-secondary btn_select_time p2'" (click)="DayStats()">
                        <input type="radio" name="options" id="option1" autocomplete="off" checked> Jour
                    </label>
                    <label [className]="timeType == 'MONTH' ? 'btn btn-outline-secondary active btn_select_time p2' : 'btn btn-outline-secondary btn_select_time p2'" (click)="MonthStats()">
                        <input type="radio" name="options" id="option2" autocomplete="off"> Mois
                    </label>
                    <label [className]="timeType == 'YEAR' ? 'btn btn-outline-secondary active btn_select_time p2' : 'btn btn-outline-secondary btn_select_time p2'" (click)="YearStats()">
                        <input type="radio" name="options" id="option3" autocomplete="off"> Année
                    </label>
                </div>
            </div>
        </div>

        <div class="row mt-5">

            <div class="col-xl-8 col-sm-12 col-12">
                <div class="card card_radius" style="height: 350px;">
                    <div class="card-content">
                        <div class="card-body d-flex flex-column pb-0">
                            <div class="d-flex flex-row justify-content-between">
                                <div class="media-body text-left">
                                    <p class="h3">Evolutions des demandes et signalements</p>
                                </div>
                               
                                <select class="custom-select ml-3"
                                    style="width: auto;box-shadow: none;border: 1px solid #B9CEF9; color: black;"
                                    [(ngModel)]="EDSselectedType" (ngModelChange)="loadEDS()" >
                                    <ng-container *ngFor='let item of elementArray; let rowIndex = index'>
                                        
                                            <option  [ngValue]="rowIndex">{{item[1]}}</option>
                                       
                                    </ng-container>
                                    <option [ngValue]="1000">Tous</option>
                                </select>
    
                            </div>
                            <div >
                                <google-chart id="chartNCA" #EDSChart [dynamicResize]="true" style="width: 100%;"
                                    (ready)="edsChartReady(EDSChart)" [hidden]="false" [type]="EDSChartType"
                                    [data]="EDSChartData" [options]="EDSChartOptions">

                                </google-chart>

                                <div class="text-center">
                                    <div class="spinner-border text-primary"
                                        style="width: 3rem; height: 3rem;margin-bottom: 75px;margin-top: 75px;"
                                        [hidden]="false" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="d-flex flex-row justify-content-center w-100  p2" style="margin-top: 100px;"
                                *ngIf="reportingCurrent.length == 0">
                                Pas de demandes et signalements
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-4 col-sm-12 col-12 mt-xl-0 mt-3">
                <div class="card card_radius" style="height: 350px;">
                    <div class="card-content">
                        <div class="card-body flex-column pb-0">

                            <div class="d-flex flex-row justify-content-between">
                                <div class="media-body text-left">
                                    <p class="h3">Répartition par types de demandes et signalements</p>
                                </div>
                            </div>

                            <div class="d-flex flex-row justify-content-around"
                                *ngIf="RTDS !='Pas de Demandes et signalements'">
                                <div class="d-flex flex-column justify-content-center align-items-center">
                                    <div class="d-flex flex-column justify-content-center align-items-center"
                                        id="JSFiddle3">
                                        <google-chart style="width: 100%; margin-bottom: 0px;" [hidden]="false"
                                            [type]="RTDSChartType" #RTDSChart [dynamicResize]="true"
                                            [data]="RTDSChartDataTest" [options]="RTDSChartOptions">
                                        </google-chart>
                                        <div id="cnt" [ngStyle]="{'color': RTDSColor}" class="overlay3 "
                                            [hidden]="false">{{RTDS}}</div>
                                    </div>
                                    <div class="text-center">
                                        <div class="spinner-border text-primary"
                                            style="width: 3rem; height: 3rem;margin-bottom: 160px;margin-top: 160px;"
                                            [hidden]="true" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="d-flex flex-row justify-content-center w-100  p2" style="margin-top: 100px;"
                                *ngIf="RTDS =='Pas de Demandes et signalements'">
                                Pas de demandes et signalements
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-5">
            <div class="col-lg-12">
                <div class="card card_radius">
                    <div class="card-content">
                        <div class="card-body d-flex flex-column pb-0">
                            <div class="d-flex flex-row justify-content-between">

                                <div class="media-body text-left">
                                    <p class="h3">Cartographie des demande et signalements</p>
                                </div>
                            </div>
                            <div class="row pl-2">
                                <div class="col-xl-2 ">
                                    <div class="row" *ngFor='let item of items[0] | keyvalue '>
                                        <div class="col-xl-12 col-lg-6 col-md-4 pt-5" *ngIf='item.value.active'>
                                            <div class="row">
                                                <div style="border-radius: 5px 0  0 5px !important;
                                                            height: 40px;" id="nameCat"
                                                    class="col-6 p2 d-flex flex-row justify-content-start pl-1" 
                                                    [ngStyle]="{'backgroundColor': item.value.colorBo+'33', color : item.value.colorBo} ">
                                                    {{lenCheck(item.key)}}
                                                </div>

                                                <div style="border-radius: 0 5px 5px 0 !important;
                                                            height: 40px;
                                                            font-family: avenir_heavy;"
                                                class="col-3 p2  d-flex flex-row align-items-center justify-content-center"
                                                [ngStyle]="{'backgroundColor': item.value.colorBo, color : 'white'}">
                                                    {{spreadLength(item.value)
                                                    }}
                                                </div>


                                                <div
                                                    class="col-3 d-flex flex-row align-items-center justify-content-center">
                                                    <i *ngIf="!item.value.show" class="fa fa-eye-slash fa-2x"
                                                        aria-hidden="true" style="color: #B0B0B0;"
                                                        (click)="item.value.show = true;checkClusterOrMarker()"></i>
                                                    <i *ngIf="item.value.show" class="fa fa-eye fa-2x"
                                                        aria-hidden="true" style="color:  #F47000;"
                                                        (click)="item.value.show = false;checkClusterOrMarker()"></i>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-xl-12 col-lg-6 col-md-4 pt-3" style="margin-bottom: 20px;">
                                        <div class="row">
                                            <div
                                                class="col-9 p2 d-flex flex-row align-items-center justify-content-start pl-0">
                                                Afficher cas traités
                                            </div>
                                        </div>
                                        <div class="row mt-2">
                                            <div class="col-9 p-0 btn-group" role="group" id="cas_traiter">
                                                <input type="radio" name="cas_traiter" [value]="true"
                                                    [(ngModel)]="display_resolu" (ngModelChange)="checkClusterOrMarker()"
                                                    id='radiobtoui' />
                                                <label class="btn btn-secondary radius_left_reporting pt-2"
                                                    id='radiooui' for='radiobtoui'>
                                                    Oui
                                                </label>
                                                <input type="radio" [value]="false" [(ngModel)]="display_resolu"
                                                    (ngModelChange)="checkClusterOrMarker()" name="cas_traiter"
                                                    class="radio_input radio_non" id='radiobtnon' />
                                                <label class="btn btn-secondary radius_right_reporting pt-2"
                                                    id="radion" for='radiobtnon'>
                                                    Non
                                                </label>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div
                                                class="col-9 p2 d-flex flex-row align-items-center justify-content-start pl-0">
                                                Afficher cas archivés
                                            </div>
                                        </div>
                                        <div class="row mt-2">
                                            <div class="col-9 p-0 btn-group" role="group" id="cas_archive">
                                                <input type="radio" name="cas_archive" [value]="true"
                                                    [(ngModel)]="display_archive" (ngModelChange)="checkClusterOrMarker()"
                                                    id='radiobtouiarchive' />
                                                <label class="btn btn-secondary radius_left_reporting pt-2"
                                                    id='radioouiarchive' for='radiobtouiarchive'>
                                                    Oui
                                                </label>
                                                <input type="radio" [value]="false" [(ngModel)]="display_archive"
                                                    (ngModelChange)="checkClusterOrMarker()" name="cas_archive"
                                                    class="radio_input radio_non" id='radiobtnonarchive' />
                                                <label class="btn btn-secondary radius_right_reporting pt-2"
                                                    id="radiononarchive" for='radiobtnonarchive'>
                                                    Non
                                                </label>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div
                                                class="col-9 p2 d-flex flex-row align-items-center justify-content-start pl-0">
                                                Afficher par groupes
                                            </div>
                                        </div>
                                        <div class="row mt-2">
                                            <div class="col-9 p-0 btn-group" role="group" id="cas_cluster">
                                                <input type="radio" name="cas_cluster" [value]="true"
                                                    [(ngModel)]="display_cluster" (ngModelChange)="checkClusterOrMarker()"
                                                    id='radiobtouicluster' />
                                                <label class="btn btn-secondary radius_left_reporting pt-2"
                                                    id='radioooui' for='radiobtouicluster'>
                                                    Oui
                                                </label>
                                                <input type="radio" [value]="false" [(ngModel)]="display_cluster"
                                                    (ngModelChange)="checkClusterOrMarker()" name="cas_cluster"
                                                    class="radio_input radio_non" id='radiobtnoncluster' />
                                                <label class="btn btn-secondary radius_right_reporting pt-2"
                                                    id="radiion" for='radiobtnoncluster'>
                                                    Non
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-10 pl-3 pr-3 pt-5 pb-4">
                                    <google-map [zoom]="zoom" style="width: 100%;border-radius:20px;" [center]="center"
                                        [options]="optionsMap"  height="695px" width="100%">
                                        <ng-container *ngIf='!display_cluster'>
                                             <map-marker #markerElem *ngFor="let marker of markers"  (mapClick)="markerClicked(marker)"
                                            [position]="marker.position" [label]="marker.label" [title]="marker.title"
                                            [options]="marker.options">
                                            </map-marker>
                                        </ng-container>
                                    </google-map>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-5">

            <div class="col-xl-4 col-sm-12 col-12 mt-xl-0 mt-3">
                <div class="card card_radius" style="height: 400px;">
                    <div class="card-content">
                        <div class="card-body flex-column pb-0">

                            <div class="d-flex flex-row justify-content-between">
                                <div class="media-body text-left">
                                    <p class="h3">Statut des demandes et signalements en cours</p>
                                </div>
                            </div>

                            <div class="d-flex flex-row justify-content-around"
                                *ngIf="SDSC !='Pas de Demandes et signalements'">
                                <div class="d-flex flex-column justify-content-center align-items-center">
                                    <div class="d-flex flex-column justify-content-center align-items-center"
                                        id="JSFiddle3">
                                        <google-chart style="width: 100%; margin-bottom: 0px;" [hidden]="false"
                                            [type]="SDSCChartType" [data]="SDSCChartData" [options]="SDSCChartOptions">
                                        </google-chart>
                                        <div id="cnt" [ngStyle]="{'color': SDSCColor}" class="overlay3"
                                            [hidden]="false">{{SDSC}}</div>
                                    </div>
                                    <div class="text-center">
                                        <div class="spinner-border text-primary"
                                            style="width: 3rem; height: 3rem;margin-bottom: 160px;margin-top: 160px;"
                                            [hidden]="true" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="d-flex flex-row justify-content-center w-100  p2" style="margin-top: 120px;"
                                *ngIf="SDSC =='Pas de Demandes et signalements'">
                                Pas de demandes et signalements
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-4 col-sm-12 col-12 mt-xl-0 mt-3">
                <div class="card card_radius" style="height: 400px;">
                    <div class="card-content">
                        <div class="card-body flex-column pb-0">
    
                            <div class="d-flex flex-row justify-content-between">
                                <div class="media-body text-left">
                                    <p class="h3">Moyenne des délais par types de demandes et signalements</p>
                                </div>
                            </div>

                            <select class="custom-select ml-3"
                                    style="width: auto;box-shadow: none;border: 1px solid #B9CEF9; color: black;"
                                    [(ngModel)]="MDDSselectedType" (ngModelChange)="loadMDDS()" >
                                    <ng-container *ngFor='let item of elementArray; let rowIndex = index'>
                                        
                                            <option  [ngValue]="rowIndex">{{item[1]}}</option>
                                       
                                    </ng-container>
                                    <option [ngValue]="1000">Tous</option>
                                </select>
    
                            <div class="d-flex flex-row justify-content-around"
                                *ngIf="MDDSChartData.length != 0">
                                <div class="d-flex flex-column justify-content-center align-items-center">
                                    <div class="d-flex flex-column justify-content-center align-items-center"
                                        id="JSFiddle3">
                                        <google-chart style="width: 100%; margin-bottom: 0px;" [hidden]="false"
                                            [type]="RTDSChartType" #RTDSChart [dynamicResize]="true"
                                            [data]="MDDSChartData" [options]="MDDSChartOptions">
                                        </google-chart>
                                        <div id="cnt" [ngStyle]="{'color': MDDSColor}" class="overlay3 "
                                            [hidden]="false">{{MDDS}}</div>
                                    </div>
                                    <div class="text-center">
                                        <div class="spinner-border text-primary"
                                            style="width: 3rem; height: 3rem;margin-bottom: 160px;margin-top: 160px;"
                                            [hidden]="true" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>
    
                                </div>
                            </div>
    
                            <div class="d-flex flex-row justify-content-center w-100  p2" style="margin-top: 100px;"
                                *ngIf="MDDSChartData.length === 0">
                                Pas de demandes et signalements
                            </div>
    
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-4 col-sm-12 col-12">
                <div class="card card_radius" style="min-height: 400px;">
                    <div class="card-content">
                        <div class="card-body d-flex flex-column pb-0">
                            <div class="d-flex flex-row justify-content-between">
                                <div class="media-body text-left">
                                    <p class="h3">Liste des demandes et signalements</p>
                                </div>
                            </div>
                            <div *ngIf="reportingCurrent.length > 0">
                                <table class="table table-hover mt-3">
                                    <tbody>
                                        <tr *ngFor="let item of reporting | slice:0:5" (click)="updateReport(item)">
                                            <td>
                                                <img src="../../../../../assets/img/reportingstats/084-statut-todo.png"
                                                    style="width: 40px; height: 40px;" *ngIf="item.status=='a_traiter'">
                                                <img src="../../../../../assets/img/reportingstats/082-statut-ok.png"
                                                    style="width: 40px; height: 40px;" *ngIf="item.status=='traiter'">
                                                <img src="../../../../../assets/img/reportingstats/083-statut-ongoing.png"
                                                    style="width: 40px; height: 40px;" *ngIf="item.status=='en_cours'">
                                                <img src="../../../../../assets/img/reportingstats/iconboautres.png"
                                                style="width: 40px; height: 40px;" *ngIf="item.status=='archiver'">
                                            </td>
                                            <td style="max-width: 200px;">
                                                <div class="d-flex flex-column justify-content-center">
                                                    <p class="d-inline-block text-truncate pb-0 mb-0  h3">
                                                        {{capitalize(item.type.toLocaleLowerCase())}}</p>
                                                    <p class="d-inline-block text-truncate pb-0 mb-0 mt-0 p2" *ngIf="getValue(item.formulaires, 'Motif') != 'Sans motif'">
                                                        {{capitalize(getValue(item.formulaires, 'Motif'))}}</p>
                                                </div>
                                            </td>
                                    

                                        </tr>
                                    </tbody>
                                </table>

                                <div class="d-flex flex-row justify-content-between">
                                    <div class="media-body text-left">
                                        <a [routerLink]="['/montri/reportinglist', { filter: timeType }]" class="p2" routerLinkActive="active">>
                                            Voir la liste complète</a>
                                    </div>
                                </div>

                                <div class="text-center">
                                    <div class="spinner-border text-primary"
                                        style="width: 3rem; height: 3rem;margin-bottom: 75px;margin-top: 75px;"
                                        [hidden]="true" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex flex-row justify-content-center w-100  p2" style="margin-top: 120px;"
                                *ngIf="reportingCurrent.length == 0">
                                Pas de demandes et signalements
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>

    </div>
</div>

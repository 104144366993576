<div class="container-fluid mt-5 pl-5" >
    <div class="row">
        <div class="col-md-4 ">
            <div class= "row" style="flex-wrap: unset;">
                <div class="col-lg-6 mt-lg-0 mt-3">
                        <button type="button" (click)="gotourl('/montri/cartsettings/coordinate')"   [ngStyle]="{
                            'background-color': sectionname == 'cord' ? 'rgb(244, 112,0)':'rgba(244, 112,0,0.3)',
                            'outline': 'none !i&²mportant',
                            'box-shadow': 'none',
                            'font-size': sectionname == 'cord' ? '14px' : '14px',
                            'font-family': sectionname == 'cord' ? 'avenir_heavy' : 'avenir_light',
                            'color': sectionname == 'cord' ? 'white' : 'black',
                            'border':'none'
                        }" class="btn btn-primary w-100 h3_heavy" data-toggle="button" aria-pressed="false" autocomplete="off">Coordonnées</button>
                </div>
                <div class="col-lg-6">
                        <button type="button" (click)="gotourl('/montri/cartsettings/flux')"   [ngStyle]="{'background-color': sectionname == 'flux' ? 'rgb(244, 112,0)':'rgba(244, 112,0,0.3)',
                            'outline': 'none !important',
                            'box-shadow': 'none',
                            'font-size': sectionname == 'flux' ? '14px' : '14px',
                            'font-family': sectionname == 'flux' ? 'avenir_heavy' : 'avenir_light',
                            'color': sectionname == 'flux' ? 'white' : 'black',
                            'border':'none'
                        }" class="btn btn-primary w-100 h3_heavy" data-toggle="button" aria-pressed="false" autocomplete="off">Sélection des flux</button>
                </div>
                <div class="col-lg-6">
                    <button type="button" (click)="gotourl('/montri/cartsettings/filtre')"   [ngStyle]="{'background-color': sectionname == 'filtre' ? 'rgb(244, 112,0)':'rgba(244, 112,0,0.3)',
                        'outline': 'none !important',
                        'box-shadow': 'none',
                        'font-size': sectionname == 'filtre' ? '14px' : '14px',
                        'font-family': sectionname == 'filtre' ? 'avenir_heavy' : 'avenir_light',
                        'color': sectionname == 'filtre' ? 'white' : 'black',
                        'border':'none'
                    }" class="btn btn-primary w-100 h3_heavy" data-toggle="button" aria-pressed="false" autocomplete="off">Filtre</button>
                 </div>
            </div>
        </div>

    </div>
   <div class="row mt-4">
        <div class="col-12">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>


<div class="modal-content  border_modal" >
    <div class="modal-header d-flex flex-column" style='border: none;'>
        <P class="avenir_light form-check-label">Vous êtes sur le point de supprimer votre actualité. Souhaitez-vous confirmer ?</P>
    </div>
    <div class="modal-footer" style='border: none;'>
        <button type="button" class="btn btn-secondary button3" (click)="closeModal()">NON</button>
        <button type="submit" class="btn btn-primary button1" (click)="deleteactus()">OUI</button>
    </div>
</div>

import { timeStamp } from 'console';
import { Actu } from './../../../../models/actus/actus';
import { ActusService } from './../../../../services/actus/actus.service';
import { NgbActiveModal, NgbDate, NgbDateParserFormatter, NgbTimeAdapter, NgbTimeStruct, NgbCalendar, NgbModalOptions, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Contrat } from './../../../../models/contrat/contrat';
import { ContratService } from './../../../../services/contrat/contrat.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { AlertService } from './../../../../services/alert/alert.service';
import { LodingBarService } from './../../../../services/lodingbar/loding-bar-service.service';
import { Component, OnInit, Input, Injectable } from '@angular/core';
import * as firebase from "firebase";
import * as moment from 'moment';
import { UserBo } from 'src/app/models/user/user';
import { UserService } from 'src/app/services/user/user.service';
import { ImgReportPopupComponent } from 'src/app/modules/contractstats/modals/imgReportPopup/imgReportPopup.component';
import { ToastAlertsComponent } from 'src/app/modules/main/components/toast-alerts/toast-alerts.component';


const pad = (i: number): string => i < 10 ? `0${i}` : `${i}`;

/**
 * Example of a String Time adapter
 */
@Injectable()
export class NgbTimeStringAdapter extends NgbTimeAdapter<string> {

  fromModel(value: string| null): NgbTimeStruct | null {
    if (!value) {
      return null;
    }
    const split = value.split(':');
    return {
      hour: parseInt(split[0], 10),
      minute: parseInt(split[1], 10),
      second: parseInt(split[2], 10)
    };
  }

  toModel(time: NgbTimeStruct | null): string | null {
    return time != null ? `${pad(time.hour)}:${pad(time.minute)}:${pad(time.second)}` : null;
  }
}

@Injectable({
    providedIn: 'root',
})
export class NgbDateMomentParserFormatter extends NgbDateParserFormatter {
    constructor() {
        super();
    };
    format(date: NgbDate): string {
        if (date === null) {
            return 'DD/MM/YYYY';
        }
        let d = moment({ year: date.year,
                         month: date.month - 1,
                         date: date.day });
        return d.isValid() ? d.format('DD/MM/YYYY') : '';
    }

    parse(value: string): NgbDate {
        if (!value) {
            return null;
        }
        let d = moment(value, 'DD/MM/YYYY');
        return d.isValid() ? new NgbDate(d.year(),d.month()+1,d.date()) : null}

    formatTwoDate(datefrom: NgbDate,dateto: NgbDate): string {
        if (datefrom === null || dateto === null) {
            return '';
        }
        let dfrom = moment({ year: datefrom.year,
                         month: datefrom.month - 1,
                         date: datefrom.day });
         let dto = moment({ year: dateto.year,
                         month: dateto.month - 1,
                         date: dateto.day });
        return dfrom.isValid() && dto.isValid() ? "Du "+dfrom.format('DD/MM/YYYY') +" au " + dto.format('DD/MM/YYYY'): '';
    }

}

@Component({
    selector: 'app-actusinfos',
    templateUrl: './actusinfos.component.html',
    styleUrls: ['./actusinfos.component.scss']
})
export class ActusinfosComponent implements OnInit {

    @Input() actus: Actu[];
    @Input() actusid: number;
    @Input() idContrat : string
    actu: Actu;
    contrat : Contrat;
    image_file : File;
    user: UserBo;
    imageactuserror :boolean =  false;
    titleactuserror : boolean = false ;
    descriptionactuserror : boolean = false;
    urlactuserror : boolean = false ;
    actuImageUrl : string = ''
    hoveredDate: NgbDate | null = null;
    fromDate: NgbDate;
    toDate: NgbDate | null = null;
    dateDebut
    dateFin
 

    constructor(
        private lodingbarservice: LodingBarService,
        private alertservice: AlertService,
        private userservice : UserService,
        private afStorage: AngularFireStorage,
        private contratservice : ContratService,
        public activeModal: NgbActiveModal,
        private actusservice : ActusService,
        private calendar: NgbCalendar,
        public formatter: NgbDateMomentParserFormatter,
        private modalService: NgbModal,
        private toastr: ToastAlertsComponent
    ) { 
        this.fromDate = calendar.getToday();
        this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);
    }

    ngOnInit(): void {
        this.userservice.user_obs.subscribe((user)=>{
            this.user =  user
            this.contratservice.contrat_obs.subscribe((contrat)=>{
                this.contrat =  contrat
                if (this.actusid == null) {
                    var actu_temp: Actu = new Actu()
                    this.dateDebut = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day)
                    actu_temp.dateDebut = firebase.firestore.Timestamp.fromDate(this.dateDebut)
                    actu_temp.date =  new firebase.firestore.Timestamp(firebase.firestore.Timestamp.now().seconds,firebase.firestore.Timestamp.now().nanoseconds);
                    actu_temp.description = ""
                    actu_temp.epingler = false
                    actu_temp.image = ""
                    actu_temp.titre = ""
                    actu_temp.url = ""
                    this.actu = {...actu_temp}
                }
                else {
                    this.actu = { ...this.actus[this.actusid] }
                    if(this.actu.dateFin){
                        this.dateFin = this.tsToNgbDate(this.actu.dateFin)
                      
                    }
                    // if(this.actu.dateDebut){
                    //     this.dateDebut = this.tsToNgbDate(this.actu.dateDebut)
                    
                    // }
                }
            })
        })
    }

    show(){
       let options: NgbModalOptions = {};
       options.centered = true
       options.size='lg'
       //options.windowClass = 'limitSize'
       const modalRef = this.modalService.open(ImgReportPopupComponent,options)
       modalRef.componentInstance.reportImageUrl = this.actuImageUrl;
    }

    tsToNgbDate(ts){
        return {
            year: ts.toDate().getFullYear(),
            month: ts.toDate().getMonth() + 1,
            day: ts.toDate().getDate()
          };
    }

    NgbDateToTimestamp(date: NgbDate) {
        return new firebase.firestore.Timestamp(date.year, date.month - 1);
    }

    addDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
      }

    sendactusimage(file: File){
        this.imageactuserror = false
        this.image_file = file;
    }

    onChange() {

    }

    onchange($event){

    }

    onDateSelection(date: NgbDate) {
        if (!this.fromDate && !this.toDate) {
          this.fromDate = date;
          this.dateDebut = this.fromDate
        } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
          this.toDate = date;
          this.dateFin = this.toDate
        } else {
          this.toDate = null;
          this.fromDate = date;
          this.dateDebut = this.fromDate
          this.dateFin = this.toDate
        }
      }

      isHovered(date: NgbDate) {
        return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
      }

      isInside(date: NgbDate) {
        return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
      }

      isRange(date: NgbDate) {
        return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
      }

      validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
        const parsed = this.formatter.parse(input);
        return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
      }

    async saveactus(){
        if(this.actu.url != "" && this.actu.url.length > 0){
            this.actu.url = this.actu.url.trim() 
        }
        if(this.actu.image == "" && this.image_file == undefined){
            this.imageactuserror = true ;
            this.toastr.openErrorToast("L'image de l'actualité est obligatoire",'')
        }
        else if(this.actu.titre == ""){
            this.titleactuserror = true ;
            this.toastr.openErrorToast("Certains champs n'ont pas été remplis",'')
        }
        else if(this.actu.description == ""){
            this.descriptionactuserror = true ;
            this.toastr.openErrorToast("Certains champs n'ont pas été remplis",'')
        }
        // else if(this.actu.url == ""){
        //     this.urlactuserror = true ;
        //     this.toastr.openErrorToast("Certains champs n'ont pas été remplis",'')
        // }
        else{
            if(this.actusid == null){
                var  self = this;
                let img = new Image()
                var file_name :  string = 'actus'+Date.now() + Math.random()+".png"
                var ref = this.afStorage.ref(this.contrat.contrat.idContrat + "/actualites/"+file_name);
                var task = ref.put(self.image_file);
                this.actu.image = file_name
                if(this.dateDebut){
                    if(this.dateDebut.year){
                        this.dateDebut = new Date(this.dateDebut.year, this.dateDebut.month - 1, this.dateDebut.day)
                    }else{
                        this.dateDebut = new Date(this.dateDebut.getUTCFullYear(), this.dateDebut.getMonth(), this.dateDebut.getDate())
                    }
                    this.actu.dateDebut = firebase.firestore.Timestamp.fromDate(this.dateDebut)
                }
                if(this.dateFin){
                    if(this.dateFin.year){
                        this.dateFin = new Date(this.dateFin.year, this.dateFin.month - 1, this.dateFin.day)
                    }else{
                        this.dateFin = new Date(this.dateFin.getUTCFullYear(), this.dateFin.getMonth(), this.dateFin.getDate())
                    }
                    this.actu.dateFin = firebase.firestore.Timestamp.fromDate(this.dateFin);
                }else{
                    this.dateFin = new Date(this.dateDebut.getUTCFullYear(), this.dateDebut.getMonth(), this.dateDebut.getDate())
                    this.dateFin.setDate(this.dateDebut.getDate() + 10)
                    this.actu.dateFin = firebase.firestore.Timestamp.fromDate(this.dateFin);
                }
                this.actusservice.actus.value.actualites.push(this.actu)
                let status = await self.actusservice.saveActus(this.idContrat)
                    if(status === "success"){
                        this.toastr.openSuccessToast('Données enregistrées avec succès', "")
                        this.activeModal.close()
                    }else{
                        this.toastr.openErrorToast(`Chargement échoué. Veuillez verifier votre actualité.`, "")
                    }
            }
            else {
                if(this.image_file != undefined){
                    var  self = this;
                    let img = new Image()
                    var file_name :  string = this.actu.image
                    var ref = this.afStorage.ref(this.contrat.contrat.idContrat + "/actualites/"+file_name);
                    var task = ref.put(self.image_file);
                    this.actu.image = file_name
                    if(this.dateDebut){
                        if(this.dateDebut.year){
                            this.dateDebut = new Date(this.dateDebut.year, this.dateDebut.month - 1, this.dateDebut.day)
                        }else{
                            this.dateDebut = new Date(this.dateDebut.getUTCFullYear(), this.dateDebut.getMonth(), this.dateDebut.getDate())
                        }
                        this.actu.dateDebut = firebase.firestore.Timestamp.fromDate(this.dateDebut)
                    }
                    if(this.dateFin){
                        if(this.dateFin.year){
                            this.dateFin = new Date(this.dateFin.year, this.dateFin.month - 1, this.dateFin.day)
                        }else{
                            this.dateFin = new Date(this.dateFin.getUTCFullYear(), this.dateFin.getMonth(), this.dateFin.getDate())
                        }
                        this.actu.dateFin = firebase.firestore.Timestamp.fromDate(this.dateFin);
                    }else{
                        this.dateFin = new Date(this.dateDebut.getUTCFullYear(), this.dateDebut.getMonth(), this.dateDebut.getDate())
                        this.dateFin.setDate(this.dateDebut.getDate() + 10)
                        this.actu.dateFin = firebase.firestore.Timestamp.fromDate(this.dateFin);
                    }
                    this.actusservice.actus.value.actualites[this.actusid] = {...this.actu}
                    let status = await self.actusservice.saveActus(this.idContrat)
                    if(status === "success"){
                        this.toastr.openSuccessToast('Données enregistrées avec succès', "")
                        this.activeModal.close()
                    }else{
                        this.toastr.openErrorToast(`Chargement échoué. Veuillez verifier votre actualité.`, "")
                    }
                }
                else {
                    if(this.dateDebut){
                        if(this.dateDebut.year){
                            this.dateDebut = new Date(this.dateDebut.year, this.dateDebut.month - 1, this.dateDebut.day)
                        }else{
                            this.dateDebut = new Date(this.dateDebut.getUTCFullYear(), this.dateDebut.getMonth(), this.dateDebut.getDate())
                        }
                        this.actu.dateDebut = firebase.firestore.Timestamp.fromDate(this.dateDebut)
                    }
                    if(this.dateFin){
                        if(this.dateFin.year){
                            this.dateFin = new Date(this.dateFin.year, this.dateFin.month - 1, this.dateFin.day)
                        }else{
                            this.dateFin = new Date(this.dateFin.getUTCFullYear(), this.dateFin.getMonth(), this.dateFin.getDate())
                        }
                        this.actu.dateFin = firebase.firestore.Timestamp.fromDate(this.dateFin);
                    }else{
                        this.dateFin = new Date(this.dateDebut.getUTCFullYear(), this.dateDebut.getMonth(), this.dateDebut.getDate())
                        this.dateFin.setDate(this.dateDebut.getDate() + 10)
                        this.actu.dateFin = firebase.firestore.Timestamp.fromDate(this.dateFin);
                    }
                    this.actusservice.actus.value.actualites[this.actusid] = {...this.actu}
                    let status = await this.actusservice.saveActus(this.idContrat)
                    if(status === "success"){
                        this.toastr.openSuccessToast('Données enregistrées avec succès', "")
                        this.activeModal.close()
                    }else{
                        this.toastr.openErrorToast(`Chargement échoué. Veuillez verifier votre actualité.`, "")
                    }
                }

            }
        }

    }

    cancel(){
        this.activeModal.close()
    }
}

<div class="row mt-5 ml-4 mr-4 mb-3">
    <div class="col-12">
        <div class="row ">
            <div class="col-lg-4">

            </div>

            <div class="col-lg-4">
            </div>

            <div class="col-lg-4 d-flex flex-row justify-content-end">
                <div class="btn-group btn-group-toggle" data-toggle="buttons">
                    <label class="btn btn-outline-secondary active btn_select_time p2">
                        <input type="radio" name="options" id="option1" autocomplete="off"  checked> Jour
                    </label>
                    <label class="btn btn-outline-secondary btn_select_time p2">
                        <input type="radio" name="options" id="option2" autocomplete="off"> Mois
                    </label>
                    <label class="btn btn-outline-secondary btn_select_time p2">
                        <input type="radio" name="options" id="option3" autocomplete="off"> Année
                    </label>
                </div>
            </div>

        </div>
        <div class="row mt-5">
            <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                <div class="card card_radius">
                    <div class="card-content">
                        <div class="card-body d-flex flex-column pb-0">
                            <div class="d-flex flex-row justify-content-between">
                                <div class="media-body text-left">
                                    <p class="p2"> Nombre d'utilisateurs <br />(cumul)</p>
                                    <span class="h_yello">13 760</span>
                                </div>
                                <div>
                                    <img src="../../../../../assets/img/fullstats/063-Logo-card1.svg"
                                        style="width: 40px;height: 40px;">
                                </div>
                            </div>

                            <p class="p2_green mb-0 pb-0 mt-2"><span><img
                                        src="../../../../../assets/img/fullstats/070-logo-up.svg"
                                        style="width: 10px;height: 10px;"></span> 10%</p>
                            <p class="p2_green mt-0 pt-0 ">vs. Janvier 2019</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                <div class="card card_radius">
                    <div class="card-content">
                        <div class="card-body flex-column pb-0">
                            <div class="d-flex flex-row justify-content-between">
                                <div class="media-body text-left">
                                    <p class="p2">% Utilisateurs <br />actifs</p>
                                    <span class="h_green">13 760</span>
                                </div>
                                <div>
                                    <img src="../../../../../assets/img/fullstats/064-Logo-card2.svg"
                                        style="width: 40px;height: 40px;">
                                </div>
                            </div>
                            <p class="p2_green mb-0 pb-0 mt-2"><span><img
                                        src="../../../../../assets/img/fullstats/070-logo-up.svg"
                                        style="width: 10px;height: 10px;"></span> 10%</p>
                            <p class="p2_green mt-0 pt-0 ">vs. Janvier 2019</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                <div class="card card_radius">
                    <div class="card-content">
                        <div class="card-body d-flex flex-column pb-0">
                            <div class="d-flex flex-row justify-content-between">
                                <div class="media-body text-left">
                                    <p class="p2">Nombre de sessions<br /> boitiers</p>
                                    <span class="h_blue">13 760</span>
                                </div>
                                <div>
                                    <img src="../../../../../assets/img/fullstats/065-Logo-card3.svg"
                                        style="width: 40px;height: 40px;">
                                </div>
                            </div>
                            <p class="p2_green mb-0 pb-0 mt-2"><span><img
                                        src="../../../../../assets/img/fullstats/070-logo-up.svg"
                                        style="width: 10px;height: 10px;"></span> 10%</p>
                            <p class="p2_green mt-0 pt-0 ">vs. Janvier 2019</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                <div class="card card_radius">
                    <div class="card-content">
                        <div class="card-body d-flex flex-column pb-0">
                            <div class="d-flex flex-row justify-content-between">
                                <div class="media-body text-left">
                                    <p class="p2">Nombre de repas <br /> offerts</p>
                                    <span class="h_red">13 760</span>
                                </div>
                                <div>
                                    <img src="../../../../../assets/img/fullstats/066-Logo-card4.svg"
                                        style="width: 40px;height: 40px;">
                                </div>
                            </div>
                            <p class="p2_green mb-0 pb-0 mt-2"><span><img
                                        src="../../../../../assets/img/fullstats/070-logo-up.svg"
                                        style="width: 10px;height: 10px;"></span> 10%</p>
                            <p class="p2_green mt-0 pt-0 ">vs. Janvier 2019</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-5">
            <div class="col-xl-6 col-sm-12 col-12">
                <div class="card card_radius">
                    <div class="card-content">
                        <div class="card-body d-flex flex-column pb-0" id="chart1">
                            <div class="d-flex flex-row justify-content-between">
                                <div class="media-body text-left">
                                    <p class="h3">Nombre de scans Appli</p>
                                </div>
                            </div>

                                <google-chart #chart style="width: 100%;" [type]="myType" [data]="myData" [options]="options">
                                </google-chart>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-6 col-sm-12 col-12">
                <div class="card card_radius">
                    <div class="card-content">
                        <div class="card-body flex-column pb-0">
                            <div class="d-flex flex-row justify-content-between">
                                <div class="media-body text-left">
                                    <p class="h3">Taux de reconnaissance</p>
                                </div>
                            </div>

                            <div class="d-flex flex-row justify-content-around">
                                <div class="d-flex flex-column justify-content-center align-items-center">
                                    <p class="h_gray">produits</p>
                                    <div class="d-flex flex-column justify-content-center align-items-center"
                                        id="JSFiddle">
                                        <google-chart style="width: 100%;" [type]="recognitionProductchart"
                                            [data]="recognitionProductData" [options]="options2"></google-chart>
                                        <div id="cnt" class="overlay">86%</div>
                                    </div>
                                </div>
                                <div class="d-flex flex-column justify-content-center align-items-center">
                                    <p class="h_gray">Consigne de tri</p>
                                    <div class="d-flex flex-column justify-content-center align-items-center"
                                        id="JSFiddle">
                                        <google-chart style="width: 100%;" [type]="recognitionProductchart"
                                            [data]="recognitionProductData" [options]="options2"></google-chart>
                                        <div id="cnt" class="overlay">86%</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-5">
            <div class="col-xl-6 col-sm-12 col-12">
                <div class="card card_radius">
                    <div class="card-content">
                        <div class="card-body d-flex flex-column pb-0">
                            <div class="d-flex flex-row justify-content-between">
                                <div class="media-body text-left">
                                    <p class="h3">Nombre de scans Appli</p>
                                </div>

                            </div>

                            <google-chart style="width: 100%;" [type]="scanDevicetype" [data]="scanDeviceData" [options]="scanDeviceOptions">
                            </google-chart>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-sm-12 col-12">
                <div class="card card_radius">
                    <div class="card-content">
                        <div class="card-body flex-column pb-0">
                            <div class="d-flex flex-row justify-content-between">
                                <div class="media-body text-left">
                                    <p class="h3">Taux de reconnaissance</p>
                                </div>
                            </div>

                            <div class="d-flex flex-row justify-content-around">
                                <div class="d-flex flex-column justify-content-center align-items-center">
                                    <p class="h_gray">produits</p>
                                    <div class="d-flex flex-column justify-content-center align-items-center"
                                        id="JSFiddle2">
                                        <google-chart style="width: 100%;" [type]="deviceRecognisingProductsType"
                                            [data]="deviceRecognisingProductsData" [options]="deviceRecognisingProductsOptions"></google-chart>
                                        <div id="cnt" class="overlay_device_recognising">86%</div>
                                    </div>
                                </div>
                                <div class="d-flex flex-column justify-content-center align-items-center">
                                    <p class="h_gray">Consigne de tri</p>
                                    <div class="d-flex flex-column justify-content-center align-items-center"
                                        id="JSFiddle2">
                                        <google-chart style="width: 100%;" [type]="deviceRecognisingProductsType"
                                            [data]="deviceRecognisingProductsData" [options]="deviceRecognisingProductsOptions"></google-chart>
                                        <div id="cnt" class="overlay_device_recognising">86%</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-5">
            <div class="col-lg-12">
                <div class="card card_radius">
                    <div class="card-content">
                        <div class="card-body d-flex flex-column pb-0">
                            <div class="d-flex flex-row justify-content-between">
                                <div class="media-body text-left">
                                    <p class="h3">Carte des boitiers Montri</p>
                                </div>
                            </div>

                            <div class="row mt-4 mb-4">
                                <div class="col-lg-2 d-flex flex-row flex-lg-column">

                                    <div class="row pr-3  mt-0 p2">
                                            Etat des lieux
                                    </div>

                                    <div class="row mt-2  pr-3 ">
                                        <div class="col-8 p2 d-flex flex-row align-items-center justify-content-start btn_card_left_green" >
                                            Disponibles
                                        </div>

                                        <div class="col-4 p2  d-flex flex-row align-items-center justify-content-center btn_card_right_green" >
                                            37
                                        </div>
                                    </div>

                                    <div class="row mt-3 pr-3">
                                        <div class="col-8 p2 d-flex flex-row align-items-center justify-content-center btn_card_left_red" >
                                            Disponibles
                                        </div>

                                        <div class="col-4 p2 d-flex flex-row align-items-center justify-content-center btn_card_right_red" >
                                            37
                                        </div>
                                    </div>


                                    <div class="row mt-3 pr-3">
                                        <div class="col-8 p2 d-flex flex-row align-items-center justify-content-center btn_card_left_green" >
                                            % Actifs
                                        </div>

                                        <div class="col-4 p2 d-flex flex-row align-items-center justify-content-center btn_card_right_green" >
                                            37
                                        </div>
                                    </div>


                                    <div class="row mt-5 p2">
                                            Afficher boitiers actifs
                                    </div>


                                    <div class="row  mt-2">
                                        <div class="col-12 p2 pl-0 ml-0 d-flex flex-row justify-content-start " >
                                            <div class="btn-group w-100" role="group" >
                                                <input type="radio"  name="btn_oui" [value]="true"  [(ngModel)]="button_toogle" (ngModelChange)="onchange($event)" id="btn-oui"/>
                                                <label class="btn btn-secondary radius_left_stats  d-flex flex-column align-items-center pt-2" id="btn_oui" for="btn_oui">
                                                    Oui
                                                </label>
                                                <input type="radio" [value]="false" [(ngModel)]="button_toogle"   (ngModelChange)="onchange($event)"   name="btn_non"  class="radio_input radio_non"   id="non"/>
                                                <label  class="btn btn-secondary radius_right_stats d-flex flex-column align-items-center pt-2" id="btn_non"   for="btn_non">
                                                    Non
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mt-3 p2">
                                            Maintenance
                                    </div>

                                    <div class="row mt-2 pr-3">
                                            <button type="button" class="btn btn-secondary w-100">Exporter</button>
                                    </div>

                                </div>
                                <div class="col-lg-10 pl-0 pr-3">
                                    <google-map [zoom]="zoom"  style="width: 100%;border-radius:20px;" [center]="center" [options]="optionsMap"
                                            height="400px"
                                            width="100%"
                                            ></google-map>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-5">
            <div class="col-xl-7 col-sm-12 col-12">
                <div class="card card_radius" style="height: 350px;">
                    <div class="card-content">
                        <div class="card-body d-flex flex-column pb-0">
                            <div class="d-flex flex-row justify-content-between">
                                <div class="media-body text-left">
                                    <p class="h3">Jours et fréquentation moyenne (YTD)</p>
                                </div>
                            </div>
                            <google-chart  style="width: 100%;" [type]="useRatechart" [data]="useRateData" [options]="useRateOptions"></google-chart>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-5 col-sm-12 col-12">
                <div class="card card_radius" style="height: 350px;">
                    <div class="card-content">
                        <div class="card-body flex-column pb-0">

                            <div class="d-flex flex-row justify-content-between">
                                <div class="media-body text-left">
                                    <p class="h3">Répartition par tranches horaires</p>
                                </div>
                            </div>

                            <div class="d-flex flex-row justify-content-around">
                                <div class="d-flex flex-column justify-content-center align-items-center">
                                    <div class="d-flex flex-column justify-content-center align-items-center"
                                        id="JSFiddle3">
                                        <google-chart style="width: 100%; margin-bottom: 0px;" [type]="RTHTSansType" [data]="RTHScansData" [options]="RTHScansOptions"></google-chart>
                                        <div id="cnt" class="overlay3">86%</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

import { MenuService } from './../../../../services/menu/menu.service';
import { rsteResponse, rste, rstm, rstmResponse, ttesResponse, ttes, ettResponse } from './../../../../models/responses/response';
import { MsgserviceService } from './../../../../services/msgservice/msgservice.service';
import { ContratService } from './../../../../services/contrat/contrat.service';
import { DatePipe } from '@angular/common';
import { AppstatsService } from './../../../../services/appstats/appstats.service';
import { ContractStats, Contrat } from './../../../../models/contrat/contrat';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-fluxstats',
    templateUrl: './fluxstats.component.html',
    styleUrls: ['./fluxstats.component.scss']
})
export class FluxstatsComponent implements OnInit {

    wrapperNSAChart: any = undefined
    wrapperNNUChart: any = undefined
    contrat: Contrat
    contractstats: ContractStats
    timeType = 'DAY'
    TRCvalue = '0 Scan'
    TRPvalue = '0 Scan'
    RCUvalue = '46%'
    TR30value = "0 Scan"
    date: string = '0000-00-00'
    current_date: Date = new Date()
    dayBefor: string = ''
    monthBefor: string = ''
    yearBefor: string = ''
    hiddenRSTESpinner =  false
    hiddenRSTEChart = true
    hiddenRSTMSpinner =  false
    hiddenRSTMChart = true
    hiddenTTESSpinner =  false
    hiddenTTESChart = true
    hiddenETTSpinner =  false
    hiddenETTChart = true
    RSTMvalue = '0 scans'
    RSTMColor = ""
    TTESvalue =  '0 scans'
    TTESColor = ''


    days = ['Dim', 'LUN', 'MAR', 'MERC', 'JUD', 'VEN', 'SAM'];
    months = ['JAN', 'FEV', 'MAR', 'AVR', 'MAI', 'JUN', 'JUL', 'AOU', 'SEP', 'OCT', 'NOV', 'DEC']
    alldates = [
        '01', '02', '03', '04', '05', '06', '07', '08', '09', '10',
        '11', '12', '13', '14', '15', '16', '17', '18', '19', '20',
        '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'
    ];


    //chart  for  "Répartition des scans par types d’emballages"
    RSTEChartType = "ColumnChart"

    RSTEChartData = [
        ['Canette métal', 0],
        ['Boite métal', 0],
        ['Bouteille verre', 0],
        ['Pots et bocaux verre', 0],
        ['Bouteille plastique', 0],
        ['Flacons et bidons plastiques', 0],
        ['Barquette plastique', 0],
        ['Sachets plastiques', 0],
        ['Briques carton', 0],
        ['Etuis et boites carton', 0],
        ['Autres', 0],
    ];

    RSTEChartOptions = {
        legend: { position: "none" },
        bar: { groupWidth: 20 },
        colors: ['#B9CEF9'],
        hAxis: { gridlines: { count: 10 } },
        chartArea: {
            bottom: '50',
            left: '50',
            right: '10',
            top: '30',
            width: '90%',

        },
        width: '90%',
        height: '300',
        textStyle: {
            fontSize: 12,
            bold: true,
        }
    };


    // chart  for  "Répartition par types de matériaux"
    RSTMChartType = "PieChart"

    RSTMChartData = [
        ['Plastique',0],
        ['Papier', 0],
        ['Carton', 0],
        ['Métal', 0],
        ['Verre',0],
        ['Autres matériaux',0]
    ];

    RSTMChartOptions = {
        width: '300',
        height: '250',
        pieHole: 0.6,
        legend: { position: 'right', alignment: 'center' },
        colors: ['#739DF3','#96AFF0','#B0C4F4','#CAD7F8','#E4ECFB'],
        chartArea: {
            top: '0',
            bottom: '10',
            left: '10',
            width: '100%',
            height: '100%'
        }
    };


    // chart  for  "Taux de tri des emballages scanné"
    TTESChartType = "PieChart"

    TTESChartData = [
        ['A trier', 3400],
        ['A jeter', 800],
    ];

    TTESChartOptions = {
        width: '300',
        height: '250',
        pieHole: 0.6,
        legend: { position: 'right', alignment: 'center' },
        colors: ['#739df3', '#d0defb'],
        chartArea: {
            top: '0',
            bottom: '10',
            left: '10',
            width: '100%',
            height: '100%'
        }
    };

    isListenning = false

    // chart  for  "Nombre de Scans Appli : NSA"
    ETTChartType = 'AreaChart';
    ETTChartData = [
        ["0", 300],
        ["1", 2570],
        ["2", 2200],
        ["3", 3660],
        ["4", 2570],
        ["5", 300]
    ];
    ETTChartOptions = {
        colors: ['#B9CEF9'],
        lineWidth: 5,
        legend: 'none',
        vAxis: {
            baselineColor: 'none',
            ticks: []
        },
        pointSize: 20,
        pointShape: 'circle',
        hAxis: { gridlines: { count: 10 } },
        chartArea: {
            width: '100%',
        },
        width: '100%',
        height: '300'
    }

    constructor(
        private appstatsService: AppstatsService,
        public datepipe: DatePipe,
        private contratservice: ContratService,
        private messageService: MsgserviceService,
        private menuservice : MenuService
    ) { }

    ngOnInit(): void {

        this.menuservice.setSection("statistiques");
        this.menuservice.setSubsection("analyse");
        var  self =  this ;

        //this.current_date.setDate(this.current_date.getDate() - 1)
        this.date =  this.datepipe.transform(this.current_date, 'yyyy-MM-dd');

        this.contratservice.contrat_obs.subscribe((contrat)=>{
            this.contrat = contrat
            if(this.contrat.contrat.idContrat != 'BOTEST'){
                if(!this.isListenning) {
                    this.isListenning = true
                    this.statsPerDay()
                }   
            } 
        })
    }

    statsPerDay() {
        this.getDayBefor()
        this.hiddenETTChart = true
        this.hiddenETTSpinner =  false
        this.hiddenRSTEChart = true
        this.hiddenRSTESpinner =  false
        this.hiddenRSTMChart = true
        this.hiddenRSTMSpinner = false
        this.hiddenTTESChart = true
        this.hiddenTTESSpinner = false

        this.appstatsService.getRSTE(this.contrat.contrat.idContrat, this.date, this.timeType).subscribe(result => {
                var response = ((result as rsteResponse).result[0][0]) as rste;
                this.RSTEChartData = [
                    ['Canette métal',response.canetteMetal],
                    ['Boite métal', response.boiteMetal],
                    ['Bouteille verre', response.bouteilleVerre],
                    ['Pots et bocaux verre', response.potBocalVerre],
                    ['Bouteille plastique', response.bouteillePlastique],
                    ['Flacons et bidons plastiques',response.flaconBidonPlastique],
                    ['Barquette plastique', response.barquettePlastique],
                    ['Sachets plastiques', response.sachetPlastique],
                    ['Briques carton', response.briqueCarton],
                    ['Etuis et boites carton', response.etuiBoiteCarton],
                    ['Autres', response.autresPackagings],
                ];

                this.hiddenRSTEChart = false
                this.hiddenRSTESpinner =  true
        })

        this.appstatsService.getRSTM(this.contrat.contrat.idContrat,this.date, this.timeType).subscribe(result => {
                var response =   ((result as rstmResponse).result[0][0]) as rstm ;
                this.RSTMChartData = [['Carton',response.carton],['Plastique',response.plastique],['Verre',response.verre],['Métal',response.metal],['Papier',response.papier], ['Autres matériaux',response.autresMateriaux]]
                if(response.carton == 0 &&  response.papier == 0  && response.plastique == 0 && response.metal == 0 && response.verre == 0 && response.autresMateriaux == 0){
                    this.RSTMvalue =  "0 scans"
                }
                else{


                    this.RSTMvalue = (response.carton == 0 && response.metal == 0 && response.papier == 0 && response.plastique == 0 && response.verre == 0 && response.autresMateriaux == 0) ? "0 scans" : Math.floor(((Math.round((Math.max.apply(Math, this.RSTMChartData.map(function (o) { return o[1]; })) / this.RSTMChartData.reduce((a, b) => a + parseFloat(b[1].toString()), 0)) * 100) / 100) * 100)).toString() + "%"

                    this.RSTMColor = this.RSTMChartOptions.colors[this.RSTMChartData.reduce((iMax, x, i, arr) => x[1] > arr[iMax][1] ? i : iMax, 0)]
                }

                this.hiddenRSTMChart = false
                this.hiddenRSTMSpinner = true
        })

        this.appstatsService.getTTES(this.contrat.contrat.idContrat,this.date, this.timeType).subscribe(result => {
            var response =   ((result as ttesResponse).result[0][0]) as ttes ;
            let trier;
            if(response.a_trier1 != 0 && response.a_trier2 != 0){
                trier = (response.a_trier1 + response.a_trier2) / 2
            }else if(response.a_trier1 === 0){
                trier = response.a_trier2
            }
            this.TTESChartData = [['A trier',trier],['A jeter', trier == null ? 0 :  100 -trier]]
            if(trier === 0){
                this.TTESvalue =  "0 scans"
            }
            else{

                this.TTESvalue = (trier === 0 ) ? "0 scans" :Math.floor( ((Math.round((Math.max.apply(Math, this.TTESChartData.map(function (o) { return o[1]; })) / this.TTESChartData.reduce((a, b) => a + parseFloat(b[1].toString()), 0)) * 100) / 100) * 100)).toString() + "%"

                this.TTESColor = this.TTESChartOptions.colors[this.TTESChartData.reduce((iMax, x, i, arr) => x[1] > arr[iMax][1] ? i : iMax, 0)]
            }

            this.hiddenTTESChart = false
            this.hiddenTTESSpinner = true
        })

        this.appstatsService.getETT(this.contrat.contrat.idContrat, this.date, this.timeType)
            .subscribe(result => {
                var response = result as ettResponse;
                    for (let i = 0; i <= 6; i++) {
                        var index = response.result[0].findIndex(x => x.time_ago == i)
                        if (index === -1) {
                            response.result[0].push({ 'time_ago': i, 'a_trier': 0 })
                        }
                    }
                    response.result[0] = response.result[0].sort(
                        function (a, b) {
                            return a.time_ago - b.time_ago;
                        }
                    )
                    var evolution = []
                    for (let element of response.result[0].reverse()) {
                        var d = new Date()
                        d.setDate(this.current_date.getDate() - element.time_ago)
                        evolution.push([this.days[d.getDay()], element.a_trier])
                    }
                    this.ETTChartData = evolution
                    this.hiddenETTChart = false
                    this.hiddenETTSpinner = true
            })
    }

    statsPerMonth() {
        this.getmonthBefor()
        this.hiddenETTChart = true
        this.hiddenETTSpinner =  false
        this.hiddenRSTEChart = true
        this.hiddenRSTESpinner =  false
        this.hiddenRSTMChart = true
        this.hiddenRSTMSpinner = false
        this.hiddenTTESChart = true
        this.hiddenTTESSpinner = false

        this.appstatsService.getRSTE(this.contrat.contrat.idContrat, this.date, this.timeType).subscribe(result => {
                var response = ((result as rsteResponse).result[0][0]) as rste;
                this.RSTEChartData = [
                    ['Canette métal',response.canetteMetal],
                    ['Boite métal', response.boiteMetal],
                    ['Bouteille verre', response.bouteilleVerre],
                    ['Pots et bocaux verre', response.potBocalVerre],
                    ['Bouteille plastique', response.bouteillePlastique],
                    ['Flacons et bidons plastiques',response.flaconBidonPlastique],
                    ['Barquette plastique', response.barquettePlastique],
                    ['Sachets plastiques', response.sachetPlastique],
                    ['Briques carton', response.briqueCarton],
                    ['Etuis et boites carton', response.etuiBoiteCarton],
                    ['Autres', response.autresPackagings],
                ];

                this.hiddenRSTEChart = false
                this.hiddenRSTESpinner =  true
        })

        this.appstatsService.getRSTM(this.contrat.contrat.idContrat,this.date, this.timeType).subscribe(result => {
                var response =   ((result as rstmResponse).result[0][0]) as rstm ;
                this.RSTMChartData = [['Carton',response.carton],['Plastique',response.plastique],['Verre',response.verre],['Métal',response.metal],['Papier',response.papier],['Autres matériaux',response.autresMateriaux]]
                if(response.carton == 0 &&  response.papier == 0  && response.plastique == 0 && response.metal == 0 && response.verre == 0 && response.autresMateriaux == 0){
                    this.RSTMvalue =  "0 scans"
                }
                else{


                    this.RSTMvalue = (response.carton == 0 && response.metal == 0 && response.papier == 0 && response.plastique == 0 && response.verre == 0 && response.autresMateriaux == 0) ? "0 scans" : Math.floor(((Math.round((Math.max.apply(Math, this.RSTMChartData.map(function (o) { return o[1]; })) / this.RSTMChartData.reduce((a, b) => a + parseFloat(b[1].toString()), 0)) * 100) / 100) * 100)).toString() + "%"

                    this.RSTMColor = this.RSTMChartOptions.colors[this.RSTMChartData.reduce((iMax, x, i, arr) => x[1] > arr[iMax][1] ? i : iMax, 0)]
                }

                this.hiddenRSTMChart = false
                this.hiddenRSTMSpinner = true
        })

        this.appstatsService.getTTES(this.contrat.contrat.idContrat,this.date, this.timeType).subscribe(result => {
            var response =   ((result as ttesResponse).result[0][0]) as ttes ;
            let trier;
            if(response.a_trier1 != 0 && response.a_trier2 != 0){
                trier = (response.a_trier1 + response.a_trier2) / 2
            }else if(response.a_trier1 === 0){
                trier = response.a_trier2
            }
            this.TTESChartData = [['A trier',trier],['A jeter', trier == null ? 0 :  100 -trier]]
            if(trier === 0){
                this.TTESvalue =  "0 scans"
            }
            else{

                this.TTESvalue = (trier === 0 ) ? "0 scans" :Math.floor( ((Math.round((Math.max.apply(Math, this.TTESChartData.map(function (o) { return o[1]; })) / this.TTESChartData.reduce((a, b) => a + parseFloat(b[1].toString()), 0)) * 100) / 100) * 100)).toString() + "%"

                this.TTESColor = this.TTESChartOptions.colors[this.TTESChartData.reduce((iMax, x, i, arr) => x[1] > arr[iMax][1] ? i : iMax, 0)]
            }

            this.hiddenTTESChart = false
            this.hiddenTTESSpinner = true
        })

        this.appstatsService.getETT(this.contrat.contrat.idContrat, this.date, this.timeType)
            .subscribe(result => {
                var response = result as ettResponse;
                    for (let i = 0; i <= 6; i++) {
                        var index = response.result[0].findIndex(x => x.time_ago == i)
                        if (index === -1) {
                            response.result[0].push({ 'time_ago': i, 'a_trier': 0 })
                        }
                    }
                    response.result[0] = response.result[0].sort(
                        function (a, b) {
                            return a.time_ago - b.time_ago;
                        }
                    )
                    var evolution = []
                    for (let element of response.result[0].reverse()) {
                        var d = new Date()
                        d.setMonth(this.current_date.getMonth() - element.time_ago)
                        evolution.push([this.months[d.getMonth()], element.a_trier])
                    }
                    this.ETTChartData = evolution
                    this.hiddenETTChart = false
                    this.hiddenETTSpinner = true
            })
    }


    statsPerYear() {
        this.getyearBefor()
        this.hiddenETTChart = true
        this.hiddenETTSpinner =  false
        this.hiddenRSTEChart = true
        this.hiddenRSTESpinner =  false
        this.hiddenRSTMChart = true
        this.hiddenRSTMSpinner = false
        this.hiddenTTESChart = true
        this.hiddenTTESSpinner = false

        this.appstatsService.getRSTE(this.contrat.contrat.idContrat, this.date, this.timeType).subscribe(result => {
                var response = ((result as rsteResponse).result[0][0]) as rste;
                this.RSTEChartData = [
                    ['Canette métal',response.canetteMetal],
                    ['Boite métal', response.boiteMetal],
                    ['Bouteille verre', response.bouteilleVerre],
                    ['Pots et bocaux verre', response.potBocalVerre],
                    ['Bouteille plastique', response.bouteillePlastique],
                    ['Flacons et bidons plastiques',response.flaconBidonPlastique],
                    ['Barquette plastique', response.barquettePlastique],
                    ['Sachets plastiques', response.sachetPlastique],
                    ['Briques carton', response.briqueCarton],
                    ['Etuis et boites carton', response.etuiBoiteCarton],
                    ['Autres', response.autresPackagings],
                ];

                this.hiddenRSTEChart = false
                this.hiddenRSTESpinner =  true
        })

        this.appstatsService.getRSTM(this.contrat.contrat.idContrat,this.date, this.timeType).subscribe(result => {
                var response =   ((result as rstmResponse).result[0][0]) as rstm ;
                this.RSTMChartData = [['Carton',response.carton],['Plastique',response.plastique],['Verre',response.verre],['Métal',response.metal],['Papier',response.papier],['Autres matériaux',response.autresMateriaux]]
                if(response.carton == 0 &&  response.papier == 0  && response.plastique == 0 && response.metal == 0 && response.verre == 0 && response.autresMateriaux == 0){
                    this.RSTMvalue =  "0 scans"
                }
                else{


                    this.RSTMvalue = (response.carton == 0 && response.metal == 0 && response.papier == 0 && response.plastique == 0 && response.verre == 0 && response.autresMateriaux == 0) ? "0 scans" : Math.floor(((Math.round((Math.max.apply(Math, this.RSTMChartData.map(function (o) { return o[1]; })) / this.RSTMChartData.reduce((a, b) => a + parseFloat(b[1].toString()), 0)) * 100) / 100) * 100)).toString() + "%"

                    this.RSTMColor = this.RSTMChartOptions.colors[this.RSTMChartData.reduce((iMax, x, i, arr) => x[1] > arr[iMax][1] ? i : iMax, 0)]
                }

                this.hiddenRSTMChart = false
                this.hiddenRSTMSpinner = true
        })

        this.appstatsService.getTTES(this.contrat.contrat.idContrat,this.date, this.timeType).subscribe(result => {
            var response =   ((result as ttesResponse).result[0][0]) as ttes ;
            let trier;
            if(response.a_trier1 != 0 && response.a_trier2 != 0){
                trier = (response.a_trier1 + response.a_trier2) / 2
            }else if(response.a_trier1 === 0){
                trier = response.a_trier2
            }
            this.TTESChartData = [['A trier',trier],['A jeter', trier == null ? 0 :  100 -trier]]
            if(trier === 0){
                this.TTESvalue =  "0 scans"
            }
            else{

                this.TTESvalue = (trier === 0 ) ? "0 scans" :Math.floor( ((Math.round((Math.max.apply(Math, this.TTESChartData.map(function (o) { return o[1]; })) / this.TTESChartData.reduce((a, b) => a + parseFloat(b[1].toString()), 0)) * 100) / 100) * 100)).toString() + "%"

                this.TTESColor = this.TTESChartOptions.colors[this.TTESChartData.reduce((iMax, x, i, arr) => x[1] > arr[iMax][1] ? i : iMax, 0)]
            }

            this.hiddenTTESChart = false
            this.hiddenTTESSpinner = true
        })

        this.appstatsService.getETT(this.contrat.contrat.idContrat, this.date, this.timeType)
            .subscribe(result => {
                var response = result as ettResponse;
                    for (let i = 0; i <= 6; i++) {
                        var index = response.result[0].findIndex(x => x.time_ago == i)
                        if (index === -1) {
                            response.result[0].push({ 'time_ago': i, 'a_trier': 0 })
                        }
                    }

                    response.result[0] = response.result[0].sort(
                        function (a, b) {
                            return a.time_ago - b.time_ago;
                        }
                    )

                    var evolution = []
                    for (let element of response.result[0].reverse()) {
                        var d = new Date()
                        d.setFullYear(this.current_date.getFullYear() - element.time_ago)
                        evolution.push([d.getFullYear().toString().replace(',',''), element.a_trier])
                    }
                    this.ETTChartData = evolution
                    this.hiddenETTChart = false
                    this.hiddenETTSpinner = true
            })
    }

    DayStats(event) {
        if(event.target.tagName === 'LABEL'){
            this.timeType = 'DAY'
            var current_date = new Date()
            this.current_date.setMonth(current_date.getMonth())
            this.current_date.setFullYear(current_date.getFullYear())
            this.date = this.datepipe.transform(this.current_date, 'yyyy-MM-dd');
            this.statsPerDay()
        }
    }

    MonthStats(event) {
        if(event.target.tagName === 'LABEL'){
            this.timeType = 'MONTH'
            var current_date = new Date()
            this.current_date.setMonth(current_date.getMonth())
            this.current_date.setFullYear(current_date.getFullYear())
            this.date = this.datepipe.transform(this.current_date, 'yyyy-MM-dd');
            this.statsPerMonth()
        }
    }

    YearStats(event) {
        if(event.target.tagName === 'LABEL'){
            this.timeType = 'YEAR'
            var current_date = new Date()
            this.current_date.setMonth(current_date.getMonth())
            this.current_date.setFullYear(current_date.getFullYear())
            this.date = this.datepipe.transform(this.current_date, 'yyyy-MM-dd');
            this.statsPerYear()
        }
    }

    MoveLet() {
        if (this.timeType == 'DAY') {
            this.current_date.setDate(this.current_date.getDate() - 1)
            this.date = this.datepipe.transform(this.current_date, 'yyyy-MM-dd');
            this.statsPerDay()
        }

        if (this.timeType == 'MONTH') {
            this.current_date.setMonth(this.current_date.getMonth() - 1)
            this.date = this.datepipe.transform(this.current_date, 'yyyy-MM-dd');
            this.statsPerMonth()
        }

        if (this.timeType == 'YEAR') {
            this.current_date.setFullYear(this.current_date.getFullYear() - 1)
            this.date = this.datepipe.transform(this.current_date, 'yyyy-MM-dd');
            this.statsPerYear()
        }
    }

    MoveRight() {
        var test_date = new Date()
        var today = new Date()
        test_date.setDate(this.current_date.getDate())
        test_date.setMonth(this.current_date.getMonth())
        test_date.setFullYear(this.current_date.getFullYear())
        if (this.timeType == 'DAY') {
            test_date.setDate(this.current_date.getDate() + 1)
            //today.setDate(today.getDate() - 1)
            if (test_date <= today) {
                this.current_date.setDate(this.current_date.getDate() + 1)
                this.date = this.datepipe.transform(this.current_date, 'yyyy-MM-dd');
                this.statsPerDay()
            }
        }

        if (this.timeType == 'MONTH') {
            test_date.setMonth(this.current_date.getMonth() + 1)
            if (test_date <= today) {
                this.current_date.setMonth(this.current_date.getMonth() + 1)
                this.date = this.datepipe.transform(this.current_date, 'yyyy-MM-dd');
                this.statsPerMonth()
            }
        }

        if (this.timeType == 'YEAR') {
            test_date.setFullYear(this.current_date.getFullYear() + 1)
            if (test_date <= today) {
                this.current_date.setFullYear(this.current_date.getFullYear() + 1)
                this.date = this.datepipe.transform(this.current_date, 'yyyy-MM-dd');
                this.statsPerYear()
            }
        }
    }

    getDayBefor(){
        var d = new Date(this.date);
        d.setDate(d.getDate() - 1)
        this.dayBefor = this.alldates[d.getDate()-1] +" "+this.months[d.getMonth()] +" " +d.getUTCFullYear().toString()
    }

    getmonthBefor(){
        var d = new Date(this.date);
        this.monthBefor =   this.months[(d.getMonth()-1)] +" " +d.getUTCFullYear().toString()
    }

    getyearBefor(){
        var d = new Date(this.date);
        this.yearBefor =   (d.getUTCFullYear()-1).toString()
    }

}

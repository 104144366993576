import { Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserModuleRoutingModule } from './user-module-routing.module';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { SignupComponent } from './components/signup/signup.component';
import { UserComponent } from './components/user/user.component';
import { UpdatepasswordComponent } from './components/updatepassword/updatepassword/updatepassword.component';
import { ResetpasswordComponent } from './components/resetpassword/resetpassword.component';
import { SendmailComponent } from './modals/sendmail/sendmail.component';


@NgModule({
    declarations: [SignInComponent, SignupComponent, UserComponent, UpdatepasswordComponent, ResetpasswordComponent, SendmailComponent],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        UserModuleRoutingModule,

    ],
})
export class UserModule {}

<div class="container mt-5 d-flex flex-column justify-content-center align-items-center">
    <img src="assets/img/montri_logo.png" class="montri_logo">
    <h5 class="h1 mt-3" style="text-align: center;">Bienvenue,<br /> Veuillez vous connecter</h5>
    <div class="row d-flex flex-column justify-content-center align-items-center" style="width: 100%;">
        <form class="mt-3" style="width: 300px;"  [formGroup]="SignInForm" (ngSubmit)="onSubmit(SignInForm.value)" >
            <div class="form-group has-feedback" style="width: 100%;">
                <input autocomplete="off" style="width: 100%;" type="email" class="form-control input_form p2"
                    id="email_signin" aria-describedby="emailHelp" formControlName="email_signin" placeholder="Email">
            </div>

            <div class="form-group has-feedback" style="width: 100%;">
                <input autocomplete="off" type="password" style="width: 100%;" class="form-control input_form p2"
                    id="password_signin" placeholder="Mot de passe" formControlName="password_signin">
            </div>
            <div class="d-flex flex-row justify-content-between mt-2 underline" style="width:300px;">
                <a (click)="showSendMailModal()" style="cursor: pointer;" id="password_forget" class="p4"t>Mot de passe oublié?</a>
            </div>
            <button type="submit"  style="width: 100%;" class="btn button_form button1 mt-3 w-100"
                id="Login_btn">Me connecter</button>
        </form>
    </div>

</div>

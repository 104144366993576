import { UserBo } from './../../../../models/user/user';
import { UserService } from './../../../../services/user/user.service';
import { WebContrat, Design } from './../../../../models/contrat/contrat';
import { AngularFireStorage } from '@angular/fire/storage';
import { AlertService } from './../../../../services/alert/alert.service';
import { LodingBarService } from './../../../../services/lodingbar/loding-bar-service.service';
import { ContratService } from './../../../../services/contrat/contrat.service';
import { TabmessageService } from './../../../../services/tabmessage/tabmessage.service';
import { Component, OnInit } from '@angular/core';
import { Contrat } from 'src/app/models/contrat/contrat';
import { last, switchMap } from 'rxjs/operators';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { ToastAlertsComponent } from 'src/app/modules/main/components/toast-alerts/toast-alerts.component';

@Component({
    selector: 'app-design',
    templateUrl: './design.component.html',
    styleUrls: ['./design.component.scss']
})
export class DesignComponent implements OnInit {

    edit_status = false
    contrat : Contrat;
    logowebapp : string = "../../../../../assets/img/logosite-demo.png" ;
    coverimage : string  ;
    logomobileapp : string = "";
    web : WebContrat;
    titrecovererror : boolean = false
    soustitrecovererror : boolean = false
    Designe : Design ;
    colorerror : boolean = false ;
    colorErrorMsg : string = ''
    user : UserBo
    constructor(
        private sectionservice: TabmessageService,
        private contratservice : ContratService,
        private lodingbarservice: LodingBarService,
        private alertservice: AlertService,
        private afStorage: AngularFireStorage,
        private userservice : UserService,
        private toastr: ToastAlertsComponent

    ) {

    }

    ngOnInit(): void {
        window.scroll(0,0)

        this.userservice.user_obs.subscribe((user)=>{
            this.user = user
            this.sectionservice.setSection('design');
            this.contratservice.contrat_obs.subscribe((contrat)=>{
                this.contrat = contrat
                if(this.contrat !=  null && this.contrat.contrat.idContrat !="BOTEST"){
                const ref = this.afStorage.ref(this.contrat.contrat.idContrat+'/preResources/logosite.png');
                ref.getDownloadURL().subscribe((href)=>{
                    if(href == "" || href == undefined || href == ""){
                        this.logowebapp = "../../../../../assets/img/logosite-demo.png";
                    }
                    else{
                        this.logowebapp = href
                    }
                })

                const ref2 = this.afStorage.ref(this.contrat.contrat.idContrat+'/preResources/cover-site.png');
                ref2.getDownloadURL().subscribe((href)=>{
                    if(href == "" || href == undefined || href == ""){
                        this.coverimage = "../../../../../assets/img/cover-demo.png";
                    }
                    else{
                        this.coverimage = href
                    }
                })

                const ref3 = this.afStorage.ref(this.contrat.contrat.idContrat+'/preResources/logoapp.png');
                ref3.getDownloadURL().subscribe((href)=>{
                    if(href == "" || href == undefined){
                        this.logomobileapp = "";
                    }
                    else{
                        this.logomobileapp = href
                    }
                })

                this.web = {... contrat.Web}
                this.Designe = contrat.design;
            }
            })
        })
    }

    onChange() {

    }

    setlogowebapp(url: any){
        this.logowebapp = url
    }

    sendWebappLogo(file: File){
        var  self = this;
        let img = new Image()
        img.src = window.URL.createObjectURL(file)
        img.onload = async () => {
                if(img.width == 300 && img.height == 100){
                    let refToast = await self.toastr.openTransferToast(`Transfert de données en cours.`, "");
                    var ref = this.afStorage.ref(this.contrat.contrat.idContrat + "/preResources/logosite.png");
                    var task = ref.put(file);
                    self.contratservice.contrat.value.localfiles.localLogoWeb = file.name
                    let status = await self.contratservice.saveContrat('')
                    if(status === "success"){
                        self.toastr.clear(refToast.toastId)
                        self.toastr.openSuccessToast('Logo téléversée avec succès', "");
                        task.snapshotChanges()
                            .pipe(last(),switchMap(() => ref.getDownloadURL()))
                            .subscribe((url) => this.setlogowebapp(url))
                    }else{
                        self.toastr.clear(refToast.toastId)
                        self.toastr.openErrorToast(`Téléversement échoué. Veuillez réessayer.`, "");
                    }
                    

                }
                else{
                  self.toastr.openFailedToast(`Dimensions incorrectes. (300x100 requis)`, "");
               }
        }
    }

    async saveContratToast(){
        let status = await this.contratservice.saveContrat('')
        if(status === "success"){
            this.toastr.openSuccessToast('Element surpprimé avec succès', "");
        }else{
            this.toastr.openErrorToast(`La suppression a échoué. Veuillez réessayer.`, "");
        };
    }


    async deletelogowebapp(){
        var ref = this.afStorage.ref(this.contrat.contrat.idContrat + "/preResources/logosite.png");
        ref.delete()
        this.logowebapp = "../../../../../assets/img/logosite-demo.png"
        this.contratservice.contrat.value.localfiles.localLogoWeb = "";
        this.saveContratToast()
    }


    setCoverImage(url: any){
        this.coverimage = url
    }


    sendCoverImage(file : File){
        var  self = this;
        let img = new Image()
        img.src = window.URL.createObjectURL(file)
        img.onload = async () => {
                if(img.width == 1500 && img.height == 500){
                    let refToast = await self.toastr.openTransferToast(`Transfert de données en cours.`, "",);
                    var ref = this.afStorage.ref(this.contrat.contrat.idContrat + "/preResources/cover-site.png");
                    var task = ref.put(file);
                    task.snapshotChanges()
                        .pipe(last(),switchMap(() => ref.getDownloadURL()))
                        .subscribe((url) => this.setCoverImage(url))
                    self.contratservice.contrat.value.localfiles.localCoverWeb = file.name
                    let status = await self.contratservice.saveContrat('')
                    if(status === "success"){
                        self.toastr.clear(refToast.toastId)
                        self.toastr.openSuccessToast('Image téléversée avec succès', "");
                        task.snapshotChanges()
                            .pipe(last(),switchMap(() => ref.getDownloadURL()))
                            .subscribe((url) => this.setCoverImage(url))
                    }else{
                        self.toastr.clear(refToast.toastId)
                        self.toastr.openErrorToast(`Téléversement échoué. Veuillez réessayer.`, "");
                    }
                }
                else{
                  self.toastr.openFailedToast(`Dimensions incorrectes. (1500x500 requis)`, "");
               }
        }

    }

    deleteCoverImage(){
        var ref = this.afStorage.ref(this.contrat.contrat.idContrat + "/preResources/cover-site.png");
        ref.delete()
        this.coverimage = "../../../../../assets/img/cover-demo.png"
        this.contratservice.contrat.value.localfiles.localCoverWeb = "";
        this.saveContratToast()
    }

    setMobileappLogo(url: any){
        this.logomobileapp = url
    }


    sendMobileappLogo(file : File){
        var  self = this;
        let img = new Image()
        img.src = window.URL.createObjectURL(file)
        img.onload = async () => {
                if(img.width == 200 && img.height == 200){
                    let refToast = await self.toastr.openTransferToast(`Transfert de données en cours.`, "");
                    var ref = this.afStorage.ref(this.contrat.contrat.idContrat + "/preResources/logoapp.png");
                    var task = ref.put(file);
                    task.snapshotChanges()
                        .pipe(last(),switchMap(() => ref.getDownloadURL()))
                        .subscribe((url) => this.setMobileappLogo(url))
                    self.contratservice.contrat.value.localfiles.localLogoMobile = file.name
                    let status = await self.contratservice.saveContrat('')
                    if(status === "success"){
                        self.toastr.clear(refToast.toastId)
                        self.toastr.openSuccessToast('Logo téléversée avec succès', "");
                    }else{
                        self.toastr.clear(refToast.toastId)
                        self.toastr.openErrorToast(`Téléversement échoué. Veuillez réessayer.`, "");
                    }
                }
                else{
                  self.toastr.openFailedToast(`Dimensions incorrectes. (200x200 requis)`, "");
                }
        }

    }

    deleteMobileappLogo(){
        var ref = this.afStorage.ref(this.contrat.contrat.idContrat + "/preResources/logoapp.png");
        ref.delete()
        this.logomobileapp = ""
        this.contratservice.contrat.value.localfiles.localLogoMobile = ''
        this.saveContratToast()
    }

    toastError(str){
        this.toastr.openFailedToast(str, "");
    }


    async saveData(){
        if(this.web.soustitreCover == "" && this.contrat.contrat.webApp == true){
            this.soustitrecovererror = true
            this.toastError('Certains champs obligatoires ne sont pas remplis.')
        }

        else if (this.web.titreCover =="" && this.contrat.contrat.webApp == true){
            this.titrecovererror = true
            this.toastError('Certains champs obligatoires ne sont pas remplis.')
        }

        else if(this.Designe.couleurPrincipale == ""){
            this.colorerror = true;
            this.colorErrorMsg ='Champs obligatoire'
            this.toastError('Certains champs obligatoires ne sont pas remplis.')
        }
        else if(!this.Designe.couleurPrincipale.match('^#([A-Fa-f0-9]{6})$')){
            this.Designe.couleurPrincipale = ''
            this.colorerror = true;
            this.colorErrorMsg ='Code couleur incorrecte'
            this.toastError('Code couleur incorrect.')
        }
        else{
            let ref = await this.toastr.openTransferToast(`Transfert de données en cours.`, "");
            this.contratservice.contrat.value.Web = {...this.web}
            this.contratservice.contrat.value.design = {...this.Designe}
            this.contratservice.contrat.value.configstatus[3].status = true
            let status = await this.contratservice.saveContrat('/montri/contractsettings/services')
            if(status === "success"){
                this.toastr.clear(ref.toastId)
                this.toastr.openSuccessToast('Données enregistrées avec succès', "");
            }else{
                this.toastr.clear(ref.toastId)
                this.toastr.openErrorToast(`Chargement échoué. Veuillez réessayer.`, "");
            }
        }
    }

    completeColor(){
        if(this.Designe.couleurPrincipale == ""){
            this.Designe.couleurPrincipale = "#"
        }
    }
}

import { element } from 'protractor';
import { UserService } from './../../../../services/user/user.service';
import { UserBo } from './../../../../models/user/user';
import { AngularFireStorage } from '@angular/fire/storage';
import { MsgserviceService } from './../../../../services/msgservice/msgservice.service';
import { ServiceinfosComponent } from './../../modals/serviceinfos/serviceinfos/serviceinfos.component';
import { SliderService } from './../../../../services/slider/slider.service';
import { Alert } from './../../../../models/alert/alert';
import { Service, Contrat, ServicesPreview } from './../../../../models/contrat/contrat';
import { AlertService } from './../../../../services/alert/alert.service';
import { LodingBarService } from './../../../../services/lodingbar/loding-bar-service.service';
import { ContratService } from './../../../../services/contrat/contrat.service';
import { TabmessageService } from './../../../../services/tabmessage/tabmessage.service';
import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { switchMap, last } from 'rxjs/operators';
import { ToastAlertsComponent } from 'src/app/modules/main/components/toast-alerts/toast-alerts.component'; 

@Component({
    selector: 'app-slider',
    templateUrl: './slider.component.html',
    styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {

    services : Service[]
    colorItem : string ;
    edit_status : boolean = true;
    splashScreentemplate  :  string;
    user :  UserBo
    splashScreen : string = ''
    splashscreenError : boolean =  false
    contrat : Contrat
    counter : number = 0;
    current_slide : ServicesPreview ;
    filter : Service[];
    slide_services : ServicesPreview [];

    constructor(
        private sectionservice: TabmessageService,
        private modalService: NgbModal,
        private lodingbarservice: LodingBarService,
        private alertservice: AlertService,
        private sliderService : SliderService,
        private contratservice : ContratService,
        private msgservice : MsgserviceService,
        private afStorage : AngularFireStorage,
        private userservice : UserService,
        private toastr: ToastAlertsComponent
    ) { }

    ngOnInit(): void {
        window.scroll(0,0)
        const ref2 = this.afStorage.ref('TemplatesBO/Splashscreen-Contrat.ai');
        ref2.getDownloadURL().subscribe((href)=>{
            this.splashScreentemplate = href
        })
        this.sectionservice.setSection('slider');
        this.userservice.user_obs.subscribe((user)=>{
            this.user = user
        this.contratservice.contrat_obs.subscribe((contrat)=>{
            this.contrat = contrat
            const ref = this.afStorage.ref(this.contrat.contrat.idContrat+'/preResources/Splashscreen-Contrat.png');
            ref.getDownloadURL().subscribe((href)=>{
                if(href == "" || href == undefined || href == ""){
                    this.splashScreen = "";
                }
                else{
                    this.splashScreen = href
                }
            })


                this.services =  [...contrat.services]

                this.filter = this.services.filter(service=>service.slider == true && service.active==true && service.titre!=""&& service.description1!="")
                this.slide_services = []
                for (let element of this.filter){
                    var servicepreview : ServicesPreview = new ServicesPreview({})
                    servicepreview.title =this.getTextArray(element.titre,20)
                    servicepreview.description1 =this.getTextArray(element.description1,45)
                    servicepreview.description2 =this.getTextArray(element.description2,45)
                    servicepreview.image = element.image
                    this.slide_services.push(servicepreview)
                }
                this.current_slide = this.slide_services[0]
                for(let  [index,element]  of this.slide_services.entries()){
                    const ref = this.afStorage.ref(this.contrat.contrat.idContrat+'/preResources/'+ element.image);
                    ref.getDownloadURL().subscribe((href)=>{
                        if(href == "" || href == undefined || href == ""){

                        }
                        else{
                            this.slide_services[index].image = href
                        }
                    })
                }

        })
        })
    }

    onChange(file: File) {
        var  self = this;
    }

    onchange(evt,i){
        this.filter = this.services.filter(service=>service.slider == true && service.active==true && service.titre!=""&& service.description1!="")
        this.slide_services = []
        for (let element of this.filter){
            var servicepreview : ServicesPreview = new ServicesPreview({})
            servicepreview.title =this.getTextArray(element.titre,20)
            servicepreview.description1 =this.getTextArray(element.description1,45)
            servicepreview.description2 =this.getTextArray(element.description2,45)
            servicepreview.image = element.image
            this.slide_services.push(servicepreview)
        }
        for(let  [index,element]  of this.slide_services.entries()){
            const ref = this.afStorage.ref(this.contrat.contrat.idContrat+'/preResources/'+ element.image);
            ref.getDownloadURL().subscribe((href)=>{
                if(href == "" || href == undefined || href == ""){

                }
                else{
                    this.slide_services[index].image = href
                }
            })
        }
        this.current_slide =   this.slide_services[this.counter]
    }

    async saveData(){
            let ref = await this.toastr.openTransferToast(`Transfert de données en cours.`, "")
            this.contratservice.contrat.value.services = [...this.services]
            this.contratservice.contrat.value.configstatus[5].status = true
            let status = await this.contratservice.saveContrat('/montri/welcome')
            if(status === "success"){
                this.toastr.clear(ref.toastId)
                this.toastr.openSuccessToast('Données enregistrées avec succès', "")
            }else{
                this.toastr.clear(ref.toastId)
                this.toastr.openErrorToast(`Chargement échoué. Veuillez réessayer.`, "")
            }
    }

    showModal(servicename : string){
        window.scrollTo(0, 0);
        this.msgservice.setService(servicename)
		let options: NgbModalOptions = {};
        options.centered = true
        options.size='lg'
		this.modalService.dismissAll()
        const modalRef = this.modalService.open(ServiceinfosComponent,options)
        modalRef.componentInstance.services = this.services;
        modalRef.result.then((result) => {
            if (result) {
                this.services = result
                this.filter = this.services.filter(service=>service.slider == true && service.active==true && service.titre!=""&& service.description1!="")
                this.slide_services = []
                for (let element of this.filter){
                    var servicepreview : ServicesPreview = new ServicesPreview({})
                    servicepreview.title =this.getTextArray(element.titre,20)
                    servicepreview.description1 =this.getTextArray(element.description1,45)
                    servicepreview.description2 =this.getTextArray(element.description2,45)
                    servicepreview.image = element.image
                    this.slide_services.push(servicepreview)
                }
                for(let  [index,element]  of this.slide_services.entries()){
                    const ref = this.afStorage.ref(this.contrat.contrat.idContrat+'/preResources/'+ element.image);
                    ref.getDownloadURL().subscribe((href)=>{
                        if(href == "" || href == undefined || href == ""){

                        }
                        else{
                            this.slide_services[index].image = href
                        }
                    })
                }
                this.current_slide =   this.slide_services[this.counter]
            }
        });
    }

    setSplashScreen(url: any){
        this.splashScreen = url
    }


    sendSplashScreen(file : File){
        this.splashscreenError = false
        var  self = this;
        let img = new Image()
        img.src = window.URL.createObjectURL(file)
        img.onload = async () => {
                if(img.width == 1125 && img.height == 1780){
                    let refToast = await self.toastr.openTransferToast(`Transfert de données en cours.`, "")
                    var ref = this.afStorage.ref(this.contrat.contrat.idContrat + "/preResources/Splashscreen-Contrat.png");
                    var task = ref.put(file);
                    self.contratservice.contrat.value.localfiles.localSplashScreen = file.name
                    let status = await self.contratservice.saveContrat('')
                        if(status === "success"){
                            self.toastr.clear(refToast.toastId)
                            self.toastr.openSuccessToast('Logo téléversée avec succès', "")
                            task.snapshotChanges()
                                .pipe(last(),switchMap(() => ref.getDownloadURL()))
                                .subscribe((url) => this.setSplashScreen(url))
                        }else{
                            self.toastr.clear(refToast.toastId)
                            self.toastr.openErrorToast(`Téléversement échoué. Veuillez réessayer.`, "")
                        }
                }
                else{
                  this.splashscreenError = true
                  self.toastr.openFailedToast(`Dimensions incorrectes. (1125x1780 requis)`, "")
               }
        }

    }

    async deleteSplashScreen(){
        this.splashscreenError = false
        var ref = this.afStorage.ref(this.contrat.contrat.idContrat + "/preResources/Splashscreen-Contrat.png");
        ref.delete()
        this.splashScreen = ""
        this.contratservice.contrat.value.localfiles.localSplashScreen = ''
        let status = await this.contratservice.saveContrat('')
        if(status === "success"){
            this.toastr.openSuccessToast('Element surpprimé avec succès', "")
        }else{
            this.toastr.openErrorToast('La suppression a échoué. Veuillez réessayer.', "")
        };
    }


    slideRight(){
        var a = 1
        if  (this.counter<this.slide_services.length-1){
            this.counter = this.counter+ 1
            this.current_slide =   this.slide_services[this.counter]
        }

    }

    slideLeft(){

        if  (this.counter>0){
            this.counter = this.counter-1
            this.current_slide =   this.slide_services[this.counter]
        }

    }

    svg_textMultiline(text : string) {

        var x = 0;
        var y = 20;
        var width = 360;
        var lineHeight = 10;



        /* get the text */
        var element = document.getElementById('description1');


        /* split the words into array */
        var words = text.split(' ');
        var line = '';

        /* Make a tspan for testing */
        element.innerHTML = '<tspan id="PROCESSING">busy</tspan >';

        for (var n = 0; n < words.length; n++) {
          var testLine = line + words[n] + ' ';
          var testElem = document.getElementById('PROCESSING');
          /*  Add line in testElement */
          testElem.innerHTML = testLine;
          /* Messure textElement */
          var metrics = testElem.getBoundingClientRect();
          var testWidth = metrics.width;

          if (testLine.length > 30 && n > 0) {
            element.innerHTML += '<tspan x="40" dy="' + y + '">' + line + '</tspan>';
            line = words[n] + ' ';
          } else {
            line = testLine;
          }
        }

        element.innerHTML += '<tspan x="40" dy="' + y + '">' + line + '</tspan>';
        document.getElementById("PROCESSING").remove();

      }

      svg_textMultiline2(text : string) {

        var x = 0;
        var y = 20;
        var width = 360;
        var lineHeight = 10;



        /* get the text */
        var element = document.getElementById('description2');


        /* split the words into array */
        var words = text.split(' ');
        var line = '';

        /* Make a tspan for testing */
        element.innerHTML = '<tspan id="PROCESSING">busy</tspan >';

        for (var n = 0; n < words.length; n++) {
          var testLine = line + words[n] + ' ';
          var testElem = document.getElementById('PROCESSING');
          /*  Add line in testElement */
          testElem.innerHTML = testLine;
          /* Messure textElement */
          var metrics = testElem.getBoundingClientRect();
          var testWidth = metrics.width;

          if (testLine.length > 30 && n > 0) {
            element.innerHTML += '<tspan x="40" dy="' + y + '">' + line + '</tspan>';
            line = words[n] + ' ';
          } else {
            line = testLine;
          }
        }

        element.innerHTML += '<tspan x="40" dy="' + y + '">' + line + '</tspan>';
        document.getElementById("PROCESSING").remove();

      }

      svg_textMultiline3(text : string) {

        var x = 0;
        var y = 20;
        var width = 360;
        var lineHeight = 10;



        /* get the text */
        var element = document.getElementById('titre');


        /* split the words into array */
        var words = text.split(' ');
        var line = '';

        /* Make a tspan for testing */
        element.innerHTML = '<tspan id="PROCESSING">busy</tspan >';

        for (var n = 0; n < words.length; n++) {
          var testLine = line + words[n] + ' ';
          var testElem = document.getElementById('PROCESSING');
          /*  Add line in testElement */
          testElem.innerHTML = testLine;
          /* Messure textElement */
          var metrics = testElem.getBoundingClientRect();
          var testWidth = metrics.width;

          if (testLine.length > 26 && n > 0) {
            element.innerHTML += '<tspan x="40" dy="' + y + '">' + line + '</tspan>';
            line = words[n] + ' ';
          } else {
            line = testLine;
          }
        }

        element.innerHTML += '<tspan x="40" dy="' + y + '">' + line + '</tspan>';
        document.getElementById("PROCESSING").remove();

      }

    getTextArray(text:  string , max : number) : String[]{
        var  array_lines : String[] = [];
        var  words = text.replace(",",", ").split(" ")
        var line  = ""
        for (let word of words ){
           if ((line + word).length <= max){
               line = line + " " +word
           }
           else{
               array_lines.push(line)
               line = ""
               line = line + word
           }
        }

        if (line!= array_lines[array_lines.length-1]){
            array_lines.push(line)
        }

        return array_lines
    }
}

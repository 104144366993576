<div class="row p-3">
    <div class="col-lg-12">
        <div class="row">
            <div class="col-12 d-flex flex-column justify-content-center align-items-lg-start align-items-center">
                <p class="pb-0 mb-0  h3" style="text-align: center;">Ajoutez une actualité dans le fil de l’application
                </p>
                <p class="pb-0 mb-0 mt-0 p2" style="text-align: center;">Votre post sera visible dès sa validation</p>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-4 d-flex flex-row justify-content-end" style="margin: 0 auto;margin-bottom: 3%;" *ngIf="actuImageUrl != ''">
                <img [src]="actuImageUrl" id="imgReport" (click)="show()"  style="height : 150px" >
            </div>
            <div class="col-lg-12  d-flex flex-row justify-content-center justify-content-lg-start">
                <div class="row w-100">
                    <div class="col-lg-10  d-flex flex-row justify-content-center justify-content-lg-start">
                        <ng-container *ngIf='actu.image'>
                            <input type="file" name="file" #fileInput id="file" class="inputfile" (change)="sendactusimage(fileInput.files[0])" />
                            <label for="file" style="cursor: pointer;" class="d-flex flex-row w-100  d-flex flex-row justify-content-center justify-content-lg-start ">
                                <img src="../../../../../assets/img/028-addelementleft.svg" style="width: 35px;height: 35px;">
                                <input type="text" name="file" class="form-control classname w-100 h4"
                                    style="height: 35px;border-radius: 0 10px 10px 0 !important;"
                                    value="{{fileInput.files[0] != null ?fileInput.files[0].name :'Modifier l\'image'}}"
                                    readonly />
                            </label>
                        </ng-container>
                        <ng-container *ngIf='!actu.image'>
                            <input type="file" name="file" #fileInput id="file" class="inputfile" (change)="sendactusimage(fileInput.files[0])" />
                            <label for="file" style="cursor: pointer;" class="d-flex flex-row w-100  d-flex flex-row justify-content-center justify-content-lg-start ">
                                <img src="../../../../../assets/img/028-addelementleft.svg" style="width: 35px;height: 35px;">
                                <input type="text" name="file" class="form-control classname w-100 h4"
                                    style="height: 35px;border-radius: 0 10px 10px 0 !important;"
                                    value="{{fileInput.files[0] != null ?fileInput.files[0].name :'Ajouter une image'}}"
                                    readonly />
                            </label>
                        </ng-container>
                        
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-2 mb-4"  *ngIf="imageactuserror==true">
            <div class="col-lg-12  d-flex flex-row justify-content-center justify-content-lg-start">
                <div class="row w-100">
                    <div class="col-lg-12 mt-3 mt-lg-0 d-flex flex-row justify-content-center justify-content-lg-start align-items-center error pb-0">
                        <i class="fa fa-exclamation-triangle fa-2x" aria-hidden="true" style="width: 30px;height: 30px;"></i>
                        <p class="ml-3 h-100 d-flex flex-row  mb-0 pb-0 justify-content-center align-items-end" style="text-align: center;">L'image de l'actualité est obligatoire</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 d-flex flex-column justify-content-center align-items-lg-start align-items-center">
                <p class="pb-0 mb-0  h3" style="text-align: center;">Titres et sous-titre :
                </p>
                <p class="pb-0 mb-0 mt-0 p2" style="text-align: center;">Il s’agit du titre et du sous-titre qui s’affichent dans le fil d’actualité.</p>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-lg-12 d-flex flex-column justify-content-center align-items-lg-start align-items-center">
                <input type="text" *ngIf="actu != undefined" ngClass="{{titleactuserror ? 'colorerrorinput' : 'colornoterrorinput'}}" style="font-family:Arial, FontAwesome" autocomplete="off" (focus)="titleactuserror = false"  placeholder="{{titleactuserror ? '&#xf071; Champs obligatoire':'Titre'}}"  [(ngModel)]="actu.titre" (ngModelChange)="onChange()"   placeholder="Titre" class="form-control input1"  required>
                <p class="p3">{{actu.titre.length}}/180</p>
            </div>
        </div>

        <div class="row mt-2">
            <div class="col-lg-12 d-flex flex-column justify-content-center align-items-lg-start align-items-center">
                <textarea type="text" *ngIf="actu != undefined"  ngClass="{{descriptionactuserror ? 'colorerrorinput' : 'colornoterrorinput'}}"  style="font-family:Arial, FontAwesome" autocomplete="off" (focus)="descriptionactuserror = false"  placeholder="{{descriptionactuserror ? '&#xf071; Champs obligatoire':'Description'}}"  [(ngModel)]="actu.description" rows="6" (ngModelChange)="onChange()"   placeholder="Description" class="form-control input1"  required></textarea>
                <p class="p3">{{actu.description.length}}/180</p>
            </div>
        </div>

        <div class="row mt-2">
            <div class="" style="width: 80%;align-items: center;display: flex;margin-left: 3%;">
            <p class="pb-0 mb-0  h3" style="width: 35%;">Periode : </p>
            <form class="form-inline" style="width: 100%;">
                <div class="form-group hidden">
                  <div class="input-group" style="width: 100%;">
                    <input name="datepicker" style="width: 100%;"
                           class="form-control p2 input1"
                           ngbDatepicker
                           #datepicker="ngbDatepicker"
                           [autoClose]="'outside'"
                           (dateSelect)="onDateSelection($event)"
                           [displayMonths]="1"
                           [dayTemplate]="t"
                           outsideDays="hidden"
                           [startDate]="fromDate!"
                           tabindex="-1">
                    <ng-template #t let-date let-focused="focused" style="width: 100%;">
                      <span class="custom-day"
                            [class.focused]="focused"
                            [class.range]="isRange(date)"
                            [class.faded]="isHovered(date) || isInside(date)"
                            (mouseenter)="hoveredDate = date"
                            (mouseleave)="hoveredDate = null">
                        {{ date.day }}
                      </span>
                    </ng-template>
                  </div>
                </div>
                <div class="form-group" style="width: 100%;">
                  <div class="input-group" style="width: 100%;">
                    <input #dpFromDate
                                   class="form-control p2" placeholder="yyyy-mm-dd"
                                   name="dpFromDate"
                                   [value]="'Du '+formatter.format(fromDate)+' au '+ formatter.format(toDate)"
                                   (input)="fromDate = validateInput(fromDate, dpFromDate.value);toDate = validateInput(toDate, dpFromDate.value)">
                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary input1" [disabled]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')"  (click)="datepicker.toggle()" type="button">
                        <i class="fa fa-calendar" style="color: f47000;" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
              </div>
        </div>

        <div class="row mt-2">
            <div class="col-lg-6 d-flex flex-row align-items-center">
                <p class="pb-0 mb-0  h3 ">Epingler :</p>
                <div class="btn-group mt-2" style="margin-left: 14%;" role="group" id="{{'item'}}">
                    <input type="radio" name="{{'item'}}"  [value]="true"  [(ngModel)]="actu.epingler" (ngModelChange)="onchange($event)" id="{{'radiobtoui'}}"/>
                    <label class="btn btn-secondary radius_left_actus" id="{{'radiooui'}}" for="{{'radiobtoui'}}">
                        Oui
                    </label>
                    <input type="radio" name="{{'item'}}" [value]="false" [(ngModel)]="actu.epingler"  (ngModelChange)="onchange($event)"    class="radio_input radio_non"   id="{{'radiobtnon'}}"/>
                    <label  class="btn btn-secondary radius_right_actus" id="{{'radionon'}}"   for="{{'radiobtnon'}}">
                        Non
                    </label>
                </div>
            </div>
        </div>

        <div class="row mt-2">
            <div class="col-lg-12 d-flex flex-column justify-content-center align-items-lg-start align-items-center">
                <input type="text" *ngIf="actu != undefined" style="font-family:Arial, FontAwesome" ngClass="{{urlactuserror ? 'colorerrorinput' : 'colornoterrorinput'}}" autocomplete="off" (focus)="urlactuserror = false"  placeholder="{{urlactuserror ? '&#xf071; Champs obligatoire':'URL'}}"  [(ngModel)]="actu.url" (ngModelChange)="onChange()"   placeholder="URL" class="form-control input1" >
            </div>
        </div>

        <div class="row mb-4 mt-4">
            <div class="col-lg-4">
                <button type="button" class="btn button4 btn-primary w-100" (click)="saveactus()">Publier</button>
            </div>
            <div class="col-lg-4 mt-3 mt-lg-0 ">
                <button type="button" class="btn button7 btn-primary w-100" (click)="cancel()">Annuler</button>
            </div>
        </div>

    </div>
</div>



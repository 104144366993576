import { AngularFirestore } from '@angular/fire/firestore';
import { UserBo } from 'src/app/models/user/user';
import { MenuService } from './../../../../services/menu/menu.service';
import { SliderService } from './../../../../services/slider/slider.service';
import { element } from 'protractor';
import { config } from './../../../../models/contrat/contrat';
import { UserService } from './../../../../services/user/user.service';
import { ContratService } from './../../../../services/contrat/contrat.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Contrat } from 'src/app/models/contrat/contrat';

@Component({
    selector: 'app-welcome',
    templateUrl: './welcome.component.html',
    styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {

    contrat : Contrat
    step : string
    state : string =''
    user : UserBo
    backToModify = false
    constructor(
    private router: Router,
    private contratservice : ContratService,
    private userservice : UserService,
    private  sliderService : SliderService,
    private menuservice :  MenuService,
    private afs : AngularFirestore
    ) { }

    ngOnInit(): void {
        this.menuservice.setSection("paramétrage");
        this.menuservice.setSubsection("");
        this.userservice.user_obs.subscribe((user)=>{
            if(user != null){
                this.user = user
                if(this.user.role.indexOf("Agent") > -1){
                    this.router.navigateByUrl('/montri/reportingstats')
                }
                this.contratservice.contrat_obs.subscribe((contrat)=>{
                    if(contrat != undefined && contrat != null && contrat.contrat.idContrat!='BOTEST'){
                        this.contrat = contrat
                        this.contratservice.contrat_obs.subscribe((contrat)=>{
                            this.sliderService.setTempServices(contrat.services)
                        })
                        this.contrat.configstatus[0].status = user.configStatus
                        if(this.contrat.contratstatus =="en_attente"){
                            this.step = "Paramétrage en attente de validation"
                        }
                        else if (this.contrat.contratstatus == "valide"){
                            this.step = "Mettre en ligne"
                        }
                        else if (this.contrat.configstatus.every(this.checkstatus)){
                            this.step = "Valider votre paramétrage"
                        }
                        else{
                            this.step = this.contrat.configstatus.filter(item => (item.status == false && item.active == true) )[0].ConfigName
                        }
                        this.contrat.configstatus[6].active = this.contrat.services.filter(service =>service.nom == "Carte des points de collecte")[0].active
                        this.contrat.configstatus[7].active = this.contrat.services.filter(service =>service.nom == "Calendrier de collecte")[0].active

                        this.getState()
                    }
                })
            }
        })
    }

    checkstatus(element : config){
        return element.status
    }

    goToStep(step : string) {
        if (step == "Configurez votre compte Utilisateur"){
            this.router.navigateByUrl('/montri/account')
        }

        if (step == "Paramétrez les informations générales de votre compte"){
            this.router.navigateByUrl('/montri/contractsettings/informations')
        }

        if (step == "Paramétrez vos règles de tri et couleurs de poubelles"){
            this.router.navigateByUrl('/montri/contractsettings/recyclinginstructions')
        }

        if (step == "Paramétrez votre design"){
            this.router.navigateByUrl('/montri/contractsettings/design')
        }

        if (step == "Sélectionnez vos services"){
            this.router.navigateByUrl('/montri/contractsettings/services')
        }

        if (step == "Paramétrez votre slider"){
            this.router.navigateByUrl('/montri/contractsettings/slider')
        }

        if (step == "Chargez les coordonnées de vos points d’apport volontaire"){
            this.router.navigateByUrl('/montri/cartsettings/coordinate')
        }


        if (step == "Chargez votre calendrier de collecte"){
            this.router.navigateByUrl('/montri/calendarsettings')
        }

        if(step == "Valider votre paramétrage"){
            this.contrat.contratstatus = "en_attente"
            this.contrat.validateur = this.user.email
            this.contratservice.saveContrat('')
            this.step = "Paramétrage en attente de validation"
        }

        if(step == "Mettre en ligne"){
            this.backToModify = true
            this.contrat.contrat.appLive = true
            this.contrat.contratstatus = "en_modification"
            this.contratservice.saveContrat('')
            this.step = "Valider votre paramétrage"
        }

        this.getState()
    }

    updateContrat(){
        this.backToModify = false
        this.contrat.contrat.appLive = true
        this.contrat.contratstatus = "en_modification"
        this.contratservice.saveContrat('')
        this.step = "Valider votre paramétrage"
    }

    getState(){

        if(this.contrat.configstatus[0].status == false && this.contrat.contrat.appLive == false ){
            this.state = "config_compte"
        }

        else if(this.contrat.configstatus[1].status == false && this.contrat.contratstatus == "en_modification" && !this.contrat.configstatus.every(element => element.status==true )  && this.contrat.contrat.appLive == false ){
            this.state = "premier_modification"
        }

        else if(this.contrat.contratstatus == "en_modification" && !this.contrat.configstatus.every(element => element.status==true)  && this.contrat.contrat.appLive == false ){
            this.state = "modification"
        }

        else if(this.contrat.contratstatus == "en_modification" && this.contrat.configstatus.every(element => element.status==true)  && this.contrat.contrat.appLive == false ){
            this.state = "complet"
        }

        else if(this.contrat.contratstatus == "en_attente" &&  this.contrat.firstActus == false  && this.contrat.contrat.appLive == false ){
            this.state = "attente_validation_first_actus"
        }

        else if(this.contrat.contratstatus == "en_attente" &&  this.contrat.firstActus == true  && this.contrat.contrat.appLive == false ){
            this.state = "attente_validation"
        }

        else if(this.contrat.contratstatus == "valide"  && this.contrat.contrat.appLive == false ){
            this.state = "valide"
        }
        else if (this.contrat.contrat.appLive == true){
            this.contrat.contratstatus == "en_ligne"
            this.state = "en_ligne"
        }


    }
}

import { MenuService } from './../../../../services/menu/menu.service';
import { MsgserviceService } from './../../../../services/msgservice/msgservice.service';
import { ContractStats} from './../../../../models/contrat/contrat';
import { ContratService } from './../../../../services/contrat/contrat.service';
import { element } from 'protractor';
import { scanAppEvolutionResponse, instructionAppRecognitionResponse, newUsersAppEvolutionResponse, accountAppDistributionResponse, retentionRateResponse, userAppActiveResponse, scanAppNbrResponse } from './../../../../models/responses/response';
import { AppstatsService } from './../../../../services/appstats/appstats.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common'
import * as $ from 'jquery';
import { Contrat } from 'src/app/models/contrat/contrat';
import { GoogleChartComponent } from 'angular-google-charts';
import { timeStamp } from 'console';

@Component({
    selector: 'app-appstats',
    templateUrl: './appstats.component.html',
    styleUrls: ['./appstats.component.scss']
})

export class AppstatsComponent implements OnInit {

    wrapperNSAChart : any = undefined
    wrapperNNUChart : any = undefined
    contrat : Contrat
    contractstats : ContractStats
    timeType = 'DAY'
    TRCvalue =  '0 Scan'
    TRPvalue =  '0 Scan'
    RCUvalue = '0 Utilisateur'
    TR30value = "0 Scan"
    date : string = '0000-00-00'
    current_date : Date = new Date()
    dayBefor : string = ''
    monthBefor : string = ''
    yearBefor : string = ''
    hiddenNSAChart :boolean = true
    hiddenNSASpinner : boolean = false
    hiddenNNUChart :boolean = true
    hiddenNNUSpinner : boolean = false
    hiddenTRPChart :boolean = true
    hiddenTRPSpinner : boolean = false
    hiddenTRCChart :boolean = true
    hiddenTRCSpinner : boolean = false
    hiddenRCUChart :boolean = true
    hiddenRCUSpinner : boolean = false
    hiddenTR30Chart :boolean = true
    hiddenTR30pinner : boolean = false
    hiddenUsersNbr : boolean = true
    hiddenUsersNbrSpinner : boolean = false
    hiddenUsersActive : boolean = true
    hiddenUsersActiveSpinner : boolean = false
    hiddenScansNbr : boolean = true
    hiddenScansNbrSpinner : boolean = false
    hiddenKlmVoiture : boolean = true
    hiddenKlmVoitureSpinner : boolean = false
    usersAppNumber: number = 0
    scanAppEvolution : number = 0
    scanAppNumber : number = 0
    userActiveAppNumber : number = 0
    userActiveAppEvolution : number = 0
    @ViewChild('NSAChartView', {static: false}) NSAChartView: GoogleChartComponent
    @ViewChild('NNUChartView', {static: false}) NNUChartView: GoogleChartComponent
    days = ['DIM', 'LUN', 'MAR', 'MER', 'JEU', 'VEN', 'SAM'];
    months = ['JAN','FEV','MAR','AVR','MAI','JUN','JUL','AOU','SEP','OCT','NOV','DEC']
    alldates = [
        '01', '02', '03', '04', '05', '06', '07', '08', '09', '10',
        '11', '12', '13', '14', '15', '16', '17', '18', '19', '20',
        '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'
    ];
    // chart  for  "Nombre de Scans Appli : NSA"
    NSAChartType = 'AreaChart';
    NSAChartData = [
        ["0", 300],
        ["1", 2570],
        ["2", 2200],
        ["3", 3660],
        ["4", 2570],
        ["5", 300]
    ];

    NSAChartOptions = {
        colors: ['#B9CEF9'],
        lineWidth: 5,
        legend: 'none',
        vAxis: {
            baselineColor: 'none',
            ticks: []
        },
        pointSize: 20,
        pointShape: 'circle',
        hAxis: { gridlines: { count: 10 } },
        chartArea: {
            width: '100%',
        },
        width: '100%',
        height : '200'
    }

    // chart  for  "Taux de Recnnaissance Produits:TRP"
    TRPChartType = "PieChart"

    TRPChartData = [
        ['Produits reconnu', 3400],
        ['Produits non reconnu', 800],
    ];

    TRPChartOptions = {
        width: '160',
        height: '160',
        pieHole: 0.9,
        legend: 'none',
        colors: ['#B9CEF9', '#E2E2E2'],
        chartArea: {
            top: '10',
            left: '10',
            right: '10',
            bottom: '10',
            width: '100%',
            height: '100%'
        }
    };


    // chart  for  "Taux de Recnnaissance Consigne: TRC"
    TRCChartType = "PieChart"
    TRCChartData = [
        ['Produits reconnu', 3400],
        ['Produits non reconnu', 800],
    ];
    TRCChartOptions = {
        width: '160',
        height: '160',
        pieHole: 0.9,
        legend: 'none',
        colors: ['#B9CEF9', '#E2E2E2'],
        chartArea: {
            top: '10',
            left: '10',
            right: '10',
            bottom: '10',
            width: '100%',
            height: '100%'
        }
    };

    //chart  for  "Nombre de Nouveaux Utilisateurs: NNU"
    NNUChartType = "ColumnChart"

    NNUChartData = [
        ['JAN', 1000],
        ['FEB', 900],
        ['MAR', 1500],
        ['APR', 3000],
        ['MAY', 2500],
        ['JUN', 2750],
        ['JUL', 1990],
        ['AUG', 2300],
        ['SEP', 2400],
        ['OCT', 3000],
    ];

    NNUChartOptions = {
        legend: { position: "none" },
        bar: { groupWidth: 60 },
        colors: ['#B9CEF9'],
        hAxis: { gridlines: { count: 10 } },
        chartArea: {
            bottom: '50',
            left: '50',
            right: '10',
            top: '30',
            width: '90%',

        },
        width: '90%',
        height: '500',
        textStyle: {
            fontSize: 12,
            bold: true,
        }
    };

    // chart  for  "Répartition Compte Utilisateur / Anonyme: RCUA"
    RCUAChartType = "PieChart"

    RCUAChartData = [
        ['Compte', 3400],
        ['Anonyme', 800],
    ];

    RCUAChartOptions = {
        width: '370',
        height: '350',
        pieHole: 0.6,
        legend: { position: 'right', alignment: 'center' },
        colors: ['#739df3', '#d0defb'],
        chartArea: {
            top: '0',
            bottom: '10',
            left : '10',
            width: '100%',
            height: '100%'
        }
    };

    isListenning = false

    // chart  for  "Taux de rétention à 30 jours: TR30"
    TR30ChartType = "PieChart"

    TR30ChartData = [
        [`X % des utilisateurs utilisent toujours l\'application 30 jours après installation`, 3400],
        [`X % des utilisateurs utilisent toujours l\'application 30 jours après installation`, 800],
    ];

    TR30ChartOptions = {
        width: '370',
        height: '350',
        pieHole: 0.6,
        legend: { position: 'right', alignment: 'center' },
        colors: ['#739df3', '#d0defb'],
        chartArea: {
            top: '0',
            bottom: '10',
            left:'10',
            width: '100%',
            height: '100%'
        }
    };

    constructor(
        private appstatsService: AppstatsService,
        public datepipe: DatePipe,
        private contratservice : ContratService,
        private messageService : MsgserviceService,
        private menuservice : MenuService
    ) { }

    ngOnInit(): void {
        this.menuservice.setSection("statistiques");
        this.menuservice.setSubsection("stats");
        var  self =  this ;
        this.messageService.menuStatus_obs.subscribe((status)=>{
            setTimeout(function(){
                if(self.wrapperNSAChart != undefined && self.wrapperNNUChart != undefined){
                    self.wrapperNNUChart.draw(document.getElementById('NNUChart'))
                    self.wrapperNSAChart.draw(document.getElementById('chartNCA'))
                }
            }, 400);

        })
        //this.current_date.setDate(this.current_date.getDate() - 1)
        this.date =  this.datepipe.transform(this.current_date, 'yyyy-MM-dd');
        this.contratservice.contrat_obs.subscribe((contrat)=>{
            this.contrat = contrat
            if(this.contrat.contrat.idContrat != 'BOTEST'){
                if(!this.isListenning) {
                    this.isListenning = true
                    this.contratservice.loadStats(contrat.contrat.idContrat)
                    this.contratservice.contractStats_obs.subscribe((stats)=>{
                        if(stats != null  && stats != undefined){
                            stats.kmVoiture =   Math.floor(Math.round(parseFloat(stats.kmVoiture)* 100) /100).toString()
                            this.contractstats = stats
                        }

                    })
                    this.statsPerDay()
                }
            }
        })



    }

    statsPerDay() {
        this.getDayBefor()
        this.hiddenNSAChart  = true
        this.hiddenNSASpinner  = false
        this.hiddenNNUChart = true
        this.hiddenNNUSpinner  = false
        this.hiddenTRPChart  = true
        this.hiddenTRPSpinner  = false
        this.hiddenTRCChart  = true
        this.hiddenTRCSpinner  = false
        this.hiddenRCUChart  = true
        this.hiddenRCUSpinner  = false
        this.hiddenTR30Chart  = true
        this.hiddenTR30pinner  = false
        this.hiddenUsersNbr  = true
        this.hiddenUsersNbrSpinner  = false
        this.hiddenUsersActive  = true
        this.hiddenUsersActiveSpinner  = false
        this.hiddenScansNbr = true
        this.hiddenScansNbrSpinner  = false
        this.hiddenKlmVoiture  = true
        this.hiddenKlmVoitureSpinner  = false

        let date = this.date;

        this.appstatsService.getUserNbr(this.contrat.contrat.idContrat)
            .subscribe(result => {
                var b = result['result'][0][0]
                this.usersAppNumber = result['result'][0][0]['userAppNbr']
                this.hiddenUsersNbr = false
                this.hiddenUsersNbrSpinner = true
                this.hiddenKlmVoiture = false
                this.hiddenKlmVoitureSpinner  = true
        })

        this.appstatsService.getScanAppEvolution(this.contrat.contrat.idContrat, this.date, this.timeType)
            .subscribe(result => {
                var response = result as scanAppEvolutionResponse;
                    for (let i = 0; i <= 6; i++) {
                        var index = response.result[0].findIndex(x => x.time_ago == i)
                        if (index === -1) {
                            response.result[0].push({ 'time_ago': i, 'scansNbr': 0 })
                        }
                    }
                    response.result[0] = response.result[0].sort(
                        function (a, b) {
                            return a.time_ago - b.time_ago;
                        }
                    )
                    var evolution = []
                    for (let element of response.result[0].reverse()) {
                        var d = new Date()
                        d.setDate(this.current_date.getDate() - element.time_ago)
                        evolution.push([this.days[d.getDay()], element.scansNbr])
                    }
                    this.NSAChartData = evolution
                    this.hiddenNSAChart = false
                    this.hiddenNSASpinner = true
            })

        this.appstatsService.getInstructionAppRecognition(this.contrat.contrat.idContrat,this.date, this.timeType)
            .subscribe(result => {
                var response = result as instructionAppRecognitionResponse;
                this.TRCChartData = [['ConsigneS reconnu',response.result[0][0].reconnu],['ConsigneS non reconnu',response.result[0][0].non_reconnu]]
                if(response.result[0][0].reconnu == 0 &&  response.result[0][0].non_reconnu == 0 ){
                    this.TRCvalue =  "0 Scan"
                }
                else{
                    const TRC =  (response.result[0][0].reconnu/(response.result[0][0].reconnu +response.result[0][0].non_reconnu))*100
                    this.TRCvalue =  (Math.round(TRC*10)/10).toString() +"%"
                }
                this.hiddenTRCChart = false
                this.hiddenTRCSpinner = true
        })



        this.appstatsService.getProductAppRecognition(this.contrat.contrat.idContrat,this.date, this.timeType)
            .subscribe(result => {
                var response = result as instructionAppRecognitionResponse;
                this.TRPChartData = [['Produits reconnu',response.result[0][0].reconnu],['Produits non reconnu',response.result[0][0].non_reconnu]]
                if(response.result[0][0].reconnu == 0 &&  response.result[0][0].non_reconnu == 0 ){
                    this.TRPvalue =  "0 Scan"
                }
                else{
                    const TRP =  (response.result[0][0].reconnu/(response.result[0][0].reconnu +response.result[0][0].non_reconnu))*100
                    this.TRPvalue =  (Math.round(TRP*10)/10).toString() +"%"
                }
                this.hiddenTRPChart = false
                this.hiddenTRPSpinner = true
        })

        this.appstatsService.getNewUsersAppEvolution(this.contrat.contrat.idContrat, this.date, this.timeType)
            .subscribe(result => {
                var response = result as newUsersAppEvolutionResponse;
                    for (let i = 0; i <= 6; i++) {
                        var index = response.result[0].findIndex(x => x.time_ago == i)
                        if (index === -1) {
                            response.result[0].push({ 'time_ago': i, 'newUsersNbr': 0 })
                        }
                    }
                    response.result[0] = response.result[0].sort(
                        function (a, b) {
                            return a.time_ago - b.time_ago;
                        }
                    )
                    var evolution = []
                    for (let element of response.result[0].reverse()) {
                        var d = new Date()
                        d.setDate(this.current_date.getDate() - element.time_ago)
                        evolution.push([this.days[d.getDay()], element.newUsersNbr])
                    }

                    this.NNUChartData = evolution
                    this.hiddenNNUChart = false
                    this.hiddenNNUSpinner = true
        })

        this.appstatsService.getAccountAppDistribution(this.contrat.contrat.idContrat,this.date, this.timeType)
            .subscribe(result => {
                var response = result as accountAppDistributionResponse;
                this.RCUAChartData = [['comptes',response.result[0][0].non_anonymous],['comptes anonymes',response.result[0][0].anonymous]]
                if(response.result[0][0].non_anonymous == 0 &&  response.result[0][0].anonymous == 0 ){
                    this.RCUvalue =  "0 Compte"
                }
                else{
                    const RCU =  (response.result[0][0].non_anonymous/(response.result[0][0].non_anonymous +response.result[0][0].anonymous))*100
                    this.RCUvalue = (Math.round(RCU*10)/10).toString() +"%"
                }
                this.hiddenRCUChart = false
                this.hiddenRCUSpinner = true
        })

        this.appstatsService.getRetentionRate(this.contrat.contrat.idContrat,this.date,'MONTH')
            .subscribe(result => {
                var response = result as retentionRateResponse;
                const TR30active =  (response.result[0][0].activeUsers/(response.result[0][0].activeUsers +response.result[0][0].passiveUsers))*100
                const TR30activeFix = TR30active.toFixed(1)
                const TR30passive = (response.result[0][0].passiveUsers/(response.result[0][0].activeUsers +response.result[0][0].passiveUsers))*100
                const TR30passiveFix = TR30passive.toFixed(1)
                this.TR30ChartData = [[`${TR30activeFix}% des utilisateurs utilisent toujours l\'application 30 jours après installation`,response.result[0][0].activeUsers],[`${TR30passiveFix}% des utilisateurs n'utilisent plus l\'application 30 jours après installation`,response.result[0][0].passiveUsers]]
                if(response.result[0][0].activeUsers == 0 &&  response.result[0][0].passiveUsers == 0 ){
                    this.TR30value =  "0 Scan"
                }
                else{
                    this.TR30value = (Math.round(TR30active*10)/10).toString() +"%"
                }
                this.hiddenTR30Chart = false
                this.hiddenTR30pinner = true

        })

        this.appstatsService.getUserAppActive(this.contrat.contrat.idContrat,this.date, this.timeType)
            .subscribe(result => {
                this.usersAppNumber;
                var response = result as userAppActiveResponse;
                //(TimeCurrent - TimeBefore) / TimeBefore * 100 <--
                //((TimeCurrent - TimeBefore) / TimeBefore)*100
                this.userActiveAppNumber = (Math.round(response.result[0][0].TimeCurrent / response.result[0][0].allUsers * 100))
                if(response.result[0][0].TimeBefore != 0){
                    //this.userActiveAppEvolution =  (Math.round((response.result[0][0].TimeCurrent - response.result[0][0].TimeBefore)/response.result[0][0].TimeBefore *100))
                    this.userActiveAppEvolution = this.userActiveAppNumber - (Math.round(response.result[0][0].TimeBefore / response.result[0][0].allUsers * 100)) 
                }else{
                    this.userActiveAppEvolution =  this.userActiveAppNumber
                }
                this.hiddenUsersActive = false
                this.hiddenUsersActiveSpinner = true

        })


        this.appstatsService.getScanAppNbr(this.contrat.contrat.idContrat,this.date, this.timeType)
            .subscribe(result => {
                var response = result as scanAppNbrResponse;
                //(NbrScansCurrent - NbrScansBefore) / NbrScansBefore * 100
                this.scanAppNumber =  (Math.round(response.result[0][0].NbrScansCurrent))
                if(response.result[0][0].NbrScansBefore != 0){
                    this.scanAppEvolution = (Math.round((response.result[0][0].NbrScansCurrent - response.result[0][0].NbrScansBefore)/response.result[0][0].NbrScansBefore *100))
                }else{
                    this.scanAppEvolution = this.scanAppNumber
                }
                this.hiddenScansNbr = false
                this.hiddenScansNbrSpinner = true

        })
    }


    statsPerMonth() {
        this.getmonthBefor()
        this.NSAChartOptions['hAxis']['gridlines']['count'] = 12
        this.NNUChartOptions['hAxis']['gridlines']['count'] = 12
        this.hiddenNSAChart  = true
        this.hiddenNSASpinner  = false
        this.hiddenNNUChart = true
        this.hiddenNNUSpinner  = false
        this.hiddenTRPChart  = true
        this.hiddenTRPSpinner  = false
        this.hiddenTRCChart  = true
        this.hiddenTRCSpinner  = false
        this.hiddenRCUChart  = true
        this.hiddenRCUSpinner  = false
        this.hiddenTR30Chart  = true
        this.hiddenTR30pinner  = false
        this.hiddenUsersNbr  = true
        this.hiddenUsersNbrSpinner  = false
        this.hiddenUsersActive  = true
        this.hiddenUsersActiveSpinner  = false
        this.hiddenScansNbr = true
        this.hiddenScansNbrSpinner  = false
        this.hiddenKlmVoiture  = true
        this.hiddenKlmVoitureSpinner  = false

        let date = this.date;

        this.appstatsService.getUserNbr(this.contrat.contrat.idContrat)
            .subscribe(result => {
                var b = result['result'][0][0]
                this.usersAppNumber = result['result'][0][0]['userAppNbr']
                this.hiddenUsersNbr = false
                this.hiddenUsersNbrSpinner = true
                this.hiddenKlmVoiture = false
                this.hiddenKlmVoitureSpinner  = true
        })

        this.appstatsService.getScanAppEvolution(this.contrat.contrat.idContrat, this.date, this.timeType)
            .subscribe(result => {
                var response = result as scanAppEvolutionResponse;
                    for (let i = 0; i <= 11; i++) {
                        var index = response.result[0].findIndex(x => x.time_ago == i)
                        if (index === -1) {
                            response.result[0].push({ 'time_ago': i, 'scansNbr': 0 })
                        }
                    }
                    response.result[0] = response.result[0].sort(
                        function (a, b) {
                            return a.time_ago - b.time_ago;
                        }
                    )
                    var evolution = []
                    for (let element of response.result[0].reverse()) {
                        var d = new Date()
                        d.setMonth(this.current_date.getMonth() - element.time_ago)
                        evolution.push([this.months[d.getMonth()], element.scansNbr])
                    }
                    this.NSAChartData = evolution
                    this.hiddenNSAChart = false
                    this.hiddenNSASpinner = true
            })

        this.appstatsService.getInstructionAppRecognition(this.contrat.contrat.idContrat,this.date, this.timeType)
            .subscribe(result => {
                var response = result as instructionAppRecognitionResponse;
                this.TRCChartData = [['Produits reconnu',response.result[0][0].reconnu],['Produits non reconnu',response.result[0][0].non_reconnu]]
                if(response.result[0][0].reconnu == 0 &&  response.result[0][0].non_reconnu == 0 ){
                    this.TRCvalue =  "0 Scan"
                }
                else{
                    const TRC =  (response.result[0][0].reconnu/(response.result[0][0].reconnu +response.result[0][0].non_reconnu))*100
                    this.TRCvalue =  (Math.round(TRC*10)/10).toString() +"%"
                }
                this.hiddenTRCChart = false
                this.hiddenTRCSpinner = true
        })

        this.appstatsService.getProductAppRecognition(this.contrat.contrat.idContrat,this.date, this.timeType)
        .subscribe(result => {
            var response = result as instructionAppRecognitionResponse;
            this.TRPChartData = [['Produits reconnu',response.result[0][0].reconnu],['Produits non reconnu',response.result[0][0].non_reconnu]]
            if(response.result[0][0].reconnu == 0 &&  response.result[0][0].non_reconnu == 0 ){
                this.TRPvalue =  "0 Scan"
            }
            else{
                const TRP =  (response.result[0][0].reconnu/(response.result[0][0].reconnu +response.result[0][0].non_reconnu))*100
                this.TRPvalue =  (Math.round(TRP*10)/10).toString() +"%"
            }
            this.hiddenTRPChart = false
            this.hiddenTRPSpinner = true
    })

        this.appstatsService.getNewUsersAppEvolution(this.contrat.contrat.idContrat, this.date, this.timeType)
            .subscribe(result => {
                var response = result as newUsersAppEvolutionResponse;
                    for (let i = 0; i <= 11; i++) {
                        var index = response.result[0].findIndex(x => x.time_ago == i)
                        if (index === -1) {
                            response.result[0].push({ 'time_ago': i, 'newUsersNbr': 0 })
                        }
                    }
                    response.result[0] = response.result[0].sort(
                        function (a, b) {
                            return a.time_ago - b.time_ago;
                        }
                    )
                    var evolution = []
                    for (let element of response.result[0].reverse()) {
                        var d = new Date()
                        d.setMonth(this.current_date.getMonth() - element.time_ago)
                        evolution.push([this.months[d.getMonth()], element.newUsersNbr])
                    }

                    this.NNUChartData = evolution
                    this.hiddenNNUChart = false
                    this.hiddenNNUSpinner = true
        })

        this.appstatsService.getAccountAppDistribution(this.contrat.contrat.idContrat,this.date, this.timeType)
            .subscribe(result => {
                var response = result as accountAppDistributionResponse;
                this.RCUAChartData = [['comptes',response.result[0][0].non_anonymous],['comptes anonymes',response.result[0][0].anonymous]]
                if(response.result[0][0].non_anonymous == 0 &&  response.result[0][0].anonymous == 0 ){
                    this.RCUvalue =  "0 Compte"
                }
                else{
                    const RCU =  (response.result[0][0].non_anonymous/(response.result[0][0].non_anonymous +response.result[0][0].anonymous))*100
                    this.RCUvalue = (Math.round(RCU*10)/10).toString() +"%"
                }
                this.hiddenRCUChart = false
                this.hiddenRCUSpinner = true
        })

        this.appstatsService.getRetentionRate(this.contrat.contrat.idContrat,this.date,'MONTH')
            .subscribe(result => {
                var response = result as retentionRateResponse;
                const TR30active =  Math.round((response.result[0][0].activeUsers/(response.result[0][0].activeUsers +response.result[0][0].passiveUsers))*100)
                const TR30activeFix = TR30active.toFixed(1)
                const TR30passive = Math.round((response.result[0][0].passiveUsers/(response.result[0][0].activeUsers +response.result[0][0].passiveUsers))*100)
                const TR30passiveFix = TR30passive.toFixed(1)
                this.TR30ChartData = [[`${TR30activeFix}% des utilisateurs utilisent toujours l\'application 30 jours après installation`,response.result[0][0].activeUsers],[`${TR30passiveFix}% des utilisateurs n'utilisent plus l\'application 30 jours après installation`,response.result[0][0].passiveUsers]]
                if(response.result[0][0].activeUsers == 0 &&  response.result[0][0].passiveUsers == 0 ){
                    this.TR30value =  "0 Scan"
                }
                else{
                    this.TR30value = (Math.round(TR30active*10)/10).toString() +"%"
                }
                this.hiddenTR30Chart = false
                this.hiddenTR30pinner = true

        })

        this.appstatsService.getUserAppActive(this.contrat.contrat.idContrat,this.date, this.timeType)
            .subscribe(result => {
                var response = result as userAppActiveResponse;
                //(TimeCurrent - TimeBefore) / TimeBefore * 100 <--
                //((TimeCurrent - TimeBefore) / TimeBefore)*100
                this.userActiveAppNumber = (Math.round(response.result[0][0].TimeCurrent / response.result[0][0].allUsers * 100))
                if(response.result[0][0].TimeBefore != 0){
                    //this.userActiveAppEvolution =  (Math.round((response.result[0][0].TimeCurrent - response.result[0][0].TimeBefore)/response.result[0][0].TimeBefore *100))
                    this.userActiveAppEvolution = this.userActiveAppNumber - (Math.round(response.result[0][0].TimeBefore / response.result[0][0].allUsers * 100)) 
                }else{
                    this.userActiveAppEvolution =  this.userActiveAppNumber
                }
                this.hiddenUsersActive = false
                this.hiddenUsersActiveSpinner = true

        })

        this.appstatsService.getScanAppNbr(this.contrat.contrat.idContrat,this.date, this.timeType)
            .subscribe(result => {
                var response = result as scanAppNbrResponse;
                //(NbrScansCurrent - NbrScansBefore) / NbrScansBefore * 100
                this.scanAppNumber =  (Math.round(response.result[0][0].NbrScansCurrent))
                if(response.result[0][0].NbrScansBefore != 0){
                    this.scanAppEvolution = (Math.round((response.result[0][0].NbrScansCurrent - response.result[0][0].NbrScansBefore)/response.result[0][0].NbrScansBefore *100))
                }else{
                    this.scanAppEvolution = this.scanAppNumber
                }
                this.hiddenScansNbr = false
                this.hiddenScansNbrSpinner = true
        })
    }


    statsPerYear() {
        this.getyearBefor()
        this.NSAChartOptions['hAxis']['gridlines']['count'] = 6
        this.NNUChartOptions['hAxis']['gridlines']['count'] = 6
        this.hiddenNSAChart  = true
        this.hiddenNSASpinner  = false
        this.hiddenNNUChart = true
        this.hiddenNNUSpinner  = false
        this.hiddenTRPChart  = true
        this.hiddenTRPSpinner  = false
        this.hiddenTRCChart  = true
        this.hiddenTRCSpinner  = false
        this.hiddenRCUChart  = true
        this.hiddenRCUSpinner  = false
        this.hiddenTR30Chart  = true
        this.hiddenTR30pinner  = false
        this.hiddenUsersNbr  = true
        this.hiddenUsersNbrSpinner  = false
        this.hiddenUsersActive  = true
        this.hiddenUsersActiveSpinner  = false
        this.hiddenScansNbr = true
        this.hiddenScansNbrSpinner  = false
        this.hiddenKlmVoiture  = true
        this.hiddenKlmVoitureSpinner  = false

        let date = this.date;

        this.appstatsService.getUserNbr(this.contrat.contrat.idContrat)
            .subscribe(result => {
                var b = result['result'][0][0]
                this.usersAppNumber = result['result'][0][0]['userAppNbr']
                this.hiddenUsersNbr = false
                this.hiddenUsersNbrSpinner = true
                this.hiddenKlmVoiture = false
                this.hiddenKlmVoitureSpinner  = true
        })

        this.appstatsService.getScanAppEvolution(this.contrat.contrat.idContrat, this.date, this.timeType)
            .subscribe(result => {
                var response = result as scanAppEvolutionResponse;
                    for (let i = 0; i <= 5; i++) {
                        var index = response.result[0].findIndex(x => x.time_ago == i)
                        if (index === -1) {
                            response.result[0].push({ 'time_ago': i, 'scansNbr': 0 })
                        }
                    }
                    response.result[0] = response.result[0].sort(
                        function (a, b) {
                            return a.time_ago - b.time_ago;
                        }
                    )
                    var evolution = []
                    for (let [index,element] of response.result[0].reverse().entries()) {
                        var d = new Date()
                        d.setFullYear(this.current_date.getFullYear() - element.time_ago)
                        evolution.push([d.getFullYear().toString().replace(',',''), element.scansNbr])
                    }
                    this.NSAChartData = evolution
                    this.hiddenNSAChart = false
                    this.hiddenNSASpinner = true
            })

        this.appstatsService.getInstructionAppRecognition(this.contrat.contrat.idContrat,this.date, this.timeType)
            .subscribe(result => {
                var response = result as instructionAppRecognitionResponse;
                this.TRCChartData = [['Produits reconnu',response.result[0][0].reconnu],['Produits non reconnu',response.result[0][0].non_reconnu]]
                if(response.result[0][0].reconnu == 0 &&  response.result[0][0].non_reconnu == 0 ){
                    this.TRCvalue =  "0 Scan"
                }
                else{
                    const TRC =  (response.result[0][0].reconnu/(response.result[0][0].reconnu +response.result[0][0].non_reconnu))*100
                    this.TRCvalue =  (Math.round(TRC*10)/10).toString() +"%"
                }
                this.hiddenTRCChart = false
                this.hiddenTRCSpinner = true
        })


        this.appstatsService.getProductAppRecognition(this.contrat.contrat.idContrat,this.date, this.timeType)
        .subscribe(result => {
            var response = result as instructionAppRecognitionResponse;
            this.TRPChartData = [['Produits reconnu',response.result[0][0].reconnu],['Produits non reconnu',response.result[0][0].non_reconnu]]
            if(response.result[0][0].reconnu == 0 &&  response.result[0][0].non_reconnu == 0 ){
                this.TRPvalue =  "0 Scan"
            }
            else{
                const TRP =  (response.result[0][0].reconnu/(response.result[0][0].reconnu +response.result[0][0].non_reconnu))*100
                this.TRPvalue =  (Math.round(TRP*10)/10).toString() +"%"
            }
            this.hiddenTRPChart = false
            this.hiddenTRPSpinner = true
        })

        this.appstatsService.getNewUsersAppEvolution(this.contrat.contrat.idContrat, this.date, this.timeType)
            .subscribe(result => {
                var response = result as newUsersAppEvolutionResponse;
                    for (let i = 0; i <= 5; i++) {
                        var index = response.result[0].findIndex(x => x.time_ago == i)
                        if (index === -1) {
                            response.result[0].push({ 'time_ago': i, 'newUsersNbr': 0 })
                        }
                    }
                    response.result[0] = response.result[0].sort(
                        function (a, b) {
                            return a.time_ago - b.time_ago;
                        }
                    )
                    var evolution = []
                    for (let element of response.result[0].reverse()) {
                        var d = new Date()
                        d.setFullYear(this.current_date.getFullYear() - element.time_ago)
                        evolution.push([d.getUTCFullYear().toString().replace(',',''), element.newUsersNbr])
                    }

                    this.NNUChartData = evolution
                    this.hiddenNNUChart = false
                    this.hiddenNNUSpinner = true
        })

        this.appstatsService.getAccountAppDistribution(this.contrat.contrat.idContrat,this.date, this.timeType)
            .subscribe(result => {
                var response = result as accountAppDistributionResponse;
                this.RCUAChartData = [['comptes',response.result[0][0].non_anonymous],['comptes anonymes',response.result[0][0].anonymous]]
                if(response.result[0][0].non_anonymous == 0 &&  response.result[0][0].anonymous == 0 ){
                    this.RCUvalue =  "0 Compte"
                }
                else{
                    const RCU =  (response.result[0][0].non_anonymous/(response.result[0][0].non_anonymous +response.result[0][0].anonymous))*100
                    this.RCUvalue = (Math.round(RCU*10)/10).toString() +"%"
                }
                this.hiddenRCUChart = false
                this.hiddenRCUSpinner = true
        })

        this.appstatsService.getRetentionRate(this.contrat.contrat.idContrat,this.date,'MONTH')
            .subscribe(result => {
                var response = result as retentionRateResponse;
                const TR30active =  Math.round((response.result[0][0].activeUsers/(response.result[0][0].activeUsers +response.result[0][0].passiveUsers))*100)
                const TR30activeFix = TR30active.toFixed(1)
                const TR30passive = Math.round((response.result[0][0].passiveUsers/(response.result[0][0].activeUsers +response.result[0][0].passiveUsers))*100)
                const TR30passiveFix = TR30passive.toFixed(1)
                this.TR30ChartData = [[`${TR30activeFix}% des utilisateurs utilisent toujours l\'application 30 jours après installation`,response.result[0][0].activeUsers],[`${TR30passiveFix}% des utilisateurs n'utilisent plus l\'application 30 jours après installation`,response.result[0][0].passiveUsers]]
                if(response.result[0][0].activeUsers == 0 &&  response.result[0][0].passiveUsers == 0 ){
                    this.TR30value =  "0 Scan"
                }
                else{
                    this.TR30value = (Math.round(TR30active*10)/10).toString() +"%"
                }
                this.hiddenTR30Chart = false
                this.hiddenTR30pinner = true

        })

        this.appstatsService.getUserAppActive(this.contrat.contrat.idContrat,this.date, this.timeType)
            .subscribe(result => {
                var response = result as userAppActiveResponse;
                //(TimeCurrent - TimeBefore) / TimeBefore * 100 <--
                //((TimeCurrent - TimeBefore) / TimeBefore)*100
                this.userActiveAppNumber = (Math.round(response.result[0][0].TimeCurrent / response.result[0][0].allUsers * 100))
                if(response.result[0][0].TimeBefore != 0){
                    //this.userActiveAppEvolution =  (Math.round((response.result[0][0].TimeCurrent - response.result[0][0].TimeBefore)/response.result[0][0].TimeBefore *100))
                    this.userActiveAppEvolution = this.userActiveAppNumber - (Math.round(response.result[0][0].TimeBefore / response.result[0][0].allUsers * 100)) 
                }else{
                    this.userActiveAppEvolution =  this.userActiveAppNumber
                }
                this.hiddenUsersActive = false
                this.hiddenUsersActiveSpinner = true

        })

        this.appstatsService.getScanAppNbr(this.contrat.contrat.idContrat,this.date, this.timeType)
            .subscribe(result => {
                var response = result as scanAppNbrResponse;
                //(NbrScansCurrent - NbrScansBefore) / NbrScansBefore * 100
                this.scanAppNumber =  (Math.round(response.result[0][0].NbrScansCurrent))
                if(response.result[0][0].NbrScansBefore != 0){
                    this.scanAppEvolution = (Math.round((response.result[0][0].NbrScansCurrent - response.result[0][0].NbrScansBefore)/response.result[0][0].NbrScansBefore *100))
                }else{
                    this.scanAppEvolution = this.scanAppNumber
                }
                this.hiddenScansNbr = false
                this.hiddenScansNbrSpinner = true

        })
    }


    DayStats(event){
        if(event.target.tagName === 'LABEL'){
            this.timeType = 'DAY'
            var current_date = new Date()
            //this.current_date.setDate(current_date.getDate() - 1)
            //this.current_date.setMonth(current_date.getMonth())
            //this.current_date.setFullYear(current_date.getFullYear())
            this.date =  this.datepipe.transform(this.current_date, 'yyyy-MM-dd');
            this.statsPerDay()
        }
    }

    MonthStats(event){
        if(event.target.tagName === 'LABEL'){
            this.timeType = 'MONTH'
            var current_date = new Date()
            //this.current_date.setDate(current_date.getDate() - 1)
            //this.current_date.setMonth(current_date.getMonth())
            //this.current_date.setFullYear(current_date.getFullYear())
            this.date =  this.datepipe.transform(this.current_date, 'yyyy-MM-dd');
            this.statsPerMonth()
        }
    }

    YearStats(event){
        if(event.target.tagName === 'LABEL'){
            this.timeType = 'YEAR'
            var current_date = new Date()
            //this.current_date.setDate(current_date.getDate() - 1)
            //this.current_date.setMonth(current_date.getMonth())
            //this.current_date.setFullYear(current_date.getFullYear())
            this.date =  this.datepipe.transform(this.current_date, 'yyyy-MM-dd');
            this.statsPerYear()
        }
    }

    MoveLet(){
        if(this.timeType == 'DAY'){
            this.current_date.setDate(this.current_date.getDate() - 1)
            this.date =  this.datepipe.transform(this.current_date, 'yyyy-MM-dd');
            this.statsPerDay()
        }

        if(this.timeType == 'MONTH'){
            var  lastDayOfMonth = new Date(this.current_date.getFullYear(), this.current_date.getMonth(), 0)
            this.current_date.setMonth(this.current_date.getMonth() - 1)
            this.date =  this.datepipe.transform(lastDayOfMonth, 'yyyy-MM-dd');
            this.statsPerMonth()
        }

        if(this.timeType == 'YEAR'){
            this.current_date.setFullYear(this.current_date.getFullYear() - 1)
            this.date =  this.datepipe.transform(this.current_date, 'yyyy-MM-dd');
            this.statsPerYear()
        }
    }

    MoveRight(){
        var test_date =  new Date()
        var  today = new Date()
        if(this.timeType == 'DAY'){
            today.setHours(0,0,0,0)
            this.current_date.setHours(0,0,0,0)
            if(this.current_date < today ){
                this.current_date.setDate(this.current_date.getDate() + 1)
                this.date =  this.datepipe.transform(this.current_date, 'yyyy-MM-dd');
                this.statsPerDay()
            }
        }

        if(this.timeType == 'MONTH'){
            today.setHours(0,0,0,0)
            this.current_date.setHours(0,0,0,0)
            if(this.current_date < today){
                this.current_date.setMonth(this.current_date.getMonth() + 1)
                var  lastDayOfMonth = new Date(this.current_date.getFullYear(), this.current_date.getMonth() +1, 0)
                this.date =  this.datepipe.transform(lastDayOfMonth, 'yyyy-MM-dd');
                this.statsPerMonth()
            }
        }

        if(this.timeType == 'YEAR'){
            test_date.setFullYear(this.current_date.getFullYear() + 1)
            if(test_date <= today){
                this.current_date.setFullYear(this.current_date.getFullYear() + 1)
                this.date =  this.datepipe.transform(this.current_date, 'yyyy-MM-dd');
                this.statsPerYear()
            }
        }
    }


    nsaChartReady(chart: GoogleChartComponent){
        this.wrapperNSAChart = chart.chartWrapper

    }

    nnuChartReady(chart: GoogleChartComponent){
        this.wrapperNNUChart = chart.chartWrapper
    }


    getDayBefor(){
        var d = new Date(this.date);
        d.setDate(d.getDate() - 1)
        this.dayBefor = this.alldates[d.getDate()-1] +" "+this.months[d.getMonth()] +" " +d.getUTCFullYear().toString()
    }

    getmonthBefor(){
        var d = new Date(this.date);
        this.monthBefor =   this.months[(d.getMonth()-1)] +" " +d.getUTCFullYear().toString()
        if(this.monthBefor.toString() === `undefined ${d.getFullYear()}`){
            this.monthBefor = "DEC "+(d.getUTCFullYear()-1).toString()
        }

    }

    getyearBefor(){
        var d = new Date(this.date);
        this.yearBefor =   (d.getUTCFullYear()-1).toString()
    }


}

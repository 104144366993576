import { ContractstatsModule } from './../contractstats/contractstats.module';
import { AlertssettingsModule } from './../alertssettings/alertssettings.module';
import { ActussettingsModule } from './../actussettings/actussettings.module';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { CartsettingsModule } from './../cartsettings/cartsettings.module';
import { ContractsettingsModule } from './../contractsettings/contractsettings.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MainRoutingModule } from './main-routing.module';
import { MainComponent } from './components/main/main.component';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { AccountComponent } from './components/account/account.component';
import { MapComponent } from './components/map/map.component';
import { ChangepasswordComponent } from './components/changepassword/changepassword/changepassword.component';
import { UpdatepasswordComponent } from './modals/updatepassword/updatepassword.component';
import { CalendarsettingsModule } from '../calendarsettings/calendarsettings.module';
import { ActiveserviceComponent } from './modals/activeservice/activeservice/activeservice.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserModule } from '@angular/platform-browser';
import { RetractinvitComponent } from './modals/retractinvit/retractinvit.component';
import { SendinvitComponent } from './modals/sendinvit/sendinvit.component';
import { ToastAlertsComponent } from './components/toast-alerts/toast-alerts.component';

@NgModule({
    declarations: [MainComponent, WelcomeComponent, AccountComponent, MapComponent, ChangepasswordComponent, UpdatepasswordComponent, ActiveserviceComponent, RetractinvitComponent, SendinvitComponent, ToastAlertsComponent],
    imports: [
        ContractstatsModule,
        ContractsettingsModule,
        CartsettingsModule,
        CalendarsettingsModule,
        ActussettingsModule,
        AlertssettingsModule,
        FormsModule,
        ReactiveFormsModule,
        AngularSvgIconModule.forRoot(),
        CommonModule,
        BrowserModule,
        MainRoutingModule,
        NgbModule
    ]
})
export class MainModule { }

import { AngularFirestore } from '@angular/fire/firestore';
import { Alert } from './../../../../models/alert/alert';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from './../../../../services/alert/alert.service';
import { LodingBarService } from './../../../../services/lodingbar/loding-bar-service.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { error } from 'protractor';
import { UserService } from './../../../../services/user/user.service';
import { FormBuilder } from '@angular/forms';
import * as firebase from "firebase";
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-updatepassword',
    templateUrl: './updatepassword.component.html',
    styleUrls: ['./updatepassword.component.scss']
})
export class UpdatepasswordComponent implements OnInit {

    PasswordForm;
    oldpasswordError : boolean = false
    newpasswordError : boolean = false
    error : boolean = false

    constructor(
        private formBuilder: FormBuilder,
        private userservice: UserService,
        private lodingBarService: LodingBarService,
        public activeModal: NgbActiveModal,
        private alertservices: AlertService,
        public afAuth: AngularFireAuth,
        private afs : AngularFirestore
    ) { }

    ngOnInit(): void {
        this.PasswordForm = this.formBuilder.group({
            old_password: "",
            new_password: ""
        });
    }

    onSubmit(userData){
        var  self = this;
        if(userData['old_password']==""){
            this.oldpasswordError = true
        }
        else if(userData['new_password']==""){
            this.newpasswordError = true
        }
        else{
            self.lodingBarService.setStatus(true);
            var send = 0
            this.userservice.user_obs.subscribe((user) => {
                if(user != null && send == 0 ){
                    user.old_password = userData['old_password']
                    user.password = userData['new_password']
                    user.passwordUpdated = true
                    send = 1


                    let credential = firebase.auth.EmailAuthProvider.credential(
                        user.email,
                        user.old_password
                    );

                    this.afAuth.authState.subscribe((user_auth)=>{
                        user_auth.reauthenticateWithCredential(credential).then(function(){
                            user_auth.updatePassword(user.password).then(function(){
                                user.password = ""
                                user.old_password = ""
                                self.afs.collection('usersBo').doc(user_auth.uid as string).set(JSON.parse(JSON.stringify(user))
                                )
                                .then(function() {
                                    self.activeModal.close()
                                    var alert: Alert = {
                                        type: "success",
                                        message: " ✔️ Votre compte a été mis à jour avec succès",
                                    };
                                    self.alertservices.setAlert(alert);
                                    self.lodingBarService.setStatus(false);

                                })
                                .catch(function(error) {
                                    self.error = true
                                    self.lodingBarService.setStatus(false);

                                });

                            })
                            .catch(function(error) {
                                self.error = true
                                self.lodingBarService.setStatus(false);

                            });
                        })
                        .catch(function(error) {
                            self.error = true
                            self.lodingBarService.setStatus(false);

                        });
                    })



                }

            })
        }
    }

}

import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable, OnInit } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TabmessageService implements OnInit  {

    section: BehaviorSubject<string> = new BehaviorSubject<string>('informations');
    secttion_obs = this.section.asObservable()

    constructor() {

    }
    ngOnInit(){

    }
    setSection(s: string){
        this.section.next(s)
        var b = this.section.value
        var c = 0

    }

    getSection():Observable<any>{
        return this.section.asObservable()
    }
}

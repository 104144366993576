import { Notification } from './../../../../models/notification/notification';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ContratService } from './../../../../services/contrat/contrat.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { AlertService } from './../../../../services/alert/alert.service';
import { LodingBarService } from './../../../../services/lodingbar/loding-bar-service.service';
import { Contrat } from './../../../../models/contrat/contrat';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import * as firebase from 'firebase';
import * as polyline from 'polyline';
import * as short from 'short-uuid';
import {ToastAlertsComponent} from '../../../main/components/toast-alerts/toast-alerts.component'




declare var ContextMenu: any;

@Component({
    selector: 'app-alertinfos',
    templateUrl: './alertinfos.component.html',
    styleUrls: ['./alertinfos.component.scss'],
})
export class AlertinfosComponent implements OnInit {
    notification: Notification;
    contrat: Contrat;
    image_file: File;
    titleactuserror: boolean = false;
    descriptionactuserror: boolean = false;
    urlactuserror: boolean = false;
    typectuserror: boolean = false;
    alerttypes: string[];
    shape = null;
    pinned: boolean = false
    shapes = []
    colorsPolygons = [
        "#D01E0D",
        "#2F2E31",
        "#70D97F",
        "#53A4FF",
        "#EB95FA",
        "#F47000",
    ]
    chapes;
    groupShapes = []
    groupTitle : string = ""
    drawingManager;
    selectedShape;
    selectedShapes = []
    colors = ['#1E90FF', '#FF1493', '#32CD32', '#FF8C00', '#4B0082'];
    selectedColor;
    modalReference;
    modalReferenceDelete
    colorButtons = {};
    clickedPolygonIndex  : number = null
    clickedInfos : any
    event : google.maps.MapMouseEvent
    mapContext : any
    selectedcolor = "#C4C9C7"
    map :  google.maps.Map
    checkPolygon = false
    exist = [false, ""]
    alreadyGet = false
    onDelete = ""
    manyPolygons : boolean = false
    errorNoPolygon: boolean = false
    @ViewChild('registerPolygon') registerPolygon : TemplateRef<any>;
    @ViewChild('modalDelete') modalDelete : TemplateRef<any>;

    constructor(
        private afStorage: AngularFireStorage,
        private contratservice: ContratService,
        public activeModal: NgbActiveModal,
        private modalService: NgbModal,
        private toastr: ToastAlertsComponent
    ) {}

    ngOnInit(): void {
        this.contratservice.alertesFromDatabase().subscribe((data) => {
            if(!this.alreadyGet){
                this.fromObjtoArray(data.payload.data())
            }
        });
        this.contratservice.contrat_obs.subscribe((contrat) => {
            this.contrat = contrat;
            this.alerttypes = contrat.services.filter(serv => serv.nom == "Alertes")[0].sousServices.filter(item => item.active).map(element => element.nom);

            var notif: Notification = new Notification();
            notif.date = firebase.firestore.Timestamp.now();
            notif.description = '';
            notif.image = '';
            notif.titre = '';
            notif.url = '';
            notif.polygon = false;
            notif.polygonPath =  [];
            notif.idContrat = contrat.contrat.idContrat;
            notif.type =  this.alerttypes[0];
            this.notification = { ...notif };
        });

        this.initialize();
    }

    sendactusimage(file: File) {
        this.image_file = file;
    }

    onChange() {}

    onChangeColor(color){
        this.selectedcolor = color
        console.log(this.selectedcolor)
    }
    
     attachPolygonInfoWindow(polygon, name) {
        this['infoWindow'+name] = new google.maps.InfoWindow();
        let infoWindow = this['infoWindow'+name]
        google.maps.event.addListener(polygon, 'mouseover', function (e) {
            infoWindow.setContent(name);
            var latLng = e.latLng;
            infoWindow.setPosition(latLng);
            infoWindow.open(this.map);
        });
        google.maps.event.addListener(polygon, 'mouseout', function (e) {
            infoWindow.close()
        });
    }

   

    removePolygonInfoWindow(name, title) {
        let infoWindow = this['infoWindow'+name]
        infoWindow.setContent(title)
        infoWindow.setOptions({content: title})
        infoWindow.open(this.map);
    }

    fromObjtoArray(obj){
        for (let key in obj) {
            let temp = []
            for (let i = 0; i < obj[key].path.length; i++) {
                const element = obj[key].path[i];
                let decodedShape = google.maps.geometry.encoding.decodePath(element)
                let polygon = new google.maps.Polygon({
                    paths: decodedShape,
                    strokeWeight: 0,
                    editable: true,
                    visible: false,
                    fillColor : obj[key].color
                })
                google.maps.event.addListener(
                    polygon,
                    'click',
                    function () { 
                        this.setSelection(polygon)
                    }.bind(this)
                )  
                this.setSelection(polygon)
                google.maps.event.addListener(polygon,'rightclick',function (e) {
                    this.mapContext.show(this, e.latLng);
                }.bind(this))
                
                this.attachPolygonInfoWindow(polygon, obj[key].title)
                polygon.setMap(this.map)
                temp.push(polygon)
                
            }
            this.groupShapes.push([obj[key].title, temp,obj[key].color, key])
        }
        this.alreadyGet = true
    }

    fromArrayToObj(array){
        let obj = {}
            array.forEach(element => {
                if(element[1].length === 1){
                    let id = ""
                    if(!element[3]){
                        id = this.genId()
                    }else{
                        id = element[3]
                    }
                    obj[id] = {
                        color: element[2],
                        title:element[0],
                        path: [element[1][0]]
                    }
                }else if(element[1].length > 1){
                    let id = ""
                    if(!element[3]){
                        id = this.genId()
                    }else{
                        id = element[3]
                    }
                    obj[id] = {
                        color: element[2],
                        title:element[0],
                        path: []
                    }
                    element[1].forEach(shape => {
                        obj[id].path.push(shape)
                    });
                }
            });
            return obj
    }

    onchange($event) {}

    async savenotif() {
 
        if(this.groupShapes.length > 0 && !this.manyPolygons && this.selectedShapes.length === 1){
            let register = false
            let group = this.groupShapes.filter(element => element[1].length ===  1)
            group.forEach(element => {
                let shape = element[1]
                if(typeof shape[0] != 'string'){
                    register = false
                    element[1][0] = google.maps.geometry.encoding.encodePath(shape[0].getPath())
                }else if(typeof shape[0]  === 'string'){
                    register = true
                }
            });
            if(!register){
                let obj = this.fromArrayToObj(group)
                this.contratservice.saveAlertesPolygon(obj)
            }
        }else if(this.manyPolygons || this.selectedShapes.length > 1){
            let register = false
            this.groupShapes.forEach(element => {
                let shapes = element[1]
                for (let i = 0; i < shapes.length; i++) {
                    let shape = shapes[i];
                    if(typeof shape != 'string'){
                        register = false
                        shapes[i] = google.maps.geometry.encoding.encodePath(shape.getPath())
                    }else if(typeof shape === 'string'){
                        register = true
                    }
                }
            });
            if(!register){
                let obj = this.fromArrayToObj(this.groupShapes)
                this.contratservice.saveAlertesPolygon(obj)
            }
        }
        this.notification.id = '';
       
        if (this.notification.type == '') {
            this.typectuserror = true;
        } else if (this.notification.titre == '') {
            this.titleactuserror = true;
        } else if (this.notification.description == '') {
            this.descriptionactuserror = true;
        } else {
            var self = this;
            if (this.image_file != undefined) {
                let img = new Image();
                var file_name: string =
                    'notif' + Date.now() + Math.random() + '.png';
                var ref = this.afStorage.ref(
                    this.contrat.contrat.idContrat +
                        '/notifications/' +
                        file_name
                );
                var task = ref.put(self.image_file);
                this.notification.image = file_name;
            }
            for (let shape of this.shapes){
                var poly = [];

                for (let item of shape.getPath().getArray()) {
                    poly.push([item.lat(), item.lng()]);
                }

                this.notification.polygonPath.push(polyline.encode(poly));
            }
            this.activeModal.close([this.notification, this.pinned]);
        }
    }

    cancel() {
        this.activeModal.close();
    }

    onChangeGroup(groupTitle){
        let selected = []
        this.groupShapes.forEach(element => {
            if(element[0] === groupTitle){
                selected = element
            }
        });
        this.setPolygonGroup(selected)
    }

    filterList(item, event){
        this.chapes = item[1]
        if(this.chapes.length === 1){
            this.chapes = this.chapes[0]
            if ( event.target.checked ) {
                this.chapes.setOptions({visible:true});;
                this.shapes.push(this.chapes)
            }else{
                this.shapes = this.shapes.filter(element => element != this.chapes)
                this.chapes.setOptions({visible:false});;
            } 
        }else if(this.chapes.length > 1){
            if ( event.target.checked ) {
                this.chapes.forEach(chape => {
                    chape.setOptions({visible:true});;
                    this.shapes.push(chape)
                });
            }else{
                this.chapes.forEach(chape => {
                    this.shapes = this.shapes.filter(element => element != chape)
                    chape.setOptions({visible:false});;
                });
            } 
        } 
    }

    showRegister(){
        if(this.selectedShapes.length === 0){
           this.errorNoPolygon = true
        }else if(this.selectedShapes.length > 0){
            this.errorNoPolygon = false
            this.exist = [false, ""]
            for (let x = 0; x < this.groupShapes.length; x++) {
                const element = this.groupShapes[x];
                if(element[1].includes(this.selectedShape)){
                    this.exist[0] = true
                    this.exist[1] = element[0]
                }
            }
            let options: NgbModalOptions = {};
            options.centered = true
            this.modalReference = this.modalService.open(this.registerPolygon, options);
        }
    }

    showConfirmDelete(title){
        this.onDelete = title
        let options: NgbModalOptions = {};
        options.centered = true
        this.modalReferenceDelete = this.modalService.open(this.modalDelete, options);
    }

    genId(){
        let id = short.generate();
        return id
    }

    closeConfirmDelete(){
        this.modalReferenceDelete.close()
    }

    closeRegister(){
        this.modalReference.close()
    }

    deletePolygon(title){
        for (let x = 0; x < this.groupShapes.length; x++) {
            const element = this.groupShapes[x];
            if(element[0] === title){
                let chapes = element[1]
                if(element[3]){
                    let id = element[3]
                    this.contratservice.deleteAlertePolygon(id)
                }
                this.groupShapes.splice(x, 1)
                chapes.forEach(chape => {
                    chape.setMap(null)
                });
                break
            }
        }
        this.modalReferenceDelete.close()
    }

  

    modifyOnePolygon(title){
        if(title){
            for (let x = 0; x < this.groupShapes.length; x++) {
                const element = this.groupShapes[x];
                if(element[0] === this.exist[1]){
                    element[1].forEach(elem => {
                        this.removePolygonInfoWindow(element[0], title)
                        //this.attachPolygonInfoWindow(elem, title)
                    });
                    element[0] = title
                    element[2] = this.selectedcolor
                    this.selectedShapes.forEach(shape => {
                        shape.setOptions({fillColor:this.selectedcolor})
                        shape.setMap(this.map)
                    });  
                }
            }
            this.modalReference.close()
        }
    }

    registerOnePolygon(title){
        if(title){
            this.attachPolygonInfoWindow(this.selectedShape, title)
            this.selectedShape.setOptions({fillColor:this.selectedcolor})
            this.selectedShape.setMap(this.map)
            this.groupShapes.push([title, [this.selectedShape], this.selectedcolor])
            this.modalReference.close()
        }
    }

    registerPolygonGroup(title){
        if( this.selectedShapes.length > 1){
            this.manyPolygons = true
            this.selectedShapes.forEach(element => {
                this.attachPolygonInfoWindow(element, title)
                element.setOptions({fillColor:this.selectedcolor})
                element.setMap(this.map)
            });
            this.groupShapes.push([title, this.selectedShapes, this.selectedcolor])
            this.modalReference.close()
         
        } else if(this.selectedShapes.length === 1){
            this.manyPolygons = false
            this.registerOnePolygon(title)
        } 
    }

    setPolygonGroup(array){
        this.deleteShapes();
        const group = array[1]
        this.map = new google.maps.Map(document.getElementById('map'), {
            zoom: 13,
            center: new google.maps.LatLng(this.contrat.contrat.coordonne.latitude,this.contrat.contrat.coordonne.longitude),
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            disableDefaultUI: true,
            zoomControl: true,
            
        });

        var polyOptions = {
            strokeWeight: 0,
            fillOpacity: 0.45,
            editable: true,
        };

        group.forEach(element => {
            const chapes = new google.maps.Polygon(element);
            chapes.setMap(this.map)      
        });
        
        this.drawingManager = new google.maps.drawing.DrawingManager({
            drawingMode: null,
            drawingControl: true,
            drawingControlOptions: {
                drawingModes: [
                google.maps.drawing.OverlayType.POLYGON
                ]
            },
            markerOptions: {
                draggable: true,
            },
            polylineOptions: {
                editable: true,
            },
            rectangleOptions: polyOptions,
            circleOptions: polyOptions,
            polygonOptions: polyOptions,
            map: this.map,
        });
    }

    capitalize = (s) => {
        if (typeof s !== 'string') return '';
        return s.charAt(0).toUpperCase() + s.slice(1);
    };

    initMap(): void {
        const map = new google.maps.Map(
            document.getElementById('map') as HTMLElement,
            {
                center: { lat: -34.397, lng: 150.644 },
                zoom: 8,
            }
        );

        const drawingManager = new google.maps.drawing.DrawingManager({
            drawingMode: google.maps.drawing.OverlayType.MARKER,
            drawingControl: true,
            drawingControlOptions: {
                position: google.maps.ControlPosition.TOP_CENTER,
                drawingModes: [
                    google.maps.drawing.OverlayType.MARKER,
                    google.maps.drawing.OverlayType.CIRCLE,
                    google.maps.drawing.OverlayType.POLYGON,
                    google.maps.drawing.OverlayType.POLYLINE,
                    google.maps.drawing.OverlayType.RECTANGLE,
                ],
            },
        });
        drawingManager.setMap(map);
    }

    clearSelection() {
        if (this.selectedShape ) {
            this.selectedShape.setEditable(false);
            this.selectedShape = null;
        }
        if(this.selectedShapes){
            if(this.selectedShapes.length > 0){
                this.selectedShapes.forEach(element => {
                    element.setEditable(false);
                    element = null;
                });
            }
        } 
    }

    setSelection(shape) {
        this.selectedShape = shape;
        if(shape.editable === true){
            this.selectedShapes = this.selectedShapes.filter(element => element != shape)
            shape.setEditable(false);
        }else if(shape.editable === false){
            this.selectedShapes.push(shape)
            shape.setEditable(true);
        }
    }

    deleteSelectedShape() {
        if (this.selectedShape) {
            this.selectedShape.setMap(null);
            this.shapes = this.shapes.filter(element => element !=  this.selectedShape)
        }
    }

    deleteShapes(){
        if (this.selectedShape) {
            for (let shape of this.shapes){
                shape.setMap(null);
            }
            this.shapes = []
        }
    }

    toggleEditable(event) {
        if ( event.target.checked ) {
            this.checkPolygon = true;
        }else{
            this.checkPolygon = false;
        }
    }

    selectColor(color) {
        this.selectedColor = color;

        // Retrieves the current options from the drawing manager and replaces the
        // stroke or fill color as appropriate.
        var polylineOptions = this.drawingManager.get('polylineOptions');
        polylineOptions.strokeColor = color;
        this.drawingManager.set('polylineOptions', polylineOptions);

        var rectangleOptions = this.drawingManager.get('rectangleOptions');
        rectangleOptions.fillColor = color;
        this.drawingManager.set('rectangleOptions', rectangleOptions);

        var circleOptions = this.drawingManager.get('circleOptions');
        circleOptions.fillColor = color;
        this.drawingManager.set('circleOptions', circleOptions);

        var polygonOptions = this.drawingManager.get('polygonOptions');
        polygonOptions.fillColor = color;
        this.drawingManager.set('polygonOptions', polygonOptions);
    }

    setSelectedShapeColor(color) {
        if (this.selectedShape) {
            if (
                this.selectedShape.type ==
                google.maps.drawing.OverlayType.POLYLINE
            ) {
                this.selectedShape.set('strokeColor', color);
            } else {
                this.selectedShape.set('fillColor', color);
            }
        }
    }

    initialize() {
        var self  = this

        this.map = new google.maps.Map(document.getElementById('map'), {
            zoom: 13,
            center: new google.maps.LatLng(self.contrat.contrat.coordonne.latitude,self.contrat.contrat.coordonne.longitude),
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            disableDefaultUI: true,
            zoomControl: true,
            disableDoubleClickZoom: true,
        });

        var polyOptions = {
            strokeWeight: 0,
            fillOpacity: 0.45,
            editable: true,
        };
        // Creates a drawing manager attached to the map that allows the user to draw
        // markers, lines, and shapes.
        this.drawingManager = new google.maps.drawing.DrawingManager({
            drawingMode: null,
            drawingControl: true,
            drawingControlOptions: {
                drawingModes: [
                google.maps.drawing.OverlayType.POLYGON
                ]
            },
            markerOptions: {
                draggable: true,
            },
            polylineOptions: {
                editable: true,
            },
            rectangleOptions: polyOptions,
            circleOptions: polyOptions,
            polygonOptions: polyOptions,
            map: this.map,
        });

        google.maps.event.addListener(
            this.drawingManager,
            'overlaycomplete',
            function (e) {
                if (e.type != google.maps.drawing.OverlayType.MARKER) {
                    // Add an event listener that selects the newly-drawn shape when the user
                    // mouses down on it.
                    var newShape = e.overlay;
                    this.shapes.push(newShape)
                    newShape.type = e.type;

                    google.maps.event.addListener(
                        newShape,
                        'click',
                        function () { 
                            this.setSelection(newShape)
                        }.bind(this)
                    )

                    google.maps.event.addListener(newShape,'rightclick',function (e) {
                        self.mapContext.show(this, e.latLng);
                        //this.setSelection(newShape)
                    }.bind(this)
                    )

                    this.setSelection(newShape);
                }
            }.bind(this)
        );

        // Clear the current selection when the drawing mode is changed, or when the
        // map is clicked.

        google.maps.event.addListener(
            this.drawingManager,
            'drawingmode_changed',
            this.clearSelection
        );
        
        google.maps.event.addListener(this.map, 'dblclick',function(e){
            this.clearSelection()
        }.bind(this));
                
        self.mapContext = new ContextMenu({
            map: this.map,                // objet google.maps.Map
            idMenu: "cm-map",         // id élément DOM du menu
            callback: self.mapAction.bind(self)      // réf. fonction appelée
            });
    }

    mapAction(param) {
    switch (param.action) {
        case "registerOnePolygon":
            this.showRegister()
            break;
        case "deleteOnePolygon":
            this.deleteSelectedShape()
            break;
        case "deleteAllPolygon":
            this.deleteShapes()
            break;
        default:
            break;
        }
    }
    
}


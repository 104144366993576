<div class="row">
    <div class="col-12 d-flex flex-column justify-content-center align-items-lg-start align-items-center">
        <p class="pb-0 mb-0  h3">Renseignez les coordonnées GPS de vos points de collecte : </p>
        <p class="pb-0 mb-0 mt-0 p2">Téléchargez le fichier exemple, complétez-le en suivant les instructions, et importez-le. </p>
    </div>
</div>

<div class="row mt-4" *ngIf="user!=undefined">
    <div class="col-lg-5  d-flex flex-row justify-content-center justify-content-lg-start">
        <div class="row w-100">
            <div class="col-lg-3  d-flex flex-row justify-content-center justify-content-lg-start ">
                <button type="button" class="btn btn-secondary button6" [ngStyle]="{'cursor' :  ((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification') ? 'not-allowed' : null }" ><a href="{{FluxTemplate}}" style='text-decoration:none;color: white;' download>Télécharger</a></button>
            </div>
            <div class="col-lg-6  d-flex flex-column  justify-content-center p1 align-items-lg-start align-items-center" style="height: 35px;">
                Fichier exemple (Format .xlsx)
            </div>
        </div>
    </div>
</div>

<div class="row mt-4">
    <div class="col-lg-5  d-flex flex-row justify-content-center justify-content-lg-start">
        <div class="row w-100">
            <div class="col-lg-6 d-flex flex-row justify-content-center justify-content-lg-start" *ngIf="user!=undefined">
                <img src="../../../../../assets/img/Boutons/002-Delete-icon.svg" [ngStyle]="{'cursor' :  ((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification') ? 'not-allowed' : null }"
                style="width: 35px;height: 35px;" (click)="fileInput.value=null;deleteFlux()" [hidden]="true">
                <input type="file" name="file" #fileInput id="file" class="inputfile" [disabled]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')"
                    (change)="sendFlux(fileInput.files[0])" />
                <label for="file" style="cursor: pointer;"
                    class="d-flex flex-row w-100  d-flex flex-row justify-content-center justify-content-lg-start ">
                    <img src="../../../../../assets/img/028-addelementleft.svg" [ngStyle]="{'cursor' :  ((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification') ? 'not-allowed' : null }"
                        style="width: 35px;height: 35px;"  [hidden]="false" >

                    <input type="text"  name="file" class="form-control classname w-100 h4"   [disabled]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')"
                        style="height: 35px;border-radius: 0 10px 10px 0 !important;" *ngIf="contrat!=undefined"
                        value="{{ contrat.localfiles != undefined && contrat.localfiles.localCarte != '' ?  contrat.localfiles.localCarte : 'Charger un fichier'}}" readonly />
                </label>
            </div>
            <div class="col-lg-6  d-flex flex-column  justify-content-center p2 align-items-lg-start align-items-center" style="height: 35px;">
                Format .xlsx
            </div>
        </div>
    </div>
</div>



<div class="row mt-5">
    <div class="col-12 d-flex flex-column justify-content-center align-items-lg-start align-items-center">
        <p class="pb-0 mb-0  h3">Instructions :</p>
        <p class="pb-0 mb-0 mt-0 p2">Ouvrez le fichier Excel (.xlsx)</p>
        <p class="pb-0 mb-0 mt-0 p2">Remplissez les informations en veillant à bien respecter la structure des champs</p>
        <p class="pb-0 mb-0 mt-0 p2">Veillez à ne pas rajouter ou insérer de nouvelles colonnes, ni modifier leur ordre)</p>
        <p class="pb-0 mb-0 mt-0 p2">Veuillez sélectionner les champs "Flux" dans la liste.</p>
        <p class="pb-0 mb-0 mt-0 p2">Une fois complété, enregistrez votre fichier au format ".xlsx" et importez-le.</p>
    </div>
</div>

<div class="mt-5"></div>
<div class="row mt-5 mb-4" *ngIf="contrat != undefined">
    <div class="col-lg-3 d-flex flex-row justify-content-center">
        <button type="button"  class="btn  btn-primary w-100 button2" routerLink="/montri/contractsettings/informations">Précédent</button>
    </div>
    <div class="col-lg-3 mt-3 mt-lg-0 d-flex flex-row justify-content-center">
        <button type="button" class="btn  btn-primary w-100  h3_heavy button4"  (click)="saveData()" ngClass="{{contrat.carte.length == 0 && userFlux.length == 0 ? 'opacity05':''}}">Suivant</button>
    </div>
</div>

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserBo } from './../../../../models/user/user';
import { UserService } from './../../../../services/user/user.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { SliderService } from './../../../../services/slider/slider.service';
import { element } from 'protractor';
import { Alert } from './../../../../models/alert/alert';
import { Service, Contrat, GuideDutri, villeServie } from './../../../../models/contrat/contrat';
import { AlertService } from './../../../../services/alert/alert.service';
import { LodingBarService } from './../../../../services/lodingbar/loding-bar-service.service';
import { ContratService } from './../../../../services/contrat/contrat.service';
import { TabmessageService } from './../../../../services/tabmessage/tabmessage.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { last, switchMap } from 'rxjs/operators';
import * as XLSX from 'xlsx';
import * as Papa from 'papaparse';
import { AngularFirestore } from '@angular/fire/firestore';
import { ToastAlertsComponent } from 'src/app/modules/main/components/toast-alerts/toast-alerts.component';


@Component({
    selector: 'app-services',
    templateUrl: './services.component.html',
    styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {

    services : Service[] = []
    destinataire : boolean = false;
    colorItem : string ;
    edit_status : boolean = true;
    contrat : Contrat;
    guideDutri : GuideDutri[];
    GuideDuTriTemplate : string ;
    guideDuTrierror : boolean = false;
    user : UserBo
    serviceIndexMondal : number =  0
    selectedVilles : villeServie[] = []
    modal_ref : any
    @ViewChild('serviceVilles', { static: false }) private serviceVilles;

    constructor(
        private sectionservice: TabmessageService,
        private lodingbarservice: LodingBarService,
        private alertservice: AlertService,
        private sliderService : SliderService,
        private afStorage: AngularFireStorage,
        private afs: AngularFirestore,
        private contratservice: ContratService,
        private  userservice  : UserService,
        public activeModal: NgbActiveModal,
        public modalService: NgbModal,
        private toastr: ToastAlertsComponent
    ) {}

    ngOnInit(): void {
        window.scroll(0,0)
        const ref2 = this.afStorage.ref('TemplatesBO/Guide-du-tri-type.xlsx');
        ref2.getDownloadURL().subscribe((href)=>{
            this.GuideDuTriTemplate = href
        })
        this.sectionservice.setSection('services');
        this.userservice.user_obs.subscribe((user)=>{
            this.user = user
            if(user != null){
                this.contratservice.contrat_obs.subscribe((contrat)=>{
                    this.contrat =  contrat
                    if(this.contrat.contrat.idContrat != "BOTTEST"){
                        this.afs.doc(`preContrats/${this.contrat.contrat.idContrat}/data/destinataires`)
                            .snapshotChanges()
                            .subscribe((data) => {
                                if(data.payload.exists){
                                    this.destinataire = true
                                }else{
                                    this.destinataire = false
                                }
                            });
                    }
                    // if (checkDestinataire === false){
                    //     this.destinataire = false
                    // }
                    for(let [index,service] of this.contrat.services.entries())
                    {
                        this.contrat.services[index].villes  = this.contrat.services[index].villes.filter(ville => this.contrat.villesListe.filter(element => element == ville.zipCode).length != 0)
                        var villesToAdd : string [] = this.contrat.villesListe.filter(ville => (service.villes.filter(element => element.zipCode == ville).length == 0))
                        villesToAdd.forEach(element => {
                            this.contrat.services[index].villes.push({
                                zipCode : element,
                                active : true
                            })
                        });
                    }
                    this.services=this.contrat.services.map(serv => ({...serv}))
                    this.guideDutri = this.contrat.guideDutri.map(element => ({...element}))
                })
            }
        })
    }

    onchangeService(state,name){
        this.contrat.services.forEach(element => {
            if(element.nom === name){
                element.active = state
            }
        });
    }



    async saveData(){
        var error = false
        if(this.services.filter(serv => serv.nom == "Demandes et Signalements")[0].active){
            for(let [indexServ,Serv] of this.services.entries()){
                if(Serv.nom == "Demandes et Signalements"){
                    for(let [indexSubServ,SubServ] of Serv.sousServices.entries()){
                        if(SubServ.email == "" && SubServ.url == "" && SubServ.active){
                            error = true
                            this.services[indexServ].sousServices[indexSubServ].error = true
                            this.services[indexServ].sousServices[indexSubServ].emailMsgError = 'champs obligatoire'
                            this.services[indexServ].sousServices[indexSubServ].urlMsgError = 'champs obligatoire'
                        }
                    }
                }
            }
        }

        if(error){
            this.toastr.openFailedToast('Certains champs obligatoires ne sont pas remplis.','')
        }
        else if(this.guideDutri.length == 0){
            this.guideDuTrierror = true;
            this.toastr.openFailedToast('Le fichier du guide du tri est obligatoire.','')
        }

        else{
            let ref = await this.toastr.openTransferToast(`Transfert de données en cours.`, "")
            this.contratservice.contrat.value.services = [...this.services]
            this.contratservice.contrat.value.guideDutri = [...this.guideDutri]
            this.contratservice.contrat.value.configstatus[4].status = true
            let status = await this.contratservice.saveContrat('/montri/contractsettings/slider')
            if(status === "success"){
                this.toastr.clear(ref.toastId)
                this.toastr.openSuccessToast('Données enregistrées avec succès', "")
            }else{
                this.toastr.clear(ref.toastId)
                this.toastr.openErrorToast(`Chargement échoué. Veuillez réessayer.`, '')
            }

        }
    }

    sendGuideDuTri(file: File){
        this.lodingbarservice.setStatus(true)
        this.guideDuTrierror = false;
        this.contratservice.contrat.value.localfiles.localGuideDuTri = file.name
        const reader: FileReader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = (e: any) => {
        /* create workbook */
        const binarystr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });
            
        /* selected the first sheet */
        const wsname: string = wb.SheetNames[1];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];
        

        var range = XLSX.utils.decode_range(ws['!ref']) //<-- start "select"
        range.s.c = 0 // <-- start column
        range.e.c = 24 // <-- end column
        range.s.r = 7 // <-- start row
        //range.e.r = 106 // <-- end row
        ws['!ref'] = XLSX.utils.encode_range(range) //<-- end "select"

        /* save data */
        var xlData = XLSX.utils.sheet_to_csv(ws,{FS:";"})
        var data = { guideDutri : [] }
        var index = 0
        Papa.parse(xlData, {
            header: false,
            skipEmptyLines: false,
            complete: (result,file) => {
                const parsedCsv = result.data
                for (var i in parsedCsv) {
                    if (parsedCsv[i][0] == "") {
                        break
                    }
                    data.guideDutri.push({ nom : parsedCsv[i][1], famille : parsedCsv[i][0], motsCle : parsedCsv[i][2], image : parsedCsv[i][3], infos : [], top : parsedCsv[i][24]})
                    var flux = []
                    if (parsedCsv[i][8] != "") {
                        flux = [parsedCsv[i][8]]
                    }
                    var info : any = { description : parsedCsv[i][4],
                            contact : parsedCsv[i][9],
                            action : parsedCsv[i][5],
                            flux : flux,
                            libelleBouton : parsedCsv[i][6],
                            lien : parsedCsv[i][7],
                            transition : parsedCsv[i][10] }
                    data.guideDutri[index].infos.push(info)
                    flux = []
                    if (parsedCsv[i][15] != "") {
                        flux = [parsedCsv[i][15]]
                    }
                    info = { description : parsedCsv[i][11],
                            contact : parsedCsv[i][16],
                            action : parsedCsv[i][12],
                            flux : flux,
                            libelleBouton : parsedCsv[i][13],
                            lien : parsedCsv[i][14],
                            transition : parsedCsv[i][17] }
                    data.guideDutri[index].infos.push(info)
                    flux = []
                    if (parsedCsv[i][22] != "") {
                        flux = [parsedCsv[i][22]]
                    }
                    info = { description : parsedCsv[i][18],
                            contact : parsedCsv[i][23],
                            action : parsedCsv[i][19],
                            flux : flux,
                            libelleBouton : parsedCsv[i][20],
                            lien : parsedCsv[i][21] }
                    data.guideDutri[index].infos.push(info)
                    index = index + 1
                }
                this.guideDutri = [...data.guideDutri]as GuideDutri[]
                this.lodingbarservice.setStatus(false)
            }

          });
        };

    }

    deleteGuideDuTri(){

        this.contratservice.contrat.value.localfiles.localGuideDuTri = "";
    }

    onChange(){

    }

    onchange(evt,i){

    }

    openModal(service : Service){
        this.selectedVilles = []
        service.villes.forEach(element => {
            this.selectedVilles.push({...element})
        });
        this.modal_ref =this.modalService.open(this.serviceVilles, {backdrop: 'static', keyboard: false, centered: true});
    }

    cancelServiceVilles(){
        this.modal_ref.close()
    }

    saveVilles(){
        this.services[this.serviceIndexMondal].villes = this.selectedVilles
        this.services[this.serviceIndexMondal].partout = (this.services[this.serviceIndexMondal].villes.filter(ville => ville.active == false).length > 0) ? false : true
        this.modal_ref.close()

    }
}

<div class="container-fluid mt-5 pl-5" *ngIf="contrat != undefined && contrat.contrat.idContrat!='BOTEST'">
    <div class="row">
    </div>
   <div class="row mt-4">
        <div class="col-12">
            <div class="row">
                <div class="col-12 d-flex flex-column justify-content-center align-items-lg-start align-items-center">
                    <p class="pb-0 mb-0  h3">Sélectionnez votre type de calendrier :  </p>
                    <p class="pb-0 mb-0 mt-0 p2">Vous pouvez soit charger un fichier Excel (.xlsx), soit dessiner directement vos zones de collecte sur une carte (Version polygoniale).
                    </p>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-lg-4">
                    <select class="custom-select p2" style="width: 100%;box-shadow: none;border: 1px solid #F47000; color: black;" [(ngModel)]="contrat.contrat.collectePolygon" (ngModelChange)="changePolygonType()">
                        <option class="p2"  [ngValue]="''" disabled selected>Sélectionner</option>
                        <option class="p2" [ngValue]="false">Fichier Excel</option>
                        <option disabled style="font-style:italic" (click)="selectType('excel')" class="p2">&nbsp;&nbsp;&nbsp;J'importe un fichier au format .xlsx</option>
                        <option class="p2"  [ngValue]="true">Polygonial</option>
                        <option disabled style="font-style:italic" (click)="selectType('polygone')" class="p2">&nbsp;&nbsp;&nbsp;Je dessine mes zones de collecte sur la carte</option>
                    </select>

                </div>

            </div>

            <div  *ngIf="!contrat.contrat.collectePolygon">
                <div class="row mt-4">
                    <div class="col-12 d-flex flex-column justify-content-center align-items-lg-start align-items-center">
                        <p class="pb-0 mb-0  h3">Renseignez les jours et horaires de collecte : </p>
                        <p class="pb-0 mb-0 mt-0 p2">Téléchargez le fichier exemple, complétez-le en suivant les instructions, et importez-le.</p>
                    </div>
                </div>

                <div class="row mt-4" *ngIf="user!=undefined">
                    <div class="col-lg-5  d-flex flex-row justify-content-center justify-content-lg-start">
                        <div class="row w-100">
                            <div class="col-lg-3  d-flex flex-row justify-content-center justify-content-lg-start ">
                                <button type="button" class="btn btn-secondary button6" [ngStyle]="{'cursor' :  ((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification') ? 'not-allowed' : null }"><a href="{{calendrierTemplate}}" style='text-decoration:none;color: white;' download>Télécharger</a></button>
                            </div>
                            <div class="col-lg-6  d-flex flex-column  justify-content-center p1 align-items-lg-start align-items-center" style="height: 35px;">
                                Fichier exemple (Format .xlsx)
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mt-4">
                    <div class="col-lg-5  d-flex flex-row justify-content-center justify-content-lg-start">
                        <div class="row w-100">
                            <div class="col-lg-6 d-flex flex-row justify-content-center justify-content-lg-start" *ngIf="user!=undefined">
                                <img src="../../../../../assets/img/Boutons/002-Delete-icon.svg" [ngStyle]="{'cursor' :  ((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification') ? 'not-allowed' : null }"
                                style="width: 35px;height: 35px;" (click)="fileInput.value=null;deleteCalendrier()" [hidden]="true">
                                <input type="file" name="file" #fileInput id="file" class="inputfile" [disabled]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')"
                                    (change)="sendCalendrier(fileInput.files[0])" />
                                <label for="file" style="cursor: pointer;"
                                    class="d-flex flex-row w-100  d-flex flex-row justify-content-center justify-content-lg-start ">
                                    <img src="../../../../../assets/img/028-addelementleft.svg" [ngStyle]="{'cursor' :  ((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification') ? 'not-allowed' : null }"
                                        style="width: 35px;height: 35px;"  [hidden]="false" >

                                    <input type="text"  name="file" class="form-control classname w-100 h4"   [disabled]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')"
                                        style="height: 35px;border-radius: 0 10px 10px 0 !important;" *ngIf="contrat!=undefined"
                                        value="{{ contrat.localfiles != undefined && contrat.localfiles.localCalendar != '' ?  contrat.localfiles.localCalendar : 'Charger une fichier'}}" readonly />
                                </label>
                            </div>
                            <div class="col-lg-6  d-flex flex-column  justify-content-center p2 align-items-lg-start align-items-center" style="height: 35px;">
                                Format .xlsx
                            </div>
                        </div>
                    </div>
                </div>

    

                <div class="row mt-5">
                    <div class="col-12 d-flex flex-column justify-content-center align-items-lg-start align-items-center">
                        <p class="pb-0 mb-0  h3">Instructions :</p>
                        <p class="pb-0 mb-0 mt-0 p2">Ouvrez le fichier Excel (.xlsx)</p>
                        <p class="pb-0 mb-0 mt-0 p2">Remplissez les informations en veillant à bien respecter la structure des champs</p>
                        <p class="pb-0 mb-0 mt-0 p2">Veillez à ne pas rajouter ou insérer de nouvelles colonnes, ni modifier leur ordre)</p>
                        <p class="pb-0 mb-0 mt-0 p2">Veuillez sélectionner les champs "Flux" dans la liste.</p>
                        <p class="pb-0 mb-0 mt-0 p2">Une fois complété, enregistrez votre fichier au format ".xlsx" et importez-le.</p>
                    </div>
                </div>

                <div class="mt-5"></div>
                <div class="row mt-5 mb-4">

                    <div class="col-lg-3 mt-3 mt-lg-0 d-flex flex-row justify-content-center">
                        <button type="button" class="btn  btn-primary w-100  h3_heavy button4"  (click)="saveData()" ngClass="{{  next() ? 'opacity05':''}}">Enregistrer</button>
                    </div>
                </div>
            </div>



            <div *ngIf="contrat.contrat.collectePolygon">
                <div class="row mt-4">
                    <div class="col-12 d-flex flex-column justify-content-center align-items-lg-start align-items-center">
                        <p class="pb-0 mb-0  h3">Dessinez vos zones de collecte sur la carte : </p>
                        <p class="pb-0 mb-0 mt-0 p2">
                            1. Dessinez votre zone de collecte directement sur la carte avec un ou plusieurs polygones.<br/>
                            2. Une fois vos polygones dessinés, cliquez sur le bouton « Créer un nouvel ensemble ».<br/>
                            3. Faites ensuite un clic droit et cliquez sur « Informations de collecte » pour renseigner les jours et horaires de collecte.<br/>
                            4. Les données renseignées affecteront l’ensemble des polygones dessinés en orange sur la carte.
                        </p>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class= "col-3">
                        <button type="button" class="btn button1 btn-primary w-100" (click)="createNewPolygon()">Créer un nouvel ensemble</button>
                    </div>
                    <div class= "col-3">
                        <div *ngIf="user!=undefined">
                            <div >
                                <div class="row w-100">
                                    <div >
                                        <label for="PolygonUpload" class="btn btn-secondary button6">Importer</label>
                                        <input type="file" accept=".zip" #fileInput style="display: none;" id="PolygonUpload" (change)="ImportPolygons(fileInput.files[0])" [ngStyle]="{'cursor' :  ((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification') ? 'not-allowed' : null }" >
                                    </div>
                                    <div class="col-lg-6  d-flex flex-column  justify-content-center p1 align-items-lg-start align-items-center" style="height: 35px;">
                                        (Format .zip)
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- <div class="row mt-4"  *ngIf="FluxError == true">
                    <div class="col-lg-12  d-flex flex-row justify-content-center justify-content-lg-start">
                        <div class="row w-100">
                            <div class="col-lg-12 mt-3 mt-lg-0 d-flex flex-row justify-content-center justify-content-lg-start align-items-center error pb-0">
                                <i class="fa fa-exclamation-triangle fa-2x" aria-hidden="true" style="width: 30px;height: 30px;"></i>
                                <p class="ml-3 h-100 d-flex flex-row  mb-0 pb-0 justify-content-center align-items-end" style="text-align: center;">Ce fichier est obligatoire</p>
                            </div>
                        </div>
                    </div>
                </div> -->

                <div class="row mt-3">

                        <div class="col-lg-7 ml-3 d-flex flex-row align-items-center" style="height: 500px;" id="map">

                        </div>

                        <div class="col-lg-4 ml-5">
                            <div class="row">

                                    <p class="h3">Choisissez les flux à afficher : </p>
                            </div>
                            <div class="row mt-3" *ngFor="let item of flux;let i=index">
                                <div class="col-2 p2  d-flex flex-row align-items-center justify-content-center btn_flux_right"  [ngStyle]="{'backgroundColor' : item.couleur}">
                                    {{fluxCount[item.nom]}}
                                </div>
                                <div class="col-6 p2 d-flex flex-row align-items-center justify-content-start btn_flux_left pl-1">
                                   {{item.nom}}
                                </div>
                                <div class="col-lg-1 d-flex flex-row justify-content-lg-start justify-content-center align-items-center">
                                    <div class="btn-group" role="group" id={{item.nom}} >
                                        <input type="radio"  name="{{item.nom}}" [value]="true" [(ngModel)]="item.showInCalendar" (ngModelChange)="onChange(item.nom,true)" id="{{'radiobtoui'+ i}}" />
                                        <label class="btn btn-secondary radius_left" id="{{'radiooui'+ i}}" for="{{'radiobtoui'+ i}}"  >
                                            Oui
                                        </label>
                                        <input type="radio"   [value]="false" [(ngModel)]="item.showInCalendar" (ngModelChange)="onChange(item.nom,false)"   name="{{item.nom}}"  class="radio_input radio_non"   id="{{'radiobtnon'+ i}}"/>
                                        <label  class="btn btn-secondary radius_right" id="{{'radionon'+ i}}"   for="{{'radiobtnon'+ i}}">
                                            Non
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>

                <div id="cm-map" class="context-menu">
                    <ul>
                      <li data-action="infosPolygon">Informations de collecte</li>
                      <li></li>
                      <li data-action="deleteOnePlygon">Supprimer ce polygone</li>
                      <li data-action="deletePolygonGroup">Supprimer l’ensemble</li>
                      <li></li>
                      <li data-action="copyOnePolygon">Copier ce polygone</li>
                      <li data-action="copyPolygonGroup">Copier l’ensemble</li>
                      <li></li>
                      <li data-action="cancel">Annuler</li>
                      <li data-action="quite">Quitter mode édition</li>
                    </ul>
                </div>


                <div class="mt-5"></div>
                <div class="row mt-5 mb-4">

                    <div class="col-lg-3 mt-3 mt-lg-0 d-flex flex-row justify-content-center">
                        <button type="button" class="btn  btn-primary w-100  h3_heavy button4"  (click)="savePolygons()" ngClass="{{  next() ? 'opacity05':''}}">Enregistrer</button>
                    </div>
                    <div class="col-lg-6 mt-3 mt-lg-0 d-flex flex-row justify-content-center align-items-center error pb-0" *ngIf="polygonError">
                        <i class="fa fa-exclamation-triangle fa-2x" aria-hidden="true" style="width: 30px;height: 30px;"></i>
                        <p class="ml-3 h-100 d-flex flex-row  mb-0 pb-0 justify-content-center align-items-end" style="text-align: center;">Veuillez Remplire les informations de tous les polygones</p>
                    </div>
                </div>

            </div>


        </div>
    </div>
</div>


<ng-template #polygonActions let-c="close" let-d="dismiss">
    <div class="modal-content">
        <div class="modal-header d-flex flex-row justify-content-start" style='border: none;'>
            <p class="pb-0 mb-0 h3">Informations du polygone :</p>
		</div>
		<div class="modal-body">
            <div class="row">
                <div class="col-lg-4 d-flex flex-column  justify-content-center">
                    <p class="pt-0 mb-0 p2 ">Nom du polygone : <span style="color: #F47000;">*</span></p>
                </div>
                <div class="col-lg-7">
                    <input type="text" [disabled]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')"   class="form-control input1 p2"  [(ngModel)]="clickedInfos.nomPolygone" (ngModelChange)="change()"  required>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-lg-4 d-flex flex-column  justify-content-center">
                    <p class="pt-0 mb-0 p2">Flux de collecte : <span style="color: #F47000;">*</span></p>
                </div>
                <div class="col-lg-7">
                    <select [disabled]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')"  class="custom-select p2" style="width: 100%;box-shadow: none;border: 1px solid #F47000; color: black;" [(ngModel)]="clickedInfos.fluxCollecte" (ngModelChange)="updateFlow()">
                        <option class="p2" [ngValue]="''" disabled selected>Sélectionner</option>
                        <option class="p2"  *ngFor="let item of flux" [ngValue]="item.nom">{{item.nom}}</option>
                    </select>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-lg-4 d-flex flex-column  justify-content-center">
                    <p class="pt-0 mb-0 p2">A publier :</p>
                </div>
                <div class="col-lg-7 d-flex flex-row justify-content-start align-items-center">
                    <div class="btn-group" role="group" id="a_publier" >
                        <input type="radio"  name="a_publier" [value]="true" [(ngModel)]="clickedInfos.a_publier" (ngModelChange)="change()" id="radiobtouipublier"  [disabled]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')"/>
                        <label class="btn btn-secondary radius_left_actus" id="a_publieroui" for="radiobtouipublier" [ngStyle]="{'cursor' :  ((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification') ? 'not-allowed' : 'pointer' }" >
                            Oui
                        </label>
                        <input type="radio"   [value]="false" [(ngModel)]="clickedInfos.a_publier" (ngModelChange)="change()"   name="a_publier"  class="radio_input radio_non"   id="radiobtnonpublier" [disabled]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')"/>
                        <label  class="btn btn-secondary radius_right_actus" id="a_publiernon"   for="radiobtnonpublier" [ngStyle]="{'cursor' :  ((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification') ? 'not-allowed' : 'pointer' }">
                            Non
                        </label>
                    </div>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-lg-4 d-flex flex-column  justify-content-center">
                    <p class="pt-0 mb-0 p2">Période : <span style="color: #F47000;">*</span></p>
                </div>
                <div class="col-lg-7">
                    <select [disabled]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')"   class="custom-select p2" style="width: 100%;box-shadow: none;border: 1px solid #F47000; color: black;" [(ngModel)]="clickedInfos.periodeType" (ngModelChange)="change()">
                        <option class="p2" [ngValue]="''" disabled selected>Sélectionner</option>
                        <option class="p2" [ngValue]="'Toute l\'année'">Toute l'année</option>
                        <option class="p2" [ngValue]="'Personnalisée'">Personnalisée</option>
                    </select>
                </div>
            </div>
            <div class="row mt-3" *ngIf="clickedInfos.periodeType == 'Personnalisée'">
                <div class="col-lg-4 d-flex flex-column  justify-content-center">
                </div>
                <div class="col-lg-7">
                    <form class="form-inline" style="width: 100%;">
                        <div class="form-group hidden">
                          <div class="input-group" style="width: 100%;">
                            <input name="datepicker" style="width: 100%;"
                                   class="form-control p2 input1"
                                   ngbDatepicker
                                   #datepicker="ngbDatepicker"
                                   [autoClose]="'outside'"
                                   (dateSelect)="onDateSelection($event)"
                                   [displayMonths]="1"
                                   [dayTemplate]="t"
                                   outsideDays="hidden"
                                   [startDate]="fromDate!"
                                   tabindex="-1">
                            <ng-template #t let-date let-focused="focused" style="width: 100%;">
                              <span class="custom-day"
                                    [class.focused]="focused"
                                    [class.range]="isRange(date)"
                                    [class.faded]="isHovered(date) || isInside(date)"
                                    (mouseenter)="hoveredDate = date"
                                    (mouseleave)="hoveredDate = null">
                                {{ date.day }}
                              </span>
                            </ng-template>
                          </div>
                        </div>
                        <div class="form-group" style="width: 100%;">
                          <div class="input-group" style="width: 100%;">
                            <input #dpFromDate
                                   class="form-control p2" placeholder="yyyy-mm-dd"
                                   name="dpFromDate"
                                   [value]="'Du '+formatter.format(fromDate)+' au '+ formatter.format(toDate)"
                                   (input)="fromDate = validateInput(fromDate, dpFromDate.value);toDate = validateInput(toDate, dpFromDate.value)">
                            <div class="input-group-append">
                              <button class="btn btn-outline-secondary input1" [disabled]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')"  (click)="datepicker.toggle()" type="button">
                                <i class="fa fa-calendar" style="color: f47000;" aria-hidden="true"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-lg-4 d-flex flex-column  justify-content-center">
                    <p class="pt-0 mb-0 p2">Fréquence : <span style="color: #F47000;">*</span></p>
                </div>
                <div class="col-lg-7">
                    <select [disabled]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')"   class="custom-select p2" style="width: 100%;box-shadow: none;border: 1px solid #F47000; color: black;" [(ngModel)]="clickedInfos.frequence" (ngModelChange)="change()">
                        <option class="p2"  [ngValue]="''" disabled selected>Sélectionner</option>
                        <option class="p2" [ngValue]="'Hebdomadaire'">Hebdomadaire</option>
                        <option class="p2" [ngValue]="'Bi-mensuelle'">Bi-mensuelle</option>
                        <option  class="p2"  [ngValue]="'mensuelle'">mensuelle</option>
                    </select>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-lg-4 d-flex flex-column">
                    <p class="pt-0 mb-0 p2">Jours : <span style="color: #F47000;">*</span></p>
                </div>
                <div class="col-lg-7 d-flex flex-row justify-content-around">
                    <button [disabled]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')"  type="button" class="btn btn-light btn-circle btn-sm p2" (click)="clickedInfos.jours.lundi = !clickedInfos.jours.lundi" [ngStyle]="{'backgroundColor': clickedInfos.jours.lundi ? '#3B9F70':'#B0B0B0' }">L</button>
                    <button [disabled]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')"   type="button" class="btn btn-light btn-circle btn-sm p2"  (click)="clickedInfos.jours.mardi = !clickedInfos.jours.mardi" [ngStyle]="{'backgroundColor': clickedInfos.jours.mardi ? '#3B9F70':'#B0B0B0' }">M</button>
                    <button [disabled]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')"  type="button" class="btn btn-light btn-circle btn-sm p2"  (click)="clickedInfos.jours.mercredi = !clickedInfos.jours.mercredi" [ngStyle]="{'backgroundColor': clickedInfos.jours.mercredi ? '#3B9F70':'#B0B0B0' }">M</button>
                    <button  [disabled]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')"  type="button" class="btn btn-light btn-circle btn-sm" (click)="clickedInfos.jours.jeudi = !clickedInfos.jours.jeudi" [ngStyle]="{'backgroundColor': clickedInfos.jours.jeudi ? '#3B9F70':'#B0B0B0' }">J</button>
                    <button [disabled]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')"  type="button" class="btn btn-light btn-circle btn-sm" (click)="clickedInfos.jours.vendredi = !clickedInfos.jours.vendredi" [ngStyle]="{'backgroundColor': clickedInfos.jours.vendredi ? '#3B9F70':'#B0B0B0' }">V</button>
                    <button [disabled]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')"  type="button" class="btn btn-light btn-circle btn-sm" (click)="clickedInfos.jours.samedi = !clickedInfos.jours.samedi" [ngStyle]="{'backgroundColor': clickedInfos.jours.samedi ? '#3B9F70':'#B0B0B0' }">S</button>
                    <button [disabled]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')"  type="button" class="btn btn-light btn-circle btn-sm" (click)="clickedInfos.jours.dimanche = !clickedInfos.jours.dimanche" [ngStyle]="{'backgroundColor': clickedInfos.jours.dimanche ? '#3B9F70':'#B0B0B0' }">D</button>
                </div>
            </div>

            <div class="row mt-3" *ngIf="clickedInfos.frequence != '' && clickedInfos.frequence != 'Hebdomadaire'">
                <div class="col-lg-4 d-flex flex-column  justify-content-center">

                </div>
                <div class="col-lg-7">
                    <select  [disabled]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')"  class="custom-select p2" style="width: 100%;box-shadow: none;border: 1px solid #F47000; color: black;" [(ngModel)]="clickedInfos.daysPerMonth" (ngModelChange)="change()">
                        <option  class="p2" [ngValue]="''" disabled selected>Sélectionner</option>
                        <option  class="p2" [ngValue]="'Tous les 1er et 3ème du mois'" *ngIf="clickedInfos.frequence == 'Bi-mensuelle'">Tous les 1er et 3ème du mois</option>
                        <option class="p2" [ngValue]="'Tous les 1er et 2ème du mois'" *ngIf="clickedInfos.frequence == 'Bi-mensuelle'">Tous les 1er et 2ème du mois</option>
                        <option class="p2" [ngValue]="'Tous les 1er et 4ème du mois'" *ngIf="clickedInfos.frequence == 'Bi-mensuelle'">Tous les 1er et 4ème du mois</option>

                        <option class="p2" [ngValue]="'Tous les 2ème et 3ème du mois'" *ngIf="clickedInfos.frequence == 'Bi-mensuelle'">Tous les 2ème et 3ème du mois</option>
                        <option class="p2" [ngValue]="'Tous les 2ème et 4ème du mois'" *ngIf="clickedInfos.frequence == 'Bi-mensuelle'">Tous les 2ème et 4ème du mois</option>
                        <option class="p2" [ngValue]="'Tous les 3ème et 4ème du mois'" *ngIf="clickedInfos.frequence == 'Bi-mensuelle'">Tous les 3ème et 4ème du mois</option>

                        <option class="p2" [ngValue]="'Tous les 1er du mois'" *ngIf="clickedInfos.frequence == 'mensuelle'">Tous les 1er du mois</option>
                        <option class="p2" [ngValue]="'Tous les 2ème du mois'" *ngIf="clickedInfos.frequence == 'mensuelle'">Tous les 2ème du mois</option>
                        <option class="p2" [ngValue]="'Tous les 3ème du mois'" *ngIf="clickedInfos.frequence == 'mensuelle'">Tous les 3ème du mois</option>
                        <option class="p2" [ngValue]="'Tous les 4ème du mois'" *ngIf="clickedInfos.frequence == 'mensuelle'">Tous les 4ème du mois</option>
                    </select>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-lg-4 d-flex flex-column  justify-content-center">
                    <p class="pt-0 mb-0 p2 ">Horaires :</p>
                </div>
                <div class="col-lg-7">
                    <ngb-timepicker [disabled]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')"  [(ngModel)]="clickedInfos.horaires"></ngb-timepicker>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-lg-4 d-flex flex-column  justify-content-center">
                    <p class="pt-0 mb-0 p2 ">Commentaire : </p>
                </div>
                <div class="col-lg-7">
                    <input type="text" [disabled]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')"   class="form-control input1 p2"  [(ngModel)]="clickedInfos.commentaire" (ngModelChange)="change()"  required>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-lg-4 d-flex flex-column  justify-content-center">
                    <p class="pt-0 mb-0 p2">Bouton PAV :</p>
                </div>
                <div class="col-lg-7 d-flex flex-row justify-content-start align-items-center">
                    <div class="btn-group" role="group" id="btn_pav" >
                        <input type="radio"  name="btn_pav" [value]="true" [(ngModel)]="clickedInfos.btn_pav" (ngModelChange)="change()" id="btn_pav_oui"  [disabled]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')"/>
                        <label class="btn btn-secondary radius_left_actus" id="btn_pav_oui" for="btn_pav_oui" [ngStyle]="{'cursor' :  ((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification') ? 'not-allowed' : 'pointer' }" >
                            Oui
                        </label>
                        <input type="radio"   [value]="false" [(ngModel)]="clickedInfos.btn_pav" (ngModelChange)="change()"   name="btn_pav_non"  class="radio_input radio_non"   id="btn_pav_non" [disabled]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')"/>
                        <label  class="btn btn-secondary radius_right_actus" id="btn_pav_non"   for="btn_pav_non" [ngStyle]="{'cursor' :  ((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification') ? 'not-allowed' : 'pointer' }">
                            Non
                        </label>
                    </div>
                </div>
            </div>

            <div class="row mt-3" *ngIf="clickedInfos.btn_pav">
                <div class="col-lg-4 d-flex flex-column  justify-content-center">
                    <p class="pt-0 mb-0 p2 ">Libellé bouton : <span style="color: #F47000;">*</span></p>
                </div>
                <div class="col-lg-7">
                    <input type="text" class="form-control input1 p2" [disabled]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')"   [(ngModel)]="clickedInfos.libelleBouton" (ngModelChange)="change()"  required>
                </div>
            </div>


            <div class="row mt-3" *ngIf="clickedInfos.btn_pav">
                <div class="col-lg-4 d-flex flex-column  justify-content-center">
                    <p class="pt-0 mb-0 p2 ">Description :</p>
                </div>
                <div class="col-lg-7">
                    <input type="text"  [disabled]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')"   class="form-control input1 p2"  [(ngModel)]="clickedInfos.description" (ngModelChange)="change()"  required>
                </div>
            </div>

            <div class="row mt-3" *ngIf="clickedInfos.btn_pav">
                <div class="col-lg-4 d-flex flex-column  justify-content-center">
                    <p class="pt-0 mb-0 p2">Flux : <span style="color: #F47000;">*</span></p>
                </div>
                <div class="col-lg-7">
                    <select  [disabled]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')"  class="custom-select p2" style="width: 100%;box-shadow: none;border: 1px solid #F47000; color: black;" [(ngModel)]="clickedInfos.fluxCarte" (ngModelChange)="change()">
                        <option class="p2" [ngValue]="''" disabled selected>Sélectionner</option>
                        <option class="p2"  *ngFor="let item of carte.flux" [ngValue]="item.nom">{{item.nom}}</option>
                    </select>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-lg-4 d-flex flex-column  justify-content-center">
                    <p class="pt-0 mb-0 p2">Lien consignes : </p>
                </div>
                <div class="col-lg-7">
                    <input type="text" [disabled]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')"   class="form-control input1 p2"  [(ngModel)]="clickedInfos.urlConsignes" (ngModelChange)="change()"  required>
                </div>
            </div>

            <div class="row mt-4">
                <div class="d-flex flex-column justify-content-start">
                    <p class="px-3 pt-0 mb-0 p2" style="font-style: italic;">Message par défaut à afficher pour ce flux aux utilisateurs ne disposant pas d'une collecte en porte à porte</p>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-lg-4 d-flex flex-column  justify-content-center">
                    <p class="pt-0 mb-0 p2">Libellé boutton : </p>
                </div>
                <div class="col-lg-7">
                    <input type="text" [disabled]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')"   class="form-control input1 p2"  [(ngModel)]="clickedInfos.defaultLibelleBouton" (ngModelChange)="change()"  required>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-lg-4 d-flex flex-column  justify-content-center">
                    <p class="pt-0 mb-0 p2 ">Description : </p>
                </div>
                <div class="col-lg-7">
                    <input type="text" [disabled]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')"   class="form-control input1 p2"  [(ngModel)]="clickedInfos.defaultDescription" (ngModelChange)="change()"  required>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-lg-4 d-flex flex-column  justify-content-center">
                    <p class="pt-0 mb-0 p2 ">Flux : </p>
                </div>
                <div class="col-lg-7">
                    <select  [disabled]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')"  class="custom-select p2" style="width: 100%;box-shadow: none;border: 1px solid #F47000; color: black;" [(ngModel)]="clickedInfos.defaultFlux" (ngModelChange)="change()">
                        <option class="p2" [ngValue]="''" disabled selected>Sélectionner</option>
                        <option class="p2"  *ngFor="let item of carte.flux" [ngValue]="item.nom">{{item.nom}}</option>
                    </select>
                </div>
            </div>

            <div class="row mb-3 mt-5">
                <div class="col-lg-4 mt-3 mt-lg-0 ">
                    <button type="button" class="btn button7 btn-primary w-100"   (click)="cancelModalInfos()">Annuler</button>
                </div>
                <div class="col-lg-4">
                    <button type="button" class="btn button4 btn-primary w-100" [disabled]="((user.role!='super_user') && (user.role!='Administrateur')) || (contrat.contratstatus != 'en_modification')"  (click)="savePolygonInfos()">Valider</button>
                </div>

            </div>

		</div>
	</div>
</ng-template>







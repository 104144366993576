import { Alert } from './../../models/alert/alert';
import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class AlertService {

    alert : BehaviorSubject<any> = new BehaviorSubject<any>(null);
	private alert_status: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    public   alert_status_obs = this.alert_status.asObservable();
	constructor() { }

	getAlert(): Observable<any> {
		return this.alert.asObservable();
	}

    setAlert(alert :Alert){
		this.alert.next(alert)
		this.alert_status.next(true)
	}

	setAlertStatus(status? : boolean){
		this.alert_status.next(status)
	}
}
